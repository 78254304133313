import React, { useState, useEffect } from "react";
import { AppBar } from "@material-ui/core";
import { useStyles } from "../../../styling/global/global";
import moment from "moment-timezone";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CachedIcon from "@material-ui/icons/Cached";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Tooltip, Paper } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";
import BillingRunExpansion from "../BillingRunExpansion/BillingRunExpansion";
import { AppStore } from "../../../../stores/AppStore";
import {
  listBillingItems,
  listBillingRunsItems,
  listBillingRunTypesItems,
  exportBillingRunResult,
  BillingExportMode,
} from "../../../../services/FunctionService";
import AddBillingRun from "../BillingModals/AddBillingRun";
import MessagePopUp from "../BillingModals/MessagePopUp";
import ResultMessage from "../BillingModals/ResultMessage";
import SubmitCalculations from "../BillingModals/SubmitCalculations";
import ExportBillingRun from "../BillingModals/ExportBillingRun";
import ViewBillingRuns from "../BillingModals/ViewBillingRuns";
import EditBillingRun from "../BillingModals/EditBillingRun";
import FinaliseRun from "../BillingModals/FinaliseRun";
import DeleteBillingRun from "../BillingModals/DeleteBillingRun";

interface BillingRunsProps {
  appStore?: AppStore;
}

const BillingRuns: React.FC<BillingRunsProps> = ({
  appStore,
}: BillingRunsProps) => {
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        height: 20,
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        width: "100%",
      },
    })
  )(TableRow);
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const [totalsData, setTotalsData] = useState<{ [key: string]: any }[]>([]);
  const [final, setFinal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [billingRunsTypes, setBillingRunsTypes] = useState<
    listBillingRunTypesItems[]
  >([]);
  const [renderValue, setRenderValue] = useState(false);
  const [billingRuns, setBillingRuns] = useState<listBillingRunsItems[]>([]);
  const [billingRun, setBillingRun] = useState<
    listBillingRunsItems | undefined
  >(undefined);
  const [billingProfiles, setBillingProfiles] = useState<listBillingItems[]>(
    []
  );
  const [profileData, setProfileData] = useState<listBillingItems | undefined>(
    undefined
  );
  const [profile_id, setProfile_id] = React.useState<number | undefined>(
    undefined
  );

  const [openExportBillingRun, setOpenExportBillingRun] = useState(false);
  const [openAddBillingRun, setOpenAddBillingRun] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [openViewBillingRuns, setOpenViewBillingRuns] = useState(false);
  const [openEditRun, setOpenEditRun] = useState(false);
  const [openFinaliseRun, setOpenFinaliseRun] = useState(false);
  const [openSubmitCalculatins, setOpenOpenSubmitcalculations] =
    useState(false);
  const [openDeleteRun, setOpenDeleteRun] = useState(false);
  const [openResultMessage, setOpenResultMessage] = useState(false);
  const handleChange = (event: React.ChangeEvent<any>) => {
    setProfile_id(+event.target.value);
    let newProfileData = billingProfiles.find(
      (x: any) => x.id == +event.target.value
    );
    setProfileData(newProfileData);
    fetchBillingRuns(+event.target.value);
  };
  const closeDeleteRun = () => {
    setOpenDeleteRun(false);
  };
  const closeSubmitCalculations = () => {
    setOpenOpenSubmitcalculations(false);
  };
  const handleOpenSubmitModal = (billingRun_id: number) => {
    let newBillingRun = billingRuns.find((x: any) => x.id == billingRun_id);
    setBillingRun(newBillingRun);
    setOpenOpenSubmitcalculations(true);
  };
  const handleOpenDeleteModal = (billingRun_id: number) => {
    let newBillingRun = billingRuns.find((x: any) => x.id == billingRun_id);
    setBillingRun(newBillingRun);
    setOpenDeleteRun(true);
  };
  const handleOpenFinaliseModal = (billingRun_id: number) => {
    let newBillingRun = billingRuns.find((x: any) => x.id == billingRun_id);
    setBillingRun(newBillingRun);
    setOpenFinaliseRun(true);
  };

  const closeAddBillingRunModal = () => {
    setOpenAddBillingRun(false);
  };
  const closeResultMessageModal = () => {
    setOpenResultMessage(false);
    setResultMessage("");
  };
  const closeOpenMessage = () => {
    setOpenMessage(false);
  };

  const closeExportBillingRun = () => {
    setOpenExportBillingRun(false);
  };
  const closeViewBillingRuns = () => {
    setOpenViewBillingRuns(false);
  };
  const closeEditBillingRun = () => {
    setOpenEditRun(false);
  };
  const closeFinaliseRun = () => {
    setOpenFinaliseRun(false);
  };
  const handleOpenExportBillingRun = (billingRun_id: number) => {
    if (profile_id) {
      let newBillingRun = billingRuns.find((x: any) => x.id == billingRun_id);
      setBillingRun(newBillingRun);
      setOpenExportBillingRun(true);
    } else {
      setErrorMessage(
        "Please select a profile id before exporting a billing run"
      );
      setOpenMessage(true);
    }
  };
  const handleOpenAddBillingModal = () => {
    if (profile_id) {
      // let newProfileData = billingProfiles.find((x: any) => x.id == profile_id);
      // setProfileData(newProfileData);
      setOpenAddBillingRun(true);
    } else {
      setErrorMessage(
        "Please select a profile id before creating a billing run"
      );
      setOpenMessage(true);
    }
  };
  const dummy = () => {};
  const fetchBillingRuns = async (profileId: number) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const listRunsResult = await appStore?.functionService.listBillingRuns(
        token,
        profileId
      );
      if (listRunsResult) {
        setBillingRuns(listRunsResult.billing_runs);
        setLoading(false);
      }
    }
  };
  const deleteBillingRun = async (billing_run_id: number) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const deleteRunResult = await appStore?.functionService.deleteBillingRun(
        token,
        billing_run_id
      );
      if (deleteRunResult) {
        if (deleteRunResult?.success) {
          fetchBillingRuns(profile_id!);
          setLoading(false);
          setResultMessage("Billing Run successfully deleted.");
          setOpenResultMessage(true);
          setRenderValue((value) => !value);
        } else {
          fetchBillingRuns(profile_id!);
          setLoading(false);
          setResultMessage(deleteRunResult?.msg);
          setOpenResultMessage(true);
          setRenderValue((value) => !value);
        }
      }
    }
  };
  const addBillingRun = async (
    profile_id: number,
    run_type_id: number,
    name: string,
    run_date: Date,
    description: string
  ) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);

      let finalTime = moment(run_date).format("YYYY-MM-DD");

      const addRunResult = await appStore?.functionService.addBillingRun(
        token,
        profile_id,
        run_type_id,
        name,
        finalTime,
        description
      );
      if (addRunResult) {
        if (addRunResult?.success) {
          fetchBillingRuns(profile_id!);
          setLoading(false);
          setRenderValue((value) => !value);
          setResultMessage("Billing Run added successfully.");
          setOpenResultMessage(true);
        } else {
          setLoading(false);
          setRenderValue((value) => !value);
          setResultMessage(addRunResult?.msg);
          setOpenResultMessage(true);
        }
      }
    }
  };
  const submitCalculationsFunc = async (
    billing_run_id: number,
    billing_org_ids: string,
    incl_pro_rata: boolean,
    finalise: boolean
  ) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const submitCalculationsResult =
        await appStore?.functionService.submitBillingCalculation(
          token,
          billing_run_id,
          billing_org_ids,
          incl_pro_rata,
          finalise
        );
      if (submitCalculationsResult) {
        if (submitCalculationsResult.success) {
          fetchBillingRuns(profile_id!);
          setLoading(false);
          // setRenderValue((value) => !value);
          setResultMessage("Calculations submitted successfully.");
          setOpenResultMessage(true);
        } else {
          setLoading(false);
          setResultMessage(submitCalculationsResult?.msg);
          setOpenResultMessage(true);
        }
      }
    }
  };
  const finaliseFunc = async (billing_run_id: number) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const finalResult = await appStore?.functionService.finaliseBilling(
        token,
        billing_run_id
      );
      if (finalResult) {
        if (finalResult.success) {
          fetchBillingRuns(profile_id!);
          setLoading(false);
          // setRenderValue((value) => !value);
          setResultMessage("Calculations submitted successfully.");
          setOpenResultMessage(true);
        } else {
          setLoading(false);
          setResultMessage(finalResult?.msg);
          setOpenResultMessage(true);
          // setRenderValue((value) => !value);
        }
      }
    }
  };
  const EditBillingRunFunction = async (
    profile_id: number,
    runId: number,
    name: string,
    description: string
  ) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const EditBillingRunResult =
        await appStore?.functionService.editBillingRun(
          token,
          runId,
          name,
          description
        );
      if (EditBillingRunResult) {
        if (EditBillingRunResult.success) {
          fetchBillingRuns(profile_id!);
          setLoading(false);
          setResultMessage("Billing Run editted successfully.");
          setOpenResultMessage(true);
        } else {
          fetchBillingRuns(profile_id!);
          setLoading(false);
          setResultMessage(EditBillingRunResult?.msg);
          setOpenResultMessage(true);
        }
      }
    }
  };
  const exportBillingRun = async (
    billing_run_id: number,
    billing_org_ids: string,
    selectedType: BillingExportMode
  ) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const exportBillingRunResult: exportBillingRunResult =
        await appStore?.functionService.exportBillingRun(
          token,
          billing_run_id,
          billing_org_ids,
          selectedType
        );
      if (exportBillingRunResult) {
        if (exportBillingRunResult.success) {
          fetchBillingRuns(profile_id!);
          setLoading(false);
          setResultMessage("Billing Run submitted successfully.");
          setTotalsData(exportBillingRunResult.data);
          setOpenResultMessage(true);
        } else {
          setLoading(false);
          setResultMessage(exportBillingRunResult?.msg);
          setOpenResultMessage(true);
        }
      }
    }
  };

  useEffect(() => {
    const fetchProfiles = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        const billinglistResult =
          await appStore?.functionService.listBillingProfiles(token);
        if (billinglistResult?.success) {
          setBillingProfiles(billinglistResult?.billing_profiles);
          const billingRunTypesResult =
            await appStore?.functionService.ListBillingRunTypes(token);
          if (billingRunTypesResult?.success) {
            setBillingRunsTypes(billingRunTypesResult.billing_run_types);
            setLoading(false);
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      }
    };
    fetchProfiles();
  }, [renderValue]);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {" "}
          <div style={{ width: "40%", marginLeft: "30%" }}>
            <FormControl style={{ marginTop: "2%" }} fullWidth>
              <InputLabel
                style={{ marginLeft: "1%", color: "#3f51b5" }}
                id="test-select-label"
              >
                Select Billing Profile
              </InputLabel>
              <Select
                label="Select Billing Profile"
                labelId="test-select-label"
                id="demo-controlled-open-select"
                variant="outlined"
                value={profile_id}
                onChange={(e) => handleChange(e)}
              >
                {billingProfiles &&
                  billingProfiles.map((profile, index) => {
                    return (
                      <MenuItem value={profile.id}>
                        <Typography
                          className={classes.bold_text_lower}
                          style={{ color: "#3f51b5" }}
                        >
                          {profile.name}
                        </Typography>
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          <br></br>
          {profile_id ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  align="center"
                  className={classes.bold_text}
                  style={{
                    maxWidth: "100%",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    color: "#3f51b5",
                  }}
                >
                  Billing Runs {"("}
                  {billingRuns ? billingRuns.length : "0"}
                  {")"}
                </Typography>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                    marginTop: 15,
                    borderRadius: 15,
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <Paper style={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer style={{ maxHeight: 300 }}>
                      <Table size="small" stickyHeader>
                        <TableHead>
                          <TableRow
                            style={{
                              backgroundColor: "#1f3161",
                              height: "20px",
                              color: "#fff",
                            }}
                          >
                            <TableCell>
                              <Typography
                                style={{ color: "#3f51b5" }}
                                className={classes.bold_text}
                              >
                                Id
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                style={{ color: "#3f51b5" }}
                                className={classes.bold_text}
                              >
                                Billing Run Name
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                style={{ color: "#3f51b5" }}
                                className={classes.bold_text}
                              >
                                Billing Run Description
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                style={{ color: "#3f51b5" }}
                                className={classes.bold_text}
                              >
                                Billing Run Type
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                style={{ color: "#3f51b5" }}
                                className={classes.bold_text}
                              >
                                Billing Run Date
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                style={{ color: "#3f51b5" }}
                                className={classes.bold_text}
                              >
                                Billing Run Status
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Tooltip placement="bottom" title="Refresh icon">
                                <CachedIcon
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    fetchBillingRuns(profile_id!);
                                  }}
                                  style={{
                                    marginRight: "1%",
                                    fill: "#3f51b5",
                                    cursor: "pointer",
                                  }}
                                />
                              </Tooltip>
                              <Tooltip
                                placement="bottom"
                                title="Create billing run"
                              >
                                <AddIcon
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleOpenAddBillingModal();
                                  }}
                                  style={{
                                    marginLeft: "1%",
                                    fill: "#3f51b5",
                                    cursor: "pointer",
                                  }}
                                />
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {billingRuns &&
                            billingRuns.map((billingRun: any, index) => {
                              return (
                                <StyledTableRow>
                                  <TableCell>
                                    <Typography
                                      align="center"
                                      className={classes.bold_text}
                                      style={{ color: "grey" }}
                                    >
                                      {billingRun.id}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      align="center"
                                      className={classes.bold_text}
                                      style={{ color: "grey" }}
                                    >
                                      {billingRun.name}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      className={classes.bold_text_lower}
                                      style={{ color: "grey" }}
                                    >
                                      {billingRun.description}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      className={classes.bold_text}
                                      style={{ color: "grey" }}
                                    >
                                      {billingRun.run_type}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      className={classes.bold_text}
                                      style={{ color: "grey" }}
                                    >
                                      {billingRun.run_date}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      className={classes.bold_text_lower}
                                      style={{ color: "grey" }}
                                    >
                                      {billingRun.status}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <BillingRunExpansion
                                      final={true}
                                      submit={true}
                                      del={true}
                                      edit={true}
                                      add={false}
                                      exp={true}
                                      handleAdd={dummy}
                                      handleEdit={() => {
                                        setBillingRun(billingRun);
                                        setOpenEditRun(true);
                                      }}
                                      handleSubmit={() => {
                                        setFinal(false);
                                        handleOpenSubmitModal(billingRun.id);
                                      }}
                                      handleDelete={() => {
                                        handleOpenDeleteModal(billingRun.id);
                                      }}
                                      handleFinalSubmit={() => {
                                        handleOpenFinaliseModal(billingRun.id);
                                      }}
                                      handleExport={() => {
                                        handleOpenExportBillingRun(
                                          billingRun.id
                                        );
                                      }}
                                    />
                                  </TableCell>
                                </StyledTableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </div>
              </div>
            </>
          ) : null}
        </div>
      )}
      <AddBillingRun
        open={openAddBillingRun}
        onClose={closeAddBillingRunModal}
        appStore={appStore}
        billingRunsTypes={billingRunsTypes}
        profile={profileData}
        addBillingRun={(
          profile_id: number,
          run_type_id: number,
          name: string,
          run_date: Date,
          description: string
        ) =>
          addBillingRun(profile_id, run_type_id, name, run_date, description)
        }
      />
      <ViewBillingRuns
        open={openViewBillingRuns}
        onClose={closeViewBillingRuns}
        appStore={appStore}
        profile={profileData}
      />
      <MessagePopUp
        open={openMessage}
        onClose={closeOpenMessage}
        message={errorMessage}
      />
      <ResultMessage
        open={openResultMessage}
        onClose={closeResultMessageModal}
        message={resultMessage}
        data={totalsData}
      />
      <SubmitCalculations
        open={openSubmitCalculatins}
        final={final}
        onClose={closeSubmitCalculations}
        appStore={appStore}
        billingRun={billingRun}
        submitCalculationsFunc={(
          billing_run_id: number,
          billing_org_ids: string,
          incl_pro_rata: boolean,
          finalise: boolean
        ) =>
          submitCalculationsFunc(
            billing_run_id,
            billing_org_ids,
            incl_pro_rata,
            finalise
          )
        }
        profile={profileData}
      />
      <ExportBillingRun
        open={openExportBillingRun}
        onClose={closeExportBillingRun}
        billingRun={billingRun}
        appStore={appStore}
        profile_id={profile_id}
        exportBillingRun={(
          billing_run_id: number,
          billing_org_ids: string,
          selectedType: BillingExportMode
        ) => exportBillingRun(billing_run_id, billing_org_ids, selectedType)}
      />
      <EditBillingRun
        appStore={appStore}
        open={openEditRun}
        onClose={closeEditBillingRun}
        profile_id={profile_id}
        EditBillingRunFunction={(
          profile_id: number,
          runId: number,
          name: string,
          description: string
        ) => EditBillingRunFunction(profile_id, runId, name, description)}
        run={billingRun}
      />
      <FinaliseRun
        open={openFinaliseRun}
        onClose={closeFinaliseRun}
        appStore={appStore}
        finalFunc={(billing_run_id: number) => finaliseFunc(billing_run_id)}
        billingRun={billingRun}
      />
      <DeleteBillingRun
        open={openDeleteRun}
        onClose={closeDeleteRun}
        appStore={appStore}
        run={billingRun}
        deleteBillingRun={(billing_run_id: number) =>
          deleteBillingRun(billing_run_id)
        }
      />
    </>
  );
};

export default BillingRuns;
