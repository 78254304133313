import { Autocomplete } from "@material-ui/lab";
import React, { FC, useState, useEffect } from "react";
import { TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "../../../spinner/Spinner";

import CustomModal from "../../UI/CustomModal";
import CustomRadio from "../../UI/CustomRadio";
import { useStyles } from "../../styling/global/global";
import { AppStore } from "../../../stores/AppStore";
import {
  GetHubs,
  GetSensors,
  Hub,
  Org,
  Sensor,
  Site,
} from "../../../services/FunctionService";

interface GlobalSearchProps {
  openModal: boolean;
  closeModal: () => void;
  setSelectedItem: (item: any, editing: boolean) => void;
  appStore: AppStore;
}
const GlobalSearch: FC<GlobalSearchProps> = ({
  openModal,
  closeModal,
  setSelectedItem,
  appStore,
}) => {
  type search_by =
    | "org_name"
    | "site_name"
    | "site_id"
    | "org_id"
    | "hub_id_str"
    | "hub_id"
    | "sensor_id_str"
    | "sensor_id";

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [orgslist, setOrgsList] = React.useState<Org[]>([]);
  const [hubsList, setHubsList] = useState<Hub[]>([]);
  const [sensorsList, setSensorsList] = useState<Sensor[]>([]);
  const [siteList, setSiteList] = React.useState<Site[]>([]);
  const [searchBySiteName, setSearchBySiteName] =
    React.useState<search_by>("site_name");

  const useStyle = makeStyles({
    paper: {
      border: "2px solid #6D809D",
      color: "#15325F",
    },
  });
  const styler = useStyle();

  const handleItemSelection = async (value: any) => {
    if (value) {
      setLoading(true);
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var rest_org = await appStore?.functionService.getOrgOneLevel(
          token!,
          value.org_id
        );
        if (rest_org) {
          if (rest_org.orgs == undefined) {
            rest_org.orgs = [];
          }
          await appStore?.authService.setBreadcrumbOrgs(rest_org);
          appStore?.helpers.setCurrentOrgId(value.org_id);
          let temp_site: Site = value;
          temp_site.type = "site";
          temp_site.name = value.site_name;
          setSelectedItem(temp_site, false);
        }
      }
      setLoading(false);
      closeModal();
    }
  };
  const handleSensorSelection = async (value: any) => {
    if (value) {
      setLoading(true);
      let required_site = siteList.find((x) => x.site_id === value.site_id);
      if (required_site) {
        const token = await appStore?.authService.getAuthorisedToken();
        if (token) {
          var rest_org = await appStore?.functionService.getOrgOneLevel(
            token!,
            required_site?.org_id!
          );
          if (rest_org) {
            if (rest_org.orgs == undefined) {
              rest_org.orgs = [];
            }

            await appStore?.authService.setBreadcrumbOrgs(rest_org);
            appStore?.helpers.setCurrentOrgId(required_site?.org_id!);
            let temp_site: Site = value;
            temp_site.type = "site";
            temp_site.name = required_site?.site_name as string;
            setSelectedItem(temp_site, false);
          }
        }
      }

      setLoading(false);
      closeModal();
    }
  };

  const handleHubsSelection = async (value: any) => {
    if (value) {
      setLoading(true);
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var rest_org = await appStore?.functionService.getOrgOneLevel(
          token!,
          value.org_id
        );
        if (rest_org) {
          if (rest_org.orgs == undefined) {
            rest_org.orgs = [];
          }
          let site_name_value = siteList.find(
            (x) => x.site_id === value.site_id
          );
          if (site_name_value) {
            let shared_hubs: Hub[] =
              await appStore?.functionService.getHubsSharedForOrgSearch(
                token,
                value.org_id.org_id!,
                value.org_id,
                value.hub_id_str
              );
            let check_shared_hub = shared_hubs.find(
              (hub) => hub.hub_id_str === value.hub_id_str
            );
            if (check_shared_hub) {
              await appStore?.authService.setBreadcrumbOrgs(rest_org);
              appStore?.helpers.setCurrentOrgId(value.org_id);
              let tempOrg: Org = value;
              tempOrg.type = "org";
              setSelectedItem(tempOrg, false);
            } else {
              await appStore?.authService.setBreadcrumbOrgs(rest_org);
              appStore?.helpers.setCurrentOrgId(value.org_id);
              let temp_site: Site = value;
              temp_site.type = "site";
              temp_site.name = site_name_value?.site_name as string;
              setSelectedItem(temp_site, false);
            }
          } else {
            await appStore?.authService.setBreadcrumbOrgs(rest_org);
            appStore?.helpers.setCurrentOrgId(value.org_id);
            let tempOrg: Org = value;
            tempOrg.type = "org";
            setSelectedItem(tempOrg, false);
          }
        }
      }
      setLoading(false);
      closeModal();
    }
  };
  const handleOrgSelection = async (value: any) => {
    if (value) {
      setLoading(true);
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var rest_org = await appStore?.functionService.getOrgOneLevel(
          token!,
          value.org_id
        );
        if (rest_org) {
          if (rest_org.orgs == undefined) {
            rest_org.orgs = [];
          }
          await appStore?.authService.setBreadcrumbOrgs(rest_org);
          appStore?.helpers.setCurrentOrgId(value.org_id);
          let tempOrg: Org = value;
          tempOrg.type = "org";
          setSelectedItem(tempOrg, false);
        }
      }
      setLoading(false);
      closeModal();
    }
  };

  const fetchHubs = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let hubsResult: GetHubs = await appStore?.functionService.getAllHubs(
        token,
        appStore.authService.getEntryOrg()?.id!
      );
      if (hubsResult.success) {
        setHubsList(hubsResult.hubs);
      }
    }
    setLoading(false);
  };
  const fetchSensors = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let sensorsResult: GetSensors =
        await appStore?.functionService.getAllSensors(token);
      if (sensorsResult.success) {
        let tempSensors: Sensor[] = [];
        sensorsResult.sensors.map((item) => {
          let sens = siteList.find((site) => site.site_id === item.site_id);
          if (sens) {
            tempSensors.push(item);
          }
        });
        setSensorsList(tempSensors);
      }
    }
    setLoading(false);
  };

  const fetchOrgList = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const OrgListResult =
        await appStore?.functionService.getListOrgsWithTreeNames(token);
      if (OrgListResult && OrgListResult.success) {
        let orgz = OrgListResult?.orgs;
        orgz.sort(function (a: any, b: any) {
          return a.org_tree_names - b.org_tree_names;
        });
        orgz.sort(function (a: any, b: any) {
          return a.org_tree_names.length - b.org_tree_names.length;
        });
        setOrgsList(orgz);
      }
      setLoading(false);
    }
  };

  // Fetch sites
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        let site_list = await appStore?.functionService.listGlobalSites(
          token,
          appStore.authService.getEntryOrg()?.id!
        );
        if (site_list && site_list.success) {
          let sites: Site[] = site_list?.sites;
          sites.sort(function (a: Site, b: Site) {
            if (b.site_name.toLowerCase() > a.site_name.toLowerCase()) {
              return -1;
            }
            if (b.site_name.toLowerCase() < a.site_name.toLowerCase()) {
              return 1;
            }
            return 0;
          });

          setSiteList(sites);
          setLoading(false);
        }
      }
    };
    fetchData();
    fetchOrgList();
    fetchHubs();
  }, []);

  // useEffect(() => {
  //   if (siteList.length > 0) {
  //     fetchSensors();
  //   }
  // }, [siteList]);

  return (
    <CustomModal open={openModal} closeHandler={closeModal}>
      {
        <div style={{ width: 450 }}>
          <CustomRadio
            optionHander={(value: string) => {
              if (value == "site_id") {
                setSearchBySiteName("site_id");
              } else if (value == "site_name") {
                setSearchBySiteName("site_name");
              } else if (value === "org_name") {
                setSearchBySiteName("org_name");
              } else if (value === "org_id") {
                setSearchBySiteName("org_id");
              } else if (value === "hub_id_str") {
                setSearchBySiteName("hub_id_str");
              } else if (value === "hub_id") {
                setSearchBySiteName("hub_id");
              }
              //  else if (value === "sensor_id_str") {
              //   setSearchBySiteName("sensor_id_str");
              // } else if (value === "sensor_id") {
              //   setSearchBySiteName("sensor_id");
              // }
            }}
          />
          <br></br>
          {!loading ? (
            searchBySiteName === "site_name" ||
            searchBySiteName === "site_id" ? (
              <Autocomplete
                id="combo-box-demo"
                className={classes.form_component_medium}
                options={siteList}
                onChange={(event: React.ChangeEvent<any>, user: any) => {
                  handleItemSelection(user);
                }}
                getOptionLabel={(option: Site) => {
                  if (searchBySiteName === "site_name") {
                    return option.site_name;
                  } else {
                    return option.site_id.toString();
                  }
                }}
                renderOption={(option: Site) => {
                  return (
                    <span
                      className={classes.bold_text}
                      style={{ color: "#15325F" }}
                    >
                      {searchBySiteName === "site_name"
                        ? option.site_name
                        : option.site_id}
                      {searchBySiteName === "site_id" && (
                        <span style={{ color: "#ccce" }}>
                          {" "}
                          - {option.site_name}
                        </span>
                      )}
                    </span>
                  );
                }}
                style={{
                  width: "100%",
                  color: "#51709e",
                  textAlign: "center",
                }}
                classes={{ paper: styler.paper }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span className={classes.bold_text}>
                        {searchBySiteName === "site_name"
                          ? "Search by site name"
                          : searchBySiteName === "site_id"
                          ? "Search by site id"
                          : "Search by org name"}
                      </span>
                    }
                    variant="outlined"
                    // className={classes.bold_text_billing}
                  />
                )}
              />
            ) : searchBySiteName === "org_name" ||
              searchBySiteName === "org_id" ? (
              <Autocomplete
                id="combo-box-demo"
                className={classes.form_component_medium}
                options={orgslist}
                onChange={(event: React.ChangeEvent<any>, user: any) => {
                  handleOrgSelection(user);
                }}
                getOptionLabel={(option: any) => {
                  let tree = option.org_tree_names!.map(
                    (item: any) => `   /   ${item} `
                  );
                  let treeString = tree.toString();
                  const rpl = treeString.replace(/,/g, "");
                  return `${rpl}`;
                }}
                renderOption={(option: Org) => {
                  let tree = option.org_tree_names!.map(
                    (item: any) => `   /   ${item} `
                  );
                  let treeString = tree.toString();
                  const rpl = treeString.replace(/,/g, "");
                  return (
                    <span
                      className={classes.bold_text}
                      style={{ color: "#15325F" }}
                    >
                      {searchBySiteName === "org_name" ? rpl : option.org_id}
                      {searchBySiteName === "org_id" && (
                        <span style={{ color: "#ccce" }}> - {rpl}</span>
                      )}
                    </span>
                  );
                }}
                style={{
                  width: "100%",
                  color: "#51709e",
                  textAlign: "center",
                }}
                classes={{ paper: styler.paper }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span className={classes.bold_text}>
                        {searchBySiteName === "org_name"
                          ? "Search by group name"
                          : "Search by group id"}
                      </span>
                    }
                    variant="outlined"
                  />
                )}
              />
            ) : searchBySiteName === "hub_id_str" ||
              searchBySiteName === "hub_id" ? (
              <Autocomplete
                id="combo-box-demo"
                className={classes.form_component_medium}
                options={hubsList}
                onChange={(event: React.ChangeEvent<any>, user: any) => {
                  handleHubsSelection(user);
                }}
                getOptionLabel={(option: Hub) => {
                  if (searchBySiteName === "hub_id_str") {
                    return option.hub_id_str;
                  } else {
                    return `${option.hub_id.toString()}`;
                  }
                }}
                renderOption={(option: Hub) => {
                  return (
                    <span
                      className={classes.bold_text}
                      style={{ color: "#15325F" }}
                    >
                      {searchBySiteName === "hub_id_str"
                        ? option.hub_id_str
                        : option.hub_id}
                      {searchBySiteName === "hub_id" && (
                        <span style={{ color: "#ccce" }}>
                          {" "}
                          - {option.hub_id_str}
                        </span>
                      )}
                    </span>
                  );
                }}
                style={{
                  width: "100%",
                  color: "#51709e",
                  textAlign: "center",
                }}
                classes={{ paper: styler.paper }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span className={classes.bold_text}>
                        {searchBySiteName === "hub_id_str"
                          ? "Search by hub id string"
                          : "Search by hub id"}
                      </span>
                    }
                    variant="outlined"
                  />
                )}
              />
            ) : (
              <Autocomplete
                id="combo-box-demo"
                className={classes.form_component_medium}
                options={sensorsList}
                onChange={(event: React.ChangeEvent<any>, user: any) => {
                  handleSensorSelection(user);
                }}
                getOptionLabel={(option: Sensor) => {
                  if (searchBySiteName === "sensor_id_str") {
                    return option.sensor_id_str;
                  } else {
                    return `${option.sensor_id}`;
                  }
                }}
                renderOption={(option: Sensor) => {
                  return (
                    <span
                      className={classes.bold_text}
                      style={{ color: "#15325F" }}
                    >
                      {searchBySiteName === "sensor_id_str"
                        ? option.sensor_id_str
                        : option.sensor_id}
                      {searchBySiteName === "sensor_id" && (
                        <span style={{ color: "#ccce" }}>
                          {" "}
                          - {option.sensor_id_str}
                        </span>
                      )}
                    </span>
                  );
                }}
                style={{
                  width: "100%",
                  color: "#51709e",
                  textAlign: "center",
                }}
                classes={{ paper: styler.paper }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <span className={classes.bold_text}>
                        {searchBySiteName === "sensor_id_str"
                          ? "Search by sensor id string"
                          : "Search by sensor id"}
                      </span>
                    }
                    variant="outlined"
                  />
                )}
              />
            )
          ) : (
            <div style={{ width: 450, paddingLeft: 180 }}>
              <Spinner />
            </div>
          )}
        </div>
      }
    </CustomModal>
  );
};

export default GlobalSearch;
