import React from "react";

export const ArrayToStringifiedArray = (
  arr: Array<number | string>
): string => {
  let stringVal = "[";
  arr.map((c, index) => {
    if (index === 0) {
      stringVal = stringVal + c;
    } else {
      stringVal = stringVal + "," + c;
    }
  });
  stringVal = stringVal + "]";

  return stringVal;
};
