import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Sensor } from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import Typography from "@material-ui/core/Typography";
interface CameraMappingProps {
  disabled: boolean;
  sensors: Sensor[] | undefined;
  handleCameraMappingsChange: (newvalues: any[]) => void;
  value: any[];
  mappingtype: string;
}
const CameraMapping: React.FC<CameraMappingProps> = ({
  mappingtype,
  sensors,
  handleCameraMappingsChange,
  disabled,
  value,
}: CameraMappingProps) => {
  const classes = useStyles();
  const [message, setMessage] = React.useState<string>("");
  const handleValueChange = (event: React.ChangeEvent<any>) => {
    let val = event.target.value;
    if ((mappingtype == "integer" && !isNaN(+val)) || mappingtype == "string") {
      let key = event.target.name;
      let newvalues: any[] = [];
      let keyFound = false;
      value.forEach((valueitem: { [x: string]: any }) => {
        if (key in valueitem) {
          valueitem[key] = val;

          keyFound = true;
        }
        newvalues.push(valueitem);
      });
      if (value.length === 0) {
        let newV = {
          [key]: val,
        };
        newvalues.push(newV);
      } else if (value.length > 0 && keyFound == false) {
        let newV = {
          [key]: val,
        };
        newvalues.push(newV);
      }
      setMessage("");
      handleCameraMappingsChange(newvalues);
    } else {
      setMessage("Invalid entry. Must be a number!");
    }
  };
  var mappings: React.ReactNode[] = [];
  if (sensors && value) {
    sensors.forEach((sensor) => {
      let val = "";
      if (value) {
        value.forEach((camera) => {
          if (sensor.sensor_id_str in camera) {
            val = camera[sensor.sensor_id_str];
          }
        });
      }
      mappings.push(
        <TextField
          disabled={disabled}
          name={sensor.sensor_id_str}
          onChange={handleValueChange}
          label={sensor.sensor_id_str}
          variant="outlined"
          margin="normal"
          autoComplete=""
          value={val}
          fullWidth
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            marginTop: 10,
          }}
          InputProps={{
            className: classes.bold_text,
          }}
          InputLabelProps={{
            classes: {
              root: classes.bold_text,
            },
            style: {
              marginLeft: 15,
            },
          }}
        />
      );
    });
  } else {
    if (value) {
      for (let key in value) {
        let camera_ip = value[key];
        mappings.push(
          <TextField
            disabled={disabled}
            label={key}
            value={camera_ip}
            variant="outlined"
            margin="normal"
            autoComplete=""
            fullWidth
            style={{
              paddingLeft: 15,
              paddingRight: 15,
              marginTop: 10,
            }}
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
              style: {
                marginLeft: 15,
              },
            }}
          />
        );
      }
    }
  }
  return (
    <div>
      <Typography variant="h6" color="error">
        {message}
      </Typography>
      {mappings}
    </div>
  );
};
export default CameraMapping;
