import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "../../../styling/global/global";
import { Typography } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
interface MessageModalProps {
  open: boolean;
  message: string;
  data?: { [key: string]: any }[];
  onClose: () => void;
}
const ResultMessage: React.FC<MessageModalProps> = ({
  message,
  open,
  data,
  onClose,
}: MessageModalProps) => {
  const StyledTableRowSMTP = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        display: "flex",
        justifyContent: "space-between",
        textAlign: "left",
      },
    })
  )(TableRow);
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        width: "100%",
        cursor: "pointer",
      },
    })
  )(TableRow);
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };
  var mappingTableHeader: React.ReactNode[] = [];
  if (data) {
    data.map((item, index) => {
      if (index === 0) {
        Object.keys(item).map((keyName, keyValue) => {
          mappingTableHeader.push(
            <TableCell>
              <Typography
                style={{ color: "black" }}
                className={classes.bold_text}
              >
                {keyName}
              </Typography>
            </TableCell>
          );
        });
      }
    });
  }
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className="edit-dialog"
      fullWidth={true}
    >
      <DialogTitle id="simple-dialog-title"></DialogTitle>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingLeft: "5%",
          paddingRight: "5%",
          marginBottom: "5%",
        }}
      >
        <Typography style={{ fontSize: 13 }} className={classes.bold_text}>
          {message}
        </Typography>
        {data && (
          <div
            style={{
              boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
              marginTop: 15,
              borderRadius: 15,
              overflow: "hidden",
              width: "60%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <TableContainer style={{ maxHeight: 500 }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>{mappingTableHeader}</TableRow>
                </TableHead>
                {data &&
                  data.map((item, index) => {
                    return (
                      <TableBody>
                        <StyledTableRow>
                          {Object.keys(item).map((key_name, key_value) => {
                            return (
                              <TableCell>
                                <Typography className={classes.bold_text}>
                                  {item[key_name]}
                                </Typography>
                              </TableCell>
                            );
                          })}
                        </StyledTableRow>
                      </TableBody>
                    );
                  })}
              </Table>
            </TableContainer>
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 10,
          }}
          className="buttons-container"
        >
          <Button
            variant="contained"
            className={classes.bold_text}
            onClick={handleClose}
          >
            OK
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ResultMessage;
