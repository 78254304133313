import React from "react";
import classes from "./ui.module.css";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import { Typography, Tooltip } from "@material-ui/core";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch);

interface Props {
  setEnabled: (enabled: boolean) => void;
  title: string;
  enabled: boolean;
}

const CustomSwitch: React.FC<Props> = ({ setEnabled, enabled, title }) => {
  return (
    <div style={{ marginTop: 20 }}>
      <Typography className={classes.bold_text}>
        <Tooltip placement="right" title="">
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>
              <h5>{title}</h5>
            </Grid>
            <Grid item>
              <AntSwitch
                checked={enabled}
                onChange={(e) => {
                  e.stopPropagation();
                  setEnabled(e.target.checked);
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                name="checkedC"
              />
            </Grid>
          </Grid>
        </Tooltip>
      </Typography>
    </div>
  );
};

export default CustomSwitch;
