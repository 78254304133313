import React, { useState, useEffect } from "react";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { useStyles } from "../../styling/global/global";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Checkbox from "@material-ui/core/Checkbox";
import { ReportGroup, Site } from "../../../services/FunctionService";
import { getBaseUrl } from "../../HelperFunctions";
import { ArrayToStringifiedArray } from "../../../hooks/ArrayToStringifiedArray";
import CustomModal from "../../UI/CustomModal";
import BasicModal from "./CamHealthModal";

//Alert
import { Divider } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  withStyles,
  createMuiTheme,
} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import Alert from "@material-ui/lab/Alert";
import LoadingSpinner from "../../../spinner/LoadingSpinner";
import Spinner from "../../../spinner/Spinner";
import { AppStore } from "../../../stores/AppStore";
import Box from "@material-ui/core/Box";
import CustomCheckBox from "../../UI/CustomCheckBox";

//TABS CONTENT
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const themez = createMuiTheme({
  overrides: {
    MuiTab: {
      root: {
        "&.Mui-selected": {
          background: "rgba(255,255,255,0.09)",
          color: "white",
          fontWeight: "bold",
        },
      },
    },
  },
});

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

//End

const useStyle = makeStyles({
  paper: {
    border: "2px solid #6D809D",
    color: "#15325F",
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStylesDate = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      color: "#6D809D",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
      color: "#6D809D",
    },
  })
);

// Switch Styles
const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch);

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const sitelist = [
  { site_name: "dummy_site_name_1", site_id: 1 },
  { site_name: "dummy_site_name_2", site_id: 2 },
  { site_name: "dummy_site_name_3", site_id: 3 },
  { site_name: "dummy_site_name_4", site_id: 4 },
];

interface ReportAddProps {
  open_add: boolean;
  appStore?: AppStore;
  setAddOpenToClose: () => void;
  reloadOrgs: (id: number) => void;
  group_id: number;
  groupTreeName: string;
  top_org_only: boolean;
}

const time_zones = [
  "Africa/Johannesburg",
  "Africa/Cairo",
  "Africa/Nairobi",
  "Africa/Accra",
  "Africa/Harare",
  "America/New_York",
  "America/Los_Angeles",
  "America/Indiana/Indianapolis",
  "America/Detroit",
  "America/Phoenix",
  "Asia/Shanghai",
  "Asia/Riyadh",
  "Asia/Colombo",
  "Asia/Calcutta",
  "Asia/Tokyo",
  "Australia/Sydney",
  "Australia/Melbourne",
  "Australia/Darwin",
  "Australia/Hobar",
  "Europe/Athens",
  "Europe/Berlin",
  "Europe/Helsinki",
  "Europe/London",
  "Europe/Paris",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "UTC",
];

const hrs_list = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];
const min_list = [
  "00",
  "05",
  "10",
  "15",
  "20",
  "25",
  "30",
  "35",
  "40",
  "45",
  "50",
  "55",
];

const ReportAdd: React.FC<ReportAddProps> = ({
  open_add,
  setAddOpenToClose,
  appStore,
  reloadOrgs,
  group_id,
  groupTreeName,
  top_org_only,
}) => {
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("md");

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // CAMERA HEALTH STATE LOGIC
  const [camHealth, setCamHealth] = useState("off");
  const [productSwitch, setProductSwitch] = useState(false);
  const [openCamHealthModal, setOpenCamHealthModal] = useState(false);

  const [orgslist, setOrgsList] = React.useState<any>([]);
  const [siteList, setSiteList] = React.useState<any>([]);
  const [groupid, setGroupId] = React.useState(group_id);
  const [loading, setLoading] = React.useState(false);
  const [loadingSites, setLoadingSites] = React.useState(false);
  const [groupname, setGroupName] = React.useState("");
  const [err, setErr] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const [openEdit, setOpenEdit] = React.useState(false);
  let [isEditable, setIsEditable] = React.useState(false);
  const [personName, setPersonName] = React.useState<string[]>([]);
  const theme = useTheme();
  const [selectedReport, setSelectedReport] = React.useState<unknown>(-1);
  const [selectedProductId, setSelectedProductId] = React.useState<
    unknown | string
  >(3);

  const [selectedHour, setSelectedHour] = React.useState<string | unknown>(
    "18"
  );
  const [selectedMinutes, setSelectedMinutes] = React.useState<
    string | unknown
  >("00");
  const [zone, setZone] = React.useState<any>("Africa/Johannesburg");

  const [viewOptions, setViewOptions] = React.useState("pdf");
  const [selectedPeriodValue, setSelectedPeriodValue] = React.useState("daily");
  const [selectedSite, setSelectedSite] = React.useState("select site");
  const [openModal, setOpenModal] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState<any>(
    "Successfully updated report configurations"
  );
  const [selectedSiteId, setSelectedSiteId] = React.useState<number | null>(
    null
  );
  const [openToast, setOpenToast] = React.useState(false);
  const [reportType, setReportType] = React.useState<
    | "site_activity_consolidated"
    | "site_activity"
    | "enhanced_camera_health_report"
    | "site_activity_group"
    | "idle_camera_report"
    | ""
  >("site_activity");
  const [scheduleReport, setScheduleReport] = React.useState("demand");
  const [escalatedEvents, setescalatedEvents] = React.useState(true);
  const [inc_csv, setIncCSV] = React.useState(true);
  const [inc_subs, setIncSubs] = React.useState(true);
  const [day, setDay] = React.useState({
    sun: false,
    mon: false,
    tue: false,
    wed: true,
    thur: false,
    fri: false,
    sat: false,
  });

  const [siteIds, setSiteIds] = React.useState<number[]>([]);
  const [productNames, setProductNames] = React.useState<
    { name: string; id: number }[]
  >([]);

  const [selectAll, setSelectAll] = React.useState(false);
  const [unSelectAll, setUnSelectAll] = React.useState(false);
  const [sitePushedIds, setSitePushedIds] = React.useState<number[] | any[]>(
    []
  );

  const timing = ["daily", "monthly", "weekly"];
  const report_type = ["site activity report", "site health report"];

  const [reportgroupsForWholeTree, setReportGroupsForWholeTree] = useState<
    ReportGroup[] | undefined
  >(undefined);

  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState<number | string>(3);

  const [reportGroupNames, setReportGroupNames] = React.useState<
    string[] | any
  >([]);

  const handleSubs = (include: boolean) => {
    setIncSubs((prev) => include);
  };

  // CAM HEALTH USE_EFFECT
  useEffect(() => {
    if (reportType === "enhanced_camera_health_report") {
      setCamHealth("on");
      setSelectedPeriodValue("daily");
      setOpenCamHealthModal(true);
    }
    if (reportType !== "enhanced_camera_health_report") {
      setCamHealth("off");
    }
    if (
      reportType === "site_activity_group" ||
      reportType === "idle_camera_report"
    ) {
      setProductSwitch(true);
    }
    if (
      reportType !== "site_activity_group" &&
      reportType !== "idle_camera_report"
    ) {
      setProductSwitch(false);
    }
  }, [reportType]);

  useEffect(() => {
    const fetchData = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      setLoading(true);
      if (token) {
        const reportgroupsforwholetree =
          await appStore?.functionService.getReportGroups(
            token,
            appStore?.authService.getEntryOrg()?.id!
          );
        if (reportgroupsforwholetree) {
          const list = reportgroupsforwholetree.sort(
            (a: ReportGroup, b: ReportGroup): number => {
              if (b.name.toLowerCase() > a.name.toLowerCase()) {
                return -1;
              }
              if (b.name.toLowerCase() < a.name.toLowerCase()) {
                return 1;
              }
              return 0;
            }
          );

          setReportGroupsForWholeTree(list);
        }
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  // STYLING SECTION
  const classer = useStyles();
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        "&:hover": {
          backgroundColor: "#e3f2fd",
        },
      },
    })
  )(TableRow);

  const handleToastClose = () => {
    setMessage("");
    setErr(false);
    setOpenToast(false);
  };

  const handleChangeAuto = (value: any) => {
    if (!value) {
      return setGroupId(-1);
    }
    setSiteIds([]);
    setReportGroupNames([]);
    setSelectedSite("select site");
    setSelectedSiteId(null);
    const new_group_id = +value.org_id;
    setGroupId(new_group_id);
    let group = orgslist.find((x: any) => x.org_id === value.org_id);
    if (group) {
      let tree = group.org_tree_names!.map((item: any) => `   /   ${item} `);
      let treeString = tree.toString();
      const rpl = treeString.replace(/,/g, "");
      // setGroupTreeName(rpl);

      setGroupName(group.org_name);
    }
  };

  const classes = useStyles();

  const siteIdsHandler = (
    id: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let status = event.target.checked;
    let newItem = id;
    if (status) {
      siteIds.push(newItem);
    } else {
      const index = siteIds.indexOf(id);
      if (index > -1) {
        siteIds.splice(index, 1);
      }
    }
  };

  // Define the modal message for the enhanced camera health report.
  const timeWarningMessage = `Please note: The report takes 30 minutes to gather data. You cannot set a report to run within the next 30 minutes. However, you can select a time 30 minutes from now or later, and it will run at that time. If you choose a time earlier than now, the report will be generated tomorrow at that time.`;

  // Function to check if the selected time is within the next 30 minutes
  const isTimeWithinNext30Minutes = (
    selectedHour: string,
    selectedMinute: string
  ) => {
    const now = new Date();
    const selectedTime = new Date();
    selectedTime.setHours(Number(selectedHour), Number(selectedMinute), 0, 0);

    const timeDifference = (selectedTime.getTime() - now.getTime()) / 1000 / 60; // difference in minutes

    // If selected time is within 30 minutes from now but after the current time
    return timeDifference > 0 && timeDifference < 30;
  }; // Call this function when the user selects a time for the enhanced_camera_health_report

  const onSubmitHandler = () => {
    let configs: any = {
      include_escalated_events: escalatedEvents,
      include_csv: inc_csv,
    };

    if (selectedReport === -1) {
      setOpenModal(true);
      setModalMessage("Please select report group first");
      setSiteIds([]);
      return;
    }

    if (
      siteIds.length === 0 &&
      reportType !== "site_activity_group" &&
      reportType !== "idle_camera_report"
    ) {
      setOpenModal(true);
      setModalMessage("Please select at least one site");
      return;
    }

    if (reportType === "enhanced_camera_health_report") {
      if (
        isTimeWithinNext30Minutes(
          selectedHour as string,
          selectedMinutes as string
        )
      ) {
        setOpenModal(true);
        setModalMessage(timeWarningMessage);
        return;
      } else {
        // Proceed
        if (
          selectedPeriodValue.includes("daily") &&
          reportType === "enhanced_camera_health_report"
        ) {
          configs = {
            time: `${selectedHour}:${selectedMinutes}`,
            include_escalated_events: escalatedEvents,
            include_csv: inc_csv,
          };
        }
      }
    }

    if (
      selectedPeriodValue.includes("weekly") &&
      reportType === "site_activity"
    ) {
      configs = {
        time: `${selectedHour}:${selectedMinutes}`,
        isoweekday: selectedDate,
        include_escalated_events: escalatedEvents,
        include_csv: inc_csv,
      };
    }

    if (
      selectedPeriodValue.includes("daily") &&
      reportType === "site_activity"
    ) {
      configs = {
        time: `${selectedHour}:${selectedMinutes}`,
        include_escalated_events: escalatedEvents,
        include_csv: inc_csv,
      };
    }

    if (
      selectedPeriodValue.includes("weekly") &&
      reportType === "idle_camera_report"
    ) {
      configs = {
        time: `${selectedHour}:${selectedMinutes}`,
        isoweekday: selectedDate,
        include_escalated_events: escalatedEvents,
        include_csv: inc_csv,
      };
    }
    if (
      selectedPeriodValue.includes("weekly") &&
      reportType === "site_activity_group"
    ) {
      configs = {
        time: `${selectedHour}:${selectedMinutes}`,
        isoweekday: selectedDate,
        include_escalated_events: escalatedEvents,
        include_csv: inc_csv,
      };
    }

    if (
      selectedPeriodValue.includes("daily") &&
      reportType === "site_activity_group"
    ) {
      configs = {
        time: `${selectedHour}:${selectedMinutes}`,
        include_escalated_events: escalatedEvents,
        include_csv: inc_csv,
      };
    }

    if (
      selectedPeriodValue.includes("weekly") &&
      reportType === "site_activity_consolidated"
    ) {
      configs = {
        time: `${selectedHour}:${selectedMinutes}`,
        isoweekday: selectedDate,
        include_escalated_events: escalatedEvents,
        include_csv: inc_csv,
      };
    }

    if (
      selectedPeriodValue.includes("daily") &&
      reportType === "site_activity_consolidated"
    ) {
      configs = {
        time: `${selectedHour}:${selectedMinutes}`,
        include_escalated_events: escalatedEvents,
        include_csv: inc_csv,
      };
    }

    // CAMERA HEALTH TIME LOGIC
    if (
      selectedPeriodValue.includes("daily") &&
      reportType === "enhanced_camera_health_report"
    ) {
      configs = {
        time: `${selectedHour}:${selectedMinutes}`,
        include_escalated_events: escalatedEvents,
        include_csv: inc_csv,
      };
    }

    addReport(configs);
  };

  const handleTypeOfReport = (value: unknown) => {
    if (value === "site_activity") {
      setReportType("site_activity");
    }
    if (value === "site_activity_group") {
      setReportType("site_activity_group");
    }
    if (value === "idle_camera_report") {
      setReportType("idle_camera_report");
      setSelectedPeriodValue("weekly");
    }
    if (value === "site_activity_consolidated") {
      setReportType("site_activity_consolidated");
    }
    if (value === "enhanced_camera_health_report") {
      setReportType("enhanced_camera_health_report");
    }
  };

  const handleChangeDay = (day: string) => {
    let default_day = {
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thur: false,
      fri: false,
      sat: false,
    };

    if (day === "sun") {
      default_day.sun = true;
      setDay(default_day);
      setSelectedDate(7);
    }
    if (day === "mon") {
      default_day.mon = true;
      setDay(default_day);
      setSelectedDate(1);
    }
    if (day === "tue") {
      default_day.tue = true;
      setDay(default_day);
      setSelectedDate(2);
    }
    if (day === "wed") {
      default_day.wed = true;
      setDay(default_day);
      setSelectedDate(3);
    }
    if (day === "thur") {
      default_day.thur = true;
      setDay(default_day);
      setSelectedDate(4);
    }
    if (day === "fri") {
      default_day.fri = true;
      setDay(default_day);
      setSelectedDate(5);
    }
    if (day === "sat") {
      default_day.sat = true;
      setDay(default_day);
      setSelectedDate(6);
    }
  };

  const handlePeriodChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    if (e.target.value === "daily") {
      setSelectedPeriodValue("daily");
    }

    if (e.target.value === "weekly") {
      setSelectedPeriodValue("weekly");
    }

    if (e.target.value === "monthly") {
      setSelectedPeriodValue("monthly");
    }
  };

  const handleOptionsChange = (value: string) => {
    if (value === "schedule") {
      setScheduleReport(value);
    }

    if (value === "demand") {
      setScheduleReport(value);
    }
  };

  //Multiple Selection method
  const handleReportGroupChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setIsEditable(true);
    setReportGroupNames(event.target.value as string[]);
  };

  const fetchOrgsData = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    if (token) {
      try {
        const request = JSON.stringify({
          enabled: true,
          paginate: false,
          verbose: false,
          incl_tree_names: true,
        });

        const res = fetch(baseUrl + "/list_orgs", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = await (await res).json();
        const orglist = result;
        if (orglist && orglist.success) {
          let orgz = orglist?.orgs;
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names - b.org_tree_names;
          });
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names.length - b.org_tree_names.length;
          });
          setOrgsList(orgz);
          setLoading(false);
        }
      } catch (err) {
        return err;
      }
    }
  };

  const fetchProductNames = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    if (token) {
      try {
        const request = JSON.stringify({
          top_org_id: groupid,
          top_org_only: false,
          enabled: true,
        });

        const res = fetch(baseUrl + "/list_site_products", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = await (await res).json();
        if (result && result.success) {
          setProductNames(result.public_product_names);
          setLoading(false);
        }
      } catch (err) {
        return err;
      }
    }
  };

  const selectAllHandler = () => {
    setSiteIds(sitePushedIds);
    setSelectAll(true);
    setUnSelectAll(false);
  };

  //Unselect All Function
  const unSelectAllHandler = () => {
    setUnSelectAll(true);
    setSelectAll(false);
    setSiteIds([]);
  };

  //************* Main Methods *************/
  // Fetch Organizations
  useEffect(() => {
    fetchOrgsData();
  }, [groupid, open_add]);

  // Fetch sites
  useEffect(() => {
    const fetchData = async () => {
      setLoadingSites(true);
      setSiteIds([]);
      var token = await appStore?.authService.getAuthorisedToken();
      var baseUrl = getBaseUrl();
      if (token) {
        try {
          const request = JSON.stringify({
            enabled: true,
            top_org_id: groupid,
            paginate: false,
            top_org_only: !inc_subs,
            product_name_id:
              reportType === "enhanced_camera_health_report"
                ? null
                : selectedProductId,
          });

          const res = fetch(baseUrl + "/list_sites", {
            method: "post",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: request,
          });
          const result = await (await res).json();
          if (result && result.success) {
            let sites: Site[] = result?.sites;
            sites.sort(function (a: Site, b: Site) {
              if (b.site_name.toLowerCase() > a.site_name.toLowerCase()) {
                return -1;
              }
              if (b.site_name.toLowerCase() < a.site_name.toLowerCase()) {
                return 1;
              }
              return 0;
            });

            setSiteList(sites);
            let pushids: number[] = [];
            sites.forEach((site: Site) => {
              pushids.push(site.site_id);
            });

            if (!selectAll && unSelectAll) {
              setSiteIds([]);
            }
            if (selectAll && !unSelectAll) {
              setSiteIds(pushids);
            }
            setSitePushedIds(pushids);
          }
          setLoadingSites(false);
        } catch (err) {
          return err;
        } finally {
          setLoadingSites(false);
        }
      }
    };
    fetchData();
  }, [groupid, selectedProductId, inc_subs, reportType]);

  //Table Content Setup.
  var sites_display: React.ReactNode[] = [];
  if (siteList && siteList.length > 0)
    siteList.forEach((site: { [key: string]: any }, index: any) => {
      let group = orgslist.find((x: any) => x.org_id === site.org_id);
      let rpl;
      if (group) {
        let tree = group.org_tree_names!.map((item: any) => `   /   ${item} `);
        let treeString = tree.toString();
        rpl = treeString.replace(/,/g, "").replace("/", "");
      }
      sites_display.push(
        <StyledTableRow>
          <TableCell>
            <Typography className={classes.bold_text}>
              {site.site_id}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>{rpl}</Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {site.site_name}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {site.public_product_name}
            </Typography>
          </TableCell>
          <TableCell>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Tooltip
                placement="bottom"
                title="select site for scheduled reports"
              >
                <Grid item>
                  <AntSwitch
                    defaultChecked={selectAll && !unSelectAll ? true : false}
                    onChange={(e) => {
                      siteIdsHandler(site.site_id, e);
                    }}
                    name="checkedC"
                  />
                </Grid>
              </Tooltip>
            </Grid>
          </TableCell>
        </StyledTableRow>
      );
    });

  //Table Content Setup
  var reports_display: React.ReactNode[] = [];
  if (siteList && siteList.length > 0) {
    siteList.forEach((site: { [key: string]: any }, index: any) => {
      reports_display.push(
        <StyledTableRow>
          <TableCell>
            <Typography className={classes.bold_text}>
              {site.site_id}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {selectedSite}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {report_type[Math.floor(Math.random() * 2)]}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {timing[Math.floor(Math.random() * 3)]}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>
                  <span
                    onClick={(e) => {
                      setOpenEdit(true);
                    }}
                  >
                    <EditIcon />
                  </span>{" "}
                  &nbsp;
                </Grid>
              </Grid>
            </Typography>
          </TableCell>
          <TableCell>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Tooltip placement="bottom" title="click to delete">
                <Grid item>
                  <DeleteIcon
                    style={{
                      color: "red",
                      alignSelf: "center",
                      marginLeft: 20,
                    }}
                  />
                </Grid>
              </Tooltip>
            </Grid>
          </TableCell>
        </StyledTableRow>
      );
    });
  }

  const handleClose = () => {
    setAddOpenToClose();
  };

  const addReport = async (configs: any) => {
    setLoading(true);
    var baseUrl = getBaseUrl();
    var token = await appStore?.authService.getAuthorisedToken();
    setMessage("");
    if (token) {
      try {
        const request = JSON.stringify({
          report_group_id: selectedReport,
          site_ids: ArrayToStringifiedArray(siteIds),
          report_type: reportType + ":" + selectedPeriodValue,
          config: configs,
          timezone_str: zone,
          org_id: groupid,
          meta: {
            product_name:
              reportType === "enhanced_camera_health_report" ||
              reportType === "site_activity_group" ||
              reportType === "idle_camera_report"
                ? -1
                : selectedProductId,
          },
        });

        const res = await fetch(baseUrl + "/site_report", {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        if (!res.ok) {
          setErr(true);
          setMessage("Something went wrong, please try again");
        }
        const result = (await (await res).json()) as any;
        const msg = result.msg;
        if (result.success) {
          setErr(false);
        }

        if (!result.success) {
          setMessage(msg);
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
      reloadOrgs(groupid);
      setAddOpenToClose();
    }
  };

  useEffect(() => {
    fetchProductNames();
  }, []);

  return (
    <div>
      <Dialog
        fullWidth={false}
        maxWidth={maxWidth}
        open={open_add}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        {!loading && (
          <>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <DialogContent>
                <div>
                  <CustomModal
                    closeHandler={() => {
                      setOpenModal(false);
                    }}
                    open={openModal}
                  >
                    <p className={classes.bold_text}>
                      {modalMessage} &nbsp;&nbsp;
                    </p>
                  </CustomModal>
                  <div>
                    <h3
                      className={classes.bold_text}
                      style={{
                        textAlign: "center",
                        fontSize: 16,
                        textTransform: "capitalize",
                        marginBottom: 20,
                      }}
                    >
                      <>Schedule Reports</>
                    </h3>
                    <Divider style={{ marginBottom: 20 }} />
                    {message && <div style={{ marginTop: 30 }} />}
                    <Collapse in={openToast}>
                      {message && (
                        <div style={{ marginBottom: 35 }}>
                          <Alert
                            severity={err ? "error" : "success"}
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  handleToastClose();
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {message}
                          </Alert>
                        </div>
                      )}
                    </Collapse>
                    {!loading && !message && (
                      <>
                        <div style={{ marginLeft: 30, display: "flex" }}>
                          <div>
                            <FormControl
                              className={classes.form_component_medium}
                              variant="outlined"
                              fullWidth={false}
                            >
                              <div
                                style={{
                                  width: 250,
                                  color: "#15325F",
                                  marginRight: 150,
                                }}
                              >
                                <h2 className={classes.header_text}>
                                  Type of Report:
                                </h2>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: 250,
                                      color: "#15325F",
                                      marginLeft: 10,
                                    }}
                                  >
                                    <FormControl fullWidth>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={reportType}
                                        label="Report Type"
                                        onChange={(e) =>
                                          handleTypeOfReport(e.target.value)
                                        }
                                      >
                                        <MenuItem value={"site_activity_group"}>
                                          site activity group
                                        </MenuItem>
                                        <MenuItem value={"idle_camera_report"}>
                                          idle camera report
                                        </MenuItem>
                                        <MenuItem value={"site_activity"}>
                                          site activity individual
                                        </MenuItem>
                                        <MenuItem
                                          value={"site_activity_consolidated"}
                                        >
                                          site activity consolidated
                                        </MenuItem>
                                        <MenuItem
                                          value={
                                            "enhanced_camera_health_report"
                                          }
                                        >
                                          enhanced camera health report
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </div>
                                </div>
                              </div>
                            </FormControl>
                          </div>
                          <div>
                            {reportgroupsForWholeTree &&
                              reportType !== "site_activity_group" &&
                              reportType !== "idle_camera_report" && (
                                <div
                                  style={{
                                    color: "#15325F",
                                    marginRight: 150,
                                    marginTop: 30,
                                    marginBottom: 25,
                                  }}
                                >
                                  <h2 className={classes.header_text}>
                                    Select Product Name
                                  </h2>
                                  <div
                                    style={{
                                      width: 250,
                                      color: "#15325F",
                                      marginLeft: 10,
                                    }}
                                  >
                                    <FormControl
                                      fullWidth
                                      disabled={
                                        camHealth === "on"
                                          ? true
                                          : productSwitch
                                          ? true
                                          : false
                                      }
                                    >
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Report Type"
                                        value={selectedProductId}
                                        onChange={(e) => {
                                          setSelectedProductId(e.target.value);
                                        }}
                                      >
                                        {productNames!.map((product) => (
                                          <MenuItem
                                            key={product.id}
                                            value={product.id}
                                            style={getStyles(
                                              product.name,
                                              personName,
                                              theme
                                            )}
                                          >
                                            {camHealth === "on"
                                              ? `All`
                                              : productSwitch
                                              ? ""
                                              : product.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                        {(reportType === "site_activity" ||
                          reportType === "site_activity_group" ||
                          reportType === "site_activity_consolidated") && (
                          <div
                            style={{
                              display: "flex",
                              paddingLeft: 40,
                              color: "#15325F",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                color: "#15325F",
                                marginRight: 120,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  width: 300,
                                }}
                              >
                                <FormControl>
                                  <br />
                                  <Typography className={classes.header_text}>
                                    Include CSV File
                                  </Typography>
                                  <br />
                                </FormControl>
                                <FormControl>
                                  <br />
                                  <Checkbox
                                    className={classes.form_component_checkbox}
                                    color="primary"
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checkedIcon={<CheckBoxIcon />}
                                    checked={inc_csv}
                                    onChange={(e) => {
                                      setIncCSV(e.target.checked);
                                    }}
                                  />
                                </FormControl>
                              </div>
                            </div>
                            {reportType !== "site_activity_consolidated" &&
                              reportType !== "site_activity_group" && (
                                <div
                                  style={{
                                    display: "flex",
                                    color: "#15325F",
                                    alignItems: "center",
                                    marginBottom: 30,
                                    width: 430,
                                  }}
                                >
                                  <div
                                    style={{
                                      color: "#15325F",

                                      marginTop: 30,
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                      }}
                                    >
                                      <FormControl>
                                        <br />
                                        <Typography
                                          className={classes.header_text}
                                        >
                                          Include Escalated Events
                                        </Typography>
                                        <br />
                                      </FormControl>
                                      <FormControl>
                                        <br />
                                        <Checkbox
                                          className={
                                            classes.form_component_checkbox
                                          }
                                          color="primary"
                                          icon={<CheckBoxOutlineBlankIcon />}
                                          checkedIcon={<CheckBoxIcon />}
                                          checked={escalatedEvents}
                                          onChange={(e) => {
                                            setescalatedEvents(
                                              e.target.checked
                                            );
                                          }}
                                        />
                                      </FormControl>
                                    </div>
                                  </div>
                                </div>
                              )}
                            {/* </> */}
                          </div>
                        )}
                        {reportgroupsForWholeTree && (
                          <div
                            style={{
                              display: "flex",
                              paddingLeft: 40,
                              color: "#15325F",
                              alignItems: "center",
                              marginBottom: 30,
                            }}
                          >
                            <div
                              style={{
                                color: "#15325F",
                                marginRight: 150,
                                marginTop: 30,
                                marginBottom: 25,
                              }}
                            >
                              <h2 className={classes.header_text}>
                                Select Report Group
                              </h2>
                              <div
                                style={{
                                  width: 250,
                                  color: "#15325F",
                                  marginLeft: 10,
                                }}
                              >
                                <FormControl fullWidth>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Report Type"
                                    value={selectedReport}
                                    onChange={(e) => {
                                      setSelectedReport(e.target.value);
                                    }}
                                  >
                                    {reportgroupsForWholeTree!.map((report) => (
                                      <MenuItem
                                        key={report.id}
                                        value={report.id}
                                        style={getStyles(
                                          report.name,
                                          personName,
                                          theme
                                        )}
                                      >
                                        {report.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                            <div
                              style={{
                                width: 250,
                                color: "#15325F",
                                marginRight: 150,
                                marginLeft: 10,
                              }}
                            >
                              <h2 className={classes.header_text}>
                                Send Report:
                              </h2>
                              <div
                                style={{
                                  width: 250,
                                  color: "#15325F",
                                  marginLeft: 10,
                                }}
                              >
                                <FormControl
                                  fullWidth
                                  disabled={
                                    camHealth === "on" ||
                                    reportType === "idle_camera_report"
                                      ? true
                                      : false
                                  }
                                >
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    // value={
                                    //   camHealth === "on"
                                    //     ? `daily`
                                    //     : selectedPeriodValue
                                    // }
                                    value={
                                      camHealth === "on"
                                        ? "daily"
                                        : reportType === "idle_camera_report"
                                        ? "weekly"
                                        : selectedPeriodValue
                                    }
                                    label="Age"
                                    onChange={handlePeriodChange}
                                  >
                                    <MenuItem value={"daily"}>daily</MenuItem>
                                    <MenuItem value={"weekly"}>weekly</MenuItem>
                                    <MenuItem value={"monthly"}>
                                      monthly
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          </div>
                        )}
                        <>
                          <div
                            style={{
                              paddingLeft: 40,
                              marginTop: 10,
                              color: "#15325F",
                              alignItems: "center",
                              marginBottom: 40,
                            }}
                          >
                            {selectedPeriodValue === "daily" && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{ marginTop: 40, color: "#15325F" }}
                                >
                                  <h2 className={classes.header_text}>
                                    Report Time (Hr : Min)
                                  </h2>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-around",
                                      marginRight: 100,
                                      paddingRight: 20,
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginLeft: 20,
                                        display: "flex",
                                        alignItems: "center",
                                        padding: 10,
                                      }}
                                    >
                                      <FormControl fullWidth>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={selectedHour}
                                          label="Age"
                                          onChange={(e) => {
                                            setSelectedHour(e.target.value);
                                          }}
                                        >
                                          {hrs_list.map((hr) => (
                                            <MenuItem value={hr}>{hr}</MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;
                                      <FormControl fullWidth>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={selectedMinutes}
                                          label="Age"
                                          onChange={(e) => {
                                            setSelectedMinutes(e.target.value);
                                          }}
                                        >
                                          {min_list.map((min) => (
                                            <MenuItem value={min}>
                                              {min}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div
                                    style={{
                                      color: "#15325F",
                                      marginRight: 150,
                                      marginTop: 30,
                                      marginLeft: 170,
                                    }}
                                  >
                                    <h2 className={classes.header_text}>
                                      Time Zone
                                    </h2>
                                    <div
                                      style={{
                                        width: 250,
                                        color: "#15325F",
                                        marginLeft: 10,
                                      }}
                                    >
                                      <FormControl fullWidth>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={zone}
                                          label="Report Type"
                                          onChange={(e) =>
                                            setZone(e.target.value)
                                          }
                                        >
                                          {time_zones.map((zone) => (
                                            <MenuItem value={zone}>
                                              {zone}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {selectedPeriodValue === "monthly" && (
                              <div>
                                <div>
                                  <h2 className={classes.header_text}>
                                    Time Zone
                                  </h2>
                                  <div
                                    style={{
                                      width: 250,
                                      color: "#15325F",
                                      marginLeft: 10,
                                    }}
                                  >
                                    <FormControl fullWidth>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={zone}
                                        label="Report Type"
                                        onChange={(e) =>
                                          setZone(e.target.value)
                                        }
                                      >
                                        {time_zones.map((zone) => (
                                          <MenuItem value={zone}>
                                            {zone}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </div>
                                </div>
                              </div>
                            )}

                            {selectedPeriodValue === "weekly" && (
                              <div style={{ color: "#15325F", marginTop: 50 }}>
                                <h2 className={classes.header_text}>
                                  Select end day of the week:
                                </h2>
                                <div style={{ marginLeft: 10 }}>
                                  <ButtonGroup
                                    color="primary"
                                    aria-label="outlined primary button group"
                                  >
                                    <Button
                                      variant={
                                        day.sun ? "contained" : "outlined"
                                      }
                                      onClick={() => {
                                        handleChangeDay("sun");
                                      }}
                                    >
                                      Sun
                                    </Button>
                                    <Button
                                      variant={
                                        day.mon ? "contained" : "outlined"
                                      }
                                      onClick={() => {
                                        handleChangeDay("mon");
                                      }}
                                    >
                                      Mon
                                    </Button>
                                    <Button
                                      variant={
                                        day.tue ? "contained" : "outlined"
                                      }
                                      onClick={() => {
                                        handleChangeDay("tue");
                                      }}
                                    >
                                      Tue
                                    </Button>
                                    <Button
                                      variant={
                                        day.wed ? "contained" : "outlined"
                                      }
                                      onClick={() => {
                                        handleChangeDay("wed");
                                      }}
                                    >
                                      Wed
                                    </Button>
                                    <Button
                                      variant={
                                        day.thur ? "contained" : "outlined"
                                      }
                                      onClick={() => {
                                        handleChangeDay("thur");
                                      }}
                                    >
                                      Thur
                                    </Button>
                                    <Button
                                      variant={
                                        day.fri ? "contained" : "outlined"
                                      }
                                      onClick={() => {
                                        handleChangeDay("fri");
                                      }}
                                    >
                                      Fri
                                    </Button>
                                    <Button
                                      variant={
                                        day.sat ? "contained" : "outlined"
                                      }
                                      onClick={() => {
                                        handleChangeDay("sat");
                                      }}
                                    >
                                      Sat
                                    </Button>
                                  </ButtonGroup>
                                </div>
                                {/* ED TIME OF THE DAY */}
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{ marginTop: 40, color: "#15325F" }}
                                  >
                                    <h2 className={classes.header_text}>
                                      Report Time (Hr : Min)
                                    </h2>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-around",
                                        marginRight: 100,
                                        paddingRight: 20,
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginLeft: 20,
                                          display: "flex",
                                          alignItems: "center",
                                          padding: 10,
                                        }}
                                      >
                                        <FormControl fullWidth>
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedHour}
                                            label="Age"
                                            onChange={(e) => {
                                              setSelectedHour(e.target.value);
                                            }}
                                          >
                                            {hrs_list.map((hr) => (
                                              <MenuItem value={hr}>
                                                {hr}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                        &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;
                                        <FormControl fullWidth>
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedMinutes}
                                            label="Age"
                                            onChange={(e) => {
                                              setSelectedMinutes(
                                                e.target.value
                                              );
                                            }}
                                          >
                                            {min_list.map((min) => (
                                              <MenuItem value={min}>
                                                {min}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div
                                      style={{
                                        color: "#15325F",
                                        marginRight: 150,
                                        marginTop: 30,
                                        marginLeft: 30,
                                      }}
                                    >
                                      <h2 className={classes.header_text}>
                                        Time Zone
                                      </h2>
                                      <div
                                        style={{
                                          width: 250,
                                          color: "#15325F",
                                          marginLeft: 10,
                                        }}
                                      >
                                        <FormControl fullWidth>
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={zone}
                                            label="Report Type"
                                            onChange={(e) =>
                                              setZone(e.target.value)
                                            }
                                          >
                                            {time_zones.map((zone) => (
                                              <MenuItem value={zone}>
                                                {zone}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                        {/* include esc events */}
                        <Divider variant="middle" style={{ margin: 20 }} />
                        <FormControl
                          className={classes.form_component_medium}
                          variant="outlined"
                          fullWidth={false}
                        >
                          <div
                            style={{
                              marginLeft: 40,
                              display: "flex",
                              justifyContent: "space-between",
                              width: 900,
                            }}
                          >
                            <div style={{}}>
                              <h2
                                className={classes.bold_text}
                                style={{ marginLeft: 10 }}
                              >
                                Select site(s) for the report:
                              </h2>
                              <CustomCheckBox
                                title={"Include sub-groups"}
                                enabled={inc_subs}
                                handleChange={handleSubs}
                              />
                            </div>
                            <div
                              className={classes.button_container_small3}
                              style={{ marginLeft: 40 }}
                            >
                              <Button
                                style={{
                                  display: productSwitch ? "none" : "block",
                                }}
                                variant="contained"
                                className={`${classes.cancel_button2} {$classes.bold_text}`}
                                onClick={unSelectAllHandler}
                              >
                                unSelect All
                              </Button>
                              <Button
                                style={{
                                  display: productSwitch ? "none" : "block",
                                }}
                                variant="contained"
                                className={classes.mute_button}
                                onClick={selectAllHandler}
                              >
                                Select All
                              </Button>
                            </div>
                          </div>
                          <br />
                        </FormControl>
                        {/* TABLE CONTENT */}
                        {selectedProductId !== -1 && !productSwitch ? (
                          <div
                            style={{
                              boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                              borderRadius: 15,
                              minHeight: "fit-content",
                              maxHeight: 250,
                              overflowY: "scroll",
                              backgroundColor: "white",
                              marginLeft: 50,
                              marginRight: 20,
                              marginBottom: 35,
                              width: 850,
                              display: "flex",
                            }}
                          >
                            {!loadingSites ? (
                              <TableContainer component={Paper}>
                                <Table size="small" stickyHeader>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>
                                        <span
                                          style={{
                                            fontSize: 13,
                                            color: "#616161",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Site_ID
                                        </span>
                                      </TableCell>
                                      <TableCell>
                                        <span
                                          style={{
                                            fontSize: 13,
                                            color: "#616161",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Group
                                        </span>
                                      </TableCell>
                                      <TableCell>
                                        <span
                                          style={{
                                            fontSize: 13,
                                            color: "#616161",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Site Name
                                        </span>
                                      </TableCell>
                                      <TableCell>
                                        <span
                                          style={{
                                            fontSize: 13,
                                            color: "#616161",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Product
                                        </span>
                                      </TableCell>
                                      <TableCell>
                                        <span
                                          style={{
                                            fontSize: 13,
                                            color: "#616161",
                                            fontWeight: "bold",
                                            display: "flex",
                                          }}
                                        >
                                          Select
                                        </span>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {!loadingSites && sites_display.length > 0
                                      ? sites_display
                                      : sites_display.length === 0 && (
                                          <>
                                            {productNames.findIndex(
                                              (product) =>
                                                product.id == selectedProductId
                                            ) < 0 ? (
                                              <p
                                                className={classes.bold_text}
                                                style={{
                                                  textAlign: "center",
                                                  margin: 70,
                                                }}
                                              >
                                                Please select product name
                                              </p>
                                            ) : (
                                              <p
                                                className={classes.bold_text}
                                                style={{
                                                  textAlign: "center",
                                                  margin: 40,
                                                }}
                                              >
                                                No sites found for the selected
                                                product name or organisation
                                              </p>
                                            )}
                                          </>
                                        )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            ) : (
                              <div
                                style={{
                                  alignSelf: "center",
                                  marginLeft: 400,
                                  padding: 10,
                                }}
                              >
                                <Spinner />
                              </div>
                            )}
                            {""}
                          </div>
                        ) : (
                          <p
                            className={classes.bold_text}
                            style={{
                              textAlign: "center",
                              margin: 40,
                              display: productSwitch ? "none" : "block",
                            }}
                          >
                            Please select product name above to view sites
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <div
                  style={{
                    marginRight: 10,
                    transform: `${
                      productSwitch ? "translate(0,-30px)" : "translate(0,0)"
                    }`,
                  }}
                >
                  <Button
                    onClick={handleClose}
                    color="primary"
                    className={classes.cancel_button2}
                  >
                    Close
                  </Button>
                  <Button
                    onClick={onSubmitHandler}
                    color="primary"
                    className={classes.mute_button}
                  >
                    Submit
                  </Button>
                </div>
              </DialogActions>
            </div>
          </>
        )}
        {loading && (
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            {" "}
            <LoadingSpinner />{" "}
          </div>
        )}
      </Dialog>
      {openCamHealthModal === true && (
        <BasicModal
          openCloseVar={openCamHealthModal}
          openCloseModal={setOpenCamHealthModal}
        ></BasicModal>
      )}
    </div>
  );
};

export default ReportAdd;
