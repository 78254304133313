import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "../../styling/global/global";

interface NewPasswordProps {
  success: boolean;
  edge_enhanced_smtp_server: string;
  edge_enhanced_smtp_server_port: string;
  smtp_password: string;
}
interface SMTPChangePasswordProps {
  open: boolean;
  newPassword: NewPasswordProps;
  onClose: () => void;
}
const SMTPChangePassword: React.FC<SMTPChangePasswordProps> = ({
  newPassword,
  open,
  onClose,
}: SMTPChangePasswordProps) => {
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className="edit-dialog"
    >
      <DialogTitle id="simple-dialog-title">SMTP Password Updated</DialogTitle>
      {/* <Typography className={classes.bold_text}>
        Successful:Please see camera details for connection parameters.
      </Typography> */}

      <div className={classes.singleOkButton}>
        <Button variant="contained" className="save" onClick={handleClose}>
          OK
        </Button>
      </div>
    </Dialog>
  );
};

export default SMTPChangePassword;
