import React, { useState, createContext, useEffect } from "react";
import { ErrorBoundary } from "../../../core/ErrorBoundary";
import { TopNavBar } from "../../components/TopNavBar/TopNavBar";
import { BrowserRouter } from "react-router-dom";
import MainDrawer from "../MainDrawer/MainDrawer";
import { AppStore } from "../../../stores/AppStore";
import { Org, MainMenu } from "../../../services/FunctionService";
import { TimeEntrySet, TimeEntry } from "../../../core/models/TimeEntry";

interface MockEvent {
  (event: Event): any;
}

interface HomePageProps {
  appStore?: AppStore;
  authhandler: MockEvent;
  changeOrg: (d: any) => void;
  currentOrg: Org | undefined;
}

class impMainMenu implements MainMenu {
  rulesets: boolean = false;
  usereditor: boolean = false;
  navigator: boolean = true;
  reports: boolean = false;
  dashboard: boolean = false;
  sites: boolean = false;
  profile: boolean = false;
  billing: boolean = false;
  site_reports: boolean = false;
  tests: boolean = false;
  insights: boolean = false;
  search: boolean = false;
  review: boolean = false;
  review3: boolean = false;
  alpr: boolean = false;
  bi: boolean = false;
}

const rulesList: any[] = [];

export const MainContext = createContext({
  rule_environment: "",
  rule_type: "",
  alertType: "",
  rule_name: "",
  last_rule_id: "",
  rule_time: "",
  startTime: "",
  endTime: "",
  channelId: "" as any,
  selectedDays: [] as any[],
  zone_config: [] as any[],
  savedZones: "",
  rulesList: [] as any[],
  scheduled: true,
  enableAdvancedRules: false,
  ruleSnapshotId: 0,
  snapshot: { stringb64: "", height: 480, width: 640 },
  scaleFactor: 1,
  zoneOptions: [] as any[],
  timeEntrySet: undefined as any,
  appStore: {} as AppStore | undefined,
  timeZone: "" as any,
  whitelistName: {} as any | undefined,
  endpointIds: [] as any[],
  fetchInterval: "5 min",

  setFetchInterval: (e: string | number) => {},
  setWhitelistName: (e: any) => {},
  setRuleEnvironment: (e: string) => {},
  setLastRuleId: (e: any) => {},
  setRuleType: (e: string) => {},
  setAlertType: (e: string) => {},
  setRuleSnapshotId: (e: any) => {},
  setRuleName: (e: string) => {},
  setRuleTime: (e: string) => {},
  setEndpointIds: (e: string[]) => {},
  setZoneConfig: (e: any) => {},
  setSavedZones: (e: any) => {},
  setRuleList: (e: any) => {},
  setEnableAdvancedRules: (e: any) => {},
  setSelectedDays: (e: any) => {},
  setStartTime: (e: any) => {},
  setEndTime: (e: any) => {},
  setSnapshot: (e: any) => {},
  setScaleFactor: (e: any) => {},
  setZoneOptions: (e: any) => {},
  setChannelId: (e: any) => {},
  setTimeEntrySet: (e: TimeEntrySet | null) => {},
  setTimeZone: (e: any) => {},

  showGraph: false,
  setShowGraph: (e: boolean) => {},
  clearRulesState: () => {},
  setScheduled: (e: any) => {},
  formattedTime: (e: TimeEntrySet): any => {},
});

const HomePage: React.FC<HomePageProps> = ({
  appStore,
  authhandler,
  changeOrg,
  currentOrg,
}: HomePageProps) => {
  const [mainMenu, setMainMenu] = React.useState(new impMainMenu());
  const [showGraph, setShowGraph] = React.useState(false);
  const [refresh, RefreshNow] = React.useState(0);
  const [ruleSnapshotId, setRuleSnapshotId] = React.useState(0);
  const [lastRuleId, setLastRuleId] = React.useState<any>("");
  const [fetchInterval, setFetchInterval] = React.useState<any>("5 min");
  // const [alertType, setAlertType] = React.useState<any>("Analytics");
  const [alertType, setAlertType] = React.useState<any>("");
  const [openSearch, setOpenSearch] = useState(false);
  const [openAjax, setOpenAjax] = useState(false);
  const [scheduled, setScheduled] = useState(false);
  const [timeEntrySet, setTimeEntrySet] = React.useState<TimeEntrySet | null>(
    null
  );
  const [enableAdvancedRules, setEnableAdvancedRules] = useState(false);
  const [snapshot, setSnapshot] = useState({
    stringb64: "",
    height: 480,
    width: 640,
  });
  const [scaleFactor, setScaleFactor] = useState(1);

  //Advanced Rules Const
  const [ruleEnvironment, setRuleEnvironment] = useState("None");
  const [ruleType, setRuleType] = useState("");
  // const [ruleType, setRuleType] = useState("alert_zones");
  const [ruleName, setRuleName] = useState("");
  const [zoneOptions, setZoneOptions] = useState<any[] | []>(["full image"]);
  const [ruleTime, setRuleTime] = useState("");
  const [channelId, setChannelId] = useState("");
  const [startTime, setStartTime] = useState("00:00:00");
  const [endTime, setEndTime] = useState("24:00:00");
  const [zoneConfig, setZoneConfig] = useState<any[] | []>([]);
  const [savedZones, setSavedZones] = useState<any>("");
  const [selectedDays, setSelectedDays] = useState<any[] | []>([
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun",
  ]);
  const [ruleList, setRuleList] = useState<any[] | []>(rulesList);
  const [timeZone, setTimeZone] = useState<any>();
  const [whitelistName, setWhitelistName] = useState<any>(undefined);
  const [endpointIds, setEndpointIds] = useState<string[]>([]);

  const changeMainMenu = (newMainMenu: MainMenu) => {
    setMainMenu(newMainMenu);
    RefreshNow(new Date().getTime());
  };

  const clearRulesState = () => {
    setZoneConfig([]);
    setRuleList([]);
    setRuleName("");
    setChannelId("");
    setEndpointIds([]);
    setRuleType("");
    setAlertType("");
    setScheduled(false);
    setStartTime("00:00:00");
    setEndTime("24:00:00");
    setSelectedDays(["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]);
    setTimeZone("UTC");
    setTimeEntrySet(null);
    setWhitelistName(undefined);
  };

  const graphHandler = (e: boolean) => {
    setShowGraph(e);
  };

  const formattedTime = (time: TimeEntrySet): string => {
    if (!time) return "";

    var valid_days_and_times1 = "[";
    if (time) {
      time.entries.forEach((timeEntry) => {
        var thisentry =
          valid_days_and_times1 == "["
            ? timeEntry.timeString
            : "," + timeEntry.timeString;
        valid_days_and_times1 = valid_days_and_times1 + thisentry;
      });
    }
    return valid_days_and_times1 + "]";
  };

  //create all roles in the helper
  appStore?.helpers.intialiseRoleStatus();

  (window as any).userGuiding.identify(
    `${appStore?.authService.getUsername()}`,
    {
      full_name: appStore?.authService.getFirstLastname,
      entry_org_name: `${appStore?.authService.getEntryOrg()?.name}`,
      entry_org_id: `${appStore?.authService.getEntryOrg()?.id}`,
    }
  );
  return (
    <BrowserRouter>
      <MainContext.Provider
        value={{
          showGraph: showGraph,
          rule_environment: ruleEnvironment,
          rule_type: ruleType,
          rule_name: ruleName,
          rule_time: ruleTime,
          zone_config: zoneConfig,
          rulesList: ruleList,
          scheduled: scheduled,
          enableAdvancedRules,
          selectedDays,
          startTime,
          endTime,
          savedZones,
          snapshot,
          scaleFactor,
          ruleSnapshotId,
          zoneOptions,
          channelId,
          timeEntrySet,
          appStore,
          last_rule_id: lastRuleId,
          timeZone: timeZone,
          whitelistName,
          endpointIds,
          fetchInterval,
          alertType,
          setAlertType,
          setFetchInterval,
          setLastRuleId: setLastRuleId,
          setEndpointIds,
          setChannelId,
          setShowGraph: graphHandler,
          setScheduled: setScheduled,
          setRuleEnvironment,
          setRuleType,
          setRuleName,
          setRuleTime,
          setZoneConfig,
          setRuleList,
          setEnableAdvancedRules,
          clearRulesState,
          setSelectedDays,
          setStartTime,
          setEndTime,
          setSavedZones,
          setSnapshot,
          setScaleFactor,
          setRuleSnapshotId,
          setZoneOptions,
          formattedTime,
          setTimeEntrySet,
          setTimeZone,
          setWhitelistName,
        }}
      >
        <ErrorBoundary>
          {refresh}
          <TopNavBar
            appStore={appStore}
            authhandler={authhandler}
            currentOrg={currentOrg!}
            changeOrg={(d: Org) => changeOrg(d)}
            changeMainMenu={changeMainMenu}
            mainMenu={mainMenu}
            openSearch={() => setOpenSearch(true)}
            searchState={openSearch}
            openAjax={() => setOpenAjax(true)}
          ></TopNavBar>
          <MainDrawer
            appStore={appStore}
            authhandler={authhandler}
            setCurrentOrg={(d: Org) => changeOrg(d)}
            currentOrg={currentOrg!}
            mainMenu={mainMenu}
            changeMainMenu={changeMainMenu}
            search={openSearch}
            closeSearch={() => setOpenSearch(false)}
            ajax={openAjax}
            closeAjax={() => setOpenAjax(false)}
          ></MainDrawer>
        </ErrorBoundary>
      </MainContext.Provider>
    </BrowserRouter>
  );
};

export default HomePage;
