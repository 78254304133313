import React from "react";
import classes from "../../UI/ui.module.css";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CustomModal from "../../UI/CustomModal";
import { AnySrvRecord } from "dns";

interface ListItemProps {
  zone: string;
  ruleId: any;
  class_label: string;
  onDeleteHandler: (ruleId: number, id: any) => void;
  onEditHandler?: (id: any) => void;
  isEdit?: boolean;
  count?: number;
  countLogic?: number;
  object_motion?: string;
  threshold?: number;
  ruleType: string;
  rule_type?: string;
  cadence_secs?: any;
  site?: any;
  sensorDefinedRule?: any;
  whiteBg?: boolean;
}

const ZoneItem: React.FC<ListItemProps> = ({
  zone,
  class_label,
  count,
  countLogic,
  object_motion,
  threshold,
  onDeleteHandler,
  ruleId,
  isEdit,
  ruleType,
  rule_type,
  sensorDefinedRule,
  onEditHandler,
  cadence_secs,
  site,
  whiteBg,
}) => {
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState("");

  let ruleTypes: string[] = [];

  if (sensorDefinedRule?.fields?.zone_configs) {
    ruleTypes =
      sensorDefinedRule?.fields?.zone_configs.map((item: any) => item.type) ||
      [];
  }

  return (
    <div
      key={zone}
      className={classes.item}
      style={{ width: 950, background: whiteBg ? "white" : "inherit" }}
    >
      <CustomModal
        closeHandler={() => {
          setOpenDelete(false);
        }}
        open={openDelete}
        title={"confirm"}
        continueAction={true}
        onContinueHandler={() => {
          onDeleteHandler(ruleId, selectedId);
          setOpenDelete(false);
        }}
      >
        <h1
          style={{
            fontSize: 14,
            color: "#15325F",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          WARNING!
        </h1>
        <br />
        <p
          style={{
            fontSize: 14,
            color: "#f44336",
            fontWeight: "bold",
            textAlign: "center",
            marginLeft: 30,
            marginRight: 30,
          }}
        >
          Are you sure you want to delete this record ?
        </p>
      </CustomModal>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          width: "900px",
          alignContent: "center",
          paddingBottom: 0,
        }}
      >
        <p
          className={classes["item-wrapper"]}
          style={{ fontSize: 13, color: "#15325F" }}
        >
          Zone: <span style={{ color: "grey" }}>{zone}</span>
        </p>
        {
          // ["alert_zones_and_counts"].includes(ruleType) ||

          ruleTypes.includes("count_logic") && (
            <>
              <p
                className={classes["item-wrapper"]}
                style={{ fontSize: 13, color: "#15325F" }}
              >
                Count: <span style={{ color: "grey" }}>{count}</span>
              </p>
              {countLogic && (
                <p
                  className={classes["item-wrapper"]}
                  style={{ fontSize: 13, color: "#15325F" }}
                >
                  Count Logic:{" "}
                  <span style={{ color: "grey" }}>{countLogic}</span>
                </p>
              )}
            </>
          )
        }
        {
          // [
          //   "alert_zones_and_counts",
          //   "alert_zones",
          //   "PPE Detection - coming soon!",
          //   "Guard Tour",
          // ].includes(ruleType) ||

          <>
            {
              // [
              //   "alert_zones_and_counts",
              //   "alert_zones",
              //   "PPE Detection - coming soon!",
              //   "Guard Tour",
              // ].includes(ruleType) ||

              ruleTypes.includes("class_label") && (
                <p
                  className={classes["item-wrapper"]}
                  style={{ fontSize: 13, color: "#15325F" }}
                >
                  Class: <span style={{ color: "grey" }}>{class_label}</span>
                </p>
              )
            }
            {ruleTypes.includes("confidence_threshold") && threshold && (
              <p
                className={classes["item-wrapper"]}
                style={{ fontSize: 13, color: "#15325F" }}
              >
                Threshold:{" "}
                <span style={{ color: "grey" }}>
                  {isEdit ? Math.round(threshold) : Math.round(threshold)}
                </span>
              </p>
            )}
            {ruleTypes.includes("object_motion") && object_motion && (
              <p
                className={classes["item-wrapper"]}
                style={{ fontSize: 13, color: "#15325F" }}
              >
                Object Motion:{" "}
                <span style={{ color: "grey" }}>{object_motion}</span>
              </p>
            )}
          </>
        }
        {["Guard Management (no guard)"].includes(ruleType) && (
          <>
            {rule_type && (
              <p
                className={classes["item-wrapper"]}
                style={{ fontSize: 13, color: "#15325F" }}
              >
                Rule Type: <span style={{ color: "grey" }}>{rule_type}</span>
              </p>
            )}
            {/* {cadence_secs && site?.data_origin !== "sentinel_http_edge_human1" && (
              <p
                className={classes["item-wrapper"]}
                style={{ fontSize: 13, color: "#15325F" }}
              >
                Fetch interval:{" "}
                <span style={{ color: "grey" }}>{cadence_secs}</span>
              </p>
            )} */}
          </>
        )}
        <div style={{ display: "flex" }}>
          &nbsp;&nbsp;&nbsp;
          <div>
            {!isEdit && (
              <DeleteForeverIcon
                style={{ color: "#f44336", fontSize: 23, cursor: "pointer" }}
                onClick={() => {
                  setSelectedId(zone);
                  setOpenDelete(true);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZoneItem;
