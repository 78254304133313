import React, { useState, useEffect } from "react";
import { AppStore } from "../../../stores/AppStore";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import { TimeEntrySetSingle } from "../../../core/models/TimeEntrySingle";
import TimeEntryPanelSingle from "../../components/TimeEntryUI/TimeEntryPanelSingle";
import { AddCircle } from "@material-ui/icons";
import { useStyles } from "../../styling/global/global";
import Typography from "@material-ui/core/Typography";
import {
  Divider,
  IconButton,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControlLabel,
} from "@material-ui/core";
interface WeaponAdvancedProps {
  appStore?: AppStore;
  weaponconfig: { [key: string]: any };
  setWeaponConfig: (x: { [key: string]: any }) => void;
  editable: boolean;
}
const WeaponAdvanced: React.FC<WeaponAdvancedProps> = ({
  editable,
  appStore,
  weaponconfig,
  setWeaponConfig,
}: WeaponAdvancedProps) => {
  const classes = useStyles();
  const [refresh, setRefresh] = React.useState(0);
  const [message, setMessage] = React.useState<string>("");
  const handleRODIntervalChange = (event: React.ChangeEvent<any>) => {
    if (isNaN(event.target.value)) {
      setMessage("Interval seconds must be a valid number!");
    } else {
      weaponconfig.regular_object_detection_interval_secs = Number(
        event.target.value
      );
      setWeaponConfig(weaponconfig);
      setRefresh(new Date().getTime());
    }
  };
  const handleODDaysTimeChange = (newentrySet: string) => {
    weaponconfig.object_detection_days_and_exact_times = newentrySet;
    setWeaponConfig(weaponconfig);
    setRefresh(new Date().getTime());
  };
  const addODSchedule = () => {
    var timeentryset = new TimeEntrySetSingle("");
    if (weaponconfig.object_detection_days_and_exact_times) {
      timeentryset = new TimeEntrySetSingle(
        weaponconfig.object_detection_days_and_exact_times
      );
    }
    timeentryset.addTimeEntry();
    handleODDaysTimeChange(timeentryset.toString());
  };
  const handleSSDaysTimeChange = (newentrySet: string) => {
    if (weaponconfig) {
      weaponconfig.snapshot_days_and_exact_times = newentrySet;
      setWeaponConfig(weaponconfig);
      setRefresh(new Date().getTime());
    }
  };
  const addSNSchedule = () => {
    var timeentryset = new TimeEntrySetSingle("");
    if (weaponconfig.snapshot_days_and_exact_times) {
      timeentryset = new TimeEntrySetSingle(
        weaponconfig.snapshot_days_and_exact_times
      );
    }
    timeentryset.addTimeEntry();
    handleSSDaysTimeChange(timeentryset.toString());
  };

  var detections: React.ReactNode[] = [];
  if (weaponconfig && weaponconfig.object_detection_days_and_exact_times) {
    const guitimeentries = new TimeEntrySetSingle(
      weaponconfig.object_detection_days_and_exact_times
    );
    if (
      guitimeentries &&
      guitimeentries.entries &&
      guitimeentries.entries.length > 0
    ) {
      detections.push(
        <TimeEntryPanelSingle
          addicon={true}
          disabled={!editable}
          entrySet={guitimeentries}
          changeTimeEntryParent={(newentrySet: TimeEntrySetSingle) => {
            handleODDaysTimeChange(newentrySet.toString());
          }}
        ></TimeEntryPanelSingle>
      );
    } else {
      detections.push(
        <IconButton
          disabled={!editable}
          edge="end"
          aria-label="add"
          onClick={() => {
            addODSchedule();
          }}
        >
          <AddCircle color={"primary"} />
        </IconButton>
      );
    }
  } else {
    detections.push(
      <IconButton
        disabled={!editable}
        edge="end"
        aria-label="add"
        onClick={() => {
          addODSchedule();
        }}
      >
        <AddCircle color={"primary"} />
      </IconButton>
    );
  }
  return (
    <div className={classes.medium_panel}>
      <div className={classes.header}>
        <div className={classes.header_text}>Advanced Settings</div>
      </div>

      <div className={classes.modal_dialog}>
        <Typography className={classes.bold_error_text}>{message}</Typography>
        <div className={classes.form_component}>
          <div className={classes.bold_text}>
            Regular Object Detection Interval Seconds
          </div>
          <TextField
            disabled={!editable}
            label="Seconds"
            onChange={handleRODIntervalChange}
            value={weaponconfig.regular_object_detection_interval_secs}
            variant="outlined"
            margin="normal"
            fullWidth
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
          />
        </div>

        <div className={classes.form_component}>
          <div className={classes.bold_text}>
            Object Detection Days and Exact Times
          </div>
          {detections}
        </div>
      </div>
    </div>
  );
};

export default WeaponAdvanced;
