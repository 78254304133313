import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useStyles } from "../styling/global/global";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

interface Props {
  enabled: boolean;
  handleChange: (enabled: boolean) => void;
  title: string;
}

const CustomCheckBox: React.FC<Props> = ({ handleChange, enabled, title }) => {
  const classes = useStyles();
  return (
    <div>
      <FormControl>
        <Typography className={classes.header_text}>{title}</Typography>
      </FormControl>
      <FormControl>
        <Checkbox
          className={classes.form_component_checkbox}
          onChange={() => {
            handleChange(!enabled);
          }}
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
          checkedIcon={<CheckBoxIcon fontSize="large" />}
          checked={enabled}
        />
      </FormControl>
    </div>
  );
};

export default CustomCheckBox;
