import * as React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "10px",
  fontSize: "12px",
  textAlign: "center",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface modalProps {
  openCloseModal: React.Dispatch<React.SetStateAction<boolean>>;
  openCloseVar: boolean;
}

export default function BasicModal(props: modalProps) {
  const handleClose = () => props.openCloseModal(false);

  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <Modal
        open={props.openCloseVar}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ fontSize: "12px", fontWeight: "bold" }}
          >
            Please note that enabling this functionality may incur additional
            charges.
          </Typography>
          <Button
            onClick={handleClose}
            variant="contained"
            style={{
              padding: "0 20px",
              marginTop: "20px",
              color: "white",
              backgroundColor: "rgba(25, 58, 165, 0.8)",
            }}
          >
            Dismiss
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
