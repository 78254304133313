import React from "react";
import classes from "./ui.module.css";

interface ListItemProps {
  human_name: string;
  sensor_id_str: string;
  enabled: string;
  site_id: number;
  site_name: string;
}

let bold_text = {
  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontWeight: "bold",
  color: "#15325F",
  fontSize: 12,
};

const CameraListItem: React.FC<ListItemProps> = ({
  human_name,
  sensor_id_str,
  enabled,
  site_id,
  site_name,
}) => {
  return (
    <li key={sensor_id_str} className={classes.item}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p
          className={classes["item-wrapper"]}
          style={{ fontSize: 13, color: "#15325F" }}
        >
          human name: <span style={{ color: "grey" }}>{human_name}</span>
        </p>
        <p
          className={classes["item-wrapper"]}
          style={{ fontSize: 13, color: "#15325F" }}
        >
          site id: <span style={{ color: "grey" }}>{site_id}</span> | site name:{" "}
          <span style={{ color: "grey" }}>{site_name}</span> | sensor_id_str:{" "}
          <span style={{ color: "grey" }}>{sensor_id_str}</span> | enabled:{" "}
          <span style={{ color: "grey" }}>{enabled ? "true" : "false"}</span>
        </p>
      </div>
    </li>
  );
};

export default CameraListItem;
