import React from "react";
import classes from "./ui.module.css";
import Metric from "./Metric";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import WavesIcon from "@material-ui/icons/Waves";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import WatchLaterRoundedIcon from "@material-ui/icons/WatchLaterRounded";
import UpdateRoundedIcon from "@material-ui/icons/UpdateRounded";

interface Props {
  width: number;
  title: string;
  subtitle: string;
  primaryBgColor: string;
  secondaryBgColor: string;
  hours_saved: string;
  alarms: string;
  triggers: string;
  eventCount: number;
  false_alarm: number;
}

const FalseAlarms: React.FC<Props> = ({
  width,
  title,
  subtitle,
  primaryBgColor,
  secondaryBgColor,
  hours_saved,
  alarms,
  triggers,
  eventCount,
  false_alarm,
}) => {
  return (
    <div className={classes.false_alarms_wrapper}>
      <div
        className={classes.primary_component_wrapper}
        style={{ background: primaryBgColor }}
      >
        <div className={classes.dashboard_header_text}>{title}</div>
        <div className={classes.dashboard_percentage}>
          {!isNaN(false_alarm) ? false_alarm + "%" : "--"}
        </div>
      </div>
      <div
        className={classes.secondary_component_wrapper}
        style={{ background: secondaryBgColor }}
      >
        <Metric name="Alerts" value={alarms}>
          <RecordVoiceOverIcon fontSize="large" />
        </Metric>
        <Metric name="Events" value={eventCount}>
          <WavesIcon fontSize="large" />
        </Metric>
        <Metric name="Inferences" value={triggers}>
          <EqualizerIcon fontSize="large" />
        </Metric>
        <Metric name="Hrs Saved" value={hours_saved}>
          <UpdateRoundedIcon fontSize="large" />
        </Metric>
      </div>
    </div>
  );
};

export default FalseAlarms;

// background-color: #045de9;
// backgroundImage: linear-gradient(315deg, #045de9 0%, #09c6f9 74%);
