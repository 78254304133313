import React, { useState, useEffect } from "react";
import { message } from "antd";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import {
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  FormLabel,
  FormGroup,
  TextField,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { TimeEntrySet } from "../../../core/models/TimeEntry";
import TimeEntryPanel from "../../components/TimeEntryUI/TimeEntryPanel";
import { AddCircle } from "@material-ui/icons";
import { AppStore } from "../../../stores/AppStore";
import {
  Classifier,
  AlertChannel,
  Sensor,
} from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import { time_zones } from "../../../hooks/Timezones";
interface AddRuleModalProps {
  appStore?: AppStore;
  onClose: () => void;
  currentClassifier: Classifier | undefined;
  currentAlertChannel: AlertChannel;
  open: boolean;
  saveNewRule(
    alert_channel_id: number,
    class_labels: string,
    sensor_ids: string,
    valid_days_and_times: string,
    snapshot?: boolean
  ): void;
}

const AddRuleModal: React.FC<AddRuleModalProps> = ({
  onClose,
  open,
  currentAlertChannel,
  saveNewRule,
  currentClassifier,
  appStore,
}: AddRuleModalProps) => {
  const classes = useStyles();
  const [selectedclasslabels, setSelectedClassLabels] = React.useState<
    [string]
  >([""]);
  const [sensors, setSensors] = useState<Sensor[] | undefined>(undefined);
  const [selectedsensors, setSelectedSensors] = React.useState<
    { [key: string]: boolean } | undefined
  >(undefined);
  const [timeentrySet, setTimeEntrySet] = React.useState<
    TimeEntrySet | undefined
  >(undefined);
  const [refresh, setRefresh] = React.useState(0);
  const [noEndtime, setNoEndtime] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var sensors = await appStore?.functionService.getSensorsForSite(
          token,
          currentAlertChannel.site_id,
          "none"
        );
        setSensors(sensors);
      }
    };
    fetchData();
  }, [currentAlertChannel]);

  const handleSave = () => {
    selectedclasslabels.splice(selectedclasslabels.indexOf(""), 1);
    let class_labels = JSON.stringify(selectedclasslabels);
    var sensor_id_arr = [];
    if (selectedsensors) {
      for (var key in selectedsensors) {
        if (selectedsensors[key]) {
          sensor_id_arr.push(Number(key));
        }
      }
    }
    const sensor_ids = JSON.stringify(sensor_id_arr);
    var valid_days_and_times = "[";
    if (timeentrySet) {
      timeentrySet.entries.forEach((timeEntry) => {
        var thisentry =
          valid_days_and_times == "["
            ? timeEntry.timeString
            : "," + timeEntry.timeString;
        valid_days_and_times = valid_days_and_times + thisentry;
      });
    }
    valid_days_and_times = valid_days_and_times + "]";
    const isSnapshot = noEndtime ? true : false;

    if (valid_days_and_times === "[]" && isSnapshot) {
      return message.error("Please add the schedule.");
    }

    if (class_labels === "[]" && isSnapshot) {
      class_labels = JSON.stringify(["snapshot:snapshot"]);
    }

    saveNewRule(
      currentAlertChannel.alert_channel_id,
      class_labels,
      sensor_ids,
      valid_days_and_times,
      isSnapshot
    );
    handleClose();
  };

  const handleClose = () => {
    onClose();
    setSelectedSensors(undefined);
    setTimeEntrySet(undefined);
    setSelectedClassLabels([""]);
  };

  var schedule: React.ReactNode[] = [];
  const handleAlertClassChange = (event: React.ChangeEvent<any>) => {
    if (
      event.target.value.includes("snapshot:snapshot") &&
      event.target.value.length > 2
    ) {
      message.info(
        "Please note that you cannot select the snapshot class in combination with other classes."
      );
      event.target.value = selectedclasslabels;
    }

    if (event.target.value[1] == "snapshot:snapshot") {
      setNoEndtime(true);
      if (schedule.length === 1) {
        addSchedule();
      }
    } else {
      setNoEndtime(false);
    }

    setSelectedClassLabels(event.target.value);
  };
  const changeTimeEntry = (entrySet: TimeEntrySet | undefined) => {
    setTimeEntrySet(entrySet);
    //if deleted las one, make sure to add add button
    if (entrySet?.entries.length == 0) {
      setRefresh(new Date().getTime());
    }
  };
  const handleSensorSelect = (event: React.ChangeEvent<any>) => {
    selectedsensors![event.target.value] =
      !selectedsensors![event.target.value];
    setSelectedSensors(selectedsensors);
    setRefresh(new Date().getTime());
  };

  const addSchedule = () => {
    if (timeentrySet) {
      timeentrySet.addTimeEntry();
      setTimeEntrySet(timeentrySet);
    } else {
      const newtimeentrySet = new TimeEntrySet("");
      newtimeentrySet.addTimeEntry();
      setTimeEntrySet(newtimeentrySet);
    }
    setRefresh(new Date().getTime());
  };
  var class_labels: React.ReactNode[] = [];
  if (
    currentClassifier &&
    currentClassifier.class_labels &&
    currentClassifier.class_labels.length > 0
  ) {
    var class_labels_arr = currentClassifier.class_labels;
    class_labels_arr.sort();
    class_labels_arr.forEach((label) => {
      const alert_class = label.split(":")[0];
      const translate = appStore?.functionService.translate(
        currentAlertChannel.locale,
        currentClassifier.name + "_class_labels",
        alert_class
      );
      var selected = false;
      if (
        selectedclasslabels &&
        selectedclasslabels.indexOf(alert_class) > -1
      ) {
        selected = true;
      }
      class_labels.push(
        <MenuItem
          selected={selected}
          value={label}
          className={classes.bold_text}
        >
          {alert_class + " (" + translate + ")"}
        </MenuItem>
      );
    });
  }
  if (timeentrySet && timeentrySet.entries.length > 0) {
    schedule.push(
      <TextField
        value={currentAlertChannel.timezone_str}
        fullWidth={true}
        disabled={true}
        variant={"outlined"}
        label={"Time Zone"}
        margin="normal"
      ></TextField>,
      <br />
    );
    schedule.push(
      <TimeEntryPanel
        horisontal={false}
        addicon={true}
        disabled={false}
        noEndTime={noEndtime}
        entrySet={timeentrySet}
        changeTimeEntryParent={(entrySet: TimeEntrySet | undefined) =>
          changeTimeEntry(entrySet)
        }
      ></TimeEntryPanel>
    );
  } else {
    schedule.push(
      <div className={classes.form_component}>
        <div className={classes.bold_text}>Always Active</div>
        <div className={classes.bold_text}>
          Add Schedule
          <IconButton
            edge="end"
            aria-label="add"
            onClick={() => {
              addSchedule();
            }}
          >
            <AddCircle color={"primary"} />
          </IconButton>
        </div>
      </div>
    );
  }
  var sensorlist: React.ReactNode[] = [];
  var advancedSensorList: React.ReactNode[] = [];
  if (sensors && sensors.length > 0) {
    let initialselectedsensors: { [key: string]: boolean } = {};
    sensors.forEach((sensor) => {
      if (sensor.enabled && !sensor.advanced_rules_enabled) {
        const thisid = sensor.sensor_id;
        initialselectedsensors[thisid] = false;
        var checked = false;
        if (selectedsensors) {
          checked = selectedsensors[thisid];
        }
        sensorlist.push(
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleSensorSelect}
                value={thisid}
              />
            }
            label={
              <span className={classes.bold_text}>
                {sensor.sensor_id_str + " " + sensor.human_name}
              </span>
            }
          />
        );
      }

      if (sensor.enabled && sensor.advanced_rules_enabled) {
        const thisid = sensor.sensor_id;
        initialselectedsensors[thisid] = false;
        advancedSensorList.push(
          <FormControlLabel
            control={<Checkbox checked={false} value={thisid} />}
            label={
              <span className={classes.bold_text}>
                {sensor.sensor_id_str + " " + sensor.human_name}
              </span>
            }
          />
        );
      }
    });
    if (!selectedsensors) {
      setSelectedSensors(initialselectedsensors);
    }
  }
  if (appStore!.authService.getRoles().indexOf("site_admin") >= 0) {
    if (sensors && sensors.length > 0) {
      return (
        <Dialog
          onClose={handleClose}
          className={classes.modal_dialog}
          open={open}
        >
          <DialogTitle className={classes.bold_text}>
            Add Rule to {currentAlertChannel.name}
          </DialogTitle>
          <FormControl variant="outlined" className={classes.form_component}>
            <InputLabel className={classes.bold_text}>Alert Class</InputLabel>
            <Select
              value={selectedclasslabels}
              className={classes.bold_text}
              multiple
              onChange={handleAlertClassChange}
            >
              {class_labels}
            </Select>
          </FormControl>
          <FormControl className={classes.form_component}>
            {schedule}
          </FormControl>
          <FormControl>
            <FormLabel component="legend" className={classes.bold_text}>
              Select Sensors
            </FormLabel>
            <FormGroup>{sensorlist}</FormGroup>
          </FormControl>
          <br></br>
          <FormControl>
            {/* <FormLabel component="legend" className={classes.bold_text}>
              Advanced Sensors
            </FormLabel>
            Please note: sensors set to advanced rules must be set up via the
            advanced rules wizard */}
            <FormGroup aria-disabled={"true"}>{advancedSensorList}</FormGroup>
          </FormControl>
          <div className={classes.button_container}>
            <Button
              variant="contained"
              className={classes.cancel_button}
              onClick={handleClose}
            >
              <span className={classes.bold_text}>CANCEL</span>
            </Button>
            <Button
              variant="contained"
              className={classes.save_button}
              onClick={handleSave}
            >
              <span className={classes.bold_text}>SAVE</span>
            </Button>
          </div>
        </Dialog>
      );
    } else {
      return (
        <Dialog
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}
        >
          <DialogTitle className="add-rule-dialog-header">
            Before adding alert rules, please add camera streams to the site.
          </DialogTitle>

          <div className={classes.button_container}>
            <Button
              variant="contained"
              className={classes.cancel_button}
              onClick={handleClose}
            >
              <span className={classes.bold_text}>OK</span>
            </Button>
          </div>
        </Dialog>
      );
    }
  } else {
    return (
      <Dialog onClose={onClose} open={open} className={classes.modal_dialog}>
        <DialogTitle className={classes.bold_text}>
          Add Rule to {currentAlertChannel.name}
        </DialogTitle>
        <br></br>
        <Typography className={classes.bold_text}>
          {
            "You need site admin role to be able to add a new Alert Rule - please contact your DeepAlert system administrator"
          }
        </Typography>
        <div className={classes.button_container}>
          <Button
            className={classes.cancel_button}
            variant="contained"
            onClick={() => onClose()}
          >
            <span className={classes.bold_text}>CANCEL</span>
          </Button>
        </div>
      </Dialog>
    );
  }
};

export default AddRuleModal;
