import React from "react";
import classes from "./ui.module.css";

interface Props {
  name: string;
  value: number | string;
}

const Pill: React.FC<Props> = ({ name, value }) => {
  return (
    <div className={classes.pill_wrapper}>
      <div className={classes.pill_name}>{name}</div>
      <div className={classes.pill_value}>{value}</div>
    </div>
  );
};

export default Pill;
