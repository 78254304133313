import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AppStore } from "../../../../stores/AppStore";
import { useStyles } from "../../../styling/global/global";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BillingProfiles from "../BillingProfiles/BillingProfiles";
import { TextField } from "@material-ui/core";
import {
  listBillingProfileOrgsItems,
  listBillingRunsItems,
} from "../../../../services/FunctionService";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";

interface EditBillingRunProps {
  open: boolean;
  appStore?: AppStore;
  run: listBillingRunsItems | undefined;
  onClose: () => void;
  profile_id: number | undefined;
  EditBillingRunFunction: (
    profile_id: number,
    runId: number,
    name: string,
    description: string
  ) => void;
}
interface listBillingItems {
  id: number;
  enabled: boolean;
  name: string;
  description: string;
  meta: { [key: string]: any };
  created: Date | string;
}

const EditBillingRun: React.FC<EditBillingRunProps> = ({
  open,
  onClose,
  run,
  profile_id,
  EditBillingRunFunction,
}: EditBillingRunProps) => {
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        display: "flex",
        justifyContent: "space-between",
        textAlign: "left",
        paddingLeft: "2%",
        paddingRight: "2%",
      },
    })
  )(TableRow);

  const classes = useStyles();
  const [inputName, setInputName] = useState<string>("");
  const [inputDescription, setInputDescription] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const handleInputNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    setNameError(false);
    setInputName(evt.target.value);
  };
  const handleInputDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    setDescriptionError(false);
    setInputDescription(event.target.value);
  };
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [renderValue, setRenderValue] = useState(false);

  useEffect(() => {
    if (run) {
      setInputDescription(run.description);
      setInputName(run.name);
      setLoading(false);
    }
  }, [renderValue, run]);
  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
        fullWidth={true}
      >
        <DialogTitle
          style={{ alignSelf: "center" }}
          className={classes.bold_text}
          id="simple-dialog-title"
        >
          <span style={{ fontSize: "25px" }}>Edit Billing Run </span>
        </DialogTitle>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "5%",
              marginBottom: "5%",
              width: "80%",
              marginLeft: "10%",
            }}
          >
            <TextField
              style={{ marginBottom: "5%" }}
              error={nameError}
              variant="outlined"
              label="edit billing run name"
              value={inputName}
              defaultValue={run?.name}
              onChange={handleInputNameChange}
              InputProps={{
                className: classes.bold_text,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            ></TextField>
            <TextField
              variant="outlined"
              error={descriptionError}
              label="edit billing run description"
              value={inputDescription}
              defaultValue={run?.description}
              onChange={handleInputDescriptionChange}
              InputProps={{
                className: classes.bold_text,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text,
                },
              }}
            ></TextField>
          </div>
        )}

        <div
          style={{ marginTop: "5%", marginBottom: "2%" }}
          className={classes.twoButtonsInARow}
        >
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => {
              setInputDescription("");
              setInputName("");
              setRenderValue((value) => !value);
              onClose();
            }}
          >
            <span className={classes.bold_text}>Dismiss</span>
          </Button>
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => {
              if (inputName != "" || inputDescription != "") {
                EditBillingRunFunction(
                  profile_id!,
                  run!.id,
                  inputName!,
                  inputDescription!
                );
                setInputDescription("");
                setInputName("");
                setRenderValue((value) => !value);
                onClose();
              } else if (inputName == "") {
                setNameError(true);
              } else {
                setDescriptionError(true);
              }
            }}
          >
            <span className={classes.bold_text}>Update</span>
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default EditBillingRun;
