import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { AppStore } from "../../../../stores/AppStore";
import {
  listBillingRatesItems,
  billing_rate_support_items,
} from "../../../../services/FunctionService";

import LoadingSpinner from "../../../../spinner/LoadingSpinner";
import { useStyles } from "../../../styling/global/global";

interface CloneProductProps {
  open: boolean;
  appStore?: AppStore;
  productData: listBillingRatesItems | undefined;
  billingRateItems: listBillingRatesItems[] | undefined;
  rateSupportItems: billing_rate_support_items;
  addCloneProduct: (
    product: string,
    classifier_id: number,
    data_origin_id: number,
    tier_id: number,
    product_name_id: number,
    rate_info: { [key: string]: any }
  ) => void;
  onClose: () => void;
}
const CloneProduct: React.FC<CloneProductProps> = ({
  open,
  onClose,
  productData,
  appStore,
  rateSupportItems,
  billingRateItems,
  addCloneProduct,
}: CloneProductProps) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [productName, setProductName] = useState("");
  const [publicName, setPublicName] = useState("");
  const [description, setDescription] = useState("");
  const [renderValue, setRenderValue] = useState(false);
  const [productNameId, setProductNameId] = useState<number | undefined>(
    undefined
  );
  const [selectError, setSelectError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [public_product_name_array, setPublic_product_name_array] = useState<
    listBillingRatesItems[] | undefined
  >([]);
  const [message, setMessage] = useState("");

  const handleClose = () => {
    setProductName("");
    setDescription("");
    setRenderValue((val) => !val);
    onClose();
  };
  const AddProduct = () => {
    if (productData) {
      if (!productNameId) {
        setSelectError(true);
      } else if (productName === "" || productName === undefined) {
        setNameError(true);
      } else {
        addCloneProduct(
          productName,
          productData?.classifier_id,
          productData?.data_origin.data_origin_id,
          productData?.tier_id,
          productNameId,
          productData.rate_info
        );
        setProductName("");
        setDescription("");
        setRenderValue((val) => !val);
        onClose();
      }
    }
  };
  const handleInputNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    if (evt.target.value) setNameError(false);
    setProductName(evt.target.value);
  };

  const handleInputDescriptionChange = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    evt.preventDefault();
    setDescription(evt.target.value);
  };
  const handleLabelsChange = (event: React.ChangeEvent<any>) => {
    setSelectError(false);
    setProductNameId(+event.target.value);
  };
  interface productType {
    product_name_id: number;
    public_product_name: string;
  }

  useEffect(() => {
    if (productData && billingRateItems) {
      setProductName(`copy of ${productData.product}`);

      const seen = new Set();

      const filteredArr = billingRateItems.filter((el) => {
        const duplicate = seen.has(el.product_name_id);
        seen.add(el.product_name_id);
        return !duplicate;
      });
      setPublic_product_name_array(filteredArr);

      billingRateItems.map((item, index) => {
        let newItem: { [key: string]: number } = {};
        newItem[item.public_product_name!] = item.product_name_id;

        if (item.product_name_id === productData.product_name_id) {
          setProductNameId(item.product_name_id);
        }
      });

      setLoading(false);
    }
  }, [productData, renderValue, billingRateItems]);

  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          style={{ alignSelf: "center" }}
          className={classes.bold_text}
          id="simple-dialog-title"
        >
          <Typography
            className={classes.bold_text}
            style={{ color: "#3f51b5" }}
          >
            Cloning the{" "}
            <span
              style={{ color: "blue", fontSize: 14 }}
              className={classes.bold_text}
            >
              {productData?.product}
            </span>{" "}
            billing rate
          </Typography>
        </DialogTitle>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "2%",
              marginBottom: "5%",
              width: "80%",
              marginLeft: "10%",
            }}
          >
            <TextField
              label="billing rate"
              fullWidth
              onChange={handleInputNameChange}
              InputProps={{
                className: classes.bold_text_billing,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text_billing,
                },
              }}
              style={{ marginBottom: "5%" }}
              error={nameError}
              helperText={nameError ? message : ""}
              variant="outlined"
              value={productName}
              className={classes.bold_text}
            />
            {/* <FormControl style={{ width: "100%" }}>
              <InputLabel
                style={{ marginLeft: "1%" }}
                className={classes.bold_text_billing}
                id="test-select-label"
              >
                public product names
              </InputLabel>
              <Select
                id="select product name"
                variant="outlined"
                value={productNameId}
                error={selectError}
                fullWidth
                onChange={(e) => handleLabelsChange(e)}
              >
                {rateSupportItems.product_name_data &&
                  rateSupportItems.product_name_data.map((item, index) => {
                    return (
                      <MenuItem value={item.product_name_id}>
                        <Typography className={classes.bold_text_billing}>
                          {item.product_name}
                        </Typography>
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl> */}
          </div>
        )}
        <div
          style={{
            marginBottom: 10,
            marginTop: 10,
          }}
          className={classes.twoButtonsInARow}
        >
          <Button
            variant="contained"
            className={classes.bold_text}
            onClick={handleClose}
          >
            Dismiss
          </Button>
          <Button
            variant="contained"
            className={classes.bold_text}
            onClick={AddProduct}
          >
            Add
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default CloneProduct;
