import React from "react";
import ImageMapper from "react-img-mapper";
import "./Mapper.css";

interface imageProp {
  imgUrl: string;
  coords: number[];
  width: number;
  ogWidth?: number;
}

const Mapper: React.FC<imageProp> = ({ imgUrl, coords, width, ogWidth }) => {
  const [hoveredAreaName, setHoveredAreaName] = React.useState(null);
  const URL = imgUrl;
  let MAP = {
    name: "my-map",
    areas: [
      {
        name: "2",
        shape: "rect",
        strokeColor: "red",
        coords: coords,
        preFillColor: "rgba(0,255,0, 0)",
        fillColor: "rgba(255,0,255, 0)",
      },
    ],
  };

  const getImageWidth = (imgUrl: string) => {
    const img = new Image();
    img.src = imgUrl;
    return (img.onload = function () {
      return img.width;
    });
  };

  const getImageHeight = (imgUrl: string) => {
    const img = new Image();
    img.src = imgUrl;
    return (img.onload = function () {
      return img.height;
    });
  };

  return (
    // <span style={{height: '100%'}}>
    <ImageMapper
      active={true}
      imgWidth={ogWidth}
      width={width}
      src={URL}
      lineWidth={2}
      natural={false}
      map={MAP}
    />
    // </span>
  );
};

export default Mapper;
