import React, { useState } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import Card from "@material-ui/core/Card";
import { AppStore } from "../../../stores/AppStore";
import { OrgSelect } from "../../../services/FunctionService";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "../../styling/global/global";
import Grid from "@material-ui/core/Grid";
import { Org, Hub, Site } from "../../../services/FunctionService";
interface OrgPageProps {
  appStore?: AppStore;
  changeOrg: (d: any) => void;
}
class impOrg implements Org {
  org_id!: number;
  org_name!: string;
  org_tree!: number[];
  org_level!: number;
  enabled!: boolean;
  sites!: Site[];
  hubs!: Hub[];
  orgs!: Org[];
  type!: string;
  created!: string;
  sub_org_count!: number;
  site_count!: number;
  hub_count!: number;
}
const OrgPage: React.FC<OrgPageProps> = ({
  appStore,
  changeOrg,
}: OrgPageProps) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  var orgs: OrgSelect[] = [];
  if (appStore) {
    orgs = appStore.authService.getOrgs();
  }

  async function handleClicked(org_id: number) {
    setLoading(true);
    if (appStore) {
      var login = await appStore.authService.signInOrg(org_id);
      if (login) {
        const token = await appStore?.authService.getAuthorisedToken();
        if (token) {
          let neworg = new impOrg();
          neworg.org_id = org_id;
          changeOrg(neworg);
        }
      }
    }
  }
  if (!loading) {
    return (
      <div className={classes.login}>
        <Card className={classes.login_container}>
          <Grid container justify="center">
            <Grid item>
              <img src={"/assets/img/logo_main.png"} alt="Logo" />
              <div className={classes.logo_text_white}>MANAGEMENT</div>
            </Grid>
          </Grid>
          <Typography variant="subtitle1" className={classes.bold_text_white}>
            Select profile to administer:
          </Typography>
          <List component="nav" aria-label="Select Group">
            {orgs.map(function (org) {
              if (org) {
                return (
                  <div>
                    <ListItem onClick={(event) => handleClicked(org.id)} button>
                      <ListItemText
                        classes={{ primary: classes.bold_text_white }}
                        primary={org.name}
                      />
                    </ListItem>
                    <Divider />
                  </div>
                );
              }
            })}
          </List>
        </Card>
      </div>
    );
  } else {
    return (
      <div className={classes.login}>
        <Card className={classes.login_container}>
          <div className="wait-container">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <svg width="48px" height="48px" viewBox="0 0 128 128">
              <g>
                <linearGradient id="linear-gradient">
                  <stop offset="0%" stop-color="#ffffff" />
                  <stop offset="100%" stop-color="#22305f" />
                </linearGradient>
                <path
                  d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                  fill="url(#linear-gradient)"
                  fill-rule="evenodd"
                />
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 64 64"
                  to="360 64 64"
                  dur="1080ms"
                  repeatCount="indefinite"
                ></animateTransform>
              </g>
            </svg>
          </div>
        </Card>
      </div>
    );
  }
};

export default OrgPage;
