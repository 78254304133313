import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AppStore } from "../../../../stores/AppStore";
import { useStyles } from "../../../styling/global/global";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BillingProfiles from "../BillingProfiles/BillingProfiles";
import { TextField } from "@material-ui/core";

interface DeleteBillingProfilesProps {
  open: boolean;
  appStore?: AppStore;
  profile: listBillingItems | undefined;
  onClose: () => void;
  DeleteProfileFunction: (profile_id: number) => void;
}
interface listBillingItems {
  id: number;
  enabled: boolean;
  name: string;
  description: string;
  meta: { [key: string]: any };
  created: Date | string;
}

const DeleteBillingProfiles: React.FC<DeleteBillingProfilesProps> = ({
  open,
  onClose,
  profile,
  appStore,
  DeleteProfileFunction,
}: DeleteBillingProfilesProps) => {
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        display: "flex",
        justifyContent: "space-between",
        textAlign: "left",
        paddingLeft: "2%",
        paddingRight: "2%",
      },
    })
  )(TableRow);

  const classes = useStyles();

  useEffect(() => {}, []);
  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
        fullWidth={true}
      >
        <DialogTitle
          style={{ alignSelf: "center" }}
          className={classes.bold_text}
          id="simple-dialog-title"
        >
          <Typography
            className={classes.bold_text}
            style={{ color: "#3f51b5" }}
          >
            delete profile
          </Typography>
        </DialogTitle>
        <TableContainer>
          <StyledTableRow>
            <TableCell>
              <Typography
                className={classes.bold_text}
                style={{ color: "#3f51b5" }}
              >
                Are you sure you want to delete the following profile:{" "}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                className={classes.bold_text}
                style={{ color: "grey" }}
              >
                {profile ? profile.name : ""}
                <span
                  className={classes.bold_text}
                  style={{ color: "#3f51b5" }}
                >
                  ?
                </span>
              </Typography>
            </TableCell>
          </StyledTableRow>
        </TableContainer>
        <div
          style={{ marginTop: "5%", marginBottom: "2%" }}
          className={classes.twoButtonsInARow}
        >
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => onClose()}
          >
            <span className={classes.bold_text}>dismiss</span>
          </Button>
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => {
              if (profile) {
                DeleteProfileFunction(profile!.id);
                onClose();
              }
            }}
          >
            <span className={classes.bold_text}>delete</span>
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default DeleteBillingProfiles;
