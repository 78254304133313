import React from "react";
import classes from "./ui.module.css";

import Button from "@material-ui/core/Button";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Slide from "@material-ui/core/Slide";

import { TransitionProps } from "@material-ui/core/transitions";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  disabled?: boolean;
  continueAction?: boolean;
  closeHandler: () => void;
  continue?: boolean;
  large?: boolean;
  title?: string;
  onContinueHandler?: () => void;
  children: React.ReactNode;
}

const CustomModal: React.FC<Props> = ({
  open,
  continueAction,
  closeHandler,
  children,
  onContinueHandler,
  title,
  large,
  disabled,
}) => {
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>(
    large ? "lg" : "md"
  );

  const handleClose = () => {
    closeHandler();
  };

  return (
    <Dialog
      // fullScreen
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <List>
        <DialogContent>{children}</DialogContent>
      </List>
      <Divider />
      <DialogActions>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: 30,
          }}
        >
          <Button onClick={handleClose} color="primary">
            {continueAction ? "Cancel" : "Close"}
          </Button>
          {continueAction && (
            <Button
              onClick={onContinueHandler}
              color="primary"
              disabled={disabled}
            >
              {title}
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CustomModal;
