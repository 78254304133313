import React, { useEffect, useState } from "react";
import moment from "moment-timezone";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Box, Tooltip } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";

import {
  RegisteredUser,
  RegisteredUsersResult,
  Role,
} from "../../../../services/FunctionService";
import { AppStore } from "../../../../stores/AppStore";
import { Typography } from "@material-ui/core";
import { useStyles } from "../../../styling/global/global";
import BillingRegistrationExpansion from "./BillingRegistrationExpansion";
import AddRegisteredUser from "../BillingModals/AddRegisteredUser";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";
import TrendingUpRounded from "@material-ui/icons/TrendingUpRounded";
import UpdateRegisteredUser from "../BillingModals/UpdateRegisteredUser";
import DeleteRegisteredUser from "../BillingModals/DeleteRegisteredUser";

interface BillingRegistrationProps {
  appStore?: AppStore;
}

const BillingRegistration: React.FC<BillingRegistrationProps> = ({
  appStore,
}: BillingRegistrationProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<RegisteredUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<RegisteredUser | undefined>(
    undefined
  );
  const [openAdd, setOpenAdd] = useState(false);
  const [openDeleteUser, setOpenDeleteUser] = useState(false);
  const [openUpdateUser, setOpenUpdateUser] = useState(false);
  const [roles, setRoles] = useState<string[]>([]);
  const [selectedValue, setSelectedValue] = React.useState("all");

  const fetchRegisteredUsers = async () => {
    setLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      const usersResult: RegisteredUsersResult =
        await appStore?.functionService.getAllRegisteredUsers(token);
      if (usersResult.success) {
        setUsers(usersResult.registration_data.registration_details);
        setRoles(usersResult.registration_data.allowed_roles);
      } else {
      }
      setLoading(false);
    }
  };

  const fetchProcessedRegisteredUsers = async (processed: boolean) => {
    setLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      const usersResult: RegisteredUsersResult =
        await appStore?.functionService.getProcessedRegisteredUsers(
          token,
          processed
        );
      if (usersResult.success) {
        setUsers(usersResult.registration_data.registration_details);
        setRoles(usersResult.registration_data.allowed_roles);
      } else {
      }
      setLoading(false);
    }
  };
  const handleAllUsersChange = (value: string) => {
    if (value === "all") {
      setSelectedValue("all");
      fetchRegisteredUsers();
    }
    if (value === "processed") {
      setSelectedValue("processed");
      fetchProcessedRegisteredUsers(true);
    }
    if (value === "unprocessed") {
      setSelectedValue("unprocessed");
      fetchProcessedRegisteredUsers(false);
    }
  };

  useEffect(() => {
    fetchRegisteredUsers();
  }, []);

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Box mt={2} mb={2}>
            <RadioGroup
              aria-label="gender"
              name="Users"
              value={selectedValue}
              onChange={(e) => handleAllUsersChange(e.target.value)}
            >
              <FormControlLabel
                value="all"
                control={<Radio />}
                label={
                  <Typography className={classes.bold_text_billing}>
                    all users
                  </Typography>
                }
                // label='Include subgroups of selected group'
              />
              <FormControlLabel
                value="processed"
                control={<Radio />}
                label={
                  <Typography className={classes.bold_text_billing}>
                    show processed
                  </Typography>
                }
                // label='Exclude subgroups of selected group'
              />
              <FormControlLabel
                value="unprocessed"
                control={<Radio />}
                label={
                  <Typography className={classes.bold_text_billing}>
                    show unprocessed
                  </Typography>
                }
              />
            </RadioGroup>
          </Box>
          <div
            style={{
              boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
              marginTop: 15,
              borderRadius: 15,
              overflow: "hidden",
              width: "100%",
            }}
          >
            <TableContainer style={{ maxHeight: 500, maxWidth: "100%" }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "#1f3161",
                      height: "20px",
                      color: "#fff",
                    }}
                  >
                    <TableCell>
                      <Typography>
                        <span className={classes.bold_text}>
                          created&nbsp;at
                        </span>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        <span className={classes.bold_text}>
                          company&nbsp;name
                        </span>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        <span className={classes.bold_text}>email</span>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        <span className={classes.bold_text}>first name</span>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        <span className={classes.bold_text}>last name</span>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        <span className={classes.bold_text}>hear about us</span>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        <span className={classes.bold_text}>processed</span>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        <span className={classes.bold_text}>actions</span>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!users &&
                    users.map((user, index) => {
                      return (
                        <TableRow>
                          <TableCell>
                            <Typography>
                              <span
                                className={classes.bold_text}
                                style={{ color: "#3f51b5" }}
                              >
                                {moment(user.createdAt).format(
                                  "YYYY-MM-DD HH:mm"
                                )}
                              </span>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              <span
                                className={classes.bold_text}
                                style={{ color: "#3f51b5" }}
                              >
                                {user.company_name}
                              </span>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              <span
                                className={classes.bold_text}
                                style={{ color: "#3f51b5" }}
                              >
                                {user.email}
                              </span>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              <span
                                className={classes.bold_text}
                                style={{ color: "#3f51b5" }}
                              >
                                {user.first_name}
                              </span>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              <span
                                className={classes.bold_text}
                                style={{ color: "#3f51b5" }}
                              >
                                {user.last_name}
                              </span>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              <Tooltip title={user?.hear_about_us}>
                                <span
                                  className={classes.bold_text}
                                  style={{ color: "#3f51b5" }}
                                >
                                  {user.hear_about_us
                                    ? user.hear_about_us.substring(0, 12)
                                    : ""}
                                  {user &&
                                    user.hear_about_us &&
                                    user.hear_about_us.length >= 12 &&
                                    "..."}
                                </span>
                              </Tooltip>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <FormControl>
                              <Checkbox
                                className={classes.form_component_checkbox}
                                // disabled={true}
                                color="primary"
                                icon={<ClearIcon fontSize="small" />}
                                checkedIcon={<DoneIcon fontSize="small" />}
                                checked={user.processed}
                              />
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <BillingRegistrationExpansion
                              add={true}
                              remove={true}
                              process_value={user.processed}
                              process={true}
                              handleAdd={() => {
                                setSelectedUser(user);
                                setOpenAdd(true);
                              }}
                              handleProcess={() => {
                                setSelectedUser(user);
                                setOpenUpdateUser(true);
                              }}
                              handleRemove={() => {
                                setSelectedUser(user);
                                setOpenDeleteUser(true);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
      {openAdd && (
        <AddRegisteredUser
          open={openAdd}
          appStore={appStore}
          user={selectedUser}
          onClose={() => {
            setOpenAdd(false);
            setSelectedValue("all");
            fetchRegisteredUsers();
          }}
          allowed_roles={roles}
        />
      )}
      {openUpdateUser && (
        <UpdateRegisteredUser
          open={openUpdateUser}
          appStore={appStore}
          user={selectedUser}
          onClose={() => {
            setOpenUpdateUser(false);
            setSelectedValue("all");
            fetchRegisteredUsers();
          }}
        />
      )}
      {openDeleteUser && (
        <DeleteRegisteredUser
          open={openDeleteUser}
          appStore={appStore}
          user={selectedUser}
          onClose={() => {
            setOpenDeleteUser(false);
            setSelectedValue("all");
            fetchRegisteredUsers();
          }}
        />
      )}
    </div>
  );
};

export default BillingRegistration;
