import React, { useState, useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { AppStore } from "../../../stores/AppStore";
import { Site, BillingRate } from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
interface AddHubModalProps {
  appStore?: AppStore;
  onClose: () => void;
  message: string;
  open: boolean;
  saveNewHub: (location: string, site_id: number, type_str: string) => void;
  site: Site | undefined;
}

const AddHubModal: React.FC<AddHubModalProps> = ({
  onClose,
  open,
  saveNewHub,
  site,
  appStore,
  message,
}: AddHubModalProps) => {
  const classes = useStyles();
  const [location, setLocation] = React.useState<string>("");
  const handleSave = () => {
    let type_str = "real";
    if (
      site?.data_origin == "alert_rest_client" ||
      site?.data_origin == "alert_smtp_client" ||
      site?.data_origin == "alert_ftp_client" ||
      site?.data_origin == "inference_api_client"
    ) {
      type_str = "logical";
    }
    saveNewHub(location, site!.site_id, type_str);
  };
  const handleClose = () => {
    onClose();
  };

  const handleLocationChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setLocation(e.target.value);
  };
  if (appStore && appStore.authService.getRoles().indexOf("site_admin") >= 0) {
    return (
      <Dialog
        onClose={handleClose}
        open={open}
        className={classes.modal_dialog}
      >
        <DialogTitle className={classes.bold_text}>
          Add New Hub to {site?.name}
        </DialogTitle>
        <Typography className={classes.bold_text} color="error">
          {message}
        </Typography>
        <TextField
          onChange={handleLocationChange}
          label="Location"
          variant="outlined"
          margin="normal"
          fullWidth
          autoFocus
          InputProps={{
            className: classes.bold_text,
          }}
          InputLabelProps={{
            classes: {
              root: classes.bold_text,
            },
          }}
        />
        <div className={classes.button_container}>
          <Button
            className={classes.cancel_button}
            variant="contained"
            onClick={handleClose}
          >
            <span className={classes.bold_text}>CANCEL</span>
          </Button>
          <Button
            className={classes.save_button}
            variant="contained"
            onClick={handleSave}
          >
            <span className={classes.bold_text}>SAVE</span>
          </Button>
        </div>
      </Dialog>
    );
  } else {
    return (
      <Dialog onClose={onClose} open={open} className={classes.modal_dialog}>
        <DialogTitle className={classes.bold_text}>
          Add New Hub to {site?.name}
        </DialogTitle>
        <br></br>
        <Typography className={classes.bold_text}>
          {
            "You need site admin role to be able to add a new Hub - please contact your DeepAlert system administrator"
          }
        </Typography>
        <div className={classes.button_container}>
          <Button
            className={classes.cancel_button}
            variant="contained"
            onClick={() => onClose()}
          >
            <span className={classes.bold_text}>CANCEL</span>
          </Button>
        </div>
      </Dialog>
    );
  }
};

export default AddHubModal;
