import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "@material-ui/core";
import { useStyles } from "../../../styling/global/global";
import { AppStore } from "../../../../stores/AppStore";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";
import {
  listBillingRunTypesItems,
  listBillingItems,
} from "../../../../services/FunctionService";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
interface AddBillingRunProps {
  appStore?: AppStore;
  open: boolean;
  onClose: () => void;
  profile: listBillingItems | undefined;
  billingRunsTypes: listBillingRunTypesItems[];
  addBillingRun: (
    profile_id: number,
    run_type_id: number,
    name: string,
    run_date: Date,
    description: string
  ) => void;
}

const AddBillingRun: React.FC<AddBillingRunProps> = ({
  open,
  onClose,
  appStore,
  billingRunsTypes,
  profile,
  addBillingRun,
}: AddBillingRunProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [renderValue, setRenderValue] = useState(false);
  const [runTypeId, setRunTypeId] = React.useState<number | undefined>(
    undefined
  );
  const [textError, setTextError] = useState(false);
  const [groupError, setGroupError] = useState(false);
  const [inputName, setInputName] = useState("");
  const [inputDescription, setInputDescription] = useState("");
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  );

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  const handleChange = (event: React.ChangeEvent<any>) => {
    setGroupError(false);
    setRunTypeId(+event.target.value);
  };
  const handleInputNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setTextError(false);
    evt.preventDefault();
    setInputName(evt.target.value);
  };
  const handleInputDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    setInputDescription(event.target.value);
  };
  useEffect(() => {}, [renderValue]);
  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
        fullWidth={true}
      >
        <DialogTitle
          style={{ alignSelf: "center" }}
          className={classes.bold_text}
          id="simple-dialog-title"
        >
          <span style={{ fontSize: "25px" }}>
            Create a Billing Run for {profile ? profile.name : ""}
          </span>
        </DialogTitle>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div
              style={{
                width: "90%",
                marginLeft: "5%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormControl>
                <InputLabel style={{ marginLeft: "1%" }} id="test-select-label">
                  Billing Run Types
                </InputLabel>
                <Select
                  label="Select Billing Profile"
                  labelId="test-select-label"
                  id="demo-controlled-open-select"
                  variant="outlined"
                  error={groupError}
                  value={runTypeId}
                  onChange={(e) => handleChange(e)}
                >
                  {billingRunsTypes &&
                    billingRunsTypes.map((type, index) => {
                      return <MenuItem value={type.id}>{type.name}</MenuItem>;
                    })}
                </Select>
              </FormControl>
              <br></br>
              <TextField
                style={{ marginBottom: "5%" }}
                required
                autoFocus
                error={textError}
                variant="outlined"
                label="Add Billing Run Name"
                value={inputName}
                onChange={handleInputNameChange}
                helperText={
                  textError === true
                    ? "Billing Run Name field cannot be blank"
                    : ""
                }
                InputProps={{
                  className: classes.bold_text,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.bold_text,
                  },
                }}
              />

              <TextField
                autoFocus
                variant="outlined"
                label="Add Billing Run Description"
                value={inputDescription}
                onChange={handleInputDescriptionChange}
                InputProps={{
                  className: classes.bold_text,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.bold_text,
                  },
                }}
              />
              <br></br>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Please pick a run date of the billing run."
                  format="MM/dd/yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div
              style={{ marginTop: "5%", marginBottom: "5%" }}
              className={classes.twoButtonsInARow}
            >
              <Button
                variant="contained"
                className={classes.cancel_button}
                onClick={() => {
                  setTextError(false);
                  setGroupError(false);
                  setInputName("");
                  setInputDescription("");
                  setRunTypeId(undefined);
                  onClose();
                }}
              >
                <span className={classes.bold_text}>Dismiss</span>
              </Button>
              <Button
                variant="contained"
                className={classes.cancel_button_billing}
                onClick={() => {
                  if (inputName != "" && runTypeId) {
                    addBillingRun(
                      profile!.id,
                      runTypeId!,
                      inputName,
                      selectedDate!,
                      inputDescription
                    );
                    setInputName("");
                    setInputDescription("");
                    setRunTypeId(undefined);
                    setRenderValue((value) => !value);
                    onClose();
                  } else if (inputName === "") {
                    setTextError(true);
                  } else {
                    setGroupError(true);
                  }
                }}
              >
                <span className={classes.bold_text}>Create a Billing Run</span>
              </Button>
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default AddBillingRun;
