import React from "react";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Props {
  multiple: boolean;
  title: string;
  names: any;
  value: any;
  defaultValue?: string;
  handleValueChange: (value: any) => void;
  clear?: boolean;
  upcoming_options?: string[];
  fullWidth?: boolean;
}

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect({
  multiple,
  title,
  names,
  defaultValue,
  clear,
  value,
  handleValueChange,
  upcoming_options = undefined,
  fullWidth,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    handleValueChange(event.target.value as string);
  };

  if (value.length === 0 && defaultValue) {
    handleValueChange(defaultValue);
  }

  return (
    <div>
      <FormControl
        className={classes.formControl}
        variant="outlined"
        disabled={value === "suspicious activity" ? true : false}
        style={{ width: fullWidth ? "100%" : 140, color: "#6D809D" }}
      >
        <InputLabel id="demo-mutiple-chip-label">{title}</InputLabel>
        <Select
          variant="outlined"
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          multiple={multiple}
          defaultValue={defaultValue}
          value={clear ? [] : value}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          MenuProps={MenuProps}
        >
          {names?.map((name: any) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, value, theme)}
            >
              {name}
            </MenuItem>
          ))}
          {upcoming_options &&
            upcoming_options.map((name: any) => (
              <MenuItem
                key={name}
                value={name}
                disabled
                style={getStyles(name, value, theme)}
              >
                {name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
