import React, { useState, useCallback } from "react";
import ReactDOM from "react-dom";
import ImageViewer from "react-simple-image-viewer";
import { Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import CustomModal from "../UI/CustomModal";
import MuiltMapper from "../components/MuiltMapper";

import { useEffect } from "react";
import classes from "./SlideShow.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faCirclePause } from "@fortawesome/free-solid-svg-icons";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface ImpProps {
  flagBtn: React.Dispatch<React.SetStateAction<boolean>>;
  slideImages: string[];
  currentImage: number;
  closeImageViewer: () => void;
}

const FullImageViewer: React.FC<ImpProps> = ({
  flagBtn,
  slideImages,
  currentImage,
  closeImageViewer,
}) => {
  let content = (
    <ImageViewer
      src={slideImages}
      currentIndex={currentImage}
      disableScroll={false}
      // closeOnClickOutside={ true }
      onClose={closeImageViewer}
    />
  );
  return ReactDOM.createPortal(
    content,
    document.getElementById("image-viewer") as HTMLElement
  );
};

interface Props {
  flagBtn: React.Dispatch<React.SetStateAction<boolean>>;
  isAlpr?: boolean;

  onChangeHandler: (id: number) => void;
  height?: number;
  width?: number;
  slideImages: string[];
  autoplay: boolean;
  autoplayHook: any;
  AutoPlayy: boolean;
  object_detections?: {
    inference_class: string;
    inference_probability: number;
    x0: number;
    y0: number;
    x1: number;
    y1: number;
  }[];
}

const SlideShow: React.FC<Props> = ({
  flagBtn,
  autoplay,
  AutoPlayy,
  autoplayHook,
  onChangeHandler,
  slideImages,
  object_detections,
  height,
  width,
  isAlpr = false,
}) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [showArrows, setShowArrows] = useState(true);
  const [AutoPlay, setAutoplay] = useState<any>(
    slideImages.length <= 1
      ? false
      : AutoPlayy === false
      ? false
      : AutoPlayy === true
      ? true
      : true
  );

  const [dotClicked, setDotClicked] = useState(false);
  const [indyImage, setIndyImage] = useState<any | null>(null);

  useEffect(() => {
    flagBtn(AutoPlay);
  }, [AutoPlay]);

  // LOGIC FOR INDICATORS
  const [activeIndex, setActiveIndex] = useState(0);

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setActiveIndex(index);
    setIsViewerOpen(true);
    setShowArrows(false);
    setAutoplay(false);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setActiveIndex(0);
    setIsViewerOpen(false);
    setShowArrows(true);
  };

  const indicatorHandler = (index: any) => {
    setAutoplay(false);
    setCurrentImage(index);
    setActiveIndex(index);
    onChangeHandler(index);
  };

  const settings = {
    dots: false,
    fade: true,
    pauseOnHover: false,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: AutoPlayy === null ? AutoPlay : AutoPlayy,
    beforeChange: (currentSlideIndex: any, nextSlideIndex: any) => {
      setActiveIndex(nextSlideIndex);
      setCurrentImage(nextSlideIndex);
      onChangeHandler(nextSlideIndex);
      setAutoplay(true);
      if (autoplay && currentSlideIndex !== nextSlideIndex) {
        setAutoplay(false); // Turn off autoplay when a dot is clicked
      }
    },

    // Add any other settings for the slider as needed
  };

  return (
    <>
      <div className="play_pause" style={{}}>
        {AutoPlay === false && !isAlpr && (
          <FontAwesomeIcon
            onClick={() => {
              setAutoplay((prev: any) => {
                return true;
              });
              // setActiveIndex(0);
              // onChangeHandler(0);
              // setCurrentImage(0);
              setIndyImage(null);
              autoplayHook(true);
            }}
            icon={faPlay}
            style={{
              fontSize: "37px",
              color: "#15325F",
              transform: "translate(-95px, -42px)",
              cursor: "pointer",
              pointerEvents: slideImages.length <= 1 ? "none" : "auto",
              opacity: slideImages.length <= 1 ? 0.3 : 1,
            }}
          />
        )}
        {AutoPlay === true && !isAlpr && (
          <FontAwesomeIcon
            onClick={() => {
              setAutoplay(false);
              setIndyImage(null);
              autoplayHook(false);
            }}
            icon={faCirclePause}
            style={{
              fontSize: "37px",
              color: "#15325F",
              transform: "translate(-95px, -42px)",
              cursor: "pointer",
              pointerEvents: slideImages.length <= 1 ? "none" : "auto",
              opacity: slideImages.length <= 1 ? 0.3 : 1,
            }}
          />
        )}
      </div>
      <Zoom
        duration={1000}
        scale={1}
        defaultIndex={activeIndex}
        arrows={false}
        transitionDuration={0}
        // autoplay={autoplay}
        autoplay={AutoPlayy === false ? AutoPlayy : AutoPlay}
        onChange={(from: number, to: number) => {
          onChangeHandler(to);
          setActiveIndex(to);
        }}
      >
        {slideImages.map((slideImage, index) => {
          if (!object_detections) {
            return (
              <div
                onClick={() => {
                  openImageViewer(index);
                }}
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  alignContent: "center",
                  objectFit: "fill",
                  justifyContent: "start",
                  borderRadius: 10,
                  justifySelf: "center",
                  cursor: "pointer",
                  // transform: "translate(125px,0px)",
                  transition: "width 2s, height 4s",
                  //

                  boxShadow: "0 2px 8px rgba(0,0,0,0.3)",

                  overflow: "hidden",

                  backgroundColor: "black",
                  width: 640,
                  height: 480,
                }}
                key={slideImage}
              >
                <img
                  src={
                    slideImages[activeIndex]
                      ? slideImages[activeIndex]
                      : "/assets/img/nodata3.png"
                  }
                  // alt={`img ${index + 1}`}
                  alt={`img ${activeIndex + 1}`}
                  onClick={() => {
                    openImageViewer(index);
                  }}
                  width={640}
                  style={{
                    float: "left",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    objectFit: "fill",
                    justifySelf: "flex-start",
                    borderRadius: 10,
                    cursor: "pointer",
                    boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
                    // width: 640,
                    width: width,
                    height: height,
                  }}
                />
              </div>
            );
          } else {
            return (
              <div
                onClick={() => {
                  openImageViewer(index);
                }}
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  alignContent: "center",
                  objectFit: "fill",
                  borderRadius: 10,
                  cursor: "pointer",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
                  overflow: "hidden",
                  // transition: "width 2s, height 4s",
                  backgroundColor: "black",
                  width: 640,
                  height: 480,
                }}
                key={slideImage}
              >
                <MuiltMapper
                  height={height}
                  img_height={height}
                  auto_play={setAutoplay}
                  imgUrl={
                    slideImage[activeIndex]
                      ? slideImages[activeIndex]
                      : "/assets/img/nodata3.png"
                  }
                  coords={object_detections.map((item: any) => {
                    // let color = 'blue';
                    let color = `rgba(0,0,255, ${
                      isAlpr ? 1 : item.inference_probability
                    })`;

                    if (
                      item.inference_class === "people" ||
                      item.inference_class === "people.person"
                    ) {
                      // color = 'red';
                      color = `rgba(255,0,0, ${
                        isAlpr ? 1 : item.inference_probability
                      })`;
                    }
                    if (
                      item.inference_class === "vehicles" ||
                      item.inference_class === "vehicle.car"
                    ) {
                      // color = '#00FF00';
                      color = `rgba(50,205,50, ${
                        isAlpr ? 1 : item.inference_probability
                      })`;
                    }
                    return {
                      name: slideImages[activeIndex]
                        ? slideImages[activeIndex]
                        : "/assets/img/nodata3.png",
                      shape: "rect",
                      strokeColor: color,
                      coords: [item.x0, item.y0, item.x1, item.y1],
                      preFillColor: "rgb(0,255,0, 0)",
                      fillColor: "rgb(255,0,255, 0)",
                    };
                  })}
                  width={width || 640}
                />
              </div>
            );
          }
        })}
      </Zoom>
      {/* <Zoom
        duration={1000}
        scale={1}
        defaultIndex={activeIndex}
        arrows={false}
        transitionDuration={0}
        // autoplay={autoplay}
        autoplay={AutoPlayy === false ? AutoPlayy : AutoPlay}
        onChange={(from: number, to: number) => {
          onChangeHandler(to);
          setActiveIndex(to);
        }}
      >
        {slideImages.map((slideImage, index) => {
          if (!object_detections) {
            return (
              <div
                onClick={() => {
                  openImageViewer(index);
                }}
                style={{
                  display: "block",
                  borderRadius: 8,
                  overflow: "hidden",
                  transition: "width 2s, height 4s",
                  height: 488,
                }}
                key={slideImage}
              >
                <img
                  src={
                    slideImages[activeIndex]
                      ? slideImages[activeIndex]
                      : "/assets/img/nodata3.png"
                  }
                  // alt={`img ${index + 1}`}
                  alt={`img ${activeIndex + 1}`}
                  onClick={() => {
                    openImageViewer(index);
                  }}
                  width={640}
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    objectFit: "fill",
                    borderRadius: 10,
                    cursor: "pointer",
                    boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
                    width: 640,
                    height: 480,
                  }}
                />
              </div>
            );
          } else {
            return (
              <div
                onClick={() => {
                  openImageViewer(index);
                }}
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  alignContent: "center",
                  objectFit: "fill",
                  borderRadius: 10,
                  cursor: "pointer",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
                  overflow: "hidden",
                  // transition: "width 2s, height 4s",
                  backgroundColor: "black",
                  width: 640,
                  height: 480,
                }}
                key={slideImage}
              >
                <MuiltMapper
                  imgUrl={
                    slideImage[activeIndex]
                      ? slideImages[activeIndex]
                      : "/assets/img/nodata3.png"
                  }
                  coords={object_detections.map((item: any) => {
                    // let color = 'blue';
                    let color = `rgba(0,0,255, ${item.inference_probability})`;

                    if (
                      item.inference_class === "people" ||
                      item.inference_class === "people.person"
                    ) {
                      // color = 'red';
                      color = `rgba(255,0,0, ${item.inference_probability})`;
                    }
                    if (
                      item.inference_class === "vehicles" ||
                      item.inference_class === "vehicle.car"
                    ) {
                      // color = '#00FF00';
                      color = `rgba(50,205,50, ${item.inference_probability})`;
                    }
                    return {
                      name: slideImages[activeIndex]
                        ? slideImages[activeIndex]
                        : "/assets/img/nodata3.png",
                      shape: "rect",
                      strokeColor: color,
                      coords: [item.x0, item.y0, item.x1, item.y1],
                      preFillColor: "rgb(0,255,0, 0)",
                      fillColor: "rgb(255,0,255, 0)",
                    };
                  })}
                  width={width || 640}
                />
              </div>
            );
          }
        })}
      </Zoom> */}
      {/* <Slider {...settings}>
        {slideImages.map((slideImage, index) => (
          <div
            key={slideImage}
            onClick={() => {
              openImageViewer(index);
              setIndyImage(null);
            }}
          >
            {!object_detections ? (
              <div
                style={{
                  display: "block",
                  borderRadius: 8,
                  overflow: "hidden",
                  transition: "width 2s, height 4s",
                  height: 480,
                }}
              >
                <img
                  src={
                    slideImages[activeIndex]
                      ? slideImages[activeIndex]
                      : "/assets/img/nodata3.png"
                  }
                  alt={`img ${activeIndex + 1}`}
                  onClick={() => {
                    openImageViewer(index);
                    setIndyImage(null);
                  }}
                  width={640}
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    objectFit: "fill",
                    borderRadius: 10,
                    cursor: "pointer",
                    boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
                    width: 640,
                    height: 480,
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  alignContent: "center",
                  objectFit: "fill",
                  borderRadius: 10,
                  cursor: "pointer",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
                  overflow: "hidden",
                  backgroundColor: "black",
                  width: 640,
                  height: 480,
                }}
              >
                <MuiltMapper
                  imgUrl={
                    slideImages[activeIndex]
                      ? slideImages[activeIndex]
                      : "/assets/img/nodata3.png"
                  }
                  coords={object_detections.map((item: any) => {
                    let color = `rgba(0,0,255, ${item.inference_probability})`;

                    if (
                      item.inference_class === "people" ||
                      item.inference_class === "people.person"
                    ) {
                      color = `rgba(255,0,0, ${item.inference_probability})`;
                    }
                    if (
                      item.inference_class === "vehicles" ||
                      item.inference_class === "vehicle.car"
                    ) {
                      color = `rgba(50,205,50, ${item.inference_probability})`;
                    }
                    return {
                      name: slideImages[activeIndex]
                        ? slideImages[activeIndex]
                        : "/assets/img/nodata3.png",
                      shape: "rect",
                      strokeColor: color,
                      coords: [item.x0, item.y0, item.x1, item.y1],
                      preFillColor: "rgb(0,255,0, 0)",
                      fillColor: "rgb(255,0,255, 0)",
                    };
                  })}
                  width={width || 640}
                />
              </div>
            )}
          </div>
        ))}
      </Slider> */}
      <div className={classes.indicators}>
        {slideImages.map((slideImage, index) => (
          <div
            key={index}
            className={`${classes.indicator} ${
              index === activeIndex ? classes.active : ""
            }`}
            onClick={() => {
              setIndyImage("indicators");
              setAutoplay(false);
              autoplayHook(false);
              setCurrentImage(index);
              setActiveIndex(index);
              onChangeHandler(index);
            }}
          />
        ))}
      </div>
      <CustomModal
        closeHandler={() => {
          closeImageViewer();
        }}
        open={isViewerOpen}
      >
        {!object_detections ? (
          <img
            src={slideImages[activeIndex]}
            alt={`img ${activeIndex + 1}`}
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              // objectFit: "fill",
              objectFit: "cover",
              borderRadius: 10,
              cursor: "pointer",
              boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
              maxWidth: 2000,
              maxHeight: 1000,
            }}
          />
        ) : (
          <div
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              objectFit: "fill",
              borderRadius: 10,
              cursor: "pointer",
              boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
              maxWidth: 2000,
              maxHeight: 1000,
            }}
          >
            <MuiltMapper
              height={height}
              auto_play={setAutoplay}
              imgUrl={slideImages[activeIndex]}
              coords={object_detections.map((item: any) => {
                // let color = 'blue';
                let color = `rgba(0,0,255, ${item.inference_probability})`;

                if (
                  item.inference_class === "people" ||
                  item.inference_class === "people.person"
                ) {
                  // color = 'red';
                  color = `rgba(255,0,0, ${item.inference_probability})`;
                }
                if (
                  item.inference_class === "vehicles" ||
                  item.inference_class === "vehicle.car"
                ) {
                  // color = '#00FF00';
                  color = `rgba(50,205,50, ${item.inference_probability})`;
                }
                return {
                  name: slideImages[activeIndex],
                  shape: "rect",
                  strokeColor: color,
                  coords: [item.x0, item.y0, item.x1, item.y1],
                  preFillColor: "rgb(0,255,0, 0)",
                  fillColor: "rgb(255,0,255, 0)",
                };
              })}
              width={width || 640}
              img_height={height}
            />
          </div>
        )}
      </CustomModal>
    </>
  );
};

export default SlideShow;
