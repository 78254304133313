import LuxonUtils from "@date-io/luxon";
import { Guid } from "guid-typescript";

export class TimeEntrySetTimeOnly {
  entries: TimeEntryTimeOnly[];
  setString?: string;

  constructor(setString?: string[]) {
    this.entries = [];
    if (setString && setString.length > 0) {
      if (
        setString &&
        setString[0].startsWith("(") &&
        setString[0].endsWith(")")
      ) {
        this.setString = setString[0].replace('"*",', "");
        this.setString = this.setString.split(",").join('","');
        this.resetEntries();
      }
    }
  }

  private static TimeEntryRegex = /^"(?:(?<time>\d{2}:\d{2})\s*)?"$/;
  private static EntryFullMatch = /^\((.+)\)/;

  resetEntries = () => {
    const entries = [];
    if (
      this.setString &&
      TimeEntrySetTimeOnly.EntryFullMatch.test(this.setString)
    ) {
      const entryStrings = this.setString
        .replace("(", "")
        .replace(")", "")
        .split(",");
      for (const entry of entryStrings) {
        const entryMatch = TimeEntrySetTimeOnly.TimeEntryRegex.exec(entry);
        if (entryMatch && entryMatch.groups) {
          entries.push(new TimeEntryTimeOnly(entryMatch.groups.time));
        }
      }
    }
    this.entries = entries;
  };

  clearEntries = () => {
    this.entries = [];
  };

  updateString = () => {
    this.setString = `[${this.entries
      .map((entry) => entry.timeString)
      .join(",")}]`;
  };

  addTimeEntry = () => {
    this.entries.push(new TimeEntryTimeOnly("00:00:00"));
  };

  deleteTimeEntry = (timeEntry: TimeEntryTimeOnly) => {
    const index = this.entries.indexOf(timeEntry);
    this.entries.splice(index, 1);
  };

  toString = () => {
    var entriesstring: string = "[";
    if (this.entries) {
      this.entries.forEach((entry: { time: any }) => {
        entriesstring = entriesstring + '("' + entry.time + '")' + ",";
      });
    }
    if (entriesstring.length > 1) {
      entriesstring = entriesstring.substr(0, entriesstring.length - 1);
    }
    entriesstring = entriesstring + "]";

    return entriesstring;
  };
}

export class TimeEntryTimeOnly {
  time: string;

  key: Guid;

  private static TimeRegex =
    /^(?<hours>[0-1]?[0-9]|2[0-4]):(?<mins>[0-5][0-9]):(?<seconds>[0-5][0-9])$/;
  private static ShortenedTimeRegex =
    /^(?<hours>[0-1]?[0-9]|2[0-4]):(?<mins>[0-5][0-9])$/;

  private static ValidateTimeString(timeString: string) {
    if (!timeString) {
      return false;
    }
    return TimeEntryTimeOnly.TimeRegex.test(timeString);
  }

  private static ValidateTimePair(time: string) {
    var utils = new LuxonUtils();
    if (!time) {
      return false;
    }
    if (!TimeEntryTimeOnly.ValidateTimeString(time)) {
      return false;
    }
    return true;
  }

  get valid() {
    // Check required times
    return TimeEntryTimeOnly.ValidateTimePair(this.time);
  }

  get timeString() {
    let timeString = `${this.time}`;
    return `("${timeString}")`;
  }

  setTime = (timeString: string) => {
    if (!timeString) {
      return;
    }

    // Append seconds to shortened time strings
    if (TimeEntryTimeOnly.ShortenedTimeRegex.test(timeString)) {
      timeString += ":00";
    }

    // Ensure time matches correct format
    if (!TimeEntryTimeOnly.TimeRegex.test(timeString)) {
      return false;
    }

    this.time = timeString;
  };
  constructor(time: string) {
    this.time = time;
    this.key = Guid.create();
  }
}
