import React, { Fragment, useEffect, useState } from "react";
import { useRef } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../../../spinner/Spinner";
import {
  faChevronDown,
  faCloudArrowUp,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";

import { AppStore } from "../../../stores/AppStore";
import { visibleSortedGridRowsAsArraySelector } from "@material-ui/data-grid";
import { Upload } from "antd";
import {
  MoveSelect,
  Site,
  Org,
  Hub,
  ReportGroup,
  SiteHealth,
  NetworkConfig,
  Sensor,
} from "../../../services/FunctionService";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "10px",
  fontSize: "12px",
  textAlign: "center",
  transform: "translate(-50%, -50%)",
  width: 650,
  height: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface AddLogosModal {
  activeOrg?: any;
  reloadLogos?: any;
  thumbnail?: any;
  appStore?: any;
  currentOrg?: number;
  openModal: boolean;
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddLogosModal = (props: AddLogosModal) => {
  const [Thumbnail, setThumbnail] = useState<{
    logo: { stringb64: string } | null;
  }>({
    logo: props?.activeOrg?.logo?.thumbnail,
  });

  ////  Comments
  const [updateMessage, setUpdateMessage] = useState<string>("");
  const [successMsg, setSuccessMsg] = useState<null | boolean>(null);
  const [upLoadMessage, setUploadMessage] = useState<string | null>(null);

  /////////////////////////////////////////////////////////////////////
  const inputRef = useRef<any | null>(null);
  const [selectedImageDataUrl, setSelectedImageDataUrl] = React.useState<
    string | undefined
  >(undefined);

  const [isDeleting, setIsDeleting] = useState(false);

  function handleDeleteImage() {
    setIsDeleting(true);

    // Simulate the deletion process with a 5-second delay
    setTimeout(() => {
      // Perform the actual deletion logic here (e.g., make an API request)

      // After deletion, set isDeleting back to false
      setIsDeleting(false);
    }, 3000); // 5000 milliseconds = 5 seconds
  }

  const dismissHandler = () => {
    setUploadMessage(null);
    setSelectedImageDataUrl(undefined);
    props.handleClose(false);
    setSuccessMsg(null);
  };

  const deleteImageHandler = () => {
    setSelectedImageDataUrl(undefined);
  };

  const closeModal = () => {
    props.handleClose(false);
  };

  const uploadLogo = async () => {
    handleDeleteImage();
    if (selectedImageDataUrl) {
      try {
        const token = await props?.appStore?.authService?.getAuthorisedToken();

        if (!token) {
          console.log("Token not available");
          return;
        }

        const add_logo = await props.appStore?.functionService?.addLogo(
          token,
          props.currentOrg,
          selectedImageDataUrl
        );

        if (add_logo) {
          setSuccessMsg(add_logo.success);
        } else {
          console.log("No message available from addLogo");
        }
        if (add_logo.success === true) {
          setUploadMessage("Logo has successfully been uploaded!");
        } else if (add_logo.success === false) {
          setUploadMessage("An error occured whilst uploaded!");
        }
      } catch (err) {
        console.error("Error:", err);
      } finally {
        setSelectedImageDataUrl(undefined);
      }
    } else {
      return;
    }
  };
  const updateLogo = async () => {
    handleDeleteImage();
    if (selectedImageDataUrl) {
      try {
        const token = await props?.appStore?.authService?.getAuthorisedToken();

        if (!token) {
          console.log("Token not available");
          return;
        }

        const update_logo = await props.appStore?.functionService?.addLogo(
          token,
          props.currentOrg,
          selectedImageDataUrl
        );

        if (update_logo) {
          setUpdateMessage(update_logo.msg);
          setSuccessMsg(update_logo.success);
        } else {
          console.log("No message available from addLogo");
        }
        if (update_logo.success === true) {
          setUploadMessage("Logo has successfully been updated!");
        } else if (update_logo.success === false) {
          setUploadMessage("An error occured whilst updating!");
        }
      } catch (err) {
        console.error("Error:", err);
      } finally {
        setSelectedImageDataUrl(undefined);
      }
    } else {
      return;
    }
  };

  const handleClick = () => {
    // 👇️ open file input box on click of another element
    inputRef.current.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0];
    if (fileObj) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && typeof e.target.result === "string") {
          setSelectedImageDataUrl(e.target.result);
        }
      };
      reader.readAsDataURL(fileObj);
    }
  };

  return (
    <div>
      {Thumbnail?.logo === null && (
        <div style={{ position: "relative" }}>
          <Modal
            open={props.openModal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={{
                  width: 600,
                  height: 250,
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                }}
              >
                <input
                  style={{ display: "none" }}
                  ref={inputRef}
                  type="file"
                  onChange={handleFileChange}
                />

                {selectedImageDataUrl ? (
                  <div>
                    <div className="image">
                      {isDeleting ? (
                        <Spinner />
                      ) : (
                        <img
                          src={selectedImageDataUrl}
                          alt="Selected Image"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100px",
                            marginTop: "20px",
                          }}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    {upLoadMessage !== null ? (
                      <div className="upload_update">
                        <h3
                          className="upload_message"
                          style={{
                            marginTop: "50px",
                            fontWeight: "bold",
                            color: "rgba(14, 67, 132, 0.88)",
                            fontSize: "19px",
                            marginRight: "10px",
                          }}
                        >
                          {upLoadMessage}
                        </h3>
                        <Button
                          onClick={dismissHandler}
                          variant="contained"
                          color="primary"
                          style={{
                            marginTop: "auto",
                            alignSelf: "flex-start",
                            padding: "5px 20px",
                            fontWeight: "bold",
                            transform: "translate(2px,50px)",
                            color: "white",
                            fontSize: "10px",
                          }}
                        >
                          Dismiss
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <FontAwesomeIcon
                          style={{
                            fontSize: "95px",
                            color: "#15325F",
                            cursor: "pointer",
                          }}
                          icon={faCloudArrowUp}
                        />

                        <Typography
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            marginTop: "25px",
                          }}
                        >
                          Click the upload button to upload an Image
                        </Typography>
                      </div>
                    )}
                  </div>
                )}

                {upLoadMessage !== null ? (
                  ""
                ) : (
                  <div>
                    {!selectedImageDataUrl ? (
                      <div
                        className="btn-group"
                        style={{
                          transform: "translate(250px, 170px)",
                          position: "absolute",
                        }}
                      >
                        <Button
                          onClick={handleClick}
                          variant="contained"
                          color="primary"
                          style={{
                            marginTop: "-300px",
                            alignSelf: "flex-start",
                            padding: "5px 20px",
                            fontWeight: "bold",
                            color: "white",
                            fontSize: "10px",
                          }}
                        >
                          Choose File
                        </Button>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "60%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: "150px",
                          marginLeft: "30px",
                          margin: "auto",
                        }}
                      >
                        <Button
                          onClick={uploadLogo}
                          variant="contained"
                          style={{
                            marginTop: "40px",
                            alignSelf: "flex-start",
                            padding: "5px 20px",
                            fontWeight: "bold",
                            color: "white",
                            fontSize: "10px",
                            backgroundColor: "rgba(25, 58, 165, 0.8)",
                          }}
                        >
                          Upload Image
                        </Button>
                        <Button
                          disabled={selectedImageDataUrl ? false : true}
                          onClick={deleteImageHandler}
                          variant="contained"
                          style={{
                            marginTop: "40px",
                            alignSelf: "flex-start",
                            padding: "5px 20px",
                            fontWeight: "bold",
                            color: "white",
                            fontSize: "10px",
                            backgroundColor: "red",
                          }}
                        >
                          Delete Image
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Box>
          </Modal>
        </div>
      )}
      {Thumbnail?.logo !== null && (
        <div style={{ position: "relative" }}>
          <Modal
            open={props.openModal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={{
                  width: 600,
                  height: 250,
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                }}
              >
                <input
                  style={{ display: "none" }}
                  ref={inputRef}
                  type="file"
                  onChange={handleFileChange}
                />
                <div>
                  <div className="image">
                    {isDeleting ? (
                      <Spinner />
                    ) : (
                      <>
                        {selectedImageDataUrl ? (
                          <img
                            src={selectedImageDataUrl}
                            alt="Selected Image"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100px",
                              marginTop: "20px",
                            }}
                          />
                        ) : (
                          <>
                            {upLoadMessage !== null ? (
                              <h3
                                className="upload_message"
                                style={{
                                  marginTop: "50px",
                                  fontWeight: "bold",
                                  color: "rgba(14, 67, 132, 0.88)",
                                  fontSize: "19px",
                                  marginRight: "10px",
                                }}
                              >
                                {upLoadMessage}
                              </h3>
                            ) : (
                              <img
                                src={`data:image/png;base64,${Thumbnail?.logo?.stringb64}`}
                                alt="Selected Image"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100px",
                                  width: "100px",
                                  height: "80px",
                                  marginTop: "20px",
                                }}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: "60%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "150px",
                    marginLeft: "30px",
                    margin: "auto",
                  }}
                >
                  <>
                    {upLoadMessage !== null ? (
                      <div>
                        <Button
                          onClick={dismissHandler}
                          variant="contained"
                          color="primary"
                          style={{
                            marginTop: "auto",
                            alignSelf: "flex-start",
                            padding: "5px 20px",
                            fontWeight: "bold",
                            transform: "translate(133px,0px)",
                            color: "white",
                            fontSize: "10px",
                          }}
                        >
                          Dismiss
                        </Button>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: "150px",
                          marginLeft: "30px",
                          margin: "auto",
                          transform: `${
                            selectedImageDataUrl
                              ? "translate(-100px,0)"
                              : "translate(0,0)"
                          }`,
                        }}
                      >
                        <Button
                          onClick={handleClick}
                          variant="contained"
                          color="primary"
                          style={{
                            marginTop: "auto",
                            alignSelf: "flex-start",
                            padding: "5px 20px",
                            fontWeight: "bold",
                            transform: "translate(125px,0px)",
                            color: "white",
                            fontSize: "10px",
                          }}
                        >
                          Replace Image
                        </Button>
                        {selectedImageDataUrl && (
                          <Button
                            onClick={updateLogo}
                            variant="contained"
                            style={{
                              marginTop: "auto",
                              alignSelf: "flex-start",
                              padding: "5px 20px",
                              fontWeight: "bold",
                              transform: "translate(90px,0px)",
                              color: "white",
                              fontSize: "10px",
                              backgroundColor: "red",
                            }}
                          >
                            Update Image
                          </Button>
                        )}
                      </div>
                    )}
                  </>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default AddLogosModal;
