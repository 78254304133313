import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Collapse, message } from "antd";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Zone from "./Zone";
import TimeSelector from "./TimeSelector";
import { Tooltip } from "@material-ui/core";
import { Site, Sensor } from "../../../services/FunctionService";
import { AppStore } from "../../../stores/AppStore";
import Summary from "./Summary";
import { MainContext } from "../../containers/HomePage/HomePage";
import { getBaseUrl } from "../../HelperFunctions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // width: "100%",
      // height: 600
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: 0,
      marginBottom: theme.spacing(1),
    },
  })
);

function getSteps() {
  return ["Rule Configurations", "Select time for the Rule", "Review"];
}

export default function RulesWizard({
  closeWizard,
  appStore,
  sensor,
  site,
  saveZonesHandler,
  setOpenWizardModal,
  sensorDefinedRule,
  sensorDefinedRules,
}: {
  closeWizard: () => void;
  appStore?: AppStore;
  sensor: Sensor;
  site?: Site;
  saveZonesHandler?: () => void;
  setOpenWizardModal?: (val: boolean) => void;
  sensorDefinedRule: any;
  sensorDefinedRules: any[];
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [channelID, setChannelD] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const steps = getSteps();
  const [msg, setMsg] = React.useState("Loading...");

  const ctx = React.useContext(MainContext);

  const postRuleHandler = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();

    if (token) {
      var zone_sanitised = ctx.zone_config;
      var cadence_secs: any = undefined;
      for (var i in zone_sanitised) {
        // if (zone_sanitised[i]["zone"] == "full image") {
        //   zone_sanitised[i]["zone"] = "full_image";
        // }
        // if (zone_sanitised[i]["cadence_secs"]) {
        //   cadence_secs = +zone_sanitised[i]["cadence_secs"].split(" ")[0] * 60;
        //   zone_sanitised[i]["cadence_secs"] = undefined;
        // }
        if (zone_sanitised[i]["id"]) {
          zone_sanitised[i]["id"] = undefined;
        }
        if (zone_sanitised[i]["meta"]) {
          zone_sanitised[i]["meta"] = undefined;
        }

        if (zone_sanitised[i]["rule_type"]) {
          zone_sanitised[i]["rule_type"] = undefined;
        }
      }

      if (sensorDefinedRule?.fields?.cadence_secs) {
        cadence_secs = sensorDefinedRule?.fields?.cadence_secs?.enabled
          ? +ctx.fetchInterval.split(" ")[0] * 60
          : null;
        console.log("Setting Cadence secs:", cadence_secs);
      }
      const ruleSubType = ctx.rule_type
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char: string) => char.toUpperCase());
      const rule = {
        rule_name: ctx.rule_name.replaceAll("_", " "),
        cadence_secs: cadence_secs,
        rule_subtype: sensorDefinedRule?.fields?.rule_subtype?.subtype,
        rule_type: ctx.rule_type.toLowerCase().replace(/ /g, "_"),
        alert_type: ctx.alertType.toLowerCase(),
        whitelist_id:
          sensorDefinedRule?.fields?.whitelist_id !== undefined
            ? ctx.whitelistName?.id || null
            : undefined,
        zone_configs:
          sensorDefinedRule?.fields?.zone_configs === undefined
            ? undefined
            : zone_sanitised,
        valid_days_and_times: !sensorDefinedRule?.fields?.valid_days_and_times
          ? undefined
          : ctx.formattedTime(ctx.timeEntrySet) === null ||
            ctx.formattedTime(ctx.timeEntrySet) === ""
          ? '[("Mon,Tue,Wed,Thu,Fri,Sat,Sun", "00:00:00-24:00:00")]'
          : ctx.formattedTime(ctx.timeEntrySet),
        exact_times: !sensorDefinedRule?.fields?.exact_times
          ? undefined
          : (ctx.formattedTime(ctx.timeEntrySet) === null ||
              ctx.formattedTime(ctx.timeEntrySet) === "") &&
            ctx.rule_type === "snapshot"
          ? '[("Mon,Tue,Wed,Thu,Fri,Sat,Sun", "00:00:00")]'
          : `${ctx.formattedTime(ctx.timeEntrySet)}`.replace(/-24:00:00/g, ""),
        // environment: ctx.rule_environment,
      };
      const ruleRequest = JSON.stringify({
        rule: rule,
        sensor_id: sensor?.sensor_id,
        meta:
          ctx.endpointIds.length > 0
            ? { end_point_ids: ctx.endpointIds }
            : { end_point_ids: null },
        class_label: ctx.rule_type === "Snapshot" ? "snapshot" : undefined,
      });

      try {
        const baseUrl = getBaseUrl();
        let ruleRes: any = await fetch(baseUrl + "/manage_sensor_rules", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: ruleRequest,
        });
        const ruleResult = (await (await ruleRes).json()) as any;
        if (ruleResult.success) {
          message.success("successfully created advanced rule");
          let msg =
            ctx.rule_type === "Known Vehicle Filter"
              ? "Please refer to the ALPR page inorder to whitelist your vehicle(s)."
              : "Request successfully submitted";
          setMsg(msg);
        } else {
          setMsg("failed to create advanced rule, please try again later");
          message.error("failed to create advanced rule");
        }

        ctx.clearRulesState();
        ctx.setRuleName("");
        ctx.setRuleList([]);
        ctx.setEndpointIds([]);
      } catch (err) {
        console.log("err -> ", err);
        setMsg("Something went wrong please try again later!");
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const handleNext = () => {
    if (
      // (ctx.rule_type === "Scene Change Alert" ||
      //   ctx.rule_type === "Snapshot" ||
      //   ctx.rule_type === "unusual_activity")
      sensorDefinedRule?.fields?.zone_configs === undefined &&
      !ctx.rule_name
    ) {
      ctx.setRuleName(
        `${sensorDefinedRule?.fields?.rule_name?.default} ${ctx.last_rule_id}`.trim()
      );
      // ctx.setRuleName(
      //   `${ctx.rule_type.toLocaleUpperCase().replace("_", " ")} ${ctx.last_rule_id
      //     }`.trim()
      // );
    }

    if (activeStep === steps.length - 1) {
      if (
        !ctx.rule_name ||
        (ctx.zone_config.length === 0 &&
          // ctx.rule_type !== "Scene Change Alert" &&
          // ctx.rule_type !== "unusual_activity" &&
          // ctx.rule_type !== "Snapshot")
          sensorDefinedRule?.fields?.zone_configs !== undefined)
      ) {
        setMsg("Invalid inputs, please enter missing fields");
      } else {
        postRuleHandler();
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    closeWizard();
  };

  React.useEffect(() => {
    const fetchData = async () => {
      if (site && site.site_id) {
        setLoading(true);
        var token = await appStore?.authService.getAuthorisedToken();
        if (token) {
          var alertchannelz: any[] =
            await appStore?.functionService.getAlertChannelsForSite(
              token,
              site.site_id
            );
          if (alertchannelz) {
            let ids_list = alertchannelz.map((channel) => {
              if (channel.site_id === site.site_id) {
                return channel.alert_channel_id;
              }
            });

            if (ids_list[0]) {
              setChannelD(ids_list[0]);
            }
          }
        }
        setLoading(false);
      }
    };
    fetchData();
  }, [site]);

  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return (
          <Zone
            appStore={appStore}
            sensor={sensor}
            site={site}
            handleNext={handleNext}
            showTable={true}
            showWizard={false}
            // showTable={showTable}
            setOpenWizardModal={setOpenWizardModal}
            saveZonesHandler={saveZonesHandler}
            sensorDefinedRule={sensorDefinedRule}
            sensorDefinedRules={sensorDefinedRules}
          />
        );
      case 1:
        return (
          <TimeSelector editable noEndTime={ctx.rule_type === "snapshot"} />
          // <TimeSelector editable noEndTime={sensorDefinedRule?.rule_subtype?.subtype === "Snapshot"} />
        );
      case 2:
        return <Summary site={site} />;
      default:
        return "Unknown stepIndex";
    }
  }

  let disabled = false;

  if (
    (activeStep === 0 &&
      (ctx.rule_name === "" || ctx.zone_config.length === 0)) ||
    activeStep === steps.length
  ) {
    disabled = true;
  }

  console.log(
    "handleNext: ",
    sensorDefinedRule?.rule_subtype?.subtype !== "SceneChangeAlert" &&
      sensorDefinedRule?.rule_subtype?.subtype !== "UnusualActivity" &&
      sensorDefinedRule?.rule_subtype?.subtype !== "Snapshot",
    "sensorDefinedRule?.rule_subtype?.subtype ",
    sensorDefinedRule?.fields?.rule_subtype?.subtype,
    "ctx.rule_type: ",
    ctx.rule_type
  );
  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div style={{ margin: 20, padding: 20 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                className={classes.instructions}
                style={{
                  color: msg.startsWith("Invalid") ? "red" : "#15325F",
                  fontWeight: 600,
                }}
              >
                {msg}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button onClick={handleReset}>Finish</Button>
            </div>
          </div>
        ) : (
          <div
            style={{
              margin: 20,
              paddingLeft: 20,
              paddingRight: 20,
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                width: "100%",
                height: "100%",
                padding: 20,
              }}
            >
              <Button
                onClick={() => {
                  closeWizard();
                  ctx.clearRulesState();
                }}
                className={classes.backButton}
              >
                Cancel
              </Button>
              {activeStep !== 0 && (
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                  variant="contained"
                  color="primary"
                >
                  Prev
                </Button>
              )}
              &nbsp;&nbsp;
              <Tooltip
                placement="bottom"
                title={disabled ? "Please enter all fields" : ""}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={
                    disabled &&
                    // ctx.rule_type !== "Scene Change Alert" &&
                    // ctx.rule_type !== "unusual_activity" &&
                    // ctx.rule_type !== "Snapshot"
                    // (sensorDefinedRule?.fields?.rule_subtype?.subtype !== "SceneChangeAlert" &&
                    //   sensorDefinedRule?.fields?.rule_subtype?.subtype !== "UnusualActivity" &&
                    //   sensorDefinedRule?.fields?.rule_subtype?.subtype !== "Snapshot" &&
                    //   sensorDefinedRule?.fields?.rule_subtype?.subtype !== "SuspiciousActivitySelfStorage") ||
                    sensorDefinedRule?.fields?.zone_configs !== undefined
                  }
                >
                  {activeStep === steps.length - 1 ? "Submit" : "Next"}
                </Button>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
