import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { AppStore } from "../../../../stores/AppStore";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: 450,
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

const style2 = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: 290,
  width: "auto",
  textAlign: "center",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

interface DeleteReportGroupModalProps {
  appStore: AppStore;
  selectedOrgID: number;
  groupName: string;
  reloadData: React.Dispatch<React.SetStateAction<boolean>>;
  openCloseModal: boolean;
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteReportGroupModal = (props: any) => {
  const handleClose = () => props.handleClose(false);

  //////////////////////// DELETE REPORT GROUP //////////////////////////////

  const [popMessage, setPopMessage] = useState<string>("");

  console.log("POP:", popMessage);

  const okHandler = () => {
    setPopMessage("");
    props?.handleClose(false);
    props?.reloadData(Math.floor(Math.random() * 100) + 1);
  };
  async function deleteReportGroup() {
    const token = await props?.appStore?.authService.getAuthorisedToken();
    if (token) {
      let delete_report_group =
        await props?.appStore?.functionService.deleteReportGroup(
          token,
          props?.selectedOrgID
        );
      if (delete_report_group) {
        if (delete_report_group.success) {
          setPopMessage("Deleted report group");
        } else {
          setPopMessage(delete_report_group.msg);
        }
      }
    }
  }
  const confirmDeleteHandler = () => {
    deleteReportGroup();
  };
  return (
    <div>
      {popMessage !== "" && (
        <Modal
          open={props?.openCloseModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, fontSize: "13px" }}
            >
              {popMessage}
            </Typography>
            <button
              style={{
                color: "#1f3161",
                backgroundColor: "#E0E0E0",
                border: "none",
                marginTop: "30px",
                cursor: "pointer",
                fontWeight: "bold",
                padding: "10px 18px",
                borderRadius: "5px",
              }}
              onClick={okHandler}
            >
              OK
            </button>
          </Box>
        </Modal>
      )}
      {popMessage === "" && (
        <Modal
          open={props?.openCloseModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-description"
              sx={{
                mt: 2,
                fontSize: "13px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              Are you sure you want to delete {props?.groupName}?
            </Typography>
            <div
              className="buttons"
              style={{
                marginTop: "15px",
                marginLeft: "10px",
                width: "90%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <button
                style={{
                  padding: "5px 25px",
                  borderRadius: "5px",
                  margin: "1px 15px",
                  color: "white",
                  border: "1px solid #1f3161",
                  backgroundColor: "#1f3161",
                  cursor: "pointer",
                }}
                onClick={() => props?.handleClose(false)}
              >
                NO
              </button>
              <button
                style={{
                  padding: "5px 25px",
                  borderRadius: "5px",
                  margin: "1px 2px",
                  color: "white",
                  border: "1px solid #1f3161",
                  cursor: "pointer",
                  backgroundColor: "#1f3161",
                }}
                onClick={confirmDeleteHandler}
              >
                YES
              </button>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default DeleteReportGroupModal;
