import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { AppStore } from "../../stores/AppStore";
import { useStyles } from "../styling/global/global";
import { makeStyles } from "@material-ui/core/styles";
import { getBaseUrl } from "../HelperFunctions";

const useStyle = makeStyles({
  paper: {
    border: "2px solid #6D809D",
    color: "#15325F",
  },
});

interface Props {
  appStore?: AppStore;
  setLoading: (b: boolean) => void;
  setGroupId: (id: number) => void;
  groupid: number;
  enabled?: boolean;
}

const OrgtreeAutocomplete: React.FC<Props> = ({
  appStore,
  setGroupId,
  setLoading,
  groupid,
  enabled,
}) => {
  const [orgslist, setOrgsList] = useState<any>([]);
  const [groupTreeName, setGroupTreeName] = useState("");
  const [groupname, setGroupName] = useState("Please select group");

  const classes = useStyles();
  const styler = useStyle();

  const fetchOrgsData = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    if (token) {
      try {
        const request = JSON.stringify({
          enabled: enabled,
          paginate: false,
          verbose: false,
          incl_tree_names: true,
        });

        const res = fetch(baseUrl + "/list_orgs", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = await (await res).json();
        const orglist = result;
        if (orglist && orglist.success) {
          let orgz = orglist?.orgs;
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names - b.org_tree_names;
          });
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names.length - b.org_tree_names.length;
          });
          setOrgsList(orgz);
          setLoading(false);
        }
      } catch (err) {
        return err;
      }
    }
  };

  // Fetch Organizations
  useEffect(() => {
    fetchOrgsData();
  }, [groupid]);

  const handleChangeAuto = (value: any) => {
    if (!value || +value.org_id === groupid) {
      return;
    }
    const new_group_id = +value.org_id;
    setGroupId(new_group_id);
    let group = orgslist.find((x: any) => x.org_id === value.org_id);
    if (group) {
      let tree = group.org_tree_names!.map((item: any) => `   /   ${item} `);
      let treeString = tree.toString();
      const rpl = treeString.replace(/,/g, "");
      setGroupTreeName(rpl);

      setGroupName(group.org_name);
    }
  };

  return (
    <Autocomplete
      id="combo-box-demo"
      // className={classes.form_component_medium}
      options={orgslist}
      onChange={(event: React.ChangeEvent<any>, value: any) => {
        handleChangeAuto(value);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
      getOptionLabel={(option) => {
        let tree = option.org_tree_names!.map((item: any) => `   /   ${item} `);
        let treeString = tree.toString();
        const rpl = treeString.replace(/,/g, "");
        return `${rpl}`;
      }}
      style={{
        borderRadius: 0,
        width: 380,
        color: "#6D809D",
        marginTop: 0,
        marginRight: 3,
        backgroundColor: "white",
      }}
      classes={{ paper: styler.paper }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            <span className={classes.bold_text} style={{ color: "#15325F" }}>
              {groupname ? groupname : "Please Select Group"}
            </span>
          }
          variant="outlined"
          value={groupid}
          className={classes.bold_text}
        />
      )}
    />
  );
};

export default OrgtreeAutocomplete;
