import React from "react";
import classes from "./ui.module.css";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Button, Divider, Space, Tooltip } from "antd";
// import Tooltip from "@material-ui/core/Tooltip";

interface Props {
  title:
    | "High Alerting Groups"
    | "High Alerting Sites"
    | "Current Site Health"
    | "Low Alerting Sites";
  handleItemSelection: (e: any) => void;
  stats: {
    name: string;
    value: string | number;
    item?: any;
    type?: "site" | "org" | "site_health";
    details?: any;
  }[];
  isNotLoading?: boolean;
  children: React.ReactElement;
}

const SiteActivityStats: React.FC<Props> = (props: Props) => {
  return (
    <div className={classes.search}>
      <div className={classes.activity_stats_header}>
        <div className={classes.activity_stats_topbar_icon}>
          {props.children}
        </div>
        <div className={classes.activity_stats_topbar_title}>{props.title}</div>
      </div>
      <div className={classes.activity_stats}>
        <div
          className={classes.activity_stats_body}
          style={{
            cursor:
              props.title === "High Alerting Groups" ||
              props.title === "Current Site Health"
                ? "default"
                : "pointer",
          }}
        >
          {!props.isNotLoading ? (
            <>
              {props.stats.map((item: any) => {
                if (item.details) {
                  return (
                    <Tooltip
                      overlayStyle={{
                        maxHeight: 500,
                        maxWidth: 800,
                        overflowY: "scroll",
                      }}
                      color="blue"
                      placement="left"
                      title={item.details}
                    >
                      <div
                        className={classes.activity_stats_body_item}
                        style={{
                          cursor:
                            props.title === "High Alerting Groups" ||
                            props.title === "Current Site Health"
                              ? "default"
                              : "pointer",
                        }}
                        onClick={() => {
                          if (props.title === "High Alerting Groups") {
                            return;
                          }

                          props.handleItemSelection(item.item);
                        }}
                      >
                        <p className={classes.activity_stats_body_name}>
                          {item.name.length > 25
                            ? item.name.slice(0, 25) + "... "
                            : item.name}
                        </p>
                        <p className={classes.activity_stats_body_value}>
                          {item.value}
                        </p>
                      </div>
                    </Tooltip>
                  );
                } else {
                  return (
                    <div
                      className={classes.activity_stats_body_item}
                      style={{
                        cursor:
                          props.title === "High Alerting Groups" ||
                          props.title === "Current Site Health"
                            ? "default"
                            : "pointer",
                      }}
                      onClick={() => {
                        if (props.title === "High Alerting Groups") {
                          return;
                        }

                        props.handleItemSelection(item.item);
                      }}
                    >
                      <p className={classes.activity_stats_body_name}>
                        {item.name.length > 25
                          ? item.name.slice(0, 25) + "... "
                          : item.name}
                      </p>
                      <p className={classes.activity_stats_body_value}>
                        {item.value}
                      </p>
                    </div>
                  );
                }
              })}
            </>
          ) : (
            <div style={{ marginTop: 60 }}>
              <LinearProgress />
            </div>
          )}
          {!props.isNotLoading && props.stats.length === 0 && (
            <p style={{ marginLeft: 40 }}>No data</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SiteActivityStats;
