import React from "react";
import classes from "./ui.module.css";

interface props {
  name: string;
  value: string | number;
  name_color?: string;
  value_color?: string;
  children: React.ReactElement;
}

const Metric: React.FC<props> = (props) => {
  return (
    <div className={classes.metric_wrapper}>
      <div className={classes.left_metric_wrapper}>{props.children}</div>
      {!props.name_color && !props.value_color && (
        <div className={classes.right_metric_wrapper}>
          <div className={classes.left_metric_wrapper_value}>{props.value}</div>
          <div className={classes.left_metric_wrapper_name}>{props.name}</div>
        </div>
      )}
      {props.name_color && props.value_color && (
        <div className={classes.right_metric_wrapper}>
          <div
            className={classes.left_metric_wrapper_value}
            style={{ color: props.value_color }}
          >
            {" " + props.value}
          </div>
          <div
            className={classes.left_metric_wrapper_name}
            style={{ color: props.name_color, fontSize: 11 }}
          >
            {props.name}
          </div>
        </div>
      )}
    </div>
  );
};

export default Metric;
