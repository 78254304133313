import React, { useState } from "react";
import { Collapse, message } from "antd";
import { useStyles } from "../../styling/global/global";
import ZoneItem from "./ZoneItem";
import CustomModal from "../../UI/CustomModal";
import AddZoneForm from "./AddZoneForm";
import { hasSnapshotRuleState } from "./Zone";
import { useRecoilState } from "recoil";
import CustomButton from "../../UI/CustomButton";
import TimeSelector from "./TimeSelector";
import { TimeEntrySet } from "../../../core/models/TimeEntry";
import { getBaseUrl } from "../../HelperFunctions";
import { AppStore } from "../../../stores/AppStore";
import { MainContext } from "../../containers/HomePage/HomePage";

const { Panel } = Collapse;

interface Props {
  reloadRules: () => void;
  reloadZones: () => void;
  sensorId: number;
  appStore?: AppStore;
  timeZone: () => void;
  site: any;
  sensorDefinedRule: any;
  sensorDefinedRules?: any[];
}

const RulesList: React.FC<Props> = ({
  reloadRules,
  reloadZones,
  sensorId,
  appStore,
  timeZone,
  site,
  sensorDefinedRule,
  sensorDefinedRules,
}) => {
  const style = useStyles();
  const [openEditHandler, setEditHandler] = useState(false);
  const [, setLoading] = useState(false);
  const [zoneId, setZoneId] = useState(0);
  const [ruleId, setRuleId] = useState(0);
  const [objectClass, setObjectClass] = useState("");
  const [zoneName, setZoneName] = useState("");
  const ctx = React.useContext(MainContext);
  const [openDelete, setOpenDelete] = useState(false);
  const [, setHasSnapshotRule] = useRecoilState(hasSnapshotRuleState);

  const panelStyle = {
    background: "white",
    borderRadius: 8,
    minWidth: 780,
    marginTop: 5,
    color: "#15325F",
    fontWeight: 500,
    fontSize: 13,
  };

  const EditIcon = () => (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6484 3.70336L11.2974 0.351573C11.186 0.240112 11.0537 0.151695 10.908 0.0913719C10.7624 0.0310484 10.6064 0 10.4488 0C10.2911 0 10.1351 0.0310484 9.98946 0.0913719C9.84385 0.151695 9.71155 0.240112 9.60012 0.351573L0.35176 9.60068C0.239843 9.71171 0.151113 9.84388 0.0907265 9.9895C0.03034 10.1351 -0.000497585 10.2913 6.07128e-06 10.4489V13.8007C6.07128e-06 14.119 0.126436 14.4242 0.351482 14.6493C0.576528 14.8743 0.881756 15.0007 1.20002 15.0007H4.55181C4.70946 15.0013 4.86563 14.9704 5.01125 14.91C5.15687 14.8496 5.28904 14.7609 5.40007 14.649L14.6484 5.40063C14.7599 5.2892 14.8483 5.1569 14.9086 5.01129C14.969 4.86568 15 4.70961 15 4.552C15 4.39439 14.969 4.23832 14.9086 4.09271C14.8483 3.9471 14.7599 3.8148 14.6484 3.70336ZM4.55181 13.8007H1.20002V10.4489L7.8001 3.84886L11.1519 7.20065L4.55181 13.8007ZM12.0001 6.35164L8.64836 3.0006L10.4484 1.20058L13.8002 4.55162L12.0001 6.35164Z"
        fill="#2E4FA5"
      />
    </svg>
  );

  const DeleteIcon = () => (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.375 2.5H11.25V1.875C11.25 1.37772 11.0525 0.900805 10.7008 0.549175C10.3492 0.197544 9.87228 0 9.375 0H5.625C5.12772 0 4.65081 0.197544 4.29917 0.549175C3.94754 0.900805 3.75 1.37772 3.75 1.875V2.5H0.625C0.45924 2.5 0.300269 2.56585 0.183058 2.68306C0.0658481 2.80027 0 2.95924 0 3.125C0 3.29076 0.0658481 3.44973 0.183058 3.56694C0.300269 3.68415 0.45924 3.75 0.625 3.75H1.25V15C1.25 15.3315 1.3817 15.6495 1.61612 15.8839C1.85054 16.1183 2.16848 16.25 2.5 16.25H12.5C12.8315 16.25 13.1495 16.1183 13.3839 15.8839C13.6183 15.6495 13.75 15.3315 13.75 15V3.75H14.375C14.5408 3.75 14.6997 3.68415 14.8169 3.56694C14.9342 3.44973 15 3.29076 15 3.125C15 2.95924 14.9342 2.80027 14.8169 2.68306C14.6997 2.56585 14.5408 2.5 14.375 2.5ZM5 1.875C5 1.70924 5.06585 1.55027 5.18306 1.43306C5.30027 1.31585 5.45924 1.25 5.625 1.25H9.375C9.54076 1.25 9.69973 1.31585 9.81694 1.43306C9.93415 1.55027 10 1.70924 10 1.875V2.5H5V1.875ZM12.5 15H2.5V3.75H12.5V15ZM6.25 6.875V11.875C6.25 12.0408 6.18415 12.1997 6.06694 12.3169C5.94973 12.4341 5.79076 12.5 5.625 12.5C5.45924 12.5 5.30027 12.4341 5.18306 12.3169C5.06585 12.1997 5 12.0408 5 11.875V6.875C5 6.70924 5.06585 6.55027 5.18306 6.43306C5.30027 6.31585 5.45924 6.25 5.625 6.25C5.79076 6.25 5.94973 6.31585 6.06694 6.43306C6.18415 6.55027 6.25 6.70924 6.25 6.875ZM10 6.875V11.875C10 12.0408 9.93415 12.1997 9.81694 12.3169C9.69973 12.4341 9.54076 12.5 9.375 12.5C9.20924 12.5 9.05027 12.4341 8.93306 12.3169C8.81585 12.1997 8.75 12.0408 8.75 11.875V6.875C8.75 6.70924 8.81585 6.55027 8.93306 6.43306C9.05027 6.31585 9.20924 6.25 9.375 6.25C9.54076 6.25 9.69973 6.31585 9.81694 6.43306C9.93415 6.55027 10 6.70924 10 6.875Z"
        fill="#EA432D"
      />
    </svg>
  );

  const ExpandIcon = () => (
    <span style={{ margin: 8 }}>
      <svg
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L6 6L11 1"
          stroke="#2E4FA5"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </span>
  );

  const genExtra = (rId: any) => (
    <>
      {/* <span style={{cursor: 'pointer'}} onClick={() => {}}>
        <EditIcon />
      </span> &nbsp;&nbsp;&nbsp; */}
      <span
        style={{ cursor: "pointer", color: "red" }}
        onClick={(event) => {
          setRuleId(rId);
          setOpenDelete(true);
          // event.stopPropagation();
        }}
      >
        <DeleteIcon />
      </span>
    </>
  );

  // const genExtra = () => <EditOutlined />;

  const closeEditHandler = () => {
    setEditHandler(false);
  };

  const deleteHandler = async (id: any) => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      const request = JSON.stringify({
        alert_config_id: id,
        sensor_id: sensorId,
      });

      try {
        const baseUrl = getBaseUrl();
        let res: any = await fetch(baseUrl + "/manage_sensor_rules", {
          // let res: any = await fetch(baseUrl + "/manage_advanced_alert_config", {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });

        const result = (await (await res).json()) as any;
        await reloadRules();
        await timeZone();
        if (result.success) {
          message.success("successfully deleted record");
          const foundRule = ctx.rulesList.find((rule: any) => rule.id === id);
          if (foundRule?.rulesType === "snapshot") {
            setHasSnapshotRule(false);
          }
          ctx.setRuleList((prev: any) =>
            prev.filter((rule: any) => rule.id !== id)
          );
          reloadZones();
        } else {
          message.error("failed to deleted record, please try again later");
        }
      } catch (err) {
        console.log("err -> ", err);
        setLoading(false);
      }
    }
    setLoading(false);
  };

  // const handleRuleNameChange = (event: React.ChangeEvent<any>, rId: any) => {
  //   const name = event.target.value;
  //   let foundRule = ctx.rulesList.find((rule) => rule.id === rId);

  //   const newRule = { ...foundRule, rulesName: name };
  //   const filteredRules = ctx.rulesList.filter((rule: any) => rule.id !== rId);
  //   ctx.setRuleList(
  //     [...filteredRules, newRule].sort((a: any, b: any) => a.id - b.id)
  //   );
  // };

  // const updateRuleHandler = async (id: any) => {
  //   setLoading(true);
  //   const foundRule = ctx.rulesList.find((rule) => rule.id === id);

  //   if (!foundRule) {
  //     return message.error("Could not update record, something went wrong");
  //   }

  //   const request = JSON.stringify({
  //     id: id,
  //     sensor_id: foundRule.sensor_id,
  //     site_id: foundRule.site_id,
  //     rulesName: foundRule.rulesName,
  //     rulesType: foundRule.rulesType,
  //     zoneConfigs: foundRule.zoneConfigs,
  //     environment: foundRule.environment,
  //   });

  //   const res = await fetch(`http://localhost:3001/rule_configs/${id}`, {
  //     method: "PUT",
  //     headers: {
  //       Accept: "application/json",
  //       // Authorization: "Bearer " + access_token,
  //       "Content-Type": "application/json",
  //     },
  //     body: request,
  //   });
  //   const result = (await (await res).json()) as any;
  //   await reloadRules();
  //   await timeZone();
  //   if (result) {
  //     message.success("successfully updated record");
  //   } else {
  //     message.error("failed to update record, please try again later");
  //   }
  //   setLoading(false);
  // };

  const genContent = (
    rId: number,
    ruleName: string,
    ruleType: string,
    zones: any[],
    timeEntry?: any,
    deliveryEndpoints?: any,
    cadenceSecs?: any,
    ruleSubType?: string,
    exact_times?: any
  ) => {
    let time = "";
    if (exact_times) {
      const timeArr = exact_times
        .replace("[(", "")
        .replace(")]", "")
        .replace(/"/g, "")
        .split(", ");
      // time = time + "[" + timeArr[0] + ":" + "[" + timeArr[1] + "]";
      time = exact_times.replace(/"/g, "").replace("),", "),\n");
    }

    return (
      <div style={{ overflowX: "scroll" }}>
        <h1
          className={style.bold_text}
          style={{ fontSize: 13, textAlign: "center" }}
        >
          Zone Configs:
        </h1>
        {zones && zones.length > 0 ? (
          zones.map((item: any) => (
            <ZoneItem
              whiteBg
              sensorDefinedRule={sensorDefinedRules?.find(
                (item: any) =>
                  item?.fields?.rule_subtype?.subtype === ruleSubType
              )}
              site={site}
              ruleId={rId}
              ruleType={ruleType}
              isEdit
              count={item?.count}
              countLogic={item?.count_logic}
              threshold={item?.confidence_threshold}
              object_motion={item?.object_motion}
              cadence_secs={item?.cadence_secs || cadenceSecs}
              rule_type={item?.rule_type}
              zone={item.zone}
              class_label={item.class_label}
              onDeleteHandler={(id: any) => {
                let foundRule = ctx.rulesList.find((rule) => rule.id === rId);
                if (foundRule) {
                  if (foundRule.zoneConfigs.length === 1)
                    return message.warn("You need at least one zone!");
                  const zones = foundRule.zoneConfigs.filter(
                    (zone: any) => zone.id !== item.id
                  );
                  const newRule = { ...foundRule, zoneConfigs: zones };
                  const filteredRules = ctx.rulesList.filter(
                    (rule: any) => rule.id !== rId
                  );
                  ctx.setRuleList(
                    [...filteredRules, newRule].sort(
                      (a: any, b: any) => a.id - b.id
                    )
                  );
                  message.success("sucessfully deleted record");
                }
              }}
              onEditHandler={(id) => {
                setEditHandler(true);
                setRuleId(rId);
                setZoneName(item.zone);
                setZoneId(item.Id);
                setObjectClass(item.class_label);
              }}
            />
          ))
        ) : (
          <p style={{ textAlign: "center", color: "darkgrey" }}>
            No zones found.
          </p>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <br />
          <h1
            className={style.bold_text}
            style={{ fontSize: 13, textAlign: "center" }}
          >
            Schedule:
          </h1>
          <br />
          {time ? (
            <div className={style.rule_item} style={{ marginBottom: 25 }}>
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 3.474L13.86 0L12.699 1.377L16.839 4.851L18 3.474ZM5.292 1.377L4.14 0L0 3.465L1.161 4.842L5.292 1.377ZM9.45 5.526H8.1V10.926L12.375 13.491L13.05 12.384L9.45 10.251V5.526ZM9 1.926C4.527 1.926 0.9 5.553 0.9 10.026C0.9 14.499 4.518 18.126 9 18.126C13.473 18.126 17.1 14.499 17.1 10.026C17.1 5.553 13.473 1.926 9 1.926ZM9 16.326C5.517 16.326 2.7 13.509 2.7 10.026C2.7 6.543 5.517 3.726 9 3.726C12.483 3.726 15.3 6.543 15.3 10.026C15.3 13.509 12.483 16.326 9 16.326Z"
                  fill="#15325F"
                  fill-opacity="0.67"
                />
              </svg>
              <div
                className={style.smaller_text_bold}
                style={{ color: "darkgrey" }}
              >
                &nbsp;&nbsp;{time}
              </div>
            </div>
          ) : (
            <TimeSelector
              editable={false}
              noEndTime={ruleType === "snapshot"}
              ruleId={rId}
              timeEntrySet={
                timeEntry &&
                timeEntry !==
                  '[("Mon,Tue,Wed,Thu,Fri,Sat,Sun", "00:00:00-24:00:00")]'
                  ? new TimeEntrySet(timeEntry)
                  : undefined
              }
            />
          )}
        </div>
        <h1
          className={style.bold_text}
          style={{ marginBottom: 20, fontSize: 13, textAlign: "center" }}
        >
          Delivery Methods:
        </h1>
        <p style={{ textAlign: "center", color: "darkgrey" }}>
          {deliveryEndpoints.join("") !== "ALL"
            ? deliveryEndpoints.join("").slice(0, -2)
            : deliveryEndpoints}
        </p>
        <br />
        {cadenceSecs && (
          <>
            <h1
              className={style.bold_text}
              style={{ marginBottom: 20, fontSize: 13, textAlign: "center" }}
            >
              Fetch Interval:
            </h1>
            <p style={{ textAlign: "center", color: "darkgrey" }}>
              {`${cadenceSecs / 60} mins`}
            </p>
            <br />
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <CustomModal
        closeHandler={() => {
          setOpenDelete(false);
        }}
        open={openDelete}
        title={"confirm"}
        continueAction={true}
        onContinueHandler={() => {
          deleteHandler(ruleId);
          setOpenDelete(false);
        }}
      >
        <h1
          style={{
            fontSize: 14,
            color: "#15325F",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          WARNING!
        </h1>
        <br />
        <p
          style={{
            fontSize: 14,
            color: "#f44336",
            fontWeight: "bold",
            textAlign: "center",
            marginLeft: 30,
            marginRight: 30,
          }}
        >
          Are you sure you want to delete this record ?
        </p>
      </CustomModal>

      <div style={{ height: 500, overflowY: "scroll" }}>
        {ctx.rulesList.map((rule: any) => (
          <Collapse
            bordered={true}
            expandIcon={(ev) => {
              return <ExpandIcon />;
            }}
            style={{
              // background: "#f6f6f6",
              background: "white",
              color: "#15325F",
              // padding: 5,
              borderRadius: 8,
              overflowY: "scroll",
              margin: 10,
              width: 900,
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              // boxShadow: '0 2px rgb(0, 0, 0,1)'
            }}
            collapsible="header"
          >
            <Panel
              header={`${rule.rulesSubType
                .replace(/_/g, " ")
                .replace("(No Guard)", "")
                .replace("(no guard)", "")} (${
                rule.alertType
              }): ${rule.rulesName.replaceAll("_", " ")}    --    days-times: ${
                (!rule.valid_days_and_times && !rule.exact_times) ||
                rule.valid_days_and_times ===
                  '[("Mon,Tue,Wed,Thu,Fri,Sat,Sun", "00:00:00-24:00:00")]'
                  ? " (always active)"
                  : " (specific times)"
              }
            `}
              key={rule.id}
              style={panelStyle}
              extra={genExtra(rule.id)}
            >
              {genContent(
                rule.id,
                rule.rulesName,
                rule.rulesType,
                rule.zoneConfigs,
                rule.valid_days_and_times,
                rule.deliveryEndpoints,
                rule?.cadence_secs,
                rule.rulesSubType,
                rule?.exact_times
              )}
            </Panel>
          </Collapse>
        ))}
      </div>

      <CustomModal closeHandler={closeEditHandler} open={openEditHandler}>
        <h1
          style={{
            fontSize: 13,
            fontWeight: 600,
            textAlign: "center",
            alignContent: "center",
            color: "#15325F",
          }}
        >
          Edit Zone Config
        </h1>
        <br />
        <br />
        <AddZoneForm
          ruleId={ruleId}
          zoneId={zoneId}
          zone={zoneName}
          class_label={objectClass}
          isEdit
          site={site}
          sensorDefinedRule={sensorDefinedRule}
        />
        <br />
        <br />
      </CustomModal>
    </>
  );
};

export default RulesList;
