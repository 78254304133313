import classes from "./ui.module.css";
import React, { useState, useRef, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DateAndTimePicker from "./DateAndTimePicker";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 320,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

// const time_date = [
//   "none",
//   "Last 1 hour",
//   "Last 3 hours",
//   "Last 6 hours",
//   "Last 12 hours",
//   "Last 24 hours",
//   "Last 2 days",
//   "Last 7 days",
//   "Last 2 weeks",
//   "Last 30 days",
//   "Last 60 days",
//   "Last 90 days",
//   "Last 120 days",
// ];

const time_date1 = [
  "none",
  "Last 1 hour",
  "Last 3 hours",
  "Last 6 hours",
  "Last 12 hours",
  "Last 24 hours",
  "Last 2 days",
  "Last 7 days",
  "Last 2 weeks",
  "Last 30 days",
];

const time_date2 = [
  "none",
  "Last 1 hour",
  "Last 3 hours",
  "Last 6 hours",
  "Last 12 hours",
  "Last 24 hours",
  "Last 2 days",
  "Last 7 days",
  "Last 2 weeks",
  "Last 30 days",
  "Last 60 days",
  "Last 90 days",
];

interface Props {
  auditLogs?: any;
  backDateHandler: (e: number) => void;
  closeModal: () => void;
  applyChangesHandler: (a: number, b: number) => void;
  dateTitle: (e: string) => void;
  savedTime?: { startT: number; endT: number };
  setSavedTime?: (time: { startT: number; endT: number }) => void;
  title: string;
}

const TimeRange: React.FC<Props> = ({
  backDateHandler,
  auditLogs,
  applyChangesHandler,
  closeModal,
  dateTitle,
  savedTime,
  setSavedTime,
  title,
}) => {
  const time_date = auditLogs === "user-audit" ? time_date2 : time_date1;
  const classer = useStyles();
  const [quick_range, setQuickRange] = React.useState<string | unknown>(
    title || "none"
  );
  const [filteredStartTime, setFilteredStartTime] = useState(
    savedTime?.startT ? savedTime?.startT : 0
  );
  const [filteredEndTime, setFilteredEndTime] = useState(
    savedTime?.endT ? savedTime?.endT : 0
  );
  const [newStartTime, setNewStartTime] = useState(
    savedTime?.endT ? timeConverter(savedTime?.startT).stamp : 0
  );
  const [newEndTime, setNewEndTime] = useState(
    savedTime?.endT ? timeConverter(savedTime?.endT).stamp : 0
  );
  const [from, setFrom] = useState(
    savedTime?.startT ? timeConverter(savedTime?.startT).locale : ""
  );
  const [to, setTo] = useState(
    savedTime?.endT ? timeConverter(savedTime?.endT).locale : ""
  );
  const [errMessage, setErrMessage] = useState("");

  const selectRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    selectRef!.current!.focus();
  }, []);

  const handleChange = (event: string) => {
    let today = new Date();
    let backdate = new Date(today.getTime() - 1000 * 60 * 60).getTime() / 1000;
    setQuickRange(event);
    if (event === "Last 3 hours") {
      backdate =
        new Date(today.getTime() - 1000 * 60 * 60 * 3).getTime() / 1000;
    }
    if (event === "Last 6 hours") {
      backdate =
        new Date(today.getTime() - 1000 * 60 * 60 * 6).getTime() / 1000;
    }
    if (event === "Last 12 hours") {
      backdate =
        new Date(today.getTime() - 1000 * 60 * 60 * 12).getTime() / 1000;
    }
    if (event === "Last 24 hours") {
      backdate =
        new Date(today.getTime() - 1000 * 60 * 60 * 24).getTime() / 1000;
    }
    if (event === "Last 2 days") {
      backdate =
        new Date(today.getTime() - 1000 * 60 * 60 * 24 * 2).getTime() / 1000;
    }
    if (event === "Last 7 days") {
      backdate =
        new Date(today.getTime() - 1000 * 60 * 60 * 24 * 7).getTime() / 1000;
    }
    if (event === "Last 2 weeks") {
      backdate =
        new Date(today.getTime() - 1000 * 60 * 60 * 24 * 14).getTime() / 1000;
    }
    if (event === "Last 30 days") {
      backdate =
        new Date(today.getTime() - 1000 * 60 * 60 * 24 * 30).getTime() / 1000;
    }
    if (event === "Last 60 days") {
      backdate =
        new Date(today.getTime() - 1000 * 60 * 60 * 24 * 60).getTime() / 1000;
    }
    if (event === "Last 90 days") {
      backdate =
        new Date(today.getTime() - 1000 * 60 * 60 * 24 * 90).getTime() / 1000;
    }
    if (event === "none") {
      backdate =
        new Date(today.getTime() - 1000 * 60 * 60 * 24 * 30).getTime() / 1000;
    }

    if (setSavedTime) {
      setSavedTime!({ startT: 0, endT: 0 });
    }

    backDateHandler(backdate);
    dateTitle!(event);
    closeModal();
  };

  function timeConverter(e: any) {
    let time: any = new Date(e);
    time = time.getTime() / 1000;
    let text = new Date(time * 1000).toLocaleString();
    return { stamp: time, locale: text };
  }

  const setStartTimeFilter = (e: any) => {
    if (auditLogs) {
      let time: any = new Date(e);
      time = time.getTime() / 1000;

      let text = new Date(time * 1000).toLocaleString();
      setFrom(text);
      setErrMessage("");
      setFilteredStartTime(e);
      setNewStartTime(time);
    } else if (!auditLogs) {
      let time: any = new Date(e);
      time = time.getTime() / 1000;

      if (time > new Date(new Date().getTime() / 1000)) {
        return setErrMessage(
          "Invalid input - Start time cannot be greater than current time"
        );
      }

      let look_back =
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 32).getTime() /
        1000;

      if (time < look_back) {
        return setErrMessage("Start time should be within the last 30 days");
      }
      let text = new Date(time * 1000).toLocaleString();
      setFrom(text);
      setErrMessage("");
      setFilteredStartTime(e);
      setNewStartTime(time);
    }
  };

  const setEndTimeFilter = (e: any) => {
    if (auditLogs) {
      var time: any = new Date(e);
      time = time.getTime() / 1000;

      let text = new Date(time * 1000).toLocaleString();
      setTo(text);
      setErrMessage("");
      setFilteredEndTime(e);
      setNewEndTime(time);
    } else if (!auditLogs) {
      var time: any = new Date(e);
      time = time.getTime() / 1000;

      if (time > new Date(new Date().getTime() / 1000)) {
        return setErrMessage(
          "Invalid input - End time cannot be greater than current time"
        );
      }

      let look_back =
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 32).getTime() /
        1000;

      if (time < look_back) {
        return setErrMessage("End time should be within the last 30 days");
      }
      let text = new Date(time * 1000).toLocaleString();
      setTo(text);
      setErrMessage("");
      setFilteredEndTime(e);
      setNewEndTime(time);
    }
  };

  const applyTimeFilter = () => {
    if (auditLogs) {
      if (newStartTime > newEndTime) {
        return setErrMessage("Start time cannot be greater than End time");
      }

      if (newStartTime === 0 || newEndTime === 0) {
        return setErrMessage("Please select all fields");
      }

      // let look_back_time =
      //   new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 32).getTime() /
      //   1000;

      // if (newStartTime < look_back_time || newEndTime < look_back_time) {
      //   return setErrMessage(
      //     "End time / Start time should be within the last 18 months"
      //   );
      // }

      setErrMessage("");
      let start_date = new Date(from);
      let end_date = new Date(to);

      let today = new Date();

      if (
        start_date.getMonth() === end_date.getMonth() &&
        start_date.getDate() === end_date.getDate() &&
        start_date.getFullYear() === end_date.getFullYear() &&
        start_date.getDate() === today.getDate() &&
        start_date.getMonth() === today.getMonth() &&
        start_date.getFullYear() === today.getFullYear()
      ) {
        dateTitle(
          new Date(from).toLocaleTimeString() +
            `${"  "}  -  ${"  "}` +
            new Date(to).toLocaleTimeString()
        );
      } else {
        dateTitle(from + `${"  "}  -  ${"  "}` + to);
      }

      applyChangesHandler(newStartTime, newEndTime);

      if (setSavedTime) {
        setSavedTime!({ startT: filteredStartTime, endT: filteredEndTime });
      }
      closeModal();
    } else if (!auditLogs) {
      if (newStartTime > newEndTime) {
        return setErrMessage("Start time cannot be greater than End time");
      }

      if (newStartTime === 0 || newEndTime === 0) {
        return setErrMessage("Please select all fields");
      }

      let look_back_time =
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 32).getTime() /
        1000;

      if (newStartTime < look_back_time || newEndTime < look_back_time) {
        return setErrMessage(
          "End time / Start time should be within the last 30 days"
        );
      }

      setErrMessage("");
      let start_date = new Date(from);
      let end_date = new Date(to);

      let today = new Date();

      if (
        start_date.getMonth() === end_date.getMonth() &&
        start_date.getDate() === end_date.getDate() &&
        start_date.getFullYear() === end_date.getFullYear() &&
        start_date.getDate() === today.getDate() &&
        start_date.getMonth() === today.getMonth() &&
        start_date.getFullYear() === today.getFullYear()
      ) {
        dateTitle(
          new Date(from).toLocaleTimeString() +
            `${"  "}  -  ${"  "}` +
            new Date(to).toLocaleTimeString()
        );
      } else {
        dateTitle(from + `${"  "}  -  ${"  "}` + to);
      }

      applyChangesHandler(newStartTime, newEndTime);

      if (setSavedTime) {
        setSavedTime!({ startT: filteredStartTime, endT: filteredEndTime });
      }
      closeModal();
    }
  };

  return (
    <div className="App">
      <div style={{ margin: 10 }}>
        {errMessage && <Alert severity="error">{errMessage}</Alert>}
      </div>
      <div className={classes.mainTimeWrapper}>
        <div className={classes.mainTimeWrapperLeft}>
          <h3
            style={{
              color: "#15325F",
              fontWeight: 600,
              fontFamily: "open sans",
            }}
          >
            CUSTOM TIME RANGE
          </h3>
          <br />
          <div className={classes.timeSelector}>
            <DateAndTimePicker
              title={"From: "}
              time={filteredStartTime}
              setTimeFilter={setStartTimeFilter}
            />
          </div>
          <div className={classes.timeSelector}>
            <DateAndTimePicker
              title={"To: "}
              time={filteredEndTime}
              setTimeFilter={setEndTimeFilter}
            />
          </div>
          {!!filteredStartTime && !!filteredEndTime && (
            <div>
              <Button
                variant="contained"
                color="primary"
                component="span"
                style={{
                  borderRadius: 25,
                  paddingLeft: 25,
                  paddingRight: 25,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
                onClick={applyTimeFilter}
              >
                Apply Changes
              </Button>
            </div>
          )}
        </div>
        <div
          style={{
            borderLeft: "1px solid #ccce",
            height: 270,
          }}
        ></div>
        <div
          className="mainTimeWrapperRight"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: 205,
            paddingTop: 60,
          }}
        >
          <FormControl variant="outlined" className={classer.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">
              Select Quick Range
            </InputLabel>
            <Select
              ref={selectRef}
              native
              value={quick_range}
              onChange={(e) => handleChange(String(e.target.value || ""))}
              label="Select Quick Ranges"
              inputProps={{
                name: "age",
                id: "outlined-age-native-simple",
              }}
            >
              {time_date.map((item) => (
                <option value={item}>{item}</option>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.borderBottom}></div>
      </div>
    </div>
  );
};

export default TimeRange;
