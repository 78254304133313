import React from "react";
import classes from "./ui.module.css";
import ListItem from "./ListItem";
import CustomButton from "./CustomButton";

interface UnusualActivity {
  id: number;
  title: string;
  description: string;
  reason: string;
  url?: string;
}

interface Activity {
  event_id: string;
  created: string;
  site_id: number;
  alert_channel_id: number;
  is_unusual: true;
  unusual_activity_type: string;
  classifier: string;
  sensor_id_strs: string[];
  alert_ids: string[];
  alert_description: string;
  result_reason: string;
  result_description: string;
  object_count: number;
  vehicle_count: number;
}

interface ListProps {
  data: Activity[];
  openModal: (id: string) => void;
}

const List: React.FC<ListProps> = ({ data, openModal }) => {
  const onViewHandler = (id: string): void => {
    openModal(id);
  };
  return (
    <ul className={classes["items-wrapper"]}>
      {data.map((item: Activity, index: number) => {
        return (
          <li key={item.event_id} className={classes.item}>
            <ListItem
              title={`${index + 1}.    ${item.alert_description.replace(
                "1 people",
                "1 person"
              )}`}
              event_type={item.unusual_activity_type.replace(/_/g, " ")}
              cameras={item.sensor_id_strs}
              site_id={item.site_id}
              date={item.created.split(".")[0].replace("T", " (H:M:S) ")}
            />{" "}
            <CustomButton
              title={"View Details"}
              onClickHandler={onViewHandler}
              id={item.event_id}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default List;
