import React, { useState, useEffect } from "react";

interface ImageLogoProps {
  getThumbnail: any;
  localLoading: any;
  currentOrg: any;
  appStore: any;
}

const ImageLogos: React.FC<ImageLogoProps> = React.memo((props) => {
  const [thumbnail, setThumbnail] = useState<{
    logo: { stringb64: string } | null;
  }>({
    logo: props?.currentOrg?.logo?.thumbnail,
  });

  return (
    <div>
      {thumbnail?.logo && (
        <div className="icon">
          <img
            src={`data:image/png;base64,${thumbnail?.logo?.stringb64}`}
            alt="logo"
            style={{
              height: "20px",
              width: "50px",
              transform: "translate(15px,0px)",
            }}
          />
        </div>
      )}
    </div>
  );
});

export default ImageLogos;
