import React, { useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { useStyles } from "../../styling/global/global";
import { AppStore } from "../../../stores/AppStore";
import { Org, Site, Locale } from "../../../services/FunctionService";
import { time_zones } from "../../../hooks/Timezones";

interface AddAlertChannelModalProps {
  appStore?: AppStore;
  open: boolean;
  message: string;
  onClose: () => void;
  currentOrg: Org;
  saveNewAlertChannel: (
    locale: string,
    alert_channel_name: string,
    site_id: number,
    timezone: string,
    data_origin: string
  ) => void;
  selectedItem: any;
  site: Site | undefined;
}

const AddAlertChannelModal: React.FC<AddAlertChannelModalProps> = ({
  onClose,
  open,
  saveNewAlertChannel,
  currentOrg,
  message,
  appStore,
  selectedItem,
  site,
}: AddAlertChannelModalProps) => {
  const classes = useStyles();
  const [alert_channel_name, setAlertChannelName] = React.useState("");
  const [timezone, setTimeZone] = React.useState("");
  const [locales, setLocales] = React.useState<Locale[]>([]);
  const [locale, setLocale] = React.useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (currentOrg.org_id) {
        var token = await appStore?.authService.getAuthorisedToken();
        if (token) {
          var all_locales = await appStore?.functionService.getLocales(token);
          if (all_locales) {
            setLocales(all_locales);
          }
        }
      }
    };
    fetchData();
  }, []);

  const handleSave = () => {
    if (site) {
      saveNewAlertChannel(
        locale,
        alert_channel_name,
        site.site_id,
        timezone,
        site.data_origin
      );
    }
  };
  const handleClose = () => {
    onClose();
  };

  const handleAlertChannelNameChange = (event: React.ChangeEvent<any>) => {
    setAlertChannelName(event.target.value);
  };

  const handleTimeZoneChange = (event: React.ChangeEvent<any>) => {
    setTimeZone(event.target.value);
  };

  const handleLocaleChange = (event: React.ChangeEvent<any>) => {
    setLocale(event.target.value);
  };

  var timezones = [];
  timezones.push(
    <MenuItem value="Africa/Johannesburg">
      <span className={classes.bold_text}>Africa/Johannesburg</span>
    </MenuItem>
  );
  // timezones.push(
  //   <MenuItem value="Africa/Windhoek">
  //     <span className={classes.bold_text}>Africa/Windhoek</span>
  //   </MenuItem>
  // );
  timezones.push(
    <MenuItem value="Europe/London">
      <span className={classes.bold_text}>Europe/London</span>
    </MenuItem>
  );
  var locales_menu: React.ReactNode[] = [];
  var locales_list: string[] = [];
  if (locales) {
    locales.forEach((locale_menu) => {
      if (!locales_list.includes(locale_menu.locale)) {
        locales_menu.push(
          <MenuItem value={locale_menu.locale}>
            <span className={classes.bold_text}>{locale_menu.locale}</span>
          </MenuItem>
        );
        locales_list.push(locale_menu.locale);
      }
    });
  }
  if (appStore && appStore.authService.getRoles().indexOf("site_admin") >= 0) {
    return (
      <Dialog
        onClose={handleClose}
        className={classes.modal_dialog}
        open={open}
      >
        <DialogTitle className={classes.bold_text}>
          Add New Alert Ruleset to {selectedItem.name}
        </DialogTitle>
        <Typography className={classes.bold_text} color="error">
          {message}
        </Typography>
        <TextField
          onChange={handleAlertChannelNameChange}
          label="Alert Ruleset Name"
          variant="outlined"
          margin="normal"
          fullWidth
          InputProps={{
            className: classes.bold_text,
          }}
          InputLabelProps={{
            classes: {
              root: classes.bold_text,
            },
          }}
        />
        <FormControl variant="outlined" className={classes.form_component}>
          <InputLabel className={classes.bold_text}>Time Zone</InputLabel>
          <Select value={timezone} onChange={handleTimeZoneChange}>
            {time_zones.map((zone) => (
              <MenuItem value={zone}>{zone}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.form_component}>
          <InputLabel className={classes.bold_text}>Locale</InputLabel>
          <Select value={locale} onChange={handleLocaleChange}>
            {locales_menu}
          </Select>
        </FormControl>

        <div className={classes.button_container}>
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={handleClose}
          >
            <span className={classes.bold_text}>CANCEL</span>
          </Button>
          <Button
            variant="contained"
            className={classes.save_button}
            onClick={handleSave}
          >
            <span className={classes.bold_text}>SAVE</span>
          </Button>
        </div>
      </Dialog>
    );
  } else {
    return (
      <Dialog
        onClose={handleClose}
        open={open}
        className={classes.modal_dialog}
      >
        <DialogTitle className={classes.bold_text}>
          Add New Alert Ruleset to {selectedItem.name}
        </DialogTitle>
        <br></br>
        <Typography className={classes.bold_text}>
          {
            "You need site admin role to be able to add an Alert Ruleset - please contact your DeepAlert system administrator"
          }
        </Typography>
        <div className={classes.button_container}>
          <Button
            className={classes.cancel_button}
            variant="contained"
            onClick={() => handleClose()}
          >
            <span className={classes.bold_text}>CANCEL</span>
          </Button>
        </div>
      </Dialog>
    );
  }
};

export default AddAlertChannelModal;
