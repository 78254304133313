import React from "react";
import "./RuleConfigs.css";
import { message } from "antd";
import AddZoneForm from "./AddZoneForm";
import { useStyles } from "../../styling/global/global";
import ZoneItem from "./ZoneItem";
import { MainContext } from "../../containers/HomePage/HomePage";

interface props {
  configData?: any;
  sensorId?: any;
  siteId?: any;
  site?: any;
  sensorDefinedRule?: any;
}
const RuleConfigs: React.FC<props> = ({
  sensorId,
  siteId,
  site,
  configData,
  sensorDefinedRule,
}) => {
  let ruleTypes: string[] = [];

  if (sensorDefinedRule?.fields?.zone_configs) {
    ruleTypes =
      sensorDefinedRule?.fields?.zone_configs.map((item: any) => item.type) ||
      [];
  }

  const style = useStyles();
  const ctx = React.useContext(MainContext);

  const onDeleteHandler = (ruleId: number, id: number) => {
    message.success("successfully deleted record");
    let zones = ctx.zone_config.filter((zone: any) => zone.id !== id);
    ctx.setZoneConfig(zones);
  };

  return (
    <div>
      <br></br>
      {(ctx.rule_type !== "Guard Management (no guard)" ||
        ctx.zone_config.length === 0) && (
        <AddZoneForm
          sensorId={sensorId}
          siteId={siteId}
          site={site}
          sensorDefinedRule={sensorDefinedRule}
        />
      )}

      {/* {(ctx?.rule_type?.includes("Guard Tour") ||
        ctx?.rule_type?.includes("Guard Management")) &&
        site?.product?.includes("EdgePlus") && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "60px auto",
              width: "700px",
            }}
          >
            <p
              style={{
                color: "rgb(21,50,95)",
                fontWeight: "bold",
              }}
            >
              {"IP Address"}
            </p>
            <p
              style={{
                color: "rgb(21,50,95)",
                fontWeight: "bold",
              }}
            >
              {"User Name"}
            </p>
            <p
              style={{
                color: "rgb(21,50,95)",
                fontWeight: "bold",
              }}
            >
              {"Password"}
            </p>
            <p
              style={{
                color: "rgb(21,50,95)",
                fontWeight: "bold",
              }}
            >
              {"Port"}
            </p>
            <Tooltip
              placement="top"
              title={
                !configData?.camera_http_port ||
                !configData?.camera_rtsp_ip ||
                !configData?.camera_rtsp_login ||
                !configData?.camera_rtsp_password
                  ? "Cannot fetch snapshot"
                  : "Able to fetch snapshot"
              }
            >
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  backgroundColor:
                    !configData?.camera_http_port ||
                    !configData?.camera_rtsp_ip ||
                    !configData?.camera_rtsp_login ||
                    !configData?.camera_rtsp_password
                      ? "orange"
                      : "rgb(21,50,95)",
                }}
              ></div>
            </Tooltip>
          </div>
        )} */}

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      {ctx.zone_config.length > 0 && (
        <h1
          className={style.bold_text}
          style={{ marginLeft: 20, fontSize: 15, textAlign: "center" }}
        >
          Zone Configs
        </h1>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {ctx.zone_config.map((zone: any) => (
          <ZoneItem
            sensorDefinedRule={sensorDefinedRule}
            ruleId={0}
            zone={`${zone.zone}`}
            count={zone.count}
            countLogic={zone.count_logic}
            object_motion={zone.object_motion}
            cadence_secs={zone?.cadence_secs}
            rule_type={zone?.rule_type}
            threshold={zone.confidence_threshold}
            class_label={zone.class_label}
            onDeleteHandler={(ruleId: number, id: any) => {
              onDeleteHandler(ruleId, zone.id);
            }}
            ruleType={ctx.rule_type}
            site={site}
          />
        ))}
      </div>
    </div>
  );
};

export default RuleConfigs;
