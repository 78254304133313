import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  })
);

interface Props {
  title: string;
  time: string | number;
  setTimeFilter: (e: any) => void;
}

const DateAndTimePickers: React.FC<Props> = ({
  title,
  time,
  setTimeFilter,
}) => {
  const classes = useStyles();

  return (
    <form className={classes.container} noValidate>
      <TextField
        id="datetime-local"
        label={title}
        type="datetime-local"
        value={time}
        // defaultValue={new Date().now}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          inputProps: {
            min: "2022-05-01",
            max: new Date(),
          },
        }}
        onChange={(e) => {
          setTimeFilter(e.target.value);
        }}
      />
    </form>
  );
};

export default DateAndTimePickers;
