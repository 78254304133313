import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "../../styling/global/global";
import { Typography } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import {
  createMuiTheme,
  ThemeProvider,
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { AppStore } from "../../../stores/AppStore";
import LoadingSpinner from "../../../spinner/LoadingSpinner";
import {
  ListHikCentralEntitiesResult,
  hikCentralListItems,
} from "../../../services/FunctionService";
import Pagination from "@material-ui/lab/Pagination";

interface HickCentralProps {
  open: boolean;
  message: string;
  onClose: () => void;
  onBack: () => void;
  appStore?: AppStore;
  alert_channel_end_point_id: number;
}
const theme = createMuiTheme({
  transitions: {
    easing: {
      easeOut: "cubic-bezier(0, 1.5, .8, 1)",
      sharp: "linear",
    },
  },
});
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return (
    <ThemeProvider theme={theme}>
      <Slide direction="up" in={true} timeout={10000} ref={ref} {...props} />;
    </ThemeProvider>
  );
});
const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 30,
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
      "&:hover": {
        backgroundColor: "#e3f2fd",
        cursor: "pointer",
      },
    },
  })
)(TableRow);
const HikCentralSecondModal: React.FC<HickCentralProps> = ({
  message,
  open,
  onClose,
  onBack,
  appStore,
  alert_channel_end_point_id,
}: HickCentralProps) => {
  const classes = useStyles();
  const [paginationTotal, setPaginationtotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(1);
  const [testResult, setTestResult] = useState(false);
  const [multipleVisit, setMultipleVisit] = useState(false);
  const [selectError, setSelectError] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState("");
  const [cameramappings, setCameraMappings] = React.useState<any[]>([]);
  const [listItems, setListItems] = useState<hikCentralListItems[] | undefined>(
    undefined
  );
  const [currentPage, setCurrentPage] = useState(0);

  const handleClose = () => {
    onClose();
  };
  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    fetchHikCentralListingData(value);
  };
  const handleChange = (event: React.ChangeEvent<any>) => {
    setSelectError(false);
    setSelectedCamera(event.target.value);
  };
  const handleCameraMappingsChange = (newvalues: any[]) => {
    setCameraMappings(newvalues);
  };
  const fetchHikCentralListingData = async (pageNo: number) => {
    const token = await appStore?.authService.getAuthorisedToken();
    setLoading(true);
    if (token) {
      const hikResult: ListHikCentralEntitiesResult =
        await appStore?.functionService.listHikCentralEntities(
          token,
          alert_channel_end_point_id,
          "camera",
          pageNo,
          20
        );
      if (hikResult) {
        if (hikResult.success) {
          setPaginationtotal(hikResult.msg.data.total);
          setListItems(hikResult.msg.data.list);
          setTestResult(true);
          setLoading(false);
          setMultipleVisit(true);
        } else {
          setTestResult(false);
          setLoading(false);
        }
      }
    }
  };
  const fetchHikCentralListingTest = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    setLoading(true);
    if (token) {
      const hikResult = await appStore?.functionService.listHikCentralEntities(
        token,
        alert_channel_end_point_id,
        "camera",
        1,
        10
      );
      if (hikResult) {
        if (hikResult.success) {
          setPaginationtotal(hikResult.msg.data.total);
          setListItems(hikResult.msg.data.list);
          setTestResult(true);
          setLoading(false);
          setMultipleVisit(true);
        } else {
          setMultipleVisit(true);
          setTestResult(false);
          setLoading(false);
        }
      }
    }
  };
  const handleTest = () => {
    fetchHikCentralListingTest();
  };
  useEffect(() => {}, [alert_channel_end_point_id]);
  return (
    <Dialog
      maxWidth="md"
      onClose={handleClose}
      scroll="paper"
      aria-describedby="alert-dialog-slide-description"
      open={open}
      className="edit-dialog"
      fullWidth={true}
      TransitionComponent={Transition}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <DialogTitle id="simple-dialog-title">
          <span
            className={classes.bold_text}
            style={{ fontSize: 20, color: "blue", marginBottom: 5 }}
          >
            HikCentralPro Setup Page
          </span>
        </DialogTitle>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {testResult ? null : multipleVisit ? (
              <Typography align="center" style={{ color: "red" }}>
                Authentication failed, please go back and check credentials
              </Typography>
            ) : null}
            {testResult ? null : (
              <Button
                variant="contained"
                className="save"
                style={{ color: "blue", marginBottom: 5 }}
                onClick={handleTest}
              >
                Test
              </Button>
            )}
            <Typography>Pagination Total :{paginationTotal}</Typography>
            <Typography>
              alert channel endpoint id : {alert_channel_end_point_id}
            </Typography>
            <FormControl className={classes.formControl}>
              <InputLabel
                style={{ paddingLeft: "1%" }}
                id="demo-controlled-open-select-label"
              >
                {" "}
                Camera list
              </InputLabel>
              <Select
                error={selectError}
                label=" Username"
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                variant="outlined"
                value={selectedCamera}
                // helperText={
                //   selectError === true ? 'Profile name field cannot be blank' : ''
                // }
                onChange={(e) => handleChange(e)}
              >
                {listItems &&
                  listItems.map((item, index) => {
                    return (
                      <MenuItem value={item.cameraName}>
                        {item.cameraName}
                      </MenuItem>
                    );
                  })}
              </Select>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Camera&nbsp;name</TableCell>
                      <TableCell>Camera&nbsp;index&nbsp;code</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listItems &&
                      listItems.map((item, index) => {
                        return (
                          <StyledTableRow
                            style={{ height: 15 }}
                            className={classes.tableRow}
                            onClick={() => {}}
                          >
                            <TableCell>{item.cameraName}</TableCell>
                            <TableCell>{item.cameraIndexCode}</TableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </FormControl>
          </>
        )}
      </div>

      <div
        style={{
          marginTop: 10,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Pagination
          count={paginationTotal}
          page={page}
          onChange={handlePaginationChange}
          variant="outlined"
          color="primary"
        />
      </div>

      <div
        style={{ marginBottom: 10, marginTop: 10 }}
        className={classes.twoButtonsInARow}
      >
        {testResult ? null : (
          <Button variant="contained" className="save" onClick={() => onBack()}>
            Back
          </Button>
        )}
        <Button variant="contained" className="save" onClick={handleClose}>
          Close
        </Button>
        {testResult ? (
          <Button variant="contained" className="save" onClick={handleClose}>
            Next Step
          </Button>
        ) : null}
      </div>
    </Dialog>
  );
};

export default HikCentralSecondModal;
