import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useStyles } from "../../../styling/global/global";
import AddBillingOrg from "../BillingModals/AddBillingOrg";
interface BillingExpansionPanelProps {
  del: boolean;
  edit: boolean;
  view: boolean;
  viewProfileOrgs: boolean;
  addProfileUser: boolean;
  viewProfileUsers: boolean;
  addBillingOrg: boolean;
  handleAddBillingOrg: () => void;
  handleViewProfileUsers: () => void;
  handleViewAddProfileUser: () => void;
  handleViewProfileOrgs: () => void;
  handleEdit: () => void;
  handleDelete: () => void;
  handleView: () => void;
}
const BillingExpansionPanel: React.FC<BillingExpansionPanelProps> = ({
  del,
  edit,
  view,
  viewProfileOrgs,
  addProfileUser,
  viewProfileUsers,
  addBillingOrg,
  handleAddBillingOrg,
  handleViewProfileUsers,
  handleViewAddProfileUser,
  handleViewProfileOrgs,
  handleView,
  handleEdit,
  handleDelete,
}: BillingExpansionPanelProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const Edit = () => {
    setAnchorEl(null);
    handleEdit();
  };
  const View = () => {
    setAnchorEl(null);
    handleView();
  };

  const Delete = () => {
    setAnchorEl(null);
    handleDelete();
  };
  const ViewProfileOrgs = () => {
    setAnchorEl(null);
    handleViewProfileOrgs();
  };
  const ViewAddProfileUser = () => {
    setAnchorEl(null);
    handleViewAddProfileUser();
  };
  const ViewProfileUsers = () => {
    setAnchorEl(null);
    handleViewProfileUsers();
  };
  const ViewAddBillingOrg = () => {
    setAnchorEl(null);
    handleAddBillingOrg();
  };

  var menuitems: React.ReactNode[] = [];
  if (view) {
    menuitems.push(
      <MenuItem onClick={View}>
        <span className={classes.bold_text}>View Profile</span>
      </MenuItem>
    );
  }
  if (edit) {
    menuitems.push(
      <MenuItem onClick={Edit}>
        <span className={classes.bold_text}>Edit Profile</span>
      </MenuItem>
    );
  }

  if (del) {
    menuitems.push(
      <MenuItem onClick={Delete}>
        <span className={classes.bold_text}>Delete Profile</span>
      </MenuItem>
    );
  }

  if (viewProfileUsers) {
    menuitems.push(
      <MenuItem onClick={ViewProfileUsers}>
        <span className={classes.bold_text}>View Profile Users</span>
      </MenuItem>
    );
  }
  if (addProfileUser) {
    menuitems.push(
      <MenuItem onClick={ViewAddProfileUser}>
        <span className={classes.bold_text}>Add Users to Profile</span>
      </MenuItem>
    );
  }

  if (viewProfileOrgs) {
    menuitems.push(
      <MenuItem onClick={ViewProfileOrgs}>
        <span className={classes.bold_text}>View Profile Groups</span>
      </MenuItem>
    );
  }
  if (addBillingOrg) {
    menuitems.push(
      <MenuItem onClick={ViewAddBillingOrg}>
        <span className={classes.bold_text}>Add Group to Profile</span>
      </MenuItem>
    );
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onMouseLeave={handleClose}
      >
        {menuitems}
      </Menu>
    </div>
  );
};
export default BillingExpansionPanel;
