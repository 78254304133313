import React, { FC, useEffect, useState } from "react";
import {
  Drawer as MUIDrawer,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { createMuiTheme } from "@material-ui/core/styles";

import { AppBar } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

// Import styles
import "@react-pdf-viewer/full-screen/lib/styles/index.css";

import { zoomPlugin } from "@react-pdf-viewer/zoom";

// Import styles
import "@react-pdf-viewer/zoom/lib/styles/index.css";

import { useStyles } from "../../styling/global/global";

const useStyle = makeStyles({
  drawerPaper: {
    marginTop: "64px",
    width: "20%",
  },
});

const currentTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#ebe7dd",
    },
    secondary: {
      main: "#010101",
    },
  },
});

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface PdfViewerComponentProps {
  url_name: string;
}
const PdfViewerComponent: FC<PdfViewerComponentProps> = ({
  url_name,
}: PdfViewerComponentProps) => {
  // Create new plugin instance
  // const defaultLayoutPluginInstance = defaultLayoutPlugin(
  // );
  const zoomPluginInstance = zoomPlugin();
  let base_dir = "/assets/docs/";
  useEffect(() => {}, []);
  return (
    <div
      style={{
        width: "100%",
        paddingLeft: "1%",
        paddingTop: "3%",
        height: 600,
        backgroundColor: "#e4e4e4",
        overflowY: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        border: "1px solid rgba(0, 0, 0, 0.3)",
      }}
    >
      <>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
          <Viewer
            fileUrl={`${base_dir}${url_name}`}
            plugins={[zoomPluginInstance]}
          />
        </Worker>
      </>
    </div>
  );
};
interface RProps {}

const Resources: FC<RProps> = ({}: RProps) => {
  const [tabValue, setTabValue] = useState(0);
  const classes = useStyles();
  const [openFeatures, setOpenFeatures] = useState(false);
  const [openInformation, setOpenInformation] = useState(false);
  const [openIntergrations, setOpenIntergrations] = useState(false);
  const [openSelfService, setOpenSelfService] = useState(false);
  const [openSMTP, setOpenSMTP] = useState(false);
  const [currentPdf, setCurrentPdf] = useState("");

  useEffect(() => {}, []);

  const itemsList = [
    {
      text: "Features",
      custom_drawer_state: openFeatures,
      id: 0,
      icon: <InboxIcon />,
      onClick: () => {
        // setTabValue(0);
        setOpenFeatures((prev) => !prev);
      },
      tabItems: [
        { name: "Activate a Site.pdf", id: 1 },
        { name: "Adding user at delivery method level.pdf", id: 2 },
        { name: "Configuring of exclusion zones.pdf", id: 3 },
        { name: "Consolidated report.pdf", id: 4 },
        { name: "Overriding alert rule schedule.pdf", id: 5 },
        { name: "RTSP settings on EdgePlus product.pdf", id: 6 },
        { name: "Site configuration.pdf", id: 7 },
        { name: "User management roles manual (version 3).pdf", id: 8 },
        { name: "Using the search feature to locate sites.pdf", id: 9 },
        {
          name: "Viewing alerts & inferences from management interface.pdf",
          id: 10,
        },
        {
          name: "Silence Button and Review Page.pdf",
          id: 11,
        },
        {
          name: "Dashboard.pdf",
          id: 12,
        },
        {
          name: "DeepAlert - Audio Talkback.pdf",
          id: 13,
        },
      ],
    },
    {
      text: "Information",
      custom_drawer_state: openInformation,
      id: 1,
      icon: <MailIcon />,
      onClick: () => {
        // setTabValue(1);
        setOpenInformation((prev) => !prev);
      },
      tabItems: [
        { name: "Camera stream routes.pdf", id: 1 },
        { name: "DeepAlert client support.pdf", id: 2 },
        { name: "Connect brochure updated March 2022.pdf", id: 3 },
        { name: "Premium brochure updated 2022.pdf", id: 4 },
      ],
    },
    {
      text: "Integrations",
      custom_drawer_state: openIntergrations,
      id: 2,
      icon: <MailIcon />,
      onClick: () => {
        // setTabValue(2);
        setOpenIntergrations((prev) => !prev);
      },
      tabItems: [
        { name: "System Telegram setup.pdf", id: 1 },
        {
          name: "HikCentral (configuration within DeepAlert system).pdf",
          id: 2,
        },
        {
          name: "HikCentral (configuration within HikCentral system).pdf",
          id: 3,
        },
        { name: "Patriot integration.pdf", id: 4 },
        { name: "DeepAlert - AXIS Integration.pdf", id: 5 },
      ],
    },
    {
      text: "Self Service",
      custom_drawer_state: openSelfService,
      id: 3,
      icon: <MailIcon />,
      onClick: () => {
        // setTabValue(3);
        setOpenSelfService((prev) => !prev);
      },
      tabItems: [
        { name: "RSTP camera stream test using VLC Media Player.pdf", id: 1 },
        { name: "DeepAlert hub activation manual.pdf", id: 2 },
        { name: "DeepAlert hub ISO install.pdf", id: 3 },
      ],
    },

    {
      text: "SMTP/Edgeplus",
      custom_drawer_state: openSMTP,
      id: 4,
      icon: <MailIcon />,
      onClick: () => {
        // setTabValue(4);
        setOpenSMTP((prev) => !prev);
      },
      tabItems: [
        { name: "Smart PSS.pdf", id: 1 },
        { name: "Axis Config Manual.pdf", id: 2 },
        { name: "Edge Installation Guide - Dahua.pdf", id: 3 },
        { name: "Edge Installation Guide - HikVision.pdf", id: 4 },
      ],
    },
  ];

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        paddingBottom: "2%",
      }}
    >
      <div
        className={classes.ResourcesRoot}
        style={{ backgroundColor: "#e4e4e4" }}
      >
        <List
          component="nav"
          aria-label="secondary mailbox folders"
          disablePadding
        >
          {itemsList.map((item, index) => {
            return (
              <>
                <ListItem
                  button
                  selected={tabValue === item.id}
                  key={item.id}
                  onClick={item.onClick}
                >
                  <ListItemText
                    className={classes.bold_text}
                    primary={item.text.split(".")[0]}
                  />
                  {item.custom_drawer_state ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={item.custom_drawer_state}
                  timeout="auto"
                  unmountOnExit
                >
                  <List
                    component="nav"
                    aria-label="secondary mailbox folders"
                    disablePadding
                    key={item.id}
                  >
                    {item &&
                      item.tabItems.map((doc, doc_index) => {
                        return (
                          <ListItem
                            button
                            key={doc.id}
                            selected={true}
                            style={{
                              marginLeft: "5%",
                              backgroundColor: "#f2f0eb",
                              width: "95%",
                            }}
                            onClick={() => {
                              setTabValue(item.id);
                              setCurrentPdf(doc.name);
                            }}
                          >
                            <ListItemText
                              className={classes.bold_text}
                              primary={doc.name.split(".")[0]}
                            />
                          </ListItem>
                        );
                      })}
                  </List>
                </Collapse>
              </>
            );
          })}
        </List>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "75%",
          alignItems: "center",
          justifyContent: "flex-start",
          position: "relative",
        }}
      >
        <AppBar
          position="static"
          className="navcolor"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "1%",
            paddingBottom: "1%",
          }}
        >
          <Typography>{`${itemsList[tabValue].text} - ${
            currentPdf.split(".")[0]
          }`}</Typography>
        </AppBar>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            backgroundColor: "#e4e4e4",
            justifyContent: "flex-end",
          }}
        >
          {currentPdf ? (
            <PdfViewerComponent url_name={currentPdf} />
          ) : (
            <div
              style={{
                height: "93vh",
                justifyContent: "center",
                display: "flex",
                width: "100%",
                border: "1px solid rgba(0, 0, 0, 0.3)",
              }}
            >
              <Typography align="center" style={{ marginTop: 30 }}>
                <span className={classes.bold_text_billing}>
                  Please select documents from the left drawer
                </span>
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Resources;
