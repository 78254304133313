import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AppStore } from "../../../stores/AppStore";
import { useStyles } from "../../styling/global/global";
import DeleteIcon from "@material-ui/icons/Delete";
import LoadingSpinner from "../../../spinner/LoadingSpinner";

import { Org } from "../../../services/FunctionService";

import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Tooltip,
  IconButton,
  Paper,
  Card,
  TextField,
} from "@material-ui/core";

interface NotesProps {
  predefined_notes: {
    alert_web_interface_notes: string[];
  };
}

interface AddGenericNotesProps {
  open: boolean;
  appStore?: AppStore;
  onClose: () => void;
  notesResultsArray: string[];
  currentOrg: Org;
}
const AddGenericNotes: React.FC<AddGenericNotesProps> = ({
  appStore,
  open,
  onClose,
  notesResultsArray,
  currentOrg,
}: AddGenericNotesProps) => {
  const classes = useStyles();
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [postOpen, setPostOpen] = useState(false);
  const [tempItemDelete, setTempItemDelete] = useState(1000);
  const [dummyNumber, setDummyNumber] = useState(0);
  const [notesArray, setNotesArray] = React.useState<string[]>([]);
  const handleTextChange = (event: React.ChangeEvent<any>) => {
    setNote(event.target.value);
  };
  var transformedArray: string[];
  useEffect(() => {
    const fetchNotes = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token && currentOrg) {
        setLoading(true);
        const notesResults = await appStore?.functionService.getNotes(
          token,
          currentOrg.org_id
        );
        if (notesResults?.success) {
          setLoading(false);
          if (notesResults.predefined_notes) {
            setNotesArray(
              notesResults.predefined_notes.alert_web_interface_notes
            );
          }
        }
      }
    };
    fetchNotes();
  }, [currentOrg, dummyNumber]);

  const updateNotes = async () => {
    setLoading(true);
    var alert_web_interface_notes = {
      alert_web_interface_notes: notesArray,
    };
    var predefined_notes = alert_web_interface_notes;

    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      const notesResults = await appStore?.functionService.updateNotes(
        token,
        currentOrg.org_id,
        predefined_notes
      );
      if (notesResults) {
        if (token && currentOrg) {
          setLoading(true);
          const notesResults = await appStore?.functionService.getNotes(
            token,
            currentOrg.org_id
          );
          if (notesResults?.success) {
            setLoading(false);
            if (notesResults.predefined_notes) {
              setNotesArray(
                notesResults.predefined_notes.alert_web_interface_notes
              );
            }
          }
        }
        setLoading(false);
        handlePostClose();
      }
    }
  };
  const alternatingColor = ["#e6e4df", "#fafafa"];

  const addNote = () => {
    if (note) {
      if (notesArray) {
        setNotesArray((value) => [...value, note]);
        setNote("");
        setPostOpen(true);
      } else {
        setNotesArray([note]);
        setNote("");
        setPostOpen(true);
      }
    }
  };
  const handleClose = () => {
    updateNotes();
  };
  const handleCancel = () => {
    // setLoading(true);
    onClose();
  };
  const handlePostClose = () => {
    setDummyNumber((value) => value + 1);
    setPostOpen(false);
  };

  const handleCloseUpdate = () => {
    if (note) {
      setNotesArray((value) => [...value, note]);
      setNote("");
    }
    setPostOpen(false);
    updateNotes();
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        className="edit-dialog"
        fullWidth={true}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingLeft: "2%",
            paddingRight: "2%",
          }}
        >
          <DialogTitle
            style={{ alignSelf: "center" }}
            className={classes.bold_text}
            id="simple-dialog-title"
          >
            <span style={{ fontSize: "25px" }}>Alert web interface notes </span>
          </DialogTitle>

          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <TextField
                value={note}
                onChange={handleTextChange}
                variant="outlined"
                label={
                  <span
                    className={classes.bold_text}
                    style={{ color: "black" }}
                  >
                    Add predefined note
                  </span>
                }
              ></TextField>
              <Button
                style={{ marginTop: "3%", marginBottom: "3%" }}
                variant="contained"
                className="save"
                onClick={addNote}
              >
                Add note
              </Button>
              {notesArray &&
                notesArray.map((singleNote, index) => {
                  const customColor =
                    index % 2 == 0 ? alternatingColor[0] : alternatingColor[1];
                  return (
                    <div
                      style={{
                        width: "100%",
                        marginTop: "1%",
                        marginBottom: "1%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        backgroundColor: customColor,
                        minWidth: "0",
                        overflow: "hidden",

                        // backgroundColor:index%2== 0?alternatingColor[0]:alternatingColor[1]
                      }}
                    >
                      <Typography display="inline">
                        {/* <span
                        style={{
                          textOverflow: 'ellipsis',
                          display: 'inline-block',
                        }}
                      > */}
                        {singleNote}
                        {/* </span> */}
                      </Typography>
                      <DeleteIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setNotesArray(
                            notesArray.filter(
                              (item, filterIndex) => filterIndex !== index
                            )
                          );

                          setPostOpen(true);
                          // notesArray.map((item, delIndex) => {
                          //   if (item == notesArray[delIndex]) {
                          //     setTempItemDelete(delIndex);
                          //     setPostOpen(true);
                          //   }
                          // });
                        }}
                      />
                    </div>
                  );
                })}

              <div
                style={{ marginTop: "3%", marginBottom: "3%" }}
                className={classes.button_container}
              >
                <Button
                  variant="contained"
                  className="save"
                  onClick={handleCancel}
                >
                  Dismiss
                </Button>
              </div>
            </>
          )}
        </div>
      </Dialog>
      <Dialog
        onClose={handlePostClose}
        open={postOpen}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
      >
        <DialogTitle className={classes.bold_text}>
          Are you sure you want to update the predefined notes?
        </DialogTitle>
        <div className={classes.twoButtonsInARow}>
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => handlePostClose()}
          >
            <span className={classes.bold_text}>Dismiss</span>
          </Button>
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => handleCloseUpdate()}
          >
            <span className={classes.bold_text}>Update</span>
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default AddGenericNotes;
