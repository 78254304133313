import React from "react";
import classes from "./ui.module.css";

interface ListItemProps {
  site_name: string;
  org_id: number;
  enabled: string;
  site_id: number;
  sensor_count: number;
}

const SiteListItem: React.FC<ListItemProps> = ({
  site_name,
  org_id,
  enabled,
  site_id,
  sensor_count,
}) => {
  return (
    <li key={site_id} className={classes.item}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p
          className={classes["item-wrapper"]}
          style={{ fontSize: 13, color: "#15325F" }}
        >
          site name: <span style={{ color: "grey" }}>{site_name}</span>
        </p>
        <p
          className={classes["item-wrapper"]}
          style={{ fontSize: 13, color: "#15325F" }}
        >
          site id: <span style={{ color: "grey" }}>{site_id}</span> {"  "}|
          {"  "} org id: <span style={{ color: "grey" }}>{org_id}</span> {"  "}|
          {"  "} enabled:{" "}
          <span style={{ color: "grey" }}>{enabled ? "true" : "false"}</span>
          {"  "}|{"  "} camera count:{" "}
          <span style={{ color: "grey" }}>{sensor_count}</span>
        </p>
      </div>
    </li>
  );
};

export default SiteListItem;
