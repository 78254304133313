import React from "react";
import classes from "./ui.module.css";
import { Button } from "antd";
// import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

interface Props {
  onClickHandler: (id: string) => void;
  id: string;
  title: string;
  red?: boolean;
  disabled?: boolean;
  groupid?: number;
  styles?: any;
}

const CustomButton: React.FC<Props> = ({
  id,
  onClickHandler,
  title,
  red,
  disabled,
  groupid = -1,
  styles = {},
}) => {
  const buttonHandler = () => {
    onClickHandler(id);
  };

  if (disabled) {
    return (
      <Tooltip
        placement="top"
        title={
          groupid !== -1 && title.includes("Silence")
            ? "Silencing is not supported for this product or event type."
            : ""
        }
      >
        <Button
          style={{
            backgroundColor: "red",
            color: "white",
            marginLeft: 5,
            minWidth: 100,
            opacity: disabled ? 0.5 : 1,
            borderRadius: 4,
            ...styles,
          }}
          // variant="contained"
          // Set the color to red
          className={!red ? classes.btn : classes.btn_red}
          onClick={buttonHandler}
          disabled={disabled}
        >
          {title}
        </Button>
      </Tooltip>
    );
  } else {
    return (
      <Button
        style={{
          color: "white",
          backgroundColor: "red",
          marginLeft: 5,
          fontWeight: 600,
          minWidth: 100,
          opacity: disabled ? 0.5 : 1,
          borderRadius: 4,
          ...styles,
        }}
        // variant="contained"
        // Set the color to red
        className={!red ? classes.btn : classes.btn_red}
        onClick={buttonHandler}
      >
        {title}
      </Button>
    );
  }
};

export default CustomButton;
