import React, { useState, useEffect, useRef } from "react";
import { AppStore } from "../../../stores/AppStore";
import {
  Site,
  Sensor,
  Hub,
  Classifier,
  DataOriginElements,
} from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import SearchIcon from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";
import FormHelperText from "@mui/material/FormHelperText";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
interface CameraWizardProps {
  appStore?: AppStore;
  site?: Site;
  open: boolean;
  clone: Sensor | undefined;
  onClose: () => void;
  saveNewCamera: (
    hub_id: number,
    site_id: number,
    name: string,
    config: string,
    current_env: string
  ) => void;
}

const cameraBrands = [
  "Dahua",
  "Hikvision",
  "TruVision",
  "GeoVision-a",
  "GeoVision-b",
  "ProVision",
  "Uniview-camera",
  "Uniview-nvr",
  "Vivotek",
  "Axis",
  "Avigilon",
  "TVT-camera",
  "TVT-nvr/dvr",
  "AVTECH",
  "TeleEye",
];

const cameraRoutes = [
  {
    brand: "Dahua",
    route: "/cam/realmonitor?channel=1&subtype=1",
  },
  {
    brand: "Hikvision",
    route: "/streaming/channels/102",
  },
  {
    brand: "TruVision",
    route: " /streaming/channels/102",
  },
  {
    brand: "GeoVision-a",
    route: "/multicast/video2",
  },
  {
    brand: "GeoVision-b",
    route: "/CH002.sdp",
  },
  {
    brand: "ProVision",
    route: "/chID=1&streamType=sub&linkType=tcp",
  },
  {
    brand: "Uniview-camera",
    route: "/media/video2",
  },
  {
    brand: "Uniview-nvr",
    route: "/unicast/c1-1/s1/live",
  },
  {
    brand: "Vivotek",
    route: "/live2.sdp",
  },
  {
    brand: "Axis",
    route: " /axis-media/media.amp",
  },
  {
    brand: "Avigilon",
    route: "/defaultSecondary?mtu=1440&streamType=u",
  },
  {
    brand: "TVT-camera",
    route: "http://ip:554/profile1",
  },
  {
    brand: "TVT-nvr/dvr",
    route: "/?chID=1&streamtype=sub",
  },
  {
    brand: "AVTECH",
    route: "/live/video/ch02/profile2",
  },
  {
    brand: "TeleEye",
    route: "/profile2",
  },
];

const CameraWizard: React.FC<CameraWizardProps> = ({
  saveNewCamera,
  appStore,
  site,
  open,
  onClose,
  clone,
}: CameraWizardProps) => {
  const classes = useStyles();
  const [hubs, setHubs] = useState<Hub[] | undefined>([]);
  const [sharedhubs, setSharedHubs] = useState<Hub[] | undefined>([]);
  const [dataOriginDefaults, setDataOriginDefaults] = useState<
    DataOriginElements | undefined
  >(undefined);
  const [classifier, setClassifier] = useState<Classifier | undefined>(
    undefined
  );
  const [hub_id, setHubId] = useState(0);
  const [cameras, setCameras] = useState([[]]);
  const [scan, setScan] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const [name, setName] = useState("");
  const [fullrtspurl, setFullRTSPUrl] = useState("");
  const [config, setConfig] = React.useState<
    { [key: string]: any } | undefined
  >(undefined);
  const [message, setMessage] = React.useState<string>(" ");
  const [showrtspurl, setShowRtspUrl] = useState(false);
  const [showpassword, setShowPassword] = useState(false);
  const [testresult, setTestResult] = React.useState<
    { [key: string]: any } | undefined
  >(undefined);
  const [videotestresult, setVideoTestResult] = React.useState<
    { [key: string]: any } | undefined
  >(undefined);
  const [showping, setShowPing] = useState(false);
  const [showtelnet, setShowTelnet] = useState(false);
  const [showcurl, setShowCurl] = useState(false);
  const [title, setTitle] = useState("Add New Camera to " + site?.site_name);
  const currentEnv = useRef("None");
  const [displayEnv, setDisplayEnv] = useState("None");
  const environment_list = [
    "None",
    "Commercial - exterior",
    "Commercial - interior",
    "Commercial - perimeter",
    "Farm",
    "High site / tower",
    "Parking area",
    "Public space - pedestrian",
    "Rapid deployment tower",
    "Residential - exterior",
    "Residential - interior",
    "Residential - perimeter",
    "Rural",
    "Shopping mall - interior",
    "Other",
  ];
  //
  //   /streaming/channels/102
  //   DAtcam007!

  useEffect(() => {
    const setData = async () => {
      if (clone) {
        setConfig(clone.config);
        setHubId(clone.hub_id);
      }
    };
    setData();
  }, [open]);
  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && site && open) {
        var hubs = await appStore?.functionService.getHubsForSite(
          token,
          site.site_id
        );
        setHubs(hubs);
        setLoading(false);
      }
    };
    fetchData();
  }, [open]);
  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && appStore && open) {
        var hubs = await appStore?.functionService.getHubsSharedForOrg(
          token,
          appStore.authService.getEntryOrg()?.id!
        );
        setSharedHubs(hubs);
        setLoading(false);
      }
    };
    fetchData();
  }, [open]);
  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && site) {
        var classifier = await appStore?.functionService.getClassifierForSite(
          token,
          site.site_id
        );
        setClassifier(classifier);
        if (config == undefined) {
          const defaults: DataOriginElements =
            await appStore?.functionService.getSiteDataOrigin(
              token,
              site.site_id
            );
          setDataOriginDefaults(defaults);
          setConfig(defaults);

          // setConfig(classifier?.sensor_defaults);
        }
      }
    };
    fetchData();
  }, [site]);
  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && scan && hubs!.length > 0) {
        let hub_id_str = hubs?.find((x) => x.hub_id === hub_id)?.hub_id_str;
        if (token && scan) {
          var cameras = await appStore?.functionService.getHubCameras(
            token,
            hub_id_str!
          );
          if (cameras && cameras.success) {
            if (cameras.cameras.length > 0) {
              setCameras(cameras.cameras);
              setLoading(false);
              setRefresh(new Date().getTime());
            } else {
              setMessage("No cameras found!");
              setLoading(false);
            }
          } else {
            setMessage("Could not scan your hub for cameras!");
            setLoading(false);
          }
          setScan(false);
        }
      } else if (token && scan && sharedhubs) {
        let hub_id_str = sharedhubs?.find(
          (x) => x.hub_id === hub_id
        )?.hub_id_str;
        if (token && scan) {
          var cameras = await appStore?.functionService.getHubCameras(
            token,
            hub_id_str!
          );
          if (cameras && cameras.success) {
            if (cameras.cameras.length > 0) {
              setCameras(cameras.cameras);
              setLoading(false);
              setRefresh(new Date().getTime());
            } else {
              setMessage("No cameras found!");
              setLoading(false);
            }
          } else {
            setMessage("Could not scan your hub for cameras!");
            setLoading(false);
          }
          setScan(false);
        }
      }
    };
    fetchData();
  }, [scan]);
  const handleHubSelection = (event: React.ChangeEvent<any>) => {
    setHubId(event.target.value);

    setMessage("");
  };
  const scanHub = () => {
    if (hub_id !== 0) {
      setLoading(true);
      setScan(true);
    } else {
      setMessage("Please select a hub first!");
    }
  };

  const handleEnvironmentChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    var new_env = event.target.value as string;
    if (new_env === "Other") {
      var own_environment = prompt("Please enter a custom environment");
      new_env += " -> " + own_environment;
    }
    currentEnv.current = new_env;

    sanityCheck();
  };

  const sanityCheck = () => {
    if (currentEnv?.current?.includes("Other")) {
      setDisplayEnv("Other");
    } else if (currentEnv) {
      setDisplayEnv(currentEnv.current);
    } else {
      setDisplayEnv("None");
    }
  };

  const handleClose = () => {
    setHubId(0);
    setConfig(undefined);
    setDataOriginDefaults(undefined);
    setName("");
    setCameras([[]]);
    setFullRTSPUrl("");
    onClose();
  };
  const handleShowRtspUrl = (event: React.ChangeEvent<any>) => {
    if (showrtspurl) {
      rtspToConfig();
    } else {
      if (config) {
        let connectstr =
          "rtsp://" +
          config.camera_rtsp_login +
          ":" +
          config.camera_rtsp_password +
          "@" +
          config.camera_rtsp_ip +
          ":" +
          config.camera_rtsp_port +
          config.camera_rtsp_url;
        setFullRTSPUrl(connectstr);
      }
    }
    setShowRtspUrl(!showrtspurl);
  };
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    })
  )(TableRow);
  var hublist: React.ReactNode[] = [];
  if (hubs) {
    hubs.forEach((hub) => {
      hublist.push(<MenuItem value={hub.hub_id}>{hub.hub_id_str}</MenuItem>);
    });
  }
  if (sharedhubs) {
    sharedhubs.forEach((hub) => {
      hublist.push(
        <MenuItem value={hub.hub_id}>{hub.hub_id_str + " (Shared)"}</MenuItem>
      );
    });
  }
  if (hublist.length == 0) {
    hublist.push(<MenuItem>No hubs for site</MenuItem>);
  }
  //Create table object for camera info returned after camera scan
  var cameratable: React.ReactNode[] = [];
  var camerasdisplay: React.ReactNode[] = [];
  if (cameras && cameras.length > 0 && cameras[0].length === 3) {
    cameras.forEach((camera) => {
      if (camera.length === 3) {
        camerasdisplay.push(
          <Tooltip title={"Click to auto-populate"} placement="bottom">
            <StyledTableRow
              className={classes.grab}
              onClick={(event) =>
                handleRowClicked(event, camera[0], camera[1], camera[2])
              }
            >
              <TableCell>
                <Typography className={classes.bold_text}>
                  {camera[0]}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.bold_text}>
                  {camera[1]}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.bold_text}>
                  {camera[2]}
                </Typography>
              </TableCell>
            </StyledTableRow>
          </Tooltip>
        );
      }
    });
    cameratable.push(
      <TableContainer component={Paper}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>IP Address</TableCell>
              <TableCell>Port</TableCell>
              <TableCell>Manufacturer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{camerasdisplay}</TableBody>
        </Table>
      </TableContainer>
    );
  }
  const handleRowClicked = (
    event: React.MouseEvent<unknown>,
    ipaddress: string,
    port: string,
    manufacturer: string
  ) => {
    if (config) {
      if (manufacturer.indexOf("Hikvision") >= 0) {
        config.camera_rtsp_url = "/streaming/channels/102";
      }
      if (config.camera_rtsp_login === "") {
        config.camera_rtsp_login = "admin";
      }
      if (config.camera_rtsp_password === "") {
        config.camera_rtsp_password = "password";
      }
      if (showrtspurl) {
        setFullRTSPUrl(
          "rtsp://" +
            config.camera_rtsp_login +
            ":" +
            config.camera_rtsp_password +
            "@" +
            ipaddress +
            ":" +
            port +
            config.camera_rtsp_url
        );
      } else {
        config.camera_rtsp_ip = ipaddress;
        config.camera_rtsp_port = port;
        setConfig(config);
        setRefresh(new Date().getTime());
      }
    }
  };
  const handleNameChange = (event: React.ChangeEvent<any>) => {
    setName(event.target.value);
    setMessage("");
  };
  const handleFullRTSPUrlChange = (event: React.ChangeEvent<any>) => {
    setFullRTSPUrl(event.target.value);
    setMessage("");
  };
  const handlePortChange = (event: React.ChangeEvent<any>) => {
    if (isNaN(event.target.value)) {
      setMessage("Port must be a valid number!");
    } else {
      if (config) {
        config.camera_rtsp_port = event.target.value;
        setConfig(config);
        setRefresh(new Date().getTime());
      }
    }
  };
  const handleLoginChange = (event: React.ChangeEvent<any>) => {
    if (config) {
      config.camera_rtsp_login = event.target.value;
      setConfig(config);
      setRefresh(new Date().getTime());
    }
  };
  const handleIPChange = (event: React.ChangeEvent<any>) => {
    if (config) {
      config.camera_rtsp_ip = event.target.value;
      setConfig(config);
      setRefresh(new Date().getTime());
    }
  };

  const [route, setRoute] = useState("");

  const handleStreamChange = (event: React.ChangeEvent<any>) => {
    // setRoute(event?.target?.value);
    // if (config) {
    //   config.camera_rtsp_url = route;
    //   setConfig(config);
    //   setRefresh(new Date().getTime());
    // }
    if (config) {
      config.camera_rtsp_url = event?.target?.value;
      setConfig(config);
      setRefresh(new Date().getTime());
    } else {
      let config_data: { [key: string]: any } = {};
      config_data["camera_rtsp_ip"] = "";
      config_data["camera_rtsp_port"] = "";
      config_data["camera_rtsp_password"] = "";
      config_data["camera_rtsp_url"] = event?.target?.value;
      config_data["camera_rtsp_login"] = "";
      config_data["camera_http_port"] = "";
      config_data["camera_brand"] = "";
      setConfig(config_data);
    }
  };
  const brandHandler = (event: React.ChangeEvent<any>) => {
    if (config) {
      const changeStreamRoute = cameraRoutes.findIndex(
        (item) => item.brand === event?.target?.value
      );
      config.camera_brand = event?.target?.value;
      config.camera_rtsp_url = cameraRoutes[changeStreamRoute].route;
      setConfig(config);
      setRefresh(new Date().getTime());
    } else {
      const changeStreamRoute = cameraRoutes.findIndex(
        (item) => item.brand === event?.target?.value
      );
      let config_data: { [key: string]: any } = {};
      config_data["camera_rtsp_ip"] = "";
      config_data["camera_rtsp_port"] = "";
      config_data["camera_rtsp_password"] = "";
      config_data["camera_rtsp_url"] = cameraRoutes[changeStreamRoute].route;
      config_data["camera_rtsp_login"] = "";
      config_data["camera_http_port"] = "";
      config_data["camera_brand"] = event?.target?.value;
      setConfig(config_data);
    }
  };

  const handleHTTPPort = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (config) {
      config.camera_http_port = event?.target?.value;
      setConfig(config);
      setRefresh(new Date().getTime());
    } else {
      let config_data: { [key: string]: any } = {};
      config_data["camera_rtsp_ip"] = "";
      config_data["camera_rtsp_port"] = "";
      config_data["camera_rtsp_password"] = "";
      config_data["camera_rtsp_url"] = "";
      config_data["camera_rtsp_login"] = "";
      config_data["camera_http_port"] = event?.target?.value;
      config_data["camera_brand"] = "";
      setConfig(config_data);
    }
  };

  const handelPasswordChange = (event: React.ChangeEvent<any>) => {
    if (config) {
      config.camera_rtsp_password = event.target.value;
      setConfig(config);
      setRefresh(new Date().getTime());
    }
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showpassword);
  };
  const handleCameraSelection = (event: React.ChangeEvent<any>) => {
    if (config) {
      config.camera_type = event.target.value;
      setConfig(config);
      setRefresh(new Date().getTime());
    }
  };
  //Record a 5 sec video from camera and return video analytics
  const handleTest = async () => {
    setMessage("");
    setTitle("Running camera video stream test");
    if (config) {
      let connectstr = "";
      if (showrtspurl) {
        connectstr = fullrtspurl;
      } else {
        connectstr =
          "rtsp://" +
          config.camera_rtsp_login +
          ":" +
          config.camera_rtsp_password +
          "@" +
          config.camera_rtsp_ip +
          ":" +
          config.camera_rtsp_port +
          config.camera_rtsp_url;
      }
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        let hub_id_str = hubs?.find((x) => x.hub_id === hub_id)?.hub_id_str;
        if (hub_id_str === undefined) {
          hub_id_str = sharedhubs?.find((x) => x.hub_id === hub_id)?.hub_id_str;
        }
        if (hub_id_str) {
          setLoading(true);
          var video_info = await appStore?.functionService.getVideoInfo(
            token,
            hub_id_str,
            connectstr
          );
          if (video_info && video_info.success && video_info.info) {
            if (video_info.info.error) {
              setMessage(
                "Could not run camera stream test on your hub/camera! " +
                  video_info.info.error
              );
            } else {
              setVideoTestResult(video_info.info.result);

              setRefresh(new Date().getTime());
            }
          } else {
            setMessage("Could not run camera stream test on your hub/camera!");
          }
          setTitle("Add New Camera to " + site?.site_name);
          setLoading(false);
        }
      }
    }
  };
  const handleDoneTest = async () => {
    setTestResult(undefined);
    setVideoTestResult(undefined);
    setMessage("");
    setRefresh(new Date().getTime());
  };
  //Run ping, telnet and curl test for the camera
  const handleAdvancedTest = async () => {
    let valid = true;
    setMessage("");
    setTitle("Running camera network test");
    if (showrtspurl && config) {
      if (!rtspToConfig()) {
        valid = false;
        setMessage("Invalid RTSP format!");
      }
    }
    var token = await appStore?.authService.getAuthorisedToken();
    if (token && valid && config) {
      let hub_id_str = hubs?.find((x) => x.hub_id === hub_id)?.hub_id_str;
      if (hub_id_str === undefined) {
        hub_id_str = sharedhubs?.find((x) => x.hub_id === hub_id)?.hub_id_str;
      }
      if (hub_id_str) {
        let connection_str = "";
        if (showrtspurl) {
          connection_str = fullrtspurl;
        } else {
          connection_str =
            "rtsp://" +
            config.camera_rtsp_login +
            ":" +
            config.camera_rtsp_password +
            "@" +
            config.camera_rtsp_ip +
            ":" +
            config.camera_rtsp_port +
            config.camera_rtsp_url;
        }
        setLoading(true);
        var advanced_tests = await appStore?.functionService.getAdvancedTests(
          token,
          hub_id_str,
          config.camera_rtsp_ip,
          Number(config.camera_rtsp_port),
          connection_str
        );
        if (advanced_tests && advanced_tests.success && advanced_tests.info) {
          setTestResult(advanced_tests.info);
          setRefresh(new Date().getTime());
        } else {
          setMessage("Could not run network testing on your hub/camera!");
        }
        setTitle("Add New Camera to " + site?.site_name);
        setLoading(false);
      }
    }
  };

  const rtspToConfig = () => {
    let valid = true;
    let rtspurl = fullrtspurl;
    if (rtspurl.toLowerCase().startsWith("rtsp://")) {
      rtspurl = rtspurl.substring(7);
      let i = rtspurl.indexOf(":");
      if (i > 0) {
        config!.camera_rtsp_login = rtspurl.substring(0, i);
        rtspurl = rtspurl.substring(i + 1);
        i = rtspurl.indexOf("@");
        if (i > 0) {
          config!.camera_rtsp_password = rtspurl.substring(0, i);
          rtspurl = rtspurl.substring(i + 1);
          i = rtspurl.indexOf(":");
          if (i > 0) {
            config!.camera_rtsp_ip = rtspurl.substring(0, i);
            rtspurl = rtspurl.substring(i + 1);
            i = rtspurl.indexOf("/");
            if (i > 0) {
              if (isNaN(Number(rtspurl.substring(0, i)))) {
                valid = false;
              } else {
                config!.camera_rtsp_port = rtspurl.substring(0, i);
                config!.camera_rtsp_url = rtspurl.substring(i);
                if (
                  config!.camera_rtsp_url.indexOf(":") >= 0 ||
                  config!.camera_rtsp_url.indexOf("@") >= 0
                ) {
                  valid = false;
                }
              }
            } else {
              valid = false;
            }
          } else {
            valid = false;
          }
        } else {
          valid = false;
        }
      } else {
        valid = false;
      }
    } else {
      valid = false;
    }
    return valid;
  };

  const [errors, setErrors] = useState<any>({
    name: false,
    camera_brand: false,
    rtsp_ip: false,
    rtsp_url: false,
    rtsp_password: false,
    rtsp_port: false,
    rtsp_login: false,
    http_port: false,
  });

  const saveCamera = () => {
    if (config && site?.data_origin?.includes("mobcam")) {
      if (
        !config.camera_rtsp_ip ||
        !config.camera_rtsp_url ||
        !config.camera_rtsp_password ||
        !config.camera_rtsp_port ||
        !config.camera_rtsp_login ||
        !config.camera_http_port ||
        !name ||
        !config.camera_brand
      ) {
        setErrors({
          name: !name ? true : false,
          camera_brand: !config.camera_brand ? true : false,
          rtsp_ip: !config.camera_rtsp_ip ? true : false,
          rtsp_url: !config.camera_rtsp_url ? true : false,
          rtsp_password: !config.camera_rtsp_password ? true : false,
          rtsp_port: !config.camera_rtsp_port ? true : false,
          rtsp_login: !config.camera_rtsp_login ? true : false,
          http_port: !config.camera_http_port ? true : false,
        });
        return;
      }
    } else if (config && !site?.data_origin?.includes("mobcam")) {
      if (
        !config.camera_rtsp_ip ||
        !config.camera_rtsp_url ||
        !config.camera_rtsp_password ||
        !config.camera_rtsp_port ||
        !config.camera_rtsp_login ||
        !config.camera_http_port ||
        !name
      ) {
        setErrors({
          name: !name ? true : false,
          camera_brand: false,
          rtsp_ip: !config.camera_rtsp_ip ? true : false,
          rtsp_url: !config.camera_rtsp_url ? true : false,
          rtsp_password: !config.camera_rtsp_password ? true : false,
          rtsp_port: !config.camera_rtsp_port ? true : false,
          rtsp_login: !config.camera_rtsp_login ? true : false,
          http_port: !config.camera_http_port ? true : false,
        });
        return;
      }
    }
    setMessage("");
    if (showrtspurl && config) {
      if (rtspToConfig()) {
        if (name === "") {
          setMessage("Please enter a human name for the camera!");
        } else {
          var sensorconfig = appStore?.helpers.configFromDefault(
            dataOriginDefaults?.sensor_defaults!,
            config!
          );
          var strconfig = JSON.stringify(sensorconfig);
          saveNewCamera(
            hub_id,
            site!.site_id,
            name,
            strconfig,
            currentEnv.current
          );
        }
      } else {
        setMessage("Invalid rtsp format !");
      }
    } else {
      if (name === "") {
        setMessage("Please enter a human name for the camera !");
      } else {
        if (
          config!.camera_rtsp_url.indexOf(":") >= 0 ||
          config!.camera_rtsp_url.indexOf("@") >= 0 ||
          config!.camera_rtsp_url.indexOf("rtsp") === 0
        ) {
          setMessage("Invalid stream route format !");
        } else {
          var sensorconfig = appStore?.helpers.configFromDefault(
            dataOriginDefaults?.sensor_defaults!,
            config!
          );
          if (site?.data_origin?.includes("mobcam")) {
            sensorconfig!.camera_brand = config?.camera_brand;
          } else {
            sensorconfig!.camera_http_port = config?.camera_http_port;
            sensorconfig!.camera_brand = config?.camera_brand;
          }
          var strconfig = JSON.stringify(sensorconfig);
          saveNewCamera(
            hub_id,
            site!.site_id,
            name,
            strconfig,
            currentEnv.current
          );
        }
      }
    }
  };
  const clickPingOpen = () => {
    setShowPing(!showping);
  };
  const clickTelnetOpen = () => {
    setShowTelnet(!showtelnet);
  };
  const clickCurlOpen = () => {
    setShowCurl(!showcurl);
  };
  var rtspdetails: React.ReactNode[] = [];
  if (hub_id !== 0) {
    if (showrtspurl) {
      rtspdetails.push(
        <>
          <TextField
            label="Camera Name"
            onChange={handleNameChange}
            value={name}
            variant="outlined"
            margin="normal"
            fullWidth
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
          />
          <FormControl component="fieldset" fullWidth variant="outlined">
            <FormLabel component="legend">Camera type</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              row
              value={config?.camera_type}
              defaultValue="rtsp_h264"
              onChange={handleCameraSelection}
            >
              <FormControlLabel
                value="rtsp_h264"
                control={<Radio />}
                color="primary"
                label={
                  <Typography className={classes.bold_text}>
                    rtsp_h264
                  </Typography>
                }
              />
              <FormControlLabel
                value="rtsp_h265"
                control={<Radio />}
                color="primary"
                label={
                  <Typography className={classes.bold_text}>
                    rtsp_h265
                  </Typography>
                }
              />
            </RadioGroup>
            {/* <RadioGroup
              value={config?.camera_type}
              onChange={handleCameraSelection}
            >
              <FormControlLabel
                value="rtsp_h264"
                control={<Radio color="primary" />}
                label="RTSP H264"
              />
              <FormControlLabel
                value="rtsp_h265"
                control={<Radio color="primary" />}
                label="RTSP H265"
              />
            </RadioGroup> */}
          </FormControl>
          <TextField
            label="Full RTSP URL"
            onChange={handleFullRTSPUrlChange}
            value={fullrtspurl}
            variant="outlined"
            margin="normal"
            fullWidth
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
          />
        </>
      );
    } else {
      rtspdetails.push(
        <>
          <TextField
            error={errors?.name}
            label="Camera Human Name"
            onChange={handleNameChange}
            value={name}
            variant="outlined"
            margin="normal"
            fullWidth
            helperText={errors?.name ? "Camera Human Name required" : ""}
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
            style={{
              margin: "20px 0",
            }}
          />
          <br></br>
          <FormControl
            fullWidth={true}
            variant="outlined"
            style={{
              margin: "10px 0",
            }}
          >
            <InputLabel
              id="demo-simple-select-outlined-label"
              className={classes.bold_text}
            >
              Select Environment
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={displayEnv}
              onChange={handleEnvironmentChange}
              label="Environment"
              // style={{ width: 300 }}
            >
              {environment_list.map((option: string) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl component="fieldset" fullWidth variant="outlined">
            <FormLabel component="legend">Camera type</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              row
              defaultValue="rtsp_h264"
              value={config?.camera_type}
              onChange={handleCameraSelection}
            >
              <FormControlLabel
                value="rtsp_h264"
                control={<Radio />}
                color="primary"
                label={
                  <Typography className={classes.bold_text}>
                    rtsp_h264
                  </Typography>
                }
              />
              <FormControlLabel
                value="rtsp_h265"
                control={<Radio />}
                color="primary"
                label={
                  <Typography className={classes.bold_text}>
                    rtsp_h265
                  </Typography>
                }
              />
            </RadioGroup>
            {/* <RadioGroup
              value={config?.camera_type}
              onChange={handleCameraSelection}
            >
              <FormControlLabel
                value="rtsp_h264"
                control={<Radio color="primary" />}
                label="RTSP H264"
              />
            </RadioGroup> */}
          </FormControl>
          <TextField
            error={errors?.rtsp_ip}
            required
            label="IP Address"
            onChange={handleIPChange}
            value={config?.camera_rtsp_ip}
            variant="outlined"
            margin="normal"
            fullWidth
            helperText={errors?.rtsp_password ? "IP Address is required" : ""}
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
          />
          {site?.data_origin?.includes("mobcam") ? (
            <FormControl
              fullWidth={true}
              variant="outlined"
              style={{ margin: "15px 0 10px 0" }}
              error={errors?.camera_brand}
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                className={classes.bold_text}
              >
                Camera Brand
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                // value={brand}
                value={config?.camera_brand}
                // onChange={handleBrandHandler}
                onChange={brandHandler}
                label="Environment"
                // style={{ width: 300 }}
              >
                {cameraBrands.map((option: string) => (
                  <MenuItem value={option}>{option}</MenuItem>
                ))}
              </Select>
              <FormHelperText
                style={{
                  color: "red",
                }}
              >
                {errors?.camera_brand
                  ? "Please select a valid camera brand"
                  : ""}
              </FormHelperText>
            </FormControl>
          ) : (
            ""
          )}
          <TextField
            error={errors?.rtsp_url}
            required
            label="Stream Route"
            onChange={handleStreamChange}
            value={config?.camera_rtsp_url}
            variant="outlined"
            margin="normal"
            fullWidth
            helperText={errors?.rtsp_url ? "Stream Route is required" : ""}
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.bold_text,
              },
            }}
            placeholder="Enter Stream Route" // Add a placeholder here
          />
          <TextField
            error={errors?.http_port}
            required
            fullWidth
            label="HTTP Port"
            onChange={handleHTTPPort}
            value={config?.camera_http_port}
            variant="outlined"
            margin="normal"
            helperText={errors?.http_port ? "HTTP Port is required" : ""}
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.bold_text,
              },
            }}
            placeholder="Enter HTTP Port" // Add a placeholder here
          />
          <TextField
            error={errors?.rtsp_port}
            required
            label="RTSP Port"
            onChange={handlePortChange}
            value={config?.camera_rtsp_port}
            variant="outlined"
            margin="normal"
            fullWidth
            helperText={errors?.rtsp_port ? "RTSP Port is required" : ""}
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
          />
          <TextField
            error={errors?.rtsp_login}
            required
            label="RTSP Login"
            onChange={handleLoginChange}
            value={config?.camera_rtsp_login}
            variant="outlined"
            margin="normal"
            fullWidth
            helperText={errors?.rtsp_login ? "RTSP Login is required" : ""}
            autoComplete="off"
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
          />
          <TextField
            error={errors?.rtsp_password}
            required
            label="RTSP Password"
            type={showpassword ? "text" : "password"}
            value={config?.camera_rtsp_password}
            onChange={handelPasswordChange}
            variant="outlined"
            margin="normal"
            fullWidth
            helperText={
              errors?.rtsp_password ? "RTSP Password is required" : ""
            }
            autoComplete="new-password"
            InputProps={{
              className: classes.bold_text,
              startAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showpassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ),
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
          />
        </>
      );
    }
  }
  var testbody: React.ReactNode[] = [];
  var pingbody: React.ReactNode[] = [];
  var videobody: React.ReactNode[] = [];
  var telnetbody: React.ReactNode[] = [];
  var curlbody: React.ReactNode[] = [];
  let pingsuccess = false;
  let telnetsuccess = false;
  let curlsuccess = false;

  if (testresult != undefined) {
    for (let key in testresult) {
      let result = testresult[key];
      if (key.toLowerCase().indexOf("ping") >= 0) {
        if (showping) {
          pingbody.push(
            <StyledTableRow>
              <TableCell>
                <Typography className={classes.bold_text}>{key}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.bold_text}>{result}</Typography>
              </TableCell>
            </StyledTableRow>
          );
        }
        if (result.indexOf("bytes from") >= 0) {
          pingsuccess = true;
        }
      }
      if (key.toLowerCase().indexOf("telnet") >= 0) {
        if (showtelnet) {
          telnetbody.push(
            <StyledTableRow>
              <TableCell>
                <Typography className={classes.bold_text}>{key}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.bold_text}>{result}</Typography>
              </TableCell>
            </StyledTableRow>
          );
        }
        if (result.indexOf("Connected to") >= 0) {
          telnetsuccess = true;
        }
      }
      if (key.toLowerCase().indexOf("curl") >= 0) {
        if (showcurl) {
          curlbody.push(
            <StyledTableRow>
              <TableCell>
                <Typography className={classes.bold_text}>{key}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.bold_text}>{result}</Typography>
              </TableCell>
            </StyledTableRow>
          );
        }
        if (result.indexOf("left intact") >= 0) {
          curlsuccess = true;
        }
      }
    }
  }
  if (videotestresult != undefined) {
    for (let key in videotestresult) {
      let result = videotestresult[key];
      videobody.push(
        <StyledTableRow>
          <TableCell>
            <Typography className={classes.bold_text}>{key}</Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>{result}</Typography>
          </TableCell>
        </StyledTableRow>
      );
    }
  }

  if (videotestresult) {
    testbody.push(<>{videobody}</>);
  } else {
    testbody.push(
      <>
        <StyledTableRow>
          <TableCell>
            <Typography className={classes.bold_text}>
              {"PING COMMAND"}
            </Typography>
          </TableCell>
          <TableCell>
            {pingsuccess ? (
              <CheckIcon style={{ fill: "#3f54a5" }} />
            ) : (
              <CloseIcon style={{ fill: "#ee4623" }} />
            )}
          </TableCell>
          <TableCell>
            {showping ? (
              <ExpandMoreIcon onClick={() => clickPingOpen()} />
            ) : (
              <ExpandLessIcon onClick={() => clickPingOpen()} />
            )}
          </TableCell>
        </StyledTableRow>
        {pingbody}
        <StyledTableRow>
          <TableCell>
            <Typography className={classes.bold_text}>
              {"TELNET COMMAND"}
            </Typography>
          </TableCell>
          <TableCell>
            {telnetsuccess ? (
              <CheckIcon style={{ fill: "#3f54a5" }} />
            ) : (
              <CloseIcon style={{ fill: "#ee4623" }} />
            )}
          </TableCell>
          <TableCell>
            {showtelnet ? (
              <ExpandMoreIcon onClick={() => clickTelnetOpen()} />
            ) : (
              <ExpandLessIcon onClick={() => clickTelnetOpen()} />
            )}
          </TableCell>
        </StyledTableRow>
        {telnetbody}
        <StyledTableRow>
          <TableCell>
            <Typography className={classes.bold_text}>
              {"CURL COMMAND"}
            </Typography>
          </TableCell>
          <TableCell>
            {curlsuccess ? (
              <CheckIcon style={{ fill: "#3f54a5" }} />
            ) : (
              <CloseIcon style={{ fill: "#ee4623" }} />
            )}
          </TableCell>
          <TableCell>
            {showcurl ? (
              <ExpandMoreIcon onClick={() => clickCurlOpen()} />
            ) : (
              <ExpandLessIcon onClick={() => clickCurlOpen()} />
            )}
          </TableCell>
        </StyledTableRow>
        {curlbody}
      </>
    );
  }
  var testbuttons: React.ReactNode[] = [];
  if (appStore!.authService.getRoles().indexOf("sys_admin") >= 0) {
    testbuttons.push(
      <>
        <Button
          className={classes.cancel_button_top_margin}
          variant="contained"
          onClick={handleTest}
          disabled={hub_id === 0}
        >
          <span className={classes.bold_text}>TEST CAMERA STREAM</span>
        </Button>
        <Button
          className={classes.cancel_button_top_margin}
          variant="contained"
          onClick={handleAdvancedTest}
          disabled={hub_id === 0}
        >
          <span className={classes.bold_text}>TEST CAMERA NETWORK</span>
        </Button>
      </>
    );
  }
  var searchbutton: React.ReactNode[] = [];
  if (appStore!.authService.getRoles().indexOf("sys_admin") >= 0) {
    searchbutton.push(
      <div className={classes.button_container}>
        <Tooltip title={"Search hub for possible cameras"} placement="bottom">
          <Button
            className={classes.bold_text}
            variant="contained"
            onClick={scanHub}
            disabled={hub_id === 0}
          >
            Search hub for cameras
          </Button>
        </Tooltip>
      </div>
    );
  }
  var body: React.ReactNode[] = [];
  if (loading) {
    body.push(
      <div className="wait-container">
        <svg width="48px" height="48px" viewBox="0 0 128 128">
          <g>
            <linearGradient id="linear-gradient">
              <stop offset="0%" stop-color="#ffffff" />
              <stop offset="100%" stop-color="#22305f" />
            </linearGradient>
            <path
              d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
              fill="url(#linear-gradient)"
              fill-rule="evenodd"
            />
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 64 64"
              to="360 64 64"
              dur="1080ms"
              repeatCount="indefinite"
            ></animateTransform>
          </g>
        </svg>
      </div>
    );
  } else {
    body.push(
      <div className="main-drawer-container">
        <FormControl fullWidth variant="outlined">
          <InputLabel className={classes.bold_text}>
            Hub for this sensor
          </InputLabel>
          <Select
            name="hub-select"
            value={hub_id}
            onChange={(e) => handleHubSelection(e)}
            placeholder="No hub selected"
          >
            {hublist}
          </Select>
        </FormControl>
        {searchbutton}
        {cameratable}

        <ToggleButtonGroup
          className={classes.form_component}
          exclusive
          value={showrtspurl}
          onChange={handleShowRtspUrl}
        >
          <ToggleButton value={false}>RTSP Details</ToggleButton>
          <ToggleButton value={true}>RTSP URL</ToggleButton>
        </ToggleButtonGroup>
        {rtspdetails}
        <div className={classes.button_container}>
          {testbuttons}
          <Button
            className={classes.cancel_button_top_margin}
            variant="contained"
            onClick={handleClose}
          >
            <span className={classes.bold_text}>CANCEL</span>
          </Button>
          <Button
            className={classes.cancel_button_top_margin}
            variant="contained"
            onClick={saveCamera}
            disabled={hub_id === 0}
          >
            <span className={classes.bold_text}>SAVE</span>
          </Button>
        </div>
      </div>
    );
  }
  if (testresult !== undefined || videotestresult !== undefined) {
    return (
      <Dialog
        onClose={onClose}
        open={open}
        className={classes.modal_dialog_fixed}
      >
        <DialogTitle className={classes.bold_text}>
          Camera Test Result
        </DialogTitle>
        <Typography className={classes.bold_error_text}>{message}</Typography>
        <TableContainer component={Paper}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Response</TableCell>
                <TableCell>Value</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{testbody}</TableBody>
          </Table>
        </TableContainer>
        <div className={classes.button_container}>
          <Button
            className={classes.cancel_button_top_margin}
            variant="contained"
            onClick={handleDoneTest}
            disabled={hub_id === 0}
          >
            <span className={classes.bold_text}>DONE</span>
          </Button>
        </div>
      </Dialog>
    );
  } else {
    return (
      <Dialog
        onClose={onClose}
        open={open}
        className={classes.modal_dialog_fixed}
      >
        <DialogTitle className={classes.bold_text}>{title}</DialogTitle>
        <Typography className={classes.bold_error_text}>{message}</Typography>
        <div className={classes.space_smallest}></div>
        {body}
      </Dialog>
    );
  }
};
export default CameraWizard;
