import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "../../styling/global/global";
interface TimeEntryPropsNoSecond {
  value: string;
  label: string;
  handleTimeChange: (x: string) => void;
  id: string;
  disabled: boolean;
}

const TimePickerNoSeconds: React.FC<TimeEntryPropsNoSecond> = ({
  value,
  handleTimeChange,
  label,
  id,
  disabled,
}: TimeEntryPropsNoSecond) => {
  const classes = useStyles();
  const [refresh, setRefresh] = React.useState(0);
  const [time, setTime] = React.useState(value);
  const [message, setMessage] = React.useState(" ");

  const onHourChange = (event: React.ChangeEvent<any>) => {
    const parts = time.split(":");
    var hour = Number(event.target.value);
    if (id == "start-time") {
      if (hour == 24) {
        hour = 23;
      }
    }
    if (hour > 24) {
      hour = 24;
    }
    if (hour < 0) {
      hour = 0;
    }
    if (hour == 24) {
      parts[1] = "00";
      parts[2] = "00";
    }
    if (id == "start-time") {
      if (hour == 24) {
        hour = 23;
      }
    }
    const newTime = add0(String(hour)) + ":" + parts[1];
    setTime(newTime);
    handleTimeChange(newTime);
  };
  const onMinuteChange = (event: React.ChangeEvent<any>) => {
    const parts = time.split(":");
    var minute = Number(event.target.value);
    if (minute > 60) {
      minute = 60;
    }
    if (minute < 0) {
      minute = 0;
    }
    if (Number(parts[0]) == 24) {
      minute = 0;
    }
    const newTime = parts[0] + ":" + add0(String(minute));

    setTime(newTime);
    handleTimeChange(newTime);
  };

  const add0 = (x: string) => {
    if (x.length == 3) {
      x = x.substr(1, 2);
    }
    if (x.length == 1) {
      x = "0" + x;
    }
    if (x.length == 0) {
      x = "00";
    }
    return x;
  };
  const timeparts = time.split(":");
  return (
    <div className="time-picker">
      <Typography variant="h6" color="error">
        {message}
      </Typography>
      <div className={classes.bold_text}>{label}</div>
      <Grid item xs={10}>
        <Grid container justify="center" spacing={2}>
          <Grid item>
            <div className="time-entry">
              <TextField
                disabled={disabled}
                type="number"
                id="hours"
                value={timeparts[0]}
                onChange={onHourChange}
              />
            </div>
          </Grid>
          <div className="time-divider">:</div>
          <Grid item>
            <div className="time-entry">
              <TextField
                disabled={disabled}
                type="number"
                className="time-entry"
                id="minutes"
                value={timeparts[1]}
                onChange={onMinuteChange}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default TimePickerNoSeconds;
