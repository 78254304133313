import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "../../styling/global/global";

interface SMTPChangePasswordProps {
  // elements:React.ReactNode[]|undefined;
  open: boolean;
  onClose: () => void;
  openNextModal: () => void;
}
const ChangePasswordCheck: React.FC<SMTPChangePasswordProps> = ({
  openNextModal,
  open,
  onClose,
}: SMTPChangePasswordProps) => {
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };
  const handleOk = () => {
    onClose();
    openNextModal();
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className="edit-dialog"
    >
      <DialogTitle id="simple-dialog-title">
        ARE YOU SURE YOU WANT TO CHANGE THE SMTP PASSWORD?
      </DialogTitle>
      {/* <Typography className={classes.bold_text}>ARE YOU SURE YOU WANT TO CHANGE THE PASSWORD?</Typography> */}

      <div className={classes.twoButtonsInARow}>
        <Button variant="contained" className="save" onClick={handleClose}>
          Dismiss
        </Button>
        <Button variant="contained" className="save" onClick={handleOk}>
          OK
        </Button>
      </div>
    </Dialog>
  );
};

export default ChangePasswordCheck;
