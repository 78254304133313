import React, { useState, useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { IconButton } from "@material-ui/core";
import { AppStore } from "../../../stores/AppStore";
import { ReportGroup, Site, Org } from "../../../services/FunctionService";
import { Typography } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { useStyles } from "../../styling/global/global";
import {
  TimeEntrySetTimeOnly,
  TimeEntryTimeOnly,
} from "../../../core/models/TimeEntryTimeOnly";
import TimeEntryPanelTimeOnly from "../../components/TimeEntryUI/TimeEntryPanelTimeOnly";
interface AddSiteReportProps {
  site: Site;
  appStore?: AppStore;
  open: boolean;
  onClose: () => void;
  currentOrg: Org;
  saveNewSiteReport: (
    site_id: number,
    report_group_id: number,
    report_type: string,
    daily_utc_schedule: string
  ) => void;
  message: string;
  reportgroups: ReportGroup[];
}

const AddSiteReportModal: React.FC<AddSiteReportProps> = ({
  site,
  appStore,
  open,
  currentOrg,
  onClose,
  saveNewSiteReport,
  message,
  reportgroups,
}: AddSiteReportProps) => {
  const classes = useStyles();
  const [report_group_id, setReportGroupId] = React.useState<number>(0);
  const [report_type, setReportType] = React.useState<string>("");
  const [timeentrySet, setTimeEntrySet] = React.useState<
    TimeEntrySetTimeOnly | undefined
  >(undefined);
  const [refresh, setRefresh] = React.useState(0);
  const handleClose = () => {
    onClose();
  };
  const handleSave = () => {
    onClose();
    var daily_utc_schedule = "";
    timeentrySet?.entries.forEach((entry) => {
      daily_utc_schedule =
        daily_utc_schedule + entry.time.substring(0, 5) + ",";
    });
    daily_utc_schedule = daily_utc_schedule.substring(
      0,
      daily_utc_schedule.length - 1
    );
    saveNewSiteReport(
      site.site_id,
      report_group_id,
      report_type,
      daily_utc_schedule
    );
  };
  const handleReportTypeChange = (event: React.ChangeEvent<any>) => {
    setReportType(event.target.value);
  };
  const handleReportGroupChange = (event: React.ChangeEvent<any>) => {
    setReportGroupId(event.target.value);
  };
  var reporttypes: React.ReactNode[] = [];
  reporttypes.push(
    <MenuItem value={"site_activity"}>
      <span className={classes.bold_text}>
        {appStore?.functionService.translate(
          "en_ZA",
          "report_types",
          "site_activity"
        )}
      </span>
    </MenuItem>
  );
  reporttypes.push(
    <MenuItem value={"site_health:schedule"}>
      <span className={classes.bold_text}>
        {appStore?.functionService.translate(
          "en_ZA",
          "report_types",
          "site_health:schedule"
        )}
      </span>
    </MenuItem>
  );
  // reporttypes.push(
  //   <MenuItem value={'site_health:trigger'}>
  //     <span className={classes.bold_text}>
  //       {appStore?.functionService.translate(
  //         'en_ZA',
  //         'report_types',
  //         'site_health:trigger'
  //       )}
  //     </span>
  //   </MenuItem>
  // );
  reporttypes.push(
    <MenuItem value={"site_health:connection"}>
      <span className={classes.bold_text}>
        {appStore?.functionService.translate(
          "en_ZA",
          "report_types",
          "site_health:connection"
        )}
      </span>
    </MenuItem>
  );

  var reportdrawer: React.ReactNode[] = [];
  if (reportgroups && reportgroups.length > 0) {
    reportgroups.forEach((report) => {
      reportdrawer.push(
        <MenuItem value={report.id}>
          <span className={classes.bold_text}>
            {report.name} (id={report.id})
          </span>
        </MenuItem>
      );
    });
  }
  const changeTimeEntry = (entrySet: TimeEntrySetTimeOnly | undefined) => {
    setTimeEntrySet(entrySet);
    //if deleted las one, make sure to add add button
    if (entrySet?.entries.length == 0) {
      setRefresh(new Date().getTime());
    }
  };
  const addSchedule = () => {
    if (timeentrySet) {
      timeentrySet.addTimeEntry();
      setTimeEntrySet(timeentrySet);
    } else {
      const newtimeentrySet = new TimeEntrySetTimeOnly([""]);
      newtimeentrySet.addTimeEntry();
      setTimeEntrySet(newtimeentrySet);
    }
    setRefresh(new Date().getTime());
  };
  var schedule: React.ReactNode[] = [];
  if (report_type && report_type == "site_health:schedule") {
    if (timeentrySet && timeentrySet.entries.length > 0) {
      schedule.push(
        <TimeEntryPanelTimeOnly
          addicon={true}
          disabled={false}
          entrySet={timeentrySet}
          changeTimeEntryParent={(entrySet: TimeEntrySetTimeOnly | undefined) =>
            changeTimeEntry(entrySet)
          }
        ></TimeEntryPanelTimeOnly>
      );
    } else {
      schedule.push(
        <div className={classes.form_component}>
          <div className={classes.bold_text}>
            Add Schedule
            <IconButton
              edge="end"
              aria-label="add"
              onClick={() => {
                addSchedule();
              }}
            >
              <AddCircle color={"primary"} />
            </IconButton>
          </div>
        </div>
      );
    }
  }
  if (site) {
    if (appStore!.authService.getRoles().indexOf("site_admin") >= 0) {
      if (reportgroups) {
        return (
          <Dialog
            onClose={handleClose}
            className={classes.modal_dialog}
            open={open}
          >
            <DialogTitle>
              <span className={classes.bold_text}>
                Add New Report to {site.name}
              </span>
            </DialogTitle>
            <Typography className={classes.bold_text} color="error">
              {message}
            </Typography>
            <FormControl variant="outlined" className={classes.form_component}>
              <InputLabel className={classes.bold_text}>
                Report Group
              </InputLabel>
              <Select
                value={report_group_id}
                onChange={handleReportGroupChange}
              >
                {reportdrawer}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.form_component}>
              <InputLabel className={classes.bold_text}>Report Type</InputLabel>
              <Select value={report_type} onChange={handleReportTypeChange}>
                {reporttypes}
              </Select>
            </FormControl>
            <FormControl className={classes.form_component}>
              {schedule}
            </FormControl>
            <div className={classes.button_container}>
              <Button
                variant="contained"
                className={classes.cancel_button}
                onClick={handleClose}
              >
                <span className={classes.bold_text}>CANCEL</span>
              </Button>

              <Button
                variant="contained"
                className={classes.save_button}
                onClick={handleSave}
              >
                <span className={classes.bold_text}>SAVE</span>
              </Button>
            </div>
          </Dialog>
        );
      } else {
        return (
          <Dialog
            onClose={handleClose}
            className={classes.modal_dialog}
            open={open}
          >
            <DialogTitle>
              <span className={classes.bold_text}>
                {"Please add Report Groups to Group " +
                  currentOrg.org_name +
                  " before adding site reports!"}
              </span>
            </DialogTitle>

            <div className={classes.button_container}>
              <Button
                variant="contained"
                className={classes.cancel_button}
                onClick={handleClose}
              >
                <span className={classes.bold_text}>OK</span>
              </Button>
            </div>
          </Dialog>
        );
      }
    } else {
      return (
        <Dialog onClose={onClose} open={open} className={classes.modal_dialog}>
          <DialogTitle className={classes.bold_text}>
            Add New Report to {site.name}
          </DialogTitle>
          <br></br>
          <Typography className={classes.bold_text}>
            {
              "You need site admin role to be able to add a new Site Report - please contact your DeepAlert system administrator"
            }
          </Typography>
          <div className={classes.button_container}>
            <Button
              className={classes.cancel_button}
              variant="contained"
              onClick={() => onClose()}
            >
              <span className={classes.bold_text}>CANCEL</span>
            </Button>
          </div>
        </Dialog>
      );
    }
  } else {
    return <></>;
  }
};

export default AddSiteReportModal;
