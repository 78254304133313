import React, { useState, useEffect } from "react";
import { AppStore } from "../../stores/AppStore";
import { Sensor } from "../../services/FunctionService";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { getBaseUrl } from "../HelperFunctions";

const useStyle = makeStyles({
  paper: {
    border: "2px solid #6D809D",
    color: "#15325F",
  },
});

interface Props {
  appStore: AppStore;
  setLoading: (b: boolean) => void;
  setSiteId: (id: number) => void;
  setSensorId: (id: number) => void;
  sensorId: number;
  siteids: number[];
  loading: boolean;
  selectAllSites: boolean;
  title?: string;
}

const ITEM_HEIGHT = 88;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minHeight: 0,
      maxWidth: 500,
      minWidth: 0,
      color: "#15325F",
    },
  },
};

const Whitelist: React.FC<Props> = ({
  appStore,
  setSiteId,
  setLoading,
  sensorId,
  siteids,
  setSensorId,
  loading,
  selectAllSites,
  title,
}) => {
  const [whitelist, setWhitelist] = React.useState<any[]>([]);
  const [sensor, setSensor] = React.useState<Sensor>();
  //   const [sensorname, setName] = useState("Please Select Camera");

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    let id = +event.target.value;
    setSensorId(id as number);
  };

  var baseUrl = getBaseUrl();

  // Fetch Whitelist
  useEffect(() => {
    const fetchData = async () => {
      if (selectAllSites) {
        return setWhitelist([]);
      }
      setLoading(true);
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && siteids.length > 0) {
        try {
          const res = await fetch(
            // `http://localhost:3004/list_whitelist?site_id=${siteids[0]}`,
            baseUrl + `/listwhitelist`,
            {
              method: "POST",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ site_id: siteids[0] }),
            }
          );
          const result = await (await res).json();
          if (result.success) {
            console.log("result list_whitelist", result);
            const whitelistData = [
              ...result.msg.known_vehicle_list.map((item: any) => {
                return {
                  name: item.name,
                  site_id: siteids[0],
                  id: item.known_vehicle_list_id,
                };
              }),
            ];
            setWhitelist(whitelistData);
          }

          //   setName("Please Select Camera");
        } catch (err) {
          return err;
        } finally {
          setLoading(false);
          setSensor(undefined);
        }
      } else {
        console.log("Condition Not Met!!!");
        setWhitelist([]);
        // setName("Please Select Camera");
      }
    };
    fetchData();
  }, [siteids]);

  return (
    <FormControl
      variant="outlined"
      style={{
        width: 360,
        color: "#6D809D",
        marginTop: 0,
        marginLeft: 3,
        backgroundColor: "white",
      }}
    >
      <InputLabel
        id="demo-simple-select-outlined-label"
        style={{
          color: "#15325F",
          fontSize: 12,
          fontWeight: "bold",
          padding: 0,
        }}
      >
        {title ? title : "Select Whitelist"}
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={sensor}
        onChange={handleChange}
        label="Age"
        MenuProps={MenuProps}
        // defaultValue={whitelist.length > 0 ? whitelist[0] : null}
      >
        {/* <MenuItem value={-1}>
          <span style={{ width: 260 }}> All Whitelist</span>
        </MenuItem> */}
        {whitelist.map((whitelist: any) => (
          <MenuItem style={{ color: "#6D809D" }} value={whitelist.id}>
            {whitelist.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Whitelist;
