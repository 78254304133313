import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AppStore } from "../../../../stores/AppStore";
import { useStyles } from "../../../styling/global/global";
import {
  billingProductOrgsItems,
  listBillingRatesItems,
} from "../../../../services/FunctionService";
import { Typography } from "@material-ui/core";

interface AlertConfirmationProps {
  open: boolean;
  appStore?: AppStore;
  onClose: () => void;
  product: listBillingRatesItems | undefined;
  billingProductOrg: billingProductOrgsItems | undefined;
  deleteProductOrgFunc: (org_id: number, billing_rate_id: number) => void;
}

const DeleteProductConfirmation: React.FC<AlertConfirmationProps> = ({
  appStore,
  open,
  onClose,
  product,
  billingProductOrg,
  deleteProductOrgFunc,
}: AlertConfirmationProps) => {
  const [mockState, setMockState] = useState(0);

  const classes = useStyles();

  useEffect(() => {}, [mockState]);

  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
        fullWidth
      >
        <DialogTitle
          style={{ alignSelf: "center" }}
          className={classes.bold_text}
          id="simple-dialog-title"
        >
          <Typography
            className={classes.bold_text}
            style={{ color: "#3f51b5" }}
          >
            Are you sure you want to unassign{" "}
            <span
              style={{ color: "blue", fontSize: 14 }}
              className={classes.bold_text}
            >
              {billingProductOrg ? billingProductOrg.name : ""}
            </span>{" "}
            from{" "}
            <span
              style={{ color: "blue", fontSize: 14 }}
              className={classes.bold_text}
            >
              {product ? product.product : ""}?{" "}
            </span>
          </Typography>
        </DialogTitle>
        <div
          className={classes.twoButtonsInARow}
          style={{ marginBottom: "5%" }}
        >
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => {
              setMockState((value) => value + 1);
              onClose();
            }}
          >
            <span className={classes.bold_text}>Dismiss</span>
          </Button>
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => {
              deleteProductOrgFunc(billingProductOrg!.id, product!.id);
              onClose();
              setMockState((value) => value + 1);
            }}
          >
            <span className={classes.bold_text}>Ok</span>
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default DeleteProductConfirmation;
