import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { AppStore } from "../../../stores/AppStore";
import { useStyles } from "../../styling/global/global";
import { Site } from "../../../services/FunctionService";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import CustomModal from "../../UI/CustomModal";
import Button from "@material-ui/core/Button";
import { MainContext } from "../../containers/HomePage/HomePage";

import OrgtreeAutocomplete from "../../UI/OrgtreeAutocomplete";
import MultiSiteSelect from "../../UI/MultiSiteSelect";
import Whitelist from "../../UI/Whitelist";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { AlprOptions } from "../../UI/RadioBtn";
import { getBaseUrl } from "../../HelperFunctions";
import Spinner from "../../../spinner/Spinner";
import ListAlprEvents from "../../UI/ListAlprEvents";
import AlprViewerMulti from "../../UI/AlprViewerMulti";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Tooltip, makeStyles } from "@material-ui/core";

import CustomButton from "../../UI/CustomButton";
import TimeRange from "../../UI/DashboardUI/TimeRange";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { message as msg } from "antd";

const useStyle = makeStyles({
  paper: {
    border: "2px solid #6D809D",
    color: "#15325F",
  },
});

interface Props {
  appStore: AppStore;
  setSelectedItem: (item: any, editing: boolean) => void;
}

interface review_logs {
  created: number;
  log_username: string;
  review_status: string;
  content: string;
}

interface EventType {
  doc_id: string;
  track_id: string;
  elastic_index: string;
  event_type: string;
  data_origin: string;
  site_id: number;
  site_name: string;
  sensor_id_str: string;
  sensor_human_name: string;
  media_url: string;
  image_height: number;
  image_width: number;
  created: number;
  classifier: string;
  probabilities: number[] | string[];
  class_labels: string[];
  message: string;
  improve_accuracy_flag: boolean;
  improve_accuracy_username: string | null;
  improve_accuracy_timestamp: string | null;
  suppress_flag: boolean;
  record_type: string;
  review_logs?: review_logs[] | [];
  reason_for_flag: string;
  alpr?: any;
}

const time_date = [
  "none",
  "Last 1 hour",
  "Last 3 hours",
  "Last 6 hours",
  "Last 12 hours",
  "Last 24 hours",
  "Last 2 days",
  "Last 7 days",
  "Last 2 weeks",
  "Last 30 days",
];

const alpr_whitelist = [
  "Zone A Whitelist",
  "Zone B Whitelist",
  "Parking Area",
  "Create New",
];

const initialState = {
  doc_id: "no data",
  track_id: "no data",
  elastic_index: "no data",
  event_type: "no data",
  data_origin: "no data",
  site_id: 0,
  site_name: "no data",
  sensor_id_str: "no data",
  sensor_human_name: "no data",
  media_url: "no data",
  image_height: 0,
  image_width: 0,
  created: 0,
  classifier: "no data",
  probabilities: [],
  class_labels: [],
  message: "no data",
  improve_accuracy_flag: false,
  improve_accuracy_username: "no data",
  improve_accuracy_timestamp: "no data",
  suppress_flag: false,
  record_type: "no data",
  reason_for_flag: "",
};

const initialState1 = {
  doc_id: "no data",
  track_id: "no data",
  elastic_index: "no data",
  event_type: "no data",
  data_origin: "no data",
  site_id: 0,
  site_name: "no data",
  sensor_id_str: "no data",
  sensor_human_name: "no data",
  media_url: "no data",
  image_height: 0,
  image_width: 0,
  created: 1,
  classifier: "no data",
  probabilities: [],
  class_labels: [],
  message: "no data",
  improve_accuracy_flag: false,
  improve_accuracy_username: "no data",
  improve_accuracy_timestamp: "no data",
  suppress_flag: false,
  record_type: "no data",
  reason_for_flag: "",
};

const AlprPage: React.FC<Props> = ({ appStore, setSelectedItem }) => {
  const { setShowGraph, showGraph } = useContext(MainContext);
  const [autoplay, setAutoplay] = React.useState(false);
  const [selectedAlert, setSelectedAlert] = useState<EventType>(initialState);
  const [selectedAlertData, setSelectedAlertData] = useState<
    { event_data: EventType; inference_data: EventType } | any
  >(null);
  const [alertList, setAlertList] = useState<EventType[]>([]);
  const [eventCount, setEventCount] = useState(0);
  const [alertEventsList, setAlertEventsList] = useState<
    { event_data: EventType; inference_data: EventType }[] | []
  >([]);
  const [groupid, setGroupId] = useState(-1);
  const classes = useStyles();
  const [siteid, setSiteId] = useState<number[]>([]);
  const [sensorId, setSensorId] = useState(-1);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingViewer, setLoadingViewer] = React.useState<boolean>(false);
  const [silenceAlert, setSilenceAlert] = React.useState<boolean>(false);
  const [loadingSite, setLoadingSite] = React.useState<boolean>(false);
  const [errMessage, setErrMessage] = React.useState<string>("");
  const [flagged_ids, setFlaggedIds] = React.useState<string[]>([]);
  const [silenced_ids, setSilencedIds] = React.useState<string[]>([]);
  const [stored_ids, setStoredIds] = React.useState<string[]>([]);
  const [alertType, setAlertType] = React.useState<string>("alerted");
  const [message, setMessage] = React.useState<string>("");
  const [whitelistName, setWhitelistName] = React.useState<string>("");
  const [siteList, setSiteList] = React.useState<Site[]>([]);
  const [openToast, setOpenToast] = React.useState(false);
  const [err, setErr] = React.useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [reloadImgs, setReloadImgs] = useState(false);
  const [loadImgs, setLoadImgs] = useState(false);
  const [event_timeout, setEventTimeout] = useState(false);
  const [openFlagModal, setOpenFlagModal] = useState(false);
  const [openSilenceModal, setOpenSilenceModal] = useState(false);
  const [newWhitelistName, setNewWhitelistName] = React.useState<string>("");
  const [createNewWhitelist, setCreateNewWhitelist] =
    React.useState<boolean>(false);

  const [selectAllSites, setSelectAllSites] = React.useState(false);
  const [showOther, setShowOther] = React.useState(false);
  const [dateTitle, setDateTitle] = React.useState<string>("Last 24 hours");
  const [class_filter, setClassFilter] = React.useState<string>("all");
  const [object_detections, setObjectDetections] =
    React.useState<any>(undefined);
  const [newStartTime, setNewStartTime] = useState<number>(
    new Date(new Date().getTime() - 1000 * 60 * 60 * 24).getTime() / 1000
  );
  const [newStartTimeFilter, setNewStartTimeFilter] = useState<number>(
    // new Date(new Date().getTime() - 1000 * 60 * 60 * 24).getTime() / 1000
    0
  );

  const [savedTime, setSavedTime] = useState({ startT: 0, endT: 0 });

  const [inferenceIdsMap, setInferenceIdsMap] = useState<any[]>([]);

  const [newEndTimeFilterAl, setNewEndTimeFilterAl] = useState<number>(0);
  const [newEndTimeFilterInf, setNewEndTimeFilterInf] = useState<number>(0);

  const [newEndTime, setNewEndTime] = useState<number | null>(
    new Date().getTime() / 1000
  );
  const [openTimeRange, setOpenTimeRange] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);

  const observer = useRef<HTMLDivElement | any>();

  // ALL INFORMATION REGARDING THE NEXT PREVIOUS BUTTON COMES HERE ///////////////////////////////////////////////////////////////////
  const [number, setNumber] = useState(0);
  const [disablePrev, setDisablePrev] = useState("disabled");
  const [disableNext, setDisableNext] = useState("able");
  const [finalNextorPrev, setFinalNextorPrev] = useState<any>({});
  const [sendPrevORNext, setSendPrevORNext] = useState<any>("");
  // const [getActiveItem, setActiveItem] = useState<any>(selectedAlert?.doc_id);
  const [getActiveItem, setActiveItem] = useState<any>(alertList[0]?.doc_id);
  //GET NEXT ITEM AND PREVIOUS!
  const [getNextOFActiveItem, setNextOfActiveItem] = useState<any>({});
  const [getPreviousOFActiveItem, setPreviousOfActiveItem] = useState<any>({});

  const [whitelist, setWhitelist] = useState<any[]>([
    {
      name: "Create New",
      site_id: null,
      id: null,
    },
  ]);
  const [whitelistId, setWhitelistId] = useState<any>(null);

  const [editPlate, setEditPlate] = useState("");

  const styler = useStyle();
  var baseUrl = getBaseUrl();

  const fetchWhitelistData = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();
    if (token && siteid.length > 0) {
      try {
        const res = await fetch(baseUrl + `/listwhitelist`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ site_id: siteid[0] }),
        });
        const result = await (await res).json();
        if (result.success) {
          setWhitelist([
            ...result.msg.known_vehicle_list.map((item: any) => {
              return {
                name: item.name,
                site_id: siteid[0],
                id: item.known_vehicle_list_id,
              };
            }),
            { name: "Create New", site_id: null, id: null },
          ]);
        }
      } catch (err) {
        return err;
      } finally {
        setLoading(false);
      }
    } else {
      setWhitelist([{ name: "Create New", site_id: null, id: null }]);
    }
  };

  // Fetch sensors
  useEffect(() => {
    fetchWhitelistData();
  }, [siteid]);

  useEffect(() => {
    setActiveItem(alertList[0]?.doc_id);
    setDisableNext("able");
    setDisablePrev("disabled");
  }, [alertList, alertEventsList]);

  const deleteHandler = (id: any) => {
    console.log("deleteHandler -> ", whitelistId);
  };

  const [prevNextData, setPrevNextData] = useState<any>({});

  useEffect(() => {
    const indexOFActive = alertList.findIndex(
      (alert) => alert.doc_id === getActiveItem
    );
    if (indexOFActive === alertList.length - 1 && alertList.length !== 0) {
      setDisableNext("disabled");
    }
    if (indexOFActive === 0) {
      setDisablePrev("disabled");
    }
    if (sendPrevORNext === "Send_Prev") {
      const index = alertEventsList.findIndex(
        (event: any) =>
          event.event_data.doc_id === getPreviousOFActiveItem.doc_id
      );
      setLoadingViewer(true);
      setTimeout(() => {
        setLoadingViewer(false);
      }, 400);
      setSelectedAlertData({
        event_data: {
          advanced_rule_name: getPreviousOFActiveItem?.rule_name,
          advanced_rule_type: getPreviousOFActiveItem?.rule_type,
          class_labels: getPreviousOFActiveItem?.class_labels,
          classifier: getPreviousOFActiveItem?.classifier,
          created: getPreviousOFActiveItem?.created,
          data_origin: getPreviousOFActiveItem?.data_origin,
          doc_id: getPreviousOFActiveItem?.doc_id,
          elastic_index: getPreviousOFActiveItem?.elastic_index,
          event_type: getPreviousOFActiveItem?.event_type,
          image_height: getPreviousOFActiveItem?.image_height,
          image_width: getPreviousOFActiveItem?.image_width,
          improve_accuracy_flag: getPreviousOFActiveItem?.improve_accuracy_flag,
          improve_accuracy_timestamp:
            getPreviousOFActiveItem?.improve_accuracy_timestamp,
          improve_accuracy_username:
            getPreviousOFActiveItem?.improve_accuracy_username,
          media_url: getPreviousOFActiveItem?.media_url,
          message: getPreviousOFActiveItem?.message,
          probabilities: getPreviousOFActiveItem?.probabilities,
          reason_for_flag: getPreviousOFActiveItem?.reason_for_flag,
          record_type: getPreviousOFActiveItem?.record_type,
          review_logs: getPreviousOFActiveItem?.review_logs,
          sensor_human_name: getPreviousOFActiveItem?.sensor_human_name,
          sensor_id_str: getPreviousOFActiveItem?.sensor_id_str,
          site_id: getPreviousOFActiveItem?.site_id,
          site_name: getPreviousOFActiveItem?.site_name,
          suppress_flag: getPreviousOFActiveItem?.suppress_flag,
          track_id: getPreviousOFActiveItem?.track_id,
        },
        inference_data: alertEventsList[index]?.inference_data,
      });
    } else if (sendPrevORNext === "Send_Next") {
      const index = alertEventsList.findIndex(
        (event: any) => event.event_data.doc_id === getNextOFActiveItem.doc_id
      );

      setLoadingViewer(true);
      setTimeout(() => {
        setLoadingViewer(false);
      }, 400);

      setSelectedAlertData({
        event_data: {
          advanced_rule_name: getNextOFActiveItem?.rule_name,
          advanced_rule_type: getNextOFActiveItem?.rule_type,
          class_labels: getNextOFActiveItem?.class_labels,
          classifier: getNextOFActiveItem?.classifier,
          created: getNextOFActiveItem?.created,
          data_origin: getNextOFActiveItem?.data_origin,
          doc_id: getNextOFActiveItem?.doc_id,
          elastic_index: getNextOFActiveItem?.elastic_index,
          event_type: getNextOFActiveItem?.event_type,
          image_height: getNextOFActiveItem?.image_height,
          image_width: getNextOFActiveItem?.image_width,
          improve_accuracy_flag: getNextOFActiveItem?.improve_accuracy_flag,
          improve_accuracy_timestamp:
            getNextOFActiveItem?.improve_accuracy_timestamp,
          improve_accuracy_username:
            getNextOFActiveItem?.improve_accuracy_username,
          media_url: getNextOFActiveItem?.media_url,
          message: getNextOFActiveItem?.message,
          probabilities: getNextOFActiveItem?.probabilities,
          reason_for_flag: getNextOFActiveItem?.reason_for_flag,
          record_type: getNextOFActiveItem?.record_type,
          review_logs: getNextOFActiveItem?.review_logs,
          sensor_human_name: getNextOFActiveItem?.sensor_human_name,
          sensor_id_str: getNextOFActiveItem?.sensor_id_str,
          site_id: getNextOFActiveItem?.site_id,
          site_name: getNextOFActiveItem?.site_name,
          suppress_flag: getNextOFActiveItem?.suppress_flag,
          track_id: getNextOFActiveItem.track_id,
        },
        inference_data: alertEventsList[index]?.inference_data,
      });
    } else if (sendPrevORNext === "") {
      setPrevNextData({});
    }
  }, [sendPrevORNext, getNextOFActiveItem, getPreviousOFActiveItem, number]);

  // ALL STATES FOR THE BUTTONS END HERE!
  // ALL INFORMATION REGARDING NEXT & PREVIOUS BUTTONS ENDS

  const lastImage = useCallback(
    async (node: any) => {
      if (loading) return;
      if (observer.current) observer!.current!.disconnect();
      observer!.current! = new IntersectionObserver(async (entries) => {
        if ((entries[0].isIntersecting && hasMore) || reloadImgs) {
          // setLoading(true);
          console.log("Image in view port***", groupid);
          console.log("page", page);
          console.log(
            "setting new start time with newStartTimeFilter",
            newStartTimeFilter
          );

          if (alertType === "alerted") {
            fetchAlertEvents(false, newStartTimeFilter);
          } else if (alertType === "whitelisted") {
            fetchWhitelistedInf(false, newStartTimeFilter);
          }
        }
      });
      if (node) observer!.current!.observe(node);
    },
    [
      loading,
      hasMore,
      reloadImgs,
      newStartTimeFilter,
      alertType,
      newEndTimeFilterInf,
      newEndTimeFilterAl,
    ]
  );

  const handleToastClose = () => {
    setMessage("");
    setErr(false);
    setOpenToast(false);
  };

  const applyTime = (startTime: number, endTime: number) => {
    setNewStartTime(startTime);
    // setNewStartTimeFilter(startTime);
    setNewEndTime(endTime);
    setOpenTimeRange(false);
  };

  const handleItemSelection = async (value: any) => {
    let type = "site";
    if (typeof value === "number") {
      type = "org";
    }

    if (type === "site") {
      value = siteList.find((item) => item.site_id === value.site_id);
    }
    if (value) {
      setLoading(true);
      console.log("global site value", value, "type: " + type);
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var rest_org = await appStore?.functionService.getOrgOneLevel(
          token!,
          value.org_id
        );
        if (rest_org) {
          if (rest_org.orgs == undefined) {
            rest_org.orgs = [];
          }
          await appStore?.authService.setBreadcrumbOrgs(rest_org);
          appStore?.helpers.setCurrentOrgId(value.org_id);
          let temp_site: Site = value;
          temp_site.type = "site";
          temp_site.name = value.site_name;
          setSelectedItem(temp_site, false);
        }
      }
      setLoading(false);
    }
  };

  function getLastTwoWeeksDate() {
    const now = new Date();
    return new Date(now.getTime() - 14 * 24 * 60 * 60 * 1000).getTime() / 1000;
  }

  function getLastHourDate() {
    const now = new Date();
    return new Date(now.getTime() - 24 * 60 * 60 * 1000).getTime() / 1000;
  }

  // fetch EventCounts
  const fetchEventCounts = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();

    if (token) {
      try {
        const request = JSON.stringify({
          top_org_id: siteid.join() && !selectAllSites ? null : groupid,
          site_ids:
            !selectAllSites && siteid.length > 0 && sensorId === -1
              ? `${siteid.join()},`
              : null,
          sensor_ids: sensorId > 0 ? `${sensorId},` : null,
          start_time_filter: newStartTime || getLastTwoWeeksDate(),
          end_time_filter: newEndTime,
          event_type:
            alertType === "alerted"
              ? "alerted_events"
              : alertType === "all"
              ? "all_events"
              : "filtered_out_events",
        });

        const res = await fetch(baseUrl + "/event_counts_v3", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = await (await res).json();
        if (result && result.success) {
          setEventCount(result.msg.event_count);
          setEventTimeout(result.msg.timeout);
        }
        // setLoading(false);
        return result.msg.event_count;
      } catch (err) {
        // setLoading(false);
        return err;
      } finally {
        // setLoading(false);
      }
    }
  };

  // Calculate startime filter for both alerts and inferences
  const getNewStartTime = (endTime: number, count: number, step = 1) => {
    // let end_time_filter = newEndTime!;
    let end_time_filter = endTime !== 0 ? endTime : newEndTime!;
    let start_time_filter = newStartTime || getLastHourDate();
    let event_count = count > 0 ? count : eventCount;
    let range = newEndTime! - start_time_filter;
    // let range = end_time_filter - start_time_filter;

    console.log("NB start_time: original ", newStartTime);
    if (event_count < 1500 || range < 0) {
      console.log("NB start_time: failed ", start_time_filter);
      return start_time_filter;
    }
    console.log("Time range: ", range);

    let average_time_per_event = range / event_count;

    console.log("average_time_per_event", average_time_per_event);

    let avg_time_per_five_hundred_events = 100 * average_time_per_event;

    let result = end_time_filter - avg_time_per_five_hundred_events * step;

    console.log("NB start_time: result ", result);

    if (result > start_time_filter) {
      start_time_filter = result;
    }
    console.log("NB start_time: passed ", start_time_filter);
    return start_time_filter;
  };

  // Re-order events
  const reOrderEvents = (selAlert: any) => {
    let finalEvent = { ...selAlert };
    let event_data = selAlert.event_data;
    let inf_data = selAlert.inference_data;
    console.log("b4 tranformation ->", selAlert);
    if (event_data.media_url !== inf_data[0].media_url) {
      let filteredInferences = inf_data.filter(
        (item: any) => item.media_url !== event_data.media_url
      );
      filteredInferences.unshift(event_data);
      finalEvent = { ...finalEvent, inference_data: filteredInferences };
    }
    console.log("after transformation ->", finalEvent);
    return finalEvent;
  };

  function reFormatEvents(items: any, type: string) {
    let result: any = [];

    items.map((data: any) => {
      let event_data;
      let inference_data: any = [];
      let alert_data: any = [];

      if ((type === "alert" || type === "all") && data.alerts[0]) {
        event_data = {
          doc_id: data.alerts[0].doc_id,
          track_id: data.event_data.track_id,
          elastic_index: data.alerts[0].elastic_index,
          event_type: data.alerts[0].alert_type,
          data_origin: data.event_data.data_origin,
          site_id: data.event_data.site_id,
          site_name: data.event_data.site_name,
          sensor_id_str: data.event_data.sensor_id_str,
          sensor_human_name: data.event_data.sensor_human_name,
          media_url: data.alerts[0].media_url || "",
          image_height: data.alerts[0].image_height || 480,
          image_width: data.alerts[0].image_width || 640,
          created: data.event_data.created,
          classifier: data.alerts[0].classifier,
          probabilities: data.alerts[0].probabilities,
          class_labels: ["vehicle.car"],
          // class_labels: data.alerts[0].class_labels,
          alpr: data.event_data?.alpr || undefined,
          message: data.alerts[0].message,
          improve_accuracy_flag: data.alerts[0].improve_accuracy_flag,
          improve_accuracy_username:
            data.alerts[0].improve_accuracy_username || null,
          improve_accuracy_timestamp:
            data.alerts[0].improve_accuracy_timestamp || null,
          suppress_flag: data.alerts[0].suppress_flag,
          record_type: data.alerts[0].record_type,
          review_logs: data.alerts[0].review_logs || null,
          reason_for_flag: data.alerts[0].reason_for_flag || "",
          advanced_rule_name: data.alerts[0].rule_name || "",
          advanced_rule_type: data.alerts[0].rule_type || "",
          object_detections: data.alerts[0].object_detections,
        };
        console.log("result.data 2nd check success");

        data.alerts.map((alert: any, index: number) => {
          if (index !== 0) {
            alert_data.push({
              doc_id: alert.doc_id,
              track_id: data.event_data.track_id,
              elastic_index: alert.elastic_index,
              event_type: type,
              data_origin: data.event_data.data_origin,
              site_id: data.event_data.site_id,
              alpr: data.event_data?.alpr || undefined,
              site_name: data.event_data.site_name,
              sensor_id_str: data.event_data.sensor_id_str,
              sensor_human_name: data.event_data.sensor_human_name,
              media_url: alert.media_url || "",
              image_height: alert.image_height || 480,
              image_width: alert.image_width || 640,
              created: alert.created,
              classifier: alert.classifier,
              probabilities: alert.probabilities,
              // class_labels: alert.class_labels,
              class_labels: ["vehicle.car"],
              message: alert.message,
              improve_accuracy_flag: alert.improve_accuracy_flag,
              improve_accuracy_username:
                alert.improve_accuracy_username || null,
              improve_accuracy_timestamp:
                alert.improve_accuracy_timestamp || null,
              suppress_flag: alert.suppress_flag,
              record_type: alert.record_type,
              review_logs: alert.review_logs || null,
              reason_for_flag: data.alerts[0].reason_for_flag || "",
              advanced_rule_name: alert?.rule_name || "",
              advanced_rule_type: alert?.rule_type || "",
              object_detections: alert.object_detections,
            });
          }
        });

        // console.log('result.data 3rd check success')
        data.inferences.map((inference: any) => {
          let arr: any = [];
          inference.object_detections &&
            inference.object_detections.map((item: any) => {
              if (arr.indexOf(item.inference_class) === -1) {
                arr.push(item.inference_class);
              }
            });

          inference_data.push({
            doc_id: inference.doc_id,
            track_id: data.event_data.track_id,
            elastic_index: inference.elastic_index,
            event_type: type,
            data_origin: inference.data_origin || data.event_data.data_origin,
            site_id: data.event_data.site_id,
            alpr: data.event_data?.alpr || undefined,
            site_name: data.event_data.site_name,
            sensor_id_str: data.event_data.sensor_id_str,
            sensor_human_name: data.event_data.sensor_human_name,
            media_url: inference.media_url || "",
            image_height: inference.image_height || 480,
            image_width: inference.image_height || 640,
            created: inference.created,
            classifier: inference.classifier,
            probabilities: inference.probabilities,
            class_labels: inference.class_labels,
            message: inference.message,
            improve_accuracy_flag: inference.improve_accuracy_flag,
            improve_accuracy_username:
              inference.improve_accuracy_username || null,
            improve_accuracy_timestamp:
              inference.improve_accuracy_timestamp || null,
            suppress_flag: inference.suppress_flag,
            record_type: inference.record_type,
            review_logs: inference.review_logs || null,
            object_detections: inference.object_detections,
            reason_for_flag: inference.reason_for_flag || "",
            // object_detections,
            classes: arr,
          });
        });
        result.push({
          event_data: event_data,
          inference_data: [...alert_data, ...inference_data],
        });
      } else if (
        (type === "inference" || type === "all") &&
        data.inferences[0]
      ) {
        setInferenceIdsMap((prev) => [
          ...prev,
          { [`${data.event_data.doc_id}`]: data.inferences[0].doc_id },
        ]);
        event_data = {
          doc_id: data.event_data.doc_id,
          // doc_id: data.inferences[0].doc_id,
          track_id: data.event_data.track_id,
          elastic_index: data.inferences[0].elastic_index,
          event_type: data.inferences[0].alert_type,
          data_origin: data.event_data.data_origin,
          site_id: data.event_data.site_id,
          site_name: data.event_data.site_name,
          sensor_id_str: data.event_data.sensor_id_str,
          sensor_human_name: data.event_data.sensor_human_name,
          media_url: data.inferences[0].media_url || "",
          image_height: data.inferences[0].image_height || 480,
          image_width: data.inferences[0].image_height || 640,
          created: data.event_data.created,
          classifier: data.inferences[0].classifier,
          probabilities: data.inferences[0].probabilities,
          class_labels: data.inferences[0].class_labels,
          message: data.inferences[0].message,
          improve_accuracy_flag: data.inferences[0].improve_accuracy_flag,
          improve_accuracy_username:
            data.inferences[0].improve_accuracy_username || null,
          improve_accuracy_timestamp:
            data.inferences[0].improve_accuracy_timestamp || null,
          suppress_flag: data.inferences[0].suppress_flag,
          record_type: data.inferences[0].record_type,
          review_logs: data.inferences[0].review_logs || null,
          reason_for_flag: data.inferences[0].reason_for_flag || "",
          object_detections: data.inferences[0].object_detections,
        };
        console.log("result.data 2nd check success");

        data.alerts.map((alert: any, index: number) => {
          if (index !== 0) {
            alert_data.push({
              doc_id: alert.doc_id,
              track_id: data.event_data.track_id,
              elastic_index: alert.elastic_index,
              event_type: type,
              data_origin: data.event_data.data_origin,
              site_id: data.event_data.site_id,
              site_name: data.event_data.site_name,
              sensor_id_str: data.event_data.sensor_id_str,
              sensor_human_name: data.event_data.sensor_human_name,
              media_url: alert.media_url || "",
              image_height: alert.image_height || 480,
              image_width: alert.image_width || 640,
              created: alert.created,
              classifier: alert.classifier,
              probabilities: alert.probabilities,
              class_labels: ["vehicle.car"],
              // class_labels: alert.class_labels,
              message: alert.message,
              improve_accuracy_flag: alert.improve_accuracy_flag,
              improve_accuracy_username:
                alert.improve_accuracy_username || null,
              improve_accuracy_timestamp:
                alert.improve_accuracy_timestamp || null,
              suppress_flag: alert.suppress_flag,
              record_type: alert.record_type,
              review_logs: alert.review_logs || null,
              reason_for_flag: alert.reason_for_flag || "",
            });
          }
        });
        // console.log('result.data 3rd check success')
        data.inferences.map((inference: any) => {
          let arr: any = [];
          inference.object_detections &&
            inference.object_detections.map((item: any) => {
              if (arr.indexOf(item.inference_class) === -1) {
                arr.push(item.inference_class);
              }
            });
          inference_data.push({
            doc_id: inference.doc_id,
            track_id: data.event_data.track_id,
            elastic_index: inference.elastic_index,
            event_type: type,
            data_origin: inference.data_origin || data.event_data.data_origin,
            site_id: data.event_data.site_id,
            site_name: data.event_data.site_name,
            sensor_id_str: data.event_data.sensor_id_str,
            sensor_human_name: data.event_data.sensor_human_name,
            media_url: inference.media_url || "",
            image_height: inference.image_height || 480,
            image_width: inference.image_height || 640,
            created: inference.created,
            classifier: inference.classifier,
            probabilities: inference.probabilities,
            class_labels: inference.class_labels,
            message: inference.message,
            improve_accuracy_flag: inference.improve_accuracy_flag,
            improve_accuracy_username:
              inference.improve_accuracy_username || null,
            improve_accuracy_timestamp:
              inference.improve_accuracy_timestamp || null,
            suppress_flag: inference.suppress_flag,
            record_type: inference.record_type,
            review_logs: inference.review_logs || null,
            reason_for_flag: inference.reason_for_flag || "",
            object_detections: inference.object_detections,
            // object_detections,
            classes: arr,
          });
        });
        // console.log('result.data last check success')
        result.push({
          event_data: event_data,
          inference_data: [...alert_data, ...inference_data],
        });
      } else {
        console.log("result.data no match");
      }
    });

    return result;
  }

  // Fetch Alert Events.
  const fetchAlertEvents = async (
    reset: boolean = true,
    startTime: number = 0,
    count = -1,
    stepper = 1
  ) => {
    console.log("fetchAlertEvents ***");
    if (siteid.length === 0) {
      setLoadImgs(false);
      return setLoading(false);
    }

    if (startTime) {
      setLoadImgs(true);
    } else {
      setLoading(true);
    }

    if (selectedAlert === initialState && count > 0) {
      setSelectedAlert(initialState1);
    }

    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    var calc_start_time = getNewStartTime(startTime, count, stepper);
    if (token) {
      try {
        // setLoading(true);
        const request = JSON.stringify({
          top_org_id: siteid.join() && !selectAllSites ? null : groupid,
          site_id: siteid[0],
          start_time_filter: calc_start_time,
          end_time_filter: startTime !== 0 ? startTime : newEndTime,
          max_event_count: selectedAlert === initialState ? 2 : 20,
          event_type: "alerted_events",
        });

        const res = await fetch(baseUrl + "/listvehicleinferences", {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = await (await res).json();
        let flagged_alertIds: string[] = [];
        let silenced_alertIds: string[] = [];
        if (result && result.success) {
          let fmt_alerts: any[] = [];
          let alert_l: EventType[] = [];

          console.log("result.data b4", result.msg.vehicles);

          result.msg.vehicles = reFormatEvents(result.msg.vehicles, "alert");

          console.log("result.msg.vehicles after", result.msg.vehicles);

          let ordered = result.msg.vehicles.sort(
            (a: any, b: any) => b.event_data.created - a.event_data.created
          );

          if (result.msg.vehicles.length > 0) {
            setNewStartTimeFilter(
              ordered[result.msg.vehicles.length - 1].event_data.created - 0.001
            );
          }

          let step = stepper + 1;
          if (
            result.msg.vehicles.length === 0 &&
            calc_start_time > newStartTime &&
            step < 10
          ) {
            fetchAlertEvents(
              false,
              newStartTimeFilter || getLastHourDate(),
              count,
              step
            );
            return;
          }

          result.msg.vehicles.forEach((i: any, indx: number) => {
            let item = i;

            item.inference_data.unshift(i.event_data);
            fmt_alerts.push(item);

            alert_l.push(i.event_data);

            if (i.event_data.improve_accuracy_flag) {
              flagged_alertIds.push(i.event_data.doc_id);
            }

            i.inference_data.forEach((item: any, index: number) => {
              if (item.improve_accuracy_flag) {
                flagged_alertIds.push(item.doc_id);
              }

              if (item.suppress_flag) {
                silenced_alertIds.push(item.doc_id);
              }
            });

            // if (i.inference_data.improve_accuracy_flag) {
            //   flagged_alertIds.push(i.inference_data.doc_id);
            // }

            // silenced events
            if (i.event_data.suppress_flag) {
              silenced_alertIds.push(i.event_data.doc_id);
            }
            if (i.inference_data.suppress_flag) {
              silenced_alertIds.push(i.inference_data.doc_id);
            }
          });

          console.log("fmt alert events ->& " + fmt_alerts);
          console.log(
            "result.msg.vehicles alert events ->&",
            result.msg.vehicles
          );
          console.log("flagged_alertIds", flagged_alertIds);
          console.log("silenced_alertIds", silenced_alertIds);

          if (startTime !== 0 || alertType === "all") {
            console.log("success all");
            setFlaggedIds((prev) => [...prev, ...flagged_alertIds]);
            setSilencedIds((prev) => [...prev, ...silenced_alertIds]);
            setAlertList((prev) => [...prev, ...alert_l]);
            setAlertEventsList((prev) => [...prev, ...fmt_alerts]);
          } else {
            console.log("failed all");
            setFlaggedIds(flagged_alertIds);
            setSilencedIds((prev) => [...prev, ...silenced_alertIds]);
            setAlertList(alert_l);
            setAlertEventsList(fmt_alerts);
          }

          let list_len = alert_l.length;
          let list_size = alertList.length + list_len;
          setHasMore(result.msg.vehicles.length > 0);

          if (startTime === 0) {
            if (fmt_alerts[0] || alert_l[0]) {
              if (reset) {
                console.log("select alert events ->", fmt_alerts[0]);
                setSelectedAlert(alert_l[0]);
                setSelectedAlertData(fmt_alerts[0]);
                setSilenceAlert(
                  fmt_alerts[0].event_data.suppress_flag ? true : false
                );
              } else {
                let b = alert_l!.find(
                  (i: EventType) => i.doc_id === selectedAlert.doc_id
                );
                let c = fmt_alerts!.find(
                  (i: { event_data: EventType; inference_data: EventType }) =>
                    i.event_data.doc_id === selectedAlert.doc_id
                );
                if (b) {
                  setSelectedAlert(b!);
                } else {
                  setSelectedAlert(alert_l[0]);
                }
                if (c) {
                  setSelectedAlertData(c!);
                  setSilenceAlert(c!.suppress_flag ? true : false);
                } else {
                  setSelectedAlertData(fmt_alerts[0]);
                  setSilenceAlert(
                    fmt_alerts[0].event_data.suppress_flag ? true : false
                  );
                }
              }
            } else {
              setSelectedAlert(initialState);
            }
          }
        }

        setLoading(false);
        setLoadImgs(false);
      } catch (err) {
        setLoading(false);
        return err;
      } finally {
        setReloadImgs(false);
      }
      setLoading(false);
      setLoadImgs(false);
    }
  };

  // Set object detections.
  useEffect(() => {
    if (selectedAlertData?.event_data?.object_detections) {
      console.log("found object detection", selectedAlertData);
      if (class_filter !== "all" && class_filter !== "none") {
        setObjectDetections(
          selectedAlertData.event_data?.object_detections.filter(
            (item: any) => item.inference_class === class_filter
          )
        );
      } else if (class_filter === "none") {
        setObjectDetections(undefined);
      } else {
        setObjectDetections(selectedAlertData.event_data?.object_detections);
      }
    } else {
      console.log("no object detection", selectedAlertData);
      setObjectDetections(undefined);
    }
  }, [selectedAlertData, class_filter]);

  // fetch All Events.
  const fetchAllEvents = async (
    reset: boolean = true,
    startTime: number = 0,
    count = -1,
    stepper = 1
  ) => {
    if (startTime) {
      setLoadImgs(true);
    } else {
      setLoading(true);
    }

    if (selectedAlert === initialState && count > 0) {
      setSelectedAlert(initialState1);
    }

    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    var calc_start_time = getNewStartTime(startTime, count, stepper);
    if (token) {
      try {
        // setLoading(true);
        const request = JSON.stringify({
          top_org_id: siteid.join() && !selectAllSites ? null : groupid,
          site_ids:
            !selectAllSites && siteid.length > 0 && sensorId === -1
              ? `${siteid.join()},`
              : null,
          sensor_ids: sensorId > 0 ? `${sensorId},` : null,
          start_time_filter: calc_start_time,
          end_time_filter: startTime !== 0 ? startTime : newEndTime,
          max_event_count: 20,
          event_type: "all_events",
        });

        const res_al = await fetch(baseUrl + "/events_v3", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result1 = await (await res_al).json();

        const total_result = reFormatEvents(result1.data, "all")
          .sort((a: any, b: any) => b.event_data.created - a.event_data.created)
          .slice(0, 10);

        console.log("total result", total_result);

        let flagged_alertIds: string[] = [];
        let silenced_alertIds: string[] = [];
        if (result1 && result1.success) {
          console.log("alerts data msg", result1.data);
          let fmt_alerts: any[] = [];
          let alert_l: EventType[] = [];

          if (total_result.length > 0) {
            setNewStartTimeFilter(
              total_result[total_result.length - 1].event_data.created - 0.001
            );
          }

          let step = stepper + 1;
          if (
            total_result.length === 0 &&
            calc_start_time > newStartTime &&
            step < 10
          ) {
            console.log("stepper: ", step);
            console.log("stepper: data", total_result.length);
            console.log("stepper: cmp", calc_start_time > newStartTime);
            fetchAllEvents(
              false,
              newStartTimeFilter || getLastHourDate(),
              count,
              step
            );
            return;
          }

          total_result.forEach((i: any, indx: number) => {
            let item = { ...i };
            if (item.event_data.record_type === "alert") {
              item.inference_data.unshift(i.event_data);
            }

            alert_l.push(i.event_data);

            if (i.event_data.improve_accuracy_flag) {
              flagged_alertIds.push(i.event_data.doc_id);
            }

            i.inference_data.forEach((item: any, index: number) => {
              if (item.improve_accuracy_flag) {
                flagged_alertIds.push(item.doc_id);
              }

              if (item.suppress_flag) {
                silenced_alertIds.push(item.doc_id);
              }
            });

            // silenced events
            if (i.event_data.suppress_flag) {
              silenced_alertIds.push(i.event_data.doc_id);
            }
            if (i.inference_data.suppress_flag) {
              silenced_alertIds.push(i.inference_data.doc_id);
            }

            if (i.inference_data.length === 0) {
              item.inference_data = [i.event_data];
            }
            fmt_alerts.push(reOrderEvents(item));
          });

          console.log("fmt alert events ->& " + fmt_alerts);
          console.log("result.data alert events ->&", result1.data);
          console.log("flagged_alertIds", flagged_alertIds);
          console.log("flagged_alertIds", silenced_alertIds);

          if (startTime !== 0) {
            setFlaggedIds((prev) => [...prev, ...flagged_alertIds]);
            setSilencedIds((prev) => [...prev, ...silenced_alertIds]);
            setAlertList((prev) => [...prev, ...alert_l].slice(0, eventCount));
            setAlertEventsList((prev) =>
              [...prev, ...fmt_alerts].slice(0, eventCount)
            );
          } else {
            setFlaggedIds(flagged_alertIds);
            setSilencedIds((prev) => [...prev, ...silenced_alertIds]);
            setAlertList(alert_l);
            setAlertEventsList(fmt_alerts);
          }

          setHasMore(total_result.length > 0);

          if (startTime === 0) {
            if (fmt_alerts[0] || alert_l[0]) {
              if (reset) {
                console.log("select alert events ->", fmt_alerts[0]);
                setSelectedAlert(alert_l[0]);
                setSelectedAlertData(fmt_alerts[0]);
                setSilenceAlert(
                  fmt_alerts[0].event_data.suppress_flag ? true : false
                );
              } else {
                let b = alert_l!.find(
                  (i: EventType) => i.doc_id === selectedAlert.doc_id
                );
                let c = fmt_alerts!.find(
                  (i: { event_data: EventType; inference_data: EventType }) =>
                    i.event_data.doc_id === selectedAlert.doc_id
                );
                if (b) {
                  setSelectedAlert(b!);
                } else {
                  setSelectedAlert(alert_l[0]);
                }
                if (c) {
                  setSelectedAlertData(c!);
                  setSilenceAlert(c!.suppress_flag ? true : false);
                } else {
                  setSelectedAlertData(fmt_alerts[0]);
                  setSilenceAlert(
                    fmt_alerts[0].event_data.suppress_flag ? true : false
                  );
                }
              }
            } else {
              setSelectedAlert(initialState);
            }
          }
        } else {
          console.log("something is wrong");
          console.log("something is wrong", result1, result1.success);
        }

        setLoading(false);
        setLoadImgs(false);
      } catch (err) {
        setLoading(false);
        return err;
      } finally {
        setReloadImgs(false);
      }
    }
  };

  useEffect(() => {
    if (alertType === "whitelisted" && whitelistId) {
      console.log("calling whitelisted vehicles -> ", whitelistId);
      fetchWhitelistedInf();
    }
  }, [whitelistId]);

  // Fetch Images Flagged for training.
  const fetchWhitelistedInf = async (
    reset: boolean = true,
    startTime: number = 0,
    count = -1,
    stepper = 1
  ) => {
    if (startTime) {
      setLoadImgs(true);
    } else {
      setLoading(true);
    }

    if (selectedAlert === initialState) {
      setSelectedAlert(initialState1);
    }

    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    var calc_start_time = getNewStartTime(startTime, count, stepper);
    if (token) {
      try {
        console.log(
          "flagged_images endpoint site_ids",
          `${siteid.join()}`,
          "**group_id",
          groupid
        );

        const bodyRequest = JSON.stringify({
          known_vehicle_list_id: whitelistId,
        });

        const res = await fetch(baseUrl + "/listvehicles", {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: bodyRequest,
        });
        const result = await (await res).json();

        console.log("whitelisted_images", result);

        let flagged_alertIds: string[] = [];

        const total_result = [...result.msg.vehicles].map((item: any) => {
          return {
            ...item,
            doc_id: item.id,
          };
        });

        console.log("total result", total_result);

        if (result.success) {
          let fmt_alerts: any[] = [];
          let alert_l: EventType[] = [];

          // let ordered = result.data
          let ordered = total_result.sort(
            (a: any, b: any) => b.created - a.created
          );

          if (total_result.length > 0 && alertType !== "all") {
            console.log(
              "set time for last image",
              ordered[ordered.length - 1].created - 1
            );
            setNewStartTimeFilter(ordered[ordered.length - 1].created - 0.001);
          }

          ordered.forEach((i: any, indx: number) => {
            fmt_alerts.push({ event_data: i, inference_data: [i] });
            alert_l.push(i);

            if (i.improve_accuracy_flag) {
              flagged_alertIds.push(i.doc_id);
            }
          });

          console.log("alertType === ", alertType);
          console.log("fmt alert events ->& " + fmt_alerts);
          console.log("alert_l ->& " + alert_l);
          console.log("result.data alert events ->&", result);
          console.log("flagged_alertIds", flagged_alertIds);

          if (startTime !== 0 || alertType === "all") {
            setFlaggedIds((prev) => [...prev, ...flagged_alertIds]);
            setAlertList((prev) => [...prev, ...alert_l]);
            setAlertEventsList((prev) => [...prev, ...fmt_alerts]);
          } else {
            setFlaggedIds(flagged_alertIds);
            setAlertList(alert_l);
            setAlertEventsList(fmt_alerts);
          }

          setHasMore(result.length > 0);

          if (startTime === 0) {
            if (fmt_alerts[0] || alert_l[0]) {
              if (reset) {
                console.log("select alert events ->", fmt_alerts[0]);
                setSelectedAlert(alert_l[0]);
                setSelectedAlertData(fmt_alerts[0]);
                setSilenceAlert(fmt_alerts[0].suppress_flag ? true : false);
              } else {
                console.log("select alert events else block ->", fmt_alerts[0]);
                let b = alert_l!.find(
                  (i: EventType) => i.doc_id === selectedAlert.doc_id
                );
                let c = fmt_alerts!.find(
                  (i: { event_data: EventType; inference_data: EventType }) =>
                    i.event_data.doc_id === selectedAlert.doc_id
                );
                if (b) {
                  setSelectedAlert(b!);
                } else {
                  setSelectedAlert(alert_l[0]);
                }
                if (c) {
                  setSelectedAlertData(c!);
                  setSilenceAlert(c!.suppress_flag ? true : false);
                } else {
                  setSelectedAlertData(fmt_alerts[0]);
                  setSilenceAlert(fmt_alerts[0].suppress_flag ? true : false);
                }
              }
            } else {
              console.log("hit  initialState for sa ->");
              setSelectedAlert(initialState);
            }
          }
        }

        setLoading(false);
        setLoadImgs(false);
      } catch (err) {
        setLoading(false);
        return err;
      } finally {
        setReloadImgs(false);
      }
      setLoading(false);
      setLoadImgs(false);
    }
  };

  const handleChangeAuto = (value: any) => {
    if (!value) {
      return;
    }
    setShowOther(false);

    if (value.name === "Create New") {
      setShowOther(true);
    }
    setWhitelistName(value.name);
    setWhitelistId(value.id);
  };

  // Whitelist Images for Training
  const whitelistImage = async (add = true) => {
    setLoadingViewer(true);
    var baseUrl = getBaseUrl();
    var token = await appStore?.authService.getAuthorisedToken();

    let whitelist_id = whitelistId;
    if (newWhitelistName) {
      whitelist_id = await createWhitelist();
    }

    setMessage("");
    if (token) {
      try {
        let request = JSON.stringify({
          known_vehicle_list_id: whitelist_id,
          plate: editPlate || selectedAlertData!.event_data.alpr?.plate || "",
          make: selectedAlertData!.event_data.alpr?.make || null,
          model: selectedAlertData!.event_data.alpr?.model || null,
          colour: selectedAlertData!.event_data.alpr?.colour || null,
          year: selectedAlertData!.event_data.alpr?.year || null,
          vehicle_type:
            selectedAlertData!.event_data.alpr?.vehicle_type || null,
          country_code:
            selectedAlertData!.event_data.alpr?.country_code || null,
          image_url: selectedAlertData!.event_data.media_url || null,
          vehicle_coordinates: {
            vehicle_x0: selectedAlertData!.event_data.alpr?.vehicle_x0,
            vehicle_y0: selectedAlertData!.event_data.alpr?.vehicle_y0,
            vehicle_x1: selectedAlertData!.event_data.alpr?.vehicle_x1,
            vehicle_y1: selectedAlertData!.event_data.alpr?.vehicle_y1,
          },
        });

        const path = add ? "/whitelistvehicle" : "/removevehicle";

        const res = await fetch(baseUrl + path, {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });

        setOpenToast(true);
        setEditPlate("");
        await setTimeout(async () => {
          setOpenToast(false);
        }, 3000);

        const result = (await (await res).json()) as any;

        if (result.success) {
          setErr(false);
          // setNewWhitelistName("");
          // setWhitelistId(null);
          alertType === "whitelisted"
            ? setMessage("successfully removed image from whitelist")
            : setMessage("successfully whitelisted image");
        }

        if (!result.success) {
          setErr(true);
          setMessage(
            result?.msg || "Something went wrong, please try again later"
          );
        }
        setLoadingViewer(false);
      } catch (err) {
        setLoadingViewer(false);
      } finally {
        await setTimeout(async () => {
          if (alertType === "whitelisted") {
            await fetchWhitelistedInf();
          } else if (alertType === "alerted") {
          } else {
          }
        }, 1000);
        setLoadingViewer(false);
        await setTimeout(async () => {
          setOpenToast(false);
        }, 3000);
      }
    }
  };

  // Create Whitelist.
  const createWhitelist = async () => {
    if (!newWhitelistName) {
      setOpenToast(true);
      setErr(true);
      await setTimeout(async () => {
        setOpenToast(false);
        setMessage("");
        setErr(false);
      }, 3000);
      return setMessage("Please enter name for whitelist");
    }

    setLoadingViewer(true);

    //temp end
    var token = await appStore?.authService.getAuthorisedToken();
    setMessage("");
    if (token && siteid.length > 0) {
      try {
        let request = JSON.stringify({
          site_id: siteid[0],
          name: newWhitelistName,
        });

        const res = await fetch(baseUrl + "/createwhitelist", {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });

        setOpenToast(true);

        const result = (await (await res).json()) as any;

        console.log("createwhitelist -> ", result);
        if (result.success) {
          setMessage("successfully created whitelist");
          setErr(false);
          setNewWhitelistName("");
          await fetchWhitelistData();
          await setTimeout(async () => {
            setOpenToast(false);
          }, 5000);
          return result.msg["Created whitelist"];
        }

        if (!result) {
          setErr(true);
          setMessage(
            result.msg || "Something went wrong, please try again later"
          );
        }
        setLoadingViewer(false);
      } catch (err) {
        setLoadingViewer(false);
      } finally {
        await setTimeout(async () => {
          if (alertType === "whitelisted") {
            await fetchWhitelistedInf();
          } else if (alertType === "alerted") {
          } else {
          }
        }, 1000);
        setLoadingViewer(false);
        await setTimeout(async () => {
          setOpenToast(false);
        }, 3000);
      }
    }
  };

  // Update Whitelist
  const updateWhitelist = async () => {
    setLoadingViewer(true);
    // temp
    setOpenToast(true);
    await setTimeout(async () => {
      setMessage("successfully updated whitelist");
      setLoadingViewer(false);
    }, 1000);

    setEditPlate("");
    return;

    //temp end
    var baseUrl = getBaseUrl();
    var token = await appStore?.authService.getAuthorisedToken();
    setMessage("");
    if (token && siteid.length > 0) {
      try {
        let request = JSON.stringify({
          id: Math.round(Math.random() * 1000),
          site_id: siteid[0],
          name: newWhitelistName,
        });

        const res = await fetch(baseUrl + "/list_whitelist", {
          // const res = await fetch("http://localhost:3004/list_whitelist", {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });

        setOpenToast(true);
        await setTimeout(async () => {
          setOpenToast(false);
        }, 3000);

        const result = (await (await res).json()) as any;

        if (result) {
          // if (result.success) {
          setErr(false);
          setNewWhitelistName("");
          setMessage("successfully created whitelist");
          await fetchWhitelistData();
          return result.id;
        }

        if (!result) {
          // if (!result.success) {
          setErr(true);
          setMessage(
            result.msg || "Something went wrong, please try again later"
          );
        }
        setLoadingViewer(false);
      } catch (err) {
        setLoadingViewer(false);
      } finally {
        await setTimeout(async () => {
          if (alertType === "whitelisted") {
            await fetchWhitelistedInf();
          } else if (alertType === "alerted") {
          } else {
          }
        }, 1000);
        setLoadingViewer(false);
        await setTimeout(async () => {
          setOpenToast(false);
        }, 3000);
      }
    }
  };

  // Fetch Alerts with grouped images
  useEffect(() => {
    const fetchImages = async () => {
      if (groupid >= 0) {
        setHasMore(true);
        setFlaggedIds([]);
        setSilencedIds([]);
        setAlertEventsList([]);
        setAlertList([]);
        setStoredIds([]);
        setEventTimeout(false);
        setWhitelistId(null);

        setSelectedAlert(initialState);
        if (alertType === "alerted") {
          console.log("calling alerted events");
          let count = await fetchEventCounts();
          await fetchAlertEvents(true, 0, count);
        }

        if (alertType === "whitelisted" && whitelistId) {
          console.log("calling flagged events");
          await fetchWhitelistedInf(true, 0);
        }
      } else {
        console.log("org id is invalid");
      }
    };
    fetchImages();
  }, [sensorId, siteid, groupid, alertType, newStartTime, newEndTime, page]);

  let toast = [
    <Collapse in={openToast}>
      {message && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 20,
            justifyContent: "center",
          }}
        >
          <Alert
            severity={err ? "error" : "success"}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  handleToastClose();
                  setOpenToast(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        </div>
      )}
    </Collapse>,
  ];

  return (
    <div
      className={classes.root_dashboard}
      style={{
        width: !showGraph ? 1380 : "100%",
        backgroundColor: "rgba(246, 246, 246, 0.8)",
        padding: 10,
        borderRadius: 8,
      }}
    >
      <Grid container spacing={1}>
        <CustomModal
          closeHandler={() => {
            setOpenDelete(false);
          }}
          open={openDelete}
          title={"confirm"}
          continueAction={true}
          onContinueHandler={() => {
            deleteHandler(whitelistId);
            setOpenDelete(false);
          }}
        >
          <h1
            style={{
              fontSize: 14,
              color: "#15325F",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            WARNING!
          </h1>
          <br />
          <p
            style={{
              fontSize: 14,
              color: "#f44336",
              fontWeight: "bold",
              textAlign: "center",
              marginLeft: 30,
              marginRight: 30,
            }}
          >
            Are you sure you want to delete this record ?
          </p>
        </CustomModal>
        <CustomModal
          closeHandler={() => {
            setOpenFlagModal(false);
            setOpenSilenceModal(false);
            setShowOther(false);
            setWhitelistName("");
            setWhitelistId(null);
          }}
          open={openFlagModal || openSilenceModal}
          onContinueHandler={() => {
            if (openFlagModal) {
              // flagImages()
              if (!whitelistId && !newWhitelistName) {
                return msg.error("Please select whitelist");
              }
              console.log("whitelisting an image...");
              whitelistImage();
            }
            setOpenFlagModal(false);
            setOpenSilenceModal(false);
            setShowOther(false);
            setWhitelistName("");
            setWhitelistId(null);
          }}
          continueAction={true}
          continue
          title={"Whitelist"}
        >
          <Autocomplete
            id="combo-box-demo"
            onChange={(event: React.ChangeEvent<any>, value: any) => {
              handleChangeAuto(value);
            }}
            options={whitelist}
            getOptionLabel={(option) => option.name}
            style={{ width: 400, color: "#6D809D" }}
            classes={{ paper: styler.paper }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <span>
                    {whitelistName
                      ? "Whitelist Name"
                      : "Please Select Whitelist"}
                  </span>
                }
                variant="outlined"
                value={groupid}
                style={{ color: "#6D809D" }}
                className={classes.bold_text}
              />
            )}
          />
          {showOther && (
            <div>
              <TextField
                onChange={(e) => {
                  setNewWhitelistName(e.target.value);
                }}
                style={{ color: "#15325F", marginTop: 20 }}
                label="Enter Name for Whitelist"
                value={newWhitelistName}
                margin="normal"
                fullWidth
                variant="outlined"
                InputProps={{
                  className: classes.bold_text,
                }}
              />
            </div>
          )}
          <div style={{ width: 400, color: "#6D809D" }}>
            <Tooltip
              placement="bottom"
              title="Remove all visible text to ignore license plate"
            >
              <TextField
                variant="outlined"
                onChange={(e) => {
                  setEditPlate(e.target.value);
                }}
                style={{ color: "#15325F", marginTop: 20 }}
                label="License Plate"
                defaultValue={selectedAlertData?.event_data?.alpr?.plate}
                // value={newWhitelistName}
                margin="normal"
                fullWidth
                InputProps={
                  {
                    // className: classes.bold_text,
                  }
                }
              />
            </Tooltip>
            <TextField
              disabled
              variant="outlined"
              onChange={(e) => {
                setEditPlate(e.target.value);
              }}
              style={{ color: "#15325F", marginTop: 20 }}
              label="Make"
              defaultValue={selectedAlertData?.event_data?.alpr?.make}
              margin="normal"
              fullWidth
              InputProps={{
                className: classes.bold_text,
              }}
            />
            <TextField
              disabled
              variant="outlined"
              onChange={(e) => {
                setEditPlate(e.target.value);
              }}
              style={{ color: "#15325F", marginTop: 20 }}
              label="Model"
              defaultValue={selectedAlertData?.event_data?.alpr?.model}
              margin="normal"
              fullWidth
              InputProps={{
                className: classes.bold_text,
              }}
            />
            <TextField
              disabled
              variant="outlined"
              onChange={(e) => {
                setEditPlate(e.target.value);
              }}
              style={{ color: "#15325F", marginTop: 20 }}
              label="Color"
              defaultValue={selectedAlertData?.event_data?.alpr?.colour}
              margin="normal"
              fullWidth
              InputProps={{
                className: classes.bold_text,
              }}
            />
          </div>
        </CustomModal>
        <CustomModal
          closeHandler={() => {
            setOpenTimeRange(false);
          }}
          open={openTimeRange}
        >
          <TimeRange
            applyChangesHandler={applyTime}
            backDateHandler={(e: number) => {
              setFlaggedIds([]);
              setSilencedIds([]);
              setAlertEventsList([]);
              setAlertList([]);
              setEventTimeout(false);
              setNewStartTime(e);

              setNewStartTimeFilter(e);
              setNewEndTime(null);

              setOpenTimeRange(false);
            }}
            savedTime={savedTime}
            title={dateTitle}
            setSavedTime={(e) => {
              setSavedTime(e);
            }}
            dateTitle={(e) => setDateTitle(e)}
            closeModal={() => setOpenTimeRange(false)}
          />
        </CustomModal>
        <CustomModal
          closeHandler={() => {
            setCreateNewWhitelist(false);
            setWhitelistName("");
          }}
          open={createNewWhitelist}
          onContinueHandler={() => {
            updateWhitelist();
            setCreateNewWhitelist(false);
            setWhitelistName("");
          }}
          continueAction={true}
          continue
          title={"Update"}
        >
          <div style={{ width: 400, color: "#6D809D" }}>
            <Tooltip
              placement="bottom"
              title="Remove all visible text to ignore license plate"
            >
              <TextField
                variant="outlined"
                onChange={(e) => {
                  setEditPlate(e.target.value);
                }}
                style={{ color: "#15325F", marginTop: 20 }}
                label="License Plate"
                defaultValue={selectedAlertData?.event_data?.alpr?.plate}
                margin="normal"
                fullWidth
                InputProps={{
                  className: classes.bold_text,
                }}
              />
            </Tooltip>
            <TextField
              disabled
              variant="outlined"
              onChange={(e) => {
                setEditPlate(e.target.value);
              }}
              style={{ color: "#15325F", marginTop: 20 }}
              label="Make"
              defaultValue={selectedAlertData?.event_data?.alpr?.make}
              // value={newWhitelistName}
              margin="normal"
              fullWidth
              InputProps={{
                className: classes.bold_text,
              }}
            />
            <TextField
              disabled
              variant="outlined"
              onChange={(e) => {
                setEditPlate(e.target.value);
              }}
              style={{ color: "#15325F", marginTop: 20 }}
              label="Model"
              defaultValue={selectedAlertData?.event_data?.alpr?.model}
              margin="normal"
              fullWidth
              InputProps={{
                className: classes.bold_text,
              }}
            />
            <TextField
              disabled
              variant="outlined"
              onChange={(e) => {
                setEditPlate(e.target.value);
              }}
              style={{ color: "#15325F", marginTop: 20 }}
              label="Color"
              defaultValue={selectedAlertData?.event_data?.alpr?.colour}
              margin="normal"
              fullWidth
              InputProps={{
                className: classes.bold_text,
              }}
            />
          </div>
        </CustomModal>
        <Grid item xs={12} sm={12} md={12} spacing={0}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              minWidth: 900,
              paddingRight: 30,
              marginTop: 0,
            }}
          >
            <OrgtreeAutocomplete
              groupid={groupid}
              appStore={appStore}
              enabled={true}
              setGroupId={(id) => {
                setGroupId(id);
                setSiteId([]);
                setSensorId(-1);
                setFlaggedIds([]);
                setSilencedIds([]);
                setEventTimeout(false);
                setAlertEventsList([]);
                setAlertList([]);
                setSelectedAlert(initialState);
              }}
              setLoading={(e: boolean) => setLoading(e)}
            />
            <MultiSiteSelect
              is_alpr
              enabled
              multiple={false}
              selectAllSites={setSelectAllSites}
              groupid={groupid}
              appStore={appStore}
              setSiteIds={(ids, motioncamids) => {
                setSiteId(ids);
                setSensorId(-1);
                setFlaggedIds([]);
                setSilencedIds([]);
                setEventTimeout(false);
                setAlertEventsList([]);
                setAlertList([]);
                setSelectedAlert(initialState);
              }}
              setSiteLists={(sites: Site[]) => setSiteList(sites)}
              setLoading={(e: boolean) => setLoadingSite(e)}
              top_org_only={false}
              loading={loading}
            />
            {alertType === "whitelisted" && (
              <Whitelist
                selectAllSites={selectAllSites}
                siteids={siteid}
                sensorId={whitelistId}
                appStore={appStore}
                setSiteId={(id) => setSiteId([id])}
                setLoading={(e: boolean) => setLoadingSite(e)}
                setSensorId={(e) => {
                  setWhitelistId(e);
                  setFlaggedIds([]);
                  setSilencedIds([]);
                  setEventTimeout(false);
                  setAlertEventsList([]);
                  setAlertList([]);
                  setSelectedAlert(initialState);
                }}
                loading={loadingSite}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 10,
              marginBottom: 3,
              width: "100%",
            }}
          >
            <AlprOptions
              disablePrevBtn={setDisablePrev}
              showFilteredEvents={
                appStore!.authService!.getRoles()!.indexOf("sys_admin") >= 0
              }
              loading={loading || loadImgs}
              groupid={groupid}
              optionHander={(e) => {
                if (e === "silenced") {
                  console.log("now alert set to silenced...");
                  setSilenceAlert(true);
                }
                if (e === "alerted") {
                  setSensorId(-1);
                }
                if (e === "unsilenced") {
                  console.log("now alert set to unsilenced...");
                  setSilenceAlert(false);
                }
                setFlaggedIds([]);
                setSilencedIds([]);
                setAlertEventsList([]);
                setEventTimeout(false);
                setAlertList([]);
                setSelectedAlert(initialState);
                setAlertType(e);
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={12} direction="row" container>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "60%" }}>
              {!!alertList.length && (
                <div
                  className={classes.bold_text}
                  style={{
                    fontSize: 13,
                    marginLeft: 10,
                    width: "100%",
                    marginRight: 20,
                  }}
                >
                  {alertType === "silenced"
                    ? "TOTAL SILENCED ALERTS: "
                    : alertType === "unsilenced"
                    ? "TOTAL UNSILENCED ALERTS: "
                    : alertType === "alerted"
                    ? ""
                    : alertType === "filtered"
                    ? ""
                    : alertType === "whitelisted"
                    ? "WHITELISTED VEHICLES: "
                    : ""}
                  <span
                    style={{
                      fontSize: 14,
                      color: "#F32424",
                      fontWeight: "bold",
                    }}
                  >
                    {alertList.length}{" "}
                  </span>{" "}
                  {(alertType === "alerted" ||
                    alertType === "filtered" ||
                    alertType === "all") && <span> VEHICLES LISTED</span>}
                </div>
              )}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              {selectedAlertData &&
                selectedAlertData?.event_data &&
                selectedAlertData?.event_data?.classes?.length > 0 && (
                  <FormControl
                    variant="outlined"
                    style={{
                      borderColor: "red",
                      marginLeft: 0,
                      fontWeight: 300,
                    }}
                    className={classes.formControl}
                  >
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={class_filter}
                      onChange={(
                        event: React.ChangeEvent<{ value: unknown }>
                      ) => setClassFilter(event.target.value as string)}
                      label="CLASSES"
                      className={classes.bold_text}
                      style={{
                        height: 45,
                        borderColor: "red",
                        minWidth: 150,
                        marginLeft: 0,
                        fontWeight: 300,
                      }}
                    >
                      <MenuItem
                        className={classes.bold_text}
                        value="all"
                        style={{ fontWeight: 300 }}
                      >
                        ALL
                      </MenuItem>
                      <MenuItem
                        className={classes.bold_text}
                        value="none"
                        style={{ fontWeight: 300 }}
                      >
                        NONE
                      </MenuItem>
                      {selectedAlertData.event_data.classes.map(
                        (item: string) => (
                          <MenuItem
                            style={{ fontWeight: 300 }}
                            className={classes.bold_text}
                            value={item}
                          >
                            {item.toUpperCase()}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                )}
              {/* temp end */}
              <div style={{ marginRight: 10, marginLeft: 50 }}>
                <Button
                  style={{
                    fontWeight: 500,
                    fontSize: 13,
                    borderRadius: 8,
                    minWidth: 150,
                    height: 45,
                  }}
                  variant="outlined"
                  color="primary"
                  component="span"
                  onClick={() => setOpenTimeRange(true)}
                >
                  {dateTitle || ""}
                </Button>
              </div>
              <CustomButton
                styles={{ borderRadius: 8 }}
                title={"Refresh"}
                disabled={time_date.indexOf(dateTitle) < 0}
                onClickHandler={(id) => {
                  setHasMore(true);
                  setNewEndTime(new Date().getTime() / 1000);
                  if (alertType === "whitelisted") {
                    fetchWhitelistedInf(true, 0);
                  } else if (groupid >= 0 && alertType === "alerted") {
                    fetchAlertEvents(true);
                  } else if (groupid >= 0 && alertType === "all") {
                    fetchAlertEvents(true);
                  } else {
                    fetchAlertEvents(true);
                  }
                  setFlaggedIds([]);
                  setSilencedIds([]);
                  setEventTimeout(false);
                  setAlertEventsList([]);
                  setAlertList([]);
                }}
                id={"refresh"}
              />
              {alertType !== "whitelisted" &&
                flagged_ids.findIndex(
                  (id: string) => id === selectedAlertData?.event_data.doc_id
                ) === -1 && (
                  <CustomButton
                    styles={{ borderRadius: 8 }}
                    disabled={
                      !!!(
                        alertType !== "whitelisted" &&
                        alertType !== "silenced" &&
                        alertType !== "unsilenced" &&
                        !autoplay &&
                        selectedAlertData
                      )
                    }
                    title={"Add to whitelist"}
                    groupid={groupid}
                    onClickHandler={(id) => {
                      if (
                        selectedAlert.site_id > -1 &&
                        alertType !== "whitelisted" &&
                        selectedAlertData
                      ) {
                        console.log("flagging...", selectedAlert);
                        setOpenFlagModal(true);
                      } else {
                        console.log(
                          "not flagging",
                          selectedAlertData,
                          "site_id",
                          selectedAlert.site_id
                        );
                      }
                    }}
                    id={"refresh"}
                    red={true}
                  />
                )}
              {alertType === "whitelisted" ||
                (flagged_ids.findIndex(
                  (id: string) => id === selectedAlertData?.event_data.doc_id
                ) > -1 && (
                  <CustomButton
                    styles={{ borderRadius: 8 }}
                    disabled={!!!selectedAlertData}
                    title={"Remove from whitelist"}
                    onClickHandler={(id) => {
                      if (
                        selectedAlert.site_id > -1 &&
                        alertType === "whitelisted" &&
                        selectedAlertData
                      ) {
                        console.log("flagging...", selectedAlert);
                        whitelistImage(false);
                      } else {
                        console.log(
                          "not flagging",
                          selectedAlertData,
                          "site_id",
                          selectedAlert.site_id
                        );
                      }
                      whitelistImage();
                    }}
                    id={"refresh"}
                    red={true}
                  />
                ))}
              {(alertType === "silenced" ||
                alertType === "unsilenced" ||
                alertType === "whitelisted") && (
                <CustomButton
                  styles={{ borderRadius: 8 }}
                  title={"Remove from whitelist"}
                  onClickHandler={(id) => {
                    if (selectedAlert.site_id > -1) {
                      console.log("flagging...", selectedAlert);
                      whitelistImage(false);
                    }
                  }}
                  id={"refresh"}
                  red={true}
                  disabled={false}
                />
              )}
            </div>
          </div>
        </Grid>
        {message && (
          <Grid item xs={12}>
            {toast}
          </Grid>
        )}
        <Grid item xs={4}>
          <Paper
            className={classes.paper}
            style={{ borderRadius: 20, border: "1px solid rgb(0, 60, 128)" }}
          >
            <div
              style={{
                height: 862,
              }}
            >
              {!loading &&
                selectedAlert.created === 0 &&
                alertList.length === 0 && (
                  <div
                    className={classes.bold_text}
                    style={{
                      display: "flex",
                      paddingTop: 100,
                      justifyContent: "center",
                      fontSize: 16,
                    }}
                  >
                    {groupid === -1
                      ? "Please select group"
                      : siteid.length === 0
                      ? "Please select site(s)"
                      : "No Events Found!"}
                  </div>
                )}
              {!loading &&
                selectedAlert.created === 0 &&
                alertList.length > 0 && (
                  <p onClick={() => fetchAllEvents()}>
                    An error occured! click here to reload events
                  </p>
                )}
              {!loading && selectedAlert.created !== 0 && (
                <>
                  <div
                    className={classes.bold_text}
                    style={{
                      margin: 0,
                      width: "100%",
                      height: 800,
                      overflowY: "hidden",
                    }}
                  >
                    <br />
                    <ListAlprEvents
                      activatePrevButton={setDisablePrev}
                      PrevORNext={setSendPrevORNext}
                      getActiveItem={setActiveItem}
                      lastImage={lastImage}
                      loading={loadImgs}
                      type={alertType}
                      selectedId={selectedAlertData?.event_data?.doc_id}
                      alertList={alertList}
                      openModal={(id: number | string) => {
                        setLoadingViewer(true);
                        setTimeout(() => {
                          setLoadingViewer(false);
                        }, 400);
                        setObjectDetections(undefined);
                        setClassFilter("all");
                        if (
                          alertType === "alerted" ||
                          alertType === "filtered" ||
                          alertType === "all" ||
                          alertType === "silenced" ||
                          alertType === "unsilenced" ||
                          alertType === "whitelisted"
                        ) {
                          let a = alertEventsList.filter(
                            (alert: any) => alert.event_data.doc_id === id
                          )[0];
                          setSelectedAlertData(a);
                          setSilenceAlert(a.event_data.suppress_flag);
                        }
                        setSelectedAlert(
                          alertList.filter((alert) => alert.doc_id === id)[0]
                        );
                        setSilenceAlert(
                          alertList.filter((alert) => alert.doc_id === id)[0]
                            .suppress_flag
                        );
                      }}
                    />
                  </div>
                  <br />
                  <br />
                </>
              )}

              {loading && (
                <div
                  className={classes.bold_text}
                  style={{
                    display: "flex",
                    paddingTop: 195,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner />
                </div>
              )}
              {errMessage && (
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {errMessage}
                </p>
              )}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper
            className={classes.paper}
            style={{
              minWidth: 750,
              borderRadius: 20,
              border: "1px solid rgb(0, 60, 128)",
            }}
          >
            <div
              style={{
                paddingBottom: "50px",
              }}
            ></div>
            <div
              style={{
                height: 812,
              }}
            >
              {!loading &&
                !loadingViewer &&
                selectedAlertData &&
                !!alertEventsList.length && (
                  <AlprViewerMulti
                    appStore={appStore}
                    alert={alertEventsList[4]}
                    object_detections={object_detections}
                    alertList={alertList}
                    mode={alertType}
                    review_logs={
                      selectedAlertData!.event_data?.review_logs || []
                    }
                    selectedAlert={selectedAlertData}
                    autoplay={autoplay}
                    setSelectedAlert={setSelectedAlertData}
                    siteId={selectedAlertData!.event_data.site_id}
                    sensorName={selectedAlertData!.event_data.sensor_human_name}
                    sensorId={selectedAlertData!.event_data.sensor_id_str}
                    advanced_rule_name={selectedAlertData!.event_data.rule_name}
                    advanced_rule_type={selectedAlertData!.event_data.rule_type}
                    classifier={selectedAlertData!.event_data.class_labels}
                    imgUrl={
                      selectedAlertData!.event_data.media_url ||
                      "/assets/img/nodata3.png"
                    }
                    created={selectedAlertData!.event_data.created}
                    message={selectedAlertData?.event_data?.message || ""}
                    siteName={selectedAlertData!.event_data.site_name}
                    probability={selectedAlertData!.event_data.probabilities}
                    flagged={
                      flagged_ids.findIndex(
                        (id: string) =>
                          id === selectedAlertData!.event_data.doc_id
                      ) > -1
                    }
                    silenced={
                      silenced_ids.findIndex(
                        (id: string) =>
                          id === selectedAlertData!.event_data.doc_id
                      ) > -1
                    }
                    onClickHandler={() => {
                      let temp_site = siteList.filter(
                        (item) =>
                          item.site_id == selectedAlertData!.event_data.site_id
                      )[0];
                      temp_site.type = "site";
                      temp_site.name = selectedAlertData!.event_data.site_name;
                      temp_site.site_name =
                        selectedAlertData!.event_data.site_name;
                      handleItemSelection(temp_site);
                      console.log("temp_site", temp_site);
                    }}
                  />
                )}
              {!loading &&
                alertList.length === 0 &&
                alertEventsList.length === 0 && (
                  <div
                    style={{
                      width: "100%",
                      display: "block",
                      borderRadius: 8,
                      overflow: "hidden",
                      transition: "width 2s, height 4s",
                    }}
                  >
                    <p
                      className={classes.bold_text}
                      style={{ marginTop: 50, fontSize: 16 }}
                    >
                      Whitelist Empty!
                    </p>
                  </div>
                )}
              {(loading || loadingViewer) && (
                <div
                  className={classes.bold_text}
                  style={{
                    display: "flex",
                    paddingTop: 140,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner />
                </div>
              )}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6} md={6}></Grid>
      </Grid>
    </div>
  );
};

export default AlprPage;
