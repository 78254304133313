import React, { useState, useEffect, Profiler } from "react";
import moment from "moment-timezone";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AppStore } from "../../../../stores/AppStore";
import { useStyles } from "../../../styling/global/global";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import { Tooltip } from "@material-ui/core";

import DeleteProductOrgConfirmation from "./DeleteProductOrgConfimation";
import {
  listBillingProfileOrgsItems,
  listBillingRunsItems,
  listBillingProductOrgs,
  billingProductOrgsItems,
  listBillingRatesItems,
} from "../../../../services/FunctionService";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";

interface ListProductOrgsProps {
  open: boolean;
  appStore?: AppStore;
  product: listBillingRatesItems | undefined;
  onClose: () => void;
}

const ListProductOrgs: React.FC<ListProductOrgsProps> = ({
  open,
  onClose,
  product,
  appStore,
}: ListProductOrgsProps) => {
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    })
  )(TableRow);

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [billingProductOrgs, setBillingProductOrgs] = useState<
    billingProductOrgsItems[]
  >([]);
  const [billingProductOrg, setBillingProductOrg] = useState<
    billingProductOrgsItems | undefined
  >(undefined);
  const [renderValue, setRenderValue] = useState(false);
  const closeOpenModal = () => {
    setOpenModal(false);
  };
  const deleteProductOrgFunc = async (
    org_id: number,
    billing_rate_id: number
  ) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const deleteProductOrgResult =
        await appStore?.functionService.deleteProductOrg(
          token,
          org_id,
          billing_rate_id
        );
      if (deleteProductOrgResult) {
        if (deleteProductOrgResult?.success) {
          setLoading(false);
          setRenderValue((value) => !value);
        } else {
          setLoading(false);
          setRenderValue((value) => !value);
        }
      }
    }
  };

  useEffect(() => {
    const fetchBillingProductOrgs = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        if (product) {
          const ListProductOrgs =
            await appStore?.functionService.listBillingProductOrgs(
              token,
              product.id
            );
          if (ListProductOrgs) {
            if (ListProductOrgs?.success) {
              setBillingProductOrgs(ListProductOrgs.billing_orgs);
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
        }
      }
    };
    fetchBillingProductOrgs();
  }, [product, renderValue]);
  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle
          style={{ alignSelf: "center" }}
          className={classes.bold_text}
          id="simple-dialog-title"
        >
          <Typography
            className={classes.bold_text}
            style={{ color: "#3f51b5" }}
          >
            Assigned groups for billing rate{" "}
            <span
              style={{ color: "blue", fontSize: 14 }}
              className={classes.bold_text}
            >
              {product ? product.product : ""} (id={product ? product.id : ""})
            </span>{" "}
          </Typography>
        </DialogTitle>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div
            style={{
              width: "90%",
              display: "flex",
              alignItems: "center",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                marginTop: 15,
                borderRadius: 15,
                overflow: "hidden",
                width: "100%",
              }}
            >
              <TableContainer style={{ maxHeight: 500 }}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {" "}
                        <span
                          className={classes.bold_text}
                          style={{ color: "#3f51b5" }}
                        >
                          group&nbsp;id
                        </span>
                      </TableCell>
                      <TableCell>
                        <span
                          className={classes.bold_text}
                          style={{ color: "#3f51b5" }}
                        >
                          group&nbsp;name
                        </span>
                      </TableCell>
                      <TableCell>
                        <span
                          className={classes.bold_text}
                          style={{ color: "#3f51b5" }}
                        >
                          group&nbsp;level
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {billingProductOrgs &&
                      billingProductOrgs.map((org, index) => {
                        return (
                          <StyledTableRow>
                            <TableCell>
                              <Typography className={classes.bold_text}>
                                {org.id}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography className={classes.bold_text}>
                                {org.name}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography className={classes.bold_text}>
                                {org.org_level}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Tooltip
                                placement="bottom"
                                title="Delete current profile org"
                              >
                                <DeleteIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setBillingProductOrg(org);
                                    setOpenModal(true);
                                  }}
                                />
                              </Tooltip>
                            </TableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        )}

        <div
          style={{ marginTop: "5%", marginBottom: "5%" }}
          className={classes.twoButtonsInARow}
        >
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => onClose()}
          >
            <span className={classes.bold_text}>Dismiss</span>
          </Button>
        </div>
      </Dialog>
      <DeleteProductOrgConfirmation
        open={openModal}
        onClose={closeOpenModal}
        appStore={appStore}
        product={product}
        billingProductOrg={billingProductOrg}
        deleteProductOrgFunc={(org_id: number, billing_rate_id: number) =>
          deleteProductOrgFunc(org_id, billing_rate_id)
        }
      />
    </div>
  );
};

export default ListProductOrgs;
