import React, { useState, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { AppStore } from "../../../stores/AppStore";
import {
  Hub,
  Classifier,
  OrgBillingRate,
  Org,
} from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import { MainContext } from "../../containers/HomePage/HomePage";
interface AddCameraSimpleModalProps {
  appStore?: AppStore;
  site: { [key: string]: any } | undefined;
  open: boolean;
  onClose: () => void;
  currentOrg: Org;
  saveNewCameraSimple: (
    hub_id: number,
    site_id: number,
    name: string,
    data_origin_name: string,
    site_name: string,
    password: string,
    current_env: string
  ) => void;
}

const AddCameraSimpleModal: React.FC<AddCameraSimpleModalProps> = ({
  appStore,
  site,
  open,
  onClose,
  saveNewCameraSimple,
  currentOrg,
}: AddCameraSimpleModalProps) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [message, setMessage] = React.useState<string>("");
  const [hubs, setHubs] = useState<Hub[] | undefined>([]);
  const [hub_id, setHubId] = useState(0);
  const [billing_rates, setBillingRates] = React.useState<
    OrgBillingRate[] | undefined
  >(undefined);
  const currentEnv = useRef("None");
  const [displayEnv, setDisplayEnv] = useState("None");
  const environment_list = [
    "None",
    "Commercial - exterior",
    "Commercial - interior",
    "Commercial - perimeter",
    "Farm",
    "High site / tower",
    "Parking area",
    "Public space - pedestrian",
    "Rapid deployment tower",
    "Residential - exterior",
    "Residential - interior",
    "Residential - perimeter",
    "Rural",
    "Shopping mall - interior",
    "Other",
  ];

  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && currentOrg && currentOrg.org_id) {
        var billing_rates =
          await appStore?.functionService.getBillingRatesForOrg(
            token,
            currentOrg.org_id,
            true
          );
        if (billing_rates) {
          setBillingRates(billing_rates);
        }
      }
    };
    fetchData();
  }, [open]);

  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && site?.site_id) {
        var hubs = await appStore?.functionService.getHubsForSite(
          token,
          site!.site_id
        );
        setHubs(hubs);
      }
    };
    fetchData();
  }, [site]);

  useEffect(() => {
    sanityCheck();
  }, [currentEnv]);

  const handleEnvironmentChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    var new_env = event.target.value as string;
    if (new_env === "Other") {
      var own_environment = prompt("Please enter a custom environment");
      new_env += " -> " + own_environment;
    }
    currentEnv.current = new_env;

    sanityCheck();
  };

  const sanityCheck = () => {
    if (currentEnv?.current?.includes("Other")) {
      setDisplayEnv("Other");
    } else if (currentEnv) {
      setDisplayEnv(currentEnv.current);
    } else {
      setDisplayEnv("None");
    }
  };

  const handleHubSelection = (event: React.ChangeEvent<any>) => {
    setHubId(event.target.value);
  };
  const handleNameChange = (event: React.ChangeEvent<any>) => {
    setName(event.target.value);
  };
  var data_origin_name = "";
  if (billing_rates && billing_rates.length > 0 && site) {
    let billingrate = billing_rates?.find(
      (x) => x.billing_rate_id == site.billing_rate_id
    );
    if (billingrate?.data_origin_name) {
      data_origin_name = billingrate?.data_origin_name;
    }
  }
  var password = "";
  if (site && site.meta && site.meta.smtp_password) {
    password = site.meta.smtp_password;
  }
  const saveCamera = (data_origin_name: string, password: string) => {
    if (hub_id == 0) {
      setMessage("Please select the hub the the camera is attached to!");
    } else {
      saveNewCameraSimple(
        hub_id,
        site!.site_id,
        name,
        data_origin_name,
        site!.name,
        password,
        currentEnv.current
      );
    }
  };

  var hublist = [];
  if (hubs && hubs.length > 0) {
    var hublistitems: React.ReactNode[] = [];
    hubs.forEach((hub) => {
      hublistitems.push(
        <MenuItem value={hub.hub_id}>
          <span className={classes.bold_text}>{hub.hub_id_str}</span>
        </MenuItem>
      );
    });
    if (hublistitems.length == 0) {
      hublistitems.push(
        <MenuItem>
          <span className={classes.bold_text}>No hubs for site</span>
        </MenuItem>
      );
    }
    hublist.push(
      <>
        <br></br>
        <FormControl fullWidth variant="outlined">
          <InputLabel className={classes.bold_text}>
            Select hub for camera
          </InputLabel>
          <Select
            name="hub-select"
            value={hub_id}
            onChange={(e) => handleHubSelection(e)}
          >
            {hublistitems}
          </Select>
        </FormControl>
      </>
    );
  }
  if (site === undefined) {
    return (
      <Dialog onClose={onClose} open={open} className={classes.modal_dialog}>
        <DialogTitle className={classes.bold_text}>
          No valid site to add sensor to!
        </DialogTitle>
        <br></br>
        <div className={classes.button_container}>
          <Button
            className={classes.cancel_button}
            variant="contained"
            onClick={() => onClose()}
          >
            <span className={classes.bold_text}>CANCEL</span>
          </Button>
        </div>
      </Dialog>
    );
  } else {
    if (appStore!.authService.getRoles().indexOf("site_admin") >= 0) {
      return (
        <Dialog open={open} className={classes.modal_dialog}>
          <DialogTitle className={classes.bold_text}>
            Add New Camera to {site.name}
          </DialogTitle>
          <Typography className={classes.bold_error_text}>{message}</Typography>

          {hublist}

          <TextField
            label="Camera Name"
            onChange={handleNameChange}
            value={name}
            variant="outlined"
            margin="normal"
            fullWidth
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
          />
          <br></br>
          <FormControl fullWidth={true} variant="outlined">
            <InputLabel
              id="demo-simple-select-outlined-label"
              className={classes.bold_text}
            >
              Select Environment
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={displayEnv}
              onChange={handleEnvironmentChange}
              label="Environment"
              style={{ width: 300 }}
            >
              {environment_list.map((option: string) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <div className={classes.button_container}>
            <Button
              variant="contained"
              className={classes.cancel_button}
              onClick={onClose}
            >
              <span className={classes.bold_text}>CANCEL</span>
            </Button>
            <Button
              variant="contained"
              className={classes.save_button}
              onClick={() => saveCamera(data_origin_name, password)}
            >
              <span className={classes.bold_text}>SAVE</span>
            </Button>
          </div>
        </Dialog>
      );
    } else {
      return (
        <Dialog onClose={onClose} open={open} className={classes.modal_dialog}>
          <DialogTitle className={classes.bold_text}>
            Add New Camera to {site.name}
          </DialogTitle>
          <br></br>
          <Typography className={classes.bold_text}>
            {
              "You need site admin role to be able to add a new Camera - please contact your DeepAlert system administrator"
            }
          </Typography>
          <div className={classes.button_container}>
            <Button
              className={classes.cancel_button}
              variant="contained"
              onClick={() => onClose()}
            >
              <span className={classes.bold_text}>CANCEL</span>
            </Button>
          </div>
        </Dialog>
      );
    }
  }
};

export default AddCameraSimpleModal;
