import React, { useEffect } from "react";
import { AppStore } from "../../../stores/AppStore";
import { getBaseUrl } from "../../HelperFunctions";
import { useStyles } from "../../styling/global/global";

interface Props {
  appStore: AppStore;
}

export const getBIBaseUrl = (): string => {
  let url = window.location.href;
  console.log("process.env.DA_BI_REPORTS: ", process.env.DA_BI_REPORTS);
  if (url.includes("local") || url.includes("staging")) {
    return "https://insights.staging.deepalert.ai";
  } else if (url.includes("preprod")) {
    return "https://insights.preprod.deepalert.ai";
  } else {
    return "https://insights.deepalert.ai";
  }
};

const BIPage: React.FC<Props> = ({ appStore }) => {
  const classes = useStyles();
  const [iframeSrc, setIframeSrc] = React.useState("");
  const [accessToken, setAccessToken] = React.useState("");
  // var token = await appStore?.authService.getAuthorisedToken();

  var baseUrl = getBaseUrl();
  useEffect(() => {
    const fetchIframeSrc = async () => {
      try {
        var token = await appStore?.authService.getAuthorisedToken();
        setAccessToken(token);
        // const res = await fetch(baseUrl + '/business_intelligence', {
        //   method: "GET",
        //   headers: {
        //     Accept: "application/json",
        //     Authorization: "Bearer " + token,
        //     "Content-Type": "application/json",
        //   },
        // });
        // const result = await (await res).json();

        // console.log("result %%", result)

        // if (result.status === 302 || result.status === 301) {
        //   const redirectedUrl = result.headers.get('Location');
        //   console.log('Redirected URL:', redirectedUrl);
        // setIframeSrc(redirectedUrl);
        // }
      } catch (error) {
        console.error("Error fetching token", error);
      }
    };

    fetchIframeSrc();
  }, []);

  console.log("iframeSrc: ", iframeSrc);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "rgba(246, 246, 246, 0.8)",
        // padding: 10,
        borderRadius: 8,
        marginTop: -20,
      }}
    >
      <div style={{ height: "100vh", width: "100%" }}>
        {accessToken ? (
          <iframe
            src={getBIBaseUrl() + `/?access_token=${accessToken}&embed=true`}
            // src={`/api/v1.3/business_intelligence/?access_token=${accessToken}&embed=true`}
            style={{ height: "100vh", width: "100%" }}
          ></iframe>
        ) : (
          <p>Loading</p>
        )}
      </div>
    </div>
  );
};

export default BIPage;
