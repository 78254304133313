import React, { useState, useEffect } from "react";
import { AppBar } from "@material-ui/core";
import { useStyles } from "../../../styling/global/global";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import BillingRuns from "../Billing/Billing";
import ProductSetup from "../ProductSetup/ProductSetup";
import BillingProfiles from "../BillingProfiles/BillingProfiles";
import { AppStore } from "../../../../stores/AppStore";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";
import BillingRegistration from "../BillingRegistration/BillingRegistration";

interface BillingMainContainerProps {
  appStore?: AppStore;
}
// Tabs Data and function
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const theme = createMuiTheme({
  overrides: {
    MuiTab: {
      root: {
        "&.Mui-selected": {
          background: "rgba(255,255,255,0.09)",
          color: "white",
          // fontSize: 15,
          fontWeight: "bold",
          // textTransform: 'capitalize',
          // opacity: 0.7,
        },
      },
    },
  },
});

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// end

const useStyle = makeStyles({
  paper: {
    border: "2px solid #6D809D",
    color: "#15325F",
  },
});
const BillingMainContainer: React.FC<BillingMainContainerProps> = ({
  appStore,
}: BillingMainContainerProps) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const [billingProfiles, setBillingProfiles] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const tabPanelItems = [
    {
      name: "billing profiles",
    },
    {
      name: "billing runs",
    },
    {
      name: "product setup",
    },
    { name: "registration" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        const billinglistResult =
          await appStore?.functionService.listBillingProfiles(token);
        if (billinglistResult?.success) {
          setBillingProfiles(billinglistResult?.billing_profiles);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, []);

  return (
    <div
      style={{
        width: "150%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <ThemeProvider theme={theme}>
        <AppBar position="static" className="navcolor">
          <Tabs
            indicatorColor="secondary"
            textColor="inherit"
            value={value}
            onChange={handleChange}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            centered
          >
            {/* {tabsmenu} */}
            {tabPanelItems.map((item, index) => {
              return (
                <Tab
                  label={item.name}
                  {...a11yProps(index)}
                  style={{
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: 12,
                    textTransform: "capitalize",
                  }}
                >
                  <span
                    className={classes.bold_text_white}
                    style={{ textTransform: "lowercase" }}
                  >
                    {item.name}
                  </span>
                </Tab>
              );
            })}
          </Tabs>
        </AppBar>
        {tabPanelItems.map((item, tabIndex) => {
          return (
            <TabPanel value={value} index={tabIndex}>
              <Typography
                className={classes.bold_text_white}
                style={{
                  textAlign: "center",
                  // textTransform: 'capitalize',
                  marginBottom: 20,
                  width: "100%",
                }}
              >
                {/* <>{item.name}</> */}
                {tabIndex === 0 ? (
                  <BillingProfiles appStore={appStore} />
                ) : tabIndex === 1 ? (
                  <BillingRuns appStore={appStore} />
                ) : tabIndex === 2 ? (
                  <ProductSetup appStore={appStore} />
                ) : (
                  <BillingRegistration appStore={appStore} />
                )}
              </Typography>
              <Divider style={{ marginBottom: 20 }} />
            </TabPanel>
          );
        })}
      </ThemeProvider>
    </div>
  );
};

export default BillingMainContainer;
