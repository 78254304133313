import React, { useState, useEffect, useRef, useCallback } from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useStyles } from "../../styling/global/global";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import { AppStore } from "../../../stores/AppStore";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Org } from "../../../services/FunctionService";
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import TableHead from "@material-ui/core/TableHead";
import LoadingSpinner from "../../../spinner/LoadingSpinner";
import Tooltip from "@material-ui/core/Tooltip";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface siteHealthModalProp {
  start_time_filter: number;
  end_time_filter: number;
  top_org_id: number;
  showModal: boolean;
  toogleFullScreenModal: () => void;
  healthParams: (string | number)[];
  appStore?: AppStore;
  setSelectedItem: (item: any, editing: boolean) => void;
  loading_main: (arg: boolean) => void;
}

interface siteProps {
  site_id: number;
  site_name: string;
  hub_id_str: string;
  overall_indicator: string;
  org_tree: number[];
  data_origin: string;
}

export const SiteHealthStats: React.FC<siteHealthModalProp> = ({
  start_time_filter,
  end_time_filter,
  top_org_id,
  showModal,
  toogleFullScreenModal,
  healthParams,
  appStore,
  setSelectedItem,
  loading_main,
}) => {
  const [overallIndicator, setOverallIndicator] = useState<string | null>(
    "error"
  );
  const [loading, setLoading] = useState(false);
  const [sites, setSites] = useState<siteProps[]>([]);
  const [message, setMessage] = useState("");

  const [unhealthy, setUnHealthy] = useState(true);
  const [degraded, setDegraded] = useState(false);
  const [healthy, setHealthy] = useState(false);
  const [unknown, setUnknown] = useState(false);

  const classes = useStyles();

  const getBaseUrl = (): string => {
    let base = "";
    if (window.location.href.includes("local")) {
      base = "https://manage.staging.deepalert.ai";
    }
    base += "/api/v1.3";
    return base;
  };

  const getOrgs = async (org_id: number) => {
    if (org_id) {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var rest_org = await appStore?.functionService.getOrgOneLevel(
          token!,
          org_id
        );
        return rest_org;
      }
    }
  };

  //function that gets passed down the application to be used to change a current org, i.e. click on breadcrumbs or on sub-group
  async function changeOrg(
    org_id: number,
    item: {
      site_id: number;
      type: string;
      site_name: string;
    },
    complete: boolean
  ) {
    if (org_id) {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var rest_org = await appStore?.functionService.getOrgOneLevel(
          token!,
          org_id
        );

        if (rest_org) {
          if (rest_org.orgs == undefined) {
            rest_org.orgs = [];
          }
          await appStore?.authService.setBreadcrumbOrgs(rest_org);

          if (complete) {
            setSelectedItem(item, false);
            // loading_main(false);
          }
        }
        loading_main(false);
      }
    }
  }

  async function setBreadcrumsbHistory(
    org_tree_ids: number[],
    item: {
      name: string;
      site_id: number;
      type: string;
      site_name: string;
      // data_origin: string;
    },
    total_items: number
  ) {
    let breadcrumbs_orgs: Org[] = [];

    if (org_tree_ids.length > 1) {
      org_tree_ids.forEach(async (id: number, i: number) => {
        if (i == total_items - 1) {
          await changeOrg(id, item, true);
          return setTimeout(() => {
            setSelectedItem(item, true);
            loading_main(false);
          }, 100);
        }

        let item2 = await getOrgs(id);
        breadcrumbs_orgs.push(item2);
      });
    } else if (org_tree_ids.length == 1) {
      await changeOrg(org_tree_ids[0], item, true);
      setSelectedItem(item, false);
      loading_main(false);
    } else {
      setSelectedItem(item, false);
      loading_main(false);
    }
  }

  const disableButton = (status: string) => {
    if (status === "unhealthy") {
      setUnHealthy(true);
      setDegraded(false);
      setHealthy(false);
      setUnknown(false);
    }
    if (status === "healthy") {
      setHealthy(true);
      setUnHealthy(false);
      setDegraded(false);
      setUnknown(false);
    }
    if (status === "degraded") {
      setDegraded(true);
      setUnHealthy(false);
      setHealthy(false);
      setUnknown(false);
    }
    if (status === "unknown") {
      setUnknown(true);
      setDegraded(false);
      setUnHealthy(false);
      setHealthy(false);
    }
  };

  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        "&:hover": {
          backgroundColor: "#e3f2fd",
          cursor: "pointer",
        },
      },
    })
  )(TableRow);

  const fetchSites = async () => {
    try {
      setLoading(true);
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        const request = JSON.stringify({
          top_org_id: top_org_id,
          start_time_filter: start_time_filter,
          end_time_filter: end_time_filter,
          overall_indicator: !!overallIndicator ? overallIndicator : "error",
        });
        const response = await fetch(getBaseUrl() + "/site_health_history", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });

        if (!response.ok) {
          setMessage("Error fetching data, please try again");
        } else {
          const result = (await (await response).json()) as any;
          let transformedData = result.msg.map((site: any) => {
            let org_tree_ids = result.site_list.find(
              (item: any) => item.site_id === site.site_id
            ).org_tree;
            let data_origin = result.site_list.find(
              (item: any) => item.site_id === site.site_id
            ).data_origin;
            if (site.overall_indicator === "ok") {
              return {
                site_id: site.site_id,
                site_name: site.site_name,
                hub_id_str: site.hub_id_str,
                overall_indicator: "Healthy",
                org_tree: org_tree_ids,
                data_origin: data_origin,
              };
            }
            if (site.overall_indicator === "warning") {
              return {
                site_id: site.site_id,
                site_name: site.site_name,
                hub_id_str: site.hub_id_str,
                overall_indicator: "Degraded",
                org_tree: org_tree_ids,
                data_origin: data_origin,
              };
            }
            if (site.overall_indicator === "error") {
              return {
                site_id: site.site_id,
                site_name: site.site_name,
                hub_id_str: site.hub_id_str,
                overall_indicator: "unHealthy",
                org_tree: org_tree_ids,
                data_origin: data_origin,
              };
            }
            if (site.overall_indicator === "unknown") {
              return {
                site_id: site.site_id,
                site_name: site.site_name,
                hub_id_str: site.hub_id_str,
                overall_indicator: "unKnown",
                org_tree: org_tree_ids,
                data_origin: data_origin,
              };
            }
          });
          setSites(transformedData);
        }

        setLoading(false);
      }

      // setLoading(false);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const getEpochTime = () => {
    var d = start_time_filter * 1000;
    var date = new Date(d).toLocaleDateString();
    var time = new Date(d).toLocaleString("en-GB", {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
    return `${time}       (${date})`;
  };

  useEffect(() => {
    fetchSites();
  }, [overallIndicator, start_time_filter]);

  var sitesListDisplay: React.ReactNode[] = [];
  if (sites && sites.length > 0) {
    sites.forEach((site: { [key: string]: any }, index: any) => {
      sitesListDisplay.push(
        <StyledTableRow
          onClick={(e) => {
            console.log("<Clicked Selected item>", {
              site_id: site.site_id,
              type: "site",
              site_name: site.site_name,
              name: site.site_name,
              data_origin: site.data_origin,
            });
            handleClose();
            loading_main(true);
            setBreadcrumsbHistory(
              site.org_tree,
              {
                site_id: site.site_id,
                type: "site",
                site_name: site.site_name,
                name: site.site_name,
                // data_origin: site.data_origin,
              },
              site.org_tree.length
            );
          }}
        >
          <TableCell>
            <Typography className={classes.bold_text}>
              {site.site_name}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {site.hub_id_str}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {site.overall_indicator}
            </Typography>
          </TableCell>
        </StyledTableRow>
      );
    });
  }

  const handleClickOpen = () => {
    setOverallIndicator(null);
    toogleFullScreenModal();
    // setOpen(true);
  };

  const handleClose = () => {
    setOverallIndicator(null);
    setSites([]);
    sitesListDisplay = [];
    disableButton("unhealthy");
    toogleFullScreenModal();
    // setOpen(false);
  };

  // Sorting Table's Data Function
  const sortByHandler = (orderBy: string | unknown, direction: boolean) => {
    switch (orderBy) {
      case "Site":
        if (direction) {
          const list = [...sites].sort((a, b): number => {
            if (b.site_name.toLowerCase() > a.site_name.toLowerCase()) {
              return -1;
            }
            if (b.site_name.toLowerCase() < a.site_name.toLowerCase()) {
              return 1;
            }
            return 0;
          });

          let newArr = [...list];
          setSites(newArr);
          return;
        } else {
          const list = [...sites].sort((a, b): number => {
            if (b.site_name.toLowerCase() > a.site_name.toLowerCase()) {
              return 1;
            }
            if (b.site_name.toLowerCase() < a.site_name.toLowerCase()) {
              return -1;
            }
            return 0;
          });
          let newArr = [...list];
          setSites(newArr);
          return;
        }
      case "Hub":
        if (direction) {
          const list = [...sites].sort((a, b): number => {
            if (b.hub_id_str.toLowerCase() > a.hub_id_str.toLowerCase()) {
              return -1;
            }
            if (b.hub_id_str.toLowerCase() < a.hub_id_str.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          let newArr = [...list];
          setSites(newArr);
          return;
        } else {
          const list = [...sites].sort((a, b): number => {
            if (b.hub_id_str.toLowerCase() > a.hub_id_str.toLowerCase()) {
              return 1;
            }
            if (b.hub_id_str.toLowerCase() < a.hub_id_str.toLowerCase()) {
              return -1;
            }
            return 0;
          });
          let newArr = [...list];
          setSites(newArr);
          return;
        }
    }
  };

  const handleOrderChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    let order = e.target.value;

    if (order === "Site ASC") {
      sortByHandler("Site", true);
    }

    if (order === "Site DSC") {
      sortByHandler("Site", false);
    }

    if (order === "Hub ASC") {
      sortByHandler("Hub", true);
    }

    if (order === "Hub DSC") {
      sortByHandler("Hub", false);
    }
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={classes.button_container}
        >
          {"Selected Time -  "} {getEpochTime()}
        </DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              htmlFor="outlined-age-native-simple"
              className={classes.bold_text}
            >
              &nbsp; ORDER BY &nbsp;
            </InputLabel>
            <Select
              native
              defaultValue={"Group ASC!"}
              onChange={(e) => handleOrderChange(e)}
              label="Order By"
              inputProps={{
                name: "Order By",
                id: "outlined-age-native-simple",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"Site ASC"}>Site (Ascending)</option>
              <option value={"Site DSC"}>Site (Descending)</option>
              <option value={"Hub ASC"}>Hub (Ascending)</option>
              <option value={"Hub DSC"}>Hub (Descending)</option>
            </Select>
          </FormControl>
        </div>
        <DialogContent>
          <div
            style={{
              boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
              marginTop: 15,
              borderRadius: 10,
              overflow: "hidden",
            }}
          >
            <TableContainer component={Paper}>
              <Table size="small" stickyHeader>
                {!loading && (
                  <TableHead>
                    <TableRow>
                      <TableCell>Site Name</TableCell>
                      <TableCell>Hub Name</TableCell>
                      <TableCell>Site Health</TableCell>
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {!loading ? (
                    !!sitesListDisplay.length ? (
                      sitesListDisplay
                    ) : (
                      <p className={classes.bold_text}>
                        {" "}
                        &nbsp; &nbsp;No data found
                      </p>
                    )
                  ) : (
                    // <StyledTableRow>
                    <TableCell>
                      <div
                        className={classes.bold_text}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <LoadingSpinner />
                      </div>
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </DialogContent>
        <br></br>
        <DialogActions>
          <Tooltip
            title="List disconnected sites only"
            aria-label="View Fullscreen"
            arrow
          >
            <Button
              disabled={unhealthy}
              className={classes.mute_button}
              onClick={(e) => {
                setOverallIndicator("error");
                disableButton("unhealthy");
              }}
              color="primary"
            >
              disconnected
            </Button>
          </Tooltip>
          <Tooltip
            title="List degraded sites only"
            aria-label="View Fullscreen"
            arrow
          >
            <Button
              disabled={degraded}
              className={classes.mute_button}
              onClick={(e) => {
                setOverallIndicator("warning");
                disableButton("degraded");
              }}
              color="primary"
            >
              degraded
            </Button>
          </Tooltip>
          <Tooltip
            title="List healthy sites only"
            aria-label="View Fullscreen"
            arrow
          >
            <Button
              disabled={healthy}
              className={classes.mute_button}
              onClick={(e) => {
                setOverallIndicator("ok");
                disableButton("healthy");
              }}
              color="primary"
            >
              healthy
            </Button>
          </Tooltip>
          <Tooltip
            title="List unknown healthy for sites only"
            aria-label="View Fullscreen"
            arrow
          >
            <Button
              disabled={unknown}
              className={classes.mute_button}
              onClick={(e) => {
                setOverallIndicator("unknown");
                disableButton("unknown");
              }}
              color="primary"
            >
              unKnown
            </Button>
          </Tooltip>
          <Button
            className={classes.cancel_button2}
            onClick={handleClose}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
