import React, { useState, useEffect } from "react";
import { Typography, Box, Snackbar, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { AppStore } from "../../../../stores/AppStore";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";
import { useStyles } from "../../../styling/global/global";
import { CreateUserAlertChannelResult } from "../../../../services/FunctionService";

interface ConfigureHikProps {
  alert_channel_end_point_id: number;
  handleClose: () => void;
  onBack: () => void;
  appStore?: AppStore;
}

const ConfigureHik: React.FC<ConfigureHikProps> = ({
  alert_channel_end_point_id,
  handleClose,
  onBack,
  appStore,
}: ConfigureHikProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [resultSuccess, setResultSuccess] = useState(false);
  const [resultFailure, setResultFailure] = useState(false);
  const [showConfigureButton, setShowConfigureButton] = useState(false);
  const [resultMessage, setResultMessage] = useState("");

  const handleConfigure = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const configureResults: CreateUserAlertChannelResult =
        await appStore?.functionService.configureHikCentralEventRules(
          token,
          alert_channel_end_point_id
        );
      if (configureResults) {
        if (configureResults.success) {
          setResultSuccess(true);
          setLoading(false);
        } else {
          setResultFailure(true);
          setLoading(false);
        }
      }
    }
  };
  const fetchHikCentralListingTest = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    setLoading(true);
    if (token) {
      const hikResult = await appStore?.functionService.listHikCentralEntities(
        token,
        alert_channel_end_point_id,
        "camera",
        1,
        10
      );
      if (hikResult) {
        if (hikResult.success) {
          setShowConfigureButton(true);
          setLoading(false);
        } else {
          setShowConfigureButton(false);
          setLoading(false);
        }
      }
    }
  };
  useEffect(() => {
    fetchHikCentralListingTest();
  }, [alert_channel_end_point_id]);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Box justifyContent="center">
            <span
              className={classes.bold_text}
              style={{ fontSize: 15, color: "primary" }}
            >
              Configure event rules
            </span>
          </Box>

          {resultSuccess ? (
            <Box mt={2} justifyContent="center">
              <Alert
                variant="outlined"
                severity="success"
                onClose={() => setResultSuccess(false)}
              >
                Configuration successful
              </Alert>
            </Box>
          ) : null}
          {resultFailure ? (
            <Box mt={2} justifyContent="center">
              <Alert
                variant="outlined"
                severity="error"
                onClose={() => setResultFailure(false)}
              >
                Configuration failed
              </Alert>
            </Box>
          ) : null}
          <div
            style={{ marginBottom: 20, marginTop: 20, width: "100%" }}
            className={classes.twoButtonsInARow}
          >
            <Button
              style={{ marginRight: 10 }}
              variant="contained"
              className={classes.mute_button}
              onClick={() => onBack()}
            >
              Back
            </Button>
            {showConfigureButton ? (
              <Button
                style={{ marginLeft: 10 }}
                variant="contained"
                className={classes.mute_button}
                onClick={() => handleConfigure()}
              >
                Configure
              </Button>
            ) : null}

            <Button
              style={{ marginLeft: 10 }}
              variant="contained"
              className={classes.mute_button}
              onClick={handleClose}
            >
              Close
            </Button>

            {resultSuccess ? (
              <Button
                style={{ marginLeft: 10 }}
                variant="contained"
                className={classes.mute_button}
                onClick={handleClose}
              >
                Finish
              </Button>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default ConfigureHik;
