import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import React from "react";
import "./CameraMainDrawer.css";
import { MainContext } from "../../containers/HomePage/HomePage";

import { useRecoilState } from "recoil";

import { deliveryEndpointsState } from "./Zone";

interface DataType {
  key: string;
  class_label: string;
  zone: number;
}
interface Props {
  site?: any;
}

const Summary: React.FC<Props> = ({ site }) => {
  const ctx = React.useContext(MainContext);
  const [endpoints] = useRecoilState(deliveryEndpointsState);

  let columns: ColumnsType<DataType> = [
    {
      title: "Zones",
      dataIndex: "zone",
      key: "zone",
    },
    {
      title: "Class Labels",
      dataIndex: "class_label",
      key: "class_label",
    },
    {
      title: "Confidence Threshold",
      dataIndex: "confidence_threshold",
      key: "confidence_threshold",
    },
    {
      title: "Object Motion",
      dataIndex: "object_motion",
      key: "object_motion",
    },
  ];

  if (ctx.rule_type === "PPE Detection - coming soon!") {
    columns.pop();
    columns.push({
      title: "Colors",
      dataIndex: "colors",
      key: "colors",
    });
  }

  if (
    ctx.rule_type === "alert_zones_and_counts" ||
    ctx.rule_type === "Tailgating - coming soon!"
  ) {
    columns = [
      {
        title: "Zones",
        dataIndex: "zone",
        key: "zone",
      },
      {
        title: "Class Labels",
        dataIndex: "class_label",
        key: "class_label",
      },
      {
        title: "Confidence Threshold",
        dataIndex: "confidence_threshold",
        key: "confidence_threshold",
      },
      {
        title: "Object Motion",
        dataIndex: "object_motion",
        key: "object_motion",
      },
      {
        title: "Count",
        dataIndex: "count",
        key: "count",
      },
      {
        title: "Count Logic",
        dataIndex: "count_logic",
        key: "count_logic",
      },
    ];
  }

  if (ctx.rule_type === "Known Vehicle Filter") {
    columns = [
      {
        title: "Zones",
        dataIndex: "zone",
        key: "zone",
      },
    ];
  }

  if (ctx.rule_type.includes("suspicious")) {
    columns = [
      {
        title: "Zones",
        dataIndex: "zone",
        key: "zone",
      },
      {
        title: "Object Motion",
        dataIndex: "object_motion",
        key: "object_motion",
      },
    ];
  }

  if (ctx.rule_type.includes("Guard Management (no guard)")) {
    columns = [
      {
        title: "Zones",
        dataIndex: "zone",
        key: "zone",
      },
      {
        title: "Rule Type",
        dataIndex: "rule_type",
        key: "rule_type",
      },
      // {
      //   title: "Fetch Interval",
      //   dataIndex: "cadence_secs",
      //   key: "cadence_secs",
      // },
    ];
  }

  const deliveryEndpointNames =
    (endpoints.length > 0 &&
      endpoints
        .filter(
          (item: any) =>
            ctx.endpointIds.indexOf(item.alert_channel_end_point_id) > -1
        )
        .map((item: any) => `${item.name}, `)) ||
    [];
  return (
    <div>
      <div className="summary_wrapper">
        <h2>CONFIRM DETAILS</h2>
        <p>
          Rule's Name: &nbsp;&nbsp;{" "}
          {ctx.rule_name ? (
            <span>{ctx.rule_name.replaceAll("_", " ")}</span>
          ) : (
            <span style={{ color: "red" }}>missing...</span>
          )}
        </p>
        <p style={{ maxWidth: 600 }}>
          Delivery Endpoints: &nbsp;&nbsp;{" "}
          {ctx.endpointIds.length > 0 ? (
            // <span>{ctx.endpointIds}</span>
            <span>{deliveryEndpointNames}</span>
          ) : (
            <span style={{ color: "red" }}>All</span>
          )}
        </p>
        {ctx.whitelistName && (
          <p>
            Whitelist Name: &nbsp; <span>{ctx.whitelistName?.name}</span>
          </p>
        )}
        <p>
          Environment: &nbsp;&nbsp; <span>{ctx.rule_environment}</span>
        </p>
        {ctx.rule_type ? (
          <p>
            Type of Rule: &nbsp;&nbsp; <span>{ctx.rule_type}</span>
          </p>
        ) : (
          <span style={{ color: "red" }}>missing...</span>
        )}
        {ctx.scheduled && (
          <p>
            Rule's Time: &nbsp; <span>Scheduled</span>
          </p>
        )}
        {(ctx.rule_type === "Guard Management (no guard)" &&
          site?.data_origin !== "sentinel_http_edge_human1") ||
        (ctx.rule_type === "Guard Tour" &&
          site?.product?.includes("EdgePlus")) ||
        (ctx.rule_type === "Scene Change Alert" &&
          site?.product?.includes("EdgePlus")) ||
        ctx.rule_type === "unusual_activity" ? (
          <p>
            Fetch Interval:-- &nbsp; <span>{ctx.fetchInterval}</span>
          </p>
        ) : null}

        {!ctx.scheduled && (
          <p>
            Rule's Time: &nbsp; <span> always active</span>
          </p>
        )}
      </div>
      <div>
        <br />
        {ctx.rule_type !== "Scene Change Alert" &&
          ctx.rule_type !== "unusual_activity" &&
          ctx.rule_type !== "snapshot" && (
            <Table
              style={{ borderRadius: 15 }}
              columns={columns}
              dataSource={ctx.zone_config}
            />
          )}
      </div>
    </div>
  );
};

export default Summary;
