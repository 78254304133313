import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useStyles } from "../../styling/global/global";
interface CameraMenuProps {
  muted: boolean;
  del: boolean;
  edit: boolean;
  enable: boolean;
  enabled: boolean;
  snapshot: boolean;
  clone: boolean;
  wizard: boolean;
  handleEdit: () => void;
  handleEnable: () => void;
  handleDelete: () => void;
  handleSnapshot: () => void;
  handleClone: () => void;
  handleWizard: () => void;
  handleCameraMute: () => void;
  handleCameraUnMute: () => void;
  mutedLength: number;
}
const CameraMenu: React.FC<CameraMenuProps> = ({
  muted,
  del,
  edit,
  enable,
  enabled,
  snapshot,
  mutedLength,
  clone,
  wizard,
  handleClone,
  handleEdit,
  handleEnable,
  handleDelete,
  handleSnapshot,
  handleWizard,
  handleCameraMute,
  handleCameraUnMute,
}: CameraMenuProps) => {
  const classes = useStyles();
  const [, setUnmute] = React.useState<boolean>(muted);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const enabletext = enabled ? "Disable" : "Enable";
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const MuteStatusHandler = () => {
    handleCameraUnMute();
    setAnchorEl(null);
    setUnmute(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const Edit = () => {
    setAnchorEl(null);
    handleEdit();
  };
  const Snapshot = () => {
    setAnchorEl(null);
    handleSnapshot();
  };
  const Enable = () => {
    setAnchorEl(null);
    handleEnable();
  };
  const Delete = () => {
    setAnchorEl(null);
    handleDelete();
  };
  const Clone = () => {
    setAnchorEl(null);
    handleClone();
  };
  const Wizard = () => {
    setAnchorEl(null);
    handleWizard();
  };
  const CameraMuting = () => {
    setAnchorEl(null);
    handleCameraMute();
  };

  return (
    <React.Fragment>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {clone ? (
          <MenuItem onClick={Clone}>
            <span className={classes.bold_text}>Clone</span>
          </MenuItem>
        ) : undefined}
        {wizard ? (
          <MenuItem onClick={Wizard}>
            <span className={classes.bold_text}>Wizard</span>
          </MenuItem>
        ) : undefined}
        {snapshot ? (
          <MenuItem onClick={Snapshot}>
            <span className={classes.bold_text}>Snapshot</span>
          </MenuItem>
        ) : undefined}
        {edit ? (
          <MenuItem onClick={Edit}>
            <span className={classes.bold_text}>Edit</span>
          </MenuItem>
        ) : undefined}
        {enable ? (
          <MenuItem onClick={Enable}>
            <span className={classes.bold_text}>{enabletext}</span>
          </MenuItem>
        ) : undefined}
        {del ? (
          <MenuItem onClick={Delete}>
            <span className={classes.bold_text}>Delete</span>
          </MenuItem>
        ) : undefined}
        {!muted && (
          <MenuItem onClick={CameraMuting}>
            <span className={classes.bold_text}>Mute Camera</span>
          </MenuItem>
        )}
        {muted && (
          <MenuItem onClick={MuteStatusHandler}>
            <span className={classes.bold_text}>Unmute Camera</span>
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  );
};
export default CameraMenu;
