import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import { Typography } from "@material-ui/core";

import { useStyles } from "../../../styling/global/global";
import { AppStore } from "../../../../stores/AppStore";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";

import {
  listBillingRunsItems,
  listBillingProfileOrgsItems,
} from "../../../../services/FunctionService";
interface SubmitClaculationsProps {
  appStore?: AppStore;
  open: boolean;
  onClose: () => void;
  billingRun: listBillingRunsItems | undefined;
  finalFunc: (billing_run_id: number) => void;
}

const FinaliseRun: React.FC<SubmitClaculationsProps> = ({
  open,
  onClose,
  appStore,
  billingRun,
  finalFunc,
}: SubmitClaculationsProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [renderValue, setRenderValue] = useState(false);
  const [viewProfileOrgsData, setProfileOrgsViewData] = useState<
    listBillingProfileOrgsItems[] | undefined
  >(undefined);
  const [runError, setRunError] = useState(false);
  const [orgsError, setOrgsError] = useState(false);
  const [orgsName, setOrgsName] = React.useState<string[]>([]);
  const [orgIds, setOrgIds] = React.useState<number[]>([]);
  const [runId, setRunId] = React.useState<number | undefined>(undefined);

  useEffect(() => {}, [renderValue]);

  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
        fullWidth={true}
      >
        <DialogTitle
          style={{ alignSelf: "center" }}
          className={classes.bold_text}
          id="simple-dialog-title"
        >
          <span style={{ fontSize: "25px" }}>
            Finalise Billing Run for {billingRun ? billingRun.name : ""}
          </span>
        </DialogTitle>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {billingRun?.status === "finalised" ? (
              <Typography className={classes.bold_text}>
                Billing Run already finalised
              </Typography>
            ) : (
              <Typography className={classes.bold_text}>
                Are you sure you want to finalise billing for{" "}
                {billingRun ? billingRun.name : ""}
              </Typography>
            )}
          </div>
        )}
        <div
          style={{ marginTop: "5%", marginBottom: "5%" }}
          className={classes.twoButtonsInARow}
        >
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => {
              setRunId(undefined);
              setOrgsName([]);
              setRunError(false);
              setOrgsError(false);
              setRenderValue((value) => !value);
              onClose();
            }}
          >
            <span className={classes.bold_text}>Dismiss</span>
          </Button>
          {billingRun?.status === "finalised" ? null : (
            <Button
              variant="contained"
              className={classes.cancel_button}
              onClick={() => {
                if (billingRun) {
                  finalFunc(billingRun.id);
                  onClose();
                  setRenderValue((value) => !value);
                }
              }}
            >
              <span className={classes.bold_text}>Submit</span>
            </Button>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default FinaliseRun;
