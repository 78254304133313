import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "../../../styling/global/global";

import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { listBillingRatesItems } from "../../../../services/FunctionService";

interface ViewProductInfoProps {
  open: boolean;
  productData: listBillingRatesItems | undefined;
  onClose: () => void;
}

const ViewProductInfo: React.FC<ViewProductInfoProps> = ({
  open,
  onClose,
  productData,
}: ViewProductInfoProps) => {
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        display: "flex",
        justifyContent: "space-between",
      },
    })
  )(TableRow);

  const StyledTableRowSMTP = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        display: "flex",
        justifyContent: "space-between",
        textAlign: "left",
      },
    })
  )(TableRow);

  const classes = useStyles();
  const [renderValue, setRenderValue] = useState(false);
  const [billingRateItems, setBillingRateItems] = useState<
    listBillingRatesItems | undefined
  >(undefined);

  useEffect(() => {
    if (productData) {
      setBillingRateItems(productData);
    }
  }, [renderValue, productData]);

  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
        maxWidth="md"
      >
        <DialogTitle
          style={{ alignSelf: "center" }}
          className={classes.bold_text}
          id="simple-dialog-title"
        >
          <span style={{ fontSize: "20px", color: "black" }}>
            Product information for {billingRateItems?.product}
          </span>
        </DialogTitle>

        <TableContainer>
          <StyledTableRow>
            <TableCell>
              <Typography className={classes.bold_text}>Data Origin</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.bold_text}>
                {billingRateItems?.data_origin.name}
              </Typography>
            </TableCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell>
              <Typography className={classes.bold_text}>
                Product Name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.bold_text}>
                {billingRateItems?.product}
              </Typography>
            </TableCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell>
              <Typography className={classes.bold_text}>Currency</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.bold_text}>
                {billingRateItems?.rate_info.currency}
              </Typography>
            </TableCell>
          </StyledTableRow>
        </TableContainer>

        <div
          style={{ marginTop: "5%", marginBottom: "5%" }}
          className={classes.twoButtonsInARow}
        >
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => {
              onClose();
              setRenderValue((value) => !value);
            }}
          >
            <span className={classes.bold_text}>Dismiss</span>
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default ViewProductInfo;
