import React, { useState } from "react";
import { Card, CardContent, CardHeader, IconButton } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {
  TimeEntry,
  TimeEntryType,
  TimeEntrySet,
} from "../../../core/models/TimeEntry";
import { Delete, AddCircle } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import TimePicker from "../TimeEntryUI/TimePicker";
import moment from "moment-timezone";
import { useStyles } from "../../styling/global/global";
interface TimeEntryProps {
  timeEntry: TimeEntry;
  disabled: boolean;
  addSchedule: boolean;
  timeEntries: TimeEntrySet;
  changeTimeEntry: (timeEntry: TimeEntry) => void;
  handleScheduleDeleteClicked: (timeEntry: TimeEntry) => void;
  handleAdd: () => void;
  addicon: boolean;
  noEndtime: boolean;
}

const TimeEntryUI: React.FC<TimeEntryProps> = ({
  addicon,
  timeEntry,
  disabled,
  addSchedule,
  changeTimeEntry,
  handleScheduleDeleteClicked,
  handleAdd,
  noEndtime,
}: TimeEntryProps) => {
  const classes = useStyles();
  const [refresh, setRefresh] = React.useState(0);

  if (!timeEntry) {
    return null;
  }
  const AddFiveSecondsToDateString = (str: string): string => {
    let date = moment(str, "HH:mm:ss").add(5, "seconds").format("HH:mm:ss");
    return date;
  };
  var utils = new DateFnsUtils();
  const handleEndTimeChange = (strTime: string) => {
    timeEntry.setTime(strTime!, TimeEntryType.EndTime);
    changeTimeEntry(timeEntry);
    setRefresh(new Date().getTime());
  };
  const handleStartTimeChange = (strTime: string) => {
    timeEntry.setTime(strTime!, TimeEntryType.StartTime);
    changeTimeEntry(timeEntry);
    setRefresh(new Date().getTime());
    if (noEndtime) {
      // let newTime = AddFiveSecondsToDateString(strTime);
      // timeEntry.setTime(newTime!, TimeEntryType.EndTime);
      changeTimeEntry(timeEntry);
      setRefresh(new Date().getTime());
    }
  };

  var addNewSchedule: React.ReactNode[] = [];
  //Only add the  addSchedule link if this is the last entry in the array and if addicon = true
  if (addSchedule && addicon && !disabled) {
    addNewSchedule.push(
      <div className={classes.form_component}>
        <div className={classes.bold_text}>
          Add Schedule
          <IconButton
            edge="end"
            aria-label="add"
            onClick={() => {
              handleAdd();
            }}
          >
            <AddCircle color={"primary"} />
          </IconButton>
        </div>
      </div>
    );
  }
  return (
    <>
      <Card className={classes.schedule_card}>
        <CardHeader
          action={
            !disabled ? (
              <IconButton
                edge="end"
                aria-label="delete"
                disabled={disabled}
                onClick={() => {
                  handleScheduleDeleteClicked(timeEntry);
                }}
              >
                <Delete color={disabled ? "disabled" : "error"} />
              </IconButton>
            ) : null
          }
          title={
            <div>
              <TimePicker
                disabled={disabled}
                label={noEndtime === true ? "Scheduled Time" : "Start Time"}
                id="start-time"
                value={timeEntry.startTime}
                handleTimeChange={(strTime: string) =>
                  handleStartTimeChange(strTime)
                }
              />
              {noEndtime === true ? (
                <div></div>
              ) : (
                <TimePicker
                  disabled={disabled}
                  label="End Time"
                  id="end-time"
                  value={timeEntry.endTime}
                  handleTimeChange={(strTime: string) =>
                    handleEndTimeChange(strTime)
                  }
                />
              )}
            </div>
          }
        ></CardHeader>
        <CardContent>
          <ToggleButtonGroup
            value={timeEntry.days}
            onChange={(ev, days: string[]) => {
              timeEntry.setDays(days);
              changeTimeEntry(timeEntry);
              setRefresh(new Date().getTime());
            }}
          >
            <ToggleButton disabled={disabled} value="Mon">
              M
            </ToggleButton>
            <ToggleButton disabled={disabled} value="Tue">
              T
            </ToggleButton>
            <ToggleButton disabled={disabled} value="Wed">
              W
            </ToggleButton>
            <ToggleButton disabled={disabled} value="Thu">
              T
            </ToggleButton>
            <ToggleButton disabled={disabled} value="Fri">
              F
            </ToggleButton>
            <ToggleButton disabled={disabled} value="Sat">
              Sa
            </ToggleButton>
            <ToggleButton disabled={disabled} value="Sun">
              S
            </ToggleButton>
          </ToggleButtonGroup>
        </CardContent>
      </Card>
      {addNewSchedule}
    </>
  );
};
export default TimeEntryUI;
