import React from "react";

import DashboardRightDrawer from "../../components/DashboardRightDrawer/DashboardRightDrawer";

import {
  MainMenu,
  SiteHealth,
  Activity,
  SensorHealth,
  Org,
  AlertChannel,
  HealthTimelineCount,
  SensorHealthTimelineCount,
} from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";

interface RightDrawerProps {
  isDashboard?: boolean;
  mid?: any;
  type?: string;
  site_id?: number;
  showFullModalHandler: (params: (number | string)[]) => void;
  siteHealthModal: (params: (number | string)[], site_id?: number) => void;
  setIntervalvalue: (x: number) => void;
  health?: SiteHealth[];
  healthtimeline?: HealthTimelineCount[];
  sensorhealthtimeline?: SensorHealthTimelineCount[];
  activity?: Activity;
  sensorhealth?: SensorHealth;
  interval: number;
  mainMenu: MainMenu;
}
const RightDrawer: React.FC<RightDrawerProps> = ({
  isDashboard,
  mid,
  type,
  site_id,
  siteHealthModal,
  mainMenu,
  setIntervalvalue,
  interval,
  health,
  healthtimeline,
  sensorhealthtimeline,
  activity,
  sensorhealth,
  showFullModalHandler,
}: RightDrawerProps) => {
  const classes = useStyles();

  if (
    mainMenu.sites ||
    mainMenu.usereditor ||
    mainMenu.profile ||
    mainMenu.rulesets
  ) {
    return <p className="sidenav-text"></p>;
  } else {
    if (sensorhealth && sensorhealthtimeline) {
      return (
        <DashboardRightDrawer
          isDashboard={isDashboard}
          mid={mid}
          type={type}
          sensor_id_str={sensorhealth.sensor_id_str}
          setIntervalvalue={(x: number) => setIntervalvalue(x)}
          interval={interval}
          sensorhealth={sensorhealth}
          health={health}
          activity={activity}
          healthtimeline={healthtimeline}
          sensorhealthtimeline={sensorhealthtimeline}
          showFullModalHandler={showFullModalHandler}
          siteHealthModal={siteHealthModal}
          site_id={site_id}
        ></DashboardRightDrawer>
      );
    } else {
      return (
        <DashboardRightDrawer
          isDashboard={isDashboard}
          mid={mid}
          type={type}
          showFullModalHandler={showFullModalHandler}
          sensor_id_str={undefined}
          setIntervalvalue={(x: number) => setIntervalvalue(x)}
          interval={interval}
          sensorhealth={sensorhealth}
          health={health}
          activity={activity}
          healthtimeline={healthtimeline}
          sensorhealthtimeline={sensorhealthtimeline}
          siteHealthModal={siteHealthModal}
          site_id={site_id}
        ></DashboardRightDrawer>
      );
    }
  }
};

export default RightDrawer;
