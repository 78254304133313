import React from "react";
import type from "prop-types";
import Line from "./handlers/line";
import Polygon from "./handlers/polygon";
import Rectangle from "./handlers/rectangle";
import canvasHandler from "./handlers/canvasHandler";
import History from "./handlers/history";
import { Button as AntButton, message, Tooltip, Select } from "antd";
import { leastIndex } from "d3";

const tools = {
  Line: Line,
  Polygon: Polygon,
  Rectangle: Rectangle,
};

const INITIAL_STATE = {
  Line: [],
};

/**
 * @componentName DrawCanvas
 * @description: This is a component to draw
 * shapes on canvas
 */

class DrawCanvas extends React.PureComponent {
  state = {
    undoData: [],
    redoData: [],
    data: INITIAL_STATE,
    canvasData: [],
    polygonId: canvasHandler.uuid(),
    rectangleId: canvasHandler.uuid(),
    mousedown: 0,
    count: 0,
    showDeleteIcon: false, // Add this line
    current_point: undefined,
    listZones: [],
    clearCanvas: false,
  };

  isInside(point) {
    // ray-casting algorithm based on
    // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

    var x = point.x,
      y = point.y;

    var inside = false;
    var insidepolygon;
    for (let key in this.state.data) {
      const polygon = this.state.data[key];
      for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        var xi = polygon[i][0],
          yi = polygon[i][1];
        var xj = polygon[j][0],
          yj = polygon[j][1];

        var intersect =
          yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
      }
      if (inside) {
        insidepolygon = polygon;
        break;
      }
    }

    return [inside, insidepolygon];
  }
  componentDidMount() {
    this.ctx = this.canvas.getContext("2d");
    if (this.props.initialData && this.props.imgSrc) {
      this.loadDraw(this.props.initialData);
    }
    if (!this.tool) {
      this.tool = tools[this.props.tool];
      this.tool.ctx = this.ctx;
    }
    this.setState({ count: 1 });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.tool !== this.props.tool) {
      if (this.props.tool) {
        this.tool = tools[this.props.tool];
        this.tool.ctx = this.ctx;
        this.tool.resetState();
      }
    }

    if (prevState.clearCanvas !== this.state.clearCanvas) {
      this.setState({ listZones: [] });
    }

    if (prevProps.imgSrc !== this.props.imgSrc) {
      this.loadDraw(this.props.initialData);
    }
  }

  onMouseDown = (e) => {
    //remove return
    if (!this.props.allowDrawing) return;
    const thispoint = this.getCursorPosition(e);
    this.state.mousedown = new Date().getTime();
    var mindist = 2000;
    var minpolygon = "";
    var minpointpair = 0;
    for (let key in this.state.data) {
      if (key.startsWith("Polygon")) {
        const polygon = this.state.data[key];
        var count = 0;
        polygon.forEach((point) => {
          var dist = Math.hypot(thispoint.x - point[0], thispoint.y - point[1]);
          if (dist < mindist) {
            mindist = dist;
            minpointpair = count;
            minpolygon = key;
          }
          count++;
        });
      }
    }
    if (mindist < 5) {
      this.setState({ dragPolygon: minpolygon });
      this.setState({ dragPair: minpointpair });
    } else {
      var ontheline = false;
      //see if new dot is part of previous polygon:
      for (let key in this.state.data) {
        if (key.startsWith("Polygon")) {
          const polygon = this.state.data[key];

          for (var i = 0; i < polygon.length; i++) {
            var point = polygon[i];
            var nextpoint = polygon[0];
            if (i < polygon.length - 1) {
              nextpoint = polygon[i + 1];
            }
            var disttopoint = Math.hypot(
              thispoint.x - point[0],
              thispoint.y - point[1]
            );
            var disttonextpoint = Math.hypot(
              thispoint.x - nextpoint[0],
              thispoint.y - nextpoint[1]
            );
            var distbetweenpoints = Math.hypot(
              point[0] - nextpoint[0],
              point[1] - nextpoint[1]
            );
            if (
              Math.abs(distbetweenpoints - (disttopoint + disttonextpoint)) <
              0.6
            ) {
              ontheline = true;
              var newpoint = [thispoint.x, thispoint.y];
              polygon.splice(i + 1, 0, newpoint);
              var newdata = this.state.data;
              newdata[key] = polygon;
              this.forceLoadDraw(newdata);
              this.setState({ data: newdata });
              break;
            }
          }
        }
      }

      if (ontheline) {
      } else {
        if (this.tool == null) {
          this.tool = tools[this.props.tool];
          this.tool.ctx = this.ctx;
          this.tool.resetState();
        }
        if (this.tool) {
          const { brushSize, color } = this.props;
          const { tool } = this.props;
          const { polygonId, rectangleId } = this.state;
          if (tool !== "Line") {
            this.createNewToolInitialData(tool);
          }
          const key =
            tool === "Line"
              ? "Line"
              : tool === "Polygon"
              ? `Polygon_${polygonId}`
              : `Rectangle_${rectangleId}`;
          this.setState({ currentKey: key });
          this.setState({ dragPolygon: undefined });
          this.setState({ dragPair: undefined });
          this.tool.onMouseDown(this.getCursorPosition(e), {
            brushSize,
            color,
            tool,
          });
        }
      }
    }
  };
  removePolygon = (point) => {
    var x = point.x,
      y = point.y;
    var inside = false;
    var newdata = this.state.data;
    for (let key in newdata) {
      const polygon = newdata[key];
      for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        var xi = polygon[i][0],
          yi = polygon[i][1];
        var xj = polygon[j][0],
          yj = polygon[j][1];

        var intersect =
          yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
      }

      if (inside) {
        console.log(`Polygon to be removed val* `, newdata[key].toString());
        console.log(`Polygon to be removed key*`, key);

        //New code here 1
        console.log(
          `Polygon to be removed in this.props.zonesKeysInUse`,
          this.props.zonesKeysInUse
        );

        if (this.props.zone && this.props.zonesKeysInUse.indexOf(key) >= 0) {
          console.log(`Key found skipping removal of the zone`, key);
          message.warn("This zone is in use and cannot be deleted");
          return;
        } else {
          console.log(`Polygon to be removed - removing...`);
        }

        delete newdata[key];
        this.forceLoadDraw(newdata);
        this.setState({ data: newdata });
        break;
      }
    }
  };
  removeZone = (key, label) => {
    var newdata = this.state.data;
    console.log(`Polygon to be removed val* `, newdata[key].toString());
    console.log(`Polygon to be removed key*`, key, "label: ", label);
    console.log(
      `Polygon to be removed in this.props.zonesKeysInUse`,
      this.props.zonesKeysInUse
    );

    if (this.props.zone && this.props.zonesKeysInUse.indexOf(key) >= 0) {
      console.log(`Key found skipping removal of the zone`, key);
      message.warn("This zone is in use and cannot be deleted");
      return;
    } else {
      console.log(`Polygon to be removed - removing...`);
    }

    delete newdata[key];
    this.forceLoadDraw(newdata);
    this.setState({ data: newdata });
    message.success("Zone successfuly removed.");
  };
  createNewToolInitialData = (tool) => {
    const toolId = tool.startsWith("Poly") ? "polygonId" : "rectangleId";
    const keyId = `${tool}_${this.state[toolId]}`;
    if (!this.state.data[keyId]) {
      this.setState({ data: { ...this.state.data, [keyId]: [] } });
    }
  };
  menu = [];
  onMouseMove = (e) => {
    //remove return
    if (!this.props.allowDrawing) return;

    this.menu = [];
    const point = this.getCursorPosition(e);
    const [inside, polygon] = this.isInside(point);

    if (inside) {
      this.setState({ showDeleteIcon: true });
      // ... other code for handling inside polygon
      this.setState({ current_point: point });
    }
    if (
      this.state.dragPolygon != undefined &&
      this.state.dragPair != undefined
    ) {
      const moveto = this.getCursorPosition(e);
      var newdata = this.state.data;
      newdata[this.state.dragPolygon][this.state.dragPair][0] = moveto.x;
      newdata[this.state.dragPolygon][this.state.dragPair][1] = moveto.y;
      this.forceLoadDraw(newdata);
      this.setState({ data: newdata });
    } else {
      const point = this.getCursorPosition(e);
      const [inside, polygon] = this.isInside(point);

      if (this.tool == null || this.tool.state == null) {
        if (inside) {
          //var pos={
          //     position: 'absolute',
          //     top:point.y+this.canvas.offsetTop,
          //     left:point.x+this.canvas.offsetLeft,
          // }

          var style = {
            color: "#FFFFFF",
            fontWeight: "bold",
          };
          this.forceLoadDraw(this.state.data);
          this.drawstart(polygon);
        } else {
          this.forceLoadDraw(this.state.data);
        }
      } else if (this.tool) {
        this.tool.onMouseMove(point, () => {
          this.setState({ polygonId: canvasHandler.uuid(), currentKey: null });
          this.tool = null;
          this.props.onFinishDraw && this.props.onFinishDraw();
          if (this.tool == null || !this.tool) {
            this.tool = tools[this.props.tool];
            this.tool.ctx = this.ctx;
          }
        });
      }
    }
  };
  drawstart(start) {
    if (start && start[0] && start[1]) {
      var x = Number(start[0][0]);
      var y = Number(start[0][1]);
      this.ctx.strokeStyle = "#ffffff";
      this.ctx.lineWidth = 3;
      this.ctx.beginPath();

      // Increase the size of the "cross" icon by changing the coordinates
      var iconSize = 10;
      // var tickSpacing = 3;

      // start
      // this.ctx.moveTo(x - iconSize, y - iconSize);
      // this.ctx.lineTo(x + iconSize, y + iconSize);
      // this.ctx.moveTo(x - iconSize, y + iconSize);
      // this.ctx.lineTo(x + iconSize, y - iconSize);
      // end

      // Draw the tick icon
      // this.ctx.moveTo(x - iconSize * 0.7, y - iconSize * 0.2);
      // this.ctx.lineTo(x - iconSize * 0.2, y + iconSize * 0.3);
      // this.ctx.lineTo(x + iconSize * 0.7, y - iconSize * 0.4);

      // Draw the circle part of the Nike logo
      // this.ctx.arc(x, y, iconSize - tickSpacing, 0.25 * Math.PI, 0.75 * Math.PI, false);

      this.ctx.closePath();
      this.ctx.stroke();
    }
  }
  onMouseUp = (e) => {
    //remove return
    if (!this.props.allowDrawing) return;
    if (
      this.state.dragPair == 0 &&
      this.state.mousedown >= new Date().getTime() - 500
    ) {
      const point = this.getCursorPosition(e);
      this.setState({ current_point: point });
      console.log("To be removed polygon point -> ", point);
      this.removePolygon(point);
      this.forceLoadDraw(this.state.data);
    }
    this.state.mousedown = 0;
    this.setState({ dragPolygon: undefined });
    this.setState({ dragPair: undefined });
    if (this.tool) {
      const newData = this.tool.onMouseUp(this.getCursorPosition(e), () => {
        this.setState({ rectangleId: canvasHandler.uuid(), currentKey: null });
        this.tool = null;
        this.props.onFinishDraw && this.props.onFinishDraw();
      });
      this.updateData(newData);
    }
  };

  updateData = (dataFromTool) => {
    const { polygonId, rectangleId } = this.state;
    const { tool } = this.props;
    const key =
      tool === "Line"
        ? "Line"
        : tool === "Polygon"
        ? `Polygon_${polygonId}`
        : `Rectangle_${rectangleId}`;

    if (dataFromTool) {
      const dataToUpdate =
        key.startsWith("Poly") || key.startsWith("Line")
          ? [...this.state.data[key], dataFromTool.data]
          : [...this.state.data[key], ...dataFromTool.data];

      this.setState(
        {
          undoData: [...this.state.undoData, this.state.data],
          data: {
            ...this.state.data,
            [key]: dataToUpdate,
          },
          canvasData: [...this.state.canvasData, dataFromTool.canvas],
        },
        () => {
          this.props.onDataUpdate && this.props.onDataUpdate(this.state.data);
        }
      );
    }
  };

  getCursorPosition = (e) => {
    // top and left of canvas
    const { top, left } = this.canvas.getBoundingClientRect();
    // clientY and clientX coordinate inside the element that the event occur.
    return {
      x: Math.round(e.clientX - left),
      y: Math.round(e.clientY - top),
    };
  };

  cleanCanvas = () => {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.setState({ data: INITIAL_STATE, canvasData: [] }, () => {
      this.props.onDataUpdate && this.props.onDataUpdate(this.state.data);
    });
  };

  isCtrlPressed = (event) => event.ctrlKey || event.metaKey;
  isShiftPressed = (event) => event.shiftKey;

  onKeyDown = (event) => {
    const isCtrl = this.isCtrlPressed(event);
    const isShift = this.isShiftPressed(event);
    const Z = 90;
    // TODO: treat the case where polygon is less than 3 points
    // we must not render it, but neither redo should have its last state
    if (isCtrl && event.which === Z) {
      const modifiedUndo = this.state.undoData;
      const oneStepBack = History.filterPolygon(modifiedUndo.pop());
      this.loadDraw(oneStepBack, true);
      this.setState({
        data: oneStepBack,
        undoData: modifiedUndo,
        redoData: [...this.state.redoData, this.state.data],
      });
    }
    if (isCtrl && isShift && event.which === Z) {
      const modifiedRedo = this.state.redoData;
      const oneStepForward = modifiedRedo.pop();
      this.loadDraw(oneStepForward, true);
      this.setState({ data: oneStepForward });
    }
  };

  onKeyUp = (event) => {
    if (event.key === "Escape") {
      // undo current drawing
      if (this.state.currentKey) {
        let newData = History.cancel(this.state.currentKey, this.state.data);
        this.setState({ data: newData, currentKey: null }, () => {
          this.loadDraw(this.state.data, true);
          this.props.onDataUpdate(this.state.data);
          this.tool = null;
        });
      }
    }
  };

  forceLoadDraw = (data) => {
    console.log("forceDraw -> ", data);
    this.loadDraw(data);
  };

  find_next_label = (zoneNames) => zoneNames.length + 1;

  find_next_suffix = (zoneNames) => {
    //Calculates the next suffix from the list of the zoneNames supplied.
    let sensor_id_suffixes = [];
    let next_suffix;

    if (zoneNames.length === 0) return "a";

    // Extract suffixes of zoneNames and put them in one array
    zoneNames.forEach((item) => {
      sensor_id_suffixes.push(item.toLowerCase());
    });

    console.log("Suffixes Unsorted: ", sensor_id_suffixes);

    // check which suffix(s) has the highest string length
    // sort array according to string length
    sensor_id_suffixes.sort((a, b) => b.length - a.length);
    console.log("Suffixes Sorted: ", sensor_id_suffixes);

    // Get the highest string length and filter all items with that string length
    let highest_str_length = sensor_id_suffixes[0].length;
    let suffixes_with_highest_length = sensor_id_suffixes.filter(
      (item) => item.length === highest_str_length
    );
    console.log("suffixes_with_highest_length: ", suffixes_with_highest_length);

    //Sort the filtered suffixes in descending order
    suffixes_with_highest_length.sort().reverse();
    console.log(
      "Sorted suffixes_with_highest_length",
      suffixes_with_highest_length
    );

    // Remove the 1st item
    let last_used_suffix = suffixes_with_highest_length[0];
    console.log("last_used_suffix", last_used_suffix);

    // Check if that last used suffix contains z

    if (last_used_suffix.includes("z")) {
      // If highest_str_length  is greater than 1 simply append "a" to last_used_suffix
      // If not simply initialize next_suffix  to "aa"

      if (highest_str_length > 1) {
        //check how many times z appears
        let count = suffixes_with_highest_length[0].match(/z/g) || [];
        console.log("count:", count, count.length);

        // Check if z appears more than once
        if (count.length > 1) {
          // check if everything is z in string
          if (highest_str_length === count.length) {
            next_suffix = "a";
            count.forEach((z) => {
              next_suffix += "a";
            });
            console.log("next_prefix", next_suffix);
            return next_suffix;
          } else {
            // split suffixes_with_highest_length[0] by z
            let cntWithoutZ = suffixes_with_highest_length[0].split("z")[0];

            // get the last char and increment & store nextChar
            let lastChar = cntWithoutZ[cntWithoutZ.length - 1];
            let nextChar = String.fromCharCode(
              lastChar.charCodeAt(lastChar.length - 1) + 1
            );

            // remove the last char
            let conentsWithoutLastchar = cntWithoutZ.slice(0, -1);

            // join returned by next char + a based on for each
            next_suffix = conentsWithoutLastchar + nextChar;
            count.forEach((z) => {
              next_suffix += "a";
            });
            console.log("next_suffix", next_suffix);
            return next_suffix;
          }
        } else {
          // copy suffixes_with_highest_length[0] into new arr
          let contents = suffixes_with_highest_length[0];

          // splice z out and store in var
          let cntWithoutZ = contents.slice(0, -1);

          // get the last character and increament store in var
          let lastChar = cntWithoutZ[cntWithoutZ.length - 1];
          let nextChar = String.fromCharCode(
            lastChar.charCodeAt(lastChar.length - 1) + 1
          );

          console.log(
            "cntWithoutZ: ",
            cntWithoutZ,
            " lastChar: ",
            lastChar,
            "nextChar: ",
            nextChar
          );

          // remove the last char after z and store returned in var
          let cntWithoutlastChar = cntWithoutZ.slice(0, -1);

          //join the return + increamented char + "a"
          next_suffix = cntWithoutlastChar + nextChar + "a";

          // next_suffix = last_used_suffix + "a";
          console.log("next_suffix", next_suffix);
          return next_suffix;
        }
      } else {
        next_suffix = "aa";
        console.log("next_suffix", next_suffix);
        return next_suffix;
      }
    } else {
      //When the last used suffix doesn't contain z
      // Check if highest_str_length  is greater than 1
      if (highest_str_length > 1) {
        // If greater than 1, Check the last_character, store it a variable
        // remove the last_character from string
        // append the next character to the new string returned from operation above
        let last_character = last_used_suffix[highest_str_length - 1];
        let first_characters = last_used_suffix.slice(0, -1);
        let next_character = String.fromCharCode(
          last_character.charCodeAt(last_character.length - 1) + 1
        );
        next_suffix = first_characters + next_character;
        console.log("next_suffix (NO Z): ", next_suffix);
        return next_suffix;
      } else {
        // If highest_str_length is NOT greater than 1, simply use the next character and assign value for next Character
        next_suffix = String.fromCharCode(
          last_used_suffix.charCodeAt(last_used_suffix.length - 1) + 1
        );
        console.log("next_suffix (Single Letter):", next_suffix);
        return next_suffix;
      }
    }
  };

  // TODO: refactor this function to canvas handle
  loadDraw = (data, byPassReset) => {
    console.log("invoked!", data, byPassReset);
    const X = 0,
      Y = 1;
    const START = 0,
      END = 1;
    const TOP_LEFT = 0,
      BOTTOM_RIGHT = 2;
    // clean the canvas
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    var temp_zone_list = [];
    // loop through the data
    const available_zones = [];
    delete data["Line"];
    data &&
      Object.keys(data).forEach((el, indx) => {
        let shape = canvasHandler.getTool(el);
        this.tool = tools[shape];
        this.tool.ctx = this.ctx;

        let elPoints = data[el];
        const index = +el.split("_")[1];
        const zoneOptionsCheck = () => {
          var result = undefined;
          if (this.props.zoneOptions !== undefined) {
            result = this.props.zoneOptions[index];
          }
          return result;
        };
        const foundLetter = zoneOptionsCheck();

        let suffix;
        if (foundLetter) {
          suffix = foundLetter;
        } else {
          console.log("temp_zone_list -> ", temp_zone_list);
          suffix = this.props.zone
            ? this.find_next_suffix(temp_zone_list)
            : this.find_next_label(temp_zone_list);
        }

        temp_zone_list.push(suffix.toString().toUpperCase());

        elPoints.forEach((point, index, array) => {
          const nextPoint = el.startsWith("Rect")
            ? array[index + 1]
            : array[index + 1] || array[0];
          if (nextPoint) {
            this.tool.draw(
              { x: point[X], y: point[Y] },
              { x: nextPoint[X], y: nextPoint[Y] },
              false,
              {
                options: {
                  brushSize: this.props.brushSize,
                },
              },
              this.props.allowDrawing
            );
          }
        });
        if (el.startsWith("Poly")) {
          var changed = false;
          var initpolygon = this.props.savedData[el];
          if (initpolygon) {
            for (var i = 0; i < initpolygon.length; i++) {
              if (initpolygon[i] && elPoints[i]) {
                if (
                  initpolygon[i][0] != elPoints[i][0] ||
                  initpolygon[i][1] != elPoints[i][1]
                ) {
                  changed = true;
                }
              }
            }
          } else {
            changed = true;
          }
          if (changed) {
            this.tool.fillGeometry(
              elPoints,
              "rgba(234, 71, 45, 0.5)",
              temp_zone_list[indx],
              this.props.showlabels,
              this.props.zone
            );
            // this.tool.fillGeometry(elPoints, 'rgba(234, 71, 45, 0.5)', indx, 0)
          } else {
            console.log("initpolygon -> ", this.props.savedData[el]);
            if (this.state.count) {
              this.tool.fillGeometry(
                elPoints,
                "rgba(55, 122, 225, 0.5)",
                temp_zone_list[indx],
                this.props.showlabels,
                this.props.zone
              );
              // this.tool.fillGeometry(
              //   elPoints,
              //   'rgba(55, 122, 225, 0.5)',
              //   indx,
              //   0,
              // )
            } else {
              this.tool.fillGeometry(
                elPoints,
                "rgba(55, 122, 225, 0.5)",
                temp_zone_list[indx],
                this.props.showlabels,
                this.props.zone
              );
              // this.tool.fillGeometry(
              //   elPoints,
              //   'rgba(55, 122, 225, 0.5)',
              //   indx,
              //   1,
              // )
            }
          }
          available_zones.push({ label: temp_zone_list[indx], value: el });
          this.setState({ listZones: available_zones });
        }
      });

    console.log("available_zones --> ", available_zones);
    this.tool && this.tool.resetState();
    this.tool = null;
    this.props.onFinishDraw && this.props.onFinishDraw();
    this.setState({ clearCanvas: false });

    if (!byPassReset) {
      this.setState({ data: { ...this.state.data, ...data } }, () =>
        this.props.onDataUpdate(this.state.data)
      );
    }
  };

  // TODO: refactor this function to canvas handle
  loadDrawOld = (data, byPassReset) => {
    const X = 0,
      Y = 1;
    const START = 0,
      END = 1;
    const TOP_LEFT = 0,
      BOTTOM_RIGHT = 2;
    // clean the canvas
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    // loop through the data
    data &&
      Object.keys(data).forEach((el) => {
        let shape = canvasHandler.getTool(el);
        this.tool = tools[shape];
        this.tool.ctx = this.ctx;
        // avoid mutate initial data;
        let newData = {};
        if (el.startsWith("Rectan")) {
          let dataIndex = data[el][BOTTOM_RIGHT] ? BOTTOM_RIGHT : 1;
          newData[el] = [data[el][TOP_LEFT], data[el][dataIndex]];
        }
        let elPoints = el.startsWith("Rectan") ? newData[el] : data[el];
        if (el.startsWith("Line") || el.startsWith("Arrang")) {
          elPoints.forEach((point) => {
            this.tool.draw(
              { x: point[START][X], y: point[START][Y] },
              { x: point[END][X], y: point[END][Y] },
              false,
              {
                options: { brushSize: this.props.brushSize },
              },
              this.props.allowDrawing
            );
            this.tool.drawCrossDirection(
              [
                [point[START][X], point[START][Y]],
                [point[END][X], point[END][Y]],
              ],
              20
            );
          });
        } else {
          elPoints.forEach((point, index, array) => {
            const nextPoint = el.startsWith("Rect")
              ? array[index + 1]
              : array[index + 1] || array[0];
            if (nextPoint) {
              this.tool.draw(
                { x: point[X], y: point[Y] },
                { x: nextPoint[X], y: nextPoint[Y] },
                false,
                {
                  options: {
                    brushSize: this.props.brushSize,
                  },
                },
                this.props.allowDrawing
              );
            }
          });
          if (el.startsWith("Poly")) {
            this.tool.fillGeometry(elPoints);
          }
        }
      });
    this.tool && this.tool.resetState();
    this.tool = null;
    this.props.onFinishDraw && this.props.onFinishDraw();
    if (!byPassReset) {
      this.setState({ data: { ...this.state.data, ...data } }, () =>
        this.props.onDataUpdate(this.state.data)
      );
    }
  };

  handleDeleteClick = () => {
    // const [inside, polygon] = this.isInside(this.mouseCursorCoordinates);
    // if (this.showDeleteIcon) {
    console.log("this.current_point, = ", this.state.current_point);
    this.removePolygon(this.state.current_point);
    // }
  };

  render() {
    const { width, height, imgSrc } = this.props;

    return (
      <React.Fragment>
        <div
          style={{
            cursor: "pointer",
            margin: "0 auto",
            marginBottom: 6,
            marginTop: 13,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Select
            allowClear={true}
            bordered={true}
            value={-1}
            style={{ overflowY: "scroll", padding: 5, marginRight: 20 }}
            placeholder={
              this.props.zone
                ? "Select alert inclusion zone to remove"
                : "Select exclusion mask to remove"
            }
            onChange={(e, l) => this.removeZone(e, l)}
            options={[
              {
                label: this.props.zone
                  ? "Select alert inclusion zone to remove"
                  : "Select exclusion mask to remove",
                value: -1,
              },
              ...this.state.listZones.map((item) => {
                return {
                  label: `Remove ${
                    this.props.zone ? "alert zone" : "exclusion mask"
                  } ${item.label}`,
                  value: item.value,
                };
              }),
            ]}
            // showArrow={true}
            // options={this.state.listZones}
            className={"ant-select-selection"}
          />
          {!this.props.zone && (
            <AntButton
              style={{ backgroundColor: "red", color: "white" }}
              danger
              onClick={() => {
                this.props.clearExcusionZones();
                this.cleanCanvas();
                this.setState({ clearCanvas: true });
              }}
              type="primary"
              shape="round"
              // icon={<DeleteOutlined />}
              size={"medium"}
            >
              Clear Masks
            </AntButton>
          )}
        </div>
        {this.menu}
        <canvas
          tabIndex="1"
          ref={(canvas) => (this.canvas = canvas)}
          width={width}
          height={height}
          style={{
            color: "black",
            backgroundImage: `url(${imgSrc})`,
            backgroundSize: "cover",
            margin: "auto",
          }}
          onMouseDown={this.onMouseDown}
          onMouseMove={this.onMouseMove}
          onMouseUp={this.onMouseUp}
          onKeyDown={this.onKeyDown}
          onKeyUp={this.onKeyUp}
        />
      </React.Fragment>
    );
  }
}

DrawCanvas.propTypes = {
  /**
   * Bool value to show labels
   */
  zone: type.bool,
  /**
   * Bool value to show labels
   */
  showlabels: type.number,
  /**
   * The width of canvas
   */
  width: type.number,
  /**
   * the height of the canvas
   */
  height: type.number,
  /**
   * Background image to canvas;
   */
  imgSrc: type.string,
  /**
   * BrushSize to draw
   */
  brushSize: type.number,
  /**
   * Color of what we want draw
   */
  color: type.string,
  /**
   * CanUndo
   */
  canUndo: type.bool,
  /**
   * Shapes that you can select to draw
   */
  tool: type.oneOf(["Line", "Polygon", "Rectangle"]),
  /**
   * Is the data to be be draw when load the component
   */
  initialData: type.object,
  /**
  /**
   * Is the data to be be used when excluding zones
   */
  zonesInUse: type.object,
  /**
   * This is a callback function that we be called
   * everytime the data updates
   */
  zonesKeysInUse: type.array,
  /**
   * Zone Options
   */
  zoneOptions: type.array,
  /**
   * This is a callback function that we be called
   * everytime the data updates
   */
  onDataUpdate: type.func,
  /**
   * This is a callback function what we be triggered
   * when the shape is drawn
   */
  onFinishDraw: type.func,
  clearExcusionZones: type.func,
  allowDrawing: type.bool,
};

DrawCanvas.defaultProps = {
  width: 300,
  height: 300,
  brushSize: 2,
  showlabels: false,
  color: "#000000",
  canUndo: false,
  allowDrawing: true,
  zone: false,
  zonesKeysInUse: [],
};

export default DrawCanvas;
