import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Alert from "@material-ui/lab/Alert";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import { AppStore } from "../../../stores/AppStore";
import {
  addBillingOrgResult,
  AuthResult,
  registrationResult,
} from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import countries from "countries-list";
import { ValueChecking } from "../../HelperFunctions";
import {
  createStyles,
  fade,
  Theme,
  ThemeProvider,
  withStyles,
  makeStyles,
  createMuiTheme,
} from "@material-ui/core/styles";
import LoadingSpinner from "../../../spinner/LoadingSpinner";
import Terms from "./Terms";

interface MockEvent {
  (event: Event): any;
}

interface RegistrationPageProps {
  appStore?: AppStore;
  onClose: () => void;
}
const formStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    width: "100%",
  },
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
  },
  root: {
    color: "white",
  },
  icon: {
    fill: "white",
  },
}));

const RegistrationPage: React.FC<RegistrationPageProps> = ({
  appStore,
  onClose,
}: RegistrationPageProps) => {
  const StyledTableRowSMTP = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        display: "flex",
        justifyContent: "space-between",
        textAlign: "left",
      },
    })
  )(TableRow);
  const classes = useStyles();

  const formClasses = formStyles();
  var [message, setMessage] = useState("");
  var [username, setUsername] = useState("");

  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorText, setPasswordErrorText] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorText, setFirstNameErrorText] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [lastNameErrorText, setLastNameErrorText] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState(false);
  const [companyNameErrorText, setCompanyNameErrorText] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [contactNumberError, setContactNumberError] = useState(false);
  const [contactNumberErrorText, setContactNumberErrorText] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toast, setToast] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [registrationId, setRegistrationId] = useState<number | undefined>(
    undefined
  );
  const [hearAboutUs, setHearAboutUs] = useState("LinkedIn");
  const [hearText, setHearText] = useState("");
  const [hearTextError, setHearTextError] = useState(false);
  const [hearTextErrorText, setHearTextErrorText] = useState("");

  const [openTerms, setOpenTerms] = useState(false);
  const [checked, setChecked] = useState(false);
  const country_names = Object.values(countries.countries)
    .map((item) => item.name)
    .sort();

  const country_objects = Object.values(countries.countries)
    .map((item) => item)
    .sort();

  console.log(Object.values(countries.countries).map((item) => item));
  var creds: AuthResult;

  type user_field =
    | "email"
    | "first_name"
    | "last_name"
    | "password"
    | "confirm_password"
    | "company_name"
    | "contact_number"
    | "hear_text";

  interface ErrorResult {
    error_present: boolean;
    error_text: string;
    update: boolean;
  }

  function validateEmail(email: string): boolean {
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  }

  const numberValidator = (value: string): ErrorResult => {
    if (value.startsWith("+")) {
      let newValue = value.substring(1);
      if (newValue === "0" || (newValue.length > 0 && Number(newValue))) {
        return {
          error_present: false,
          error_text: "",
          update: true,
        };
      } else if (newValue === "") {
        return {
          error_present: true,
          error_text: "please enter more digits",
          update: true,
        };
      } else {
        return {
          error_present: true,
          error_text: "please enter valid digits",
          update: false,
        };
      }
    } else if (value.startsWith("0")) {
      if (value === "0" || (value.length > 0 && Number(value))) {
        return {
          error_present: false,
          error_text: "",
          update: true,
        };
      } else {
        return {
          error_present: true,
          error_text: "please enter valid digits",
          update: false,
        };
      }
    } else if (Number(value)) {
      return {
        error_present: false,
        error_text: "",
        update: true,
      };
    } else if (value === "") {
      return {
        error_present: true,
        error_text: "please enter valid digits",
        update: true,
      };
    } else {
      return {
        error_present: true,
        error_text: "please enter valid digits",
        update: false,
      };
    }
  };

  const passwordValidator = (test_string: string): ErrorResult => {
    const hasSpecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (test_string.length > 12) {
      if (test_string.toLowerCase() != test_string) {
        if (hasSpecial.test(test_string)) {
          return {
            error_present: false,
            error_text: "",
            update: true,
          };
        } else {
          return {
            error_present: true,
            error_text: "password must contain special characters",
            update: true,
          };
        }
      } else {
        return {
          error_present: true,
          error_text: "please use both uppercase and lowercase letters",
          update: true,
        };
      }
    } else {
      return {
        error_present: true,
        error_text:
          "please enter at least 12 characters including alphanumeric and special characters",
        update: true,
      };
    }
  };

  function handleUserChange(
    e: React.ChangeEvent<HTMLInputElement>,
    input_type: user_field
  ) {
    let value = e.target.value;
    if (input_type === "company_name") {
      if (value === "") {
        setCompanyNameError(true);
        setCompanyNameErrorText("company name cannot be empty");
      } else {
        setCompanyNameError(false);
        setCompanyNameErrorText("");
      }
      setCompanyName(value);
    } else if (input_type === "email") {
      if (validateEmail(value)) {
        setEmailError(false);
        setEmailErrorText("");
      } else {
        setEmailError(true);
        setEmailErrorText("invalid email");
      }
      setEmail(value);
    } else if (input_type === "password") {
      let validation_result = passwordValidator(value);
      setPasswordError(validation_result.error_present);
      setPasswordErrorText(validation_result.error_text);
      if (validation_result.update) {
        setPassword(value);
      }
    } else if (input_type === "confirm_password") {
      if (value !== password) {
        setConfirmPasswordError(true);
        setConfirmPasswordErrorText("password mismatch");
      } else {
        setConfirmPasswordError(false);
        setConfirmPasswordErrorText("");
      }
      setConfirmPassword(value);
    } else if (input_type === "first_name") {
      if (value === "") {
        setFirstNameError(true);
        setFirstNameErrorText("first name field cannot be empty");
      } else {
        setFirstNameError(false);
        setFirstNameErrorText("");
      }
      setFirstName(value);
    } else if (input_type === "last_name") {
      if (value === "") {
        setLastNameError(true);
        setLastNameErrorText("last name field cannot be empty");
      } else {
        setLastNameError(false);
        setLastNameErrorText("");
      }
      setLastName(value);
    } else if (input_type === "contact_number") {
      let number_validation = numberValidator(value);
      setContactNumberError(number_validation.error_present);
      setContactNumberErrorText(number_validation.error_text);
      if (number_validation.update) {
        setContactNumber(value);
      }
    } else if (input_type === "hear_text") {
      if (value === "") {
        setHearTextError(true);
        setHearTextErrorText("hear about us field cannot be empty");
      } else {
        setHearTextError(false);
        setHearTextErrorText("");
      }
      setHearText(value);
    }
  }

  const handleRegister = async () => {
    let num_validation = numberValidator(contactNumber);

    if (country !== "") {
      let desiredCountryCode = country_objects.find(
        (item: any) => item.name === country
      )?.phone;
      let newContact = contactNumber.substring(1); // remove the leading zero
      let contactWithCountryCode = "+" + desiredCountryCode + newContact;
      if (checked) {
        if (!num_validation.error_present) {
          setContactNumberError(num_validation.error_present);
          setContactNumberErrorText(num_validation.error_text);
          if (
            firstName != "" &&
            lastName != "" &&
            email != "" &&
            companyName != "" &&
            password != "" &&
            confirmPassword != ""
          ) {
            if (
              !firstNameError &&
              !lastNameError &&
              !emailError &&
              !passwordError &&
              !confirmPasswordError &&
              !companyNameError
            ) {
              if (hearAboutUs === "Other") {
                if (hearText === "") {
                  setHearTextError(true);
                  setHearTextErrorText("hear about us field cannot be empty");
                } else {
                  setLoading(true);
                  let hear_about_us =
                    hearAboutUs === "Other" ? hearText : hearAboutUs;

                  let post_result: registrationResult =
                    await appStore?.functionService.registerUser(
                      contactWithCountryCode,
                      email,
                      country,
                      companyName,
                      firstName,
                      lastName,
                      password,
                      checked,
                      hear_about_us
                    );
                  if (post_result.success) {
                    setShowResult(true);
                    setResultMessage(post_result.msg);
                    setRegistrationId(
                      post_result.registration_data.registration_id
                    );
                  } else {
                    setShowResult(true);
                    setResultMessage(post_result.msg);
                  }
                  setLoading(false);
                }
              } else {
                setLoading(true);
                let hear_about_us =
                  hearAboutUs === "Other" ? hearText : hearAboutUs;

                let post_result: registrationResult =
                  await appStore?.functionService.registerUser(
                    contactWithCountryCode,
                    email,
                    country,
                    companyName,
                    firstName,
                    lastName,
                    password,
                    checked,
                    hear_about_us
                  );
                if (post_result.success) {
                  setShowResult(true);
                  setResultMessage(post_result.msg);
                  setRegistrationId(
                    post_result.registration_data.registration_id
                  );
                } else {
                  setShowResult(true);
                  setResultMessage(post_result.msg);
                }
                setLoading(false);
              }
            } else {
              setShowToast(true);
              setToast("one or more fields are invalid");
            }
          } else {
            setShowToast(true);
            setToast("one or more fields are empty");
          }
        } else {
          setContactNumberError(num_validation.error_present);
          setContactNumberErrorText(num_validation.error_text);
          setShowToast(true);
          setToast("invalid contact number");
        }
      } else {
        setShowToast(true);
        setToast("please accept the terms and conditions");
      }
    } else {
      setCountryError(true);
      setShowToast(true);
      setToast("please select country");
    }
  };

  const handleHearChange = (event: React.ChangeEvent<any>) => {
    setHearAboutUs(event.target.value);
  };

  const handleCountryChange = (event: React.ChangeEvent<any>) => {
    setCountryError(false);
    setCountry(event.target.value);
    if (!contactNumber) {
      let desiredCountryCode = country_objects.find(
        (item) => item.name === event.target.value
      )?.phone;
      setContactNumber(("+" + desiredCountryCode) as string);
    }
  };
  const handleCheck = (check: boolean) => {
    setChecked(check);
  };

  useEffect(() => {
    if (showToast) {
      const timeId = setTimeout(() => {
        // After 3 seconds set the show value to false

        setShowToast(false);
        setToast("");
      }, 3000);

      return () => {
        clearTimeout(timeId);
      };
    }
    // when the component is mounted, the alert is displayed for 3 seconds
  }, [showToast]);

  return (
    <div className={classes.login}>
      <Card className={classes.register_container}>
        <Box justifyContent="center">
          <Typography
            align="center"
            style={{ fontSize: 15, fontWeight: 900, color: "#ffff" }}
          >
            Registration
          </Typography>
        </Box>

        {loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingSpinner />
          </Box>
        ) : resultMessage.includes("successful") ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box mt={2} mb={2}>
              <Typography>
                Thank you for your interest. Your registration has been
                received. Client Services will contact you within 24 hours.
              </Typography>
            </Box>
            <Button
              onClick={() => onClose()}
              variant="contained"
              className={classes.button_text_white}
            >
              Finish
            </Button>
          </Box>
        ) : (
          <form className="form" noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="company name"
              label="company name"
              name="company name"
              autoFocus
              value={companyName}
              error={companyNameError}
              helperText={companyNameErrorText}
              onChange={(e) =>
                handleUserChange(
                  e as React.ChangeEvent<HTMLInputElement>,
                  "company_name"
                )
              }
              className={classes.bold_text_white}
              InputProps={{
                className: classes.bold_text_white,
                classes: {
                  root: classes.bold_text_white_blue_background,
                  notchedOutline: classes.bold_text_white_blue_background,
                  focused: classes.bold_text_white_blue_background,
                  input: classes.bold_text_white_blue_background,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text_white,
                },
                style: { color: "#FFFFFF" },
              }}
            />
            <FormControl
              style={{ color: "#FFFFFF", marginTop: 17, marginBottom: 10 }}
              className={`${formClasses.formControl} ${classes.bold_text_white}`}
            >
              <InputLabel className={classes.bold_text_white}>
                &nbsp;&nbsp;&nbsp;country *
              </InputLabel>
              <Select
                fullWidth
                id="demo-controlled-open-select"
                variant="outlined"
                value={country}
                error={countryError}
                onChange={handleCountryChange}
                className={formClasses.select}
                inputProps={{
                  classes: {
                    icon: formClasses.icon,
                    root: formClasses.root,
                  },
                }}
              >
                {country_names &&
                  country_names.map((item, index) => {
                    return (
                      <MenuItem
                        key={item}
                        value={item}
                        className={classes.bold_text_billing}
                      >
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="contact number"
              label="contact number"
              name="contact number"
              autoFocus
              placeholder="065248293"
              value={contactNumber}
              error={contactNumberError}
              helperText={contactNumberErrorText}
              onChange={(e) =>
                handleUserChange(
                  e as React.ChangeEvent<HTMLInputElement>,
                  "contact_number"
                )
              }
              className={classes.bold_text_white}
              InputProps={{
                className: classes.bold_text_white,
                classes: {
                  root: classes.bold_text_white_blue_background,
                  notchedOutline: classes.bold_text_white_blue_background,
                  focused: classes.bold_text_white_blue_background,
                  input: classes.bold_text_white_blue_background,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text_white,
                },
                style: { color: "#FFFFFF" },
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="first name"
              name="first name"
              autoComplete="email"
              autoFocus
              value={firstName}
              error={firstNameError}
              helperText={firstNameErrorText}
              onChange={(e) =>
                handleUserChange(
                  e as React.ChangeEvent<HTMLInputElement>,
                  "first_name"
                )
              }
              className={classes.bold_text_white}
              InputProps={{
                className: classes.bold_text_white,
                classes: {
                  root: classes.bold_text_white_blue_background,
                  notchedOutline: classes.bold_text_white_blue_background,
                  focused: classes.bold_text_white_blue_background,
                  input: classes.bold_text_white_blue_background,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text_white,
                },
                style: { color: "#FFFFFF" },
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="last name"
              label="last name"
              name="last name"
              autoFocus
              value={lastName}
              error={lastNameError}
              helperText={lastNameErrorText}
              onChange={(e) =>
                handleUserChange(
                  e as React.ChangeEvent<HTMLInputElement>,
                  "last_name"
                )
              }
              className={classes.bold_text_white}
              InputProps={{
                className: classes.bold_text_white,
                classes: {
                  root: classes.bold_text_white_blue_background,
                  notchedOutline: classes.bold_text_white_blue_background,
                  focused: classes.bold_text_white_blue_background,
                  input: classes.bold_text_white_blue_background,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text_white,
                },
                style: { color: "#FFFFFF" },
              }}
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              error={emailError}
              helperText={emailErrorText}
              onChange={(e) =>
                handleUserChange(
                  e as React.ChangeEvent<HTMLInputElement>,
                  "email"
                )
              }
              className={classes.bold_text_white}
              InputProps={{
                className: classes.bold_text_white,
                classes: {
                  root: classes.bold_text_white_blue_background,
                  notchedOutline: classes.bold_text_white_blue_background,
                  focused: classes.bold_text_white_blue_background,
                  input: classes.bold_text_white_blue_background,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text_white,
                },
                style: { color: "#FFFFFF" },
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              error={passwordError}
              helperText={passwordErrorText}
              onChange={(e) =>
                handleUserChange(
                  e as React.ChangeEvent<HTMLInputElement>,
                  "password"
                )
              }
              InputProps={{
                className: classes.bold_text_white,
                classes: {
                  root: classes.bold_text_white_blue_background,
                  notchedOutline: classes.bold_text_white_blue_background,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text_white,
                },
                style: { color: "#FFFFFF" },
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirm password"
              label="confirm password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={confirmPassword}
              error={confirmPasswordError}
              helperText={confirmPasswordErrorText}
              onChange={(e) =>
                handleUserChange(
                  e as React.ChangeEvent<HTMLInputElement>,
                  "confirm_password"
                )
              }
              InputProps={{
                className: classes.bold_text_white,
                classes: {
                  root: classes.bold_text_white_blue_background,
                  notchedOutline: classes.bold_text_white_blue_background,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text_white,
                },
                style: { color: "#FFFFFF" },
              }}
            />

            <br />
            <Box mt={2}>
              <FormControl className={formClasses.formControl}>
                <InputLabel style={{ color: "#FFFFFF" }}>
                  &nbsp;&nbsp;&nbsp;How did you hear about us?
                </InputLabel>
                <Select
                  fullWidth
                  id="demo-controlled-open-select"
                  variant="outlined"
                  value={hearAboutUs}
                  onChange={handleHearChange}
                  className={formClasses.select}
                  inputProps={{
                    classes: {
                      icon: formClasses.icon,
                      root: formClasses.root,
                    },
                  }}
                >
                  <MenuItem
                    value="LinkedIn"
                    className={classes.bold_text_billing}
                  >
                    LinkedIn
                  </MenuItem>
                  <MenuItem
                    value="Website"
                    className={classes.bold_text_billing}
                  >
                    Website
                  </MenuItem>
                  <MenuItem
                    value="Search Engine"
                    className={classes.bold_text_billing}
                  >
                    Search Engine
                  </MenuItem>
                  <MenuItem
                    value="Google Ads"
                    className={classes.bold_text_billing}
                  >
                    Google Ads
                  </MenuItem>
                  <MenuItem
                    value="Facebook Ads"
                    className={classes.bold_text_billing}
                  >
                    Facebook Ads
                  </MenuItem>
                  <MenuItem
                    value="Facebook post/group"
                    className={classes.bold_text_billing}
                  >
                    Facebook post/group
                  </MenuItem>
                  <MenuItem
                    value="Email Campaign"
                    className={classes.bold_text_billing}
                  >
                    Email Campaign
                  </MenuItem>
                  <MenuItem
                    value="Word of mouth"
                    className={classes.bold_text_billing}
                  >
                    Word of mouth
                  </MenuItem>
                  <MenuItem value="Other" className={classes.bold_text_billing}>
                    Other
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            {hearAboutUs === "Other" && (
              <Box mt={1}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="how you heard about us"
                  label="how you heard about us"
                  type="text"
                  value={hearText}
                  error={hearTextError}
                  helperText={hearTextErrorText}
                  onChange={(e) =>
                    handleUserChange(
                      e as React.ChangeEvent<HTMLInputElement>,
                      "hear_text"
                    )
                  }
                  InputProps={{
                    className: classes.bold_text_white,
                    classes: {
                      root: classes.bold_text_white_blue_background,
                      notchedOutline: classes.bold_text_white_blue_background,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.bold_text_white,
                    },
                    style: { color: "#FFFFFF" },
                  }}
                />
              </Box>
            )}
            <p
              style={{ cursor: "pointer", color: "#ffff" }}
              onClick={() => setOpenTerms(!openTerms)}
            >
              click to {openTerms ? "close" : "view"} terms and conditions
            </p>
            {openTerms && (
              <Box mt={2} mb={2}>
                <p style={{ color: "#ffff" }}>
                  <strong>
                    DEEPALERT TERMS AND CONDITIONS APPLICABLE TO YOUR USE OF OUR
                    GOODS AND SERVICES
                  </strong>
                </p>
                <Terms checked={checked} setChecked={handleCheck} />
              </Box>
            )}
            <FormControlLabel
              className={classes.form_component_checkbox}
              control={
                <Checkbox
                  color="primary"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
              }
              label={
                <Typography style={{ color: "#FFFF", cursor: "pointer" }}>
                  I have read, understood and agree to the Terms and Conditions
                </Typography>
              }
            />

            {showToast && (
              <Box mt={2} mb={2} justifyContent="center">
                <Alert
                  variant="outlined"
                  severity="error"
                  onClose={() => {
                    setShowToast(false);
                    setToast("");
                  }}
                >
                  {toast}
                </Alert>
              </Box>
            )}
            {showResult && (
              <Box mt={2} mb={2} justifyContent="center">
                <Alert
                  variant="outlined"
                  severity={
                    resultMessage.includes("successfully") ? "success" : "error"
                  }
                  onClose={() => {
                    setShowResult(false);
                    setResultMessage("");
                  }}
                >
                  {resultMessage}
                </Alert>
              </Box>
            )}
            <Grid container justify="center">
              <Grid item>
                <Button
                  onClick={() => handleRegister()}
                  variant="contained"
                  className={classes.button_text_white}
                >
                  Register
                </Button>
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Grid item>
                <span
                  style={{
                    color: "white",
                    textAlign: "center",
                    paddingTop: 20,
                    fontStyle: "bold",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => onClose()}
                >
                  login
                </span>
              </Grid>
            </Grid>
          </form>
        )}
      </Card>
    </div>
  );
};

export default RegistrationPage;
