import React, { useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { AppStore } from "../../../../stores/AppStore";
import {
  FormControlLabel,
  Checkbox,
  FormLabel,
  FormGroup,
} from "@material-ui/core";
import { Role, EntryPoint } from "../../../../services/FunctionService";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "../../../styling/global/global";
interface AddRoleToOrgModalProps {
  appStore?: AppStore;
  user_id: number;
  entry_org_id: number;
  onClose: () => void;
  open: boolean;
  entry_org_name: string;
  entrypoints: EntryPoint[] | undefined;
  saveRolesForEntryOrg: (
    user_id: number,
    entry_org_id: number,
    selectedroles: { [key: string]: any }
  ) => void;
}
const AddRoleToOrgModal: React.FC<AddRoleToOrgModalProps> = ({
  appStore,
  user_id,
  entry_org_id,
  entry_org_name,
  onClose,
  open,
  saveRolesForEntryOrg,
  entrypoints,
}: AddRoleToOrgModalProps) => {
  const [roles, setRoles] = React.useState<Role[] | undefined>([]);
  const [selectedroles, setSelectedRoles] = React.useState<
    { [key: string]: any } | undefined
  >(undefined);
  const [refresh, setRefresh] = React.useState(0);
  const classes = useStyles();
  //get roles for selected endpoint_type
  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        const roles = await appStore?.functionService.getAllRoles(token);
        setRoles(roles);
      }
    };
    fetchData();
  }, []);

  const handleSave = () => {
    onClose();
    saveRolesForEntryOrg(user_id, entry_org_id, selectedroles!);
    setSelectedRoles(undefined);
  };

  const closeModal = () => {
    setSelectedRoles(undefined);
    onClose();
  };
  const handleRoleSelect = (event: React.ChangeEvent<any>) => {
    selectedroles![event.target.value] = !selectedroles![event.target.value];
    setSelectedRoles(selectedroles);
    setRefresh(new Date().getTime());
  };
  if (
    entrypoints &&
    selectedroles == undefined &&
    entry_org_id != 0 &&
    entry_org_id != undefined
  ) {
    var initialselectedroles: { [key: string]: any } = {};
    entrypoints?.forEach((entrypoint) => {
      if (entrypoint.org_id == entry_org_id) {
        if (entrypoint.role != "default") {
          initialselectedroles[entrypoint.role] = true;
        }
      }
    });
    setSelectedRoles(initialselectedroles);
  }
  var role_display: React.ReactNode[] = [];
  if (roles) {
    var rolelist: React.ReactNode[] = [];

    let description = "";
    rolelist.push(
      <Typography className={classes.bold_text}>Management Roles:</Typography>
    );

    let role = roles.find((x) => x.role == "org_admin");
    if (role) {
      description = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "org_admin"
      )!;
      rolelist.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedroles ? selectedroles["org_admin"] : false}
                onChange={handleRoleSelect}
                value={"org_admin"}
              />
            }
            label={"Group Admin"}
          />
        </Tooltip>
      );
    }
    role = roles.find((x) => x.role == "site_admin");
    if (role) {
      description = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "site_admin"
      )!;
      rolelist.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedroles ? selectedroles["site_admin"] : false}
                onChange={handleRoleSelect}
                value={"site_admin"}
              />
            }
            label={"Site Admin"}
          />
        </Tooltip>
      );
    }
    role = roles.find((x) => x.role == "user_admin");
    if (role) {
      description = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "user_admin"
      )!;
      rolelist.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedroles ? selectedroles["user_admin"] : false}
                onChange={handleRoleSelect}
                value={"user_admin"}
              />
            }
            label={"User Admin"}
          />
        </Tooltip>
      );
    }
    role = roles.find((x) => x.role == "billing_admin");
    if (role) {
      description = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "billing_admin"
      )!;
      rolelist.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedroles ? selectedroles["billing_admin"] : false}
                onChange={handleRoleSelect}
                value={"billing_admin"}
              />
            }
            label={"Billing Admin"}
          />
        </Tooltip>
      );
    }

    role = roles.find((x) => x.role === "support");
    if (role) {
      description = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "support"
      )!;
      rolelist.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedroles ? selectedroles["support"] : false}
                onChange={handleRoleSelect}
                value={"support"}
              />
            }
            label={"Support"}
          />
        </Tooltip>
      );
    }
    role = roles.find((x) => x.role == "classifier_jwt");
    if (role) {
      description = appStore?.functionService.translate(
        "en_ZA",
        "user_roles_descriptions",
        "classifier_jwt"
      )!;
      rolelist.push(<div className={classes.space_smallest}></div>);
      rolelist.push(
        <Typography className={classes.bold_text}>Other Roles:</Typography>
      );
      rolelist.push(
        <Tooltip title={description!} placement="bottom">
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  selectedroles ? selectedroles["classifier_jwt"] : false
                }
                onChange={handleRoleSelect}
                value={"classifier_jwt"}
              />
            }
            label={"Inference Tokens"}
          />
        </Tooltip>
      );
    }

    if (rolelist.length > 0) {
      role_display.push(<FormGroup>{rolelist}</FormGroup>);
    }
  }
  return (
    <Dialog
      onClose={closeModal}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Add new roles to {entry_org_name}
      </DialogTitle>
      <div className="add-user-text-field-container">
        <form noValidate autoComplete="off">
          {role_display}
        </form>
      </div>
      <br />
      <div className="button-container-add-user">
        <Button
          variant="contained"
          className="add-user-cancel-button"
          onClick={closeModal}
        >
          <span className="button-text">CANCEL</span>
        </Button>
        &nbsp;&nbsp;
        <Button
          variant="contained"
          color="primary"
          className="add-user-save-button"
          onClick={handleSave}
        >
          <span className="button-text">SAVE</span>
        </Button>
      </div>
    </Dialog>
  );
};

export default AddRoleToOrgModal;
