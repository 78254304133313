import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useStyles } from "../styling/global/global";

interface Props {
  children: React.ReactNode;
}

const Card: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Paper
        className={classes.paper}
        style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.4)" }}
      >
        {children}
      </Paper>
    </Grid>
  );
};

export default Card;
