import React, { useState } from "react";
import { Dialog, DialogTitle, TextField, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "../../styling/global/global";
import { AppStore } from "../../../stores/AppStore";
import { Org } from "../../../services/FunctionService";
// TODO: frontel customer server to AS server
// import { Checkbox, FormControlLabel } from "@material-ui/core";

interface AddGroupsModalProps {
  appStore?: AppStore;
  open: boolean;
  message: string;
  onClose: () => void;
  currentOrg: Org;
  saveNewOrg: (org_name: string) => void;
}

const AddGroupsModal: React.FC<AddGroupsModalProps> = ({
  onClose,
  open,
  saveNewOrg,
  currentOrg,
  message,
  appStore,
}: AddGroupsModalProps) => {
  const classes = useStyles();
  const [charError, setCharError] = useState(false);
  // TODO: frontel customer server to AS server
  // const [frontelCharError, setFrontelCharError] = useState(false);
  // const [createFrontelOrg, setCreateFrontelOrg] = React.useState(false);
  var org_name = "";
  // var frontel_details = "";
  const handleClose = () => {
    onClose();
  };
  const handleSave = () => {
    if (!charError) {
      saveNewOrg(org_name);
    }
  };
  // TODO: frontel customer server to AS server
  // const handleCheck = (event: React.ChangeEvent<any>) => {
  //   setCreateFrontelOrg(event.target.checked);
  // };
  const handleNameChange = (event: React.ChangeEvent<any>) => {
    let letterNumber = /^[0-9a-zA-Z_ -]+$/;
    if (event.target.value.match(letterNumber)) {
      setCharError(false);
      org_name = event.target.value;
    } else {
      org_name = event.target.value;
      setCharError(true);
    }
  };
  // TODO: frontel customer server to AS server
  // const handleFrontelDetails = (event: React.ChangeEvent<any>) => {
  //   let letterNumber = /^[0-9a-zA-Z_ \-:.\/]+$/;
  //   if (event.target.value.match(letterNumber)) {
  //     setFrontelCharError(false);
  //     frontel_details = event.target.value;
  //   } else {
  //     frontel_details = event.target.value;
  //     setFrontelCharError(true);
  //   }
  // };

  if (appStore && appStore.authService.getRoles().indexOf("org_admin") >= 0) {
    return (
      <Dialog
        onClose={handleClose}
        open={open}
        className={classes.modal_dialog}
      >
        <DialogTitle className={classes.bold_text}>
          Add New Group to : {currentOrg.org_name}
        </DialogTitle>
        <Typography className={classes.bold_text} color="error">
          {message}
        </Typography>

        <TextField
          onChange={handleNameChange}
          label="Group Name"
          variant="outlined"
          margin="normal"
          fullWidth
          autoFocus
          InputProps={{
            className: classes.bold_text,
          }}
          InputLabelProps={{
            classes: {
              root: classes.bold_text,
            },
          }}
          error={charError}
          helperText={
            charError
              ? "Name must only contain numbers,letters or the following characters: _-"
              : ""
          }
        />
        {/* TODO: frontel customer server to AS server */}
        {/* { createFrontelOrg && (
          <TextField
          onChange={handleFrontelDetails}
          label="Frontel Org Detail"
          variant="outlined"
          margin="normal"
          fullWidth
          autoFocus
          InputProps={{
            className: classes.bold_text,
          }}
          InputLabelProps={{
            classes: {
              root: classes.bold_text,
            },
          }}
          error={frontelCharError}
          helperText={
            frontelCharError
              ? "Frontel details must only contain numbers,letters or the following characters: _-./:"
              : ""
          }
        />
        )}
        <FormControlLabel
          className={classes.form_component_checkbox}
          control={
            <Checkbox
              color="primary"
              checked={createFrontelOrg}
              onChange={handleCheck}
            />
          }
          label="Create Frontel Org"
        /> */}

        <div className={classes.button_container}>
          <Button
            className={classes.cancel_button}
            variant="contained"
            onClick={() => handleClose()}
          >
            <span className={classes.bold_text}>CANCEL</span>
          </Button>
          <Button
            className={classes.save_button}
            variant="contained"
            onClick={() => handleSave()}
          >
            <span className={classes.bold_text}>SAVE</span>
          </Button>
        </div>
      </Dialog>
    );
  } else {
    return (
      <Dialog
        onClose={handleClose}
        open={open}
        className={classes.modal_dialog}
      >
        <DialogTitle className={classes.bold_text}>
          Add New Group to : {currentOrg.org_name}
        </DialogTitle>
        <br></br>
        <Typography className={classes.bold_text}>
          {
            "You need group admin role to be able to add a group - please contact your DeepAlert system administrator"
          }
        </Typography>
        <div className={classes.button_container}>
          <Button
            className={classes.cancel_button}
            variant="contained"
            onClick={() => handleClose()}
          >
            <span className={classes.bold_text}>CANCEL</span>
          </Button>
        </div>
      </Dialog>
    );
  }
};

export default AddGroupsModal;
