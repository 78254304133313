import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useStyles } from "../../styling/global/global";
import { AppStore } from "../../../stores/AppStore";
import { Org, addBillingOrgResult } from "../../../services/FunctionService";
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Tooltip,
  IconButton,
  Paper,
  Card,
} from "@material-ui/core";
import LoadingSpinner from "../../../spinner/LoadingSpinner";

interface GenerateSentinelTokenProps {
  open: boolean;
  appStore?: AppStore;
  org: Org | undefined;
  onClose: () => void;
}
const GenerateSentinelToken: React.FC<GenerateSentinelTokenProps> = ({
  open,
  appStore,
  org,
  onClose,
}: GenerateSentinelTokenProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const [openResultMessage, setOpenResultMessage] = useState(false);
  const handleClose = () => {
    onClose();
  };
  useEffect(() => {
    // const retrieveToken = async () => {
    //   const token = await appStore?.authService.getAuthorisedToken();
    //   if (token) {
    //     setLoading(true);
    //     const showToken: addBillingOrgResult =
    //       await appStore?.functionService.generateSentinelToken(
    //         token,
    //         org!.org_id
    //       );
    //     if (showToken) {
    //       if (showToken.success) {
    //         setToken('Token successfully generated');
    //         setOpenResultMessage(true);
    //         setResultMessage('Token successfully generated');
    //         setLoading(false);
    //       } else {
    //         setToken('showToken.msg');
    //         setOpenResultMessage(true);
    //         setResultMessage(showToken.msg);
    //         setLoading(false);
    //       }
    //     }
    //   }
    // };
    // if (org) {
    //   retrieveToken();
    // }
  }, [org]);
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className="edit-dialog"
    >
      <DialogTitle id="simple-dialog-title">Generate Token</DialogTitle>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div
          style={{
            marginLeft: "20%",
            marginRight: "20%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {openResultMessage ? (
            <Box mt={2} mb={2} justifyContent="center">
              <Alert
                variant="outlined"
                severity={
                  resultMessage === "Token successfully generated"
                    ? "success"
                    : "error"
                }
              >
                {resultMessage}
              </Alert>
            </Box>
          ) : null}
        </div>
      )}

      <div className={classes.singleOkButton} style={{ marginBottom: 10 }}>
        <Button
          className={classes.bold_text}
          variant="contained"
          onClick={handleClose}
        >
          Dismiss
        </Button>
      </div>
    </Dialog>
  );
};

export default GenerateSentinelToken;
