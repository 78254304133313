import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../../spinner/LoadingSpinner";
import { AppStore } from "../../../stores/AppStore";
import Tooltip from "@material-ui/core/Tooltip";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { useStyles } from "../../styling/global/global";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import { Table, message as AntMessage } from "antd";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import {
  withStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Typography from "@material-ui/core/Typography";
import { User, UsersForOrg } from "../../../services/FunctionService";
import CustomModal from "../../UI/CustomModal";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";

//Dialog
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
//end

//Alert
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useStaticState } from "@material-ui/pickers";
//End

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

// Roles Names
const names = [
  "View Alerts",
  "View History",
  "View Escalations",
  "Mute Controls",
  "Camera filter",
  "Bulk Actions",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// end

interface GroupAssignmentProps {
  appStore?: AppStore;
}

// END OF Full Screen Modal
interface MockEvent {
  (event: Event): any;
}

// Switch Styles
const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch);

// WithStyles
const useStyle = makeStyles({
  paper: {
    border: "2px solid #6D809D",
    color: "#15325F",
  },
});

const GroupAssignment: React.FC<GroupAssignmentProps> = ({
  appStore,
}: GroupAssignmentProps) => {
  const [loading, setLoading] = React.useState(true);
  let [isEditable, setIsEditable] = React.useState(false);
  const [selectAll, setSelectAll] = React.useState(false);
  const [unSelectAll, setUnSelectAll] = React.useState(false);
  const [orgslist, setOrgsList] = React.useState<any>([]);
  const [groupid, setGroupId] = React.useState(
    appStore?.authService.getEntryOrg()?.id!
  );
  const [groupname, setGroupName] = React.useState(
    appStore?.authService.getEntryOrg()?.name || ""
  );
  const [selectedGroupId, setSelectedGroupId] = React.useState(-1);
  const [selectedGroupName, setSelectedGroupName] = React.useState("");
  const [value, setValue] = React.useState(0);
  const [usersfortree, setUsersForTree] = useState<User[] | undefined>([]);
  const [userId, setUserId] = useState<number | undefined>(-1);
  const [alertchannels, setAlertChannels] = useState<{ [key: string]: any }[]>(
    []
  );
  const [usersDefaultText, setUsersDefaultText] =
    useState("Please Select User");

  // Users & Roles
  const [allUsers, setAllUsers] = React.useState(false);
  const [userFullName, setUserFullName] = useState("");
  const [roleNames, setRoleNames] = React.useState<string[] | any>([]);
  let [channelsData, setChannelsData] = useState<number[]>([]);

  // Dialog
  const [open, setOpen] = React.useState(false);
  // Toast
  const [openToast, setOpenToast] = React.useState(false);
  const [err, setErr] = React.useState(false);
  const [message, setMessage] = React.useState("");

  //Fetching Roles Containers
  const [endPointRoles, setEndPointRoles] = useState<string | undefined[]>([]);

  //Fetching Users For specific channel
  const [channelEndPointId, setChannelEndPointId] = useState<number>();
  const [usersList, setUsersList] = useState<User[]>([]);
  const [tableMessage, setTableMessage] = useState("");
  const [loadingTable, setLoadingTable] = useState(false);
  const [enabled, setEnabled] = useState(false);

  const [data, setData] = React.useState<any>([]);

  const [orgToBeRemoved, setOrgToBeRemoved] = useState(-1);
  const [openDelete, setOpenDelete] = React.useState(false);

  const columns: any = [
    {
      title: "Org ID",
      width: 100,
      dataIndex: "org_id",
      key: "org_id",
    },
    {
      title: "Org Name",
      width: 100,
      dataIndex: "org_tree_names",
      key: "org_name",
      render: (_: any, record: any) => {
        let tree = record.org_tree_names.map((item: any) => ` / ${item} `);
        let treeString = tree.toString();
        const rpl = treeString.replace(/,/g, "");
        return <span>{rpl}</span>;
      },
    },
    {
      title: "Unassign group",
      key: "org_id",
      dataIndex: "org_id",
      fixed: "right",
      width: 100,
      render: (item: any, record: any) => (
        <a
          onClick={() => {
            // setActivityDetail(
            //   JSON.parse(
            //     JSON.stringify(record)
            //       .replace(/true/g, '"true"')
            //       .replace(/false/g, '"false"')
            //   )
            // );
            // setActivityDetailJson(JSON.stringify(record));
            // showModal();

            setOrgToBeRemoved(item);
            setOpenDelete(true);
          }}
        >
          Unassign
        </a>
      ),
    },
  ];

  const getBaseUrl = (): string => {
    let base = "";
    if (window.location.href.includes("local")) {
      base = "https://manage.staging.deepalert.ai";
    }
    base += "/api/v1.3";
    return base;
  };

  if (message) {
    setTimeout(function () {
      setMessage("");
    }, 20000); //20 Seconds delay
  }

  const handleClickOpen = (id?: number) => {
    setOpen(true);
    if (id) {
      setChannelEndPointId(id);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToastOpen = () => {
    setOpenToast(true);
  };

  const handleToastClose = () => {
    setMessage("");
    setOpenToast(false);
    setChannelsData(channelsData);
  };

  //Radio
  const [selectedValue, setSelectedValue] = React.useState("mainGroup");
  const [includeSubs, setIncludeSubs] = React.useState(false);

  const styler = useStyle();

  // STYLING SECTION
  const classes = useStyles();
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        "&:hover": {
          backgroundColor: "#e3f2fd",
        },
      },
    })
  )(TableRow);

  //end

  async function deleteUserWebOrg() {
    setLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let delete_web_org = await appStore?.functionService.deleteUserWebOrgs(
        token,
        userId!,
        orgToBeRemoved.toString()
      );
      if (delete_web_org) {
        if (delete_web_org.success) {
          AntMessage.success("successfully unassigned group");
        } else {
          AntMessage.error("Failed to unassign group");
        }
      }
      await fetchUserWebOrgsData();
      setLoading(false);
    }
  }

  //FUNCTIONS SECTION
  const handleAllUsersChange = (value: string) => {
    handleToastClose();
    if (value === "subgroups") {
      setSelectedValue("subgroups");
      setIncludeSubs(true);
      setAllUsers(false);
    }
    if (value === "mainGroup") {
      setSelectedValue("mainGroup");
      setIncludeSubs(false);
      setAllUsers(false);
    }
    if (value === "allUsers") {
      setSelectedValue("allUsers");
      setAllUsers(true);
    }

    setUserFullName("");
    setIsEditable(false);
  };

  const handleChangeAuto = (value: any) => {
    handleToastClose();
    setUserFullName("");
    setRoleNames([]);
    if (!value) {
      return setGroupId(-1);
    }
    const new_group_id = +value.org_id;
    setGroupId(new_group_id);
    let group = orgslist.find((x: any) => x.org_id === value.org_id);
    if (group) {
      setGroupName(group.org_name);
    }
    setIsEditable(false);
  };

  const handleSelectedChangeAuto = (value: any) => {
    if (!value) {
      return setGroupId(-1);
    }
    const new_group_id = +value.org_id;
    setSelectedGroupId(new_group_id);
    let group = orgslist.find((x: any) => x.org_id === value.org_id);
    if (group) {
      setSelectedGroupName(group.org_name);
    }
  };

  const handleChangeUser = (value: any) => {
    handleToastClose();
    setRoleNames([]);
    setIsEditable(false);
    let new_user_id = +value.user_id;
    let user = usersfortree!.find((x) => x.user_id === new_user_id);
    setUserId(new_user_id);
    if (user) {
      setUserFullName(`${user.first_name} ${user.last_name}`);
    }
  };

  const handleChangeUsers = (event: React.ChangeEvent<any>) => {
    console.log(
      `User ID is ${event.target.value} and Channel Data is: ${channelsData}`
    );
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const fetchOrgsData = async () => {
    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    if (token) {
      try {
        const request = JSON.stringify({
          enabled: true,
          paginate: false,
          verbose: false,
          incl_tree_names: true,
        });

        const res = fetch(baseUrl + "/list_orgs", {
          method: "post",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        const result = await (await res).json();
        const orglist = result;
        if (orglist && orglist.success) {
          let orgz = orglist?.orgs;
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names - b.org_tree_names;
          });
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names.length - b.org_tree_names.length;
          });
          setOrgsList(orgz);
          setLoading(false);
        }
      } catch (err) {
        return err;
      }

      //Fetch end
    }
  };

  //************* Fetching Data Functions *************/
  // Fetch Organizations
  useEffect(() => {
    fetchOrgsData();
  }, [groupid]);

  // 2)Fetching Users
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setUserFullName("");
      setUsersDefaultText("Please Select User");
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && groupid >= 0) {
        let usersData: User[] = [];
        if (allUsers) {
          usersData = await appStore!.functionService.getAllVisibleUsers(
            token,
            appStore!.authService.getLoggedInOrg(),
            enabled
          );
        }

        if (!allUsers && includeSubs) {
          usersData = await getAllUsersForOrg(token, groupid, false, enabled);
        }

        if (!allUsers && !includeSubs) {
          usersData = await getAllUsersForOrg(token, groupid, true, enabled);
        }

        if (usersData === undefined || usersData.length === 0) {
          setUsersDefaultText("No Users Found");
        }
        setUserId(-1);
        setUsersForTree(usersData);
        // setLoading(false);
      }
      setLoading(false);
    };
    fetchData();
  }, [groupid, allUsers, includeSubs, enabled]);

  // Org Content Setup
  let orgsContent;
  if (orgslist && orgslist.length > 0) {
    orgsContent = orgslist.map((org: any) => (
      <MenuItem value={org.org_id}>
        <span className={classes.bold_text}>{org.org_name}</span>
      </MenuItem>
    ));
  }

  // Fetch Users Function (All Users and For Specific Org Tree)
  const getAllUsersForOrg = async (
    access_token: string,
    top_org_id: number,
    top_org_only: boolean,
    enabled: boolean
  ): Promise<User[] | any> => {
    var baseUrl = getBaseUrl();
    try {
      const request = JSON.stringify({
        top_org_id: top_org_id,
        order_by: "username",
        top_org_only: top_org_only,
        paginate: false,
        verbose: true,
        enabled: enabled ? null : true,
      });

      const res = fetch(baseUrl + "/list_users", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UsersForOrg;
      let ret = result.users;
      return ret;
    } catch (err) {
      return err;
    }
  };

  //Users Content Setup
  let usersContent;
  if (usersfortree && usersfortree.length > 0) {
    usersContent = usersfortree.map((user) => (
      <MenuItem value={user.user_id}>
        <span className={classes.bold_text}>
          {user.first_name} {user.last_name}
        </span>
      </MenuItem>
    ));
  }

  // This Function Transforms WebRoles From Server to Interface
  const transformDataFromServer = (userRoles: string[]) => {
    const transformedData = userRoles.map((role: string) => {
      if (role === "web_alerts") {
        return "View Alerts";
      }

      if (role === "web_alert_history") {
        return "View History";
      }

      if (role === "web_mute_controls") {
        return "Mute Controls";
      }

      if (role === "web_alert_escalations") {
        return "View Escalations";
      }

      if (role === "web_alert_camera_filter") {
        return "Camera filter";
      }
      if (role === "web_bulk_actions") {
        return "Bulk Actions";
      }
    });

    if (transformedData.length > 0) {
      transformedData!.sort((a: any, b: any) => {
        return a.length - b.length;
      });
    }

    return transformedData;
  };

  //DIALOG TABLE CONTENT
  /***************************************************************/
  var usersListDisplay: React.ReactNode[] = [];
  if (usersList && usersList.length > 0) {
    usersList.forEach((user: { [key: string]: any }, index: any) => {
      const transformedData = transformDataFromServer(user.web_roles);
      usersListDisplay.push(
        <StyledTableRow>
          <TableCell>
            <Typography className={classes.bold_text}>
              {user.first_name}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {user.last_name}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              {user.username}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.bold_text}>
              <span style={{ textAlign: "center", paddingLeft: 20 }}>
                {transformedData.length}
              </span>
            </Typography>
          </TableCell>
          <TableCell>
            <div>
              {transformedData.map((role: any) => {
                return (
                  <li key={role} style={{ listStyle: "none" }}>
                    <Chip
                      // icon={icon}
                      label={role}
                      className={classes.chip}
                    />
                  </li>
                );
              })}
            </div>
          </TableCell>
        </StyledTableRow>
      );
    });
  }

  /***************************************************************************/
  //Fetch Users For Specific Channel
  useEffect(() => {
    const fetchUsersList = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && channelEndPointId) {
        setLoadingTable(true);
        const popUpUsersList =
          await appStore?.functionService.getUsersForEndPoint(
            token,
            channelEndPointId
          );

        if (popUpUsersList) {
          setUsersList(popUpUsersList);
          setLoadingTable(false);
        } else {
          setTableMessage("No Users Found");
          setLoadingTable(false);
        }
      }
    };
    fetchUsersList();
  }, [channelEndPointId]);

  const fetchUserWebOrgsData = async () => {
    setLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token && userId && userId! > -1) {
      let data = await appStore?.functionService.getUserWebOrgs(token, userId!);

      const orgs_data = orgslist.filter(
        (item: any) => data.org_ids.indexOf(item.org_id) > -1
      );
      setData(orgs_data);
    } else {
      console.log(`User Logs Data: failed `);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserWebOrgsData();
  }, [groupid, userId]);

  //Fetch User Roles Using User ID:
  useEffect(() => {
    const fetchUserRoles = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      var baseUrl = getBaseUrl();
      setLoading(true);
      if (token && userId! >= 0) {
        try {
          let request = undefined;
          if (groupid !== -1) {
            request = JSON.stringify({
              top_org_id: groupid,
              user_id: userId,
            });
          } else {
            request = JSON.stringify({
              top_org_only: false,
            });
          }

          const res = await fetch(
            baseUrl + `/user_web_roles?user_id=${userId}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
              },
            }
          );
          if (!res.ok) {
            handleToastOpen();
            setErr(true);
            setMessage("Something went wrong, please try again");
          }
          const result = (await (await res).json()) as any;

          const userRolesList = result.web_roles;

          if (userRolesList.length > 0) {
            const transformedData = transformDataFromServer(userRolesList);
            setRoleNames(transformedData);
            setEndPointRoles(result.web_roles);
          }
          setLoading(false);
        } catch (err) {
          console.log(err);
        }
      }
      setLoading(false);
    };
    fetchUserRoles();
  }, [userId]);

  // Remove Duplicate Values in Array Function
  function getUnique(arr: any) {
    let uniqueArr = [];

    // loop through array
    for (let i of arr) {
      if (uniqueArr.indexOf(i) === -1) {
        uniqueArr.push(i);
      }
    }
    return uniqueArr;
  }

  const assignGroup = async () => {
    setLoading(true);
    var baseUrl = getBaseUrl();
    const lastSnapShot = getUnique(channelsData);
    var token = await appStore?.authService.getAuthorisedToken();
    setMessage("");
    if (token && groupid !== -1) {
      try {
        let request = JSON.stringify({
          user_id: userId,
          org_ids: `${selectedGroupId}`,
        });

        const res = await fetch(baseUrl + "/user_web_orgs", {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        if (!res.ok) {
          setErr(true);
          setSelectAll(false);
          setUnSelectAll(false);
          setMessage("Something went wrong, please try again");
        }
        const result = (await (await res).json()) as any;
        const msg = result.msg;
        handleToastOpen();

        if (result.success) {
          setErr(false);
          setMessage(
            `Your changes for ${userFullName} have been successfully saved`
          );
          setSelectAll(false);
          setUnSelectAll(false);
          //   setUserId(-1)
          //   setUserFullName('')
          setSelectedGroupId(-1);
          setSelectedGroupName("");
        }

        if (!result.success) {
          setErr(true);
          setChannelsData(lastSnapShot);
          setMessage(msg);
        }

        fetchUserWebOrgsData();
      } catch (err) {
        console.log(err);
      }
    }
    setLoading(false);
    window.scrollTo(0, document.body.scrollHeight);
  };

  return (
    <div>
      {!loading && (
        <div style={{ minWidth: 1200 }}>
          <FormControl
            className={classes.form_component_medium}
            variant="outlined"
            fullWidth={true}
          >
            <div style={{ marginBottom: 3 }}>
              <h5 className={classes.bold_text}>
                Select Group to filter Users <br />{" "}
                {/* <p style={{ fontSize: 11, color: "#9e9e9e" }}>
                  (This is for Rulesets with Alert Web Interface Delivery
                  Method)
                </p> */}
              </h5>
            </div>

            <Autocomplete
              id="combo-box-demo"
              className={classes.form_component_medium}
              options={orgslist}
              onChange={(event: React.ChangeEvent<any>, value: any) => {
                handleChangeAuto(value);
              }}
              getOptionLabel={(option) => {
                let tree = option.org_tree_names!.map(
                  (item: any) => `   /   ${item} `
                );
                let treeString = tree.toString();
                const rpl = treeString.replace(/,/g, "");
                return `${rpl}`;
              }}
              style={{ width: 600, color: "#6D809D" }}
              classes={{ paper: styler.paper }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <span
                      className={classes.bold_text}
                      style={{ color: "#15325F" }}
                    >
                      {groupname ? groupname : "Please Select Group"}
                    </span>
                  }
                  variant="outlined"
                  value={groupid}
                  className={classes.bold_text}
                />
              )}
            />
          </FormControl>

          <Divider variant="middle" style={{ margin: 20 }} />

          {message && <div style={{ marginTop: 30 }} />}
          <Collapse in={openToast}>
            {message && (
              <div style={{ marginBottom: 35 }}>
                <Alert
                  severity={err ? "error" : "success"}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        handleToastClose();
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {message}
                </Alert>
              </div>
            )}
          </Collapse>

          <FormControl
            className={classes.form_component_medium}
            variant="outlined"
            fullWidth={false}
          >
            <h2 className={classes.bold_text} style={{ marginBottom: 25 }}>
              Users
            </h2>
            <Autocomplete
              id="combo-box-demo"
              options={usersfortree!}
              onChange={(event: React.ChangeEvent<any>, value: any) => {
                handleChangeUser(value);
              }}
              getOptionLabel={(option) => {
                return `${option.first_name} ${option.last_name} (${option.username})`;
              }}
              renderOption={(option: any) => {
                if (!option.enabled) {
                  return (
                    <span style={{ color: "red" }}>
                      {option.first_name} {option.last_name} - (
                      {option.username})
                    </span>
                  );
                }
                return (
                  <span>
                    {option.first_name} {option.last_name} - {option.username}
                  </span>
                );
              }}
              style={{ width: 300, color: "#6D809D", marginLeft: 10 }}
              classes={{ paper: styler.paper }}
              renderInput={
                (params) =>
                  // (
                  {
                    return (
                      <TextField
                        {...params}
                        label={
                          <span className={classes.bold_text}>
                            {userFullName ? userFullName : "Please Select User"}
                          </span>
                        }
                        variant="outlined"
                        value={userId}
                        className={classes.bold_text}
                      />
                    );
                  }
                // )
              }
            />
          </FormControl>

          {/* Users' Filter Options */}
          <FormControl
            className={classes.form_component_medium}
            variant="outlined"
            fullWidth={false}
          >
            <div>
              <h2 className={classes.header_text}>Users' Filter Options</h2>
              <div style={{ width: 300 }}>
                <RadioGroup
                  aria-label="gender"
                  name="Users"
                  value={selectedValue}
                  onChange={(e) => handleAllUsersChange(e.target.value)}
                >
                  <FormControlLabel
                    value="subgroups"
                    control={<Radio />}
                    label="Include subgroups"
                    // label='Include subgroups of selected group'
                  />
                  <FormControlLabel
                    value="mainGroup"
                    control={<Radio />}
                    label="Exclude subgroups"
                    // label='Exclude subgroups of selected group'
                  />
                </RadioGroup>
                {/* <br />
                <FormControl>
                  <Checkbox
                    // className={classes.form_component_checkbox}
                    // disabled={true}
                    onChange={() => {
                      setEnabled((prev) => !prev);
                    }}
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    checked={enabled}
                  />
                </FormControl>
                <FormControl>
                  <Typography className={classes.header_text}>
                    <div style={{ paddingBottom: 14, color: "#ee4623" }}>
                      Include Disabled Users
                    </div>
                  </Typography>
                </FormControl> */}
              </div>
            </div>

            {/* Multiple Select for User Roles*/}
          </FormControl>
          {userId! >= 0 && (
            <div>
              <FormControl className={classes.form_component_medium}>
                <h2
                  className={classes.bold_text}
                  style={{ marginTop: 25, marginBottom: 25 }}
                >
                  Select Group for Alert Web Interface
                  <br />{" "}
                  <p style={{ fontSize: 12, color: "#9e9e9e", marginTop: 10 }}>
                    (subgroups automatically included)
                  </p>
                </h2>

                {/* <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  disabled={!!otherEndPointRoles.length}
                  value={roleNames}
                  onChange={handleChange}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {(selected as string[]).map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {names.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={getStyles(name, personName, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select> */}
                <Autocomplete
                  id="combo-box-demo"
                  className={classes.form_component_medium}
                  options={orgslist}
                  onChange={(event: React.ChangeEvent<any>, value: any) => {
                    handleSelectedChangeAuto(value);
                  }}
                  getOptionLabel={(option) => {
                    let tree = option.org_tree_names!.map(
                      (item: any) => `   /   ${item} `
                    );
                    let treeString = tree.toString();
                    const rpl = treeString.replace(/,/g, "");
                    return `${rpl}`;
                  }}
                  style={{ width: 600, color: "#6D809D" }}
                  classes={{ paper: styler.paper }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <span
                          className={classes.bold_text}
                          style={{ color: "#15325F" }}
                        >
                          {selectedGroupName
                            ? selectedGroupName
                            : "Please Select Group"}
                        </span>
                      }
                      variant="outlined"
                      value={selectedGroupId}
                      className={classes.bold_text}
                    />
                  )}
                />
              </FormControl>

              <span style={{ marginLeft: 50, width: 30, marginTop: 50 }}>
                <FormControl className={classes.form_component_small}>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <Button
                    style={{ width: 170, marginTop: 15 }}
                    variant="contained"
                    className={classes.bold_text}
                    onClick={() => {
                      assignGroup();
                    }}
                    disabled={selectedGroupId === -1}
                  >
                    Add Group
                  </Button>
                </FormControl>
              </span>
            </div>
          )}
          {data.length > 0 && userId !== -1 && (
            <Grid item xs={12} sm={12} md={12} spacing={0}>
              <Table
                style={{ marginTop: 100 }}
                columns={columns}
                dataSource={data}
                scroll={{ y: 500 }}
                loading={loadingTable}
              />
            </Grid>
          )}
        </div>
      )}
      {loading && <LoadingSpinner />}
      <CustomModal
        closeHandler={() => {
          setOpenDelete(false);
        }}
        open={openDelete}
        title={"confirm"}
        continueAction={true}
        onContinueHandler={() => {
          deleteUserWebOrg();
          setOpenDelete(false);
        }}
      >
        <h1
          style={{
            fontSize: 14,
            color: "#15325F",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          WARNING!
        </h1>
        <br />
        <p
          style={{
            fontSize: 14,
            color: "#f44336",
            fontWeight: "bold",
            textAlign: "center",
            marginLeft: 30,
            marginRight: 30,
          }}
        >
          Are you sure you want to unassign this group?
        </p>
      </CustomModal>
    </div>
  );
};

export default GroupAssignment;
