import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AppStore } from "../../../../stores/AppStore";
import { useStyles } from "../../../styling/global/global";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { listBillingRunsItems } from "../../../../services/FunctionService";

interface DeleteBillingRunProps {
  open: boolean;
  appStore?: AppStore;
  run: listBillingRunsItems | undefined;
  onClose: () => void;
  deleteBillingRun: (billing_run_id: number) => void;
}

const DeleteBillingRun: React.FC<DeleteBillingRunProps> = ({
  open,
  onClose,
  appStore,
  run,
  deleteBillingRun,
}: DeleteBillingRunProps) => {
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        display: "flex",
        justifyContent: "space-around",
        textAlign: "left",
        paddingLeft: "2%",
        paddingRight: "2%",
      },
    })
  )(TableRow);

  const classes = useStyles();

  useEffect(() => {}, []);
  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
        fullWidth={true}
      >
        <DialogTitle
          style={{ alignSelf: "center" }}
          className={classes.bold_text}
          id="simple-dialog-title"
        >
          <span style={{ fontSize: "25px" }}>Delete Billing Run </span>
        </DialogTitle>
        <TableContainer>
          <StyledTableRow>
            <TableCell>
              <Typography className={classes.bold_text}>
                Are you sure you want to delete the following billing run:
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.bold_text}>
                {run ? run.name : ""} ?
              </Typography>
            </TableCell>
          </StyledTableRow>
        </TableContainer>
        <div
          style={{ marginTop: "5%", marginBottom: "2%" }}
          className={classes.twoButtonsInARow}
        >
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => onClose()}
          >
            <span className={classes.bold_text}>Dismiss</span>
          </Button>
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => {
              if (run) {
                deleteBillingRun(run.id);
                onClose();
              }
            }}
          >
            <span className={classes.bold_text}>Delete</span>
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default DeleteBillingRun;
