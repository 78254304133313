import React from "react";

interface ErrorBoundaryProps {
  children?: any;
}

interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

class ErrorBoundary extends React.PureComponent<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error({
      error,
      errorInfo,
    });

    this.setState({
      error,
      errorInfo,
    });
  }

  didError(): boolean {
    const { error, errorInfo } = this.state;
    return !!error || !!errorInfo;
  }

  render(): React.ReactNode {
    if (this.didError()) {
      return (
        <div>
          <h1>An error occurred. See console for details.</h1>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

export { ErrorBoundary };
