import React from "react";
import classes from "./ui.module.css";

interface ListItemProps {
  title: string;
  event_type: string;
  cameras: string[];
  site_id: number;
  date: string;
}

const ListItem: React.FC<ListItemProps> = ({
  title,
  event_type,
  cameras,
  site_id,
  date,
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <p className={classes["item-wrapper"]}>
        {title.replace(",", ", ")} | Event Type:{" "}
        <span style={{ color: "grey" }}>{event_type}</span>
      </p>
      <p className={classes["item-wrapper"]}>
        Site ID: <span style={{ color: "grey" }}>{site_id}</span> | Cameras:{" "}
        <span style={{ color: "grey" }}>{cameras}</span> | Date:{" "}
        <span style={{ color: "grey" }}>{date} (UTC)</span>
      </p>
    </div>
  );
};

export default ListItem;
