import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "../../../styling/global/global";
import { Typography } from "@material-ui/core";
interface MessagePopUpProps {
  open: boolean;
  message: string;
  onClose: () => void;
}
const MessagePopUp: React.FC<MessagePopUpProps> = ({
  message,
  open,
  onClose,
}: MessagePopUpProps) => {
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className="edit-dialog"
    >
      <DialogTitle id="simple-dialog-title">Error</DialogTitle>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "5%",
          paddingRight: "5%",
          marginBottom: "5%",
        }}
      >
        <Typography className={classes.bold_text}>{message}</Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          className="buttons-container"
        >
          <Button variant="contained" className="save" onClick={handleClose}>
            OK
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default MessagePopUp;
