import React from "react";
import { Divider, IconButton } from "@material-ui/core";
import { useStyles } from "../../styling/global/global";
import {
  SiteHealth,
  Activity,
  SensorHealth,
  HealthTimelineCount,
  SensorHealthTimelineCount,
} from "../../../services/FunctionService";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Grid from "@material-ui/core/Grid";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  Label,
  BarChart,
  Bar,
  AreaChart,
  Area,
} from "recharts";

import { withStyles, makeStyles } from "@material-ui/core/styles";
interface DashboardRightDrawerProps {
  isDashboard?: boolean;
  mid?: any;
  type?: string;
  site_id?: number;
  showFullModalHandler: (params: (number | string)[]) => void;
  siteHealthModal: (params: (number | string)[], site_id?: number) => void;
  health?: SiteHealth[];
  activity?: Activity;
  healthtimeline?: HealthTimelineCount[];
  sensorhealth?: SensorHealth;
  sensor_id_str?: string;
  interval: number;
  setIntervalvalue: (x: number) => void;
  sensorhealthtimeline?: SensorHealthTimelineCount[];
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active) {
    return (
      <div className="custom-tooltip">
        <p
          className="label"
          style={{
            color: "white",
            fontWeight: 800,
            backgroundColor: "black",
            padding: 7,
            borderRadius: 10,
          }}
        >{`${label}`}</p>
      </div>
    );
  }

  return null;
};

const DashboardRightDrawer: React.FC<DashboardRightDrawerProps> = ({
  mid,
  type,
  site_id,
  siteHealthModal,
  showFullModalHandler,
  setIntervalvalue,
  sensor_id_str,
  interval,
  health,
  activity,
  healthtimeline,
  sensorhealth,
  sensorhealthtimeline,
  isDashboard,
}: DashboardRightDrawerProps) => {
  const classes = useStyles();

  // React.useEffect(() => {
  //   alert('interval -> '+interval)
  // }, [])

  const addZero = (str: string) => {
    if (str.length == 1) {
      str = "0" + str;
    }
    return str;
  };
  const marks = [
    {
      value: 2,
      label: "2H",
    },
    {
      value: 17,
      label: "4H",
    },
    {
      value: 29,
      label: "8H",
    },
    {
      value: 44,
      label: "12H",
    },
    {
      value: 62,
      label: "24H",
    },
    {
      value: 84,
      label: "48H",
    },
    {
      value: 110,
      label: "1 Wk",
    },
    {
      value: 148,
      label: "2 Wks",
    },
  ];

  const handleIntervalChange = (
    event: React.ChangeEvent<any>,
    interval: number | number[]
  ) => {
    setIntervalvalue(interval as number);
  };
  var timeseries: React.ReactNode[] = [];
  if (activity && (healthtimeline || sensorhealthtimeline)) {
    timeseries.push(
      <Typography className={classes.center_text}>Activity</Typography>
    );
    let data = [];
    for (var i = 0; i < activity.inference_counts.length; i++) {
      var time = new Date(activity.inference_counts[i].epoch_msecs);
      var hours = time.getHours();
      var minutes = time.getMinutes();
      var x = addZero(hours.toString()) + ":" + addZero(minutes.toString());
      if (interval > 42) {
        var day = time.getDate();
        var month = time.getMonth() + 1;
        x =
          addZero(day.toString()) +
          "/" +
          addZero(month.toString()) +
          " (" +
          addZero(hours.toString()) +
          ":" +
          addZero(minutes.toString()) +
          ")  ";
      }
      var tmp: { [key: string]: any } = {};
      tmp["time"] = x;
      tmp["inferences"] = activity.inference_counts[i].count;

      for (var j = 0; j < activity.alert_counts.length; j++) {
        if (
          activity.inference_counts[i].epoch_msecs ==
          activity.alert_counts[j].epoch_msecs
        )
          tmp["alerts"] = activity.alert_counts[j].count;
      }
      if (!("alerts" in tmp)) {
        tmp["alerts"] = 0;
      }
      data.push(tmp);
    }

    if (activity.inference_counts.length === 0) {
      for (var i = 0; i < activity.alert_counts.length; i++) {
        var time = new Date(activity.alert_counts[i].epoch_msecs);
        var hours = time.getHours();
        var minutes = time.getMinutes();
        var x = addZero(hours.toString()) + ":" + addZero(minutes.toString());
        if (interval > 42) {
          var day = time.getDate();
          var month = time.getMonth() + 1;
          x =
            addZero(day.toString()) +
            "/" +
            addZero(month.toString()) +
            " " +
            addZero(hours.toString()) +
            ":" +
            addZero(minutes.toString());
        }
        var tmp: { [key: string]: any } = {};
        tmp["time"] = x;
        if (!("alerts" in tmp)) {
          tmp["alerts"] = 0;
        }
        tmp["alerts"] = activity.alert_counts[i].count;
        data.push(tmp);
      }
    }

    timeseries.push(
      <AreaChart
        width={!isDashboard ? 380 : 570}
        height={!isDashboard ? 200 : 210}
        data={data}
        margin={{ top: 2, bottom: 2, left: 2, right: 2 }}
        onClick={(e, i) => {
          if (
            !e ||
            isDashboard ||
            !e.activePayload ||
            !e.activePayload[0]?.payload?.time
            // !e.activePayload[0]?.payload?.inferences
          ) {
            return;
          }

          showFullModalHandler([
            e.activePayload[0].payload.time,
            e.activePayload[0].payload.inferences,
            e.activePayload[0].payload.alerts,
            interval,
            type,
            mid,
          ]);
        }}
      >
        <Legend />
        <Tooltip />
        <YAxis
          type="number"
          tick={{ fontSize: 12 }}
          allowDecimals={false}
          domain={["dataMin", "dataMax"]}
        />
        <XAxis dataKey="time" tick={{ fontSize: 12 }} />
        <CartesianGrid stroke="#f5f5f5" />
        <Area
          style={{ cursor: "pointer" }}
          dataKey="alerts"
          type="monotone"
          stroke="#ee4623"
          fill="#ee4623"
          onClick={(e, i) => {}}
        />
        <Area
          style={{ cursor: "pointer" }}
          dataKey="inferences"
          type="monotone"
          stroke="#3f54a5"
          fill="#3f54a5"
          onClick={(e, i) => {}}
        />
      </AreaChart>
    );

    var yMax = 0;
    if (healthtimeline) {
      let healthtimelinedata = [];
      for (var i = 0; i < healthtimeline.length; i++) {
        var time = new Date(healthtimeline[i].epoch_msecs);
        var hours = time.getHours();
        var minutes = time.getMinutes();
        var x = addZero(hours.toString()) + ":" + addZero(minutes.toString());
        if (interval > 42) {
          var day = time.getDate();
          var month = time.getMonth() + 1;
          x =
            addZero(day.toString()) +
            "/" +
            addZero(month.toString()) +
            " " +
            addZero(hours.toString()) +
            ":" +
            addZero(minutes.toString());
        }
        var tmp: { [key: string]: any } = {};
        tmp["time"] = x;
        if (healthtimeline[i].site_count > yMax) {
          yMax = healthtimeline[i].site_count;
          if (yMax === 3) {
            yMax = 4;
          }
        }
        if ("ok" in healthtimeline[i].indicators) {
          tmp["healthy"] =
            (healthtimeline[i].indicators["ok"] / healthtimeline[i].doc_count) *
            healthtimeline[i].site_count;
        } else {
          tmp["healthy"] = 0;
        }
        if ("warning" in healthtimeline[i].indicators) {
          tmp["degraded"] =
            (healthtimeline[i].indicators["warning"] /
              healthtimeline[i].doc_count) *
            healthtimeline[i].site_count;
        } else {
          tmp["degraded"] = 0;
        }
        if ("error" in healthtimeline[i].indicators) {
          tmp["unhealthy"] =
            (healthtimeline[i].indicators["error"] /
              healthtimeline[i].doc_count) *
            healthtimeline[i].site_count;
        } else {
          tmp["unhealthy"] = 0;
        }
        if ("unknown" in healthtimeline[i].indicators) {
          tmp["unknown"] =
            (healthtimeline[i].indicators["unknown"] /
              healthtimeline[i].doc_count) *
            healthtimeline[i].site_count;
        } else {
          tmp["unknown"] = 0;
        }
        healthtimelinedata.push(tmp);
      }
      // Test condition
      if (!sensor_id_str && !isDashboard) {
        timeseries.push(
          <Typography className={classes.center_text}>Site Health</Typography>
        );
        timeseries.push(
          <BarChart
            width={380}
            height={200}
            data={healthtimelinedata}
            margin={{ top: 2, bottom: 2, left: 2, right: 2 }}
          >
            <Legend />
            <Tooltip content={<CustomTooltip />} />
            <YAxis
              onClick={() => {
                alert("Clicked graph Y-Axis");
              }}
              type="number"
              tick={{ fontSize: 12 }}
              domain={[0, yMax]}
              allowDecimals={false}
            />
            <XAxis
              onClick={() => {
                alert("Clicked graph X-Axis");
              }}
              dataKey="time"
              tick={{ fontSize: 12 }}
            />
            <CartesianGrid stroke="#f5f5f5" />
            <Bar
              style={{ cursor: "pointer" }}
              stackId="a"
              dataKey="healthy"
              fill="#3f54a5"
              onClick={(e) => {
                if (site_id) {
                  siteHealthModal(
                    [e.time, sensor_id_str, e.healthy, interval],
                    site_id
                  );
                } else {
                  siteHealthModal([e.time, sensor_id_str, e.healthy, interval]);
                }
              }}
            />
            <Bar
              style={{ cursor: "pointer" }}
              stackId="a"
              dataKey="degraded"
              fill="#b4d5fa"
              onClick={(e) => {
                if (site_id) {
                  siteHealthModal(
                    [e.time, sensor_id_str, e.healthy, interval],
                    site_id
                  );
                } else {
                  siteHealthModal([e.time, sensor_id_str, e.healthy, interval]);
                }
              }}
            />
            <Bar
              style={{ cursor: "pointer" }}
              stackId="a"
              dataKey="unhealthy"
              fill="#ee4623"
              onClick={(e) => {
                if (site_id) {
                  siteHealthModal(
                    [e.time, sensor_id_str, e.healthy, interval],
                    site_id
                  );
                } else {
                  siteHealthModal([e.time, sensor_id_str, e.healthy, interval]);
                }
              }}
            />
            <Bar
              style={{ cursor: "pointer" }}
              stackId="a"
              dataKey="unknown"
              fill="#D3D3D3"
              onClick={(e) => {
                if (site_id) {
                  siteHealthModal(
                    [e.time, sensor_id_str, e.healthy, interval],
                    site_id
                  );
                } else {
                  siteHealthModal([e.time, sensor_id_str, e.healthy, interval]);
                }
              }}
            />
          </BarChart>
        );
      }
    }
    // end of test function
    var ySensorMax = 0;
    if (sensorhealthtimeline && sensor_id_str) {
      let sensorhealthtimelinedata = [];
      for (var i = 0; i < sensorhealthtimeline.length; i++) {
        var time = new Date(sensorhealthtimeline[i].epoch_msecs);
        var hours = time.getHours();
        var minutes = time.getMinutes();
        var x = addZero(hours.toString()) + ":" + addZero(minutes.toString());
        if (interval > 42) {
          var day = time.getDate();
          var month = time.getMonth() + 1;
          x =
            addZero(day.toString()) +
            "/" +
            addZero(month.toString()) +
            " " +
            addZero(hours.toString()) +
            ":" +
            addZero(minutes.toString());
        }
        var tmp: { [key: string]: any } = {};
        tmp["time"] = x;
        ySensorMax = 1;
        if (sensor_id_str in sensorhealthtimeline[i].sensors) {
          if ("ok" in sensorhealthtimeline[i].sensors[sensor_id_str]) {
            tmp["healthy"] =
              sensorhealthtimeline[i].sensors[sensor_id_str]["ok"] /
              sensorhealthtimeline[i].doc_count;
          } else {
            tmp["healthy"] = 0;
          }
          if ("warning" in sensorhealthtimeline[i].sensors[sensor_id_str]) {
            tmp["degraded"] =
              sensorhealthtimeline[i].sensors[sensor_id_str]["warning"] /
              sensorhealthtimeline[i].doc_count;
          } else {
            tmp["degraded"] = 0;
          }
          if ("error" in sensorhealthtimeline[i].sensors[sensor_id_str]) {
            tmp["unhealthy"] =
              sensorhealthtimeline[i].sensors[sensor_id_str]["error"] /
              sensorhealthtimeline[i].doc_count;
          } else {
            tmp["unhealthy"] = 0;
          }
          if ("unknown" in sensorhealthtimeline[i].sensors[sensor_id_str]) {
            tmp["unknown"] =
              sensorhealthtimeline[i].sensors[sensor_id_str]["unknown"] /
              sensorhealthtimeline[i].doc_count;
          } else {
            tmp["unknown"] = 0;
          }
          sensorhealthtimelinedata.push(tmp);
        }
      }
      timeseries.push(
        <Typography className={classes.center_text}>
          Sensor Health : {sensor_id_str}
        </Typography>
      );
      timeseries.push(
        <BarChart
          width={380}
          height={200}
          data={sensorhealthtimelinedata}
          margin={{ top: 2, bottom: 2, left: 2, right: 2 }}
        >
          <Legend />
          <Tooltip content={<CustomTooltip />} />
          <YAxis
            type="number"
            allowDecimals={false}
            tick={{ fontSize: 12 }}
            domain={[0, ySensorMax]}
          />
          <XAxis dataKey="time" tick={{ fontSize: 12 }} />
          <CartesianGrid stroke="#f5f5f5" />
          <Bar
            style={{ cursor: "pointer" }}
            stackId="a"
            dataKey="healthy"
            fill="#3f54a5"
            onClick={(e) => {
              if (site_id) {
                siteHealthModal(
                  [e.time, sensor_id_str, e.healthy, interval],
                  site_id
                );
              } else {
                siteHealthModal([e.time, sensor_id_str, e.healthy, interval]);
              }
            }}
          />
          <Bar
            style={{ cursor: "pointer" }}
            stackId="a"
            dataKey="degraded"
            fill="#b4d5fa"
            onClick={(e) => {
              if (site_id) {
                siteHealthModal(
                  [e.time, sensor_id_str, e.healthy, interval],
                  site_id
                );
              } else {
                siteHealthModal([e.time, sensor_id_str, e.healthy, interval]);
              }
            }}
          />
          <Bar
            style={{ cursor: "pointer" }}
            stackId="a"
            dataKey="unhealthy"
            fill="#ee4623"
            onClick={(e) => {
              if (site_id) {
                siteHealthModal(
                  [e.time, sensor_id_str, e.healthy, interval],
                  site_id
                );
              } else {
                siteHealthModal([e.time, sensor_id_str, e.healthy, interval]);
              }
            }}
          />
          <Bar
            style={{ cursor: "pointer" }}
            stackId="a"
            dataKey="unknown"
            fill="#D3D3D3"
            onClick={(e) => {
              if (site_id) {
                siteHealthModal(
                  [e.time, sensor_id_str, e.healthy, interval],
                  site_id
                );
              } else {
                siteHealthModal([e.time, sensor_id_str, e.healthy, interval]);
              }
            }}
          />
        </BarChart>
      );
    }
    const PrettySlider = withStyles({
      root: {
        color: "#3f54a5",
        height: 8,
      },
      thumb: {
        height: 24,
        width: 24,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
          boxShadow: "inherit",
        },
      },
      active: {},
      valueLabel: {
        left: "calc(-50% + 4px)",
      },
      track: {
        height: 8,
        borderRadius: 4,
      },
      rail: {
        height: 8,
        borderRadius: 4,
      },
    })(Slider);

    if (isDashboard) {
      // timeseries.push(<div style={{ height: 40, margin: 20 }}></div>);
    } else {
      timeseries.push(
        <div
          className={classes.interval_selector}
          style={{
            marginLeft: isDashboard ? 90 : 20,
            marginBottom: isDashboard ? 10 : 20,
          }}
        >
          <PrettySlider
            value={interval}
            step={null}
            marks={marks}
            min={2}
            max={148}
            className={classes.bold_text}
            onChange={(event: React.ChangeEvent<any>, x: number | number[]) =>
              handleIntervalChange(event, x)
            }
          />
        </div>
      );
    }
  }

  var sitehealthseries: React.ReactNode[] = [];
  var sensorhealthseries: React.ReactNode[] = [];
  const fillColor = (status: string) => {
    switch (status) {
      case "Healthy":
        return "#3f54a5";
        break;
      case "UnHealthy":
        return "#ee4623";
        break;
      case "Degraded":
        return "#b4d5fa";
        break;
      default:
        return "";
    }
  };
  if (health && health?.length > 0 && sensorhealth == undefined) {
    let healthy = 0;
    let unhealthy = 0;
    let degraded = 0;
    let unknown = 0;
    health.forEach((healthsite) => {
      if (healthsite.health_indicator == "ok") {
        healthy++;
      }
      if (healthsite.health_indicator == "error") {
        unhealthy++;
      }
      if (healthsite.health_indicator == "warning") {
        degraded++;
      }
    });

    const healthdata = [
      { name: "Healthy", value: healthy },
      { name: "UnHealthy", value: unhealthy },
      { name: "Degraded", value: degraded },
    ];
    if (healthy > 0 || unhealthy > 0 || degraded > 0) {
      sitehealthseries.push(
        <PieChart
          width={380}
          height={200}
          margin={{ top: 2, bottom: 2, left: 2, right: 2 }}
        >
          <Legend align="center" />
          <Tooltip content={<CustomTooltip />} />
          <Pie
            data={healthdata}
            dataKey="value"
            cx={150}
            cy={150}
            startAngle={180}
            endAngle={0}
            innerRadius={60}
            outerRadius={80}
            label
          >
            {healthdata.map((entry, index) => (
              <Cell key={`slice-${index}`} fill={fillColor(entry.name)} />
            ))}
            <Label value="Site Health" position="outside" />
          </Pie>
        </PieChart>
      );
    }

    let sensorhealthy = 0;
    let sensorunhealthy = 0;
    let sensordegraded = 0;
    let sensorunknown = 0;
    health.forEach((healthsite) => {
      healthsite.sensors &&
        healthsite.sensors.forEach((healthsensor) => {
          if (healthsensor.sensor_overall_indicator == "ok") {
            sensorhealthy++;
          }
          if (healthsensor.sensor_overall_indicator == "error") {
            sensorunhealthy++;
          }
          if (healthsensor.sensor_overall_indicator == "warning") {
            sensordegraded++;
          }
        });
    });

    const sensorhealthdata = [
      { name: "Healthy", value: sensorhealthy },
      { name: "UnHealthy", value: sensorunhealthy },
      { name: "Degraded", value: sensordegraded },
    ];
    if (sensorhealthy > 0 || sensorunhealthy > 0 || sensordegraded > 0) {
      sensorhealthseries.push(
        <PieChart
          width={380}
          height={200}
          margin={{ top: 2, bottom: 2, left: 2, right: 2 }}
        >
          <Legend align="center" />
          <Tooltip content={<CustomTooltip />} />
          <Pie
            data={sensorhealthdata}
            dataKey="value"
            cx={150}
            cy={150}
            startAngle={180}
            endAngle={0}
            innerRadius={60}
            outerRadius={80}
            label
          >
            {sensorhealthdata.map((entry, index) => (
              <Cell key={`slice-${index}`} fill={fillColor(entry.name)} />
            ))}
            <Label value="Sensor Health" position="outside" />
          </Pie>
        </PieChart>
      );
    }
  } else if (sensorhealth) {
    let sensorhealthy = 0;
    let sensorunhealthy = 0;
    let sensordegraded = 0;
    let sensorunknown = 0;

    if (sensorhealth.sensor_overall_indicator == "ok") {
      sensorhealthy++;
    }
    if (sensorhealth.sensor_overall_indicator == "error") {
      sensorunhealthy++;
    }
    if (sensorhealth.sensor_overall_indicator == "warning") {
      sensordegraded++;
    }

    const sensorhealthdata = [
      { name: "Healthy", value: sensorhealthy },
      { name: "UnHealthy", value: sensorunhealthy },
      { name: "Degraded", value: sensordegraded },
    ];
    if (sensorhealthy > 0 || sensorunhealthy > 0 || sensordegraded > 0) {
      sensorhealthseries.push(
        <Typography className={classes.center_text}>Health</Typography>
      );
      sensorhealthseries.push(
        <PieChart
          width={380}
          height={200}
          margin={{ top: 2, bottom: 2, left: 2, right: 2 }}
        >
          <Legend align="center" />
          <Tooltip content={<CustomTooltip />} />
          <Pie
            data={sensorhealthdata}
            dataKey="value"
            cx={150}
            cy={150}
            startAngle={180}
            endAngle={0}
            innerRadius={60}
            outerRadius={80}
            label
          >
            {sensorhealthdata.map((entry, index) => (
              <Cell key={`slice-${index}`} fill={fillColor(entry.name)} />
            ))}
            <Label value="Sensor Health" position="outside" />
          </Pie>
        </PieChart>
      );
    }
  }

  if (!isDashboard) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {timeseries}
        </Grid>

        <Grid item xs={12}>
          <div className={classes.space}></div>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <div style={{ width: "100%", height: 195, marginBottom: 20 }}>
        {timeseries}
      </div>
    );
  }
};

export default DashboardRightDrawer;
