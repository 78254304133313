import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AppStore } from "../../../../stores/AppStore";
import { useStyles } from "../../../styling/global/global";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BillingProfiles from "../BillingProfiles/BillingProfiles";
import {
  Org,
  Site,
  Hub,
  billingProductOrgsItems,
  listBillingRatesItems,
} from "../../../../services/FunctionService";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";

import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";

interface AddProductOrgProps {
  open: boolean;
  appStore?: AppStore;
  product: listBillingRatesItems | undefined;
  onClose: () => void;
  addProductOrgFunc: (org_id: number, billing_rate_id: number) => void;
}
class impOrg implements Org {
  org_id!: number;
  org_name!: string;
  org_tree!: number[];
  org_level!: number;
  enabled!: boolean;
  sites!: Site[];
  hubs!: Hub[];
  orgs!: Org[];
  type!: string;
  created!: string;
  sub_org_count!: number;
  site_count!: number;
  hub_count!: number;
}

const AddProductOrg: React.FC<AddProductOrgProps> = ({
  open,
  onClose,
  product,
  appStore,
  addProductOrgFunc,
}: AddProductOrgProps) => {
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    })
  )(TableRow);

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [orgslist, setOrgsList] = React.useState<Org[]>([]);
  const [groupid, setGroupId] = React.useState(0);
  const [groupname, setGroupName] = React.useState("");
  const [renderValue, setRenderValue] = useState(false);
  const [groupError, setGroupError] = useState(false);
  const useStyle = makeStyles({
    paper: {
      border: "2px solid #6D809D",
      color: "#15325F",
    },
  });
  const styler = useStyle();

  const handleChangeAuto = (value: any) => {
    setGroupError(false);
    if (!value) {
      return setGroupId(-1);
    }
    const new_group_id = +value.org_id;
    setGroupId(new_group_id);
    let group = orgslist.find((x: any) => x.org_id === value.org_id);
    if (group) {
      setGroupName(group.org_name);
    }
  };

  useEffect(() => {
    const fetchOrgList = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        const OrgListResult =
          await appStore?.functionService.getListOrgsWithTreeNames(token);
        if (OrgListResult && OrgListResult.success) {
          let orgz = OrgListResult?.orgs;
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names - b.org_tree_names;
          });
          orgz.sort(function (a: any, b: any) {
            return a.org_tree_names.length - b.org_tree_names.length;
          });
          setOrgsList(orgz);
          setLoading(false);
        }
      }
    };
    fetchOrgList();
  }, [renderValue, product]);
  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
        maxWidth="md"
      >
        <DialogTitle
          style={{ alignSelf: "center" }}
          className={classes.bold_text}
          id="simple-dialog-title"
        >
          <Typography
            className={classes.bold_text}
            style={{ color: "#3f51b5" }}
          >
            Assign{" "}
            <span
              style={{ color: "blue", fontSize: 14 }}
              className={classes.bold_text}
            >
              {product ? product.product : ""} (id={product ? product.id : ""})
            </span>{" "}
            billing rate to a group
          </Typography>
        </DialogTitle>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "5%",
            marginBottom: "5%",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Autocomplete
            id="combo-box-demo"
            className={classes.form_component_medium}
            options={orgslist}
            onChange={(event: React.ChangeEvent<any>, value: any) => {
              handleChangeAuto(value);
            }}
            style={{ width: 600, color: "#6D809D" }}
            classes={{ paper: styler.paper }}
            getOptionLabel={(option: any) => {
              let tree = option.org_tree_names!.map(
                (item: any) => `   /   ${item} `
              );
              let treeString = tree.toString();
              const rpl = treeString.replace(/,/g, "");
              return `${rpl}`;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <span className={classes.bold_text_billing}>
                    {groupname ? groupname : "Please Select Group"}
                  </span>
                }
                error={groupError}
                variant="outlined"
                value={groupid}
                className={classes.bold_text_billing}
              />
            )}
          />
        </div>
        <div
          style={{ marginTop: "5%", marginBottom: "5%" }}
          className={classes.twoButtonsInARow}
        >
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => {
              setGroupId(0);
              setGroupName("");
              onClose();
            }}
          >
            <span className={classes.bold_text}>Dismiss</span>
          </Button>
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => {
              if (groupid != 0) {
                addProductOrgFunc(groupid, product!.id);
                setGroupId(0);
                setGroupName("");
                onClose();
              } else {
                setGroupError(true);
              }
            }}
          >
            <span className={classes.bold_text}>Ok</span>
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default AddProductOrg;
