import React from "react";
import classes from "./ui.module.css";
import Metric from "./Metric";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import LanguageIcon from "@material-ui/icons/Language";
import VoiceChatRoundedIcon from "@material-ui/icons/VoiceChatRounded";

interface Props {
  width: number;
  title: string;
  subtitle: string;
  primaryBgColor: string;
  secondaryBgColor: string;
  cameras: number | string;
  sites: number | string;
  groups: number | string;
}
let url = "/assets/deepalert_logo.png";

const Overview: React.FC<Props> = ({
  width,
  title,
  subtitle,
  primaryBgColor,
  secondaryBgColor,
  cameras,
  sites,
  groups,
}) => {
  return (
    <div className={classes.false_alarms_wrapper}>
      <div
        className={classes.primary_component_wrapper}
        style={{ background: primaryBgColor, justifyContent: "space-between" }}
      >
        <div className={classes.dashboard_header_text}>{title}</div>
        <div
          className={classes.dashboard_img}
          style={{ backgroundColor: secondaryBgColor }}
        >
          <img src={url} alt="logo" style={{ width: 42, objectFit: "cover" }} />
        </div>
      </div>
      <div
        className={classes.secondary_component_wrapper}
        style={{ backgroundColor: secondaryBgColor }}
      >
        <Metric
          name="Sub-groups"
          value={groups}
          name_color={"rgba(57, 72, 158, 0.98)"}
          value_color={"rgba(57, 72, 158, 0.98)"}
        >
          <PeopleAltIcon
            fontSize="large"
            style={{ color: "rgba(57, 72, 158, 0.98)" }}
          />
        </Metric>
        <Metric
          name="Sites"
          value={sites}
          name_color={"rgba(57, 72, 158, 0.98)"}
          value_color={"rgba(57, 72, 158, 0.98)"}
        >
          <LanguageIcon
            fontSize="large"
            style={{ color: "rgba(57, 72, 158, 0.98)" }}
          />
        </Metric>
        <Metric
          name="Cameras"
          value={cameras}
          name_color={"rgba(57, 72, 158, 0.98)"}
          value_color={"rgba(57, 72, 158, 0.98)"}
        >
          <VoiceChatRoundedIcon
            fontSize="large"
            style={{
              color: "rgba(57, 72, 158, 0.98)",
            }}
          />
        </Metric>
      </div>
    </div>
  );
};

export default Overview;

// background-color: #045de9;
// backgroundImage: linear-gradient(315deg, #045de9 0%, #09c6f9 74%);
