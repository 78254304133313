import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
});

// Inspired by blueprintjs
function StyledRadio(props: RadioProps) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

interface CustomRadio {
  optionHander: (item: string) => void;
}

const CustomRadio: React.FC<CustomRadio> = ({ optionHander }) => {
  const onChangeHandler = (event: string) => {
    optionHander(event);
  };
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Select Search Type</FormLabel>
      <br></br>
      <RadioGroup
        onChange={(event, value) => onChangeHandler(value)}
        defaultValue="site_name"
        aria-label="gender"
        name="customized-radios"
      >
        <Grid container spacing={1} direction="row">
          <Grid item xs={6}>
            <FormControlLabel
              value="site_name"
              control={<StyledRadio />}
              label="site name"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              value="site_id"
              //   disabled
              control={<StyledRadio />}
              label="site id"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              value="org_name"
              //   disabled
              control={<StyledRadio />}
              label="group name"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              value="org_id"
              //   disabled
              control={<StyledRadio />}
              label="group id"
            />
          </Grid>

          <Grid item xs={6}>
            <FormControlLabel
              value="hub_id_str"
              //   disabled
              control={<StyledRadio />}
              label="hub id string"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              value="hub_id"
              //   disabled
              control={<StyledRadio />}
              label="hub id"
            />
          </Grid>
          {/* <Grid item xs={6}>
            <FormControlLabel
              value="sensor_id_str"
              //   disabled
              control={<StyledRadio />}
              label="sensor id string"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              value="sensor_id"
              //   disabled
              control={<StyledRadio />}
              label="sensor id"
            />
          </Grid> */}
        </Grid>
      </RadioGroup>
    </FormControl>
  );
};

export default CustomRadio;
