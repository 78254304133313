export const time_zones = [
  "Africa/Johannesburg",
  "Africa/Cairo",
  "Africa/Nairobi",
  "Africa/Accra",
  "Africa/Harare",
  "America/New_York",
  "America/Los_Angeles",
  "America/Indiana/Indianapolis",
  "America/Detroit",
  "America/Phoenix",
  "Asia/Shanghai",
  "Asia/Riyadh",
  "Asia/Colombo",
  "Asia/Calcutta",
  "Asia/Tokyo",
  "Australia/Sydney",
  "Australia/Melbourne",
  "Australia/Darwin",
  "Australia/Hobar",
  "Europe/Athens",
  "Europe/Berlin",
  "Europe/Helsinki",
  "Europe/London",
  "Europe/Paris",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "UTC",
];
