import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { AppStore } from "../../../../stores/AppStore";
import {
  listBillingRatesItems,
  billing_rate_support_items,
} from "../../../../services/FunctionService";

import LoadingSpinner from "../../../../spinner/LoadingSpinner";
import { useStyles } from "../../../styling/global/global";

interface EditProductProps {
  open: boolean;
  appStore?: AppStore;
  rateSupportItems: billing_rate_support_items;
  productData: listBillingRatesItems | undefined;
  billingRateItems: listBillingRatesItems[] | undefined;
  editProduct: (
    id: number,
    product: string,
    classifier_id: number,
    tier_id: number,
    product_name_id: number,
    data_origin_id: number
  ) => void;
  onClose: () => void;
}
const EditProduct: React.FC<EditProductProps> = ({
  open,
  onClose,
  editProduct,
  productData,
  rateSupportItems,
  appStore,
  billingRateItems,
}: EditProductProps) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [productName, setProductName] = useState("");
  const [publicName, setPublicName] = useState("");
  const [description, setDescription] = useState("");
  const [renderValue, setRenderValue] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [message, setMessage] = useState("");

  const [productNameItems, setProductNameItems] = useState<
    listBillingRatesItems[]
  >([]);
  const [tierItems, setTierItems] = useState<listBillingRatesItems[]>([]);
  const [classifierItems, setClassifierItems] = useState<
    listBillingRatesItems[]
  >([]);
  const [dataOriginItems, setDataOriginItems] = useState<
    listBillingRatesItems[]
  >([]);

  const [publicProductId, setPublicProductId] = useState<number>(0);
  const [classifierId, setClassifierId] = useState<number>(0);
  const [tierId, setTierId] = useState<number>(0);
  const [dataOriginId, setDataOriginId] = useState<number>(0);

  const handleClose = () => {
    onClose();
  };

  const handleEdit = () => {
    if (!nameError) {
      editProduct(
        productData?.id!,
        productName,
        classifierId,
        tierId,
        publicProductId,
        dataOriginId
      );
      onClose();
    }
  };
  const handleProductChange = (event: React.ChangeEvent<any>) => {
    setPublicProductId(+event.target.value);
  };
  const handleClassifierChange = (event: React.ChangeEvent<any>) => {
    setClassifierId(+event.target.value);
  };
  const handleTierChange = (event: React.ChangeEvent<any>) => {
    setTierId(+event.target.value);
  };
  const handleDataOriginChange = (event: React.ChangeEvent<any>) => {
    setDataOriginId(+event.target.value);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      setMessage("product name cannot be empty");
      setProductName(event.target.value);
      setNameError(true);
    } else {
      setNameError(false);
      setProductName(event.target.value);
    }
  };

  type remove_string =
    | "product_name_id"
    | "classifier_id"
    | "tier_id"
    | "data_origin_id"
    | "";

  const dupliRemover = (
    remove_str: remove_string,
    billingRateElements: listBillingRatesItems[]
  ): listBillingRatesItems[] => {
    const seen = new Set();
    if (remove_str === "product_name_id") {
      const filteredArr = billingRateElements.filter((el) => {
        const duplicate = seen.has(el.product_name_id);
        seen.add(el.product_name_id);
        return !duplicate;
      });
      return filteredArr;
    } else if (remove_str === "classifier_id") {
      const filteredArr = billingRateElements.filter((el) => {
        const duplicate = seen.has(el.classifier_id);
        seen.add(el.classifier_id);
        return !duplicate;
      });
      return filteredArr;
    } else if (remove_str === "tier_id") {
      const filteredArr = billingRateElements.filter((el) => {
        const duplicate = seen.has(el.tier_id);
        seen.add(el.tier_id);
        return !duplicate;
      });
      return filteredArr;
    } else {
      const filteredArr = billingRateElements.filter((el) => {
        const duplicate = seen.has(el.data_origin.data_origin_id);
        seen.add(el.data_origin.data_origin_id);
        return !duplicate;
      });
      return filteredArr;
    }
  };

  useEffect(() => {
    if (productData && billingRateItems) {
      setLoading(true);

      setProductNameItems(dupliRemover("product_name_id", billingRateItems));
      setTierItems(dupliRemover("tier_id", billingRateItems));
      setClassifierItems(dupliRemover("classifier_id", billingRateItems));
      setDataOriginItems(dupliRemover("data_origin_id", billingRateItems));
      setProductName(productData.product);
      setPublicProductId(productData.product_name_id);
      setClassifierId(productData.classifier_id);
      setDataOriginId(productData.data_origin.data_origin_id);
      setTierId(productData.tier_id);
      setLoading(false);
    }
  }, [productData, renderValue, billingRateItems]);
  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
        fullWidth={true}
      >
        <DialogTitle
          style={{ alignSelf: "center" }}
          className={classes.bold_text}
          id="simple-dialog-title"
        >
          <Typography
            className={classes.bold_text}
            style={{ color: "#3f51b5" }}
          >
            Editing billing rate{" "}
            <span
              style={{ color: "blue", fontSize: 14 }}
              className={classes.bold_text}
            >
              {productData?.product} (id={productData?.id})
            </span>{" "}
          </Typography>
        </DialogTitle>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "5%",
              marginBottom: "5%",
              width: "80%",
              marginLeft: "10%",
            }}
          >
            <TextField
              label="billing rate name"
              fullWidth
              error={nameError}
              onChange={handleNameChange}
              helperText={nameError ? message : ""}
              variant="outlined"
              value={productName}
              InputProps={{
                className: classes.bold_text_billing,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.bold_text_billing,
                },
              }}
            />
            <br></br>
            <FormControl style={{ width: "100%" }}>
              <InputLabel
                className={classes.bold_text_billing}
                style={{ marginLeft: "1%" }}
                id="select-product-name"
              >
                public product name
              </InputLabel>
              <Select
                fullWidth
                id="demo-controlled-open-select"
                variant="outlined"
                value={publicProductId}
                onChange={(e) => handleProductChange(e)}
              >
                {rateSupportItems.product_name_data &&
                  rateSupportItems.product_name_data.map((item, index) => {
                    return (
                      <MenuItem value={item.product_name_id}>
                        <Typography className={classes.bold_text_billing}>
                          {item.product_name}
                        </Typography>
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <br></br>
            <FormControl style={{ width: "100%" }}>
              <InputLabel
                className={classes.bold_text_billing}
                style={{ marginLeft: "1%" }}
                id="select classifier"
              >
                classifier
              </InputLabel>
              <Select
                id="demo-controlled-open-select"
                variant="outlined"
                value={classifierId}
                onChange={(e) => handleClassifierChange(e)}
              >
                {rateSupportItems.classifier_data &&
                  rateSupportItems.classifier_data.map((item, index) => {
                    return (
                      <MenuItem value={item.classifier_id}>
                        <Typography className={classes.bold_text_billing}>
                          {item.classifier}
                        </Typography>
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <br></br>
            <FormControl style={{ width: "100%" }}>
              <InputLabel
                className={classes.bold_text_billing}
                style={{ marginLeft: "1%" }}
                id="test-select-label"
              >
                data origin
              </InputLabel>
              <Select
                fullWidth
                id="demo-controlled-open-select"
                variant="outlined"
                value={dataOriginId}
                onChange={(e) => handleDataOriginChange(e)}
              >
                {rateSupportItems.data_origin_data &&
                  rateSupportItems.data_origin_data.map((item, index) => {
                    return (
                      <MenuItem value={item.data_origin_id}>
                        <Typography className={classes.bold_text_billing}>
                          {item.data_origin_name}
                        </Typography>
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <br></br>
            <FormControl style={{ width: "100%" }}>
              <InputLabel
                className={classes.bold_text_billing}
                style={{ marginLeft: "1%" }}
                id="test-select-label"
              >
                tier
              </InputLabel>
              <Select
                fullWidth
                id="demo-controlled-open-select"
                variant="outlined"
                value={tierId}
                onChange={(e) => handleTierChange(e)}
              >
                {rateSupportItems.tier_data &&
                  rateSupportItems.tier_data.map((item, index) => {
                    return (
                      <MenuItem value={item.tier_id}>
                        <Typography className={classes.bold_text_billing}>
                          {item.tier}
                        </Typography>
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
        )}
        <div
          style={{
            marginTop: 10,
            marginBottom: 20,
          }}
          className={classes.twoButtonsInARow}
        >
          <Button
            variant="contained"
            className={classes.cancel_button_billing}
            onClick={() => {
              setRenderValue((value) => !value);
              setProductName("");
              handleClose();
            }}
          >
            <span className={classes.bold_text}>Dismiss</span>
          </Button>
          <Button
            variant="contained"
            className={classes.cancel_button_billing}
            onClick={handleEdit}
          >
            <span className={classes.bold_text}>Save</span>
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default EditProduct;
