import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AppStore } from "../../../../stores/AppStore";
import { useStyles } from "../../../styling/global/global";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";

import { listBillingProfileUsersItems } from "../../../../services/FunctionService";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";

interface ViewBillingProfileUsersProps {
  open: boolean;
  appStore?: AppStore;
  profile: listBillingItems | undefined;
  onClose: () => void;
}
interface listBillingItems {
  id: number;
  enabled: boolean;
  name: string;
  description: string;
  meta: { [key: string]: any };
  created: Date | string;
}

const ViewBillingProfileUsers: React.FC<ViewBillingProfileUsersProps> = ({
  open,
  onClose,
  profile,
  appStore,
}: ViewBillingProfileUsersProps) => {
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    })
  )(TableRow);

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [renderValue, setRenderValue] = useState(false);

  const [viewProfileUsersData, setViewProfileUsersData] = useState<
    listBillingProfileUsersItems[] | undefined
  >(undefined);
  const deleteBillingProfileUser = async (billing_profile_user_id: number) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const deleteProfileUserResult =
        await appStore?.functionService.deleteBillingProfileUser(
          token,
          billing_profile_user_id
        );

      if (deleteProfileUserResult) {
        setLoading(false);
        setRenderValue((value) => !value);
      }
    }
  };

  useEffect(() => {
    const ViewProfileFunction = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        if (profile) {
          const listBillingProfileUsersResult =
            await appStore?.functionService.listBillingProfileUsers(
              token,
              profile.id
            );
          if (listBillingProfileUsersResult) {
            setViewProfileUsersData(
              listBillingProfileUsersResult.billing_profile_users
            );
            setLoading(false);
          }
        }
      }
    };
    ViewProfileFunction();
  }, [profile, renderValue]);
  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
        fullWidth={true}
      >
        <DialogTitle
          style={{ alignSelf: "center" }}
          className={classes.bold_text}
          id="simple-dialog-title"
        >
          <Typography
            className={classes.bold_text}
            style={{ color: "#3f51b5" }}
          >
            profile users
          </Typography>
        </DialogTitle>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div
            style={{
              width: "90%",
              display: "flex",
              alignItems: "center",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                marginTop: 15,
                borderRadius: 15,
                overflow: "hidden",
                width: "100%",
              }}
            >
              <TableContainer style={{ maxHeight: 500 }}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <span
                          className={classes.bold_text}
                          style={{ color: "#3f51b5" }}
                        >
                          username
                        </span>
                      </TableCell>
                      <TableCell>
                        <span
                          className={classes.bold_text}
                          style={{ color: "#3f51b5" }}
                        >
                          first&nbsp;name
                        </span>
                      </TableCell>
                      <TableCell>
                        <span
                          className={classes.bold_text}
                          style={{ color: "#3f51b5" }}
                        >
                          last&nbsp;name
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {viewProfileUsersData &&
                      viewProfileUsersData.map((billingProfileUser, index) => {
                        return (
                          <StyledTableRow>
                            <TableCell>
                              <Typography
                                className={classes.bold_text}
                                style={{ color: "grey" }}
                              >
                                {billingProfileUser.username}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                className={classes.bold_text}
                                style={{ color: "grey" }}
                              >
                                {billingProfileUser.first_name}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                className={classes.bold_text}
                                style={{ color: "grey" }}
                              >
                                {billingProfileUser.last_name}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <DeleteIcon
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  deleteBillingProfileUser(
                                    billingProfileUser.billing_profile_user_id
                                  )
                                }
                              />
                            </TableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        )}

        <div
          style={{ marginTop: "5%", marginBottom: "5%" }}
          className={classes.twoButtonsInARow}
        >
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => onClose()}
          >
            <span className={classes.bold_text}>Dismiss</span>
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default ViewBillingProfileUsers;
