import React, { useState } from "react";

import { Card, CardHeader, IconButton } from "@material-ui/core";
import {
  TimeEntryTimeOnly,
  TimeEntrySetTimeOnly,
} from "../../../core/models/TimeEntryTimeOnly";
import { Delete, AddCircle } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import TimePickerNoSeconds from "../TimeEntryUI/TimePickerNoSeconds";
import { useStyles } from "../../styling/global/global";
interface TimeEntryUITimeOnlyProps {
  timeEntry: TimeEntryTimeOnly;
  disabled: boolean;
  addSchedule: boolean;
  key: any;
  timeEntries: TimeEntrySetTimeOnly;
  changeTimeEntry: (timeEntry: TimeEntryTimeOnly) => void;
  handleScheduleDeleteClicked: (timeEntry: TimeEntryTimeOnly) => void;
  handleAdd: () => void;
  addicon: boolean;
}

const TimeEntryUITimeOnly: React.FC<TimeEntryUITimeOnlyProps> = ({
  addicon,
  timeEntry,
  disabled,
  addSchedule,
  key,
  changeTimeEntry,
  handleScheduleDeleteClicked,
  handleAdd,
}: TimeEntryUITimeOnlyProps) => {
  const [refresh, setRefresh] = React.useState(0);
  const classes = useStyles();
  if (!timeEntry) {
    return null;
  }
  var utils = new DateFnsUtils();
  const handleTimeChange = (strTime: string) => {
    timeEntry.setTime(strTime!);
    changeTimeEntry(timeEntry);
    setRefresh(new Date().getTime());
  };

  var addNewSchedule: React.ReactNode[] = [];
  //Only add the  addSchedule link if this is the last entry in the array and if addicon = true
  if (addSchedule && addicon) {
    addNewSchedule.push(
      <div className={classes.form_component}>
        <div className={classes.bold_text}>
          Add Schedule
          <IconButton
            edge="end"
            aria-label="add"
            disabled={disabled}
            onClick={() => {
              handleAdd();
            }}
          >
            <AddCircle color={disabled ? "disabled" : "primary"} />
          </IconButton>
        </div>
      </div>
    );
  }
  return (
    <>
      <Card key={key} className="scheduleCard">
        <CardHeader
          action={
            <IconButton
              edge="end"
              aria-label="delete"
              disabled={disabled}
              onClick={() => {
                handleScheduleDeleteClicked(timeEntry);
              }}
            >
              <Delete color={disabled ? "disabled" : "error"} />
            </IconButton>
          }
          title={
            <div>
              <TimePickerNoSeconds
                disabled={disabled}
                id="single-entry"
                label="Time"
                value={timeEntry.time}
                handleTimeChange={(strTime: string) =>
                  handleTimeChange(strTime)
                }
              />
            </div>
          }
        ></CardHeader>
      </Card>
      {addNewSchedule}
    </>
  );
};
export default TimeEntryUITimeOnly;
