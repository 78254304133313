import React from "react";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  })
);

interface props {
  value: any;
  type: string;
  setValue: (val: any) => void;
  setTouched: (val: any) => void;
  label: string;
  max?: number;
  min?: number;
  defaultVal?: any;
  clear?: boolean;
  touched: boolean;
}

export default function CustomInput({
  value,
  type,
  setValue,
  label,
  defaultVal,
  max,
  min,
  clear,
  touched = false,
  setTouched,
}: props) {
  const classes = useStyles();

  if (!value && !touched && defaultVal && label === "Threshold") {
    setValue(defaultVal);
  }

  return (
    <FormControl
      className={classes.formControl}
      variant="outlined"
      style={{ width: 100, color: "#6D809D" }}
    >
      <InputLabel id="demo-mutiple-chip-label">
        {!value && !defaultVal ? label : ""}
      </InputLabel>
      <TextField
        onBlur={() => {
          if (max && value > max) {
            return setValue(max);
          }

          if (min && value < min) {
            return setValue(min);
          }
        }}
        id="standard-number"
        InputProps={{
          inputProps: {
            max: 100,
            min: 1,
          },
        }}
        onChange={(e: any) => {
          // if(max && +e.target.value > max ){
          //   return
          // }

          // if(min && +e.target.value < min ){
          //   return
          // }
          setTouched(true);
          setValue(e.target.value);
        }}
        label="."
        defaultValue={defaultVal ? defaultVal : null}
        value={(!value && !defaultVal) || clear ? "" : value}
        type={type}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </FormControl>
  );
}
