import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "../../styling/global/global";
import LoadingSpinner from "../../../spinner/LoadingSpinner";
interface MessageModalProps {
  elements: React.ReactNode[] | undefined;
  reload?: any;
  open: boolean;
  spinner?: any;
  message: string;
  onClose: () => void;
}
const MessageModal: React.FC<MessageModalProps> = ({
  message,
  reload,
  open,
  spinner,
  onClose,
  elements,
}: MessageModalProps) => {
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };

  const handleSpinnerLogic = () => {
    spinner(Math.floor(Math.random() * 100) + 1);
    reload(Math.floor(Math.random() * 100) + 1);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className="edit-dialog"
    >
      <DialogTitle id="simple-dialog-title">
        <span className={classes.bold_text}>{message}</span>
      </DialogTitle>
      {elements}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="buttons-container"
      >
        <Button
          variant="contained"
          className="save"
          onClick={() => {
            handleClose();
            handleSpinnerLogic();
          }}
        >
          OK
        </Button>
      </div>
    </Dialog>
  );
};

export default MessageModal;
