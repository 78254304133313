import React, { useEffect, useState } from "react";
import classes from "./ui.module.css";
import { AppStore } from "../../stores/AppStore";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Radio } from "antd";
import type { RadioChangeEvent } from "antd";

interface ListProps {
  currentOrgId: number;
  appStore: AppStore;
  setSelectedItem: (item: any, editing: boolean) => void;
}

const FrontelSiteList: React.FC<ListProps> = ({
  setSelectedItem,
  currentOrgId,
  appStore,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [value, setValue] = useState(1);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const onViewHandler = (site: any): void => {
    setSelectedItem({ ...site, type: "site" }, false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const token = await appStore?.authService.getAuthorisedToken();
      if (token && currentOrgId) {
        const sites_data = await appStore?.functionService.getFrontelSites(
          token,
          currentOrgId
        );
        setData(sites_data);
      }
      setLoading(false);
    };
    fetchData();
  }, [currentOrgId]);

  let filtered = data;

  if (value === 1) {
    filtered = data;
  }

  if (value === 3) {
    filtered = data.filter(
      (item: any) =>
        item.frontel_panel_battery !== "" || item.frontel_sensor_battery !== ""
    );
  }

  if (value === 2) {
    filtered = data.filter((item: any) => item.signal_poll === "expired");
  }

  return (
    <div>
      <h2
        className={classes.header_text}
        style={{ color: "#15325F", textAlign: "center" }}
      >
        Frontel Sites
      </h2>
      <br />
      <div style={{ margin: "0 auto", padding: 5 }}>
        <Radio.Group onChange={onChange} value={value}>
          <Radio value={1}>All Sites</Radio>
          <Radio value={2}>Unhealthy Signal Polled</Radio>
          <Radio value={3}>Low Panel/Sensor Battery</Radio>
        </Radio.Group>
      </div>
      <br />
      <br />
      <ul
        className={classes["items-wrapper"]}
        style={{ marginLeft: 0, paddingLeft: 0 }}
      >
        {!loading && filtered ? (
          filtered.map((item: any, index: number) => {
            let signal_poll_color = "";
            let frontel_panel_battery_color = "";
            let frontel_sensor_battery_color = "";

            if (item.signal_poll === "none") {
              signal_poll_color = "#e0e0e0";
            } else if (item.signal_poll === "current") {
              signal_poll_color = "#15325F";
            } else {
              signal_poll_color = "#F32424";
            }

            if (item.frontel_panel_battery === "") {
              frontel_panel_battery_color = "#15325F";
            } else {
              frontel_panel_battery_color = "#F32424";
            }

            if (item.frontel_sensor_battery === "") {
              frontel_sensor_battery_color = "#15325F";
            } else {
              frontel_sensor_battery_color = "#F32424";
            }

            return (
              <li
                key={item.site_id}
                className={classes.item}
                onClick={() => onViewHandler(item)}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                  }}
                >
                  <p
                    className={classes["item-wrapper"]}
                    style={{ fontSize: 13, color: "#15325F" }}
                  >
                    Site Name:{" "}
                    <span style={{ color: "grey" }}>{item.site_name}</span>
                  </p>
                  <p
                    className={classes["item-wrapper"]}
                    style={{ fontSize: 13, color: "#15325F" }}
                  >
                    Site ID:{" "}
                    <span style={{ color: "grey" }}>{item.site_id}</span>
                    {"  "}|{"  "} Frontel Account:{" "}
                    <span style={{ color: "grey" }}>
                      {item.frontel_account}
                    </span>
                    {"  "}|{"  "} Frontel Panel:{" "}
                    <span style={{ color: "grey" }}>{item.frontel_panel}</span>
                  </p>
                  <p
                    className={classes["item-wrapper"]}
                    style={{ fontSize: 13, color: "#15325F" }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ width: 180 }}>Signal Poll: </div>
                      <span
                        style={{
                          backgroundColor: signal_poll_color,
                          width: 70,
                          height: 15,
                          border: "1px solid white",
                          display: "block",
                          marginLeft: 20,
                          borderRadius: 10,
                        }}
                      ></span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ width: 180 }}>Panel Battery Level: </div>
                      <span
                        style={{
                          backgroundColor: frontel_panel_battery_color,
                          width: 70,
                          height: 15,
                          border: "1px solid white",
                          display: "block",
                          marginLeft: 20,
                          borderRadius: 10,
                        }}
                      ></span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ width: 180 }}>Sensor Battery Level:</div>
                      <span
                        style={{
                          backgroundColor: frontel_sensor_battery_color,
                          width: 70,
                          height: 15,
                          border: "1px solid white",
                          display: "block",
                          marginLeft: 20,
                          borderRadius: 10,
                        }}
                      ></span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ width: 180 }}>Frontel Arm Status:</div>
                      {/* <span
                        style={{
                          // backgroundColor: frontel_sensor_battery_color,
                          width: 70,
                          height: 15,
                          border: "1px solid white",
                          display: "block",
                          marginLeft: 20,
                          borderRadius: 10,
                        }}
                      ></span> */}
                      <span style={{ color: "grey", marginLeft: 22 }}>
                        {item.frontel_arm_status}
                      </span>
                    </div>
                  </p>
                </div>
              </li>
            );
          })
        ) : (
          <>
            <p
              style={{
                width: 250,
                textAlign: "center",
                margin: "0 auto",
                marginTop: 70,
                marginBottom: 20,
              }}
            >
              Loading...
            </p>
            <span>
              <LinearProgress />
            </span>
          </>
        )}
        {!loading && filtered && filtered.length === 0 && (
          <li>
            <p
              style={{
                width: 250,
                textAlign: "center",
                margin: "0 auto",
                marginTop: 70,
                marginBottom: 20,
                fontSize: 14,
              }}
            >
              No data found.
            </p>
          </li>
        )}
      </ul>
    </div>
  );
};

export default FrontelSiteList;
