import React, { FC } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import { Typography, Checkbox, Tooltip } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { useStyles } from "../../../styling/global/global";
import { AppStore } from "../../../../stores/AppStore";
import {
  AlertChannel,
  AlertEndPoint,
  AlertEndPointDef,
  UserRevisedWebRoles,
} from "../../../../services/FunctionService";
import { NewData } from "../WebSettings";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";
interface ViewUsersProps {
  finishedFetching: boolean;
  appStore?: AppStore;
  alertchannel?: AlertChannel;
  endpointdefs?: AlertEndPointDef[];
  newseletedrows: NewData[];
  endpoint: AlertEndPoint;
  refresh: number;
  setCurrentId: (id: number) => void;
}

class newImpUserWebRoles implements UserRevisedWebRoles {
  first_name!: string;
  last_name!: string;
  user_id!: number;
  username!: string;
  web_alerts!: boolean;
  web_alert_escalations!: boolean;
  web_mute_controls!: boolean;
  web_alert_camera_filter!: boolean;
  web_alert_history!: boolean;
  web_bulk_actions!: boolean;
}

const ViewUsers: FC<ViewUsersProps> = ({
  endpoint,
  appStore,
  endpointdefs,
  newseletedrows,
  finishedFetching,
  setCurrentId,
  refresh,
}) => {
  const classes = useStyles();

  if (newseletedrows.length > 0 && finishedFetching === true) {
    return (
      <>
        <Typography align="center" className={classes.bold_text_billing}>
          These directly-assigned users can view alerts for this ruleset on the
          Alert Web Interface{" "}
          <a href="https://alert.deepalert.ai" target="_blank">
            (https://alert.deepalert.ai)
          </a>
        </Typography>
        <div
          style={{
            boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
            marginTop: 15,
            borderRadius: 15,
            overflow: "hidden",
            width: "100%",
          }}
        >
          <TableContainer component={Paper} style={{ maxHeight: 300 }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span
                      className={classes.bold_text}
                      style={{ color: "#3f51b5" }}
                    >
                      username
                    </span>
                  </TableCell>
                  <TableCell>
                    <span
                      className={classes.bold_text}
                      style={{ color: "#3f51b5" }}
                    >
                      firstname
                    </span>
                  </TableCell>
                  <TableCell>
                    <span
                      className={classes.bold_text}
                      style={{ color: "#3f51b5" }}
                    >
                      lastname
                    </span>
                  </TableCell>
                  <TableCell>
                    <span
                      className={classes.bold_text}
                      style={{ color: "#3f51b5" }}
                    >
                      web&nbsp;alerts
                    </span>
                  </TableCell>
                  <TableCell>
                    <span
                      className={classes.bold_text}
                      style={{ color: "#3f51b5" }}
                    >
                      web&nbsp;alert&nbsp;escalations
                    </span>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <span
                      className={classes.bold_text}
                      style={{ color: "#3f51b5" }}
                    >
                      web&nbsp;mute&nbsp;controls
                    </span>
                  </TableCell>
                  <TableCell>
                    <span
                      className={classes.bold_text}
                      style={{ color: "#3f51b5" }}
                    >
                      web&nbsp;alert&nbsp;camera&nbsp;filter
                    </span>
                  </TableCell>
                  <TableCell>
                    <span
                      className={classes.bold_text}
                      style={{ color: "#3f51b5" }}
                    >
                      web&nbsp;alert&nbsp;history
                    </span>
                  </TableCell>
                  <TableCell>
                    <span
                      className={classes.bold_text}
                      style={{ color: "#3f51b5" }}
                    >
                      web&nbsp;bulk&nbsp;actions
                    </span>
                  </TableCell>
                  <TableCell>
                    <span
                      className={classes.bold_text}
                      style={{ color: "#3f51b5" }}
                    >
                      delete
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {newseletedrows.map((seletedrow) => (
                  <TableRow key={seletedrow.user_id}>
                    <TableCell>
                      <span className={classes.bold_text}>
                        {seletedrow.username}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className={classes.bold_text}>
                        {seletedrow.first_name}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className={classes.bold_text}>
                        {seletedrow.last_name}
                      </span>
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        disabled={true}
                        checked={seletedrow.web_alerts}
                        inputProps={{ "aria-label": "web_alerts" }}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        disabled={true}
                        checked={seletedrow.web_alert_escalations}
                        inputProps={{
                          "aria-label": "web_alert_escalations",
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        disabled={true}
                        checked={seletedrow.web_mute_controls}
                        inputProps={{ "aria-label": "web_mute_controls" }}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        disabled={true}
                        checked={seletedrow.web_alert_camera_filter}
                        inputProps={{
                          "aria-label": "web_alert_camera_filter",
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        disabled={true}
                        checked={seletedrow.web_alert_history}
                        inputProps={{ "aria-label": "web_alert_history" }}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        disabled={true}
                        checked={seletedrow.web_bulk_actions}
                        inputProps={{ "aria-label": "web_bulk_actions" }}
                      />
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        placement="bottom"
                        title="Remove user from endpoint"
                      >
                        <DeleteIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setCurrentId(seletedrow.user_id);
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
    );
  } else {
    if (finishedFetching === false) {
      return (
        <>
          <Typography className={classes.bold_text_billing}>
            These directly-assigned users can view alerts for this ruleset on
            the Alert Web Interface <a>(https://alert.deepalert.ai)</a>
          </Typography>
          <br></br>
          <LoadingSpinner />
        </>
      );
    } else {
      return (
        <>
          <Typography className={classes.bold_text_billing}>
            These directly-assigned users can view alerts for this ruleset on
            the Alert Web Interface{" "}
            <a href="https://alert.deepalert.ai" target="_blank">
              (https://alert.deepalert.ai)
            </a>
          </Typography>
          <br></br>
          <Typography align="center">
            <span
              className={classes.bold_text_billing}
              style={{ color: "red" }}
            >
              No users found for this web alert endpoint
            </span>
          </Typography>
        </>
      );
    }
  }
};

export default ViewUsers;
