import { createMuiTheme } from "@material-ui/core/styles";
import OpenSansR from "./fonts/Open_Sans/OpenSans-Regular.ttf";
import CenturyGothic from "./fonts/century_gothic/GOTHIC.TTF";

const opensans = {
  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
      local('Open Sans'),
      local('Open Sans Regular'),
      url(${OpenSansR}) format('truetype')
    `,
};
const gothic = {
  fontFamily: "Century Gothic",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
      local('Century Gothic'),
      local('Century Gothic Regular'),
      url(${CenturyGothic}) format('truetype')
    `,
};

// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    fontFamily: "Open Sans",
    fontSize: 12,
    "@font-face": [opensans],
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [opensans],
      },
    },
    MuiRadio: {
      root: {
        color: "#15325F",
      },
      colorSecondary: {
        "&$checked": {
          color: "#ee4623",
        },
      },
    },
  },
  palette: {
    primary: { main: "#15325F" },
    secondary: { main: "#15325F" },
  },
});

export default theme;
