import Button from "@material-ui/core/Button";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Slide from "@material-ui/core/Slide";
import React, { useEffect } from "react";
import { useStyles } from "../styling/global/global";
import MapperD from "./MapperD";
import MuiltMapperD from "./MuiltMapperD";
import { Site } from "../../services/FunctionService";
import { AppStore } from "../../stores/AppStore";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import CustomModal from "../UI/CustomModal";

import { TransitionProps } from "@material-ui/core/transitions";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface MapperDetailsProp {
  openHandler: () => void;
  closeHandler: () => void;
  coords: number[];
  siteId: string | number;
  siteName: string;
  sensorId: string;
  fileName: string;
  classifier: string;
  imgUrl: string;
  objDetection: boolean | undefined;
  open: boolean;
  created: number;
  inferences: boolean;
  setSelectedItem: (item: any, editing: boolean) => void;
  handleToastClose: () => void;
  handleMClose: () => void;
  probability: number;
  probs: string[];
  index: number;
  navigator: (i: number) => void;
  appStore?: AppStore;
  doc_id: string;
  available_images: number;
  reloadImages: (reload: boolean) => void;
  message?: string;
  advanced_rule_type?: string;
  advanced_rule_name?: string;
  class_probs?: any[];
}

const MapperDetails: React.FC<MapperDetailsProp> = ({
  handleToastClose,
  handleMClose,
  openHandler,
  closeHandler,
  coords,
  siteId,
  siteName,
  sensorId,
  fileName,
  classifier,
  imgUrl,
  objDetection,
  open,
  created,
  setSelectedItem,
  inferences,
  probability,
  probs,
  index,
  navigator,
  appStore,
  doc_id,
  available_images,
  reloadImages,
  message: msg,
  advanced_rule_type,
  advanced_rule_name,
}) => {
  // const [open, setOpen] = React.useState(true);
  const [state, setState] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("md");
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [err, setErr] = React.useState(false);
  const [openToast, setOpenToast] = React.useState(false);
  const [siteList, setSiteList] = React.useState<Site[]>([]);
  const [openWarning, setOpenWarning] = React.useState(false);
  const [isMotionCam, setIsMotionCam] = React.useState(false);

  const defaultFileName =
    "TSTAAAB-b_20210927_105230.356514_I2vwItvGUyNS1drA_1_z0-0-0-0_vm.jpg";

  const classes = useStyles();

  const getBaseUrl = (): string => {
    let url = window.location.href;
    if (url.includes("local")) {
      return "https://manage.staging.deepalert.ai/api/v1.3";
    } else {
      return "/api/v1.3";
    }
  };

  const handleToastOpen = () => {
    setOpenToast(true);
  };

  // Fetch sites
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      var token = await appStore?.authService.getAuthorisedToken();
      var baseUrl = getBaseUrl();
      if (token) {
        try {
          const request = JSON.stringify({
            enabled: true,
            top_org_id: appStore?.authService.getEntryOrg()?.id!,
            paginate: false,
            top_org_only: false,
            verbose: true,
          });

          const res = fetch(baseUrl + "/list_sites", {
            method: "post",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: request,
          });
          const result = await (await res).json();
          if (result && result.success) {
            let sites: Site[] = result?.sites;
            sites.sort(function (a: Site, b: Site) {
              if (b.site_name.toLowerCase() > a.site_name.toLowerCase()) {
                return -1;
              }
              if (b.site_name.toLowerCase() < a.site_name.toLowerCase()) {
                return 1;
              }
              return 0;
            });

            setSiteList(sites);
          }
          setLoading(false);
        } catch (err) {
          return err;
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, []);

  const navigateToAnotherItem = (i: number) => {
    navigator(i);
  };

  const coordsExtractor = (filename: string): number[] => {
    const fileArr = filename.split("_");
    const coords = fileArr[5].split("-");
    let a = coords[0].substring(1);
    let b = coords[1];
    let c = coords[2];
    let d = coords[3];
    return [
      parseInt(a),
      parseInt(b),
      parseInt(c) + parseInt(a),
      parseInt(b) + parseInt(d),
    ];
  };

  const handleClickOpen = () => {
    openHandler();
  };

  const createdTime = (epocSeconds: number) => {
    let today = new Date(epocSeconds * 1000);
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();

    let hr: number | string = new Date(epocSeconds * 1000).getHours();
    let min: number | string = new Date(epocSeconds * 1000).getMinutes();
    let sec: number | string = new Date(epocSeconds * 1000).getSeconds();

    if (min == 0) {
      min = "00";
    }

    if (min.toString().length == 1) {
      min = `0${min}`;
    }

    if (hr.toString().length == 1) {
      hr = `0${hr}`;
    }

    if (sec.toString().length == 1) {
      sec = `0${sec}`;
    }

    return ` ${yyyy}/${mm}/${dd} -  ${hr}:${min}:${sec} (browser time) `;
  };

  const handleClose = () => {
    closeHandler();
  };

  let toast = [
    <Collapse in={openToast}>
      {message && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 20,
            justifyContent: "center",
            // alignItems: 'center',
          }}
        >
          <Alert
            severity={err ? "error" : "success"}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  handleToastClose();
                  setOpenToast(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        </div>
      )}
    </Collapse>,
  ];

  // Flag Images for Training (Bulk Flagging)
  const flagImages = async () => {
    let docIds = [];
    docIds.push(doc_id);
    setLoading(true);
    var baseUrl = getBaseUrl();
    const lastSnapShot = docIds;
    var token = await appStore?.authService.getAuthorisedToken();

    setMessage("");
    if (token) {
      try {
        let request = JSON.stringify({
          doc_ids: `${docIds}`,
          doc_type: inferences ? "inference" : "alert",
        });
        const res = await fetch(baseUrl + "/flag_for_training", {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });
        if (!res.ok) {
          setErr(true);
          setMessage("Something went wrong, please try again later");
        }
        const result = (await (await res).json()) as any;
        handleToastOpen();

        setTimeout(() => {
          setOpenToast(false);
          reloadImages(true);
        }, 1500);

        if (result.success) {
          // setImg([]);
          // setNewEndTime(0);
          // setImagesLeftAl(+healthParams[2]);
          // setImagesLeftInf(+healthParams[1]);
          // setHasMore(true);
          // setNewStartTime(0);
          // setReloadImgs(true);
          setErr(false);
          setMessage(`Image successfully flagged for training`);
        }

        if (!result.success) {
          // setImagesList(lastSnapShot);
          setErr(true);
          setMessage("Something went wrong, please select at least 1 image");
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    }
  };

  const suppressAlert = async () => {
    setLoading(true);
    var token = await appStore?.authService.getAuthorisedToken();
    var baseUrl = getBaseUrl();
    let site = siteList.find((item) => item.site_id === siteId);
    if (site!.data_origin.includes("motioncam")) {
      handleToastOpen();
      setErr(true);
      setTimeout(() => {
        setOpenToast(false);
      }, 1500);
      return setMessage("Silencing is not supported for this product");
    }
    if (token) {
      try {
        const request = JSON.stringify({
          doc_ids: doc_id,
          suppress_flag: true,
        });

        const res = fetch(baseUrl + "/suppress_alert", {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: request,
        });

        const result = (await (await res).json()) as any;
        handleToastOpen();

        setTimeout(() => {
          setOpenToast(false);
          reloadImages(true);
        }, 1500);
        if (result && result.success) {
          setErr(false);
          setMessage("successfully silenced alert");
        } else {
          setErr(true);
          setMessage("something went wrong");
        }
      } catch (err) {
        return err;
      } finally {
        setLoading(false);
      }
    }
  };

  const handleItemSelection = async (value: any) => {
    let type = "site";
    if (typeof value === "number") {
      type = "org";
    }

    if (type === "site") {
      value = siteList.find((item) => item.site_id === value.site_id);
    }
    if (value) {
      setLoading(true);
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var rest_org = await appStore?.functionService.getOrgOneLevel(
          token!,
          value.org_id
        );
        if (rest_org) {
          if (rest_org.orgs == undefined) {
            rest_org.orgs = [];
          }
          await appStore?.authService.setBreadcrumbOrgs(rest_org);
          appStore?.helpers.setCurrentOrgId(value.org_id);
          let temp_site: Site = value;
          temp_site.type = "site";
          temp_site.name = value.site_name;

          setSelectedItem(temp_site, false);
        }
      }
      setLoading(false);
      // closeModal();
    }
  };

  const objClass = classifier ? ` (${classifier})` : ` `;

  return (
    <Dialog
      // fullScreen
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <CustomModal
        closeHandler={() => {
          setOpenWarning(false);
        }}
        open={openWarning}
        title={"confirm"}
        continueAction={true}
        onContinueHandler={() => {
          setOpenWarning(false);
          suppressAlert();
        }}
      >
        <h1
          style={{
            fontSize: 14,
            color: "#15325F",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          WARNING!
        </h1>
        <br />
        <p
          style={{
            fontSize: 14,
            color: "#F32424",
            fontWeight: "bold",
            textAlign: "center",
            marginLeft: 30,
            marginRight: 30,
          }}
        >
          Silencing an alert means that DeepAlert will not generate alerts for
          that specific camera for a period of 2 weeks for similar objects in
          similar locations as detected in the silenced alert. This is intended
          mostly for stationary objects causing nuisance alerts. When silencing
          an alert, it will also be flagged for training.
        </p>
        <p
          style={{
            fontSize: 14,
            color: "#15325F",
            fontWeight: "bold",
            textAlign: "center",
            marginLeft: 30,
            marginRight: 30,
          }}
        >
          Are you sure you want to silence the alert(s) ?
        </p>
      </CustomModal>
      <List>
        <DialogContent>
          <div
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              objectFit: "contain",
              borderRadius: 5,
              // overflow: "hidden",
              overflowX: "scroll",
              overflowY: "scroll",
              transition: "width 2s, height 4s",
            }}
          >
            {!inferences && (
              <img
                src={imgUrl}
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  objectFit: "contain",
                  borderRadius: 3,
                  cursor: "pointer",
                }}
              />
            )}
            {!objDetection && inferences && (
              <MapperD
                key={fileName}
                coords={coordsExtractor(fileName)}
                imgUrl={imgUrl}
              />
            )}

            {objDetection && inferences && (
              <MuiltMapperD key={fileName} coords={coords} imgUrl={imgUrl} />
            )}
          </div>
          <div style={{ marginTop: 15 }}>
            <Divider />
          </div>
          <div
            className={classes.hover}
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleToastClose();
              handleClose();
              handleMClose();
              let temp_site = siteList.filter(
                (item) => item.site_id == siteId
              )[0];
              temp_site.type = "site";
              temp_site.name = siteName;
              temp_site.site_name = siteName;
              // setSelectedItem(temp_site, false);
              handleItemSelection(temp_site);
            }}
          >
            {siteId && (
              <p
                className={classes.bold_text}
                onClick={() => {
                  handleToastClose();
                  let temp_site = siteList.filter(
                    (item) => item.site_id == siteId
                  )[0];
                  temp_site.type = "site";
                  temp_site.name = siteName;
                  temp_site.site_name = siteName;
                  // setSelectedItem(temp_site, false);
                  handleItemSelection(temp_site);
                }}
              >
                Site ID:{" "}
                <span style={{ color: "grey", marginLeft: 10 }}>{siteId}</span>
              </p>
            )}
            {siteName && (
              <p
                className={classes.bold_text}
                onClick={() => {
                  handleToastClose();
                  let temp_site = siteList.filter(
                    (item) => item.site_id == siteId
                  )[0];
                  temp_site.type = "site";
                  temp_site.name = siteName;
                  temp_site.site_name = siteName;
                  alert(temp_site);
                  handleItemSelection({
                    site_id: siteId,
                    type: "site",
                    site_name: siteName,
                  });
                }}
              >
                Site Name:{" "}
                <span style={{ color: "grey", marginLeft: 10 }}>
                  {siteName}
                </span>
              </p>
            )}
            {sensorId && (
              <p
                className={classes.bold_text}
                onClick={() => {
                  handleToastClose();
                  setSelectedItem(
                    {
                      sensor_id_str: sensorId,
                      site_id: siteId,
                      type: "sensor",
                    },
                    false
                  );
                }}
              >
                Sensor ID:{" "}
                <span style={{ color: "grey", marginLeft: 10 }}>
                  {sensorId}
                </span>
              </p>
            )}
            {fileName && fileName !== defaultFileName && (
              <p className={classes.bold_text}>
                File Name:{" "}
                <span style={{ color: "grey", marginLeft: 10 }}>
                  {fileName}
                </span>
              </p>
            )}
            {advanced_rule_type && advanced_rule_name && (
              <p className={classes.bold_text}>
                Advanced Rule:{" "}
                <span style={{ color: "grey", marginLeft: 10 }}>
                  {advanced_rule_name} ({advanced_rule_type})
                </span>
              </p>
            )}
            {msg && (
              <p className={classes.bold_text}>
                Message:{" "}
                <span style={{ color: "grey", marginLeft: 10 }}>{msg}</span>
              </p>
            )}
            {/* <p className={classes.bold_text}>
              Classifier:{" "}
              <span
                style={{
                  color: "grey",
                  marginLeft: 10,
                  textTransform: "capitalize",
                }}
              >
                {classifier}
              </span>
            </p> */}

            {probability !== 0 && probs.length === 0 && (
              <p className={classes.bold_text}>
                Probability %:{" "}
                <span style={{ color: "grey", marginLeft: 10 }}>
                  {probability == 0
                    ? "no data"
                    : Number(probability * 100).toFixed(0) + objClass}
                </span>
              </p>
            )}
            {probs.length !== 0 && (
              <p className={classes.bold_text}>
                Probabilities %:{" "}
                <span style={{ color: "grey", marginLeft: 10 }}>
                  {probs.length !== 0 &&
                    probs.map((prob: any, index: number) =>
                      index === probs.length - 1
                        ? prob.replace("%", "")
                        : prob.replace("%", "") + ","
                    )}
                </span>
              </p>
            )}
            <p className={classes.bold_text}>
              Created At:{" "}
              <span style={{ color: "grey", marginLeft: 10 }}>
                {createdTime(created)}
              </span>
            </p>
          </div>
        </DialogContent>
      </List>
      <div>{toast}</div>
      <Divider />
      <DialogActions>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: 30,
          }}
        >
          <div style={{ display: "flex", padding: 10 }}>
            {index !== 0 && (
              <Button
                onClick={() => {
                  navigateToAnotherItem(index - 1);
                }}
                color="primary"
              >
                <KeyboardArrowLeftIcon /> PREV
              </Button>
            )}
            <Button
              onClick={() => {
                navigateToAnotherItem(index + 1);
                if (index + 2 === available_images) {
                  reloadImages(false);
                }
              }}
              color="primary"
            >
              NEXT <KeyboardArrowRightIcon />
            </Button>
          </div>
          <Button onClick={flagImages} color="primary">
            Flag
          </Button>
          {!isMotionCam && !inferences && (
            <Button onClick={() => setOpenWarning(true)} color="primary">
              Silence
            </Button>
          )}
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default MapperDetails;
