import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AppStore } from "../../../../stores/AppStore";
import { useStyles } from "../../../styling/global/global";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  listBillingProfileOrgsItems,
  listBillingRunsItems,
} from "../../../../services/FunctionService";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Tooltip,
  IconButton,
  Paper,
  Card,
} from "@material-ui/core";

interface ViewBillingProfilesProps {
  open: boolean;
  appStore?: AppStore;
  profile: listBillingItems | undefined;
  onClose: () => void;
}
interface listBillingItems {
  id: number;
  enabled: boolean;
  name: string;
  description: string;
  meta: { [key: string]: any };
  created: Date | string;
}

const ViewBillingRuns: React.FC<ViewBillingProfilesProps> = ({
  open,
  onClose,
  profile,
  appStore,
}: ViewBillingProfilesProps) => {
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    })
  )(TableRow);

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [viewProfileOrgsData, setProfileOrgsViewData] = useState<
    listBillingProfileOrgsItems | undefined
  >(undefined);
  const [renderValue, setRenderValue] = useState(false);
  const [billingOrgsProfiles, setBillingOrgsProfiles] = useState<
    listBillingProfileOrgsItems[] | undefined
  >(undefined);
  const [billingRuns, setBillingRuns] = useState<listBillingRunsItems[]>([]);
  const fetchBillingRuns = async (profileId: number) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const listRunsResult = await appStore?.functionService.listBillingRuns(
        token,
        profileId
      );
      if (listRunsResult) {
        setBillingRuns(listRunsResult.billing_runs);
        setLoading(false);
      }
    }
  };
  const deleteBillingRun = async (billing_run_id: number) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const deleteRunResult = await appStore?.functionService.deleteBillingRun(
        token,
        billing_run_id
      );
      if (deleteRunResult) {
        if (deleteRunResult?.success) {
          fetchBillingRuns(profile!.id);
          setLoading(false);
          //   setResultMessage('Billing Run successfully deleted.');
          //   setOpenResultMessage(true);
          //   setRenderValue((value) => !value);
        } else {
          fetchBillingRuns(profile!.id);
          setLoading(false);
          //   setResultMessage(deleteRunResult?.msg);
          //   setOpenResultMessage(true);
          //   setRenderValue((value) => !value);
        }
      }
    }
  };

  useEffect(() => {
    const fetchBillingRuns = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        if (profile) {
          const listRunsResult =
            await appStore?.functionService.listBillingRuns(token, profile!.id);
          if (listRunsResult) {
            if (listRunsResult.success) {
              setBillingRuns(listRunsResult.billing_runs);
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
        }
      }
    };
    fetchBillingRuns();
  }, [profile, renderValue]);
  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
        fullWidth={true}
      >
        <DialogTitle
          style={{ alignSelf: "center" }}
          className={classes.bold_text}
          id="simple-dialog-title"
        >
          <span style={{ fontSize: "25px" }}>
            Billing Runs for {profile ? profile.name : ""}{" "}
          </span>
        </DialogTitle>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Billing Run Name</TableCell>
                    <TableCell>Billing Run Description</TableCell>
                    <TableCell>Billing Run Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billingRuns &&
                    billingRuns.map((billingRun, index) => {
                      return (
                        <StyledTableRow>
                          <TableCell>
                            <Typography className={classes.bold_text}>
                              {billingRun.name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={classes.bold_text}>
                              {billingRun.description}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={classes.bold_text}>
                              {billingRun.run_type}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Tooltip
                              placement="bottom"
                              title="Delete Billing Run"
                            >
                              <DeleteIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  deleteBillingRun(billingRun.id);
                                  setRenderValue((value) => !value);
                                }}
                              />
                            </Tooltip>
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        <div
          style={{ marginTop: "5%", marginBottom: "5%" }}
          className={classes.twoButtonsInARow}
        >
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => onClose()}
          >
            <span className={classes.bold_text}>Dismiss</span>
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default ViewBillingRuns;
