import React, { useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Org } from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";

interface AddSharedHubModalProps {
  saveNewSharedHub: (localtion: string) => void;
  onClose: () => void;
  currentOrg: Org;
  open: boolean;
  message: string;
}

const AddSharedHubModal: React.FC<AddSharedHubModalProps> = ({
  onClose,
  open,
  saveNewSharedHub,
  currentOrg,
  message,
}: AddSharedHubModalProps) => {
  const classes = useStyles();
  var location = "";

  const handleSave = () => {
    saveNewSharedHub(location);
  };
  const handleClose = () => {
    onClose();
  };

  const handleLocationChange = (event: React.ChangeEvent<any>) => {
    location = event.target.value;
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Add New Shared Hub to {currentOrg.org_name}
      </DialogTitle>
      <Typography color="error">{message}</Typography>
      <TextField
        onChange={handleLocationChange}
        label="Location"
        variant="outlined"
        margin="normal"
        fullWidth
        autoFocus
        InputProps={{
          className: classes.bold_text,
        }}
        InputLabelProps={{
          classes: {
            root: classes.bold_text,
          },
        }}
      />
      <div className={classes.button_container}>
        <Button
          variant="contained"
          className="add-user-cancel-button"
          onClick={handleClose}
        >
          <span className="button-text">CANCEL</span>
        </Button>
        <Button
          variant="contained"
          className="add-user-save-button"
          onClick={handleSave}
        >
          <span className="button-text">SAVE</span>
        </Button>
      </div>
    </Dialog>
  );
};

export default AddSharedHubModal;
