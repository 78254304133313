import React, { useState, useEffect } from "react";
import { AppStore } from "../../../stores/AppStore";
import { Site, Org } from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import Container from "@material-ui/core/Container";
import { Helpers } from "../../../services/Helpers";
import { Tooltip } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Brightness1 from "@material-ui/icons/Brightness1";

interface SiteMainDrawerProps {
  appStore?: AppStore;
  currentOrg: Org;
  setSelectedItem: (item: any, editing: boolean) => void;
}

//This does not work at the moment as the call to the backend to list the sites time-out

const SitesMainDrawer: React.FC<SiteMainDrawerProps> = ({
  currentOrg,
  appStore,
  setSelectedItem,
}: SiteMainDrawerProps) => {
  const classes = useStyles();
  const helpers = new Helpers();
  const [loading, setLoading] = React.useState(true);
  const [sites, setSites] = useState<Site[] | undefined>(undefined);

  useEffect(() => {
    const fetchData = async () => {};
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        //const orgtree = await appStore?.functionService.getOrgTreeIncHealth(token);
        //if (orgtree && orgtree.success) {
        //    let sitelist = helpers.getSitesFromTree(orgtree.tree.orgs, []);
        //    if (sitelist) {
        //        setSites(sitelist);
        //        setLoading(false);
        //    }
        //}
      }
    };
    fetchData();
  }, []);

  const setSelectedSite = (site: Site, editing: boolean) => {
    site.type = "site";
    setSelectedItem(site, false);
  };
  var sitesdisplay: React.ReactNode[] = [];
  if (sites && sites.length > 0) {
    sites.forEach((site, index) => {
      var fill = "";

      if (site.site_health?.health_indicator == "ok") {
        fill = "#3f54a5";
      }
      if (site.site_health?.health_indicator == "warning") {
        fill = "#b4d5fa";
      }
      if (site.site_health?.health_indicator == "error") {
        fill = "#ee4623";
      }
      var product = [];

      if (site.public_product_name?.includes("Weapons")) {
        product.push(
          <div className="product-brand">
            <img
              className={classes.product_icon}
              src="/assets/img/weapons.png"
              alt=""
            />
          </div>
        );
      } else if (site.public_product_name?.includes("Safety")) {
        product.push(
          <div className="product-brand">
            <img
              className={classes.product_icon}
              src="/assets/img/safety.png"
              alt=""
            />
          </div>
        );
      } else if (site.tier_name?.includes("premium")) {
        product.push(
          <div className="product-brand">
            <img
              className={classes.product_icon}
              src="/assets/img/premium.png"
              alt=""
            />
          </div>
        );
      } else {
        product.push(
          <div className="product-brand">
            <img
              className={classes.product_icon}
              src="/assets/img/connect.png"
              alt=""
            />
          </div>
        );
      }

      var alerting_icon = [];
      if (site.alertconfigcount.count > 0) {
        alerting_icon.push(
          <CheckIcon fontSize="small" style={{ fill: "#3f54a5" }} />
        );
      } else {
        alerting_icon.push(
          <CloseIcon fontSize="small" style={{ fill: "#ee4623" }} />
        );
      }
      sitesdisplay.push(
        <div className={classes.even_shelf}>
          <div
            className={classes.shelf_name}
            onClick={() => setSelectedSite(site, false)}
          >
            <div className={classes.shelf_icon}>
              <svg
                width="20"
                height="17"
                viewBox="0 0 20 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.87">
                  <path
                    d="M17 6.3V1H14V3.6L10 0L0 9H3V17H9V11H11V17H17V9H20L17 6.3ZM15 15H13V9H7V15H5V7.19L10 2.69L15 7.19V15Z"
                    fill="#15325F"
                    fill-opacity="0.87"
                  />
                  <path
                    d="M8 7H12C12 5.9 11.1 5 10 5C8.9 5 8 5.9 8 7Z"
                    fill="#15325F"
                    fill-opacity="0.87"
                  />
                </g>
              </svg>
            </div>
            <div
              className={classes.bold_text}
              onClick={() => setSelectedSite(site, false)}
            >
              {site.name}
            </div>
          </div>
          <div className={classes.shelf_container}>
            <div
              className={classes.shelf_item}
              onClick={() => setSelectedSite(site, false)}
            >
              <div className={classes.bold_text}>{product}</div>
            </div>

            <div
              className={classes.shelf_item}
              onClick={() => setSelectedSite(site, false)}
            >
              <div className="icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 16L13 12V8.82C14.16 8.4 15 7.3 15 6C15 4.34 13.66 3 12 3C10.34 3 9 4.34 9 6C9 7.3 9.84 8.4 11 8.82V12L7 16H3V21H8V17.95L12 13.75L16 17.95V21H21V16H17Z"
                    fill="#15325F"
                    fill-opacity="0.27"
                  />
                </svg>
              </div>

              <div className="text">{site.hubcount}</div>
            </div>

            <div
              className={classes.shelf_item}
              onClick={() => setSelectedSite(site, false)}
            >
              <div className="icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.87">
                    <path
                      d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"
                      fill="#15325F"
                      fill-opacity="0.27"
                    />
                  </g>
                </svg>
              </div>
              <div className="text">{site.sensorcount}</div>
            </div>

            <div
              className={classes.shelf_item}
              onClick={() => setSelectedSite(site, false)}
            >
              <div className="icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.58 4.08L6.15 2.65C3.75 4.48 2.17 7.3 2.03 10.5h2c.15-2.65 1.51-4.97 3.55-6.42zm12.39 6.42h2c-.15-3.2-1.73-6.02-4.12-7.85l-1.42 1.43c2.02 1.45 3.39 3.77 3.54 6.42zM18 11c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2v-5zm-6 11c.14 0 .27-.01.4-.04.65-.14 1.18-.58 1.44-1.18.1-.24.15-.5.15-.78h-4c.01 1.1.9 2 2.01 2z"
                    fill="#15325F"
                    fill-opacity="0.27"
                  />
                </svg>
              </div>
              <div className={classes.shelf_item}>{alerting_icon}</div>
            </div>

            <div
              className={classes.shelf_item}
              onClick={() => setSelectedSite(site, false)}
            >
              <div className="icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"
                    fill="#15325F"
                    fill-opacity="0.27"
                  />
                </svg>
              </div>
              <div className="text">{site.reportcount}</div>
            </div>

            <div className={classes.shelf_item}>
              {!site.meta.frontel_account && (
                <div className="circle">
                  <Brightness1 style={{ fill: fill }}></Brightness1>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });
  }
  if (loading) {
    return (
      <Container maxWidth="md">
        <div className="wait-container">
          <svg width="48px" height="48px" viewBox="0 0 128 128">
            <g>
              <linearGradient id="linear-gradient">
                <stop offset="0%" stop-color="#ffffff" />
                <stop offset="100%" stop-color="#22305f" />
              </linearGradient>
              <path
                d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                fill="url(#linear-gradient)"
                fill-rule="evenodd"
              />
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 64 64"
                to="360 64 64"
                dur="1080ms"
                repeatCount="indefinite"
              ></animateTransform>
            </g>
          </svg>
        </div>
      </Container>
    );
  } else {
    return <div className="main-drawer-container">{sitesdisplay}</div>;
  }
};
export default SitesMainDrawer;
