import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import CustomModal from "../../UI/CustomModal";
import CustomizedSwitch from "../../UI/CustomizedSwitch";
import { useStyles } from "../../styling/global/global";

interface Props {
  openModal: boolean;
  onContinue: (id?: any) => void;
  onClose: () => void;
}

export const ALPRModal: React.FC<Props> = ({
  openModal,
  onContinue,
  onClose,
}) => {
  const classes = useStyles();
  const [enableALPR, setEnableALPR] = useState(false);
  return (
    <div>
      <Grid container spacing={1}>
        <CustomModal
          closeHandler={onClose}
          open={openModal}
          onContinueHandler={onContinue}
          continueAction={enableALPR}
          continue
          title={"continue"}
        >
          <div
            style={{
              padding: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                padding: 20,
                border: "1px solid lightgrey",
                borderRadius: "20px",
                overflowY: "scroll",
                height: 300,
              }}
            >
              <h1
                style={{ textAlign: "center" }}
                className={classes.bold_text_edit_modal_header}
              >
                Warning!!!
              </h1>
              <p
                className={classes.bold_text}
                style={{ fontWeight: 300, marginTop: 20, padding: 30 }}
              >
                Enabling ALPR requires additional processing within DeepAlert
                and may incur additional costs.
                <br />
                <br />
                By enabling this setting you are acknowledging your acceptance
                of potential additional costs
              </p>
            </div>
            <div style={{ marginTop: "50px" }}>
              <CustomizedSwitch
                checked={enableALPR}
                setChecked={(e: boolean) => setEnableALPR(e)}
                label="Enable ALPR"
              />
            </div>
          </div>
        </CustomModal>
      </Grid>
    </div>
  );
};
