import React from "react";
import { useStyles } from "../styling/global/global";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

interface Option {
  label: string;
  value: string;
}

interface Props {
  options: Option[];
  orderBy: (value: any) => void;
  title: string;
  value: string;
}

const OrderBy: React.FC<Props> = ({ options, orderBy, title, value }) => {
  const classes = useStyles();

  // Order By Change
  const handleOrderChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    orderBy(e.target.value);
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel
        htmlFor="outlined-age-native-simple"
        className={classes.bold_text}
      >
        &nbsp; {title} &nbsp;
      </InputLabel>
      <Select
        native
        value={value}
        onChange={(e) => handleOrderChange(e)}
        label={title}
        inputProps={{
          name: "Order By",
          id: "outlined-age-native-simple",
        }}
      >
        {options.map((item: Option, index) => (
          <option value={item.value}>{item.label}</option>
        ))}
      </Select>
    </FormControl>
  );
};

export default OrderBy;
