import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AppStore } from "../../../../stores/AppStore";
import { useStyles } from "../../../styling/global/global";

import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { listBillingProfileOrgsItems } from "../../../../services/FunctionService";
import LoadingSpinner from "../../../../spinner/LoadingSpinner";

interface ViewBillingProfilesProps {
  open: boolean;
  appStore?: AppStore;
  profile: listBillingItems | undefined;
  onClose: () => void;
}
interface listBillingItems {
  id: number;
  enabled: boolean;
  name: string;
  description: string;
  meta: { [key: string]: any };
  created: Date | string;
}

const ViewBillingProfiles: React.FC<ViewBillingProfilesProps> = ({
  open,
  onClose,
  profile,
  appStore,
}: ViewBillingProfilesProps) => {
  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.action.hover,
        },
        display: "flex",
        justifyContent: "space-between",
        textAlign: "left",
        paddingLeft: "2%",
        paddingRight: "2%",
      },
    })
  )(TableRow);

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [billingOrgsProfiles, setBillingOrgsProfiles] = useState<
    listBillingProfileOrgsItems[] | undefined
  >(undefined);

  useEffect(() => {
    const ViewProfileFunction = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        setLoading(true);
        if (profile) {
          const viewBillingProfileOrgsResult =
            await appStore?.functionService.listBillingProfileOrgs(
              token,
              profile.id
            );
          if (viewBillingProfileOrgsResult?.success) {
            if (viewBillingProfileOrgsResult.billing_profile_orgs) {
              setBillingOrgsProfiles(
                viewBillingProfileOrgsResult.billing_profile_orgs
              );
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        }
      }
    };
    ViewProfileFunction();
  }, [profile]);
  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
        fullWidth={true}
      >
        <DialogTitle
          style={{ alignSelf: "center" }}
          className={classes.bold_text}
          id="simple-dialog-title"
        >
          <Typography
            className={classes.bold_text}
            style={{ color: "#3f51b5" }}
          >
            billing profile details
          </Typography>
        </DialogTitle>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div
            style={{
              width: "90%",
              display: "flex",
              alignItems: "center",
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                marginTop: 15,
                borderRadius: 15,
                overflow: "hidden",
                width: "100%",
              }}
            >
              <TableContainer style={{ maxHeight: 500 }}>
                <StyledTableRow>
                  <TableCell>
                    <Typography
                      className={classes.bold_text}
                      style={{ color: "#3f51b5" }}
                    >
                      Profile Name :{" "}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      className={classes.bold_text}
                      style={{ color: "grey" }}
                    >
                      {profile ? profile.name : ""}
                    </Typography>
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell>
                    <Typography
                      className={classes.bold_text}
                      style={{ color: "#3f51b5" }}
                    >
                      profile id :{" "}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      className={classes.bold_text}
                      style={{ color: "grey" }}
                    >
                      {profile ? profile.id : ""}
                    </Typography>
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell>
                    <Typography
                      className={classes.bold_text}
                      style={{ color: "#3f51b5" }}
                    >
                      Description :{" "}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      className={classes.bold_text}
                      style={{ color: "grey" }}
                    >
                      {profile ? profile.description : ""}
                    </Typography>
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell>
                    <Typography
                      className={classes.bold_text}
                      style={{ color: "#3f51b5" }}
                    >
                      CreatedAt :{" "}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      className={classes.bold_text}
                      style={{ color: "grey" }}
                    >
                      {profile
                        ? moment(profile.created).format("DD-MM-YYYY")
                        : ""}
                    </Typography>
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell>
                    <Typography
                      className={classes.bold_text}
                      style={{ color: "#3f51b5" }}
                    >
                      Enabled Status :{" "}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      className={classes.bold_text}
                      style={{ color: "grey" }}
                    >
                      {profile
                        ? profile.enabled === true
                          ? "enabled"
                          : "disabled"
                        : ""}
                    </Typography>
                  </TableCell>
                </StyledTableRow>
              </TableContainer>
            </div>
          </div>
        )}

        <div
          style={{ marginTop: "5%", marginBottom: "5%" }}
          className={classes.twoButtonsInARow}
        >
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => onClose()}
          >
            <span className={classes.bold_text}>Dismiss</span>
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default ViewBillingProfiles;
