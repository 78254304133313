import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AppStore } from "../../../stores/AppStore";
import { useStyles } from "../../styling/global/global";
import { AlertEndPoint } from "../../../services/FunctionService";

interface AlertConfirmationProps {
  open: boolean;
  appStore?: AppStore;
  onClose: () => void;
  alertchannelInput: AlertEndPoint | undefined;
  HandleUpdateDisconnectNotifications: (input: AlertEndPoint) => void;
}

const AlertConfirmation: React.FC<AlertConfirmationProps> = ({
  appStore,
  open,
  onClose,
  alertchannelInput,
  HandleUpdateDisconnectNotifications,
}: AlertConfirmationProps) => {
  const [mockState, setMockState] = useState(0);

  const classes = useStyles();

  useEffect(() => {}, [mockState]);

  return (
    <div>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        className="edit-dialog"
      >
        <DialogTitle className={classes.bold_text}>
          Are you sure you want to{" "}
          {typeof alertchannelInput?.meta["send_disconnect_notifications"] !=
          "undefined"
            ? alertchannelInput?.meta["send_disconnect_notifications"] === true
              ? "disable"
              : "enable"
            : "disable"}{" "}
          disconnection notifications ?
        </DialogTitle>
        <div className={classes.twoButtonsInARow}>
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => onClose()}
          >
            <span className={classes.bold_text}>Dismiss</span>
          </Button>
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={() => {
              setMockState((value) => value + 1);
              HandleUpdateDisconnectNotifications(alertchannelInput!);
              onClose();
            }}
          >
            <span className={classes.bold_text}>Update</span>
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default AlertConfirmation;
