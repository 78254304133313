import { Skeleton } from "antd";
import React from "react";

const App: React.FC = () => {
  return (
    <>
      <Skeleton.Image style={{ width: 520, height: 210 }} />
      <br />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
    </>
  );
};

export default App;
