import React, { useRef } from "react";
import ImageMapper from "react-img-mapper";

interface imageProp {
  imgUrl: string;
  coords: any;
}

const MuiltMapper: React.FC<imageProp> = ({ imgUrl, coords }) => {
  const [hoveredAreaName, setHoveredAreaName] = React.useState({ name: "" });

  const containerRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  let MAP = {
    id: `${Math.random()}`,
    name: "my-map",
    areas: coords,
    // [
    //   {name: "person", shape: "rect", coords: [79, 246, 153, 357],  strokeColor: "green", preFillColor: 'none',},
    //   {name: "car", shape: "rect", coords: [257, 222, 316, 340],  strokeColor: "pink", preFillColor: 'none',  },
    //   {name: "person", shape: "rect", coords: [98, 183, 124, 255],  strokeColor: "yellow", preFillColor: 'rgba(0,0,0, 0)',  },
    //   {name: "truck", shape: "rect", coords: [128, 166, 410, 320], strokeColor: "red", preFillColor: 'rgba(0,0,0, 0)',  },
    // ]
  };

  const URL =
    "https://s3.eu-central-1.wasabisys.com/incoming-images.staging.2021-9/to_view/TSLGBMO-a/20210927/10%3A52%3A14.549334/TSLGBMO-a_20210927_105213.921803_sjv6MBqwHxUJcKAl_0_vf.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=6OAFXDB2I19HWLV3PI1L%2F20210927%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20210927T122731Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=c12e54716517618632228a4d1aae2a05afaa19e5e9e5e018bcd2861dfa870d16";

  function getTipPosition(area: any) {
    return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
  }

  function enterArea(area: any) {
    setHoveredAreaName(area);
    // this.setState({
    // 	hoveredArea: area,
    // 	msg: `You entered ${area.shape} ${area.name} at coords ${JSON.stringify(
    // 		area.coords
    // 	)} !`
    // });
  }

  function leaveArea(area: any) {
    // this.setState({
    // 	hoveredArea: null,
    // 	msg: `You leaved ${area.shape} ${area.name} at coords ${JSON.stringify(
    // 		area.coords
    // 	)} !`
    // });
  }

  function moveOnArea(area: any) {
    // const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    // this.setState({
    // 	moveMsg: `You moved on ${area.shape} ${
    // 		area.name
    // 	} at coords ${JSON.stringify(coords)} !`
    // });
  }

  return (
    <>
      <ImageMapper
        containerRef={containerRef!}
        // imgWidth={ogWidth}
        // width={width}
        areaKeyName={`id`}
        src={imgUrl}
        lineWidth={2}
        natural={false}
        map={MAP}
        onMouseEnter={(area) => enterArea(area)}
        onMouseLeave={(area) => leaveArea(area)}
        onMouseMove={(area, _, evt) => moveOnArea(area)}
      />
      {/* <span style={{ backgroundColor: '#ccce', color: 'red' }}>
        {hoveredAreaName && hoveredAreaName!.name}
      </span> */}
    </>
  );
};

export default MuiltMapper;
