import React, { useState, useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { AppStore } from "../../../stores/AppStore";
import { Site, OrgBillingRate, Org } from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";

import { time_zones } from "../../../hooks/Timezones";

interface AddSiteModalProps {
  appStore?: AppStore;
  onClose: () => void;
  currentOrg: Org;
  open: boolean;
  saveNewSite: (
    site_name: string,
    createhub: boolean,
    billing_rate_id: number,
    timezone: string,
    frontelOrg: boolean,
    ajaxOrg: boolean,
    ajaxHubId: string
  ) => void;
  saveNewVirtualSite: (
    site_name: string,
    billing_rate_id: number,
    timezone: string,
    frontelOrg: boolean,
    ajaxOrg: boolean,
    ajaxHubId: string
  ) => void;

  message: string;
}

const AddSiteModal: React.FC<AddSiteModalProps> = ({
  onClose,
  open,
  appStore,
  message,
  currentOrg,
  saveNewSite,
  saveNewVirtualSite,
}: AddSiteModalProps) => {
  const classes = useStyles();
  const [site_name, setSiteName] = React.useState("");
  const [createhub, setCreateHub] = React.useState(true);
  const [timezone, setTimeZone] = React.useState("Africa/Johannesburg");
  const [org_billing_rate_id, setOrgBillingRateId] = React.useState(0);
  const [frontelOrg, setFrontelOrg] = React.useState(false);
  const [ajaxOrg, setAjaxOrg] = React.useState(false);
  const [ajaxHubId, setAjaxHubId] = React.useState("");
  const [localmessage, setMessage] = React.useState(message);
  const [billing_rates, setBillingRates] = React.useState<
    OrgBillingRate[] | undefined
  >(undefined);
  const [charError, setCharError] = useState(false);
  const [ajaxCharError, setAjaxCharError] = useState(false);

  let frontelOrgIds: number[] = [];
  let ajaxOrgIds: number[] = [];

  useEffect(() => {
    const fetchData = async () => {
      var token = await appStore?.authService.getAuthorisedToken();
      if (token && currentOrg && currentOrg.org_id) {
        var billing_rates =
          await appStore?.functionService.getBillingRatesForOrg(
            token,
            currentOrg.org_id,
            true
          );
        if (billing_rates) {
          setBillingRates(billing_rates);
        }
      }
    };
    fetchData();
  }, [open]);

  var isvirtual = false;
  var isAjax = false;
  if (billing_rates && billing_rates?.length > 0) {
    let billingrate = billing_rates?.find((x) => x.id === +org_billing_rate_id);
    if (billingrate?.data_origin_name) {
      if (
        billingrate?.data_origin_name === "alert_rest_client" ||
        billingrate?.data_origin_name === "alert_smtp_client" ||
        billingrate?.data_origin_name === "alert_ftp_client" ||
        billingrate?.data_origin_name === "inference_api_client" ||
        billingrate?.data_origin_name.includes("frontel") ||
        billingrate?.data_origin_name.includes("deepapi")
      ) {
        isvirtual = true;
      }
      if (billingrate?.data_origin_name.includes("ajax")) {
        isvirtual = true;
        isAjax = true;
      }
      if (billingrate?.data_origin_name.includes("client_hub")) {
        isvirtual = false;
      }
    }
  }
  const handleClose = () => {
    setMessage("");
    setOrgBillingRateId(0);
    setFrontelOrg(false);
    setCreateHub(true);
    setSiteName("");
    onClose();
  };
  const handleSave = () => {
    if (isvirtual) {
      if (site_name === "") {
        setMessage("Site name cannot be empty!");
      } else if (charError) {
        setMessage(
          "Name must only contain numbers,letters or the following characters: _-"
        );
      } else if (ajaxCharError) {
        setMessage("Ajax Hub ID must only contain numbers and the letters a-f");
      } else if (ajaxOrg === true && ajaxHubId === "") {
        setMessage("ajax Hub ID cannot be empty!");
      } else {
        saveNewVirtualSite(
          site_name,
          org_billing_rate_id,
          timezone,
          frontelOrg,
          ajaxOrg,
          ajaxHubId
        );
      }
    } else {
      if (site_name === "") {
        setMessage("Site name cannot be empty!");
      } else if (ajaxOrg === true && ajaxHubId === "") {
        setMessage("ajax Hub ID cannot be empty!");
      } else if (charError) {
        setMessage(
          "Name must only contain numbers,letters or the following characters: _-"
        );
      } else if (ajaxCharError) {
        setMessage("Ajax Hub ID must only contain numbers and the letters a-f");
      } else if (org_billing_rate_id == 0) {
        setMessage("Please select a product for the site!");
      } else {
        saveNewSite(
          site_name,
          createhub,
          org_billing_rate_id,
          timezone,
          frontelOrg,
          ajaxOrg,
          ajaxHubId
        );
      }
    }
  };
  const handleCheck = (event: React.ChangeEvent<any>) => {
    setCreateHub(event.target.checked);
  };
  const handleNameChange = (event: React.ChangeEvent<any>) => {
    let letterNumber = /^[0-9a-zA-Z_ -]+$/;
    if (event.target.value.match(letterNumber)) {
      setCharError(false);
      setSiteName(event.target.value);
    } else {
      setSiteName(event.target.value);
      setCharError(true);
    }
  };
  const handleAjaxHubIdChange = (event: React.ChangeEvent<any>) => {
    let letterNumber = /^[0-9a-fA-F]+$/;
    if (event.target.value.match(letterNumber)) {
      setAjaxCharError(false);
      setAjaxHubId(event.target.value);
    } else {
      setAjaxHubId(event.target.value);
      setAjaxCharError(true);
    }
  };
  const handleTimeZoneChange = (event: React.ChangeEvent<any>) => {
    setTimeZone(event.target.value);
  };
  const handleProductChange = (event: React.ChangeEvent<any>) => {
    setOrgBillingRateId(event.target.value);
    if (frontelOrgIds.includes(parseInt(event.target.value))) {
      setFrontelOrg(true);
      setAjaxOrg(false);
    } else if (ajaxOrgIds.includes(parseInt(event.target.value))) {
      setFrontelOrg(false);
      setAjaxOrg(true);
    } else {
      setFrontelOrg(false);
      setAjaxOrg(false);
    }
  };

  var products: React.ReactNode[] = [];
  if (billing_rates) {
    billing_rates.forEach((org_billing_rate) => {
      if (
        org_billing_rate.public_product_name
          ?.toLowerCase()
          .includes("frontel") ||
        org_billing_rate.public_product_name?.toLowerCase().includes("rsi")
      ) {
        frontelOrgIds.push(org_billing_rate.id);
      } else if (
        org_billing_rate.public_product_name?.toLowerCase().includes("ajax")
      ) {
        ajaxOrgIds.push(org_billing_rate.id);
      }
      products.push(
        <FormControlLabel
          className={classes.bold_text}
          value={org_billing_rate.id.toString()}
          control={<Radio color="primary" />}
          label={`${org_billing_rate.public_product_name} (id=${org_billing_rate.billing_rate_id}) `}
        />
      );
    });
  }

  // var timezones: React.ReactNode[] = [];
  // timezones.push(
  //   <MenuItem value='Africa/Johannesburg'>
  //     <span className={classes.bold_text}>Africa/Johannesburg</span>
  //   </MenuItem>
  // );
  // timezones.push(
  //   <MenuItem value="Africa/Windhoek">
  //     <span className={classes.bold_text}>Africa/Windhoek</span>
  //   </MenuItem>
  // );
  // timezones.push(
  //   <MenuItem value='Europe/London'>
  //     <span className={classes.bold_text}>Europe/London</span>
  //   </MenuItem>
  // );
  var nonvirtual: React.ReactNode[] = [];
  if (!isvirtual) {
    nonvirtual.push(
      <>
        <FormControlLabel
          className={classes.form_component_checkbox}
          control={
            <Checkbox
              color="primary"
              checked={createhub}
              onChange={handleCheck}
            />
          }
          label="Add DeepAlert Hub Device"
        />
      </>
    );
  }
  if (isAjax) {
    nonvirtual.push(
      <>
        <TextField
          onChange={handleAjaxHubIdChange}
          label="Ajax Hub Id"
          variant="outlined"
          margin="normal"
          fullWidth
          error={ajaxCharError}
          helperText={
            ajaxCharError
              ? "Ajax Hub ID must only contain numbers and the letters a-f"
              : ""
          }
          autoFocus
          InputProps={{
            className: classes.bold_text,
          }}
          InputLabelProps={{
            classes: {
              root: classes.bold_text,
            },
          }}
        />
      </>
    );
  }
  if (
    appStore &&
    appStore.authService!.getRoles().indexOf("billing_admin") >= 0 &&
    appStore!.authService!.getRoles().indexOf("site_admin") >= 0
  ) {
    if (products) {
      return (
        <Dialog
          onClose={handleClose}
          open={open}
          className={classes.modal_dialog}
        >
          <DialogTitle className={classes.bold_text}>
            Add New Site in {currentOrg.org_name}
          </DialogTitle>
          <br></br>
          <Typography className={classes.bold_error_text}>
            {localmessage}
          </Typography>

          <TextField
            onChange={handleNameChange}
            label="Site Name"
            variant="outlined"
            margin="normal"
            fullWidth
            error={charError}
            helperText={
              charError
                ? "Name must only contain numbers,letters or the following characters: _-"
                : ""
            }
            autoFocus
            InputProps={{
              className: classes.bold_text,
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text,
              },
            }}
          />
          <FormControl component="fieldset" className={classes.form_component}>
            <FormLabel className={classes.bold_text}>
              Product for site :
            </FormLabel>
            <RadioGroup
              value={org_billing_rate_id}
              onChange={handleProductChange}
            >
              {products}
            </RadioGroup>
          </FormControl>
          <FormControl variant="outlined" className={classes.form_component}>
            <InputLabel className={classes.bold_text}>
              Alert Time Zone
            </InputLabel>
            <Select value={timezone} onChange={handleTimeZoneChange}>
              {time_zones.map((zone) => (
                <MenuItem value={zone}>{zone}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {nonvirtual}

          <div className={classes.button_container}>
            <Button
              variant="contained"
              className={classes.cancel_button}
              onClick={handleClose}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              className={classes.save_button}
              onClick={handleSave}
            >
              SAVE
            </Button>
          </div>
        </Dialog>
      );
    } else {
      return (
        <svg width="48px" height="48px" viewBox="0 0 128 128">
          <g>
            <linearGradient id="linear-gradient">
              <stop offset="0%" stop-color="#ffffff" />
              <stop offset="100%" stop-color="#22305f" />
            </linearGradient>
            <path
              d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
              fill="url(#linear-gradient)"
              fill-rule="evenodd"
            />
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 64 64"
              to="360 64 64"
              dur="1080ms"
              repeatCount="indefinite"
            ></animateTransform>
          </g>
        </svg>
      );
    }
  } else {
    return (
      <Dialog
        onClose={handleClose}
        open={open}
        className={classes.modal_dialog}
      >
        <DialogTitle className={classes.bold_text}>
          Add New Site in {currentOrg.org_name}
        </DialogTitle>
        <br></br>
        <Typography className={classes.bold_text}>
          {
            "You need billing admin and site admin roles to be able to add a site - please contact your DeepAlert system administrator"
          }
        </Typography>
        <div className={classes.button_container}>
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={handleClose}
          >
            CANCEL
          </Button>
        </div>
      </Dialog>
    );
  }
};
export default AddSiteModal;
