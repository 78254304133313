import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useStyles } from "../../../styling/global/global";
interface BillingRegistrationExpansionProps {
  add: boolean;
  process: boolean;
  remove: boolean;
  process_value: boolean;
  handleAdd: () => void;
  handleProcess: () => void;
  handleRemove: () => void;
}
const BillingRegistrationExpansion: React.FC<
  BillingRegistrationExpansionProps
> = ({
  remove,
  add,
  process,
  process_value,
  handleProcess,
  handleRemove,
  handleAdd,
}: BillingRegistrationExpansionProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Add = () => {
    setAnchorEl(null);
    handleAdd();
  };
  const Process = () => {
    setAnchorEl(null);
    handleProcess();
  };

  const Remove = () => {
    setAnchorEl(null);
    handleRemove();
  };

  var menuitems: React.ReactNode[] = [];

  if (add) {
    menuitems.push(
      <MenuItem onClick={Add}>
        <span className={classes.bold_text}>add user</span>
      </MenuItem>
    );
  }
  if (process) {
    menuitems.push(
      <MenuItem onClick={Process}>
        <span className={classes.bold_text}>
          {process_value ? "mark as unprocessed" : "mark as processed"}
        </span>
      </MenuItem>
    );
  }

  if (remove) {
    menuitems.push(
      <MenuItem onClick={Remove}>
        <span className={classes.bold_text}>delete user</span>
      </MenuItem>
    );
  }

  return (
    <>
      <div>
        <IconButton
          style={{ marginLeft: "2%" }}
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onMouseLeave={handleClose}
        >
          {menuitems}
        </Menu>
      </div>
    </>
  );
};
export default BillingRegistrationExpansion;
