import React, { useState, useEffect } from "react";
import { AppStore } from "../../stores/AppStore";
import { useStyles } from "../styling/global/global";
import { makeStyles } from "@material-ui/core/styles";

import Switch from "@material-ui/core/Switch";
import {
  withStyles,
  useTheme,
  Theme,
  createStyles,
  fade,
} from "@material-ui/core/styles";

import { Org, User, UsersForOrg } from "../../services/FunctionService";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// end

interface UserListProps {
  appStore?: AppStore;
  groupid: number;
  setSelectedUserId: (id: number) => void;
}

// END OF Full Screen Modal
interface MockEvent {
  (event: Event): any;
}

// Switch Styles
const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch);

// WithStyles
const useStyle = makeStyles({
  paper: {
    border: "2px solid #6D809D",
    color: "#15325F",
  },
});

const UserList: React.FC<UserListProps> = ({
  appStore,
  groupid,
  setSelectedUserId,
}: UserListProps) => {
  const [loading, setLoading] = React.useState(true);
  let [isEditable, setIsEditable] = React.useState(false);

  const [usersfortree, setUsersForTree] = useState<User[] | undefined>([]);
  const [value, setValue] = React.useState(0);
  const [userId, setUserId] = useState<number | undefined>(-1);

  const [usersDefaultText, setUsersDefaultText] =
    useState("Please Select User");

  // Users & Roles
  const [allUsers, setAllUsers] = React.useState(true);
  const [userFullName, setUserFullName] = useState("");
  const [roleNames, setRoleNames] = React.useState<string[] | any>([]);
  let [channelsData, setChannelsData] = useState<number[]>([]);

  // Toast
  const [openToast, setOpenToast] = React.useState(false);

  const [message, setMessage] = React.useState("");

  const [enabled, setEnabled] = useState(true);

  const getBaseUrl = (): string => {
    let base = "";
    if (window.location.href.includes("local")) {
      base = "https://manage.staging.deepalert.ai";
    }
    base += "/api/v1.3";
    return base;
  };

  if (message) {
    setTimeout(function () {
      setMessage("");
    }, 20000); //20 Seconds delay
  }

  const handleToastClose = () => {
    setMessage("");
    setOpenToast(false);
    setChannelsData(channelsData);
  };

  //Radio
  const [selectedValue, setSelectedValue] = React.useState("subgroups");
  const [includeSubs, setIncludeSubs] = React.useState(true);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const styler = useStyle();

  // STYLING SECTION
  const classes = useStyles();

  const handleChangeUser = (value: any) => {
    handleToastClose();
    setRoleNames([]);
    setIsEditable(false);
    if (!value?.user_id) {
      setUserId(-1);
      setSelectedUserId(0);
      return setUserFullName(``);
    }
    let new_user_id = +value.user_id;
    let user = usersfortree!.find((x) => x.user_id === new_user_id);
    setUserId(new_user_id);
    setSelectedUserId(new_user_id);
    if (user) {
      setUserFullName(`${user.first_name} ${user.last_name}`);
    }
  };

  // 2)Fetching Users
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setUserFullName("");
      setUsersDefaultText("Please Select User");
      const tok = await appStore?.authService.getAuthorisedToken();
      if (tok && groupid >= 0) {
        let usersData: User[] = [];
        usersData = await getAllUsersForOrg(tok, groupid, false, enabled);

        setUserId(-1);
        setUsersForTree(usersData);
        // setLoading(false);
      }
      setLoading(false);
    };
    fetchData();
  }, [groupid, allUsers, includeSubs, enabled]);

  // Fetch Users Function (All Users and For Specific Org Tree)
  const getAllUsersForOrg = async (
    access_token: string,
    top_org_id: number,
    top_org_only: boolean,
    enabled: boolean
  ): Promise<User[] | any> => {
    var baseUrl = getBaseUrl();
    try {
      const request = JSON.stringify({
        top_org_id: top_org_id,
        order_by: "username",
        top_org_only: top_org_only,
        paginate: false,
        verbose: true,
        enabled: enabled ? undefined : true,
      });

      const res = fetch(baseUrl + "/list_users", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: request,
      });
      const result = (await (await res).json()) as UsersForOrg;
      let ret = result.users;
      return ret;
    } catch (err) {
      return err;
    }
  };

  return (
    <Autocomplete
      id="combo-box-demo"
      loading={loading}
      options={usersfortree!}
      onChange={(event: React.ChangeEvent<any>, value: any) => {
        handleChangeUser(value);
      }}
      getOptionLabel={(option) => {
        return `${option.first_name} ${option.last_name} (${option.username})`;
      }}
      renderOption={(option: any) => {
        if (!option.enabled) {
          return (
            <span style={{ color: "red" }}>
              {option.first_name} {option.last_name} - ({option.username})
            </span>
          );
        }
        return (
          <span>
            {option.first_name} {option.last_name} - {option.username}
          </span>
        );
      }}
      style={{
        borderRadius: 0,
        width: 380,
        color: "#6D809D",
        marginTop: 0,
        marginRight: 3,
        backgroundColor: "white",
      }}
      classes={{ paper: styler.paper }}
      renderInput={
        (params) =>
          // (
          {
            return (
              <TextField
                {...params}
                label={
                  <span className={classes.bold_text}>
                    {userFullName ? userFullName : "Filter by user"}
                  </span>
                }
                variant="outlined"
                value={userId}
                className={classes.bold_text}
              />
            );
          }
        // )
      }
    />
  );
};

export default UserList;
