import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { Typography as AntTypography } from "antd";
import {
  Card,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import Input from "@material-ui/core/Input";
import {
  DirectionsCar,
  DirectionsWalk,
  Pets,
  Help,
  Height,
} from "@material-ui/icons";
import EditNoteIcon from "@mui/icons-material/EditNote";
import TimeEntryPanel from "../TimeEntryUI/TimeEntryPanel";
import SecurityIcon from "@material-ui/icons/Security";
import SportsIcon from "@material-ui/icons/Sports";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import MultilineChartIcon from "@material-ui/icons/MultilineChart";
import { TimeEntrySet } from "../../../core/models/TimeEntry";
import Container from "@material-ui/core/Container";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Tooltip } from "@material-ui/core";
import { Box } from "@material-ui/core";
import {
  AlertConfig,
  FunctionService,
  MultiEmail,
} from "../../../services/FunctionService";
import { AppStore } from "../../../stores/AppStore";
import {
  AlertChannel,
  Site,
  Org,
  Classifier,
  AlertEndPoint,
  AlertEndPointDef,
  UserEndPointRoles,
  Sensor,
} from "../../../services/FunctionService";
import AddRuleModal from "../../modals/AddRuleModal/AddRuleModal";
import EditRuleModal from "../../modals/AddRuleModal/EditRuleModal";
import EditRulesModal from "../../modals/AddRuleModal/EditRulesModal";
import MessageModal from "../../modals/MessageModal/MessageModal";
import AddAlertEndpointModal from "../../modals/AddAlertEndpointModal/AddAlertEndpointModal";
import EditAlertEndpointModal from "../../modals/AddAlertEndpointModal/EditAlertEndpointModal";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import { Guid } from "guid-typescript";
import ExpansionPanelMenu from "../../components/ExpansionPanelMenu/ExpansionPanelMenu";
import { useStyles } from "../../styling/global/global";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Button from "@material-ui/core/Button";
import AlertConfirmation from "../../modals/AlertConfirmation/AlertConfirmation";
import EditHikCentral from "../../modals/AddHickCentralModal/EditHikCentral";
import WebSettings from "../../modals/AddToTelegram/WebSettings";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { time_zones } from "../../../hooks/Timezones";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CircularProgress from "@mui/material/CircularProgress";
import zIndex from "@material-ui/core/styles/zIndex";
import RulesList from "../CameraMainDrawer/RulesList";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import LoadingSpinner from "../../../spinner/LoadingSpinner";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { v4 as uuidv4 } from "uuid";
import { Console } from "console";

const { Text } = AntTypography;

class impMultiEmail implements MultiEmail {
  key!: Guid;
  email!: string;
  parent_id!: number;
}

interface AlertChannelInSiteProps {
  appStore?: AppStore;
  alertchannel: AlertChannel;
  reload?: any;
  currentOrg: Org;
  site: Site | undefined;
  setRefreshParent: () => void;
}

interface CardData {
  id: any;
  days: string[];
  startTime: number[];
  endTime: number[];
}

const style = {
  textAlign: "center",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 200,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const addRuleModal = {
  overflowY: "scroll",
  textAlign: "center",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 880,
  height: 550,
  maxHeight: 700,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const editRuleModal = {
  overflowY: "scroll",
  textAlign: "center",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1050,
  height: 550,
  maxHeight: 700,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const updateMsg = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  height: 200,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const configureRuleModal = {
  overflowY: "scroll",
  textAlign: "center",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 550,
  maxHeight: 700,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const addRuleDialogModal = {
  textAlign: "center",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  minHeight: 200,
  height: 200,
  maxHeight: 500,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

interface ZoneConfig {
  zone?: string;
  count?: number;
  class_label?: any;
  count_logic?: string;
  object_motion?: any;
  confidence_threshold?: any;
}

interface Rule {
  rule_name: any;
  rule_type: any;
  rule_subtype: any;
  alert_type: any;
  zone_configs?: ZoneConfig[];
  valid_days_and_times: string | null;
  cadence_secs?: number | null;
}

const AlertChannelInSite: React.FC<AlertChannelInSiteProps> = ({
  appStore,
  alertchannel,
  reload,
  currentOrg,
  site,
  setRefreshParent,
}: AlertChannelInSiteProps) => {
  // FETCH RELAVENT DEFS INFORMATION
  const [relaventRuleData, setRelaventRuleData] = useState<any>({});
  const [rule, setRule] = React.useState<string>("");
  const [ruleTypes, setRuleTypes] = useState<string[]>([]);
  const [zoneConfigDefs, setZoneConfigDefs] = useState<any>([]);
  const [validDaysTimesDef, setValidDaysTimesDef] = useState<any>({});
  const [fetchIntervalDef, setFetchIntervalDef] = useState<any>({});
  const [ruleDefs, setRuleDefs] = useState<any>([]);
  const [occuranceCount, setOccuranceCount] = useState<any>({});
  const [alert, setAlert] = React.useState<string | null>(null);
  const [relaventRuleDataToDelete, setRelaventRuleDataToDelete] = useState<any>(
    {}
  );
  const [ruleSubTypeToDelete, setRuleSubTypeToDelete] = useState<string>("");

  useEffect(() => {
    const foundRuleData = ruleDefs?.find(
      (option: any) =>
        ruleSubTypeToDelete === option?.fields?.rule_subtype?.subtype
    );
    setRelaventRuleDataToDelete(foundRuleData);
  }, [ruleSubTypeToDelete]);

  useEffect(() => {
    const siteDefaultRuleDefs = async () => {
      try {
        const token = await appStore?.authService?.getAuthorisedToken();
        if (!token) {
          return;
        } else if (token) {
          const siteDefs = await appStore?.functionService?.siteDefaultRuleDefs(
            token,
            site?.site_id
          );
          if (siteDefs && siteDefs?.success) {
            setRuleDefs(siteDefs?.rule_defs);
          }
        }
      } catch (err) {
        console.error("Error:", err);
      } finally {
        // setLocalLoading(false);
      }
    };
    siteDefaultRuleDefs();
  }, [site?.site_id]);

  useEffect(() => {
    if (alert) {
      setRuleTypes(
        ruleDefs?.filter(
          (rule: any) => rule?.fields?.alert_type === alert?.toLowerCase()
        )
      );
    }
  }, [alert]);

  const relaventRuleTypeOptions = (data: any) => {
    let abledOptionsArray: any = [];
    let disabledOptionsArray: any = [];
    let max_count_data: any = {};
    data?.forEach((item: any) => {
      if (item?.meta?.create) {
        if (
          item?.meta?.max_count &&
          item?.meta?.max_count <=
            occuranceCount[item?.fields?.rule_subtype?.subtype]
        ) {
          disabledOptionsArray?.push(`${item?.fields?.rule_subtype?.label}`);
        } else if (
          item?.meta?.max_count !==
          occuranceCount[item?.fields?.rule_subtype?.subtype]
        ) {
          abledOptionsArray?.push(item?.fields?.rule_subtype?.label);
          max_count_data[item?.fields?.rule_subtype] = item?.meta?.max_count;
        }
      } else if (!item?.meta?.create) {
        disabledOptionsArray?.push(
          `${
            item?.fields?.rule_subtype?.label
          } (${item?.meta?.create_reason.toLowerCase()})`
        );
        max_count_data[item?.fields?.rule_subtype] = item?.meta?.max_count;
      }
    });
    return {
      abled: abledOptionsArray,
      disabled: disabledOptionsArray,
      max_count_data,
    };
  };

  let ruleTypeOptions = relaventRuleTypeOptions(ruleTypes);

  useEffect(() => {
    const foundRuleData = ruleDefs?.find(
      (option: any) => rule === option?.fields?.rule_subtype?.label
    );
    setRelaventRuleData(foundRuleData);
    if (foundRuleData) {
      setZoneConfigDefs(foundRuleData?.fields?.zone_configs);
      setValidDaysTimesDef(foundRuleData?.fields?.valid_days_and_times);
      if (foundRuleData?.fields?.cadence_secs) {
        setFetchIntervalDef(foundRuleData?.fields?.cadence_secs);
      }
    }
  }, [rule, ruleTypeOptions]);

  // LOGIC FOR ADDING A RULE
  const [ruleConfig, setRuleConfig] = React.useState(false);
  const [openRuleConfig, setOpenRuleConfig] = React.useState<boolean>(false);
  const [collectRules, setCollectRules] = useState<string[]>([]);

  const handleRuleConfigModalClose = () => {
    setZoneData([]);
    setRuleConfig(false);
  };

  const openRuleConfigHandler = () => {
    setOpenRuleConfig(true);
  };

  const closeRuleConfigDialog = () => {
    setAlert(null);
    setOpenRuleConfig(false);
  };

  const [selectedInterVal, setSelectedInterVal] = useState<string | null>(
    relaventRuleData?.fields?.cadence_secs?.default || null
  );
  const [selectedInterValEdit, setSelectedInterValEdit] = useState<
    string | null
  >(null);

  const handleIntervalChange = (event: any) => {
    setSelectedInterVal(event?.target?.value);
  };

  const handleIntervalEditChange = (event: any) => {
    setSelectedInterValEdit(event?.target?.value);
  };

  const initialAlertTypes = ["Analytics", "Scheduled"];

  const [ruleName, setRuleName] = useState<string>("");
  const [alertTypes, setAlertTypes] = useState<string[]>(initialAlertTypes);

  const ruleNameHandler = (event: any) => {
    setRuleName(event?.target?.value);
  };

  const handleAlertChange = (event: any, value: any) => {
    setAlert(value);
  };

  const handleRuleChange = (event: any, value: any) => {
    setRule(value);
    setRuleName(value);
  };

  // DEFAULT RULES LOGIC
  const [radioValue, setRadioValue] = React.useState<any>("always");
  const [expandDefaultRules, setExpandDefaultRules] = useState<boolean>(false);
  const [expandListOfRules, setExpandListOfRules] = useState<boolean>(false);
  const [default_rules, setDefaultRules] = useState<any>(null);

  const [listRules, setListRules] = useState<any>(null);
  const [loadingSpinner, setLoadingSpinner] = useState<boolean>(false);
  const [parentReload, setParentReload] = useState<any>(new Date().getTime());
  const [orgName, setOrgName] = useState<any>(null);
  const [steps, setSteps] = useState<number>(1);
  const [fetchRules, setFetchRules] = useState<number>(0);

  // HANDLE THE VALID DAYS AND TIMES FORM
  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue(event?.target?.value);
  };

  const fetchDefaultRules = async () => {
    try {
      const token = await appStore?.authService?.getAuthorisedToken();
      if (!token) {
        return;
      } else if (token) {
        const default_rules =
          await appStore?.functionService?.fetchSiteDefaultRules(
            token,
            site?.site_id
          );
        if (default_rules && default_rules?.success) {
          const rulesWithIds = default_rules.default_rules.rules.map(
            (rule: any) => ({
              ...rule,
              id: uuidv4(), // Add a unique id to each rule
            })
          );

          setDefaultRules({
            rules_origin: default_rules.default_rules.rules_origin,
            rules: rulesWithIds,
          });
          setOrgName(default_rules?.rules_origin?.site_name);

          let occuranceData: any = {};
          rulesWithIds?.forEach((rule: any) => {
            const subtype = rule?.rule_subtype;
            if (subtype) {
              // Increment the count if subtype already exists, else set it to 1
              occuranceData[subtype] = (occuranceData[subtype] || 0) + 1;
            }
          });
          setOccuranceCount(occuranceData);
        }
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  useEffect(() => {
    fetchDefaultRules();
  }, [site?.site_id]);

  useEffect(() => {
    if (default_rules?.rules) {
      setCollectRules(
        default_rules?.rules?.map((rule: any) =>
          rule?.rule_type?.toLocaleLowerCase()
        )
      );
    }
  }, [fetchRules, default_rules]);

  const nextStepsHandler = () => {
    let newStep = steps + 1;
    setSteps(newStep);
  };

  const prevStepsHandler = () => {
    let newStep = steps + -1;
    setSteps(newStep);
  };

  const configZones = [];

  if (default_rules) {
    default_rules?.rules?.forEach((rule: any, index: any) => {
      // Assuming each rule contains an array named "data" that you want to spread into configZones
      if (rule?.data && Array.isArray(rule.data)) {
        configZones.push(...rule.data);
      }
    });
  }

  const handleExpandDefaultRules = () => {
    if (expandDefaultRules === false) {
      setExpandDefaultRules(true);
    } else if (expandDefaultRules === true) {
      setExpandDefaultRules(false);
    }
  };

  const handleExpandedListOfRules = () => {
    if (expandListOfRules === false) {
      setExpandListOfRules(true);
    } else if (expandListOfRules === true) {
      setExpandListOfRules(false);
    }
  };

  // TIME ENTRY DATA
  const [zoneData, setZoneData] = useState<any>([]);
  const [addZoneMsg, setAddZoneMsg] = useState<string>("");

  const [openMsg, setOpenMsg] = React.useState(false);
  const handleOpenMsgModal = () => setOpenMsg(true);
  const handleCloseMsgModal = () => {
    setAddZoneMsg("");
    setOpenMsg(false);
  };

  const addRuleHandler = () => {
    const showValidationError = () => {
      setAddZoneMsg(
        "Please note all fields are required in order to add a default rule."
      );
      setOpenMsg(true);
    };

    // Determine required fields based on zone_configs
    const isClassRequired =
      relaventRuleDataToDelete?.fields?.zone_configs?.some(
        (config: any) => config.type === "class_label"
      );
    const isMotionRequired =
      relaventRuleDataToDelete?.fields?.zone_configs?.some(
        (config: any) => config.type === "object_motion"
      );
    const isThresholdRequired =
      relaventRuleDataToDelete?.fields?.zone_configs?.some(
        (config: any) => config.type === "confidence_threshold"
      );
    const isCountRequired =
      relaventRuleDataToDelete?.fields?.zone_configs?.some(
        (config: any) => config.type === "count"
      );
    const isCountLogicRequired =
      relaventRuleDataToDelete?.fields?.zone_configs?.some(
        (config: any) => config.type === "count_logic"
      );
    const isIntervalRequired =
      relaventRuleDataToDelete?.fields?.cadence_secs?.enabled;

    // Validate required fields
    if (
      (isClassRequired && !selectedClass) ||
      (isMotionRequired && !selectedMotion) ||
      (isThresholdRequired && selectedThreshold === null) ||
      (isCountRequired && selectedCount === null) ||
      (isCountLogicRequired && !selectedCountLogic) ||
      (isIntervalRequired && !selectedInterValEdit)
    ) {
      showValidationError();
      return;
    }

    let data = zoneData;
    const indexToUpdate = data?.findIndex(
      (item: any) => item?.class_label === selectedClass
    );

    const newZone = {
      random_id: new Date().getTime(),
      zone: "full_image",
      class_label: selectedClass,
      object_motion: selectedMotion,
      count: selectedCount,
      count_logic: selectedCountLogic,
      confidence_threshold: selectedThreshold,
      cadence_secs: isIntervalRequired ? selectedInterValEdit : null,
    };

    if (indexToUpdate === -1) {
      setZoneData([...zoneData, newZone]);
      setSelectedClass("");
      setSelectedMotion("");
      setSelectedThreshold(35);
      // setSelectedInterVal(null);
    } else {
      setAddZoneMsg(
        `Class label ${
          selectedClass?.split(".")[1]
        } has already been selected! Please select another class.`
      );
      setOpenMsg(true);
    }
  };

  const addRuleHandlerConfig = () => {
    const showValidationError = () => {
      setAddZoneMsg(
        "Please note all fields are required in order to add a default rule."
      );
      setOpenMsg(true);
    };

    // Determine required fields based on zone_configs
    const isClassRequired = relaventRuleData?.fields?.zone_configs?.some(
      (config: any) => config.type === "class_label"
    );
    const isMotionRequired = relaventRuleData?.fields?.zone_configs?.some(
      (config: any) => config.type === "object_motion"
    );
    const isThresholdRequired = relaventRuleData?.fields?.zone_configs?.some(
      (config: any) => config.type === "confidence_threshold"
    );
    const isCountRequired = relaventRuleData?.fields?.zone_configs?.some(
      (config: any) => config.type === "count"
    );
    const isCountLogicRequired = relaventRuleData?.fields?.zone_configs?.some(
      (config: any) => config.type === "count_logic"
    );
    const isIntervalRequired = relaventRuleData?.fields?.cadence_secs?.enabled;

    // Validate required fields
    if (
      (isClassRequired && !selectedClass) ||
      (isMotionRequired && !selectedMotion) ||
      (isThresholdRequired && selectedThreshold === null) ||
      (isCountRequired && selectedCount === null) ||
      (isCountLogicRequired && !selectedCountLogic) ||
      (isIntervalRequired && !selectedInterVal)
    ) {
      showValidationError();
      return;
    }

    let data = zoneData;
    const indexToUpdate = data?.findIndex(
      (item: any) => item?.class_label === selectedClass
    );

    const newZone = {
      random_id: new Date().getTime(),
      zone: "full_image",
      class_label: selectedClass,
      object_motion: selectedMotion,
      count: selectedCount,
      count_logic: selectedCountLogic,
      confidence_threshold: selectedThreshold,
      cadence_secs: isIntervalRequired ? selectedInterVal : null,
    };

    if (indexToUpdate === -1) {
      setZoneData([...zoneData, newZone]);
      setSelectedClass("");
      setSelectedMotion("");
      setSelectedThreshold(35);
      // setSelectedInterVal(null);
    } else {
      setAddZoneMsg(
        `Class label ${
          selectedClass?.split(".")[1]
        } has already been selected! Please select another class.`
      );
      setOpenMsg(true);
    }
  };

  const removeRuleHandler = (id: any) => {
    const dataArray = zoneData;
    const filteredArr = dataArray?.filter(
      (data: any, index: any) => data?.random_id !== id
    );
    setZoneData(filteredArr);
  };

  const [ruleTypeToDelete, setRuleTypeToDelete] = useState<string>("");
  const [ruleSubType, setRuleSubType] = useState<string>("");
  const [ruleIDToDelete, setRuleIDToDelete] = useState<string>("");
  const [ruleNameToDelete, setRuleNameToDelete] = useState<string>("");
  const [alertTypeToDelete, setAlertTypeToDelete] = useState<string>("");

  const editRuleNameHandler = (event: any) => {
    setRuleNameToDelete(event?.target?.value);
  };

  const deleteDefaultRuleHandler = async () => {
    setLoadingSpinner(true);
    try {
      const token = await appStore?.authService?.getAuthorisedToken();
      if (!token) {
        setLoadingSpinner(false);
        return;
      }

      // Filter out the rule with the specified rule_type, alert_type, and ruleID
      const updatedRules = default_rules?.rules?.filter(
        (rule: any) =>
          !(
            rule?.rule_type === ruleTypeToDelete &&
            rule?.alert_type === alertTypeToDelete &&
            rule?.id === ruleIDToDelete &&
            rule?.rule_subtype === ruleSubTypeToDelete &&
            rule?.rule_name === ruleNameToDelete
          )
      );

      // Remove the 'id' key from each rule
      const rulesWithoutId = updatedRules?.map(({ id, ...rule }: any) => rule);

      const updatedDefaultRule = {
        rules_origin: {
          ...default_rules?.rules_origin,
        },
        rules: rulesWithoutId, // Keep rules as an array, even if empty
      };

      const update = await appStore?.functionService?.updateSiteDefaultRules(
        token,
        site?.site_id,
        updatedDefaultRule
      );

      if (update) {
        setUpdateResponse(update);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoadingSpinner(false);
      setRuleTypeToDelete("");
      setRuleSubTypeToDelete("");
      setAlertTypeToDelete("");
      fetchDefaultRules();
      setRuleIDToDelete("");
    }
  };

  // DELETE DEFAULT RULE MODAL
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // ADD RULE MODAL
  const [openAddRule, setOpenAddRule] = React.useState(false);

  const handleOpenAddRule = (event: any) => {
    event.preventDefault();
    if (default_rules?.rules?.length !== 0) {
      setAddZoneMsg(
        default_rules?.rules?.length > 1
          ? "Please note that default rules already exist, please edit rules."
          : "Please note that default rule already exists, please edit rule."
      );
      setOpenMsg(true);
      return;
    } else {
      setOpenAddRule(true);
    }
  };

  const editRuleHandler = (ruleType: any) => {
    setOpenAddRule(true);
  };

  const handleCloseAddRule = () => {
    setOpenAddRule(false);
    setTimeEntrySetRules(undefined);
    setSelectedClass("");
    setRuleName("");
    setSelectedInterVal(null);
    setSelectedMotion("");
    setRuleSubType("");
    setSelectedThreshold(35);
    setZoneData([]);
    setSteps(1);
    scheduleRules = [];
    setRadioValue("always");
  };

  // ADD RULE OPTIONS:
  const classOptions = ["people", "vehicles"];
  const objectMotion = ["include all", "moving only", "stationary only"];

  // State for selected values of each section
  const [selectedClass, setSelectedClass] = useState<string | null>(null);
  const [selectedMotion, setSelectedMotion] = useState<string | null>(null);
  const [selectedThreshold, setSelectedThreshold] = useState<number>(35);
  const [selectedCount, setSelectedCount] = useState(0);

  const defaultCountLogic =
    relaventRuleData?.fields?.zone_configs?.find(
      (config: any) => config.type === "count_logic"
    )?.default || ">";
  const [selectedCountLogic, setSelectedCountLogic] =
    useState("> (greater than)");

  const handleClassChange = (event: any) => {
    setSelectedClass(event?.target?.value);
    if (event?.target?.value === "human1.people") {
      setSelectedMotion("include_all");
    } else if (event?.target?.value === "human1.vehicles") {
      setSelectedMotion("moving_only");
    }
  };

  const handleObjectMotion = (event: any) => {
    setSelectedMotion(event?.target?.value);
  };

  const handleCountChange = (event: any) => {
    const newCount = event.target.value;
    setSelectedCount(newCount);
  };

  const handleCountLogicChange = (event: any) => {
    const newLogic = event.target.value;
    setSelectedCountLogic(newLogic);
  };

  const handleThreshold = (event: any) => {
    if (event?.target?.value < 10) {
      setSelectedThreshold(10);
    } else if (event?.target?.value > 100) setSelectedThreshold(100);
    else {
      setSelectedThreshold(event?.target?.value);
    }
  };

  // FETCH THE DEFAULT RULES
  useEffect(() => {
    fetchDefaultRules();
  }, [site?.site_id, parentReload]);

  useEffect(() => {
    const listRulesOFSite = async () => {
      try {
        const token = await appStore?.authService?.getAuthorisedToken();
        if (!token) {
          return;
        } else if (token) {
          const rules_list = await appStore?.functionService?.listRulesForSite(
            token,
            site?.site_id
          );
          if (rules_list && rules_list?.success) {
            setListRules(rules_list?.rules);
          }
        }
      } catch (err) {
        console.error("Error:", err);
      }
    };
    listRulesOFSite();
  }, [site?.site_name]);

  //UPDATE THE DEFAULT RULES
  const [infoToDelete, setInfoToDelete] = useState<any>("");

  const [updateResponse, setUpdateResponse] = useState<any>("");
  const [updateRulesMsg, setUpdateRulesMsg] = useState<any>("");

  const classes = useStyles();

  const [timeentrySetRules, setTimeEntrySetRules] = React.useState<
    TimeEntrySet | undefined
  >(undefined);

  const [noEndtimeRules, setNoEndtimeRules] = React.useState(false);

  const changeTimeEntryRules = (entrySet: TimeEntrySet | undefined) => {
    setTimeEntrySetRules(entrySet);
    //if deleted las one, make sure to add add button
    if (entrySet?.entries.length === 0) {
      setRefresh(new Date().getTime());
    }
  };

  const addSchedule = () => {
    if (timeentrySet) {
      timeentrySet.addTimeEntry();
      setTimeEntrySetRules(timeentrySet);
    } else {
      const newtimeentrySet = new TimeEntrySet("");
      newtimeentrySet.addTimeEntry();
      setTimeEntrySetRules(newtimeentrySet);
    }
    setRefresh(new Date().getTime());
  };

  const cloneDefaultRulesHandler = async () => {
    // Deep copy of default_rules to ensure no references to nested objects
    const updatedDefaultRules = JSON.parse(JSON.stringify(default_rules));

    // Remove the 'id' field from each rule
    if (updatedDefaultRules.rules) {
      updatedDefaultRules.rules = updatedDefaultRules.rules.map(
        ({ id, ...rest }: any) => rest
      );
    }

    try {
      const token = await appStore?.authService?.getAuthorisedToken();
      if (!token) {
        return;
      }

      const update = await appStore?.functionService?.updateSiteDefaultRules(
        token,
        site?.site_id,
        updatedDefaultRules
      );

      if (update) {
        fetchDefaultRules();
        // Optionally provide feedback to the user
        // setUpdateRulesMsg(update?.msg);
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      // Trigger a re-fetch or state update
      fetchDefaultRules();
    }
  };

  const saveUpdateSiteDefaultRulesHandler = async () => {
    const createNewZoneData = (): ZoneConfig[] => {
      if (!zoneData) return [];
      return zoneData.map((zone: ZoneConfig) => {
        const newZoneConfig: ZoneConfig = {
          zone: "full_image",
        };
        relaventRuleDataToDelete?.fields?.zone_configs.forEach(
          (config: any) => {
            switch (config.type) {
              case "class_label":
                newZoneConfig.class_label = zone.class_label;
                break;
              case "object_motion":
                newZoneConfig.object_motion = zone.object_motion;
                break;
              case "confidence_threshold":
                newZoneConfig.confidence_threshold = Number(
                  zone.confidence_threshold
                );
                break;
              case "count":
                newZoneConfig.count = Number(zone.count) || 0;
                break;
              case "count_logic":
                const logicMap: { [key: string]: string } = {
                  "< (less than)": "<",
                  "> (greater than)": ">",
                  ">= (greater than or equal to)": ">=",
                  "<= (less than or equal to)": "<=",
                  "== (equal to)": "==",
                };
                const countLogic = zone?.count_logic;
                newZoneConfig.count_logic =
                  countLogic && logicMap[countLogic]
                    ? logicMap[countLogic]
                    : ">"; // Default to ">" if count_logic is not set
                break;
              default:
                break;
            }
          }
        );

        return newZoneConfig;
      });
    };

    const createValidDaysAndTimes = (): string => {
      if (!timeentrySetRules) return "[]";
      return `[${timeentrySetRules.entries
        .map((entry) => entry.timeString)
        .join(",")}]`;
    };

    const updateRule = (rule: any): Rule => {
      const updatedRule: Rule = {
        ...rule,
        rule_name: ruleNameToDelete,
        rule_type:
          relaventRuleDataToDelete?.fields?.rule_type || "default_rule_type",
        rule_subtype:
          relaventRuleDataToDelete?.fields?.rule_subtype?.subtype ||
          "default_subtype",
        alert_type:
          relaventRuleDataToDelete?.fields?.alert_type || "default_alert_type",
        valid_days_and_times: timeentrySetRules
          ? createValidDaysAndTimes()
          : null,
      };

      if (relaventRuleDataToDelete?.fields?.zone_configs?.length) {
        updatedRule.zone_configs = createNewZoneData();
      }

      // Implementing the cadence_secs logic based on your requirements
      if (relaventRuleDataToDelete?.fields?.cadence_secs) {
        if (relaventRuleDataToDelete.fields.cadence_secs.enabled) {
          if (selectedInterValEdit) {
            updatedRule.cadence_secs = parseInt(selectedInterValEdit);
          } else {
            throw new Error("cadence_secs is required but not provided.");
          }
        } else {
          updatedRule.cadence_secs = null; // Explicitly set to null if not enabled
        }
      } else {
        // Delete cadence_secs if it is not present in relaventRuleDataToDelete
        const { cadence_secs, ...rest } = updatedRule; // Remove cadence_secs if not required
        return rest;
      }

      return updatedRule;
    };

    // Map through the rules and update only the matching rule
    const updatedRules = default_rules?.rules?.map((rule: any) => {
      if (rule.id === ruleIDToDelete) {
        return updateRule(rule); // Update the matching rule
      }
      return rule; // Return unchanged rule
    });

    // Remove 'id' from each rule before sending the data to the backend
    const rulesWithoutId = updatedRules.map(({ id, ...rest }: any) => rest);

    const updatedDefaultRules = {
      ...default_rules,
      rules: rulesWithoutId, // Updated rules without 'id'
    };

    setLoadingSpinner(true);
    try {
      const token = await appStore?.authService?.getAuthorisedToken();
      if (!token) {
        setLoadingSpinner(false);
        return;
      }

      const update = await appStore?.functionService?.updateSiteDefaultRules(
        token,
        site?.site_id,
        updatedDefaultRules
      );

      if (update) {
        setUpdateRulesMsg(update.msg);
        fetchDefaultRules();
      }
    } catch (err) {
      console.error("Error updating site default rules:", err);
    } finally {
      setRadioValue("always");
      setRuleTypeToDelete("");
      setRuleSubTypeToDelete("");
      setAlertTypeToDelete("");
      setRuleIDToDelete("");
      setSelectedInterVal(null);
      fetchDefaultRules();
      setLoadingSpinner(false);
      setRuleName("");
      setZoneData([]);
    }
  };

  const submitSiteDefaultRuleHandler = async () => {
    const createNewZoneData = (): ZoneConfig[] => {
      if (!zoneData) return [];

      return zoneData.map((zone: ZoneConfig) => {
        const newZoneConfig: ZoneConfig = {
          zone: "full_image",
        };

        relaventRuleData?.fields?.zone_configs.forEach((config: any) => {
          switch (config.type) {
            case "class_label":
              newZoneConfig.class_label = zone.class_label;
              break;
            case "object_motion":
              newZoneConfig.object_motion = zone.object_motion;
              break;
            case "confidence_threshold":
              newZoneConfig.confidence_threshold = Number(
                zone.confidence_threshold
              );
              break;
            case "count":
              newZoneConfig.count = Number(zone.count) || 0;
              break;
            case "count_logic":
              // Map the descriptive value to a valid logic symbol
              const logicMap: { [key: string]: string } = {
                "< (less than)": "<",
                "> (greater than)": ">",
                ">= (greater than or equal to)": ">=",
                "<= (less than or equal to)": "<=",
                "== (equal to)": "==",
              };
              const countLogic = zone?.count_logic;
              newZoneConfig.count_logic =
                countLogic && logicMap[countLogic] ? logicMap[countLogic] : ">"; // Default to ">" if count_logic is not set
              break;
            default:
              break;
          }
        });

        return newZoneConfig;
      });
    };

    const createValidDaysAndTimes = (): string => {
      if (!timeentrySetRules) return "[]";
      return `[${timeentrySetRules.entries
        .map((entry) => entry.timeString)
        .join(",")}]`;
    };

    const createNewRule = (): Rule => {
      const newRule: Rule = {
        rule_name: ruleName || relaventRuleData?.fields?.rule_subtype?.label,
        rule_type: relaventRuleData?.fields?.rule_type || "default_rule_type",
        rule_subtype:
          relaventRuleData?.fields?.rule_subtype?.subtype || "default_subtype",
        alert_type:
          relaventRuleData?.fields?.alert_type || "default_alert_type",
        valid_days_and_times: timeentrySetRules
          ? createValidDaysAndTimes()
          : null,
      };

      // Add zone_configs based on the zoneData
      if (relaventRuleData?.fields?.zone_configs?.length) {
        newRule.zone_configs = createNewZoneData();
      }

      // Handle cadence_secs based on its presence and enabled status
      // Implementing the cadence_secs logic based on your requirements
      if (relaventRuleData?.fields?.cadence_secs) {
        if (relaventRuleData.fields.cadence_secs.enabled) {
          if (selectedInterVal) {
            newRule.cadence_secs = parseInt(selectedInterVal);
          } else {
            throw new Error("cadence_secs is required but not provided.");
          }
        } else {
          newRule.cadence_secs = null; // Explicitly set to null if not enabled
        }
      } else {
        // Delete cadence_secs if it is not present in relaventRuleDataToDelete
        const { cadence_secs, ...rest } = newRule; // Remove cadence_secs if not required
        return rest;
      }

      // If cadence_secs is not present, it will not be added to newRule

      return newRule;
    };

    const newRule = createNewRule();
    if (!newRule) return;

    const updatedDefaultRules = {
      ...default_rules,
      rules: [...default_rules?.rules, newRule],
    };

    const rulesWithoutId = updatedDefaultRules.rules.map(
      ({ id, ...rule }: { id: any; rule: Rule }) => rule
    );

    const updatedDefaultRulesWithoutId = {
      ...default_rules,
      rules: rulesWithoutId,
    };

    setLoadingSpinner(true);
    try {
      const token = await appStore?.authService?.getAuthorisedToken();
      if (!token) {
        setLoadingSpinner(false);
        return;
      }

      const update = await appStore?.functionService?.updateSiteDefaultRules(
        token,
        site?.site_id,
        updatedDefaultRulesWithoutId
      );

      if (update) {
        setUpdateRulesMsg(update.msg);
        fetchDefaultRules();
      }
    } catch (err) {
      console.error("Error updating rules:", err);
    } finally {
      setRadioValue("always");
      setRuleTypeToDelete("");
      setRuleSubTypeToDelete("");
      setRuleNameToDelete("");
      setAlertTypeToDelete("");
      setRuleIDToDelete("");
      setSelectedInterVal(null);
      fetchDefaultRules();
      setLoadingSpinner(false);
      setRuleName("");
      setZoneData([]);
    }
  };

  var scene_valid_days_and_times = "[";
  if (timeentrySetRules) {
    timeentrySetRules.entries.forEach((timeEntry, index) => {
      scene_valid_days_and_times +=
        (index > 0 ? "," : "") + timeEntry.timeString;
    });
  }
  scene_valid_days_and_times += "]";

  var scheduleRules: React.ReactNode[] = [];
  if (timeentrySetRules && timeentrySetRules.entries.length > 0) {
    scheduleRules.push(
      // <TextField
      //   // value={currentAlertChannel.timezone_str}
      //   fullWidth={true}
      //   disabled={true}
      //   variant={"outlined"}
      //   label={"Time Zone"}
      //   margin="normal"
      // ></TextField>,
      <br />
    );
    scheduleRules.push(
      <TimeEntryPanel
        horisontal={false}
        addicon={true}
        disabled={false}
        noEndTime={noEndtimeRules}
        entrySet={timeentrySetRules}
        changeTimeEntryParent={(entrySet: TimeEntrySet | undefined) =>
          changeTimeEntryRules(entrySet)
        }
      ></TimeEntryPanel>
    );
  } else {
    scheduleRules.push(
      <div className={classes.form_component}>
        <div className={classes.bold_text}>
          Add Schedule
          <IconButton
            edge="end"
            aria-label="add"
            onClick={() => {
              addSchedule();
            }}
          >
            <AddCircle color={"primary"} />
          </IconButton>
        </div>
      </div>
    );
  }

  // const classes = useStyles();
  const [configs, setConfigs] = useState<AlertConfig[] | undefined>(undefined);
  const [alertendpoints, setAlertEndPoints] = useState<
    AlertEndPoint[] | undefined
  >(undefined);
  const [endpointdefs, setEndPointDefs] = useState<
    AlertEndPointDef[] | undefined
  >(undefined);
  const [telegramDefinition, setTelegramDefinition] = useState<
    AlertEndPointDef | undefined
  >(undefined);
  const [activeIndex, setActiveIndex] = React.useState(-1);
  const [classifier, setClassifier] = useState<Classifier | undefined>(
    undefined
  );
  const [ruleopen, setOpenAddRuleModal] = React.useState(false);
  const [popmessage, setPopMessage] = useState("");
  const [endpointopen, setEndpointModalOpen] = React.useState(false);
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [allSensors, setAllSensors] = useState<Sensor[]>([]);
  const [deleteuserid, setDeleteUserId] = React.useState(0);
  const [deleteuserendpointid, setDeleteUserEndpointId] = React.useState(0);
  const [confirmUserOpen, setConfirmUserOpen] = React.useState(false);
  const [deleteruleid, setDeleteRuleId] = React.useState(0);
  const [confirmRuleOpen, setConfirmRuleOpen] = React.useState(false);
  const [confirmRulesOpen, setConfirmRulesOpen] = React.useState(false);
  const [confirmEndPointOpen, setConfirmEndPointOpen] = React.useState(false);
  const [deleteendpointid, setDeleteEndPointId] = React.useState(0);
  const [confirmmessage, setConfirmMessage] = React.useState("");
  const [refresh, setRefresh] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [openeditrule, setOpenEditRule] = React.useState(false);
  const [editingconfig, setEditingConfig] = React.useState<
    AlertConfig | undefined
  >(undefined);
  const [confirmAlertChannelOpen, setConfirmAlertChannelOpen] =
    React.useState(false);
  const [opendeliverymethod, setOpenDeliveryMethod] = React.useState(false);
  const [openAddToTele, setOpenAddToTele] = React.useState(false);
  const [openEditHikCentral, setOpenEditHikCentral] = React.useState(false);
  const [editingdeliverymethod, setEditingDeliveryMethod] = React.useState<
    AlertEndPoint | undefined
  >(undefined);
  //MessageModal
  const [messagemodalopen, setMessageModalOpen] = React.useState(false);
  const [openeditrules, setOpenEditRules] = React.useState(false);
  const [selectedrules, setSelectedRules] = React.useState<number[]>([]);
  const [rulesexpanded, setRulesExpanded] = React.useState(false);
  const [methodsexpanded, setMethodsExpanded] = React.useState(false);

  const [alertchannelname, setAlertChannelName] = React.useState<string>("");
  const [threshold, setThreshold] = React.useState<number>(0);
  const [timeZone, setTimeZone] = React.useState<string>("");
  // const [dynDnsUsername, setDynDnsUsername] = React.useState<string>("");
  // const [dynDnsPassword, setDynDnsPassword] = React.useState<string>("");
  const [returnNonDetectionVideos, setReturnNonDetectionVideos] =
    useState(false);
  // const [ignoreStationaryVehicles, setIgnoreStationaryVehicles] =
  //   useState(true);
  const [dummyNumber, setDummyNumber] = React.useState(0);
  // boolean that idicates if alertchannels are expanded
  const [alertchannelexpanded, setAlertChannelExpanded] = useState(false);
  // boolean that idicates if general settings are expanded
  const [generalsettingsexpanded, setGeneralSettingsExpanded] = useState(false);
  const [deletealertchannelid, setDeleteAlertChannelId] = React.useState(0);
  const [message, setMessage] = React.useState<string>("");
  const [disconnectOpen, setDisconnectOpen] = React.useState(false);
  const [alertchannelEdit, setAlertChannelEdit] = React.useState<
    AlertEndPoint | undefined
  >(undefined);

  const [noEndtime, setNoEndtime] = React.useState(false);
  const [timeentrySet, setTimeEntrySet] = React.useState<
    TimeEntrySet | undefined
  >(undefined);

  const changeTimeEntry = (entrySet: TimeEntrySet | undefined) => {
    setTimeEntrySet(entrySet);
    //if deleted las one, make sure to add add button
    if (entrySet?.entries.length === 0) {
      setRefresh(new Date().getTime());
    }
  };

  const forceRefresh = () => {
    setRefresh(new Date().getTime());
  };

  useEffect(() => {
    if (alertendpoints && alertendpoints.length > 0) {
      if (
        alertendpoints[0].alert_channel_id !== alertchannel.alert_channel_id
      ) {
        setAlertEndPoints(undefined);
        fetchAlertEndpointsData(true);
      }
    }
  }, [alertendpoints, alertchannel]);

  useEffect(() => {
    const fetchData = async () => {
      if (alertchannel) {
        if (alertchannel.default_confidence_threshold) {
          setThreshold(alertchannel.default_confidence_threshold);
        }
        if (
          alertchannel.meta &&
          alertchannel.meta["return_non_detection_videos"] !== "undefined"
        ) {
          setReturnNonDetectionVideos(
            alertchannel.meta.return_non_detection_videos
          );
        }
        // if (
        //   alertchannel.meta &&
        //   alertchannel.meta.additional_inference_options &&
        //   alertchannel.meta.additional_inference_options[
        //     "ignore_stationary_vehicles"
        //   ] !== "undefined"
        // ) {
        //   setIgnoreStationaryVehicles(
        //     alertchannel.meta.additional_inference_options
        //       .ignore_stationary_vehicles
        //   );
        // } else {
        //   setIgnoreStationaryVehicles(false);
        // }

        if (alertchannel.name) {
          setAlertChannelName(alertchannel.name);
        }

        if (alertchannel.timezone_str) {
          setTimeZone(alertchannel.timezone_str);
        }
      }
    };
    fetchData();
  }, [alertchannel]);

  const openMessageModal = () => {
    setMessageModalOpen(true);
  };
  const setMessageModal = (message: string) => {
    setPopMessage(message);
  };
  const dummy = () => {};
  const handleDisconnectClose = () => {
    setDisconnectOpen(false);
  };
  // const handleUpdateDisconnections = (alertchannelInput: AlertEndPoint) => {
  //   setDisconnectOpen(false);
  //   HandleUpdateDisconnectNotifications(alertchannelInput);
  // };
  const handleDisconnectPopUp = (x: AlertEndPoint) => {
    setAlertChannelEdit(x);
    setDisconnectOpen(true);
  };

  function wait(ms: number) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }
  const refreshLoading = () => {
    setRefresh(new Date().getTime());
    handleMethodsExpanded();
    setPopMessage("");
    openMessageModal();
    setAlertEndPoints(undefined);
    setRefresh(new Date().getTime());
    setMessageModalOpen(false);
    // openMessageModal();

    // setLoading(true);
    // wait(3000);
    // setLoading(false);
  };
  const HandleUpdateDisconnectNotifications = async (
    alertchannelInput: AlertEndPoint
  ) => {
    let meta = alertchannelInput.meta;
    setLoading(true);
    if (typeof meta["send_disconnect_notifications"] != "undefined") {
      if (meta["send_disconnect_notifications"] == true) {
        meta["send_disconnect_notifications"] = false;
      } else {
        meta["send_disconnect_notifications"] = true;
      }
    } else {
      meta["send_disconnect_notifications"] = false;
    }

    var token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      const updateDisconnect =
        await appStore?.functionService.modifyAlertEndPoint(
          token,
          alertchannelInput.alert_channel_end_point_id,
          meta
        );
      if (updateDisconnect) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const closeMessageModal = () => {
    setPopMessage("");
    setMessageModalOpen(false);
  };
  const openEndpointModal = () => {
    setEndpointModalOpen(true);
  };
  const closeAddEndpointModal = () => {
    setEndpointModalOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (endpointdefs == undefined) {
        var token = await appStore?.authService.getAuthorisedToken();
        if (token) {
          var endpoint_defs =
            await appStore?.functionService.getAlertEndPointDefs(token);
          setEndPointDefs(endpoint_defs);
        }
      }
    };
    fetchData();
  }, [alertchannel, refresh]);

  useEffect(() => {
    const fetchData = async () => {
      if (configs === undefined) {
        setLoading(true);
        var token = await appStore?.authService.getAuthorisedToken();
        if (token) {
          var config: AlertConfig[] =
            await appStore?.functionService.getConfigForAlertChannel(
              token,
              alertchannel.alert_channel_id
            );
          if (config && config.length > 0) {
            config.forEach((conf) => {
              let valid_days_and_times = conf.valid_days_and_times;
              if (valid_days_and_times != null) {
              }
            });
          }

          setConfigs(config);
        }
      }
    };
    fetchData();
  }, [alertchannel, refresh]);

  useEffect(() => {
    const fetchData = async () => {
      if (classifier === undefined) {
        var token = await appStore?.authService.getAuthorisedToken();
        if (token) {
          appStore?.authService.getTranslations(token, alertchannel.locale);
          var classifier = await appStore?.functionService.getClassifierForSite(
            token,
            alertchannel.site_id
          );
          setClassifier(classifier);
        }
      }
    };
    fetchData();
  }, [alertchannel, refresh]);

  async function fetchAlertEndpointsData(override = false) {
    if (alertendpoints === undefined || override) {
      setLoading(true);
      var token = await appStore?.authService.getAuthorisedToken();
      if (token) {
        var endpoints = await appStore?.functionService.getAlertEndPoints(
          token,
          alertchannel.alert_channel_id
        );
        if (token && endpoints) {
          let telegram_def = endpoints.tel_def;
          endpoints = endpoints.ret;
          for (let j = 0; j < endpoints.length; j++) {
            const epusers = await appStore?.functionService.getUsersForEndPoint(
              token!,
              endpoints[j].alert_channel_end_point_id
            );

            if (epusers) {
              const translate = appStore?.functionService.translate(
                alertchannel.locale,
                "endpoint_names",
                endpoints[j].end_point_type
              );
              for (let i = 0; i < epusers?.length; i++) {
                epusers[i].orgname = translate?.toString()!;
                epusers[i].end_point_id =
                  endpoints[j].alert_channel_end_point_id;
                if (endpoints[j].users === undefined) {
                  endpoints[j].users = [];
                }
                endpoints[j].users.push(epusers[i]);
              }
            }
          }
          setAlertEndPoints((pre) => endpoints);
          setTelegramDefinition((pre) => telegram_def);
          setLoading(false);
        }
      }
    }
  }
  useEffect(() => {
    fetchAlertEndpointsData();
  }, [alertchannel, refresh]);

  useEffect(() => {
    const fetchData = async () => {
      if (site) {
        var token = await appStore?.authService.getAuthorisedToken();
        if (token) {
          var allSensorsList =
            await appStore?.functionService.getSensorsForSite(
              token,
              site.site_id,
              "none"
            );
          let sensor_ids: number[] = [];
          if (allSensorsList) {
            for (var entry of allSensorsList) {
              sensor_ids.push(entry["sensor_id"]);
            }
            setSensors(allSensorsList);
          }
          if (allSensorsList) {
            var filteredSensors = [];
            var count = 0;
            for (let i in allSensorsList) {
              if (
                allSensorsList[count].enabled &&
                !sensor_ids?.includes(allSensorsList[count].sensor_id)
              ) {
                filteredSensors.push(allSensorsList[count]);
              }
              count++;
            }
            setAllSensors(filteredSensors);
          }
        }
      }
    };
    fetchData();
  }, [popmessage, site, refresh]);

  const openAddRuleModal = () => {
    setOpenAddRuleModal(true);
  };

  const closeAddRuleModal = () => {
    setOpenAddRuleModal(false);
  };

  async function saveNewEndpoint(
    alert_channel_id: number,
    end_point_type: string,
    name: string,
    meta: { [key: string]: any },
    userendpointroles: UserEndPointRoles[],
    message: string
  ) {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      setRefresh(new Date().getTime());
      let new_end_point = await appStore?.functionService.createAlertEndPoint(
        token,
        alert_channel_id,
        end_point_type,
        name,
        meta
      );
      if (new_end_point) {
        if (new_end_point.success) {
          if (userendpointroles) {
            userendpointroles.forEach(async (userendpointrole) => {
              if (userendpointrole.user_id) {
                var end_point_role_names: string = "[";
                for (let key in userendpointrole.web_roles) {
                  let roleselected = userendpointrole.web_roles[key];
                  if (roleselected) {
                    const comma = end_point_role_names === "[" ? "" : ",";
                    end_point_role_names = end_point_role_names + comma;
                    end_point_role_names =
                      end_point_role_names + "'" + key + "'";
                  }
                }
                end_point_role_names = end_point_role_names + "]";
                let new_user_alert_channel =
                  await appStore?.functionService.createUserAlertChannelEndPoint(
                    token,
                    new_end_point!.alert_channel_end_point_id,
                    userendpointrole.user_id,
                    end_point_role_names
                  );
                if (!new_user_alert_channel?.success) {
                  setPopMessage(new_user_alert_channel?.msg!);
                  openMessageModal();
                }
              }
            });
          }
          setPopMessage(message + " Delivery Method");
          openMessageModal();
          setAlertEndPoints(undefined);
          setRefresh(new Date().getTime());
        } else {
          setPopMessage(new_end_point.msg);
          openMessageModal();
          setLoading(false);
          setRefresh(new Date().getTime());
        }
      }
    }
  }

  const LoadingParent = () => {
    setRefresh(new Date().getTime());
    handleMethodsExpanded();
    setPopMessage("Please go to the Alerting section to finish allocations");
    openMessageModal();
    setAlertEndPoints(undefined);
    setRefresh(new Date().getTime());
    openMessageModal();
  };
  async function saveRevisedNewEndpoint(
    end_point_type: string,
    alertchannel: AlertChannel
  ) {
    const token = await appStore?.authService.getAuthorisedToken();
    const unique =
      appStore?.functionService.translate(
        alertchannel.locale,
        "endpoint_names",
        end_point_type
      ) + new Date().getTime().toString();
    if (token) {
      setLoading(true);
      const postNewEndpoint =
        await appStore?.functionService.createAlertEndPoint(
          token,
          alertchannel.alert_channel_id,
          end_point_type,
          unique,
          {}
        );
      if (postNewEndpoint) {
        if (postNewEndpoint.success) {
          setPopMessage("New web endpoint successfully saved");
          openMessageModal();
          setAlertEndPoints(undefined);
          setRefresh(new Date().getTime());
          openMessageModal();
        } else {
          setPopMessage(postNewEndpoint.msg);
          openMessageModal();
          setLoading(false);
          setRefresh(new Date().getTime());
        }
      }
    }
  }

  async function updateEndpoint(
    alert_channel_end_point_id: number,
    meta: { [key: string]: any }
  ) {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      setRefresh(new Date().getTime());
      let new_end_point = await appStore?.functionService.modifyAlertEndPoint(
        token,
        alert_channel_end_point_id,
        meta
      );
      if (new_end_point) {
        if (new_end_point.success) {
          setPopMessage("updated successfully");
          openMessageModal();
          setAlertEndPoints(undefined);
          setRefresh(new Date().getTime());
        } else {
          setPopMessage(new_end_point.msg);
          openMessageModal();
          setLoading(false);
          setRefresh(new Date().getTime());
        }
      }
    }
  }
  async function saveNewRule(
    alert_channel_id: number,
    class_labels: string,
    sensor_ids: string,
    valid_days_and_times: string,
    snapshot?: boolean
  ) {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      closeAddRuleModal();
      setLoading(true);
      setRefresh(new Date().getTime());
      if (valid_days_and_times === "[]") {
        let new_alert_config =
          await appStore?.functionService.createAlertConfigNoSchedule(
            token,
            alert_channel_id,
            class_labels,
            sensor_ids
          );
        if (new_alert_config) {
          if (new_alert_config.success) {
            setPopMessage(
              "Created new rule for alert ruleset " + alertchannel.name
            );
            openMessageModal();
            setConfigs(undefined);
            setAlertEndPoints(undefined);
            setRefresh(new Date().getTime());
          } else {
            setPopMessage(new_alert_config.msg);
            openMessageModal();
            setLoading(false);
            setRefresh(new Date().getTime());
          }
        }
      } else {
        let new_alert_config =
          await appStore?.functionService.createAlertConfig(
            token,
            alert_channel_id,
            class_labels,
            sensor_ids,
            valid_days_and_times,
            snapshot
          );
        if (new_alert_config) {
          if (new_alert_config.success) {
            setPopMessage(
              "Created new rule for alert ruleset " + alertchannel.name
            );
            openMessageModal();
            setConfigs(undefined);
            setAlertEndPoints(undefined);
            setRefresh(new Date().getTime());
          } else {
            setPopMessage(new_alert_config.msg);
            openMessageModal();
            setLoading(false);
            setRefresh(new Date().getTime());
          }
        }
      }
    }
  }

  async function updateRule(
    alert_channel_id: number,
    alert_config_id: number,
    valid_days_and_times: string
  ) {
    const token = await appStore?.authService.getAuthorisedToken();
    const alert_config_ids = "[" + alert_config_id + "]";
    if (token) {
      closeAddRuleModal();
      setLoading(true);
      setRefresh(new Date().getTime());
      if (valid_days_and_times === "[]") {
        let new_alert_config = await appStore?.functionService.modifyAlertRule(
          token,
          alert_channel_id,
          alert_config_ids,
          "ALL"
        );
        if (new_alert_config) {
          if (new_alert_config.success) {
            setPopMessage(
              "Updated rule for alert ruleset " + alertchannel.name
            );
            openMessageModal();
            setConfigs(undefined);
            setAlertEndPoints(undefined);
            setRefresh(new Date().getTime());
          } else {
            setPopMessage(new_alert_config.msg);
            openMessageModal();
            setLoading(false);
            setRefresh(new Date().getTime());
          }
        }
      } else {
        let new_alert_config = await appStore?.functionService.modifyAlertRule(
          token,
          alert_channel_id,
          alert_config_ids,
          valid_days_and_times
        );
        if (new_alert_config) {
          if (new_alert_config.success) {
            setPopMessage(
              "Updated rule for alert ruleset " + alertchannel.name
            );
            openMessageModal();
            setConfigs(undefined);
            setAlertEndPoints(undefined);
            setRefresh(new Date().getTime());
          } else {
            setPopMessage(new_alert_config.msg);
            openMessageModal();
            setLoading(false);
            setRefresh(new Date().getTime());
          }
        }
      }
    }
  }

  async function updateRules(
    alert_channel_id: number,
    valid_days_and_times: string
  ) {
    const token = await appStore?.authService.getAuthorisedToken();
    let alert_config_ids = "[";
    selectedrules.forEach((selectedrule) => {
      alert_config_ids = alert_config_ids + selectedrule + ",";
    });
    if (alert_config_ids.length > 1) {
      alert_config_ids = alert_config_ids.substring(
        0,
        alert_config_ids.length - 1
      );
    }
    alert_config_ids = alert_config_ids + "]";
    if (token) {
      closeAddRuleModal();
      setLoading(true);
      setRefresh(new Date().getTime());
      if (valid_days_and_times === "[]") {
        let new_alert_config = await appStore?.functionService.modifyAlertRule(
          token,
          alert_channel_id,
          alert_config_ids,
          "ALL"
        );
        if (new_alert_config) {
          if (new_alert_config.success) {
            setPopMessage(
              "Updated rules for alert ruleset " + alertchannel.name
            );
            openMessageModal();
            setConfigs(undefined);
            setAlertEndPoints(undefined);
            setRefresh(new Date().getTime());
          } else {
            setPopMessage(new_alert_config.msg);
            openMessageModal();
            setLoading(false);
            setRefresh(new Date().getTime());
          }
        }
      } else {
        let new_alert_config = await appStore?.functionService.modifyAlertRule(
          token,
          alert_channel_id,
          alert_config_ids,
          valid_days_and_times
        );
        if (new_alert_config) {
          if (new_alert_config.success) {
            setPopMessage(
              "Updated rules for alert ruleset " + alertchannel.name
            );
            openMessageModal();
            setConfigs(undefined);
            setAlertEndPoints(undefined);
            setRefresh(new Date().getTime());
          } else {
            setPopMessage(new_alert_config.msg);
            openMessageModal();
            setLoading(false);
            setRefresh(new Date().getTime());
          }
        }
      }
    }
  }

  const deleteUser = async () => {
    setConfirmUserOpen(false);
    setLoading(true);
    setRefresh(new Date().getTime());
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let delete_user = await appStore?.functionService.deleteAlertUser(
        token,
        deleteuserid,
        deleteuserendpointid
      );
      if (delete_user) {
        if (delete_user.success) {
          setPopMessage("Deleted user");
          openMessageModal();
          setLoading(false);
        } else {
          setPopMessage(delete_user.msg);
          openMessageModal();
          setLoading(false);
        }
      }
    }
  };

  //function to toggle alertchannelexpanded when alertchannel open/close icon clicked
  const handleAlertChannelExpanded = () => {
    setAlertChannelExpanded(!alertchannelexpanded);
  };
  //function to toggle generalsettingsexpanded when generalsettings open/close icon clicked
  const handleGeneralSettingsExpanded = () => {
    setGeneralSettingsExpanded(!generalsettingsexpanded);
  };

  const handleEditRule = (config: AlertConfig) => {
    setEditingConfig(config);
    setOpenEditRule(true);
    if (config.class_label === "snapshot") {
      setNoEndtime(true);
    } else {
      setNoEndtime(false);
    }
  };
  const handleEditRuleClose = () => {
    setOpenEditRule(false);
  };
  const handleSelectAll = () => {
    let newselected: number[] = [];
    if (configs && configs.length > 0) {
      configs.forEach((config) => {
        newselected.push(config.alert_config_id);
      });
    }
    setSelectedRules(newselected);
    setRefresh(new Date().getTime());
  };
  const handleUnSelectAll = () => {
    setSelectedRules([]);
    setRefresh(new Date().getTime());
  };

  const handleEditRules = () => {
    setOpenEditRules(true);
  };
  const handleEditRulesClose = () => {
    setOpenEditRules(false);
  };
  const handleAddToTele = (x: AlertEndPoint) => {
    setEditingDeliveryMethod(x);
    setOpenAddToTele(true);
  };
  const handleEditDeliveryMethod = (x: AlertEndPoint) => {
    setEditingDeliveryMethod(x);
    setOpenDeliveryMethod(true);
  };
  const handleEditHikDeliveryMethod = (x: AlertEndPoint) => {
    setEditingDeliveryMethod(x);
    setOpenEditHikCentral(true);
  };
  const closeEditHikDeliveryMethod = () => {
    refreshLoading();
    setOpenEditHikCentral(false);
  };
  const handleEditDeliveryMethodClose = () => {
    setOpenDeliveryMethod(false);
  };
  const handleEnableRule = () => {};
  const openConfirmDeleteRule = (config_id: number, configname: string) => {
    setDeleteRuleId(config_id);
    setConfirmMessage("Are you sure you want to delete " + configname + "?");
    setConfirmRuleOpen(true);
  };
  const openConfirmDeleteRules = () => {
    if (selectedrules.length === 1) {
      setConfirmMessage(
        "Are you sure you want to delete " + selectedrules.length + " rule ?"
      );
    } else {
      setConfirmMessage(
        "Are you sure you want to delete " + selectedrules.length + " rules ?"
      );
    }

    setConfirmRulesOpen(true);
  };
  const deleteRule = async () => {
    setConfirmRuleOpen(false);
    setLoading(true);
    setRefresh(new Date().getTime());

    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let delete_rule = await appStore?.functionService.deleteRules(
        token,
        [deleteruleid],
        alertchannel.alert_channel_id
      );
      if (delete_rule) {
        if (delete_rule.success) {
          setPopMessage("Deleted rule");
          openMessageModal();
          setConfigs(undefined);
          setAlertEndPoints(undefined);
          let idx = selectedrules.indexOf(deleteruleid);
          if (idx >= 0) {
            selectedrules.splice(idx, 1);
          }
          setSelectedRules(selectedrules);
          setRefresh(new Date().getTime());
        } else {
          setPopMessage(delete_rule.msg);
          openMessageModal();
          setLoading(false);
          setRefresh(new Date().getTime());
        }
      }
    }
  };
  const deleteRules = async () => {
    setConfirmRuleOpen(false);
    setLoading(true);
    setRefresh(new Date().getTime());

    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let delete_rule = await appStore?.functionService.deleteRules(
        token,
        selectedrules,
        alertchannel.alert_channel_id
      );
      if (delete_rule) {
        if (delete_rule.success) {
          setPopMessage("Deleted " + selectedrules.length + " rules.");
          openMessageModal();
          setSelectedRules([]);
          setConfigs(undefined);
          setAlertEndPoints(undefined);
          setRefresh(new Date().getTime());
        } else {
          setPopMessage(delete_rule.msg);
          openMessageModal();
          setLoading(false);
          setRefresh(new Date().getTime());
        }
      }
    }
  };
  const openConfirmDeleteEndPoint = (
    alert_channel_endpoint_id: number,
    endpointname: string
  ) => {
    setDeleteEndPointId(alert_channel_endpoint_id);
    setConfirmMessage("Are you sure you want to delete " + endpointname + "?");
    setConfirmEndPointOpen(true);
  };
  const deleteEndPoint = async () => {
    setLoading(true);
    setConfirmEndPointOpen(false);
    setRefresh(new Date().getTime());
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let delete_endpoint = await appStore?.functionService.deleteEndPoint(
        token,
        deleteendpointid
      );
      if (delete_endpoint) {
        if (delete_endpoint.success) {
          setPopMessage("Deleted delivery method");
          openMessageModal();
          setAlertEndPoints(undefined);
          setRefresh(new Date().getTime());
        } else {
          setPopMessage(delete_endpoint.msg);
          openMessageModal();
          setLoading(false);
          setRefresh(new Date().getTime());
        }
      }
    }
  };
  const handleSelectedRuleChange = (alert_config_id: number) => {
    let idx = selectedrules.indexOf(alert_config_id);
    if (idx >= 0) {
      selectedrules.splice(idx, 1);
    } else {
      selectedrules.push(alert_config_id);
    }
    setSelectedRules(selectedrules);
    setRefresh(new Date().getTime());
  };
  //function to toggle rulesexpanded when rules open/close icon clicked
  const handleRulesExpanded = () => {
    setRulesExpanded(!rulesexpanded);
  };
  //function to toggle methodexpanded when method open/close icon clicked
  const handleMethodsExpanded = () => {
    setMethodsExpanded(!methodsexpanded);
  };

  const handleAlertChannelNameChange = (
    event: React.ChangeEvent<any>,
    alert_channel_id: number
  ) => {
    setAlertChannelName(event.target.value);
  };
  const handleThresholdChange = (
    event: React.ChangeEvent<any>,
    alert_channel_id: number
  ) => {
    setThreshold(event.target.value);
  };
  const handleTimeZoneChange = (event: React.ChangeEvent<any>) => {
    setTimeZone(event.target.value);
  };

  const handleUpdateAlertChannel = (alert_channel_id: number) => {
    let meta = alertchannel.meta;
    if (!meta) {
      meta = {};
    }
    if (
      alertchannel.meta.additional_inference_options &&
      alertchannel.meta.additional_inference_options[
        "ignore_stationary_vehicles"
      ] !== "undefined"
    ) {
      // meta.additional_inference_options["ignore_stationary_vehicles"] =
      //   ignoreStationaryVehicles;
    } else if (
      (site?.data_origin === "client_hub_mobcam_human1" ||
        site?.data_origin === "alert_rest_client" ||
        site?.data_origin === "alert_smtp_client" ||
        site?.data_origin === "sentinel_http_edge_human1" ||
        site?.data_origin === "alert_ftp_client") &&
      alertchannel.meta.additional_inference_options
    ) {
      // meta.additional_inference_options["ignore_stationary_vehicles"] =
      //   ignoreStationaryVehicles;
    } else if (
      site?.data_origin === "client_hub_mobcam_human1" ||
      site?.data_origin === "alert_rest_client" ||
      site?.data_origin === "alert_smtp_client" ||
      site?.data_origin === "sentinel_http_edge_human1" ||
      site?.data_origin === "alert_ftp_client"
    ) {
      meta["additional_inference_options"] = {};
      // meta.additional_inference_options["ignore_stationary_vehicles"] =
      //   ignoreStationaryVehicles;
    }

    if (alertchannel.meta["return_non_detection_videos"] !== "undefined") {
      meta["return_non_detection_videos"] = returnNonDetectionVideos;
    }

    updateAlertChannel(
      alert_channel_id,
      alertchannelname,
      threshold,
      meta,
      timeZone
    );
    setRefreshParent();
  };
  const updateAlertChannel = async (
    alert_channel_id: number,
    alert_channel_name: string,
    default_confidence_threshold: number,
    meta: { [key: string]: any },
    alert_time_zone: string
  ) => {
    setLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      if (site?.data_origin?.includes("motioncam")) {
        let update_channel =
          await appStore?.functionService.modifyAlertChannelForMotionCam(
            token,
            alert_channel_id,
            site?.site_id!,
            alert_channel_name,
            default_confidence_threshold,
            meta,
            alert_time_zone
          );
        if (update_channel) {
          setLoading(false);
          if (update_channel.success) {
            setPopMessage("Updated Ruleset");
            openMessageModal();
            setRefresh(new Date().getTime());
          } else {
            setPopMessage(update_channel.msg);
            openMessageModal();
            setRefresh(new Date().getTime());
          }
        }
      } else {
        let update_channel = await appStore?.functionService.modifyAlertChannel(
          token,
          alert_channel_id,
          site?.site_id!,
          alert_channel_name,
          // default_confidence_threshold,
          meta,
          alert_time_zone
        );
        if (update_channel) {
          setLoading(false);
          if (update_channel.success) {
            setPopMessage("Updated Ruleset");
            openMessageModal();
            setRefresh(new Date().getTime());
          } else {
            setPopMessage(update_channel.msg);
            openMessageModal();
            setRefresh(new Date().getTime());
          }
        }
      }
    }
  };
  const openConfirmDeleteAlertChannel = (
    alertchannel_id: number,
    alertchannel_name: string
  ) => {
    setDeleteAlertChannelId(alertchannel_id);
    setConfirmMessage(
      "Are you sure you want to delete " + alertchannel_name + "?"
    );
    setConfirmAlertChannelOpen(true);
  };

  const deleteAlertChannel = async () => {
    setLoading(true);
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      let delete_alertchannel =
        await appStore?.functionService.deleteAlertChannel(
          token,
          deletealertchannelid
        );
      if (delete_alertchannel) {
        setLoading(false);
        if (delete_alertchannel.success) {
          setPopMessage("Deleted alert ruleset");
          openMessageModal();
          reload(Math.floor(Math.random() * 100) + 1);
          setRefresh(new Date().getTime());
        } else {
          setPopMessage(delete_alertchannel.msg);
          openMessageModal();
          setRefresh(new Date().getTime());
        }
      }
    }
  };

  function getRulesDrawer() {
    var rules: React.ReactNode[] = [];
    let index = 0;
    var advanced_cameras: number[] = [];
    var not_setup_sensor_id_strs: string[] = [];
    allSensors.forEach((entry: Sensor) => {
      not_setup_sensor_id_strs.push(entry.sensor_id_str);
    });
    if (rulesexpanded) {
      if (not_setup_sensor_id_strs.length > 0) {
        let sensor_id_strs: string = "Camera";
        let grammar: string = "has";
        if (not_setup_sensor_id_strs.length > 1) {
          sensor_id_strs += "s ";
          grammar = "have";
        } else {
          sensor_id_strs += " ";
        }
        for (var i = 0; i < not_setup_sensor_id_strs.length; i++) {
          sensor_id_strs += not_setup_sensor_id_strs[i];
          if (i === not_setup_sensor_id_strs.length - 2) {
            sensor_id_strs += " & ";
          } else if (i < not_setup_sensor_id_strs.length - 2) {
            sensor_id_strs += ", ";
          }
        }

        rules.push(
          <Card
            raised
            className={classes.expansion_shelf}
            style={{ justifyContent: "center" }}
          >
            {!site?.data_origin?.includes("immix") && (
              <div
                className={classes.bold_text}
                style={{ color: "red", paddingTop: "5px" }}
              >
                WARNING: {sensor_id_strs} {grammar} not been set up with any
                alert rules
              </div>
            )}
            {site?.data_origin?.includes("immix") && (
              <div
                className={classes.bold_text}
                style={{ color: "red", paddingTop: "5px" }}
              >
                WARNING: {sensor_id_strs} {grammar} not been set up to use
                DeepAlert processing rules and will alert on Immix rulesets
              </div>
            )}
          </Card>
        );
      }
    }
    if (configs && configs.length > 0) {
      configs.forEach((config: AlertConfig) => {
        var advanced_rule_display = false;
        var icon: React.ReactNode;
        var subclass: string = "";
        var time: string = "";
        if (config?.advanced === true) {
          var sensor_id_check = -1;
          if (config?.sensor_id != null) {
            sensor_id_check = config.sensor_id;
          }
          if (
            advanced_cameras?.includes(sensor_id_check) ||
            sensor_id_check === -1
          ) {
            return;
          } else {
            advanced_cameras.push(sensor_id_check);
            advanced_rule_display = true;
            subclass =
              "This sensor has been configured to use advanced rules, please set " +
              "parameters through the advanced rules wizard";
            if (site?.data_origin === "immix_ailink_human1") {
              for (let i = 0; i < sensors.length; i++) {
                if (
                  sensors[i]["sensor_id"] === sensor_id_check &&
                  !sensors[i]["advanced_rules_enabled"]
                ) {
                  subclass =
                    "placeholder advanced rule - uses inference request parameters";
                }
              }
            }
          }
        } else {
          if (config?.class_label?.includes("animal.")) {
            icon = <Pets></Pets>;
          } else if (config?.class_label?.includes("people.")) {
            icon = <DirectionsWalk></DirectionsWalk>;
          } else if (config?.class_label?.includes("vehicle.")) {
            icon = <DirectionsCar></DirectionsCar>;
          } else if (config?.class_label?.includes("safety.")) {
            icon = <SecurityIcon></SecurityIcon>;
          } else if (config?.class_label?.includes("analytics")) {
            icon = <MultilineChartIcon></MultilineChartIcon>;
          } else if (config?.class_label?.includes("snapshot")) {
            icon = <CameraAltIcon></CameraAltIcon>;
          } else if (config?.class_label?.includes("weapons")) {
            icon = <SportsIcon></SportsIcon>;
          } else {
            icon = <Help></Help>;
          }
          subclass = appStore?.functionService.translate(
            alertchannel.locale,
            classifier?.name + "_class_labels",
            config.class_label
          )!;

          var space = "";
          for (let i = 0; i < 50 - subclass?.length * 2; i++) {
            space = space + "";
          }
          const scheduled_time =
            config.class_label !== "snapshot"
              ? config.valid_days_and_times
              : config.exact_times;

          const entrySet = new TimeEntrySet(scheduled_time);

          if (entrySet.entries && entrySet.entries.length === 0) {
            if (
              config?.class_label?.includes("snapshot") &&
              config?.exact_times
            ) {
              const timeArr = config!.exact_times
                .replace("[(", "")
                .replace(")]", "")
                .replace(/"/g, "")
                .split(", ");
              // time = time + "[" + timeArr[0] + ":" + "[" + timeArr[1] + "]";
              time = config!.exact_times
                .replace(/"/g, "")
                .replace("),", "),\n");
            } else {
              time = "Always";
            }
          } else {
            entrySet.entries.forEach((entry) => {
              if (config?.class_label?.includes("snapshot")) {
                time =
                  time + "[" + entry.days + ":" + "[" + entry.startTime + "]";
              } else {
                time =
                  time +
                  "[" +
                  entry.days +
                  ":" +
                  "[" +
                  entry.startTime +
                  "-" +
                  entry.endTime +
                  "] ";
              }
            });
          }
        }
        let selected = selectedrules.indexOf(config.alert_config_id) >= 0;
        if (rulesexpanded) {
          if (advanced_rule_display) {
            let label_in_use = config?.class_label;
            if (config?.advanced) {
              label_in_use = "";
            }
            rules.push(
              <Card raised className={classes.rule_expansion_shelf_reponsive}>
                <Checkbox
                  disabled
                  className={classes.floatleft}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  checked={selected}
                />
                <div
                  className={classes.rule_name}
                  // onClick={() => handleEditRule(config)}
                >
                  <div className={classes.shelf_icon}>{icon}</div>
                  <div className={classes.bold_text}>
                    &nbsp;{label_in_use}
                    <br />
                    {"(" + subclass + ")"}
                  </div>
                </div>
                <div
                  className={classes.rule_container}
                  // onClick={() => handleEditRule(config)}
                >
                  <div className={classes.rule_item}>
                    <div className={classes.bold_text}>
                      {config.sensor_id_str.trim()}
                    </div>
                  </div>
                  <div className={classes.rule_item}>
                    <div
                      className={classes.bold_text}
                      style={{ textAlign: "left" }}
                    >
                      {config.human_name.trim()}
                    </div>
                  </div>
                  <div className={classes.rule_item}>
                    <div
                      className={classes.smaller_text_bold}
                      style={{ color: "#ccce" }}
                    >
                      {" "}
                      Advanced
                    </div>
                  </div>
                </div>
                <div className={classes.rule_menu} />
              </Card>
            );
          } else {
            rules.push(
              <Card
                raised
                className={classes.rule_expansion_shelf_reponsive}
                style={{
                  cursor: site?.data_origin.includes("motioncam")
                    ? "default"
                    : "pointer",
                }}
              >
                <Checkbox
                  className={classes.floatleft}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  checked={selected}
                  onChange={() =>
                    handleSelectedRuleChange(config.alert_config_id)
                  }
                />
                <div
                  className={classes.rule_name}
                  onClick={() => {
                    if (!site?.data_origin.includes("motioncam")) {
                      handleEditRule(config);
                    }
                  }}
                >
                  <div className={classes.shelf_icon}>{icon}</div>
                  <div className={classes.bold_text}>
                    &nbsp;{config.class_label}
                    <br />
                    {"(" + subclass + ")"}
                  </div>
                </div>
                <div
                  className={classes.rule_container}
                  onClick={() => {
                    if (!site?.data_origin.includes("motioncam")) {
                      handleEditRule(config);
                    }
                  }}
                >
                  <div className={classes.rule_item}>
                    <div className={classes.bold_text}>
                      {config.sensor_id_str}
                    </div>
                  </div>
                  <div className={classes.rule_item}>
                    <div className={classes.bold_text}>{config.human_name}</div>
                  </div>
                  <div className={classes.rule_item}>
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 3.474L13.86 0L12.699 1.377L16.839 4.851L18 3.474ZM5.292 1.377L4.14 0L0 3.465L1.161 4.842L5.292 1.377ZM9.45 5.526H8.1V10.926L12.375 13.491L13.05 12.384L9.45 10.251V5.526ZM9 1.926C4.527 1.926 0.9 5.553 0.9 10.026C0.9 14.499 4.518 18.126 9 18.126C13.473 18.126 17.1 14.499 17.1 10.026C17.1 5.553 13.473 1.926 9 1.926ZM9 16.326C5.517 16.326 2.7 13.509 2.7 10.026C2.7 6.543 5.517 3.726 9 3.726C12.483 3.726 15.3 6.543 15.3 10.026C15.3 13.509 12.483 16.326 9 16.326Z"
                        fill="#15325F"
                        fill-opacity="0.67"
                      />
                    </svg>
                    <Text
                      style={{ width: 220, cursor: "pointer" }}
                      ellipsis={{ tooltip: time }}
                      className={classes.smaller_text_bold}
                    >
                      &nbsp;&nbsp;{time}
                    </Text>
                  </div>
                </div>
                <div className={classes.rule_menu}>
                  <ExpansionPanelMenu
                    site={site}
                    handleAddGroupToSentinel={() => {}}
                    sentinel={false}
                    training={false}
                    handleGenerateSentinelToken={() => {}}
                    handleShowSentinelToken={() => {}}
                    handleTraining={dummy}
                    rename_site={false}
                    handleRenameSite={dummy}
                    enter={false}
                    nvr={false}
                    siteDynDNS={false}
                    requestDynDNS={true}
                    canSetDynDNS={false}
                    canViewDynDNS={false}
                    canViewFrontel={false}
                    disconnectOption={false}
                    disconnectStatus={false}
                    handleDisconnect={dummy}
                    billingratesedit={false}
                    handleBillingRatesEdit={dummy}
                    handleNvr={dummy}
                    handleActivateDynDns={dummy}
                    handleSetDynDns={dummy}
                    handleViewDynDns={dummy}
                    move={false}
                    handleMove={dummy}
                    del={true}
                    edit={true}
                    enable={false}
                    enabled={true}
                    handleEdit={() => handleEditRule(config)}
                    handleEnable={handleEnableRule}
                    handleDelete={() =>
                      openConfirmDeleteRule(
                        config.alert_config_id,
                        config.class_label
                      )
                    }
                    alert_smtp_client={false}
                    handleSmtpPasswordChange={dummy}
                    report={false}
                    rules={true}
                    view_users={false}
                    handleAddTele={dummy}
                    handleAddGroupToFrontel={() => {}}
                    handleAddSiteToFrontel={() => {}}
                  ></ExpansionPanelMenu>
                </div>
              </Card>
            );
          }
        }
        index++;
      });
      if (rulesexpanded) {
        if (index === 0) {
          rules.push(
            <Card raised className={classes.expansion_shelf}>
              <div className={classes.bold_text}>
                no rules configured for alert ruleset
              </div>
            </Card>
          );
        } else {
          rules.push(
            <div className={classes.button_container_four}>
              {!site?.data_origin?.includes("motioncam") === true && (
                <Button
                  variant="contained"
                  className={classes.cancel_button}
                  onClick={handleEditRules}
                >
                  <span className={classes.bold_text}>EDIT SELECTED</span>
                </Button>
              )}
              <Button
                variant="contained"
                className={classes.save_button}
                onClick={openConfirmDeleteRules}
              >
                <span className={classes.bold_text}>DELETE SELECTED</span>
              </Button>
              <Button
                variant="contained"
                className={classes.save_button}
                onClick={handleSelectAll}
              >
                <span className={classes.bold_text}>SELECT ALL</span>
              </Button>
              <Button
                variant="contained"
                className={classes.save_button}
                onClick={handleUnSelectAll}
              >
                <span className={classes.bold_text}>UNSELECT ALL</span>
              </Button>
            </div>
          );
        }
      }
    }
    return [rules, index];
  }
  function getDeliveryMethodsDrawer() {
    var endpoints: React.ReactNode[] = [];
    let index = 0;
    if (alertendpoints?.length !== 0) {
      alertendpoints?.forEach((endpoint: any) => {
        let disconnectStatusValue = true;
        if (
          typeof endpoint.meta["send_disconnect_notifications"] != "undefined"
        ) {
          disconnectStatusValue =
            endpoint.meta["send_disconnect_notifications"];
        }
        endpoint.type = "alertendpoint";
        const translate = appStore?.functionService.translate(
          "en_ZA",
          "endpoint_names",
          endpoint.end_point_type
        );

        endpoint.name = translate!;
        let endpoint_name = translate!;

        if (endpoint.end_point_type === "web") {
          endpoint_name = endpoint.name;
        }

        if (endpoint.end_point_type === "telegram") {
          endpoint_name = endpoint.meta?.recipient_name
            ? endpoint.name + " - " + endpoint.meta?.recipient_name
            : endpoint.name;
        }

        if (methodsexpanded) {
          endpoints.push(
            <Card raised className={classes.expansion_shelf}>
              <div className={classes.shelf_name}>
                <div className={classes.shelf_icon}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M3 6h18V4H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h4v-2H3V6zm10 6H9v1.78c-.61.55-1 1.33-1 2.22s.39 1.67 1 2.22V20h4v-1.78c.61-.55 1-1.34 1-2.22s-.39-1.67-1-2.22V12zm-2 5.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM22 8h-6c-.5 0-1 .5-1 1v10c0 .5.5 1 1 1h6c.5 0 1-.5 1-1V9c0-.5-.5-1-1-1zm-1 10h-4v-8h4v8z" />
                  </svg>
                </div>
                <div className={classes.delivery_endpoint}>{endpoint_name}</div>
              </div>
              <div className={classes.shelf_container}>
                <div className={classes.shelf_item}></div>
              </div>
              <div className={classes.shelf_menu}>
                {translate === "Alert Web Interface" ? (
                  <ExpansionPanelMenu
                    site={site}
                    handleGenerateSentinelToken={() => {}}
                    handleShowSentinelToken={() => {}}
                    sentinel={false}
                    handleAddGroupToSentinel={() => {}}
                    training={false}
                    handleTraining={dummy}
                    rename_site={false}
                    handleRenameSite={dummy}
                    enter={false}
                    nvr={false}
                    siteDynDNS={false}
                    requestDynDNS={true}
                    canSetDynDNS={false}
                    canViewDynDNS={false}
                    canViewFrontel={false}
                    disconnectOption={false}
                    disconnectStatus={disconnectStatusValue}
                    handleDisconnect={dummy}
                    billingratesedit={false}
                    handleBillingRatesEdit={dummy}
                    move={false}
                    handleMove={dummy}
                    handleNvr={dummy}
                    handleActivateDynDns={dummy}
                    handleSetDynDns={dummy}
                    handleViewDynDns={dummy}
                    del={true}
                    edit={endpoint.end_point_type === "user_app" ? false : true}
                    enable={false}
                    enabled={true}
                    handleEdit={() => handleEditDeliveryMethod(endpoint)}
                    handleEnable={handleEnableRule}
                    handleDelete={() =>
                      openConfirmDeleteEndPoint(
                        endpoint.alert_channel_end_point_id,
                        translate!
                      )
                    }
                    alert_smtp_client={false}
                    handleSmtpPasswordChange={dummy}
                    report={false}
                    rules={false}
                    view_users={true}
                    handleAddTele={() => handleAddToTele(endpoint)}
                    handleAddGroupToFrontel={() => {}}
                    handleAddSiteToFrontel={() => {}}
                  ></ExpansionPanelMenu>
                ) : translate === "Telegram" ? (
                  <ExpansionPanelMenu
                    site={site}
                    handleGenerateSentinelToken={() => {}}
                    handleShowSentinelToken={() => {}}
                    handleAddGroupToSentinel={() => {}}
                    sentinel={false}
                    training={false}
                    handleTraining={dummy}
                    rename_site={false}
                    handleRenameSite={dummy}
                    enter={false}
                    nvr={false}
                    siteDynDNS={false}
                    requestDynDNS={true}
                    canSetDynDNS={false}
                    canViewDynDNS={false}
                    canViewFrontel={false}
                    disconnectOption={true}
                    disconnectStatus={disconnectStatusValue}
                    handleDisconnect={() => handleDisconnectPopUp(endpoint)}
                    billingratesedit={false}
                    handleBillingRatesEdit={dummy}
                    handleNvr={dummy}
                    handleActivateDynDns={dummy}
                    handleSetDynDns={dummy}
                    handleViewDynDns={dummy}
                    move={false}
                    handleMove={dummy}
                    del={true}
                    edit={endpoint.end_point_type === "user_app" ? false : true}
                    enable={false}
                    enabled={true}
                    handleEdit={() => handleEditDeliveryMethod(endpoint)}
                    handleEnable={handleEnableRule}
                    handleDelete={() =>
                      openConfirmDeleteEndPoint(
                        endpoint.alert_channel_end_point_id,
                        translate!
                      )
                    }
                    alert_smtp_client={false}
                    handleSmtpPasswordChange={dummy}
                    report={false}
                    rules={true}
                    view_users={false}
                    handleAddTele={dummy}
                    handleAddGroupToFrontel={() => {}}
                    handleAddSiteToFrontel={() => {}}
                  ></ExpansionPanelMenu>
                ) : translate === "HikCentral" ? (
                  <ExpansionPanelMenu
                    site={site}
                    handleGenerateSentinelToken={() => {}}
                    handleShowSentinelToken={() => {}}
                    handleAddGroupToSentinel={() => {}}
                    sentinel={false}
                    training={false}
                    handleTraining={dummy}
                    rename_site={false}
                    handleRenameSite={dummy}
                    enter={false}
                    nvr={false}
                    siteDynDNS={false}
                    requestDynDNS={true}
                    canSetDynDNS={false}
                    canViewDynDNS={false}
                    canViewFrontel={false}
                    disconnectOption={false}
                    disconnectStatus={false}
                    handleDisconnect={dummy}
                    billingratesedit={false}
                    handleBillingRatesEdit={dummy}
                    move={false}
                    handleMove={dummy}
                    handleNvr={dummy}
                    handleActivateDynDns={dummy}
                    handleSetDynDns={dummy}
                    handleViewDynDns={dummy}
                    del={true}
                    edit={endpoint.end_point_type === "user_app" ? false : true}
                    enable={false}
                    enabled={true}
                    handleEdit={() => handleEditHikDeliveryMethod(endpoint)}
                    handleEnable={handleEnableRule}
                    handleDelete={() =>
                      openConfirmDeleteEndPoint(
                        endpoint.alert_channel_end_point_id,
                        translate!
                      )
                    }
                    alert_smtp_client={false}
                    handleSmtpPasswordChange={dummy}
                    report={false}
                    rules={true}
                    view_users={false}
                    handleAddTele={dummy}
                    handleAddGroupToFrontel={() => {}}
                    handleAddSiteToFrontel={() => {}}
                  ></ExpansionPanelMenu>
                ) : translate === "Arteco" ||
                  translate === "Export" ||
                  translate === "Immix" ||
                  translate === "Listener" ||
                  translate === "Milestone" ||
                  translate === "Patriot" ||
                  translate === "Sentinel" ||
                  translate === "Signaltower" ||
                  translate === "DeepAlert User App" ||
                  (translate === "unusual_alert" &&
                    appStore!.authService!.getRoles()!.indexOf("sys_admin") >=
                      0) ? (
                  <ExpansionPanelMenu
                    site={site}
                    handleGenerateSentinelToken={() => {}}
                    handleShowSentinelToken={() => {}}
                    sentinel={false}
                    handleAddGroupToSentinel={() => {}}
                    training={false}
                    handleTraining={dummy}
                    rename_site={false}
                    handleRenameSite={dummy}
                    enter={false}
                    nvr={false}
                    siteDynDNS={false}
                    requestDynDNS={true}
                    canSetDynDNS={false}
                    canViewDynDNS={false}
                    canViewFrontel={false}
                    disconnectOption={true}
                    disconnectStatus={disconnectStatusValue}
                    handleDisconnect={() => handleDisconnectPopUp(endpoint)}
                    billingratesedit={false}
                    handleBillingRatesEdit={dummy}
                    move={false}
                    handleMove={dummy}
                    handleNvr={dummy}
                    handleActivateDynDns={dummy}
                    handleSetDynDns={dummy}
                    handleViewDynDns={dummy}
                    del={true}
                    edit={endpoint.end_point_type === "user_app" ? false : true}
                    enable={false}
                    enabled={true}
                    handleEdit={() => handleEditDeliveryMethod(endpoint)}
                    handleEnable={handleEnableRule}
                    handleDelete={() =>
                      openConfirmDeleteEndPoint(
                        endpoint.alert_channel_end_point_id,
                        translate!
                      )
                    }
                    alert_smtp_client={false}
                    handleSmtpPasswordChange={dummy}
                    report={false}
                    rules={true}
                    view_users={false}
                    handleAddTele={dummy}
                    handleAddGroupToFrontel={() => {}}
                    handleAddSiteToFrontel={() => {}}
                  ></ExpansionPanelMenu>
                ) : (
                  <ExpansionPanelMenu
                    site={site}
                    handleGenerateSentinelToken={() => {}}
                    handleShowSentinelToken={() => {}}
                    sentinel={false}
                    handleAddGroupToSentinel={() => {}}
                    training={false}
                    handleTraining={dummy}
                    rename_site={false}
                    handleRenameSite={dummy}
                    enter={false}
                    nvr={false}
                    siteDynDNS={false}
                    requestDynDNS={true}
                    canSetDynDNS={false}
                    canViewDynDNS={false}
                    canViewFrontel={false}
                    disconnectOption={false}
                    disconnectStatus={false}
                    handleDisconnect={dummy}
                    billingratesedit={false}
                    handleBillingRatesEdit={dummy}
                    move={false}
                    handleMove={dummy}
                    handleNvr={dummy}
                    handleActivateDynDns={dummy}
                    handleSetDynDns={dummy}
                    handleViewDynDns={dummy}
                    del={true}
                    edit={endpoint.end_point_type === "user_app" ? false : true}
                    enable={false}
                    enabled={true}
                    handleEdit={() => handleEditDeliveryMethod(endpoint)}
                    handleEnable={handleEnableRule}
                    handleDelete={() =>
                      openConfirmDeleteEndPoint(
                        endpoint.alert_channel_end_point_id,
                        translate!
                      )
                    }
                    alert_smtp_client={false}
                    handleSmtpPasswordChange={dummy}
                    report={false}
                    rules={true}
                    view_users={false}
                    handleAddTele={dummy}
                    handleAddGroupToFrontel={() => {}}
                    handleAddSiteToFrontel={() => {}}
                  ></ExpansionPanelMenu>
                )}
              </div>
            </Card>
          );
        }
        index++;
      });
      if (index === 0) {
        endpoints.push(
          <Card raised className={classes.expansion_shelf}>
            <div className={classes.bold_text}>
              no delivery methods configured for alert ruleset
            </div>
          </Card>
        );
      }
    }
    return [endpoints, index];
  }

  var editable = appStore!.authService.getRoles().indexOf("site_admin") >= 0;
  let updateChannelButton: React.ReactNode[] = [];
  if (editable) {
    updateChannelButton.push(
      <div className={classes.button_container}>
        <Button
          variant="contained"
          className={classes.bold_text}
          onClick={() =>
            handleUpdateAlertChannel(alertchannel.alert_channel_id)
          }
        >
          Update Ruleset
        </Button>
        <Button
          variant="contained"
          className={classes.bold_text}
          onClick={() =>
            openConfirmDeleteAlertChannel(
              alertchannel.alert_channel_id,
              alertchannel.name
            )
          }
        >
          Delete Ruleset
        </Button>
      </div>
    );
  }

  let expandalertchannel: React.ReactNode[] = [];
  if (alertchannelexpanded) {
    expandalertchannel.push(
      <ExpandLessIcon
        className={classes.icon_white}
        onClick={() => handleAlertChannelExpanded()}
      />
    );
  } else {
    expandalertchannel.push(
      <ExpandMoreIcon
        className={classes.icon_white}
        onClick={() => handleAlertChannelExpanded()}
      />
    );
  }
  let expandgeneralsettings: React.ReactNode[] = [];
  if (generalsettingsexpanded) {
    expandgeneralsettings.push(
      <ExpandLessIcon
        className={classes.icon_white}
        onClick={() => handleGeneralSettingsExpanded()}
      />
    );
  } else {
    expandgeneralsettings.push(
      <ExpandMoreIcon
        className={classes.icon_white}
        onClick={() => handleGeneralSettingsExpanded()}
      />
    );
  }

  var generalsettings: React.ReactNode[] = [];
  if (generalsettingsexpanded) {
    generalsettings.push(
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Box
              style={{
                width: "50%",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "25%",
              }}
            >
              <Typography className={classes.bold_text}>
                {"Alert Ruleset ID: " + alertchannel.alert_channel_id}
              </Typography>
              <Typography className={classes.bold_error_text}>
                {message}
              </Typography>
              <TextField
                onChange={(e) => {
                  handleAlertChannelNameChange(
                    e,
                    alertchannel.alert_channel_id
                  );
                }}
                label="Ruleset name"
                variant="outlined"
                disabled={!editable}
                margin="normal"
                autoFocus
                value={alertchannelname}
                fullWidth
                InputProps={{
                  className: classes.bold_text,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.bold_text,
                  },
                }}
              />
              {site?.data_origin === "immix_ailink_human1" ? (
                <Typography className={classes.bold_text}>
                  Confidence threshold set on Immix platform. Deepalert
                  recommends a 50% threshold setting.
                </Typography>
              ) : site?.data_origin?.includes("motioncam") ? (
                <TextField
                  onChange={(e) => {
                    handleThresholdChange(e, alertchannel.alert_channel_id);
                  }}
                  label="Default Confidence Threshold"
                  variant="outlined"
                  disabled={!editable}
                  margin="normal"
                  autoFocus
                  value={threshold}
                  fullWidth
                  InputProps={{
                    className: classes.bold_text,
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.bold_text,
                    },
                  }}
                />
              ) : null}

              <br />
              <TextField
                value={timeZone}
                onChange={handleTimeZoneChange}
                fullWidth={true}
                variant={"outlined"}
                select={true}
                label={"Time Zone"}
                disabled={!editable}
                margin="normal"
                InputProps={{
                  className: classes.bold_text,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.bold_text,
                  },
                }}
              >
                {time_zones.map((zone) => (
                  <MenuItem value={zone}>{zone}</MenuItem>
                ))}
              </TextField>
              <br />

              {site?.data_origin === "sentinel_http_edge_human1" &&
              alertchannel.meta.hasOwnProperty(
                "return_non_detection_videos"
              ) ? (
                <FormControlLabel
                  className={classes.form_component_checkbox}
                  //   fontSize='large'
                  control={
                    <Checkbox
                      color="primary"
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                      checked={returnNonDetectionVideos}
                      onChange={(e) => {
                        setReturnNonDetectionVideos(e.target.checked);
                      }}
                    />
                  }
                  label="Return non-detection videos"
                />
              ) : null}
              {/* {site?.data_origin === "client_hub_mobcam_human1" ||
              site?.data_origin === "alert_rest_client" ||
              site?.data_origin === "alert_smtp_client" ||
              site?.data_origin === "sentinel_http_edge_human1" ||
              site?.data_origin === "alert_ftp_client" ? (
                <FormControlLabel
                  className={classes.form_component_checkbox}
                  //   fontSize='large'
                  control={
                    <Checkbox
                      color="primary"
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                      checked={ignoreStationaryVehicles}
                      onChange={(e) => {
                        setIgnoreStationaryVehicles(e.target.checked);
                      }}
                    />
                  }
                  label="Ignore stationary vehicles"
                />
              ) : null} */}
            </Box>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
        <Grid container>
          {/* <Grid item xs={5}></Grid>
          <Grid item xs={2}>
            <IconButton
              edge="end"
              aria-label="add"
              disabled={!editable}
              onClick={() => {
                addEmail(alertchannel.alert_channel_id);
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
                  fill="#15325F"
                  fill-opacity="0.87"
                />
              </svg>
            </IconButton>
          </Grid>
          <Grid item xs={5}>
            <p className={classes.bold_text}>{"Add Email for export"}</p>
          </Grid> */}
        </Grid>
        {updateChannelButton}
      </Container>
    );
  }

  //rules and methods section
  const [rules_drawer, rulescount] = getRulesDrawer();
  const [deliverymethods_drawer, deliverymethodscount] =
    getDeliveryMethodsDrawer();
  let expandrules: React.ReactNode[] = [];
  let expandmethods: React.ReactNode[] = [];
  if (rulesexpanded) {
    expandrules.push(
      <ExpandLessIcon
        className={classes.icon_white}
        onClick={() => handleRulesExpanded()}
      />
    );
  } else {
    expandrules.push(
      <ExpandMoreIcon
        className={classes.icon_white}
        onClick={() => handleRulesExpanded()}
      />
    );
  }
  if (methodsexpanded) {
    expandmethods.push(
      <ExpandLessIcon
        className={classes.icon_white}
        onClick={() => handleMethodsExpanded()}
      />
    );
  } else {
    expandmethods.push(
      <ExpandMoreIcon
        className={classes.icon_white}
        onClick={() => handleMethodsExpanded()}
      />
    );
  }
  let rulesandmethods: React.ReactNode[] = [];
  rulesandmethods.push(
    <>
      {site?.data_origin?.includes("motioncam") && (
        <div>
          <Card
            className={classes.expansionbar_small_alert}
            onClick={() => handleRulesExpanded()}
          >
            <Tooltip
              placement="bottom"
              title="Alert rules define which alerts and schedules apply to which camera streams."
            >
              <Typography>
                <div className={classes.header}>
                  <div className={classes.header_icon}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 10H2v2h12v-2zm0-4H2v2h12V6zM2 16h8v-2H2v2zm19.5-4.5L23 13l-6.99 7-4.51-4.5L13 14l3.01 3 5.49-5.5z"
                        fill="#FFFFFF"
                        fill-opacity="0.87"
                      />
                    </svg>
                  </div>
                  <div className={classes.header_text_white}>
                    Rules{"  (" + rulescount + ")"}
                  </div>
                  {site?.data_origin !== "immix_ailink_human1" &&
                    !site?.data_origin.includes("frontel") && (
                      <IconButton
                        edge="end"
                        aria-label="add"
                        onClick={(event) => {
                          event.stopPropagation();
                          openAddRuleModal();
                        }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
                            fill="#FFFFFF"
                            fill-opacity="0.87"
                          />
                        </svg>
                      </IconButton>
                    )}
                </div>
              </Typography>
            </Tooltip>
            {expandrules}
          </Card>
          <div className={classes.expansioncontent_whole_width}>
            {rules_drawer}
          </div>
        </div>
      )}

      <Card
        className={classes.expansionbar_small_alert}
        onClick={() => handleMethodsExpanded()}
      >
        <Tooltip
          placement="bottom"
          title="The destinations for alerts are configured as delivery methods. These could include alerting to web, mobile and third party systems."
        >
          <Typography>
            <div className={classes.header}>
              <div className={classes.header_icon}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 6h18V4H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h4v-2H3V6zm10 6H9v1.78c-.61.55-1 1.33-1 2.22s.39 1.67 1 2.22V20h4v-1.78c.61-.55 1-1.34 1-2.22s-.39-1.67-1-2.22V12zm-2 5.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM22 8h-6c-.5 0-1 .5-1 1v10c0 .5.5 1 1 1h6c.5 0 1-.5 1-1V9c0-.5-.5-1-1-1zm-1 10h-4v-8h4v8z"
                    fill="#FFFFFF"
                    fill-opacity="0.87"
                  />
                </svg>
              </div>
              <div className={classes.header_text_white}>
                Delivery Methods{"  (" + deliverymethodscount + ")"}
              </div>
              <IconButton
                edge="end"
                aria-label="add"
                onClick={(event) => {
                  event.stopPropagation();
                  openEndpointModal();
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
                    fill="#FFFFFF"
                    fill-opacity="0.87"
                  />
                </svg>
              </IconButton>
            </div>
          </Typography>
        </Tooltip>
        {expandmethods}
      </Card>
      <div className={classes.expansioncontent}>{deliverymethods_drawer}</div>
    </>
  );

  var alertchannelcontent: React.ReactNode[] = [];
  if (alertchannelexpanded) {
    alertchannelcontent.push(
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Card
          className={classes.expansionbar_small_alert}
          onClick={() => handleGeneralSettingsExpanded()}
        >
          <div className={classes.header}>
            <div className={classes.header_icon}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58c.18-.14.23-.41.12-.61l-1.92-3.32c-.12-.22-.37-.29-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54c-.04-.24-.24-.41-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58c-.18.14-.23.41-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61l-2.01-1.58zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z"
                  fill="#15325F"
                  fill-opacity="0.87"
                ></path>
              </svg>
            </div>
            <div className={classes.header_text_white}>General Settings</div>
          </div>
          {expandgeneralsettings}
        </Card>
        <div className={classes.expansioncontent}>{generalsettings}</div>
        {!site?.data_origin.includes("motioncam") && (
          <div className={classes.default_rules_header}>
            <Modal
              open={openMsg}
              onClose={handleCloseMsgModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={updateMsg}>
                <p
                  style={{
                    fontWeight: "bold",
                    color: "rgb(21,50,95)",
                    textAlign: "center",
                  }}
                >
                  {addZoneMsg}
                </p>
                <Button
                  style={{
                    backgroundColor: "rgb(220,220,220)",
                    color: "rgb(21,50,95)",
                    fontWeight: "bold",
                    margin: " 40px 180px",
                  }}
                  onClick={handleCloseMsgModal}
                >
                  OK
                </Button>
              </Box>
            </Modal>
            <Modal
              open={openAddRule}
              onClose={handleCloseAddRule}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={editRuleModal}>
                {loadingSpinner === true ? (
                  <div style={{ marginTop: "190px" }}>
                    <LoadingSpinner />
                  </div>
                ) : (
                  <div>
                    {updateRulesMsg !== "" ? (
                      <div
                        style={{
                          margin: "auto",
                          marginTop: "200px",
                        }}
                      >
                        <div></div>
                        <p
                          style={{
                            color: "rgb(21,50,95)",
                            fontWeight: "bold",
                          }}
                        >
                          {updateRulesMsg}
                        </p>
                        <Button
                          style={{
                            backgroundColor: "rgb(220,220,220)",
                            color: "rgb(21,50,95)",
                            fontWeight: "bold",
                            marginTop: "40px",
                          }}
                          onClick={() => {
                            setUpdateRulesMsg("");
                            setRefreshParent();
                            setUpdateResponse("");
                            setRuleName("");
                            setParentReload(new Date().getTime());
                            fetchDefaultRules();
                          }}
                        >
                          OK
                        </Button>
                      </div>
                    ) : (
                      <div>
                        {steps === 1 && (
                          <div>
                            <div>
                              {updateResponse === "" && (
                                <div
                                  className="save_update_btns"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <h3
                                    style={{
                                      fontWeight: "bold",
                                      color: "rgb(21,50,95)",
                                      margin: "0 auto",
                                      padding: "10px 0",
                                    }}
                                  >
                                    {" "}
                                    {default_rules?.rules?.length > 1 ? (
                                      <b>EDIT DEFAULT RULES:</b>
                                    ) : (
                                      <b>EDIT DEFAULT RULE:</b>
                                    )}
                                  </h3>
                                </div>
                              )}
                              <div
                                style={{
                                  width: "100%",
                                  minWidth: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {" "}
                                <div
                                  className="rule-name"
                                  style={{
                                    width: "100%",
                                    minWidth: "100%",
                                    margin: "0 auto",
                                    padding: "30px 0",
                                    borderTop: "1px solid rgb(21,50,95)",
                                    borderBottom: "1px solid rgb(21,50,95)",
                                    paddingTop: "20px",
                                    paddingBottom: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                  <h3
                                    style={{
                                      fontWeight: "bold",
                                      color: "rgb(21,50,95)",
                                    }}
                                  >
                                    {"Enter Rule's Name:"}
                                  </h3>
                                  <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    style={{
                                      width: "450px",
                                    }}
                                    value={ruleNameToDelete}
                                    onChange={editRuleNameHandler}
                                    placeholder={ruleNameToDelete}
                                  />
                                </div>
                                {relaventRuleDataToDelete?.fields?.cadence_secs
                                  ?.enabled && (
                                  <div
                                    className="rule-name"
                                    style={{
                                      width: "100%",
                                      minWidth: "100%",
                                      margin: "0 auto",
                                      padding: "30px 0",
                                      borderTop: "1px solid rgb(21,50,95)",
                                      borderBottom: "1px solid rgb(21,50,95)",
                                      paddingTop: "20px",
                                      paddingBottom: "20px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <FormControl
                                      className={classes.formControl}
                                      variant="outlined"
                                      style={{
                                        width: 140,
                                        color: "#6D809D",
                                      }}
                                    >
                                      <InputLabel id="demo-mutiple-chip-label">
                                        {
                                          relaventRuleDataToDelete?.fields
                                            ?.cadence_secs?.label
                                        }
                                        :
                                      </InputLabel>
                                      <Select
                                        variant="outlined"
                                        labelId="demo-mutiple-chip-label"
                                        id="demo-mutiple-chip"
                                        defaultValue={
                                          relaventRuleDataToDelete?.fields
                                            ?.cadence_secs?.default || ""
                                        }
                                        value={selectedInterValEdit}
                                        onChange={handleIntervalEditChange}
                                        input={
                                          <Input id="select-multiple-chip" />
                                        }
                                      >
                                        {relaventRuleDataToDelete?.fields?.cadence_secs?.options?.map(
                                          (item: any, index: number) => (
                                            <MenuItem key={index} value={item}>
                                              {`${item / 60} min`}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                    </FormControl>
                                    <p
                                      style={{
                                        fontStyle: "italic",
                                        color: "red",
                                        fontSize: "10px",
                                      }}
                                    >
                                      {
                                        "applies where products and cameras are configured for fetching images"
                                      }
                                    </p>
                                  </div>
                                )}
                                <div
                                  className="form-field"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "45px",
                                    width: "100%",
                                    margin: "0 auto",
                                    borderTop: "1px solid rgb(21,50,95)",
                                    paddingTop: "20px",
                                  }}
                                >
                                  {relaventRuleDataToDelete?.fields?.zone_configs?.map(
                                    (config: any, index: number) => {
                                      if (config?.type === "zone") {
                                        return (
                                          <FormControl
                                            key={index}
                                            disabled={true}
                                            className={classes.formControl}
                                            variant="outlined"
                                            style={{
                                              width: 140,
                                              color: "#6D809D",
                                            }}
                                          >
                                            <InputLabel>
                                              {config?.label}
                                            </InputLabel>
                                            <Select
                                              variant="outlined"
                                              value={config?.default}
                                              input={<Input />}
                                              displayEmpty
                                            >
                                              <MenuItem value={config?.default}>
                                                {config?.options?.replaceAll(
                                                  "_",
                                                  " "
                                                )}
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        );
                                      }

                                      if (config?.type === "class_label") {
                                        return (
                                          <FormControl
                                            key={index}
                                            disabled={zoneData?.length === 2}
                                            className={classes?.formControl}
                                            variant="outlined"
                                            style={{
                                              width: 140,
                                              color: "#6D809D",
                                            }}
                                          >
                                            <InputLabel>
                                              {config.label}
                                            </InputLabel>
                                            <Select
                                              variant="outlined"
                                              value={selectedClass}
                                              onChange={handleClassChange}
                                              input={<Input />}
                                            >
                                              {config.options.map(
                                                (
                                                  classItem: any,
                                                  idx: number
                                                ) => (
                                                  <MenuItem
                                                    key={idx}
                                                    value={classItem}
                                                  >
                                                    {classItem.split(".")[1]}
                                                  </MenuItem>
                                                )
                                              )}
                                            </Select>
                                          </FormControl>
                                        );
                                      }

                                      if (
                                        config.type === "confidence_threshold"
                                      ) {
                                        return (
                                          <Tooltip
                                            key={index}
                                            placement="top"
                                            title={`Please note the default value for the Threshold is ${config.default}% with the minimum value being ${config.min}% and the maximum being ${config.max}%.`}
                                          >
                                            <TextField
                                              disabled={zoneData?.length === 2}
                                              type="number"
                                              label={config.label}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              inputProps={{
                                                min: config.min,
                                                max: config.max,
                                                step: 1,
                                              }}
                                              style={{
                                                width: 130,
                                                marginTop: "9px",
                                              }}
                                              value={selectedThreshold}
                                              onChange={handleThreshold}
                                              variant="standard"
                                            />
                                          </Tooltip>
                                        );
                                      }
                                      if (config.type === "object_motion") {
                                        return (
                                          <FormControl
                                            key={index}
                                            disabled={zoneData?.length === 2}
                                            className={classes.formControl}
                                            variant="outlined"
                                            style={{
                                              width: 160,
                                              color: "#6D809D",
                                            }}
                                          >
                                            <InputLabel
                                              shrink={Boolean(selectedClass)}
                                            >
                                              {config.label}
                                            </InputLabel>
                                            <Select
                                              variant="outlined"
                                              value={selectedMotion}
                                              onChange={handleObjectMotion}
                                              input={<Input />}
                                            >
                                              {config.options.map(
                                                (motion: any, idx: number) => (
                                                  <MenuItem
                                                    key={idx}
                                                    value={motion.replaceAll(
                                                      " ",
                                                      "_"
                                                    )}
                                                  >
                                                    {motion.replaceAll(
                                                      "_",
                                                      " "
                                                    )}
                                                  </MenuItem>
                                                )
                                              )}
                                            </Select>
                                          </FormControl>
                                        );
                                      }
                                      if (config.type === "count") {
                                        return (
                                          <TextField
                                            key={index}
                                            disabled={zoneData?.length === 2}
                                            type="number"
                                            label={config.label}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            inputProps={{
                                              min: config.min,
                                              max: config.max,
                                              step: 1,
                                            }}
                                            style={{
                                              width: 130,
                                              marginTop: "9px",
                                            }}
                                            value={selectedCount}
                                            onChange={handleCountChange}
                                            variant="standard"
                                          />
                                        );
                                      }

                                      if (config.type === "count_logic") {
                                        return (
                                          <FormControl
                                            key={index}
                                            disabled={zoneData?.length === 2}
                                            className={classes.formControl}
                                            variant="outlined"
                                            style={{
                                              width: 160,
                                              color: "#6D809D",
                                            }}
                                          >
                                            <InputLabel>
                                              {config.label}
                                            </InputLabel>
                                            <Select
                                              variant="outlined"
                                              value={selectedCountLogic}
                                              onChange={handleCountLogicChange}
                                              input={<Input />}
                                            >
                                              {config?.options?.map(
                                                (logic: any, idx: any) => (
                                                  <MenuItem
                                                    key={idx}
                                                    value={logic}
                                                  >
                                                    {logic}
                                                  </MenuItem>
                                                )
                                              )}
                                            </Select>
                                          </FormControl>
                                        );
                                      }

                                      return null;
                                    }
                                  )}
                                  {updateResponse === "" ? (
                                    <div
                                      style={{
                                        display: relaventRuleDataToDelete
                                          ?.fields?.zone_configs
                                          ? "block"
                                          : "none",
                                      }}
                                    >
                                      <Button
                                        variant="contained"
                                        style={{
                                          color: "white",
                                          backgroundColor:
                                            zoneData?.length === 2 ? "" : "red",
                                          fontWeight: "bold",
                                          width: "95px",
                                          height: "50px",
                                          margin: "0 15px",
                                          transform: "translate(10px,0)",
                                        }}
                                        onClick={addRuleHandler}
                                      >
                                        ADD
                                      </Button>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              {zoneData.length === 2 && (
                                <p
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    fontSize: "10px",
                                    padding: "10px 0",
                                  }}
                                >
                                  The maximum default number of zone configs is
                                  2
                                </p>
                              )}

                              <div
                                style={{
                                  marginTop: "45px",
                                  width: "100%",
                                  margin: "0 auto",
                                  borderTop: "1px solid rgb(21,50,95)",
                                  paddingTop: "20px",
                                  overflow: "auto",
                                  maxHeight: "300px",
                                  overflowY: "auto",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {zoneData?.length !== 0 && (
                                    <p
                                      style={{
                                        color: "#15325F",
                                        fontSize: "15px",
                                        fontStyle: "normal",
                                        fontFamily: "Open Sans",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {"ZONE CONFIGS:"}
                                    </p>
                                  )}
                                  {zoneData?.map((zone: any, index: any) => {
                                    return (
                                      <div
                                        key={index}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          margin: "10px auto",
                                          width: "100%",
                                          backgroundColor: "#F6F6F6",
                                          boxShadow: "2px 1px #F6F6F6",
                                          borderRadius: "10px",
                                          padding: "5px 0",
                                        }}
                                      >
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Zone:{" "}
                                          <span style={{ color: "grey" }}>
                                            {"full image"}
                                          </span>
                                        </p>
                                        {relaventRuleDataToDelete?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "class_label"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Class:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.class_label?.split(".")[1]}
                                            </span>
                                          </p>
                                        )}

                                        {relaventRuleDataToDelete?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "object_motion"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Object Motion:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.object_motion?.replaceAll(
                                                "_",
                                                " "
                                              )}
                                            </span>
                                          </p>
                                        )}

                                        {relaventRuleDataToDelete?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type ===
                                            "confidence_threshold"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Threshold:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.confidence_threshold}
                                            </span>
                                          </p>
                                        )}

                                        {relaventRuleDataToDelete?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "count"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Count:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.count}
                                            </span>
                                          </p>
                                        )}

                                        {relaventRuleDataToDelete?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "count_logic"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Count Logic:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.count_logic}
                                            </span>
                                          </p>
                                        )}

                                        {!relaventRuleDataToDelete?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "class_label" ||
                                            config.type === "object_motion" ||
                                            config.type ===
                                              "confidence_threshold"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                              visibility: "hidden",
                                            }}
                                          >
                                            Fetch Interval:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.cadence_secs}
                                            </span>
                                          </p>
                                        )}

                                        <IconButton
                                          style={{
                                            color: "red",
                                            marginTop: "-8px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            removeRuleHandler(zone?.random_id);
                                          }}
                                        >
                                          <DeleteForeverIcon />
                                        </IconButton>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {steps === 2 && (
                          <div
                            style={{
                              width: "1000px",
                              display: "flex",
                              flexDirection: "column",
                              alignContent: "center",
                              justifyContent: "center",
                            }}
                          >
                            <p
                              style={{
                                color: "rgb(21,50,95)",
                                fontWeight: "bold",
                                fontSize: "12px",
                                margin: "0 auto",
                                padding: "10px 0",
                              }}
                            >
                              {
                                "Please note that the timezone applied will be as specified at the site level."
                              }
                            </p>
                            {relaventRuleDataToDelete?.fields
                              ?.valid_days_and_times?.enabled && (
                              <div
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignContent: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    margin: "auto",
                                  }}
                                >
                                  <FormControl component="fieldset">
                                    <RadioGroup
                                      aria-label="schedule"
                                      name="schedule"
                                      value={radioValue}
                                      onChange={handleTimeChange}
                                    >
                                      <FormControlLabel
                                        value="always"
                                        control={<Radio />}
                                        label="Always active"
                                        disabled={false}
                                      />

                                      <FormControlLabel
                                        value="schedule"
                                        control={<Radio />}
                                        label="Schedule"
                                        disabled={false}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                                {radioValue !== "always" && (
                                  <div
                                    style={{
                                      width: "38%",
                                      margin: "0 auto",
                                      display: "flex",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    {radioValue === "" ? (
                                      <span>{""}</span>
                                    ) : (
                                      <div
                                        style={{
                                          // width: "38%",
                                          margin: "0 auto",
                                        }}
                                      >
                                        {scheduleRules}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                        {steps === 3 && (
                          <div
                            style={{
                              width: "90%",
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "column",
                              alignContent: "center",
                              marginLeft: "20px",
                            }}
                          >
                            <h2
                              style={{
                                color: "rgb(21,50,95)",
                                fontWeight: "bold",
                                fontSize: "15px",
                                textAlign: "center",
                                marginLeft: "65px",
                              }}
                            >
                              CONFIRM CHANGES:
                            </h2>
                            <div style={{ height: "20px" }}></div>
                            <p
                              style={{
                                fontWeight: "bold",
                                color: "red",
                                textAlign: "center",
                                marginLeft: "70px",
                              }}
                            >
                              {
                                "Please note that you will be updating your defaults rules for this ‘site’"
                              }
                            </p>
                            <div style={{ height: "60px" }}></div>
                            <div
                              style={{
                                width: "100%",
                                margin: "0 auto",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                style={{
                                  color: "rgb(21,50,95)",
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                }}
                              >
                                Rule Name:{" "}
                                <span style={{ color: "grey" }}>
                                  {ruleNameToDelete}
                                </span>
                              </p>
                              <p
                                style={{
                                  color: "rgb(21,50,95)",
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                }}
                              >
                                Rule Type:{" "}
                                <span style={{ color: "grey" }}>
                                  {ruleTypeToDelete
                                    ?.replaceAll("_", " ")
                                    ?.toLocaleUpperCase()}
                                </span>
                              </p>
                              {relaventRuleDataToDelete?.fields?.cadence_secs
                                ?.enabled && (
                                <p
                                  style={{
                                    color: "rgb(21,50,95)",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}
                                >
                                  Fetch Interval:{" "}
                                  <span style={{ color: "grey" }}>
                                    {selectedInterVal}
                                  </span>
                                </p>
                              )}
                            </div>
                            <div
                              style={{
                                marginTop: "45px",
                                width: "900px",
                                margin: "0 auto",
                                borderTop: "1px solid rgb(21,50,95)",
                                borderBottom: "1px solid rgb(21,50,95)",
                                marginRight: "100px",
                                paddingTop: "20px",
                                paddingBottom: "20px",
                                overflow: "auto",
                                maxHeight: "400px",
                                overflowY: "auto",
                              }}
                            >
                              <p
                                style={{
                                  color: "rgb(21,50,95)",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  marginTop: "5px",
                                  display: relaventRuleDataToDelete?.fields
                                    ?.zone_configs
                                    ? "block"
                                    : "none",
                                }}
                              >
                                ZONE CONFIGS:{" "}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {zoneData?.map((zone: any, index: any) => {
                                  return (
                                    <div
                                      key={index}
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        margin: "10px auto",
                                        width: "100%",
                                        backgroundColor: "#F6F6F6",
                                        boxShadow: "2px 1px #F6F6F6",
                                        borderRadius: "10px",
                                        padding: "5px 0",
                                      }}
                                    >
                                      <p
                                        style={{
                                          color: "rgb(21,50,95)",
                                          fontWeight: "bold",
                                          padding: "5px 10px",
                                        }}
                                      >
                                        Zone:{" "}
                                        <span style={{ color: "grey" }}>
                                          {"full image"}
                                        </span>
                                      </p>
                                      {relaventRuleDataToDelete?.fields?.zone_configs?.some(
                                        (config: any) =>
                                          config.type === "class_label"
                                      ) && (
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Class:{" "}
                                          <span style={{ color: "grey" }}>
                                            {zone?.class_label?.split(".")[1]}
                                          </span>
                                        </p>
                                      )}
                                      {relaventRuleDataToDelete?.fields?.zone_configs?.some(
                                        (config: any) =>
                                          config.type === "object_motion"
                                      ) && (
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Object Motion:{" "}
                                          <span style={{ color: "grey" }}>
                                            {zone?.object_motion?.replaceAll(
                                              "_",
                                              " "
                                            )}
                                          </span>
                                        </p>
                                      )}
                                      {relaventRuleDataToDelete?.fields?.zone_configs?.some(
                                        (config: any) =>
                                          config.type === "confidence_threshold"
                                      ) && (
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Threshold:{" "}
                                          <span style={{ color: "grey" }}>
                                            {zone?.confidence_threshold}
                                          </span>
                                        </p>
                                      )}
                                      {relaventRuleDataToDelete?.fields?.zone_configs?.some(
                                        (config: any) => config.type === "count"
                                      ) && (
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Count:{" "}
                                          <span style={{ color: "grey" }}>
                                            {zone?.count}
                                          </span>
                                        </p>
                                      )}
                                      {relaventRuleDataToDelete?.fields?.zone_configs?.some(
                                        (config: any) =>
                                          config.type === "count_logic"
                                      ) && (
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Count Logic:{" "}
                                          <span style={{ color: "grey" }}>
                                            {zone?.count_logic}
                                          </span>
                                        </p>
                                      )}
                                      <IconButton
                                        style={{
                                          color: "red",
                                          marginTop: "-8px",
                                          cursor: "pointer",
                                          visibility: "hidden",
                                        }}
                                        onClick={() => {
                                          removeRuleHandler(zone?.random_id);
                                        }}
                                      >
                                        <DeleteForeverIcon />
                                      </IconButton>
                                    </div>
                                  );
                                })}
                                <div
                                  style={{
                                    marginTop: "45px",
                                    width: "100%",
                                    margin: "0 auto",
                                    borderTop: "1px solid rgb(21,50,95)",
                                    paddingTop: "20px",
                                    paddingBottom: "20px",
                                    overflow: "auto",
                                    maxHeight: "300px",
                                    overflowY: "auto",
                                    display: "grid",
                                    translate: "transform(10px,0)",
                                    // gridTemplateColumns: "repeat(2, 1fr)",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "rgb(21,50,95)",
                                      fontWeight: "bold",
                                      fontSize: "15px",
                                    }}
                                  >
                                    DAYS & TIMES:{" "}
                                  </p>
                                  <div
                                    style={{
                                      width: "80%",
                                      margin: "0 auto",
                                      textAlign: "center",
                                    }}
                                  >
                                    {radioValue === "always" ? (
                                      <p
                                        style={{
                                          color: "rgb(21,50,95)",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "grey",
                                            fontWeight: "light",
                                          }}
                                        >{`${"always active"}`}</span>
                                      </p>
                                    ) : radioValue === "schedule" ? (
                                      <p
                                        style={{
                                          color: "rgb(21,50,95)",
                                          fontWeight: "bold",
                                          pointerEvents: "none",
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "grey",
                                            fontWeight: "light",
                                          }}
                                        >
                                          {scene_valid_days_and_times}
                                        </span>
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div
                          className="cancel_next_btns"
                          style={{
                            display: "flex",
                          }}
                        >
                          <Button
                            variant="contained"
                            style={{
                              color: "rgb(21,50,95)",
                              fontWeight: "bold",
                              width: "100px",
                              height: "40px",
                              margin: "35px auto",
                            }}
                            onClick={() => {
                              setZoneData([]);
                              setSteps(1);
                              setSelectedClass("");
                              setRuleName("");
                              setSelectedMotion("");
                              setSelectedThreshold(35);
                              setSelectedInterVal(null);
                              handleCloseAddRule();
                              setTimeEntrySetRules(undefined);
                              setRuleTypeToDelete("");
                              setRuleSubTypeToDelete("");
                              setRuleSubType("");
                            }}
                          >
                            CANCEL
                          </Button>
                          {steps !== 1 && (
                            <Button
                              variant="contained"
                              style={{
                                color: "white",
                                backgroundColor: "rgb(21,50,95)",
                                fontWeight: "bold",
                                width: "100px",
                                height: "40px",
                                margin: "35px auto",
                              }}
                              onClick={prevStepsHandler}
                            >
                              PREV
                            </Button>
                          )}
                          {steps !== 3 && (
                            <Button
                              variant="contained"
                              disabled={
                                !relaventRuleDataToDelete?.fields?.zone_configs
                                  ? false
                                  : zoneData?.length === 0 || steps === 3
                                  ? true
                                  : false
                              }
                              style={{
                                color: "white",
                                backgroundColor:
                                  relaventRuleDataToDelete?.fields
                                    ?.zone_configs &&
                                  (zoneData?.length === 0 || steps === 3)
                                    ? "" // Default color when disabled
                                    : "rgb(21,50,95)", // Red background when enabled
                                fontWeight: "bold",
                                width: "100px",
                                height: "40px",
                                margin: "35px auto",
                              }}
                              onClick={nextStepsHandler}
                            >
                              NEXT
                            </Button>
                          )}
                          {steps === 3 && (
                            <Button
                              variant="contained"
                              style={{
                                color: "white",
                                backgroundColor: "rgb(21,50,95)",
                                fontWeight: "bold",
                                width: "190px",
                                height: "40px",
                                margin: "35px auto",
                              }}
                              onClick={() => {
                                saveUpdateSiteDefaultRulesHandler();
                              }}
                            >
                              {`${
                                orgName === site?.site_name
                                  ? "Update Default Rules"
                                  : "Save Default Rules"
                              }`}
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Box>
            </Modal>
            <Modal
              open={ruleConfig}
              onClose={handleRuleConfigModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={configureRuleModal}>
                {loadingSpinner === true ? (
                  <div
                    style={{
                      margin: "auto",
                      fontSize: "100px",
                      position: "absolute",
                      top: "30%",
                      left: "50%",
                      color: "rgb(21,50,95)",
                    }}
                  >
                    <CircularProgress
                      size={80}
                      style={{
                        color: "rgb(21,50,95)",
                        margin: "auto",
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    {updateRulesMsg !== "" ? (
                      <div
                        style={{
                          marginTop: "150px",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "rgb(21,50,95)",
                            textAlign: "center",
                          }}
                        >
                          {updateRulesMsg}
                        </p>
                        <Button
                          style={{
                            backgroundColor: "rgb(220,220,220)",
                            color: "rgb(21,50,95)",
                            fontWeight: "bold",
                            margin: " 40px 180px",
                            width: "100px",
                          }}
                          onClick={() => {
                            handleCloseAddRule();
                            setUpdateRulesMsg("");
                            handleRuleConfigModalClose();
                          }}
                        >
                          OK
                        </Button>
                      </div>
                    ) : (
                      <div>
                        {steps === 1 && (
                          <div>
                            <div>
                              {updateResponse === "" && (
                                <div
                                  className="save_update_btns"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <h3
                                    style={{
                                      fontWeight: "bold",
                                      color: "rgb(21,50,95)",
                                      margin: "0 auto",
                                      padding: "10px 0",
                                    }}
                                  >
                                    <b>ADD DEFAULT RULE:</b>
                                  </h3>
                                </div>
                              )}
                              {updateResponse !== "" ? (
                                <div
                                  style={{
                                    margin: "0 auto",
                                  }}
                                >
                                  <div></div>
                                  <p
                                    style={{
                                      color: "rgb(21,50,95)",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {updateResponse?.msg}
                                  </p>
                                  <Button
                                    style={{
                                      backgroundColor: "rgb(220,220,220)",
                                      color: "rgb(21,50,95)",
                                      fontWeight: "bold",
                                      marginTop: "40px",
                                    }}
                                    onClick={() => {
                                      setUpdateResponse("");
                                      setParentReload(new Date().getTime());
                                      fetchDefaultRules();
                                    }}
                                  >
                                    OK
                                  </Button>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    width: "100%",
                                    minWidth: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    className="rule-name"
                                    style={{
                                      width: "100%",
                                      minWidth: "100%",
                                      margin: "0 auto",
                                      padding: "30px 0",
                                      borderTop: "1px solid rgb(21,50,95)",
                                      borderBottom: "1px solid rgb(21,50,95)",
                                      paddingTop: "20px",
                                      paddingBottom: "20px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <h3
                                      style={{
                                        fontWeight: "bold",
                                        color: "rgb(21,50,95)",
                                      }}
                                    >
                                      {"Enter Rule's Name:"}
                                    </h3>
                                    <TextField
                                      id="outlined-basic"
                                      variant="outlined"
                                      style={{
                                        width: "450px",
                                      }}
                                      value={ruleName}
                                      onChange={ruleNameHandler}
                                      placeholder={rule}
                                    />
                                  </div>
                                  {relaventRuleData?.fields?.cadence_secs
                                    ?.enabled && (
                                    <div
                                      className="rule-name"
                                      style={{
                                        width: "100%",
                                        minWidth: "100%",
                                        margin: "0 auto",
                                        padding: "30px 0",
                                        borderTop: "1px solid rgb(21,50,95)",
                                        borderBottom: "1px solid rgb(21,50,95)",
                                        paddingTop: "20px",
                                        paddingBottom: "20px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <FormControl
                                        className={classes.formControl}
                                        variant="outlined"
                                        style={{
                                          width: 140,
                                          color: "#6D809D",
                                        }}
                                      >
                                        <InputLabel
                                          id="demo-mutiple-chip-label"
                                          shrink={Boolean(selectedInterVal)}
                                        >
                                          {
                                            relaventRuleData?.fields
                                              ?.cadence_secs?.label
                                          }
                                        </InputLabel>
                                        <Select
                                          variant="outlined"
                                          labelId="demo-mutiple-chip-label"
                                          id="demo-mutiple-chip"
                                          defaultValue={""}
                                          value={selectedInterVal}
                                          onChange={handleIntervalChange}
                                          input={
                                            <Input id="select-multiple-chip" />
                                          }
                                        >
                                          {relaventRuleData?.fields?.cadence_secs?.options?.map(
                                            (item: any, index: number) => (
                                              <MenuItem
                                                key={index}
                                                value={item}
                                              >
                                                {`${item / 60} min`}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                      </FormControl>
                                      <p
                                        style={{
                                          fontStyle: "italic",
                                          color: "red",
                                          fontSize: "10px",
                                        }}
                                      >
                                        {
                                          "applies where products and cameras are configured for fetching images"
                                        }
                                      </p>
                                    </div>
                                  )}
                                  <div
                                    className="form-field"
                                    style={{
                                      marginTop: "45px",
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      margin: "0 auto",
                                      borderTop: "1px solid rgb(21,50,95)",
                                      paddingTop: "20px",
                                    }}
                                  >
                                    {relaventRuleData?.fields?.zone_configs?.map(
                                      (config: any, index: number) => {
                                        if (config?.type === "zone") {
                                          return (
                                            <FormControl
                                              key={index}
                                              disabled={true}
                                              className={classes.formControl}
                                              variant="outlined"
                                              style={{
                                                width: 140,
                                                color: "#6D809D",
                                              }}
                                            >
                                              <InputLabel>
                                                {config?.label}
                                              </InputLabel>
                                              <Select
                                                variant="outlined"
                                                value={config?.default}
                                                input={<Input />}
                                                displayEmpty
                                              >
                                                <MenuItem
                                                  value={config?.default}
                                                >
                                                  {config?.options?.replaceAll(
                                                    "_",
                                                    " "
                                                  )}
                                                </MenuItem>
                                              </Select>
                                            </FormControl>
                                          );
                                        }

                                        if (config?.type === "class_label") {
                                          return (
                                            <FormControl
                                              key={index}
                                              disabled={zoneData?.length === 2}
                                              className={classes?.formControl}
                                              variant="outlined"
                                              style={{
                                                width: 140,
                                                color: "#6D809D",
                                              }}
                                            >
                                              <InputLabel>
                                                {config.label}
                                              </InputLabel>
                                              <Select
                                                variant="outlined"
                                                value={selectedClass}
                                                onChange={handleClassChange}
                                                input={<Input />}
                                              >
                                                {config.options.map(
                                                  (
                                                    classItem: any,
                                                    idx: number
                                                  ) => (
                                                    <MenuItem
                                                      key={idx}
                                                      value={classItem}
                                                    >
                                                      {classItem.split(".")[1]}
                                                    </MenuItem>
                                                  )
                                                )}
                                              </Select>
                                            </FormControl>
                                          );
                                        }

                                        if (
                                          config.type === "confidence_threshold"
                                        ) {
                                          return (
                                            <Tooltip
                                              key={index}
                                              placement="top"
                                              title={`Please note the default value for the Threshold is ${config.default}% with the minimum value being ${config.min}% and the maximum being ${config.max}%.`}
                                            >
                                              <TextField
                                                disabled={
                                                  zoneData?.length === 2
                                                }
                                                type="number"
                                                label={config.label}
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                inputProps={{
                                                  min: config.min,
                                                  max: config.max,
                                                  step: 1,
                                                }}
                                                style={{
                                                  width: 130,
                                                  marginTop: "9px",
                                                }}
                                                value={selectedThreshold}
                                                onChange={handleThreshold}
                                                variant="standard"
                                              />
                                            </Tooltip>
                                          );
                                        }

                                        if (config.type === "object_motion") {
                                          return (
                                            <FormControl
                                              key={index}
                                              disabled={zoneData?.length === 2}
                                              className={classes.formControl}
                                              variant="outlined"
                                              style={{
                                                width: 160,
                                                color: "#6D809D",
                                              }}
                                            >
                                              <InputLabel
                                                shrink={Boolean(selectedClass)}
                                              >
                                                {config.label}
                                              </InputLabel>
                                              <Select
                                                variant="outlined"
                                                value={selectedMotion}
                                                onChange={handleObjectMotion}
                                                input={<Input />}
                                              >
                                                {config.options.map(
                                                  (
                                                    motion: any,
                                                    idx: number
                                                  ) => (
                                                    <MenuItem
                                                      key={idx}
                                                      value={motion.replaceAll(
                                                        " ",
                                                        "_"
                                                      )}
                                                    >
                                                      {motion.replaceAll(
                                                        "_",
                                                        " "
                                                      )}
                                                    </MenuItem>
                                                  )
                                                )}
                                              </Select>
                                            </FormControl>
                                          );
                                        }

                                        if (config.type === "count") {
                                          return (
                                            <TextField
                                              key={index}
                                              disabled={zoneData?.length === 2}
                                              type="number"
                                              label={config.label}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              inputProps={{
                                                min: config.min,
                                                max: config.max,
                                                step: 1,
                                              }}
                                              style={{
                                                width: 130,
                                                marginTop: "9px",
                                              }}
                                              value={selectedCount}
                                              onChange={handleCountChange}
                                              variant="standard"
                                            />
                                          );
                                        }

                                        if (config.type === "count_logic") {
                                          return (
                                            <FormControl
                                              key={index}
                                              disabled={zoneData?.length === 2}
                                              className={classes.formControl}
                                              variant="outlined"
                                              style={{
                                                width: 160,
                                                color: "#6D809D",
                                              }}
                                            >
                                              <InputLabel>
                                                {config.label}
                                              </InputLabel>
                                              <Select
                                                variant="outlined"
                                                value={selectedCountLogic}
                                                onChange={
                                                  handleCountLogicChange
                                                }
                                                input={<Input />}
                                              >
                                                {config?.options?.map(
                                                  (logic: any, idx: any) => (
                                                    <MenuItem
                                                      key={idx}
                                                      value={logic}
                                                    >
                                                      {logic}
                                                    </MenuItem>
                                                  )
                                                )}
                                              </Select>
                                            </FormControl>
                                          );
                                        }

                                        return null;
                                      }
                                    )}
                                    {updateResponse === "" ? (
                                      <div
                                        style={{
                                          display: relaventRuleData?.fields
                                            ?.zone_configs
                                            ? "block"
                                            : "none",
                                        }}
                                      >
                                        <Button
                                          disabled={
                                            zoneData?.length === 2
                                              ? true
                                              : false
                                          }
                                          variant="contained"
                                          style={{
                                            color: "white",
                                            backgroundColor:
                                              zoneData?.length === 2
                                                ? ""
                                                : "red",
                                            fontWeight: "bold",
                                            width: "95px",
                                            height: "50px",
                                            margin: "0 15px",
                                            transform: "translate(10px,0)",
                                          }}
                                          onClick={addRuleHandlerConfig}
                                        >
                                          ADD
                                        </Button>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              )}
                              {zoneData.length === 2 && (
                                <p
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    fontSize: "10px",
                                    width: "800px",
                                    textAlign: "center",
                                    margin: "auto",
                                    padding: "10px 0",
                                  }}
                                >
                                  The maximum default number of zone configs is
                                  2
                                </p>
                              )}
                              <div
                                style={{
                                  marginTop: "45px",
                                  width: "100%",
                                  margin: "0 auto",
                                  borderTop: "1px solid rgb(21,50,95)",
                                  paddingTop: "20px",
                                  overflow: "auto",
                                  maxHeight: "300px",
                                  overflowY: "auto",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {zoneData?.length !== 0 && (
                                    <p
                                      style={{
                                        color: "#15325F",
                                        fontSize: "15px",
                                        fontStyle: "normal",
                                        fontFamily: "Open Sans",
                                        width: "800px",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {"ZONE CONFIGS:"}
                                    </p>
                                  )}
                                  {zoneData?.map((zone: any, index: any) => {
                                    return (
                                      <div
                                        key={index}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          margin: "10px auto",
                                          width: "100%",
                                          backgroundColor: "#F6F6F6",
                                          boxShadow: "2px 1px #F6F6F6",
                                          borderRadius: "10px",
                                          padding: "5px 0",
                                        }}
                                      >
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Zone:{" "}
                                          <span style={{ color: "grey" }}>
                                            {"full image"}
                                          </span>
                                        </p>

                                        {relaventRuleData?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "class_label"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Class:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.class_label?.split(".")[1]}
                                            </span>
                                          </p>
                                        )}
                                        {relaventRuleData?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "object_motion"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Object Motion:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.object_motion?.replaceAll(
                                                "_",
                                                " "
                                              )}
                                            </span>
                                          </p>
                                        )}

                                        {relaventRuleData?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type ===
                                            "confidence_threshold"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Threshold:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.confidence_threshold}
                                            </span>
                                          </p>
                                        )}

                                        {relaventRuleData?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "count"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Count:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.count}
                                            </span>
                                          </p>
                                        )}

                                        {relaventRuleData?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "count_logic"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                            }}
                                          >
                                            Count Logic:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.count_logic}
                                            </span>
                                          </p>
                                        )}

                                        {!relaventRuleData?.fields?.zone_configs?.some(
                                          (config: any) =>
                                            config.type === "class_label" ||
                                            config.type === "object_motion" ||
                                            config.type ===
                                              "confidence_threshold"
                                        ) && (
                                          <p
                                            style={{
                                              color: "rgb(21,50,95)",
                                              fontWeight: "bold",
                                              padding: "5px 10px",
                                              visibility: "hidden",
                                            }}
                                          >
                                            Fetch Interval:{" "}
                                            <span style={{ color: "grey" }}>
                                              {zone?.cadence_secs}
                                            </span>
                                          </p>
                                        )}
                                        <IconButton
                                          style={{
                                            color: "red",
                                            marginTop: "-8px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            removeRuleHandler(zone?.random_id);
                                          }}
                                        >
                                          <DeleteForeverIcon />
                                        </IconButton>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {steps === 2 && (
                          <div
                            style={{
                              width: "1000px",
                              display: "flex",
                              flexDirection: "column",
                              alignContent: "center",
                              justifyContent: "center",
                            }}
                          >
                            <p
                              style={{
                                color: "rgb(21,50,95)",
                                fontWeight: "bold",
                                fontSize: "12px",
                                margin: "0 auto",
                                padding: "10px 0",
                              }}
                            >
                              {
                                "Please note that the timezone applied will be as specified at the site level."
                              }
                            </p>
                            {relaventRuleData?.fields?.valid_days_and_times
                              ?.enabled && (
                              <div
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignContent: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    margin: "auto",
                                  }}
                                >
                                  <FormControl component="fieldset">
                                    <RadioGroup
                                      aria-label="schedule"
                                      name="schedule"
                                      value={radioValue}
                                      onChange={handleTimeChange}
                                    >
                                      <FormControlLabel
                                        value="always"
                                        control={<Radio />}
                                        label="Always active"
                                        disabled={false}
                                      />

                                      <FormControlLabel
                                        value="schedule"
                                        control={<Radio />}
                                        label="Schedule"
                                        disabled={false}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                                {radioValue !== "always" && (
                                  <div
                                    style={{
                                      width: "38%",
                                      margin: "0 auto",
                                      display: "flex",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    {radioValue === "" ? (
                                      <span>{""}</span>
                                    ) : (
                                      <div
                                        style={{
                                          // width: "38%",
                                          margin: "0 auto",
                                        }}
                                      >
                                        {scheduleRules}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                        {steps === 3 && (
                          <div style={{ width: "800px", textAlign: "center" }}>
                            <h2
                              style={{
                                color: "rgb(21,50,95)",
                                fontWeight: "bold",
                                fontSize: "15px",
                              }}
                            >
                              CONFIRM DETAILS:
                            </h2>
                            <div style={{ height: "20px" }}></div>
                            <p style={{ fontWeight: "bold", color: "red" }}>
                              {
                                "Please note that you will be updating your defaults rules for this site"
                              }
                            </p>
                            <div style={{ height: "60px" }}></div>
                            <div
                              style={{
                                width: "100%",
                                margin: "0 auto",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p
                                style={{
                                  color: "rgb(21,50,95)",
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                }}
                              >
                                Rule Name:{" "}
                                <span style={{ color: "grey" }}>
                                  {`${ruleName === "" ? rule : ruleName}`}
                                </span>
                              </p>
                              <p
                                style={{
                                  color: "rgb(21,50,95)",
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                }}
                              >
                                Rule Type:{" "}
                                <span style={{ color: "grey" }}>{rule}</span>
                              </p>
                              {relaventRuleData?.fields?.cadence_secs
                                ?.enabled && (
                                <p
                                  style={{
                                    color: "rgb(21,50,95)",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}
                                >
                                  Fetch Interval:{" "}
                                  <span style={{ color: "grey" }}>
                                    {selectedInterVal}
                                  </span>
                                </p>
                              )}
                            </div>
                            <div
                              style={{
                                marginTop: "45px",
                                width: "100%",
                                margin: "0 auto",
                                borderTop: "1px solid rgb(21,50,95)",
                                borderBottom: "1px solid rgb(21,50,95)",
                                paddingTop: "20px",
                                paddingBottom: "20px",
                                overflow: "auto",
                                maxHeight: "300px",
                                overflowY: "auto",
                              }}
                            >
                              <p
                                style={{
                                  color: "rgb(21,50,95)",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                }}
                              >
                                ZONE CONFIGS:{" "}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {zoneData?.map((zone: any, index: any) => {
                                  return (
                                    <div
                                      key={index}
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        margin: "10px auto",
                                        width: "100%",
                                        backgroundColor: "#F6F6F6",
                                        boxShadow: "2px 1px #F6F6F6",
                                        borderRadius: "10px",
                                        padding: "5px 0",
                                      }}
                                    >
                                      <p
                                        style={{
                                          color: "rgb(21,50,95)",
                                          fontWeight: "bold",
                                          padding: "5px 10px",
                                        }}
                                      >
                                        Zone:{" "}
                                        <span style={{ color: "grey" }}>
                                          {"full image"}
                                        </span>
                                      </p>
                                      {relaventRuleData?.fields?.zone_configs?.some(
                                        (config: any) =>
                                          config.type === "class_label"
                                      ) && (
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Class:{" "}
                                          <span style={{ color: "grey" }}>
                                            {zone?.class_label?.split(".")[1]}
                                          </span>
                                        </p>
                                      )}
                                      {relaventRuleData?.fields?.zone_configs?.some(
                                        (config: any) =>
                                          config.type === "object_motion"
                                      ) && (
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Object Motion:{" "}
                                          <span style={{ color: "grey" }}>
                                            {zone?.object_motion?.replaceAll(
                                              "_",
                                              " "
                                            )}
                                          </span>
                                        </p>
                                      )}
                                      {relaventRuleData?.fields?.zone_configs?.some(
                                        (config: any) =>
                                          config.type === "confidence_threshold"
                                      ) && (
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Threshold:{" "}
                                          <span style={{ color: "grey" }}>
                                            {zone?.confidence_threshold}
                                          </span>
                                        </p>
                                      )}
                                      {relaventRuleData?.fields?.zone_configs?.some(
                                        (config: any) => config.type === "count"
                                      ) && (
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Count:{" "}
                                          <span style={{ color: "grey" }}>
                                            {zone?.count}
                                          </span>
                                        </p>
                                      )}
                                      {relaventRuleData?.fields?.zone_configs?.some(
                                        (config: any) =>
                                          config.type === "count_logic"
                                      ) && (
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                          }}
                                        >
                                          Count Logic:{" "}
                                          <span style={{ color: "grey" }}>
                                            {zone?.count_logic}
                                          </span>
                                        </p>
                                      )}
                                      {!relaventRuleData?.fields?.zone_configs?.some(
                                        (config: any) =>
                                          config.type === "class_label" ||
                                          config.type === "object_motion" ||
                                          config.type ===
                                            "confidence_threshold" ||
                                          config.type === "count" ||
                                          config.type === "count_logic"
                                      ) && (
                                        <p
                                          style={{
                                            color: "rgb(21,50,95)",
                                            fontWeight: "bold",
                                            padding: "5px 10px",
                                            visibility: "hidden",
                                          }}
                                        >
                                          Fetch Interval:{" "}
                                          <span style={{ color: "grey" }}>
                                            {zone?.cadence_secs}
                                          </span>
                                        </p>
                                      )}

                                      <IconButton
                                        style={{
                                          color: "red",
                                          marginTop: "-8px",
                                          cursor: "pointer",
                                          visibility: "hidden",
                                        }}
                                        onClick={() => {
                                          removeRuleHandler(zone?.random_id);
                                        }}
                                      >
                                        <DeleteForeverIcon />
                                      </IconButton>
                                    </div>
                                  );
                                })}
                              </div>
                              <p
                                style={{
                                  color: "rgb(21,50,95)",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  marginTop: "5px",
                                }}
                              >
                                DAYS & TIMES:{" "}
                              </p>
                              <div style={{ width: "100%" }}>
                                <p
                                  style={{
                                    color: "grey",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {timeentrySetRules === undefined
                                    ? "always active"
                                    : scene_valid_days_and_times}
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                        <div
                          className="cancel_next_btns"
                          style={{
                            display: "flex",
                          }}
                        >
                          <Button
                            variant="contained"
                            style={{
                              color: "rgb(21,50,95)",
                              fontWeight: "bold",
                              width: "100px",
                              height: "40px",
                              margin: "35px auto",
                            }}
                            onClick={() => {
                              setZoneData([]);
                              setSteps(1);
                              setSelectedClass("");
                              setRuleName("");
                              setSelectedInterVal(null);
                              setSelectedMotion("");
                              setSelectedInterVal(null);
                              setSelectedThreshold(35);
                              handleCloseAddRule();
                              setTimeEntrySetRules(undefined);
                              setRadioValue("always");
                              setRuleTypeToDelete("");
                              setRuleSubTypeToDelete("");
                              setAlertTypeToDelete("");
                              setRuleIDToDelete("");
                              setRuleSubType("");
                              handleRuleConfigModalClose();
                            }}
                          >
                            CANCEL
                          </Button>
                          {steps > 1 && (
                            <Button
                              variant="contained"
                              style={{
                                color: "white",
                                backgroundColor: "rgb(21,50,95)",
                                fontWeight: "bold",
                                width: "100px",
                                height: "40px",
                                margin: "35px auto",
                              }}
                              onClick={prevStepsHandler}
                            >
                              PREV
                            </Button>
                          )}
                          {steps !== 3 && (
                            <Button
                              variant="contained"
                              disabled={
                                !relaventRuleData?.fields?.zone_configs
                                  ? false
                                  : zoneData?.length === 0 || steps === 3
                                  ? true
                                  : false
                              }
                              style={{
                                color: "white",
                                backgroundColor:
                                  relaventRuleData?.fields?.zone_configs &&
                                  (zoneData?.length === 0 || steps === 3)
                                    ? "" // Default color when disabled
                                    : "rgb(21,50,95)", // Red background when enabled
                                fontWeight: "bold",
                                width: "100px",
                                height: "40px",
                                margin: "35px auto",
                              }}
                              onClick={nextStepsHandler}
                            >
                              NEXT
                            </Button>
                          )}
                          {steps === 3 && (
                            <Button
                              variant="contained"
                              style={{
                                color: "white",
                                backgroundColor: "rgb(21,50,95)",
                                fontWeight: "bold",
                                width: "100px",
                                height: "40px",
                                margin: "35px auto",
                              }}
                              onClick={submitSiteDefaultRuleHandler}
                            >
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Box>
            </Modal>
            <Modal
              open={openRuleConfig}
              onClose={closeRuleConfigDialog}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={addRuleDialogModal}>
                <div style={{ width: "100%" }}>
                  <Grid container spacing={1}>
                    <Autocomplete
                      id="combo-box-demo"
                      onChange={handleAlertChange}
                      options={alertTypes}
                      style={{ width: 400, color: "rgb(21,50,95)" }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          label={
                            <span
                              className={classes.bold_text}
                              style={{ color: "rgb(21,50,95)" }}
                            >
                              {"Alert Type"}
                            </span>
                          }
                          variant="outlined"
                          value={alert}
                          className={classes.bold_text}
                          style={{
                            color: "rgb(21,50,95)",
                            borderWidth: "2px",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <div
                    style={{
                      height: "30px",
                    }}
                  ></div>
                  {alert === null ? null : (
                    <Grid container spacing={1}>
                      <Autocomplete
                        id="combo-box-demo"
                        onChange={handleRuleChange}
                        options={[
                          ...ruleTypeOptions?.abled,
                          ...ruleTypeOptions?.disabled,
                        ]}
                        getOptionDisabled={(option) => {
                          return ruleTypeOptions?.disabled?.includes(option); // Disable the option if it's in the disabledOptions array
                        }}
                        style={{ width: 400, color: "rgb(21,50,95)" }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label={
                              <span
                                className={classes.bold_text}
                                style={{ color: "rgb(21,50,95)" }}
                              >
                                {"Rule Type"}
                              </span>
                            }
                            variant="outlined"
                            value={rule}
                            className={classes.bold_text}
                            style={{
                              color: "rgb(21,50,95)",
                              borderWidth: "2px",
                            }}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <div
                    style={{
                      width: "97%",
                      display: "flex",
                      justifyContent: "end",
                      padding: "15px 0",
                    }}
                  >
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="text"
                        style={{ color: "rgb(21,50,95)" }}
                        onClick={closeRuleConfigDialog}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="text"
                        onClick={() => {
                          setRuleConfig(true);
                          closeRuleConfigDialog();
                        }}
                        style={{
                          color:
                            alert === null || rule === ""
                              ? "	#D3D3D3"
                              : "rgb(21,50,95)",
                          pointerEvents:
                            alert === null || rule === "" ? "none" : "auto",
                        }}
                      >
                        Continue
                      </Button>
                    </Stack>
                  </div>
                </div>
              </Box>
            </Modal>
            <div
              className={classes.header_text_white}
              style={{
                marginLeft: "35px",
                marginBottom: "33px",
              }}
              onClick={handleExpandDefaultRules}
            >
              Default Rules {`(${default_rules?.rules?.length})`}
              <span
                style={{
                  visibility:
                    default_rules?.rules_origin?.site_name &&
                    default_rules?.rules_origin?.site_name === site?.site_name
                      ? "visible"
                      : "hidden",
                }}
              >
                <IconButton
                  edge="end"
                  aria-label="add"
                  onClick={() => {
                    openRuleConfigHandler();
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
                      fill="#FFFFFF"
                      fill-opacity="0.87"
                    />
                  </svg>
                </IconButton>
              </span>
            </div>
            <div
              style={{
                fontWeight: "bold",
                color: "white",
              }}
            >
              {expandDefaultRules === false ? (
                <ExpandMoreIcon onClick={() => handleExpandDefaultRules()} />
              ) : (
                <ExpandLessIcon onClick={() => handleExpandDefaultRules()} />
              )}
            </div>
          </div>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {loadingSpinner === true ? (
              <CircularProgress
                size={70}
                style={{
                  color: "rgb(21,50,95)",
                  margin: "auto",
                }}
              />
            ) : (
              <div>
                <p
                  style={{
                    color: "rgb(21,50,95)",
                    fontWeight: "bold",
                  }}
                >
                  {updateResponse === ""
                    ? "Are you sure you want to delete rule?"
                    : updateResponse?.msg}
                </p>
                {updateResponse !== "" ? (
                  <Button
                    style={{
                      backgroundColor: "rgb(220,220,220)",
                      color: "rgb(21,50,95)",
                      fontWeight: "bold",
                      marginTop: "40px",
                    }}
                    onClick={() => {
                      handleClose();
                      setRefreshParent();
                      setUpdateResponse("");
                      setRuleName("");
                    }}
                  >
                    OK
                  </Button>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "80%",
                      margin: "0 auto",
                      marginTop: "70px",
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "rgb(220,220,220)",
                        color: "rgb(21,50,95)",
                        fontWeight: "bold",
                      }}
                      onClick={handleClose}
                    >
                      CANCEL
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        deleteDefaultRuleHandler();
                      }}
                    >
                      DELETE
                    </Button>
                  </div>
                )}
              </div>
            )}
          </Box>
        </Modal>
        {expandDefaultRules === true &&
        !site?.data_origin?.includes("motioncam") ? (
          <div className="">
            <p
              style={{
                padding: "10px 0",
                width: "50%",
                textAlign: "center",
                fontWeight: "bold",
                color: "rgb(21,50,95)",
                fontSize: "15px",
                borderBottom: "1px solid rgb(21,50,95)",
                margin: "auto",
              }}
            >
              {site?.site_name}
              <span
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "14px",
                  color: "rgb(21,50,95)",
                }}
              >
                {" "}
                {`(id=${site?.site_id})`}
              </span>
            </p>
            <p
              style={{
                padding: "10px 0",
                width: "100%",
                textAlign: "center",
                fontWeight: "bold",
                color: "rgb(21,50,95)",
                fontSize: "12px",
                borderBottom: "1px solid rgb(21,50,95)",
              }}
            >
              {`Please note the below default rules are applied to each camera that has no rules configured. If one or more rules are configured on a camera this will override the default rules set for that specific camera.`}
            </p>
            <p
              style={{
                padding: "10px 0",
                width: "100%",
                textAlign: "center",
                fontWeight: "bold",
                color: "red",
                fontSize: "12px",
              }}
            >
              {default_rules?.rules_origin?.site_name
                ? default_rules?.rules_origin?.site_name === site?.site_name
                  ? `The default rules are defined at the level of this site.`
                  : `The default rules for this site are inherited from group ${default_rules?.rules_origin?.site_name}`
                : default_rules?.rules_origin?.org_name === site?.site_name
                ? `The default rules are defined at the level of this site.`
                : `The default rules for this site are inherited from group ${default_rules?.rules_origin?.org_name} (id=${default_rules?.rules_origin?.org_id})`}
            </p>
            {default_rules?.rules_origin?.site_name &&
            default_rules?.rules_origin?.site_name === site?.site_name ? (
              ""
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    backgroundColor: "rgb(21,50,95)",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    cloneDefaultRulesHandler();
                    setRefreshParent();
                  }}
                >
                  Clone Inherited Rules
                </Button>
              </div>
            )}
            <div
              style={{
                height: "15px",
              }}
            ></div>
            {default_rules?.rules?.map((rule: any, parentIndex: any) => {
              return (
                <div className="defaults" style={{ marginTop: "5px" }}>
                  {rule?.zone_configs?.length !== 0 ? (
                    <div
                      style={{
                        opacity:
                          site?.data_origin?.includes("mobcam") &&
                          rule?.alert_type?.toLocaleLowerCase() === "scheduled"
                            ? 0.2
                            : 1,
                      }}
                    >
                      <p
                        key={parentIndex}
                        style={{
                          width: "95%",
                          margin: "auto",
                          fontWeight: "bold",
                          color: "rgb(21,50,95)",
                          fontSize: "11px",
                        }}
                      >
                        {rule?.rule_type
                          ?.replaceAll("_", " ")
                          .toLocaleUpperCase()}
                        <span
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {" "}
                          ({rule?.alert_type?.toLocaleLowerCase()})
                        </span>
                        <span
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {": "}
                          {rule?.rule_name}
                        </span>
                      </p>
                    </div>
                  ) : null}
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        width: "97%",
                        border: "2px solid rgb(21,50,95)",
                        borderRadius: "10px",
                        margin: "10px 0",
                        opacity:
                          site?.data_origin?.includes("mobcam") &&
                          rule?.alert_type?.toLocaleLowerCase() === "scheduled"
                            ? 0.2
                            : 1,
                      }}
                    >
                      {rule?.zone_configs?.map((zone: any, childIndex: any) => {
                        return (
                          <div
                            className="zone_information"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              backgroundColor: "#FFFFFF",
                              borderRadius: "5px",
                              width: "90%",
                              margin: "0 auto",
                              fontSize: "12px",
                              marginTop: "10px",
                            }}
                            key={childIndex} // Added key prop for list rendering
                          >
                            <p
                              style={{
                                color: "rgb(21,50,95)",
                                fontWeight: "bold",
                                width: "25%",
                                textAlign: "start",
                                padding: "5px 10px",
                              }}
                            >
                              Zone:{" "}
                              <span style={{ color: "grey" }}>
                                {zone?.zone?.replaceAll("_", " ")}
                              </span>
                            </p>
                            <p
                              style={{
                                color: "rgb(21,50,95)",
                                fontWeight: "bold",
                                textAlign: "start",
                                width: "25%",
                                padding: "5px 10px",
                                visibility: `${
                                  zone?.class_label ? "visible" : "hidden"
                                }`,
                              }}
                            >
                              Class:{" "}
                              <span style={{ color: "grey" }}>
                                {zone?.class_label?.split(".")[1]}
                              </span>
                            </p>

                            <p
                              style={{
                                color: "rgb(21,50,95)",
                                fontWeight: "bold",
                                padding: "5px 10px",
                                textAlign: "start",
                                width: "30%",
                                visibility: `${
                                  zone?.object_motion ? "visible" : "hidden"
                                }`,
                              }}
                            >
                              Object Motion:{" "}
                              <span style={{ color: "grey" }}>
                                {zone?.object_motion?.replaceAll("_", " ")}
                              </span>
                            </p>
                            <p
                              style={{
                                color: "rgb(21,50,95)",
                                fontWeight: "bold",
                                padding: "5px 10px",
                                textAlign: "start",
                                width: "20%",
                                visibility: `${
                                  zone?.confidence_threshold
                                    ? "visible"
                                    : "hidden"
                                }`,
                              }}
                            >
                              Threshold:{" "}
                              <span style={{ color: "grey" }}>
                                {zone?.confidence_threshold}
                              </span>
                            </p>
                            <p
                              style={{
                                color: "rgb(21,50,95)",
                                fontWeight: "bold",
                                padding: "5px 10px",
                                width: "20%",
                                display: zone?.count >= 0 ? "block" : "none",
                              }}
                            >
                              Count:{" "}
                              <span style={{ color: "grey" }}>
                                {zone?.count}
                              </span>
                            </p>
                            <p
                              style={{
                                color: "rgb(21,50,95)",
                                fontWeight: "bold",
                                padding: "5px 10px",
                                width: "20%",
                                display: zone?.count_logic ? "block" : "none",
                              }}
                            >
                              Count Logic:{" "}
                              <span style={{ color: "grey" }}>
                                {zone?.count_logic}
                              </span>
                            </p>
                          </div>
                        );
                      })}
                      <div
                        style={{
                          borderTop: "2px solid rgb(21,50,95)",
                          paddingTop: "10px",
                          margin: "auto",
                          color: "rgb(21,50,95)",
                          width: "95%",
                          textAlign: "start",
                          fontWeight: "bold",
                          padding: "5px 10px",
                        }}
                      >
                        <p
                          style={{
                            marginLeft: "15px",
                          }}
                        >
                          Days-Times:{" "}
                          <span style={{ color: "grey" }}>
                            {rule?.valid_days_and_times === null
                              ? "always active"
                              : rule?.valid_days_and_times?.toString()}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignSelf: "center",
                        marginTop: "8px",
                        width: "2px",
                      }}
                    >
                      {default_rules?.rules_origin?.site_name &&
                        default_rules.rules_origin.site_name ===
                          site?.site_name && (
                          <IconButton
                            style={{
                              color: "rgb(21,50,95)",
                              marginTop: "-8px",
                              cursor: "pointer",
                              display:
                                site?.data_origin?.includes("mobcam") &&
                                rule?.alert_type?.toLocaleLowerCase() ===
                                  "scheduled"
                                  ? "none"
                                  : "block",
                            }}
                            onClick={(event: any) => {
                              if (rule!) {
                                setRuleIDToDelete(rule?.id!);
                                editRuleHandler(rule?.rule_type);
                                setRuleNameToDelete(rule?.rule_name);
                                setAlertTypeToDelete(rule?.alert_type);
                                setRuleTypeToDelete(rule?.rule_type);
                                setRuleSubType(rule?.rule_subtype);
                                setRuleSubTypeToDelete(rule?.rule_subtype);
                                if (rule?.cadence_secs) {
                                  setSelectedInterValEdit(rule?.cadence_secs);
                                }
                              } else {
                                console.warn(
                                  "Rule data is not available at this time."
                                );
                              }
                            }}
                          >
                            <EditNoteIcon style={{ fontSize: "18px" }} />
                          </IconButton>
                        )}
                      {default_rules?.rules_origin?.site_name &&
                        default_rules.rules_origin.site_name ===
                          site?.site_name && (
                          <IconButton
                            style={{
                              color: "red",
                              marginTop: "-8px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleOpen();
                              setRuleTypeToDelete(rule?.rule_type);
                              setRuleSubTypeToDelete(rule?.rule_subtype);
                              setAlertTypeToDelete(rule?.alert_type);
                              setRuleIDToDelete(rule?.id);
                              setRuleNameToDelete(rule?.rule_name);
                            }}
                          >
                            <DeleteForeverIcon style={{ fontSize: "18px" }} />
                          </IconButton>
                        )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
        {!site?.data_origin.includes("motioncam") && (
          <div className={classes.default_rules_header}>
            <div
              className={classes.header_text_white}
              style={{
                marginLeft: "35px",
                marginBottom: "33px",
              }}
              onClick={handleExpandedListOfRules}
            >
              List of Rules
              <span style={{ visibility: "hidden" }}>
                <IconButton
                  edge="end"
                  aria-label="add"
                  onClick={handleOpenAddRule}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
                      fill="#FFFFFF"
                      fill-opacity="0.87"
                    />
                  </svg>
                </IconButton>
              </span>
            </div>
            <div
              style={{
                fontWeight: "bold",
                color: "white",
              }}
            >
              {expandListOfRules === false ? (
                <ExpandMoreIcon onClick={() => handleExpandedListOfRules()} />
              ) : (
                <ExpandLessIcon onClick={() => handleExpandedListOfRules()} />
              )}
            </div>
          </div>
        )}
        {expandListOfRules === true && (
          <div
            style={{
              maxHeight: "500px",
              minHeight: "180px",
              overflow: "auto",
              textAlign: "start",
            }}
          >
            {listRules?.length !== 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "between",
                  width: "100%",
                  padding: "5px",
                  position: "sticky",
                  top: 0,
                  backgroundColor: "white",
                  margin: "10px 0",
                  borderBottom: "2px solid red",
                  paddingBottom: "5px",
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    color: "red",
                    fontSize: "12px",
                    width: "25%",
                  }}
                >
                  {"Camera:  "}
                </p>
                <p
                  style={{
                    fontWeight: "bold",
                    color: "red",
                    fontSize: "12px",
                    width: "25%",
                    visibility: "hidden",
                  }}
                >
                  {"default message:  "}
                </p>
                <p
                  style={{
                    fontWeight: "bold",
                    color: "red",
                    fontSize: "12px",
                    width: "25%",
                  }}
                >
                  {"Rule Name:  "}
                </p>
                <p
                  style={{
                    fontWeight: "bold",
                    color: "red",
                    fontSize: "12px",
                    width: "25%",
                  }}
                >
                  {"Rule Type:  "}
                </p>
                <p
                  style={{
                    fontWeight: "bold",
                    color: "red",
                    fontSize: "12px",
                    width: "25%",
                  }}
                >
                  {"Days-Times: "}
                </p>
              </div>
            )}
            {listRules?.map((ruleItem: any, ruleItemIndex: any) => (
              <div
                key={ruleItemIndex}
                style={{
                  borderBottom: "1px solid rgb(21,50,95)",
                  paddingBottom: "5px",
                }}
              >
                {ruleItem?.map((rule: any, ruleIndex: any) => (
                  <div>
                    {ruleIndex === 0 && (
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "rgb(21,50,95)",
                            fontSize: "11px",
                            width: "25%",
                          }}
                        >
                          {rule?.sensor_name}
                          {` (${rule?.sensor_id_str})`}
                        </p>
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "rgb(21,50,95)",
                            fontSize: "10px",
                            width: "20%",
                          }}
                        >
                          {`(${
                            rule?.default_rule === true
                              ? "Using Default Rules"
                              : "Using Defined Rules"
                          })`}
                        </p>
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "rgb(21,50,95)",
                            fontSize: "10px",
                            width: "25%",
                            visibility: "hidden",
                          }}
                        ></p>
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "rgb(21,50,95)",
                            fontSize: "10px",
                            width: "25%",
                            visibility: "hidden",
                          }}
                        ></p>
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "rgb(21,50,95)",
                            fontSize: "10px",
                            width: "25%",
                            visibility: "hidden",
                          }}
                        ></p>
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "between",
                        width: "100%",
                        padding: "5px",
                        borderRadius: "10px",
                        margin: "10px 0",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "rgb(21,50,95)",
                          fontSize: "10px",
                          width: "25%",
                          visibility: "hidden",
                        }}
                      >
                        {rule?.sensor_name}
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "rgb(21,50,95)",
                          fontSize: "10px",
                          width: "25%",
                          visibility: "hidden",
                        }}
                      >
                        {rule?.sensor_id_str}
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "rgb(21,50,95)",
                          fontSize: "10px",
                          width: "25%",
                        }}
                      >
                        {rule?.rule?.rule_name}
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "rgb(21,50,95)",
                          fontSize: "10px",
                          width: "25%",
                        }}
                      >
                        {rule?.rule?.rule_type === null ||
                        rule?.rule?.rule_type === "default_rules"
                          ? "(Using Defaults)"
                          : rule?.rule?.rule_type
                              ?.replaceAll("_", " ")
                              ?.toLocaleUpperCase()}
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "rgb(21,50,95)",
                          fontSize: "10px",
                          width: "25%",
                        }}
                      >
                        {rule?.rule?.valid_days_and_times === null
                          ? "always active"
                          : rule?.rule?.valid_days_and_times}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
        {rulesandmethods}
      </div>
    );
  }
  if (loading) {
    return (
      <Container maxWidth="md">
        <div className="wait-container">
          <svg width="48px" height="48px" viewBox="0 0 128 128">
            <g>
              <linearGradient id="linear-gradient">
                <stop offset="0%" stop-color="#ffffff" />
                <stop offset="100%" stop-color="#22305f" />
              </linearGradient>
              <path
                d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                fill="url(#linear-gradient)"
                fill-rule="evenodd"
              />
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 64 64"
                to="360 64 64"
                dur="1080ms"
                repeatCount="indefinite"
              ></animateTransform>
            </g>
          </svg>
        </div>
      </Container>
    );
  } else {
    return (
      <div
        style={{
          width: "90%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // marginRight: '10%',
        }}
      >
        <Card
          className={classes.expansionbar_medium_alert}
          onClick={() => handleAlertChannelExpanded()}
        >
          <div className={classes.header}>
            <div className={classes.header_icon}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.58 4.08L6.15 2.65C3.75 4.48 2.17 7.3 2.03 10.5h2c.15-2.65 1.51-4.97 3.55-6.42zm12.39 6.42h2c-.15-3.2-1.73-6.02-4.12-7.85l-1.42 1.43c2.02 1.45 3.39 3.77 3.54 6.42zM18 11c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2v-5zm-6 11c.14 0 .27-.01.4-.04.65-.14 1.18-.58 1.44-1.18.1-.24.15-.5.15-.78h-4c.01 1.1.9 2 2.01 2z"
                  fill="#FFFFFF"
                  fill-opacity="0.87"
                />
              </svg>
            </div>
            <div className={classes.header_text_white}>
              {alertchannel.name}{" "}
            </div>
          </div>
          {expandalertchannel}
        </Card>
        <div className={classes.expansioncontent_whole_width}>
          {alertchannelcontent}
        </div>
        <AddRuleModal
          appStore={appStore}
          currentAlertChannel={alertchannel}
          open={ruleopen}
          onClose={closeAddRuleModal}
          saveNewRule={(
            alert_channel_id: number,
            class_labels: string,
            sensor_ids: string,
            valid_days_and_times: string,
            snapshot?: boolean
          ) =>
            saveNewRule(
              alert_channel_id,
              class_labels,
              sensor_ids,
              valid_days_and_times,
              snapshot
            )
          }
          currentClassifier={classifier}
        />
        <EditRuleModal
          noEndtime={noEndtime}
          config={editingconfig!}
          appStore={appStore}
          currentAlertChannel={alertchannel}
          open={openeditrule}
          onClose={handleEditRuleClose}
          updateRule={(
            alert_channel_id: number,
            alert_config_id: number,
            valid_days_and_times: string
          ) =>
            updateRule(alert_channel_id, alert_config_id, valid_days_and_times)
          }
          currentClassifier={classifier}
        />
        <EditRulesModal
          configs={selectedrules}
          appStore={appStore}
          currentAlertChannel={alertchannel}
          open={openeditrules}
          onClose={handleEditRulesClose}
          updateRules={(
            alert_channel_id: number,
            valid_days_and_times: string
          ) => updateRules(alert_channel_id, valid_days_and_times)}
          rules={configs}
        />
        <AddAlertEndpointModal
          forceRefresh={forceRefresh}
          refreshLoading={refreshLoading}
          sensors={sensors}
          endpointdefs={endpointdefs}
          appStore={appStore}
          currentOrg={currentOrg}
          alertchannel={alertchannel}
          open={endpointopen}
          onClose={closeAddEndpointModal}
          saveRevisedNewEndpoint={(
            end_point_type: string,
            alertchannel: AlertChannel
          ) => saveRevisedNewEndpoint(end_point_type, alertchannel)}
          saveNewEndpoint={(
            alert_channel_id: number,
            end_point_type: string,
            name: string,
            meta: { [key: string]: any },
            userendpointroles: UserEndPointRoles[],
            message: string
          ) =>
            saveNewEndpoint(
              alert_channel_id,
              end_point_type,
              name,
              meta,
              userendpointroles,
              message
            )
          }
          site={site}
        />
        <EditAlertEndpointModal
          telegramDefinition={telegramDefinition}
          forceRefresh={forceRefresh}
          setPopMessage={(message: string) => setMessageModal(message)}
          setLoadingParent={() => LoadingParent()}
          openPopMessage={() => setMessageModalOpen(true)}
          endpoint={editingdeliverymethod!}
          sensors={sensors}
          endpointdefs={endpointdefs}
          appStore={appStore}
          currentOrg={currentOrg}
          alertchannel={alertchannel}
          open={opendeliverymethod}
          onClose={handleEditDeliveryMethodClose}
          updateEndpoint={(
            alert_channel_end_point_id: number,
            meta: { [key: string]: any }
          ) => updateEndpoint(alert_channel_end_point_id, meta)}
          saveNewEndpoint={(
            alert_channel_id: number,
            end_point_type: string,
            name: string,
            meta: { [key: string]: any },
            userendpointroles: UserEndPointRoles[],
            message: string
          ) =>
            saveNewEndpoint(
              alert_channel_id,
              end_point_type,
              name,
              meta,
              userendpointroles,
              message
            )
          }
        />
        {openAddToTele && (
          <WebSettings
            sensors={sensors}
            open={openAddToTele}
            endpoint={editingdeliverymethod!}
            alertchannel={alertchannel}
            org_id={currentOrg.org_id}
            onClose={() => {
              setOpenAddToTele(false);
              setRefresh((val) => val + 1);
            }}
            appStore={appStore}
            endpointdefs={endpointdefs}
          />
        )}
        <MessageModal
          open={messagemodalopen}
          reload={reload}
          onClose={closeMessageModal}
          message={popmessage}
          elements={undefined}
        />
        <ConfirmDialog
          title=""
          open={confirmAlertChannelOpen}
          setOpen={setConfirmAlertChannelOpen}
          onConfirm={deleteAlertChannel}
        >
          {confirmmessage}
        </ConfirmDialog>
        <ConfirmDialog
          title=""
          open={confirmUserOpen}
          setOpen={setConfirmUserOpen}
          onConfirm={deleteUser}
        >
          {confirmmessage}
        </ConfirmDialog>
        <ConfirmDialog
          title=""
          open={confirmRuleOpen}
          setOpen={setConfirmRuleOpen}
          onConfirm={deleteRule}
        >
          {confirmmessage}
        </ConfirmDialog>
        <ConfirmDialog
          title=""
          open={confirmRulesOpen}
          setOpen={setConfirmRulesOpen}
          onConfirm={deleteRules}
        >
          {confirmmessage}
        </ConfirmDialog>
        <ConfirmDialog
          title=""
          open={confirmEndPointOpen}
          setOpen={setConfirmEndPointOpen}
          onConfirm={deleteEndPoint}
        >
          {confirmmessage}
        </ConfirmDialog>
        <AlertConfirmation
          open={disconnectOpen}
          onClose={handleDisconnectClose}
          alertchannelInput={alertchannelEdit}
          HandleUpdateDisconnectNotifications={(input: AlertEndPoint) =>
            HandleUpdateDisconnectNotifications(input)
          }
          appStore={appStore}
        />
        <EditHikCentral
          open={openEditHikCentral}
          onClose={closeEditHikDeliveryMethod}
          appStore={appStore}
          alert_end_point={editingdeliverymethod}
          site={site}
        />
      </div>
    );
  }
};

export default AlertChannelInSite;
